import React, { Component, Fragment } from 'react'
import { markAsReviewed, markUserAsAllowed } from '../../../../../utils/post-utils'
import { connect } from 'react-redux'
import AllowUserIcon from './icons/allow_user.svg'

class MarkUserAsAllow extends Component {
  state = {
    redirect: false,
  }

  markAsAllow = async e => {
    e.preventDefault()
    let {
      postDetails: { post_id, isQuorum, user },
      dispatch,
      postStatus
    } = this.props

    // allow user
    let allow = await markUserAsAllowed({user, dispatch})
    if (allow && postStatus == 'under-review') {
      // reviewed post
      markAsReviewed({
        post_id,
        isQuorum,
        dispatch,
      })
    }
  }

  render() {
    return (
      <Fragment>
        <li style={{ display: 'flex' }}>
          <a href="" onClick={this.markAsAllow}>
            <img
              src={AllowUserIcon}
              alt="mark allow"
              style={{ width: 20, marginLeft: -10, marginRight: 10 }}
            />
            Allow this user
          </a>
        </li>
      </Fragment>
    )
  }
}

export default connect()(MarkUserAsAllow);
