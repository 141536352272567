import React, { Component } from "react";
import { FadeIn } from "animate-components";
import Title from "../others/title";
import { getUnreadNotifications } from "../../actions/notification";
import { getUserDetails } from "../../actions/user";
import { connect } from "react-redux";
import { getFeed, setFeedLoading, getFeedNew } from "../../actions/post";
import CaseStreamLoader from "../others/fadedLoaders/case-stream-loader";
import Feed from "./feed";
import { uDetails } from "../../utils/utils";
import { setActivePage } from "../../actions/user";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import SlideboxPage from "../slidebox-component/slidebox-page";
import CaseStreamPost from "./case-stream-post.js";
import closeIcon from "../../assets/icons/close-new.svg";
import AddSlide from './icons/add-from-slidebox.svg';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      showStreamsModal: false,
      sum: 1,
      nextPage: false,
      onScroll: false,
      showReview: false
    };
    this.toggleNext = this.toggleNext.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.toggleShowReview = this.toggleShowReview.bind(this);
  }


  componentDidMount = () => {
    let { dispatch } = this.props;
    const { username } = uDetails("session");
    dispatch(getUserDetails(username));
    dispatch(getFeedNew(1));
    dispatch(getUnreadNotifications());
    dispatch(setActivePage("home"));
    window.addEventListener("scroll", this.onHomePageScroll);
    this.initializeReactGA();
    document.body.style.overflowY = "scroll"
    document.body.style.overflowX = "hidden"
    window.scrollTo(0, 0);
  };

  triggerModal = e => {
    const overlay = document.getElementById("quorum-modal-overlay");
    if (
      e == "CLOSE MODAL" ||
      e.target == overlay ||
      e.target.id == "close_modal_streams"
    ) {
      this.setState({ showStreamsModal: false, nextPage: false });
    } else {
      this.setState({ showStreamsModal: true });
    }
  };

  componentWillReceiveProps = () => this.setState({ loading: false });

  componentWillUnmount = () => {
    let { dispatch } = this.props;
    dispatch(setActivePage(""));
    window.removeEventListener("scroll", this.onHomePageScroll);
    document.body.style.overflow = "hidden"
  };

  onHomePageScroll = async event => {
    await this.setState({ onScroll: true });
    let element = event.target;
    let {
      dispatch,
      isFeedLoad,
      isAllFeedLoad,
      currentFeedPage,
      isMobile
    } = this.props;
    if (isMobile) {
      if (
        element.documentElement.scrollTop +
        element.documentElement.clientHeight +
        1000 >=
        element.documentElement.scrollHeight
      ) {
        if (isFeedLoad && !isAllFeedLoad) {
          dispatch(setFeedLoading());
          dispatch(getFeed(currentFeedPage + 1, this.state.showReview));
        }
      }
    } else {
      if (
        element.documentElement.scrollTop +
        element.documentElement.clientHeight +
        15 >=
        element.documentElement.scrollHeight
      ) {
        if (isFeedLoad && !isAllFeedLoad) {
          dispatch(setFeedLoading());
          dispatch(getFeed(currentFeedPage + 1, this.state.showReview));
        }
      }
    }
  };

  showCaseMenu = () => {
    const { isMobile, history } = this.props;
    if (isMobile) {
      history.push("/submit-menu");
    }
  };

  initializeReactGA() {
    ReactGA.initialize("UA-138571723-4");
    ReactGA.pageview("/case-stream");
  }

  toggleNext() {
    let { nextPage } = this.state;
    this.setState({ nextPage: !nextPage });
  }

  toggleShowReview() {
    let { dispatch } = this.props
    const show = !this.state.showReview
    this.setState({ showReview: show })
    dispatch(setFeedLoading());
    dispatch(getFeedNew(1, show))
  }

  render() {
    let {
      loading,
      showStreamsModal,
      nextPage,
      onScroll
    } = this.state;
    let { windowSize, isMobile, isFeedLoad, feed } = this.props;

    const initialLoading = feed.length == 0 && !isFeedLoad
    return (
      <div className="home-page">
        <Title value="Home" />

        {
          showStreamsModal && (
            <div
              className="overlay-quorum"
              id="quorum-modal-overlay"
            >
              {!nextPage ? (
                <div
                  className="modal-slidebox"
                >
                  <React.Fragment>
                    <div className="header-modal">
                      <div className="left">
                        <img src={AddSlide} alt="AddSlide" />
                        <h3 className="add-from">Add from Slidebox</h3>
                      </div>
                      <img
                        src={closeIcon}
                        onClick={e => this.triggerModal("CLOSE MODAL")}
                        id="close_modal_streams"
                        style={{ cursor: "pointer" }}
                        className="close-icon"
                      />
                    </div>
                    <SlideboxPage
                      forSlidebox={true}
                      goNext={this.toggleNext}
                      showShared={false}
                      showCheckbox={true}
                      showStreamsModal={this.state.showStreamsModal}
                    />
                  </React.Fragment>
                </div>
              ) : (
                <div
                  className="modal-slidebox modal-publish"
                >
                  <CaseStreamPost
                    goBack={this.toggleNext}
                    closeModal={this.triggerModal}
                  />
                </div>
              )}
            </div>
          )
        }
        <FadeIn duration="1ms">
          <div
            className={
              windowSize < 992 || isMobile
                ? "main_view_container home_main_view_container_mobile"
                : "main_view_container home_main_view_container"
            }
          >
            <div
              className={
                windowSize < 992 || isMobile ? "stream_page_container_mobile" : "stream_page_container"
              }
            >
              {initialLoading && (
                <div style={{ marginTop: 20 }}>
                  <CaseStreamLoader />
                </div>
              )}

              <Feed
                triggerModal={this.triggerModal}
                onScroll={onScroll}
                showReview={this.state.showReview}
                toggleReview={this.toggleShowReview}
              />
            </div>
          </div>
          <a
            className="float_create_button"
            onClick={this.triggerModal}
            tooltip="Submit Menu"
          ></a>
        </FadeIn>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  store,
  userData: store.User,
  windowSize: store.Setting.windowSize,
  isMobile: store.Setting.isMobile,
  isFeedLoad: store.Post.isFeedLoad,
  isAllFeedLoad: store.Post.isAllFeedLoad,
  currentFeedPage: store.Post.currentFeedPage,
  feed: store.Post.feed,
  isFeedLoad: store.Post.isFeedLoad
});

export default connect(mapStateToProps)(withRouter(Home));
export { Home as PureHome };
