import React, { Component, Fragment } from 'react'
import { Me } from '../../../../utils/utils'
import Prompt from '../../../others/prompt'
import deleteIcon from 'icons/delete-user-icon.svg'

class DeleteConfirm extends Component {
  state = {
    deleteProfile: false,
  }

  delete = e => {
    e.preventDefault()
    let {
      handleDelete
    } = this.props;

    handleDelete();
  }

  modalBack = () => {
    this.props.toggleOptions()
    this.setState({ deleteProfile: false })
  }

  render() {
    let {
      user
    } = this.props

    return (
      <Fragment>
        {Me(user) && (
          <li style={{ display: 'flex' }}>
            <img src={deleteIcon} alt="delete" style={{ width: '20%' }} />
            <a href="#" onClick={this.showDeleteFileOrFolder}>
              Delete file
            </a>
          </li>
        )}
        <Prompt
          title="Delete profile"
          content="This profile will be deleted. There's no undo so you won't be able to use this account."
          actionText="Delete"
          action={this.delete}
          back={this.modalBack}
          img={deleteIcon}
          customClass="deleteAccount"
        />
      </Fragment>
    )
  }
}

export default DeleteConfirm;
