import React, { Fragment, useState } from "react";
import ModalHeader from "../../../others/modal/modal-header";
import ModalBack from "../../../others/modal/modal-back";
import PrimaryButton from "../../../others/button/primary-btn";
import SecondaryButton from "../../../others/button/secondary-btn";
import Overlay from "../../../others/overlay";
import { FadeIn } from "animate-components";
import StarRatingComponent from "react-star-rating-component";
import ClosePopupIcon from "../../../../assets/icons/close-new.svg";
import RateIcon from "../../../../assets/icons/rate.svg";

const PostRatePost = ({ rated, close, submit }) => {
  const [rate, setRate] = useState(rated || 0);
  const onStarClick = value => {
    setRate(value);
  };
  return (
    <Fragment>
      <Overlay />
      <div className="prompt rate_post_modal">
        <FadeIn duration="300ms">
          <div className="prompt-top">
            <div className="top-icon-and-text">
              <img src={RateIcon} alt="EditIcon" />
              <span className="prompt-title">Rate this post</span>
            </div>
            <img
              className="close-icon"
              src={ClosePopupIcon}
              onClick={() => close()}
            />
          </div>

          <div className="modal_middle">
            <StarRatingComponent
              starCount={5}
              value={rate}
              onStarClick={onStarClick}
            />
          </div>

          <div className="prompt-bottom">
            <SecondaryButton label="Cancel" onClick={() => close()} />
            <PrimaryButton
              label={"Rate"}
              onClick={() => submit(rate)}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </Fragment>
  );
};

export default PostRatePost;
