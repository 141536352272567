import React from 'react'
import AppLink from '../others/link/link'

const ResultUser = ({
  id,
  username,
  firstname,
  surname,
  affiliation,
  country,
}) => {
  return (
    <React.Fragment>
      <AppLink to={`/profile/${username}`}>
        <div className="userInfo">
          <div className="img_div">
            <img
              src={`/users/${id}/avatar.jpg`}
              onError={e => {
                e.target.onerror = null
                e.target.src = '/images/default-avatar.svg'
              }}
            />
          </div>
          <div className="info_detail">
            <div className="uname">
              <span className="name_details">
                {firstname} {surname}
              </span>
            </div>
            <div className="spec_and_country">
              <span>
                {affiliation}, {country}
              </span>
            </div>
          </div>
        </div>
      </AppLink>
    </React.Fragment>
  )
}

export default ResultUser
