import React from 'react'
import PropTypes from 'prop-types'
import d from '../../utils/API/DOM'

const End = ({ mssg }) => {
  let toTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className="page_end" onClick={toTop}>
      <span>{mssg}</span>
    </div>
  )
}

End.defaultProps = {
  mssg: "Looks like you've reached the end",
}

End.propTypes = {
  mssg: PropTypes.string,
}

export default End
