import React, { Component } from "react";
import PrimaryButton from "../../../others/button/primary-btn";
import { deactivatOrDeleteAccount } from "../../services/edit-profile-utils";
import { userLogOut } from "../../../../actions/user";
import Prompt from "../../../others/prompt";
import DeactivateIcon from '../../../../assets/images/deactivate.png'
export default class Deactivate extends Component {
  constructor() {
    super();
    this.state = { showConfirmationModal: false };
  }

  deactivateAccount = e => {
    e.preventDefault();
    this.setState({ showConfirmationModal: true });
  };

  confirmDeactivateAccount = (e, status) => {
    e.preventDefault();
    let {
      ud: { username: susername, email: semail }
    } = this.props;
    deactivatOrDeleteAccount({
      susername,
      semail,
      status: status
    });
    window.location.href = "/logout";
    sessionStorage.clear();
    this.props.dispatch(userLogOut());
  };

  modalBack = () => {
    this.setState({ showConfirmationModal: false });
  };
  render() {
    const { account_status } = this.props.ud;
    return (
      <React.Fragment>
        <div className="edit-tab">
          <h3 className="label-styles">
            Do you want to Deactivate this account?
          </h3>
          <PrimaryButton
            extraClass="d-button"
            onClick={e =>
              this.deactivateAccount(
                e,
                account_status === "deactive" ? "active" : "deactive"
              )
            }
            label="DEACTIVATE"
          />
        </div>
        {this.state.showConfirmationModal && (
          <Prompt
            title="Deactivate Account"
            content="Are you sure you want to deactivate account?"
            actionText="Deactivate"
            action={e =>
              this.confirmDeactivateAccount(
                e,
                account_status === "deactive" ? "active" : "deactive"
              )
            }
            back={this.modalBack}
            customClass="deactivate-account"
            img={DeactivateIcon}
          />
        )}
      </React.Fragment>
    );
  }
}
