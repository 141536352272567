import React, { Component } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import ModalItemInfo from '../../../others/modal/modal-item-info'
import Notify from 'handy-notification'

export default class ShareList extends Component {
  state = {
    didIShare: false,
  }

  share = () => {}

  render() {
    let { name, icon, link, isCopy } = this.props
    return (
      <div className="modal_items">
        {!isCopy && (
          <a href={link} target="_blank">
            <div className="modal_it_img">
              <img src={`/images/icons/settings/${icon}`} />
            </div>
            <div className="modal_it_content ">
              <ModalItemInfo info={{ link, name }} />
            </div>
          </a>
        )}

        {isCopy && (
          <CopyToClipboard
            text={link}
            onCopy={() => Notify({ value: 'Copied' })}
          >
            <a style={{ cursor: 'pointer'}}>
              <div className="modal_it_img">
                <img src={`/images/icons/settings/${icon}`} style={{ width: '28px' }}/>
              </div>
              <div className="modal_it_content ">
                <ModalItemInfo info={{ link, name }} />
              </div>
            </a>
          </CopyToClipboard>
        )}
      </div>
    )
  }
}
