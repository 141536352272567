import React from "react";
import PropTypes from "prop-types";
import OpenSeadragon from "../utils/fabricjs-overlay";
import OpenSeadragonControls from "./react-openseadragon-controls";
import "../utils/openseadragon-input-hook";
import "../utils/openseadragon-imaging-helper";
import { connect } from "react-redux";
import { SocketContext } from "../../../services/ServiceContext";
import {
  liveShareOnAction,
  liveShareUserOnAction
} from "../../../actions/viewer";
import { updateSelectedBoardLiveSync } from "../../../actions/tumorBoards";
import {
  setViewerScaleUnit,
  setViewerDefaultScaleUnit,
  setViewerScaleRatio,
  setViewerDefaultScaleRatio
} from "../../../actions/viewer";

import ViewerToolBar from "./viewer-toolbar";
import ViewerScaleBar from "./viewer-scalebar";
import { htmlDecode } from "../../../utils/utils";
import SlideSpliteSlideRemoveIcon from "../icons/minus-icon.png";

let OPENSEADRAGONVIEWER = undefined;
let OPENSEADRAGONBUTTON = undefined;

class OpenSeadragonViewer extends React.Component {
  constructor(props) {
    super(props);
    this._config = this._config.bind(this);
    this._id = this._id.bind(this);
    this._currentPageIndex = this._currentPageIndex.bind(this);
    this._osdViewer = this._osdViewer.bind(this);
    this.setStrings = this.setStrings.bind(this);
    this.setStringsItems = this.setStringsItems.bind(this);
    this.state = {
      pages: props.pages,
      osdDisplay: {},
      textDisplay: { display: "none" },
      currentRotate: 0,
      currentImageId: 0,
      mouseOverImage: false,
      mouseOverImageSet: [],
      zoomValueBar: 1,
      imageZoomValue: 0,
      WebCoordinates: { x: 0, y: 0 },
      viewPortCoordinates: { x: 0, y: 0 },
      imageCoordinates: { x: 0, y: 0 },
      curColor: "#000000",
      scaleVal: 1,
      enableFullScreenState: false,
      rotationValue: 0
    };

    this.setHomeRef = React.createRef();
    this.setFullScreenRef = React.createRef();
    this.homeButton = "reset";
    this.startPos = null;
    this.endPos = null;
    this.angleInRad = 0;
    this.microscopeMaxZoom = 20;

    this.overlay = null;
    if (
      this.props.viewerData["config"]["imageMetadata"][
        "openslide.objective-power"
      ]
    ) {
      this.microscopeMaxZoom = this.props.viewerData["config"]["imageMetadata"][
        "openslide.objective-power"
      ];
    }
  }

  static contextType = SocketContext;

  componentDidMount() {
    this.initSocketConnection();
    this.setEmptyStrings();
    this.loadCanvas();
    window.addEventListener("keydown", this.handleKeyDown);
  }

  // componentWillReceiveProps = nextProps => {
  //   if (nextProps.liveShareIsOn !== this.props.liveShareIsOn) {
  //     if (this.props.groupAdmin) {
  //       this.socketInstance.emit("viewerLiveShareOnOff", {
  //         slideId: this.props.osdConfig.slideId,
  //         when: this.props.when,
  //         status: nextProps.liveShareIsOn,
  //         featureId:
  //           this.props.when === "quorum"
  //             ? this.props.quorum_id
  //             : this.props.when === "groups"
  //             ? this.props.groupId
  //             : null
  //       });
  //     }
  //   }

  //   if (nextProps.userLiveShareOn !== this.props.userLiveShareOn) {
  //     this.socketInstance.emit("viewerLiveShareOnOffByNonAdmin", {
  //       slideId: this.props.osdConfig.slideId,
  //       when: this.props.when,
  //       status: nextProps.userLiveShareOn,
  //       featureId:
  //         this.props.when === "quorum"
  //           ? this.props.quorum_id
  //           : this.props.when === "groups"
  //           ? this.props.groupId
  //           : null
  //     });
  //   }
  // };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.viewerInstance !== this.props.viewerInstance &&
      typeof nextProps.viewerInstance !== "undefined" &&
      typeof nextProps.viewerIndex !== "undefined"
    ) {
      nextProps.viewerInstance.addHandler("zoom", event => {
        this.osdZoomHandler(event);
      });
      nextProps.viewerInstance.addHandler("rotate", event => {
        // this.osdRotateHandler(event);
      });
      this.props.setViewerObject(nextProps.viewerInstance);
      const zoom = nextProps.viewerInstance.viewport.getZoom().toFixed(3);
      const imageZoom = nextProps.viewerInstance.viewport
        .viewportToImageZoom(zoom)
        .toFixed(3);
      this.setState({
        zoomValueBar: zoom,
        imageZoomValue: imageZoom
      });
      const imageJson = {
        scale: this.props.viewerData["config"]["imageMetadata"]["width"]
      };
      this.overlay = nextProps.viewerInstance.fabricjsOverlay(imageJson);
      this.props.setOverlayInstance(this.overlay, nextProps.viewerIndex);
    }
  };

  loadCanvas = async () => {
    this.setStrings();
    if (typeof window.OpenSeadragon !== "undefined") {
      this.props.setViewerInstance(
        window.OpenSeadragon(this._config()),
        this.props.viewerIndex
      );
    } else {
      this.props.setViewerInstance(
        OpenSeadragon(this._config()),
        this.props.viewerIndex
      );
    }

    this.props.setButtonInstance(
      new OpenSeadragon.Button({
        element: this.props.setFullScreenRef.current,
        onClick: this.enableFullScreen,
        tooltip: ""
      })
    );
  };

  enableFullScreen = () => {
    this.setState({ enableFullScreenState: !this.state.enableFullScreenState });
  };

  initSocketConnection = () => {
    this.socketInstance = this.context.getSocketInstance();

    if (this.props.when === "groups") {
      const liveShareStatus =
        this.props.selectedGroupData &&
        this.props.selectedGroupData.slideLiveShareStatus
          ? true
          : false;
      const groupMemberArray =
        this.props.selectedMembers && this.props.selectedMembers.length > 0
          ? this.props.selectedMembers.filter(
              ({ id }) => id === this.props.userDetails.id
            )
          : null;
      const userSlideShare = groupMemberArray
        ? groupMemberArray[0].userLiveShareStatus
          ? true
          : false
        : false;
      this.props.liveShareOn(liveShareStatus);
      this.props.liveShareUserOn(userSlideShare);

      this.context.onViewerSyncroniztion().subscribe(data => {
        if (
          data.slideId === this.props.osdConfig.slideId &&
          data.userId !== this.props.userDetails.id &&
          this.props.userLiveShareOn
        ) {
          this.imagingHelper.setView(
            data.width,
            data.height,
            new OpenSeadragon.Point(data.centerX, data.centerY),
            false
          );
        }
      });
      this.context.onViewerSyncroniztionStart().subscribe(data => {
        if (
          data.slideId === this.props.osdConfig.slideId &&
          data.userId !== this.props.userDetails.id
        ) {
          this.props.liveShareUserOn(data.status);
        }
        this.props.updateGroupLiveSynStat(data);
      });
    }
  };

  setEmptyStrings = () => {
    OpenSeadragon.setString("Tooltips.Home", "");
    OpenSeadragon.setString("Tooltips.ZoomOut", "");
    OpenSeadragon.setString("Tooltips.ZoomIn", "");
    OpenSeadragon.setString("Tooltips.FullPage", "");
  };

  osdZoomHandler = event => {
    if (this.props.selectedViewer !== this.props.viewerIndex) {
      return false;
    }
    const defZoom = this.props.viewerInstance.viewport.getHomeZoom();
    const maxZoom = this.props.viewerInstance.viewport.getMaxZoom();
    const zoom = (event.zoom * this.microscopeMaxZoom) / maxZoom;
    let currentScale =
      ((this.props.viewerData["config"]["imageMetadata"]["aperio.MPP"] *
        maxZoom) /
        event.zoom) *
      100;
    if (currentScale > 1000) {
      currentScale /= 1000;
      currentScale = currentScale.toFixed(2);
      this.props.setScaleUnit("mm");
      this.props.setScaleRatio(
        this.props.viewerData["config"]["imageMetadata"]["aperio.MPP"] * 0.1
      );
    } else if (Number.isNaN(currentScale)) {
      this.props.setDefaultScaleUnit("cm");
      this.props.setDefaultScaleRatio();
    } else {
      currentScale = currentScale.toFixed(2);
      this.props.setScaleUnit(htmlDecode("&#181;m"));
      this.props.setScaleRatio(
        this.props.viewerData["config"]["imageMetadata"]["aperio.MPP"] * 0.1
      );
    }

    this.setState({
      zoomValueBar: zoom.toFixed(3),
      scaleVal: currentScale
    });
    this.forceUpdate();
    if (typeof this.overlay.fabricCanvas() !== "undefined" && this.overlay) {
      this.overlay
        .fabricCanvas()
        .getObjects()
        .some(obj => {
          if (obj.type !== "path") {
            obj.strokeWidth = 80 / this.state.zoomValueBar;
          }
        });
    }
  };

  rotateImageHandler = val => {
    OPENSEADRAGONVIEWER.viewport.setRotation(val);
    this.angleInRad = (val * Math.PI) / 180;
    this.setState({ rotationValue: val });
  };

  osdRotateHandler = event => {
    var objs = this.overlay.fabricCanvas().getObjects();
    for (var i = 0; i < objs.length; i++) {
      // console.log(objs[i].type, event.degrees )
      if (objs[i].type == "rect") {
        if (objs[i].centeredRotation) {
          objs[i].centeredRotation = false;
        }
        objs[i].rotate(event.degrees);
      }
    }
  };

  handleOsdFilters = (val, filterType) => {
    switch (filterType) {
      case "brightness":
        this.props.viewerInstance.setFilterOptions({
          filters: {
            processors: OpenSeadragon.Filters.BRIGHTNESS(val)
          }
        });
        break;
      case "saturation":
        break;
      case "contrast":
        this.props.viewerInstance.setFilterOptions({
          filters: {
            processors: OpenSeadragon.Filters.CONTRAST(val / 10)
          }
        });
        break;
      case "hue":
        break;
    }
  };

  onImageViewChanged(event) {
    if (this.props.liveShareIsOn) {
      if (this.props.groupAdmin) {
        this.socketInstance.emit("viewerLiveShareData", {
          width: event.viewportWidth,
          height: event.viewportHeight,
          centerX: event.viewportCenter.x,
          centerY: event.viewportCenter.y,
          zoomFactor: event.zoomFactor,
          slideId: this.props.osdConfig.slideId,
          featureId:
            this.props.when === "quorum"
              ? this.props.quorum_id
              : this.props.when === "groups"
              ? this.props.groupId
              : null
        });
      }
    }
  }

  setStringsItems() {
    return typeof this.props.osdConfig.setStrings !== "undefined"
      ? this.props.osdConfig.setStrings
      : [];
  }

  setStrings() {
    this.setStringsItems().map(item =>
      OpenSeadragon.setString(item.name, `${item.value}`)
    );
  }

  _currentPageIndex() {
    let urlParts = window.location.href.split("/");
    return parseInt(
      urlParts
        .map((part, i) => {
          if (part === "image") {
            return urlParts[i + 1];
          }
          return "";
        })
        .join("")
        .trim(),
      [0],
      10
    );
  }

  _currentPage() {
    return this.state.pages[this._currentPageIndex()];
  }

  _config() {
    return Object.assign(this.props.viewerData["config"]["osdConfig"], {
      id:
        this.props.when === "central_feed"
          ? this.props.viewerId
          : this.props.viewerData["id"]
    });
  }

  _id() {
    return parseInt(this.props.match.params.id, 10);
  }

  _osdViewer() {
    const {
      when,
      viewerId,
      viewerIndex,
      selectedViewer,
      viewerData,
      viewerDataArray
    } = this.props;
    const style = {
      height: "100%"
    };
    return (
      // <div
      //   className={
      //     when === "quorum" || when === "tumor_board"
      //       ? "viewer-container-other"
      //       : "viewer-container"
      //   }
      //   style={
      //     when === "groups" &&
      //     userDetails.id === selectedGroupData.slideLiveSharePerson
      //       ? liveShareIsOn
      //         ? { border: "5px solid #2dbb34" }
      //         : { border: "5px solid #FF000000" }
      //       : userLiveShareOn && selectedGroupData.slideLiveShareStatus
      //       ? { border: "5px solid #2dbb34" }
      //       : { border: "5px solid #FF000000" }
      //   }
      // >
      // <div className="viewer">
      <div
        key={viewerData.id}
        className={
          viewerDataArray.length === 1
            ? "openseadragon container-viewer"
            : selectedViewer === viewerIndex
            ? "openseadragon container-viewer split selected"
            : "openseadragon container-viewer split"
        }
        style={style}
        id={when === "central_feed" ? viewerId : viewerData.id}
        onClick={() => this.props.viewerOnClick(this.props.viewerIndex)}
      >
        {viewerDataArray.length !== 1 && (
          <div className="viewer-toolbar-mini-container">
            <ul>
              <li
                id="remove-split-view"
                className={"nonactive-tool remove-slide-li"}
                data-for="viewer-toolbar"
                data-tip={"Remove slide"}
                onClick={e =>
                  this.props.removeSplitSlide(e, this.props.viewerIndex)
                }
              >
                <img src={SlideSpliteSlideRemoveIcon} />
              </li>
            </ul>
          </div>
        )}
        <ViewerScaleBar
          OPENSEADRAGONVIEWER={this.props.viewerInstance}
          imageMetadata={this.props.viewerData["config"]["imageMetadata"]}
          scaleVal={this.state.scaleVal}
          overlay={this.overlay}
          zoomValueBar={this.state.zoomValueBar}
        />
      </div>
      //   </div>
      //  </div>
    );
  }

  render() {
    if (typeof this.props.viewerInstance !== "undefined") {
      this.props.viewerInstance.goToPage(this.props.currentPageId);
    }
    return (
      <React.Fragment>
        {/* {this.props.when !== "central_feed" && (
          <ViewerScaleBar
            OPENSEADRAGONVIEWER={this.props.viewerInstance}
            imageMetadata={this.props.viewerData["config"]["imageMetadata"]}
            scaleVal={this.state.scaleVal}
            overlay={this.overlay}
            zoomValueBar={this.state.zoomValueBar}
          />
        )} */}
        {this._osdViewer()}
      </React.Fragment>
    );
  }
}

OpenSeadragonViewer.defaultProps = {
  viewSearchText: "",
  osdConfig: {
    defaultZoomLevel: 0,
    tileSources: [],
    setStrings: [],
    visibilityRatio: 1.0,
    constrainDuringPan: true
  }
};

OpenSeadragonViewer.propTypes = {
  osdConfig: PropTypes.shape({
    defaultZoomLevel: PropTypes.number,
    tileSources: PropTypes.arrayOf(PropTypes.string),
    setStrings: PropTypes.arrayOf(PropTypes.object),
    visibilityRatio: 1.0,
    constrainDuringPan: true
  }),
  viewSearchText: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  liveShareOn: payload => dispatch(liveShareOnAction(payload)),
  liveShareUserOn: payload => dispatch(liveShareUserOnAction(payload)),
  updateGroupLiveSynStat: payload =>
    dispatch(updateSelectedBoardLiveSync(payload)),
  setScaleUnit: payload => dispatch(setViewerScaleUnit(payload)),
  setDefaultScaleUnit: () => dispatch(setViewerDefaultScaleUnit()),
  setScaleRatio: payload => dispatch(setViewerScaleRatio(payload)),
  setDefaultScaleRatio: () => dispatch(setViewerDefaultScaleRatio())
});

const mapStateToProps = state => ({
  userDetails: state.User.user_details,
  windowSize: state.Setting.windowSize,
  isMobile: state.Setting.isMobile,
  liveShareIsOn: state.Viewer.liveShareOn,
  selectedGroupData: state.TumorBoards.selectedBoard,
  userLiveShareOn: state.Viewer.userLiveShareOn,
  selectedMembers: state.TumorBoards.selectedMembers,
  scaleUnit: state.Viewer.scaleUnit,
  viewerDataArray: state.Viewer.viewerDataArray
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenSeadragonViewer);
