import React, { useState, useEffect, useContext } from "react";
import SlideboxPage from "../../slidebox-component/slidebox-page";
import { connect } from "react-redux";
import Viewer from "../../image-viewer/ImageViewer";
import ReactTooltip from "react-tooltip";
import { SocketContext } from "../../../services/ServiceContext";
import PrimaryButton from "../../others/button/primary-btn";
import plusSrc from "../icons/add.svg";
import closeIcon from "../../../assets/icons/close-new.svg";
import AddSlide from "../icons/add-from-slidebox.svg";

const styles = {
  disabledButton: {
    color: "#999",
    cursor: "not-allowed",
    background: "none",
    border: "1px solid #0b569f",
    marginTop: "40%"
  }
};

const ImagePanel = ({
  openSlidebox,
  setOpenSlidebox,
  imageFromSlideBox,
  group,
  onGroupRefresh
}) => {
  const [slideBoxId, setSlideBoxId] = useState(null);
  const [isFolderSelected, setIsFolderSelected] = useState(true);

  const socket = useContext(SocketContext);

  const toggleModal = e => {
    const overlay = document.getElementById("quorum-modal-overlay");
    if (
      e == "CLOSE MODAL" ||
      e.target == overlay ||
      e.target.classList.contains("slidebox-next-page-btn") ||
      e.target.id == "close_modal_streams"
    ) {
      setOpenSlidebox(false);
    } else {
      setOpenSlidebox(true);
    }
  };

  useEffect(() => {
    socket.onUpdateBoard().subscribe(id => {
      if (group.id === parseInt(id)) {
        onGroupRefresh();
      }
    });
  }, []);

  useEffect(() => {
    const slideBoxId =
      Object.keys(imageFromSlideBox).length > 0
        ? imageFromSlideBox.fileid
        : group.tumorBoardSlideId;
    setSlideBoxId(slideBoxId);
  }, [imageFromSlideBox, group]);

  const changeThumbnailSlide = newSlideId => {
    setSlideBoxId(newSlideId);
  };

  return (
    <div className="image-panel">
      <div className="content">
        <div className="quorum-action-main-container">
          {group &&
          (Object.keys(imageFromSlideBox).length > 0 || group.slide) ? (
            <div className="group-viewer-container">
              {slideBoxId && (
                <Viewer
                  when={"groups"}
                  slideBoxId={slideBoxId}
                  groupId={group.id}
                  groupAdmin={group.admin}
                  changeThumbnailSlide={changeThumbnailSlide}
                  tumorBoardSlideId={group.tumorBoardSlideId}
                />
              )}
            </div>
          ) : (
            <div
              className="add_from_quorum_btn"
              data-tip={
                group.admin
                  ? null
                  : "This function is available only to group admins"
              }
            >
              <PrimaryButton
                img={plusSrc}
                label="Add from Slidebox"
                onClick={group.admin ? toggleModal : e => {}}
                disabled={!group.admin}
              />
            </div>
          )}
          {openSlidebox ? (
            <div
              className="overlay-quorum"
              id="quorum-modal-overlay"
              onClick={toggleModal}
            >
              <div className="modal-slidebox">
                <div className="header-modal">
                  <div className="left">
                    <img src={AddSlide} alt="AddSlide" />
                    <h3 className="add-from">Add from Slidebox</h3>
                  </div>
                  <img
                    src={closeIcon}
                    id="close_modal_streams"
                    style={{ cursor: "pointer" }}
                    className="close-icon"
                  />
                </div>
                <div
                  className="modal_middle"
                  onContextMenu={() => {
                    return false;
                  }}
                >
                  <SlideboxPage
                    forTumor={true}
                    closeModal={toggleModal}
                    tumorBoardId={group.id}
                    showShared={false}
                    isFolderSelected={isFolderSelected}
                    setIsFolderSelected={setIsFolderSelected}
                    when="groups"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <ReactTooltip />
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  isImageLoaded: state.SlideBox.isQuorumLoaded,
  imageFromSlideBox: state.SlideBox.quorumImageFromSlideBox || ""
});

export default connect(mapStateToProps)(ImagePanel);
