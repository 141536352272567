import React, { Component, Fragment } from "react";
import { isAdmin } from "../../../../../utils/admin-utils";
import Prompt from "../../../../others/prompt";
import {
  deleteComment,
  deleteReply,
  deleteCommentFeed,
  deleteReplyFeed
} from "../../../../../actions/post";
import Notify from "handy-notification";
import axios from "../../../../../utils/axios-instance-utils";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DeleteIcon from '../../../../../assets/icons/delete-icon.svg';

class DeleteCommentOption extends Component {
  state = {
    deleteComment: false
  };

  showPrompt = e => {
    e.preventDefault();
    this.setState({ deleteComment: !this.state.editComment });
  };

  deleteComment = async e => {
    e.preventDefault();
    let {
      commentDetails: { comment_id, type, imgCommentSrc, post_id, reply_id },
      dispatch,
      decrementComments,
      when,
      isReply
    } = this.props;

    if (isReply) {
      await axios.post("/api/delete-reply", {
        reply_id,
        type,
        imgReplySrc: imgCommentSrc
      });
    } else {
      await axios.post("/api/delete-comment", {
        comment_id,
        type,
        imgCommentSrc
      });
    }
    if (when == "feed" && isReply) {
      this.modalBack();
      dispatch(deleteReplyFeed({ post_id, comment_id, reply_id }));
    } else if (when === "feed") {
      this.modalBack();
      dispatch(deleteCommentFeed({ post_id, comment_id }));
      decrementComments();
    } else {
      this.modalBack();
      if (isReply) {
        dispatch(deleteComment(comment_id));
        dispatch(deleteReply({ comment_id, reply_id }));
      } else {
        dispatch(deleteComment(comment_id));
        decrementComments();
      }
    }

    Notify({ value: "Comment deleted" });
  };

  modalBack = () => {
    this.props.toggleOptions();
    this.setState({ deleteComment: false });
  };

  render() {
    let { deleteComment } = this.state;

    return (
      <Fragment>
        <li>
          <a href="#" onClick={this.showPrompt}>{`Delete${isAdmin() ? "as admin" : ""
            }`}</a>
        </li>

        {deleteComment && (
          <Prompt
            title={`Delete ${this.props.isReply ? "reply" : "comment"}`}
            content={`This ${this.props.isReply ? "reply" : "comment"
              }  will be deleted. There's no undo so you won't be able to find it.`}
            actionText="Delete"
            action={this.deleteComment}
            back={this.modalBack}
            img={DeleteIcon}
            alt="Delete"
          />
        )}
      </Fragment>
    );
  }
}

DeleteCommentOption.propTypes = {
  commentDetails: PropTypes.shape({
    comment_id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    imgCommentSrc: PropTypes.string.isRequired
  }).isRequired,
  decrementComments: PropTypes.func.isRequired,
  toggleOptions: PropTypes.func.isRequired
};

export default connect()(DeleteCommentOption);
export { DeleteCommentOption as PureDeleteCommentOption };
