import React, { Component } from "react";

import DiamondIcon from "../../../assets/icons/diamond.svg";
import EventIcon from "../../../assets/icons/event.svg";
import CalenderIcon from "../../../assets/icons/calendar.svg";

export default class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = { imgSrc: "/images/default-avatar.svg" };
  }
  componentDidMount() {
    this.setState({ imgSrc: `/users/${this.props.ud.id}/avatar.jpg` });
  }

  render() {
    const { speciality, about, affiliation, role, joined } = this.props;
    const date = new Date(Number(joined));
    const dateParts = date.toString().split(" ");
    const joining_date =
      dateParts[1] + " " + dateParts[2] + ", " + dateParts[3];
    return (
      <div>
        <div className="top">
          <div className="top-part">
            <img
              src={this.state.imgSrc}
              className="user-img"
              onError={e => {
                e.target.onerror = null;
                e.target.src = "/images/default-avatar.svg";
              }}
            />
            <div className="user-segment">
              <div className="user-data">
                <h2 className="username">
                  {this.props.ud.firstname} {this.props.ud.surname}
                </h2>

                <div className="joined_on sub-data">
                  <img src={CalenderIcon} alt="calender icon" />
                  <span>Joined on {joining_date}</span>
                </div>
              </div>
              {this.props.ExtraTopSection}
            </div>
            {this.props.ChangeAvatarSection}
            <div className="top-right">{this.props.TopRightSection}</div>
          </div>
        </div>
        <div className="bottom">
          <div className="bottom-left">
            <div className="info-box about-section">
              <span className="heading">About</span>
              {about ? (
                <React.Fragment>
                  <br />
                  <span className="text about-text">{about}</span>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            <div className="info-box">
              <span className="heading">Affiliation</span>
              <br />
              <span className="text">{affiliation}</span>
            </div>
            <div className="info-box">
              <span className="heading">Role</span>
              <br />
              <span className="text">{role}</span>
            </div>
            <div className="info-box" id="final">
              <span className="heading">Specialty</span>
              <br />
              <span className="text">{speciality}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
