// FOR LOGGEDIN USER
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import App from "./components/App";
import { SocketService } from "./services/SocketService";
import { SocketContext } from "./services/ServiceContext";
import { PersistGate } from "redux-persist/integration/react";
import { FeatureProvider } from "react-feature-gate";

let element = document.getElementById("root");
const service = new SocketService();
const socket = service.init();

const features = {
  quorum: false
};

if (process.env.NODE_ENV === "production") {
  console.log = function() {};
}

if (element) {
  ReactDOM.render(
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <FeatureProvider features={features}>
            <App />
          </FeatureProvider>
        </PersistGate>
      </Provider>
    </SocketContext.Provider>,
    element
  );
} else {
  // USER SYSTEM (FOR NOT-LOGGEDIN USER)
  require("./user-system/user-system");
}
