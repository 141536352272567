import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Search extends Component {
  constructor(props) {
    super(props)
    this.setSearchRef = React.createRef()
  }

  componentDidMount() {
    this.setSearchRef.current.addEventListener(
      'keydown',
      this.handleSearchKeyDown
    )
  }

  componentWillUnmount() {
    this.setSearchRef.current.removeEventListener(
      'keydown',
      this.handleSearchKeyDown
    )
  }

  handleSearchKeyDown = e => {
    const {
      target: { value },
    } = e
    if (e.keyCode == 13) {
      this.search(value)
    }
  }

  search = value => {
    if (value.trim() != '') {
      this.props.history.push({
        pathname: '/search',
        search: `?text=${value}`,
      })

      // this.setSearchRef.current.value = ''
    } else {
      return false
    }
  }

  // clicked = () => {
  //   this.setState({ value: "" });
  //   this.hide();
  // };

  // hide = () => {
  //   this.setState({
  //     search: {
  //       users: [],
  //       hashtags: []
  //     }
  //   });
  // };

  render() {
    const { activeRoute } = this.props
    return (
      <React.Fragment>
        <div
          className="search_box"
          style={
            activeRoute === 'search'
              ? { display: 'none' }
              : { display: 'block' }
          }
        >
          <input
            className="search"
            type="text"
            placeholder="Search..."
            spellCheck="false"
            autoComplete="false"
            ref={this.setSearchRef}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Search)
