import * as fn from "../utils/utils";
import * as User from "../utils/user-system-utils";
import Notify from "handy-notification";
import axios from "../utils/axios-instance-utils";
import d from "../utils/API/DOM";
import { quickLogin } from "../utils/quick-login-utils";
import Croppie from "croppie";
import { imageCompressor } from "../utils/utils";
import ClientOAuth2 from "client-oauth2";

let auth2 = {};
let profileImage;

const linedinAuth = new ClientOAuth2({
  grantType: "client_credentials",
  clientId: "81v1mac3sm7nuk",
  clientSecret: "eAE6mD7kJL7BH0qt",
  authorizationUri: "https://www.linkedin.com/oauth/v2/authorization",
  accessTokenUri: "https://www.linkedin.com/oauth/v2/accessToken",
  redirectUri: "https://accounts.crosscope.com/linkedinSignIn",
  scopes: ["r_liteprofile", "r_emailaddress"],
  state: "12345",
  debugHttp: 1,
  debug: 1
});

const linedinSignUp = new ClientOAuth2({
  grantType: "client_credentials",
  clientId: "81v1mac3sm7nuk",
  clientSecret: "eAE6mD7kJL7BH0qt",
  authorizationUri: "https://www.linkedin.com/oauth/v2/authorization",
  accessTokenUri: "https://www.linkedin.com/oauth/v2/accessToken",
  redirectUri: "https://accounts.crosscope.com/linkedinSignUp",
  scopes: ["r_liteprofile", "r_emailaddress"],
  state: "12345",
  debugHttp: 1,
  debug: 1
});

(async function() {
  let signInError = new d(".login-container").getAttr("data-signinerror");
  if (signInError) {
    Notify({ value: signInError });
  }
  await gapi.load("auth2", function() {
    auth2 = gapi.auth2.init({
      client_id:
        "654838866445-c74ksluiu4h7iummdpijfpa6q3o25jof.apps.googleusercontent.com",
      cookiepolicy: "single_host_origin"
    });
    attachSignin(document.getElementById("google-sign-in"));
  });
})();

new d(".nh_logo").on("click", () => (location.href = "/"));

// View Password for signup
new d(".s_p_s").on("click", () =>
  fn.viewPassword({
    input: "#s_password",
    icon: ".s_p_s"
  })
);

// View Password for login
new d(".s_p_l").on("click", () =>
  fn.viewPassword({
    input: "#l_password",
    icon: ".s_p_l"
  })
);

// // Filtering illegal characters
fn.replacer(".s_username", "normal");
fn.replacer(".s_firstname", "normal");
fn.replacer(".s_surname", "normal");

// Username checker
User.username_checker(".s_username");

// User Signup
new d("form.form_register").on("submit", e => {
  e.preventDefault();
  new d(".s_submit").setAttr("disabled", true);

  let firstname = new d(".s_firstname").val(),
    surname = new d(".s_surname").val(),
    email = new d(".s_email").val(),
    password = new d(".s_password").val(),
    cpassword = new d(".s_cpassword").val();

  if (!firstname || !surname || !email) {
    new d(".s_submit").removeAttr("disabled");
    Notify({ value: "Values are missing" });
  } else if (password !== cpassword) {
    new d(".s_submit").removeAttr("disabled");
    Notify({ value: "Password and Confirm Password different" });
  } else {
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.length >= 6
    ) {
      const sessData = {
        firstname,
        surname,
        email,
        isSocialSignUp: false
      };
      let signupOpt = {
        data: {
          firstname,
          surname,
          email,
          password,
          isSocialSignUp: false
        },
        when: "signup",
        btn: ".s_submit",
        url: "/user/signup",
        redirect: "/create-profile",
        defBtnValue: "Sign Up"
      };
      sessionStorage.setItem("socialLoginData", JSON.stringify(sessData));
      User.commonLogin(signupOpt);
    } else {
      new d("#s_password").addClass("s_password_error");
    }
  }
});

document.getElementById("s_password") &&
  document.getElementById("s_password").addEventListener("input", function() {
    const password = new d(".s_password").val();
    const text = new d("#password-strength-text");
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.length >= 6
    ) {
      text.html("");
    } else {
      let errorMsg = "";
      if (!password.match(/[A-Z]/g) || !password.match(/[0-9]/g)) {
        if (!password.match(/[A-Z]/g) && password.match(/[0-9]/g)) {
          errorMsg = "Password must contain at least one uppercase";
        }

        if (!password.match(/[0-9]/g) && password.match(/[A-Z]/g)) {
          errorMsg = "Password must contain at least one number";
        }

        if (!password.match(/[A-Z]/g) && !password.match(/[0-9]/g)) {
          errorMsg =
            "Password must contain at least one uppercase and one number";
        }
      } else {
        if (!password.match(/[a-z]/g)) {
          errorMsg = "Password must contain lowercase";
        }
        if (password.length < 6) {
          errorMsg = "Password must be at least 6 characters long";
        }
      }

      text.html(`<p style="color:red; font-size: 12px;">${errorMsg}</p>`);
    }
  });

//User Signup profile creation
new d(".s_p_submit").on("click", e => {
  e.preventDefault();
  new d(".s_p_submit").setAttr("disabled", true);
  let isSocialSignUp = null;
  let firstname = null;
  let surname = null;
  let email = null;
  if (sessionStorage.getItem("socialLoginData")) {
    const data = JSON.parse(sessionStorage.getItem("socialLoginData"));
    isSocialSignUp = data.isSocialSignUp;
    firstname = data.firstname;
    email = data.email;
    surname = data.surname;
  } else {
    isSocialSignUp = true;
    firstname = new d("#first_name").val();
    surname = new d("#surname").val();
    email = new d("#email").val();
  }

  const setProfileImage = sessionStorage.getItem("setProfileImage");
  let username = new d(".s_username").val(),
    user_id = sessionStorage.getItem("signupId"),
    affiliation = new d(".s_affiliation").val(),
    role = new d(".s_role").val(),
    speciality = new d(".s_speciality").val(),
    country = new d("#country").val();

  let userCheckerDiv = document.getElementById("username_checker");
  let isValidUserName = userCheckerDiv
    ? userCheckerDiv.getAttribute("data-valid")
    : null;

  if (isValidUserName === "invalid") {
    new d(".s_p_submit").removeAttr("disabled");
    Notify({ value: "Username already taken" });
    return false;
  }

  if (
    !username ||
    !firstname ||
    !surname ||
    !email ||
    !affiliation ||
    !speciality ||
    !role ||
    !country
  ) {
    new d(".s_p_submit").removeAttr("disabled");
    if (!username) {
      Notify({ value: "Username is missing" });
      return false;
    }
    if (!firstname) {
      Notify({ value: "Firstname is missing" });
      return false;
    }

    if (!surname) {
      Notify({ value: "Surname is missing" });
      return false;
    }

    if (!email) {
      Notify({ value: "Email is missing" });
      return false;
    }

    if (!affiliation) {
      Notify({ value: "Affiliation is missing" });
      return false;
    }

    if (!speciality) {
      Notify({ value: "Specialty is missing" });
      return false;
    }

    if (!role) {
      Notify({ value: "Role is missing" });
      return false;
    }

    if (!country) {
      Notify({ value: "Country is missing" });
      return false;
    }
  } else {
    let signupOpt = {
      data: {
        username,
        user_id,
        affiliation,
        role,
        speciality,
        country,
        isSocialSignUp,
        firstname,
        email,
        setProfileImage
      },
      when: "create_profile",
      btn: ".s_p_submit",
      url: "/user/create_profile",
      redirect: isSocialSignUp ? "/" : "/registered",
      defBtnValue: "Save & Continue"
    };
    User.commonLogin(signupOpt);
  }
});

// User login
new d(".l_submit").on("click", e => {
  e.preventDefault();
  login();
});
window.onload = event => {
  if (localStorage.getItem("username") && localStorage.getItem("password")) {
    document.getElementById("l_username").value = localStorage.getItem(
      "username"
    );
    document.getElementById("l_password").value = localStorage.getItem(
      "password"
    );
  }
};
new d(".l_password").on("keydown", e => {
  if (event.keyCode === 13) {
    event.preventDefault();
    login();
  }
});

// QUICK LOGIN
let allQL = Array.from(new d(".q_l_div").toAll());
for (let elem of allQL) {
  elem.addEventListener("click", e => {
    let { id, username } = e.currentTarget.dataset;
    quickLogin({ id, username });
  });
}

// CLOSE QUICK MODAL
new d(".q_l_m_cancel").on("click", () => {
  new d(".overlay-2-black").hide();
  new d("#q_l_password").setValue("");
  new d(".q_l_model").hide();
});

// CLEAR ALL QUICK LOGINS
new d(".clear_all_ql").on("click", async e => {
  e.preventDefault();
  await axios.post("/api/clear-all-quick-logins");
  Notify({
    value: "Cleared all quick logins",
    done: () => location.reload()
  });
});

// FORGOT PASSWORD
new d("form.form_fp").on("submit", async e => {
  e.preventDefault();
  let email = new d(".fp_email").val();

  if (!email) {
    Notify({ value: "Email field is empty" });
  } else {
    let fpOpt = {
      data: { email },
      when: "forgot_password",
      btn: ".fp_submit",
      url: "/user/password-retrieve",
      redirect: "/forgot-password",
      defBtnValue: "Retrieve"
    };
    User.commonLogin(fpOpt);
  }
});

new d("form.form_fp_reset").on("submit", async e => {
  e.preventDefault();
  const password = new d(".s_password").val();
  const cpassword = new d(".s_cpassword").val();
  const token = window.location.search.split("=")[1];

  if (password !== cpassword) {
    Notify({ value: "Password and confirm not match" });
  } else {
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.length >= 6
    ) {
      let fpOpt = {
        data: { password, cpassword, token },
        when: "reset_password",
        btn: ".fp_submit",
        url: "/user/reset-password-api",
        redirect: "/login",
        defBtnValue: "Reset"
      };
      User.commonLogin(fpOpt);
    } else {
      new d("#s_password").addClass("s_password_error");
    }
  }
});

new d(".outside_post").on("load", e => {
  e.preventDefault();
  $("#outsidePostSignupshow").modal("show");
});

new d(".s_register_next").on("click", e => {
  e.preventDefault();
  clearSessions();
  window.location.href = "/";
});

// SOCIAL SIGNIN
new d("#facebook-sign-in").on("click", async e => {
  e.preventDefault();
  if (location.pathname === "/login" || location.pathname === "/signup") {
    if (location.pathname === "/login") {
      window.open(linedinAuth.code.getUri(), "_self");
    }
    if (location.pathname === "/signup") {
      window.open(linedinSignUp.code.getUri(), "_self");
    }
  }
});

function login() {
  let username = new d(".l_username").val();
  let password = new d(".l_password").val();
  let redirectUrl = new d(".login-container").getAttr("data-redirect");
  if (!username || !password) {
    Notify({ value: "Values are missing" });
  } else {
    if (document.getElementById("l_remember").checked) {
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
    }
    let loginOpt = {
      data: {
        username,
        password
      },
      when: "login",
      btn: ".l_submit",
      url: "/user/login",
      redirect: redirectUrl ? redirectUrl : "/",
      defBtnValue: "Login To Continue"
    };
    User.commonLogin(loginOpt);
  }
}

new d("#google-sign-in").on("click", e => {
  e.preventDefault();
});

new d("#profile_file_input").on("change", function(e) {
  e.preventDefault();
  new d(".image-preview-area").addClass("hide");
  new d(".image-upload-area").removeClass("hide");
  readFile(e);
});

new d("#s_p_image_upload").on("click", function(e) {
  e.preventDefault();
  if (profileImage) {
    new d("#s_p_image_upload").setAttr("disabled", true);
    profileImage.result("blob").then(function(blobData) {
      const options = {
        file: blobData,
        user_id: sessionStorage.getItem("signupId")
      };
      upload_avatar(options);
    });
  } else {
    Notify({ value: "Please select profile image" });
  }
});

new d("#s_p_image_select").on("click", function(e) {
  e.preventDefault();
  document.getElementById("profile_file_input").click();
});

new d("#s_p_image_change").on("click", function(e) {
  e.preventDefault();
  document.getElementById("profile_file_input").click();
});

function attachSignin(element) {
  if (location.pathname === "/login" || location.pathname === "/signup") {
    auth2.attachClickHandler(element, {}, function(googleUser) {
      const profile = googleUser.getBasicProfile();
      if (location.pathname === "/signup") {
        let signupOpt = {
          data: {
            firstname: profile.getGivenName(),
            surname: profile.getFamilyName(),
            email: profile.getEmail(),
            fullname: profile.getName(),
            isSocialSignUp: true
          },
          when: "signup",
          btn: ".s_submit",
          url: "/user/signup",
          redirect: "/create-profile",
          defBtnValue: "Sign Up"
        };
        sessionStorage.setItem(
          "socialLoginData",
          JSON.stringify(signupOpt.data)
        );
        User.commonLogin(signupOpt);
      } else {
        let loginOpt = {
          data: {
            firstname: profile.getGivenName(),
            surname: profile.getFamilyName(),
            email: profile.getEmail(),
            fullname: profile.getName(),
            isSocialSignUp: true
          },
          when: "login",
          btn: ".l_submit",
          url: "/user/social-login",
          redirect: "/",
          defBtnValue: "Login To Continue"
        };
        User.commonLogin(loginOpt);
      }
    });
  }
}

function readFile({ target }) {
  if (typeof profileImage === "undefined") {
    profileImage = new Croppie(
      document.getElementById("create-profile-image"),
      {
        viewport: { width: 150, height: 150, type: "circle" },
        boundary: { width: 240, height: 240 },
        showZoomer: true,
        enableOrientation: true
      }
    );
  }
  let input = target;
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
      profileImage.bind({ url: e.target.result });
    };
    new d(".s_p_select").addClass("hide");
    new d("#s_p_image_change").addClass("hide");
    new d(".s_p_upload").removeClass("hide");
    reader.readAsDataURL(input.files[0]);
    target.value = null;
  } else {
    Notify({
      value: "Sorry - youre browser doesn't support the FileReader API"
    });
  }
}

const upload_avatar = async options => {
  let { file: userFile, user_id } = options,
    form = new FormData(),
    file = await imageCompressor(userFile);
  if (file.size > 6000000) {
    Notify({ value: "Image should be less than 4MB" });
  } else {
    form.append("avatar", file);
    form.append("user_id", user_id);
    let {
      data: { success, mssg }
    } = await axios.post("/api/upload-profile-avatar", form);

    Notify({
      value: mssg,
      done: () => (success ? showPreview(user_id, file) : null)
    });
  }
};

const showPreview = (user_id, file) => {
  sessionStorage.setItem("setProfileImage", true);
  let objectURL = URL.createObjectURL(file);
  new d(".image-upload-area").addClass("hide");
  new d("#s_p_image_upload").addClass("hide");
  new d("#avatar-image-tag").setAttr("src", objectURL);
  new d(".image-preview-area").removeClass("hide");
  new d("#s_p_image_change").removeClass("hide");
  new d("#s_p_image_upload").removeAttr("disabled");
};

const clearSessions = () => {
  sessionStorage.removeItem("setProfileImage");
  sessionStorage.removeItem("socialLoginData");
  sessionStorage.removeItem("signupId");
};

const addElement = (parentId, elementTag, elementId, html) => {
  // Adds an element to the document
  var p = document.getElementById(parentId);
  var newElement = document.createElement(elementTag);
  newElement.setAttribute("id", elementId);
  newElement.innerHTML = html;
  p.appendChild(newElement);
};
