import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import commentIcon from '../icons/speech-bubbles.svg'
class PostLike extends Component {
  state = {
    liked: false,
  }

  focusComment = e => {
    const { commentBoxId } = this.props
    e.preventDefault()
    document.getElementById(commentBoxId).focus()
  }

  loadQuorum = e => {
    const {
      postDetails: { postid_token },
    } = this.props
    this.props.history.push(`quorum/${postid_token}`)
  }

  render() {
    const { isMobile, isQuorum } = this.props
    return (
      <Fragment>
        <div className="p_Like_wra">
          <span
            className="p_comment p_comment_icon"
            data-tip={isMobile ? '' : isQuorum ? 'Join' : 'Comment'}
            onClick={!isQuorum ? this.focusComment : this.loadQuorum}
          >
            {!isQuorum ? (
              <img
                src={commentIcon}
                style={
                  !isMobile
                    ? { display: 'inline-block', height: '35px' }
                    : { display: 'inline-block', height: 20 }
                }
              />
            ) : (
              <i
                class="custom-icon-quorum"
                aria-hidden="true"
                style={{ display: 'inline-block', fontSize: '24px' }}
              ></i>
            )}
          </span>
        </div>
      </Fragment>
    )
  }
}

PostLike.propTypes = {
  postDetails: PropTypes.shape({
    post_id: PropTypes.number.isRequired,
    user: PropTypes.number.isRequired,
  }).isRequired,
  incrementWhat: PropTypes.func.isRequired,
  decrementWhat: PropTypes.func.isRequired,
}

export default withRouter(PostLike)
