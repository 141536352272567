import React, { useState } from "react";
import { connect } from "react-redux";
import AddUserIcon from "../icons/add-new-member-icon.svg";
import AddImageIcon from "../icons/add-icon-new.svg";
import ThreeDotsIcon from "../icons/three-dots-icon-new.svg";
import ClosePopupIcon from "../../../assets/icons/close-new.svg";
import Fade from "../../animations/Fade";
import MemberInvitation from "../../tumor-boards/new-components/member-invitation";
import User from "../../tumor-boards/new-components/user";
import Notify from "handy-notification";
import axios from "../../../utils/axios-instance-utils";
import ToolTip from "react-tooltip";
import PrimaryButton from "../../others/button/primary-btn";
import SecondaryButton from "../../others/button/secondary-btn";
import Overlay from "../../others/overlay";

const ChatHeader = ({
  group,
  groupMembers,
  onChangeActiveTab,
  loggedUser,
  onOpenSlidebox,
  isPublicGroup
}) => {
  const [openInvitation, setOpenInvitation] = useState(false);
  const [members, setMembers] = useState([]);
  const addDefaultSrc = ev => {
    ev.target.src = "/images/icons/settings/tumor-board-default.jpg";
  };
  const checkUser = toCheck => {
    if (toCheck.id === loggedUser) return false;
    const checked = members.filter(user => {
      if (user.id === toCheck.id) return user.id;
    });
    return checked.length === 0;
  };
  const addMember = user => {
    if (checkUser(user)) {
      setMembers([user, ...members]);
    }
  };
  const closePopup = () => {
    setMembers([]);
    setOpenInvitation(false);
  };
  const inviteMembers = async () => {
    const membersIds = members.map(m => m.id);
    if (membersIds.length === 0){
      Notify({value: 'Enter at least one member\'s name to invite'})
      return
    }
    let res;
    try {
      res = await axios.post(
        "/api/tumorBoards/addMembers",
        { tumorBoardId: group.id, members: membersIds },
        { withCredentials: true }
      );
    } catch (err) {
      Notify({ value: "Invite failed" });
    }
    if (res.status === 201) Notify({ value: `${membersIds.length > 1 ? 'Invitations' : 'Invitation'} sent` });
    closePopup();
  };

  const isCreator = group.creator === loggedUser;
  const isAdmin = group.admin;

  return (
    <div className="chat-header">
      <img
        className="chat-img"
        src={
          group.imgSrc
            ? group.imgSrc
            : "/images/icons/settings/tumor-board-default.jpg"
        }
        alt="group icon"
        onError={addDefaultSrc}
      />
      <div className="chat-content">
        <div className="chat-info">
          <h3>{group.name}</h3>
          {groupMembers && (
            <div className="chat-number-info">
              <div className="member-count">
                {groupMembers.length}{" "}
                {groupMembers.length > 1 ? "members" : "member"}
              </div>
              <div className="online-count">
                {groupMembers.filter(m => m.connected).length} online
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="chat-buttons-wrapper">
        {isAdmin ? (
          <img
            data-for="quorum-header-tooltip"
            data-tip="Add slide"
            src={AddImageIcon}
            alt="add"
            className="add-img-btn"
            onClick={onOpenSlidebox}
          />
        ) : null}
        {!isPublicGroup &&
          <img
            data-for="quorum-header-tooltip"
            data-tip="Invite"
            className="add-user-img"
            src={AddUserIcon}
            onClick={() => setOpenInvitation(!openInvitation)}
          />
        }
        <img
          className="three-dots-img"
          onClick={onChangeActiveTab}
          src={ThreeDotsIcon}
        />
      </div>
      <Fade show={openInvitation} duration="300ms">
        <Overlay />
        <div id="create-board-overlay">
          <div id="leave-board">
            <div id="leave-header">
              <p className="invite-members-header-text">
                <img
                  src={AddUserIcon}
                  className="add-user-icon"
                  alt="add user icon"
                />
                Invite Members
              </p>
              <img
                src={ClosePopupIcon}
                id="close-create-board"
                onClick={closePopup}
              />
            </div>

            <div id="leave-content" style={{ minHeight: 0 }}>
              <MemberInvitation onMemberSelect={addMember} />
              <div className="users">
                {members.map((member, i) => (
                  <User
                    key={i}
                    img={`/users/${member.id}/avatar.jpg`}
                    fullName={`${member.firstName || member.firstname} ${member.surname
                      }`}
                  />
                ))}
              </div>
            </div>
            <div id="leave-footer">
              <SecondaryButton label="Cancel" onClick={closePopup} style={{ textTransform: 'uppercase' }} />
              <PrimaryButton label="Invite" onClick={inviteMembers} style={{ textTransform: 'uppercase' }} />
            </div>
          </div>
        </div>
      </Fade>
      <ToolTip
        id="quorum-header-tooltip"
        place="bottom"
        type="dark"
        effect="solid"
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { session } = state.User;
  const { selectedBoard: group } = state.TumorBoards;
  return {
    loggedUser: session.id,
    group
  };
};
export default connect(mapStateToProps)(ChatHeader);
