import { dispatchHelper } from '../utils/utils'

export const getNotifications = () =>
  dispatchHelper('GET_NOTIFICATIONS', 'get-notifications')

export const clearNotifications = () =>
  dispatchHelper('CLEAR_NOTIFICATIONS', 'clear-notifications')

export const getUnreadNotifications = () =>
  dispatchHelper('GET_UNREAD_NOTIFICATIONS', 'get-unread-notifications')

export const readNotifications = () =>
  dispatchHelper('READ_NOTIFICATIONS', 'read-notifications')

export const getNotificationSettings = () =>
  dispatchHelper('GET_NOTIFICATION_SETTINGS', 'get-notification-settings')

export const showSnackBarComponent = (
  show,
  fileList = [],
  conversionComplete = null,
  percentCompleted
) => dispatch => {
  dispatch({
    type: 'SHOW_SNACK_BAR_COMPONENT',
    payload: { show, fileList, conversionComplete, percentCompleted },
  })
}

export const setFileUploadProgress = (
  fileIndex,
  percentCompleted
) => dispatch => {
  dispatch({
    type: 'SET_FILE_UPLOAD_PROGRESS',
    payload: { fileIndex, percentCompleted },
  })
}

export const setFileCanceller = (fileIndex, canceller) => {
  return {
    type: 'SET_FILE_CANCELLER',
    payload: { fileIndex, canceller },
  }
}

export const removeUploadingFile = fileIndex => {
  return {
    type: 'REMOVE_UPLOADING_FILE',
    payload: { fileIndex },
  }
}

export const clearUploadingList = () => {
  return {
    type: 'CLEAR_UPLOADING_LIST',
  }
}
