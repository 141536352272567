import React, { Component } from "react";
import PrimaryButton from "../../others/button/primary-btn"
import SecondaryButton from "../../others/button/secondary-btn"

export default class ChangeAvatarPreview extends Component {
  render() {
    return (
      <React.Fragment>
      <div class="edit-image-preview-area" id="create-profile-image">
      </div>
          <div class="image-btn-area">
            <SecondaryButton
              label="Cancel"
              extraClass="blue"
              onClick={this.props.cancleUpload}
            />
            <PrimaryButton
              label="Upload"
              extraClass="blue"
              onClick={this.props.uploadProfileHandler}
            />
          </div>
      </React.Fragment>
    );
  }
}