import React, { Fragment } from 'react'
import { unfollow } from '../../../utils/user-interact-utils'
import { number, func, bool } from 'prop-types'
import { connect } from 'react-redux'
import SecondaryButton from '../button/secondary-btn'

const Unfollow = ({
  user,
  unfollowed,
  updateFollowings,
  updateFollowers,
  dispatch,
}) => {
  let unfollowUser = e => {
    e.preventDefault()
    let obj = {
      user,
      dispatch,
      update_followings: updateFollowings,
      update_followers: updateFollowers,
      done: () => unfollowed(),
    }
    unfollow(obj)
  }

  return (
    <SecondaryButton
      label="Unfollow"
      onClick={unfollowUser}
    />
  )
}

Unfollow.defaultProps = {
  updateFollowings: false,
  updateFollowers: false,
}

Unfollow.propTypes = {
  user: number.isRequired,
  unfollowed: func.isRequired,
  updateFollowings: bool,
  updateFollowers: bool,
}

export default connect()(Unfollow)
