import React, { Component } from "react";
import GroupNew from "./group-new";
import EmptyPanel from "./empty-panel";
import GroupDetails from "./group-details";
import ImagePanel from "./image-panel";
import GroupEdit from "./group-edit";

class GroupPanel extends Component {
  constructor() {
    super();
    this.state = {
      group: null
    };
  }
  componentWillReceiveProps({ group }) {
    if (group.slide) {
    }
    this.setState({ group });
  }
  onClickSubmit = data => {
    const { onSubmit } = this.props;
    onSubmit && onSubmit(data);
  };
  onClickEditGroup = () => {
    const { onChangeActiveTab } = this.props;
    onChangeActiveTab && onChangeActiveTab("edit");
  };
  onClickEditSubmit = data => {
    const { onEditSubmit } = this.props;
    onEditSubmit && onEditSubmit(data);
  };
  renderComponent = () => {
    const {
      activeTab,
      creatingError,
      editError,
      openSlidebox,
      setOpenSlidebox,
      onGroupRefresh,
      isPublicGroup
    } = this.props;
    const { group } = this.state;
    switch (activeTab) {
      case "details":
        return (
          <GroupDetails
            group={group}
            onEdit={this.onClickEditGroup}
            changeActiveTab={this.props.onChangeActiveTab}
            footerNav={this.props.footerNav}
          />
        );
      case "image":
        return (
          !isPublicGroup && (
            <ImagePanel
              group={group}
              openSlidebox={openSlidebox}
              setOpenSlidebox={setOpenSlidebox}
              onGroupRefresh={onGroupRefresh}
              footerNav={this.props.footerNav}
            />
          )
        );
      case "new":
        return (
          <GroupNew
            onSubmit={this.onClickSubmit}
            creatingError={creatingError}
            footerNav={this.props.footerNav}
            changeActiveTab={this.props.onChangeActiveTab}
          />
        );
      case "edit":
        return (
          <GroupEdit
            group={group}
            onSubmit={this.onClickEditSubmit}
            editError={editError}
            footerNav={this.props.footerNav}
            changeActiveTab={this.props.onChangeActiveTab}
          />
        );
      default:
        return <EmptyPanel />;
    }
  };
  render() {
    return (
      <div className="group-panel-component">{this.renderComponent()}</div>
    );
  }
}

export default GroupPanel;
