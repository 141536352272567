import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ClosePopupIcon from "../../../assets/icons/close-new.svg";
import DownloadIcon from "../../../assets/icons/download-icon.svg";
import { FadeIn } from "animate-components";
import { AppBar, Toolbar } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import Overlay from "../../others/overlay";

const drawerWidth = 0;
const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "400px",
    zIndex: "9"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#ffffff",
    color: "#0F0DA1",
    fontWeight: 700,
    border: "none"
  },
  drawer: {
    width: drawerWidth,
    // width: "auto",
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
    // width: "auto"
  },
  drawerContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 0),
    alignContent: "center",
    userSelect: "none"
  },
  customizeToolbar: {
    minHeight: 20
  }
}));

const PDFViewer = ({ openModal, onClose }) => {
  const classes = useStyles();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const downloadResource = () => {
    // If no filename is set, use filename from URL
    const url = "/api/documents/User_guide_Crosscope_Scholar_version_1.1.pdf";
    const filename = url.match(/\/([^/#?]+)[^/]*$/)[1];
    fetch(url, {
      headers: new Headers({
        Origin: window.location.origin
      }),
      mode: "cors"
    })
      .then(response => response.blob())
      .then(blob => forceDownload(blob, filename))
      .catch(e => console.error(e));
  };
  const forceDownload = (blob, filename) => {
    // Create an invisible anchor element
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = window.URL.createObjectURL(blob);
    anchor.setAttribute("download", filename);
    document.body.appendChild(anchor);

    // Trigger the download by simulating click
    anchor.click();

    // Clean up
    window.URL.revokeObjectURL(anchor.href);
    document.body.removeChild(anchor);
  };

  return (
    openModal && (
      <React.Fragment>
        <Overlay />

        <div className="modal_big">
          <div className="user-guide-modal">
            <FadeIn duration="300ms">
              <div className={classes.root}>
                <CssBaseline />

                <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar className="prompt-top">
                    <span className="prompt-title">User guide</span>

                    <div className="pageChanger">
                      <span>{numPages} pages</span>
                    </div>

                    <div className="downloadAndClose">
                      <img
                        className="download-icon"
                        src={DownloadIcon}
                        onClick={downloadResource}
                      />
                      <img
                        className="close-icon"
                        src={ClosePopupIcon}
                        onClick={() => {
                          onClose(false);
                        }}
                      />
                    </div>
                  </Toolbar>
                </AppBar>

                <main className={classes.content}>
                  <div className="modal_middle">
                    <div className="pdf-content">
                      <Document
                        file={
                          "/api/documents/User_guide_Crosscope_Scholar_version_1.1.pdf"
                        }
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {numPages &&
                          Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map(page => (
                              <Page
                                pageNumber={page}
                                width={1000}
                                renderAnnotationLayer={false}
                                loading={
                                  page !== 1 ? null : (
                                    <div className="pdf-loader">
                                      Please wait!
                                    </div>
                                  )
                                }
                              />
                            ))}
                      </Document>
                    </div>
                  </div>
                </main>
              </div>
            </FadeIn>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default PDFViewer;
