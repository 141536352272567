import React, { useState } from 'react'
import DropdownIcon from '../icons/dropdown-icon-new.svg'
import NewGroupIcon from '../icons/new-group-icon.svg'
import ThreeDotsIcon from '../icons/three-dots-icon-new.svg'

const LeftTabHeader = ({
  collapse,
  onChangeActiveTab,
  onChangeCollapseGroup,
  footerNav,
}) => {
  const [openPopup, setOpenPopup] = useState(false)
  const togglePopup = () => {
    let tempOpenPopup = openPopup

    setOpenPopup(!tempOpenPopup)
  }
  const onClickNewGroup = () => {
    togglePopup()
    onChangeActiveTab('new')
    if (footerNav) {
      footerNav("middle");
    }
  }

  return (
    <div className="header">
      {collapse && (
        <React.Fragment>
          <h3>Groups</h3>
          <div>
            <button>
            <img
                src={DropdownIcon}
                alt="arrow"
                onClick={onChangeCollapseGroup}
            />
            </button>
            <button className="three-dots-btn" onClick={togglePopup}>
              <img src={ThreeDotsIcon} alt="new group" />
            </button>
          </div>
        </React.Fragment>
      )}
      {collapse && openPopup && (
        <div className="create-group-popup" onClick={onClickNewGroup}>
          <img src={NewGroupIcon} className="popup-image" alt="new group" />
          <div className="popup-content">
            <h3 className="popup-title" style={{lineHeight: '20px'}}> New group</h3>
            <p className="popup-description">Chat where everyone can write</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default LeftTabHeader
