import React, { Fragment, useState } from "react";
import ThreeDotsIcon from "../icons/three-dots-icon-new.svg";
import { NavLink } from "react-router-dom";
import axios from "../../../utils/axios-instance-utils";
import { connect } from "react-redux";
import { setAdmin, removeMember } from "../../../actions/tumorBoards";
import Fade from "../../animations/Fade";
import GroupLeavePopup from "./group-leave-popup";
import Notify from "handy-notification";

const GroupDetailsUser = ({
  member,
  group,
  loggedUser,
  popupActive,
  onOptionClick,
  dispatch,
  changeActiveTab
}) => {
  const isCreator = group.creator.id === loggedUser;
  const self = member.id === loggedUser;
  const [openPopup, setOpenPopup] = useState(false);
  const [showLeave, setShowLeave] = useState(false);
  const onMakeGroupAdmin = async () => {
    setOpenPopup(false);
    const { status } = await axios
      .put(
        "/api/tumorBoards/setAdmin",
        { userId: member.id, tumorBoardId: group.id },
        { withCredentials: true }
      )
      .catch(console.log);
    if (status === 200) {
      dispatch(setAdmin(member.id, true));
      Notify({ value: "Set admin successfully" });
    }
  };
  const onRevokeGroupAdmin = async () => {
    setOpenPopup(false);
    const { status } = await axios
      .put(
        "/api/tumorBoards/removeAdmin",
        { userId: member.id, tumorBoardId: group.id },
        { withCredentials: true }
      )
      .catch(console.log);
    if (status === 200) {
      dispatch(setAdmin(member.id, false));
      Notify({ value: "Revoke admin successfully" });
    }
  };
  const onRemoveFromGroup = async () => {
    setOpenPopup(false);
    const { status } = await axios
      .delete(`/api/tumorBoards/removeMember/${group.id}/${member.id}`, {
        withCredentials: true
      })
      .catch(console.log);
    if (status === 200) {
      dispatch(removeMember(member.id));
      Notify({ value: "Removed member successfully" });
    }
  };
  return (
    <Fragment>
      <div className="user">
        <div className="user-avatar">
          <img
            src={`/users/${member.id}/avatar.jpg`}
            onError={e => {
              e.target.onerror = null
              e.target.src = '/images/default-avatar.svg'
            }}
            alt="user"
            height="40"
            width="40"
            style={{ borderRadius: "100%" }}
          />
        </div>
        <h3 className="user-name">{`${member.firstName} ${member.surname}`}</h3>
        <div className="user-info">
          <button className="three-dots-btn" onClick={onOptionClick}>
            <img src={ThreeDotsIcon} alt="user options" />
          </button>
        </div>
        {popupActive && (
          <div className="options user_options">
            <ul>
              <li>
                <NavLink
                  to={`/profile/${member.username}`}
                  exact
                  ispopup="true"
                >
                  Go to Profile
                </NavLink>
              </li>
              {isCreator && !member.admin && !self && (
                <li>
                  <a
                    onClick={onMakeGroupAdmin}
                    ispopup="true"
                    style={{ color: "#66757f" }}
                  >
                    Make Group Admin
                  </a>
                </li>
              )}
              {isCreator && member.admin && !self && (
                <li>
                  <a
                    onClick={onRevokeGroupAdmin}
                    style={{ color: "red" }}
                    ispopup="true"
                  >
                    Revoke Admin
                  </a>
                </li>
              )}
              {((isCreator && !self) ||
                (group.admin && !member.admin && !self)) && (
                <li>
                  <a
                    onClick={onRemoveFromGroup}
                    style={{ color: "red" }}
                    ispopup="true"
                  >
                    Remove from Group
                  </a>
                </li>
              )}
              {self && (
                <li>
                  <a
                    onClick={() => setShowLeave(true)}
                    style={{ color: "red" }}
                    ispopup="true"
                  >
                    Leave Group
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <Fade show={showLeave} duration="300ms">
        <div id="create-board-overlay">
          <GroupLeavePopup
            close={() => setShowLeave(false)}
            changeActiveTab={changeActiveTab}
          />
        </div>
      </Fade>
    </Fragment>
  );
};
const mapStateToProps = state => {
  const { session } = state.User;
  return {
    loggedUser: session.id
  };
};

export default connect(mapStateToProps)(GroupDetailsUser);
