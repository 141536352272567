import React, { Fragment } from 'react'
import { dateDetails } from '../../../../utils/utils'
import PropTypes from 'prop-types'
import AppLink from '../../../others/link/link'
import TimeAgo from 'handy-timeago'
import { isAdmin } from '../../../../utils/admin-utils'
import CorrectIcon from './options/icons/case-solved.svg'
import ReviewIcon from './options/icons/review.svg'
import PostRating from '../post-rating/post-rating'

const PostTopDetails = ({
  details,
  isQuorum,
  postStatus,
  isLoggedUser,
  rate,
  rateCount,
  rated,
  submit,
  openRate,
  setOpenRate,
  isOwner

}) => {
  let {
    user,
    username,
    firstname,
    surname,
    group_id,
    group_name,
    type,
    location,
    post_time,
    affiliation,
    country,
    case_slove,
  } = details

  const calculateRemainingTime = time => {
    const currentTime = new Date().getTime()
    const msec = currentTime - time

    const mins = Math.ceil(parseInt(msec) / 60000)
    const hrs = Math.ceil(parseInt(mins) / 60)

    let remaingMins = null
    if (mins < 60) {
      remaingMins = 60 - mins
    } else {
      remaingMins = 60 - (mins % 60)
    }

    let remaingHours = remaingMins !== 0 ? 24 - hrs : hrs
    if (remaingHours > 0 || (remaingHours === 0 && remaingMins !== 0)) {
      remaingHours = remaingHours < 10 ? '0' + remaingHours : remaingHours
      remaingMins = remaingMins < 10 ? '0' + remaingMins : remaingMins
      return `${remaingHours}${':'}${remaingMins === 60 ? '00' : remaingMins}`
    } else {
      return 0
    }
  }

  const needReview = (
    <span className="need-review-label">
      {' '}
      <span>
        <img src={ReviewIcon} alt="" />
      </span>{' '}
        REQUIRED REVIEW
    </span>
  )


  return (
    <Fragment>
      <div className="p_i_img">
        <img
          src={`/users/${user}/avatar.jpg`}
          onError={e => {
            e.target.onerror = null
            e.target.src = '/images/default-avatar.svg'
          }}
        />
      </div>
      <div className="p_i_1" style={{ top: type == 'group' ? -8 : 'inherit' }}>
        <div className="name-rating">
          <AppLink
            url={`/profile/${username}`}
            title={username}
            label={`${firstname} ${surname}`}
          />
          {isLoggedUser && (
            <PostRating
              rate={rate}
              rateCount={rateCount}
              rated={rated}
              submit={submit}
              openRate={openRate}
              setOpenRate={setOpenRate}
              isOwner={isOwner}
            />
          )}
        </div>
        <span className="p_i_1_title">{`${affiliation}${', '}${country}`}</span>

        <div className="p_time">
          {isAdmin() && (postStatus === 'under-review') && needReview}
          {isQuorum && (
            <span className="quorum-identity-label">
              {' '}
              <span
                className={
                  calculateRemainingTime(post_time) !== 0
                    ? 'quorum-status-span-active'
                    : 'quorum-status-span-nonactive'
                }
              >
                {calculateRemainingTime(post_time) !== 0 ? (
                  <i className="custom-icon-deactive"></i>
                ) : (
                  <input type="radio" value="Offline" checked={true} />
                )}
              </span>{' '}
              QUORUM
            </span>
          )}
          {!isQuorum && case_slove ? (
            <span className="case-resolved-identity-label">
              {' '}
              <span>
                <img src={CorrectIcon} alt="" />
              </span>{' '}
              Case Resolved
            </span>
          ) : (
            ''
          )}
          <span className="post-time-label" title={dateDetails(post_time)}>
            {post_time && TimeAgo(post_time) === 0
              ? 'Now'
              : TimeAgo(post_time).replace(/\s ago/, '')}
          </span>
        </div>
      </div>
    </Fragment>
  )
}

PostTopDetails.propTypes = {
  details: PropTypes.shape({
    user: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    group_id: PropTypes.number,
    group_name: PropTypes.string,
    type: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }).isRequired,
}

export default PostTopDetails
