import React, { Fragment } from "react";
import Nothing from "../../others/nothing";
import { FadeIn } from "animate-components";
import PropTypes from "prop-types";

const MapPosts = ({
  posts,
  pageIndicator,
  nothingMssg,
  profile,
  waitForLoad = false
}) => {
  console.log({ pageIndicator, nothingMssg });
  return (
    <Fragment>
      {waitForLoad ? (
        <Fragment></Fragment>
      ) : posts.length == 0 ? (
        <Nothing pageIndicator={pageIndicator} mssg={nothingMssg} />
      ) : (
        <FadeIn duration="500ms" style={profile ? { width: "100%" } : null}>
          {posts}
        </FadeIn>
      )}
    </Fragment>
  );
};

MapPosts.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.node).isRequired,
  nothingMssg: PropTypes.string.isRequired
};

export default MapPosts;
