import React, { Component } from "react";
import Viewer from "./components/viewer";
import "./ImageViewer.scss";
import axios from "../../utils/axios-instance-utils";
import $ from "jquery";
import { connect } from "react-redux";

class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
      imageData: undefined,
      imageOtherSlideData: [],
      caseStremPostId: null,
      thumbnailSliderShow: props.when == "viewer" ? true : false,
      thumbToken: null,
      navigatorShow: true,
      loadFrom: null,
      fromCaseStream: false,
      postIsMine: null,
      slideOwnerOrCollaborator: null
    };
  }

  componentWillMount() {
    sessionStorage.removeItem("selectedSlide");
    this.updateImageViewer(this.props);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.location !== this.props.location ||
      nextProps.value !== this.props.value ||
      nextProps.dziData !== this.props.dziData ||
      nextProps.slideBoxId !== this.props.slideBoxId
    ) {
      if (this.props.location && this.props.location.pathname === "/viewer") {
        const urlParams = new URLSearchParams(window.location.search);
        const folderKey = urlParams.get("image");
        const thumb = urlParams.get("thumb");
        const postId = urlParams.get("post");
        if (thumb) {
          this.loadImageDataWithThumbInSlideBox(folderKey, thumb);
        }
        if (postId) {
          this.loadImageDataWithThumbInCaseStream(folderKey, postId);
          this.setState({ fromCaseStream: true });
        }
      } else {
        this.updateImageViewer(nextProps);
      }
    }
    return true;
  }

  updateImageViewer = props => {
    if (props.location) {
      const {
        location: { search }
      } = this.props;
      const searchData = search.split("=");
      this.value = searchData[1].split("&")[0];
      if (typeof searchData[1].split("&")[1] !== "undefined") {
        const searchType = searchData[1].split("&")[1];
        if (searchType === "thumb") {
          const thumbToken = searchData[2];
          this.setState({ thumbToken: thumbToken });
          this.loadImageDataWithThumbInSlideBox(this.value, thumbToken);
        } else {
          const postId = searchData[2];
          this.setState({ caseStremPostId: postId, fromCaseStream: true });
          this.loadImageDataWithThumbInCaseStream(this.value, postId);
        }
      } else {
        this.loadImageDataWithoutThumb(this.value);
      }
    } else {
      if (
        props.when === "central_feed" ||
        props.when === "groups" ||
        props.when === "quorum"
      ) {
        if (props.slideBoxId) {
          this.setState({ postIsMine: props.postIsMine });
          this.loadImageDataWithoutThumb(
            props.slideBoxId,
            true,
            props.keyFolder
          );
        }
      }
    }
  };

  loadImageDataWithThumb = value => {
    axios
      .post("api/getFileData", {
        keyFolder: decodeURI(value),
        showSlideThumb: true
      })
      .then(response => {
        this.handleOnDziData(response.data[0]);
        this.setState({ imageData: response.data[0] });
        if (this.state.imageOtherSlideData.length > 0) {
          this.setState({
            imageOtherSlideData: response.data[0].slide_thumbdata
          });
        }
      })
      .catch(e => console.warn(e));
  };

  loadImageDataWithThumbInCaseStream = (value, postId) => {
    this.setState({ loadFrom: "case-view" });
    axios
      .post("api/getFileData", {
        caseStreamId: postId,
        keyFolder: decodeURI(value),
        showSlideThumb: true
      })
      .then(response => {
        this.handleOnDziData(response.data[0]);
        this.setState({
          imageData: response.data[0],
          postIsMine: response.data[0].postIsMine
        });
        this.setState({
          imageOtherSlideData: response.data[0].slide_thumbdata
        });
      })
      .catch(e => console.warn(e));
  };

  loadImageDataWithThumbInSlideBox = (value, thumToken) => {
    this.setState({ loadFrom: "slide-view" });
    axios
      .post("api/getFileData", {
        thumbToken: thumToken,
        keyFolder: decodeURI(value),
        showSlideThumb: true,
        isPublicFolder:
          this.props.selectedUploadFolder &&
          this.props.selectedUploadFolder.isPublicFolder
            ? true
            : false
      })
      .then(response => {
        this.handleOnDziData(response.data[0]);
        this.setState({ imageData: response.data[0] });
        this.setState({
          imageOtherSlideData: response.data[0].slide_thumbdata,
          slideOwnerOrCollaborator: response.data[0].slideOwnerOrCollaborator
        });
      })
      .catch(e => console.warn(e));
  };

  loadImageDataWithoutThumb = (value, slideId = false, folderKey) => {
    let dataParams;
    if (slideId) {
      dataParams = {
        moduleName: "slidebox",
        showSlideThumb: false,
        fileId: value,
        isGroup: true,
        groupId: this.props.selectedGroup ? this.props.selectedGroup.id : null,
        keyFolder:
          typeof value !== "number" && value.toString().includes("PU")
            ? folderKey
            : null,
        isPublicFolder:
          typeof value !== "number" && value.toString().includes("PU")
            ? true
            : false
      };
    } else {
      dataParams = {
        moduleName: "slidebox",
        showSlideThumb: false,
        keyFolder: value
      };
    }
    axios
      .post("api/getFileData", dataParams)
      .then(response => {
        this.handleOnDziData(response.data[0]);
        this.setState({
          imageData: response.data[0],
          imageOtherSlideData: response.data[0].slide_thumbdata
        });
      })
      .catch(e => console.warn(e));
  };

  fetchImageMetadata = url => {
    fetch(url + "vips-properties.xml").then(response => {
      response.blob().then(blob => {
        const reader = new FileReader();
        const ABS = !!reader.readAsBinaryString;

        reader.onload = e => {
          this.metadata = this.parseMetadataXML(e.target.result);
          this.setState({ isDataLoaded: true });
        };

        if (ABS) reader.readAsBinaryString(blob);
        else reader.readAsArrayBuffer(blob);
      });
    });
  };

  parseMetadataXML = data => {
    let imageMetadata = {};
    const xml = $($.parseXML(data));
    const image = xml.find("image");
    const props = image.children().children();
    for (let p in props) {
      let property = props[p];
      if (property.firstElementChild) {
        let key = property.firstElementChild.innerHTML,
          value = property.lastElementChild.innerHTML;
        imageMetadata[key] = value;
      }
    }
    return imageMetadata;
  };

  handleOnDziData = imgData => {
    if (_.isEmpty(imgData)) {
      return;
    }
    this.setState({ isDataLoaded: false });
    this.dziFilesUrl =
      typeof imgData.dzi_url !== "undefined"
        ? imgData.module_name === "public"
          ? `${imgData.dzi_url}`
          : imgData.dzi_url
        : null;
    fetch(
      imgData.module_name === "public"
        ? `${imgData.dzi_url.split(".dzi_files/")[0]}.dzi.dzi`
        : imgData.dzi_data
    ).then(response => {
      response.blob().then(blob => {
        const reader = new FileReader();
        const ABS = !!reader.readAsBinaryString;
        reader.onload = e => {
          this.dziData = e.target.result; // This will give the xml string of that file
          this.fetchImageMetadata(this.dziFilesUrl);
        };

        if (ABS) reader.readAsBinaryString(blob);
        else reader.readAsArrayBuffer(blob);
      });
    });
  };

  tileSourceFromData = (data, filesUrl) => {
    const xml = $($.parseXML(data));
    const image = xml.find("Image");
    const size = xml.find("Size");
    let dzi = {
      Image: {
        xmlns: image.attr("xmlns"),
        Url: filesUrl,
        Format: image.attr("Format"),
        Overlap: image.attr("Overlap"),
        TileSize: image.attr("TileSize"),
        Size: {
          Height: size.attr("Height"),
          Width: size.attr("Width")
        }
      }
    };

    console.log(dzi);

    return dzi;
  };

  changeSlideImage = (keyFolder, slideId = null) => {
    if (this.props.when == "groups") {
      this.props.changeThumbnailSlide(slideId);
      return;
    }

    const { caseStremPostId, thumbToken } = this.state;
    if (caseStremPostId) {
      this.props.history.push(
        `/viewer?image=${keyFolder}&post=${caseStremPostId}`
      );
    } else {
      this.props.history.push(`/viewer?image=${keyFolder}&thumb=${thumbToken}`);
    }
  };

  toggleThumbnailSlider = () => {
    this.setState({ thumbnailSliderShow: !this.state.thumbnailSliderShow });
  };

  toggleNavigator = () => {
    this.setState({ navigatorShow: !this.state.navigatorShow });
  };

  render() {
    if (!this.state.isDataLoaded) {
      return null;
    }
    const { zoom } = this.props;
    const {
      imageData,
      imageOtherSlideData,
      navigatorShow,
      loadFrom,
      fromCaseStream,
      postIsMine,
      slideOwnerOrCollaborator
    } = this.state;
    const dziTileData = this.tileSourceFromData(this.dziData, this.dziFilesUrl);
    const config = {
      basename: "example/image",
      getImageURL: dziTileData,
      thumbnail: dziTileData,
      osdConfig: {
        setStrings: [{ name: "Tooltips.Home", value: "Reset" }],
        defaultZoomLevel: zoom ? zoom : 0,
        tileSources: dziTileData,
        navigatorPosition: "ABSOLUTE",
        navigatorTop: "50px",
        navigatorLeft: "0px",
        navigatorHeight: "120px",
        navigatorWidth: "145px",
        visibilityRatio: 1.0,
        constrainDuringPan: true,
        sequenceMode: false,
        showReferenceStrip: false,
        showNavigator: true,
        navigatorAutoFade: false,
        zoomInButton:
          typeof this.props.viewerId === "undefined"
            ? "zoom-in"
            : `zoom-in-${this.props.viewerId}`,
        zoomOutButton:
          typeof this.props.viewerId === "undefined"
            ? "zoom-out"
            : `zoom-out-${this.props.viewerId}`,
        homeButton: "home",
        fullPageButton: "full-page",
        rotate: "rotate",
        crossOriginPolicy: "Anonymous",
        canvasDrag: false,
        slideId:
          typeof imageData !== "undefined"
            ? imageData.slide_box_id
            : this.props.dziData
            ? this.props.dziData.slide_box_id
            : null
        // toolbar: "viewer-toolbar-container"
      },
      imageMetadata: {
        ...this.metadata,
        "aperio.Filename":
          typeof imageData !== "undefined" ? imageData.file_name : ""
      },
      pages: [
        {
          id: 0,
          title: "MLK",
          sidebarThumbnail: dziTileData,
          transcript: "MLK",
          viewer: "OSD_VIEWER",
          cdmCollection: "mpls",
          cdmIdentifier: "3188",
          infoURL: dziTileData
        }
      ]
    };

    return (
      <Viewer
        {...config}
        {...this.props}
        mainImage={this.value}
        slideThumbData={imageOtherSlideData}
        changeSlide={this.changeSlideImage}
        toggleThumbnailSlider={this.toggleThumbnailSlider}
        thumbnailSliderShow={this.state.thumbnailSliderShow}
        defultKeyFolder={imageData ? imageData.key_folder : null}
        navigatorShow={navigatorShow}
        toggleNavigator={this.toggleNavigator}
        loadFrom={loadFrom}
        showCollapsibleSidebar={this.props.showCollapsibleSidebar}
        fromCaseStream={fromCaseStream}
        postIsMine={postIsMine}
        slideOwnerOrCollaborator={slideOwnerOrCollaborator}
      />
    );
  }
}

//selectedBoard

const mapStateToProps = state => ({
  selectedGroup: state.TumorBoards.selectedBoard,
  selectedUploadFolder: state.SlideBox.selectedUploadFolder
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageViewer);
