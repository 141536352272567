import React from "react";

const PostItQuorumMiddle = () => {
  return (
    <div className="post-main-middle p_details" style={{ height: "auto" }}>
      <div className="image">
      <img
            src="../images/icons/settings/icons8-student_male.png"
            alt="Filled"
          />
      </div>
      <div className="description">
       <span>
           An interactive viewer for you to
           learn and study from hand-picked cases,
           or brush up on must know diagonoses
       </span>

      </div>
    </div>
  );
};

export default PostItQuorumMiddle;
