import axios from '../utils/axios-instance-utils'
import Notify from 'handy-notification'
import {
  comment,
  feedComment,
  voteCommentAction,
  feedCommentReply,
  commentReply,
  voteReplyAction,
  voteCommentPostAction,
  voteReplyPostAction,
} from '../actions/post'
import {
  imageCompressor,
  insta_notify,
  Me,
  uData,
  wait,
  uDetails,
} from './utils'
import d from './API/DOM'

/**
 * A helper for dispatching actions related to comments
 * @param {Object} options
 */
const commentDispatchHelper = async options => {
  let {
    user,
    post_id,
    comment_id,
    commentExtraDetails,
    when,
    dispatch,
  } = options
  let session = uData('session')
  let username = uData('username')
  let { firstname, surname, affiliation, country } = uDetails('user_details')

  if (when == 'viewPost') {
    dispatch(
      comment({
        comment_id,
        comment_by: Number(session),
        comment_by_username: username,
        post_id,
        firstname,
        surname,
        affiliation,
        country,
        replies: [],
        comment_time: `${new Date().getTime()}`,
        ...commentExtraDetails,
      })
    )
  }

  if (when == 'feed') {
    dispatch(
      feedComment({
        comment_id,
        comment_by: Number(session),
        comment_by_username: username,
        post_id,
        firstname,
        surname,
        affiliation,
        country,
        replies: [],
        comment_time: `${new Date().getTime()}`,
        ...commentExtraDetails,
      })
    )
  }

  if (!Me(user)) {
    insta_notify({
      to: user,
      type: 'comment',
      post_id,
    })
  }
}

const replyDispatchHelper = async options => {
  let {
    user,
    post_id,
    comment_id,
    reply_id,
    replyExtraDetails,
    when,
    dispatch,
  } = options
  let session = uData('session')
  let username = uData('username')
  let { firstname, surname, affiliation, country } = uDetails('user_details')

  if (when == 'viewPost') {
    dispatch(
      commentReply({
        reply_id,
        comment_id,
        reply_by: Number(session),
        reply_by_username: username,
        post_id,
        firstname,
        surname,
        affiliation,
        country,
        reply_time: `${new Date().getTime()}`,
        ...replyExtraDetails,
      })
    )
  }

  if (when == 'feed') {
    dispatch(
      feedCommentReply({
        reply_id,
        comment_id,
        reply_by: Number(session),
        reply_by_username: username,
        post_id,
        firstname,
        surname,
        affiliation,
        country,
        reply_time: `${new Date().getTime()}`,
        ...replyExtraDetails,
      })
    )
  }

  if (!Me(user)) {
    insta_notify({
      to: user,
      type: 'comment',
      post_id,
    })
  }
}

const voteToCommentDispatchHelper = async options => {
  const {
    vote_type_in,
    post_id,
    conversation_id,
    conversation_type,
    when,
    dispatch,
  } = options

  if (when === 'feed' && conversation_type === 'comment') {
    dispatch(
      voteCommentAction({
        vote_type_in,
        post_id,
        conversation_id,
        conversation_type,
      })
    )
  } else if (when === 'feed' && conversation_type === 'reply') {
    dispatch(
      voteReplyAction({
        vote_type_in,
        post_id,
        conversation_id,
        conversation_type,
      })
    )
  } else if (when === 'viewPost' && conversation_type === 'comment') {
    dispatch(
      voteCommentPostAction({
        vote_type_in,
        post_id,
        conversation_id,
        conversation_type,
      })
    )
  } else if (when === 'viewPost' && conversation_type === 'reply') {
    dispatch(
      voteReplyPostAction({
        vote_type_in,
        post_id,
        conversation_id,
        conversation_type,
      })
    )
  }
}

/**
 * Image comment
 * @param {Object} options
 * @param {Number} options.post_id
 * @param {Function} options.dispatch
 * @param {String} options.when
 * @param {Number} options.user
 * @param {File} options.file
 * @param {Function} options.done
 */
export const imageComment = async options => {
  let { post_id, dispatch, when, user, file: commentFile, done } = options,
    form = new FormData(),
    file = await imageCompressor(commentFile),
    o = new d('.overlay-2')

  o.show()
  wait()

  form.append('commentImage', file)
  form.append('post', post_id)

  let {
    data: { success, mssg, filename, comment_id },
  } = await axios.post('/api/comment-image', form)

  if (success) {
    await commentDispatchHelper({
      user,
      post_id,
      comment_id,
      when,
      dispatch,
      commentExtraDetails: {
        type: 'image',
        imgCommentSrc: filename,
        text: '',
      },
    })

    done()
  }

  o.hide()
  Notify({ value: mssg })
}

/**
 * Sticker comment
 * @param {Object} options
 * @param {String} options.sticker
 * @param {Number} options.post_id
 * @param {Number} options.user
 * @param {String} options.when
 * @param {Function} options.dispatch
 */
export const stickerComment = async options => {
  let { sticker, post_id, user, when, dispatch, done } = options
  let {
    data: { mssg, success, comment_id, filename },
  } = await axios.post('/api/comment-sticker', {
    sticker: sticker,
    post: post_id,
  })

  wait()

  if (success) {
    await commentDispatchHelper({
      user,
      post_id,
      comment_id,
      when,
      dispatch,
      commentExtraDetails: {
        type: 'sticker',
        imgCommentSrc: filename,
        text: '',
      },
    })

    done()
  }

  Notify({ value: mssg })
}

/**
 * Text comment
 * @param {Object} options
 * @param {Number} options.post
 * @param {String} options.text
 * @param {String} options.when
 * @param {Function} options.dispatch
 * @param {Number} options.postOwner
 */

export const textComment = async options => {
  let {
    post_id,
    text,
    when,
    dispatch,
    postOwner,
    firstname,
    surname,
    file,
    mentionedUsers,
    done,
  } = options
  const formData = new FormData()
  formData.append('post_id', post_id)
  formData.append('text', text)
  formData.append('mentionedUsers', JSON.stringify(mentionedUsers))
  if (file) formData.append('attachment', file)

  let {
    data: { success, mssg, comment_id, imgSrc },
  } = await axios.post('/api/comment-text', formData)

  if (success) {
    await commentDispatchHelper({
      user: postOwner,
      post_id,
      comment_id,
      when,
      dispatch,
      firstname,
      surname,
      commentExtraDetails: {
        type: imgSrc ? 'image' : 'text',
        imgCommentSrc: imgSrc ? imgSrc : '',
        text,
        upvote_count: 0,
        downvote_count: 0,
      },
    })

    done()
  }

  Notify({ value: mssg })
}

export const replyComment = async options => {
  let {
    comment_id,
    post_id,
    text,
    when,
    dispatch,
    postOwner,
    firstname,
    surname,
    file,
    mentionedUsers,
    done,
  } = options

  const formData = new FormData()
  formData.append('post_id', post_id)
  formData.append('comment_id', comment_id)
  formData.append('mentionedUsers', JSON.stringify(mentionedUsers))
  formData.append('text', text)
  if (file) {
    Notify({ value: 'Attaching file' })
    formData.append('attachment', file)
  }

  let {
    data: { success, mssg, reply_id, imgSrc },
  } = await axios.post('/api/reply-text', formData)

  if (success) {
    await replyDispatchHelper({
      user: postOwner,
      post_id,
      comment_id,
      reply_id,
      when,
      firstname,
      surname,
      dispatch,
      replyExtraDetails: {
        type: imgSrc ? 'image' : 'text',
        imgReplySrc: imgSrc ? imgSrc : '',
        text,
        upvote_count: 0,
        downvote_count: 0,
      },
    })

    done()
  }

  Notify({ value: mssg })
}

export const voteComment = async options => {
  let {
    vote_type,
    vote_by,
    conversation_type,
    conversation_id,
    post_id,
    postOwner,
    dispatch,
    when,
  } = options

  let {
    data: { success, mssg },
  } = await axios.post('/api/vote-comment', {
    vote_type,
    conversation_type,
    conversation_id,
    post_id,
  })

  if (success) {
    await voteToCommentDispatchHelper({
      vote_type_in: vote_type,
      post_id,
      conversation_id,
      conversation_type,
      when,
      dispatch,
    })

    //done()
  }
}
