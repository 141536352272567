import React, { Fragment } from "react";
import PropTypes from "prop-types";

const ImageTheatre = props => {
  let { imgSrc, back } = props;
  const onClickOutside = e => {
    var element = document.getElementById("create-board");
    if (e.target !== element && !element.contains(e.target)) {
      back();
    }
  };

  const styles = {
    previewStyles: {
      height: "auto",
      width: "auto",
      objectFit: "contain",
      margin: "20px 20px",
      border: "solid 1px #eee",
      display: "block",
      maxHeight: "80vh",
      maxWidth: "80vw"
    },
    headerTop: {
      height: "100%",
      paddingLeft: "20px",
      paddingRight: "20px",
      display: "flex",
      alignItems: "center"
    }
  };
  return (
    <Fragment>
      <div id="create-board-overlay" onClick={onClickOutside}>
        <div id="create-board" style={{width: "auto" }}> 
          <div id="file-div">
            <div
              id="create-board-header"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span style={{ ...styles.headerTop }}>Attachment Preview</span>
              <img
                src="/images/icons/neutral/icons8-delete_sign.png"
                id="close-create-board"
                onClick={() => back()}
                style={{ height: 30, marginRight: 10, cursor: "pointer" }}
              ></img>
            </div>
            <div
              id="edit-board-content"
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {" "}
              <img
                src={imgSrc}
                alt="preview"
                id="preview"
                style={{ ...styles.previewStyles }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ImageTheatre.defaultProps = {
  showInfo: true,
  imgSrc: "/images/location.jpg",
  filter: ""
};

ImageTheatre.propTypes = {
  showInfo: PropTypes.bool,
  imgSrc: PropTypes.array.isRequired,
  filter: PropTypes.string,
  username: PropTypes.string,
  link: PropTypes.string,
  time: PropTypes.string,
  back: PropTypes.func.isRequired
};

export default ImageTheatre;
