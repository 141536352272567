import React from "react";
import PropTypes from "prop-types";
import RemBookmarkAsAdmin from "./remBookmark";
import OpenPost from "./openPost";
import EditPostOption from "./editPost";
import DeletePostOption from "./deletePost";
import CaseslovePostOption from "./caseslovePost";
import SpamPostOption from "./spamPost";
import MarkUserAsAllow from './markUserAsAllow';
import MarkAsReviewed from './markAsReviewed'
import { isAdmin } from '../../../../../utils/admin-utils'

const PostOptionLists = ({ postDetails, toggleOptions, updateDescription, isQuorum, postStatus }) => {
  let { post_id, user, when, description, case_slove, post_status, postid_token, labels, verification } = postDetails;
  return (
    <div>
      <ul className="unorderlist-options">
        <OpenPost when={when} post_id={post_id} token={postid_token} isQuorum={isQuorum} />
        {
          isAdmin() && (postStatus == 'under-review') && (
            <MarkAsReviewed
              postDetails={{ post_id, user, when, case_slove, isQuorum }}
              toggleOptions={toggleOptions}
            />
          )
        }

        {
          isAdmin() && (verification !== 'verified') && (
            <MarkUserAsAllow
              postDetails={{post_id, user, when, case_slove, isQuorum, verification }}
              toggleOptions={toggleOptions}
              postStatus={postStatus}
            />
          )
        }

        {!isQuorum &&
          <CaseslovePostOption
            postDetails={{ post_id, user, when, case_slove }}
            toggleOptions={toggleOptions}
          />
        }

        <SpamPostOption
          postDetails={{ post_id, user, when, post_status }}
          toggleOptions={toggleOptions}
        />

        <EditPostOption
          postDetails={{ user, post_id, description, labels, when }}
          updateDescription={updateDescription}
          toggleOptions={toggleOptions}
        />
        <DeletePostOption
          postDetails={{ post_id, user, when, isQuorum }}
          toggleOptions={toggleOptions}
        />
        {!isQuorum && <RemBookmarkAsAdmin {...postDetails} />}
      </ul>
    </div>
  );
};

PostOptionLists.propTypes = {
  postDetails: PropTypes.shape({
    post_id: PropTypes.number.isRequired,
    user: PropTypes.number.isRequired,
    when: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  toggleOptions: PropTypes.func.isRequired,
  updateDescription: PropTypes.func.isRequired
};

export default PostOptionLists;
