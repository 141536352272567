import React, { Fragment } from "react";
import { string, func, bool, oneOfType } from "prop-types";
import classNames from "classnames";

const PrimaryButton = ({
  img,
  label,
  onClick,
  extraClass,
  disabled,
  ...props
}) => {
  return (
    <Fragment>
      {props.href ? (
        <a
          href={props.href ? props.href : ""}
          className={classNames(
            "pri_btn",
            extraClass,
            disabled ? "a_disabled" : ""
          )}
          onClick={!disabled && onClick}
          {...props}
        >
          {img && <img src={img} atl="img" />}
          {typeof label == "function" ? label() : label}
        </a>
      ) : (
        <a
          className={classNames(
            "pri_btn",
            extraClass,
            disabled ? "a_disabled" : ""
          )}
          onClick={!disabled && onClick}
          {...props}
        >
          {img && <img src={img} atl="img" />}
          {typeof label == "function" ? label() : label}
        </a>
      )}
    </Fragment>
  );
};

PrimaryButton.defaultProps = {
  label: "No label",
  Btntype: "button",
  value: "",
  disabled: false,
  secondary: false,
  img: "",
  content: "No content",
  extraClass: ""
};

PrimaryButton.propTypes = {
  label: oneOfType([string, func]),
  onClick: func.isRequired,
  extraClass: string,
  disabled: bool,
  secondary: bool
};

export default PrimaryButton;
