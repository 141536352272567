import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Directory from "./directory";
import MoreMenu from "./MoreMenu";
import Notify from "handy-notification";
import axios from "../../../utils/axios-instance-utils";
import verticalThreeDotsIcon from "./icons/vertical-three-dots.svg";
import DeleteConfirm from "./deleteConfirm";
import FolderIcon from "../../../assets/icons/folder-close-new.svg";
import FolderOpenIcon from "../../../assets/icons/folder-open-new.svg";

const DirectoryBuilder = props => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [currentDeleteFile, setCurrentDeleteFile] = useState(null);
  const handleRename = (file, fileIndex) => {
    props.onFolderRename &&
      props.onFolderRename(
        { name: props.name, path: props.folderPath, folderId: props.folderId },
        fileIndex,
        false
      );
  };

  const confirmDelete = async file => {
    setCurrentDeleteFile(file);
    setShowDeleteConfirm(true);
  };

  const handleShowingDetails = (file, fileIndex, isShared) => {
    props.showDetails && props.showDetails(file, fileIndex, false, isShared);
  };

  const shareFolders = (file, fileIndex) => {
    props.showDetails && props.showDetails(file, fileIndex, false, true);
  };

  const deleteFolder = async () => {
    try {
      await axios.post("/api/deleteFolder", {
        folderToDelete: currentDeleteFile
      });
    } catch (err) {
      Notify({ value: "Error occurred" });
    }
    Notify({ value: "Folder deleted" });
    setShowDeleteConfirm(false);
    props.onFolderChanged && props.onFolderChanged();
  };

  const handleCancelDeleteFile = () => {
    setCurrentDeleteFile(null);
    setShowDeleteConfirm(false);
  };

  const openParentDir = () => {
    props.folderPath !== "slidebox" &&
      props.onToggleNestedDirectory(
        props.folderPath ? props.folderPath : props.folderId
      );
    props.onOpenDirectoryContent({
      directoryPath: props.folderPath ? props.folderPath : props.folderId,
      isSharedFolder: props.isSharedDirectory,
      isPublicFolder: props.isPublicFolder,
      name: props.name
    });
  };

  let moreMenuItems;
  if (props.selectedFolder && !props.selectedFolder.isPublicFolder) {
    moreMenuItems = [
      { title: "Rename", callBack: handleRename },
      { title: "Delete", callBack: confirmDelete },
      { title: "Details", callBack: handleShowingDetails },
      { title: "Share", callBack: shareFolders }
    ];
  } else {
    moreMenuItems = [{ title: "Details", callBack: handleShowingDetails }];
  }

  if (
    (!props.children || props.children.length === 0) &&
    props.folderPath !== "slidebox" &&
    props.folderPath !== "shared" &&
    props.folderPath !== "public"
  ) {
    return (
      <React.Fragment>
        <Directory
          folderPath={props.folderPath}
          folderId={props.folderId}
          isFolder={props.isFolder}
          name={props.name}
          onOpenDirectoryContent={props.onOpenDirectoryContent}
          openedDirectory={props.openedDirectory}
          index={props.index}
          onFolderChanged={props.onFolderChanged}
          onFolderRenameChosen={props.onFolderRename}
          withMoreMenu={props.withMoreMenu !== false}
          parentFolder={props.parentFolder}
          showDetails={handleShowingDetails}
          shareFolders={shareFolders}
          isSharedDirectory={props.isSharedDirectory}
          isPublicFolder={props.isPublicFolder}
          onFolderDelete={props.onFolderDelete}
          rootId={props.folderRootId}
        />
      </React.Fragment>
    );
  }

  let expanded =
    props.toggledDirectories[
      props.folderPath ? props.folderPath : props.folderId
    ] ||
    props.folderPath === "slidebox" ||
    props.folderPath === "shared" ||
    props.folderPath === "public";

  let openedFolderType =
    props.selectedFolder && props.selectedFolder.isPublicFolder
      ? "public"
      : props.selectedFolder && props.selectedFolder.isSharedFolder
      ? "shared"
      : "slidebox";
  let opened =
    props.openedDirectory == props.folderId &&
    openedFolderType === props.folderPath;
  return (
    <div
      className={`directory-item parent-directory
        ${expanded ? "expanded-sub-items" : "collapsed-sub-items"}
      `}
    >
      <div
        className={`${props.mainRoot ? "main-folder" : "child-folder"} ${
          props.openedDirectory === `${props.folderPath}/` ||
          opened ||
          (props.folderPath === "public" &&
            props.openedDirectory === `${props.folderPath}`)
            ? "open"
            : ""
        }`}
      >
        <div onClick={openParentDir} style={{ display: "flex" }}>
          <ParentDirectoryIcon
            isRootFolder={props.mainRoot && props.isRootFolder}
            expanded={expanded}
          />
          <p
            className="directory-label"
            style={
              props.mainRoot
                ? { display: "inline-block" }
                : { display: "inline-block" }
            }
          >
            {props.name}
          </p>
        </div>

        {!props.mainRoot && props.withMoreMenu && (
          <MoreMenu
            className={"row-option"}
            icon={verticalThreeDotsIcon}
            fileData={{
              name: props.name,
              path: props.folderPath ? props.folderPath : props.folderId,
              isSharedDirectory: props.isSharedDirectory
            }}
            fileIndex={props.index}
            menuItems={moreMenuItems}
            onButtonMouseDown={() => {}}
            isFolder={true}
          />
        )}
      </div>
      {props.children && props.children.length > 0 && (
        <div className="list-of-directories">
          {props.children.map((directory, index) => (
            <DirectoryBuilder
              {...props}
              index={index}
              isFolder={directory.isFolder}
              name={directory.name}
              folderPath={directory.folderPath}
              folderId={directory.folder_id}
              children={directory.children}
              mainRoot={directory.mainRoot}
              parentFolder={directory.parentFolder}
            />
          ))}
        </div>
      )}

      {showDeleteConfirm && (
        <DeleteConfirm
          handleDelete={deleteFolder}
          isFile={false}
          toggleOptions={() => handleCancelDeleteFile()}
        />
      )}
    </div>
  );
};

const ParentDirectoryIcon = props => {
  return (
    <React.Fragment>
      {props.isRootFolder ? (
        <React.Fragment>
          <div style={{ display: "inline-block" }}>
            <img className="open-directory-icon" src={FolderIcon} />
          </div>
        </React.Fragment>
      ) : (
        <div style={{ display: "inline-block" }}>
          {props.expanded ? (
            <React.Fragment>
              <img
                className="rotate-icon arrow"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNCA0Ljc1TDEgOC41IiBzdHJva2U9IiM0QjRCNEIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
              />
              <img className="open-directory-icon" src={FolderOpenIcon} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img
                className="arrow"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNCA0Ljc1TDEgOC41IiBzdHJva2U9IiM0QjRCNEIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
              />
              <img className="closed-directory-icon" src={FolderIcon} />
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

DirectoryBuilder.propTypes = {
  name: PropTypes.string,
  folderPath: PropTypes.string,
  isRootFolder: PropTypes.bool,
  openedDirectory: PropTypes.string,
  onOpenDirectoryContent: PropTypes.func.isRequired,
  onToggleDirectory: PropTypes.func.isRequired,
  toggledDirectories: PropTypes.object,
  children: PropTypes.array
};

export default DirectoryBuilder;
