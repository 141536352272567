import React from 'react';
import moment from 'moment'

const TimeItem = ({ time }) => {
    const formattedTime = moment(time).format('dddd, MMMM Do')
    return (
        <div className="time-item">
            <p>{formattedTime}</p>
        </div>
    )
};

export default TimeItem;