import React from "react";

const expansionInfoPanel = ({ title, tooltip, content }) => {
  return (
    <div className="expansionInfoPanel">
      <div className="expansionInfoPanel__header">
        <div>
          {title}{" "}
          <img src="/images/img/information.svg" data-tip={tooltip ? tooltip : ""} />
        </div>
        {/* <span>SEE ALL</span> */}
      </div>
      <hr />
      <div className="expansionInfoPanel__content">{content}</div>
    </div>
  );
};

export default expansionInfoPanel;
