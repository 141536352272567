import React from "react";
import StarRatingComponent from "react-star-rating-component";
import PostRatePost from "./post-rate-post";

const PostRating = ({
  rate,
  rateCount,
  submit,
  openRate,
  setOpenRate,
  rated,
  isOwner,
  isCardView = false,
  applyHoverEffect
}) => {

  const openRateModal = () => {
    if (applyHoverEffect) {
      applyHoverEffect(true)
    }
    setTimeout(function () {
      setOpenRate(true);
    }, 500);
  }

  const closeRateModal = () => {
    if (applyHoverEffect) {
      applyHoverEffect(false)
    }
    setOpenRate(false)
  }

  return (
    <div className="post-rating">
      <div className="post-rating-info">
        {
          !isCardView && (
            <span className="post-rating-title">
              <img src="/images/img/information.svg" data-tip="Educational Value" />
            &nbsp;
              {(rate || 0).toFixed(1)}&nbsp;
            </span>
          )
        }
        <StarRatingComponent starCount={5} value={rate} />
        {(!isOwner && isCardView) ? (
          <div
            style={{ "cursor": "pointer" }}
            data-tip="Rate"
            onClick={() => openRateModal()}
          >
            <span>&nbsp;({rateCount})</span>
          </div>
        ) : <span>&nbsp;({rateCount})</span>
        }
      </div>
      {!isOwner && !isCardView && (
        <div className="submit-button-wrapper">
          <button onClick={() => setOpenRate(true)}>
            <img src="/images/img/wf-plus.svg" alt="" /> Rate
          </button>
        </div>
      )}
      {openRate && (
        <PostRatePost
          rated={rated}
          close={() => closeRateModal()}
          submit={rate => submit(rate)}
        />
      )}
    </div>
  );
};

export default PostRating;
