import React from 'react'
import moment from 'moment'
import NotifcationIcon from 'icons/notification-icon.svg'
import MuteIcon from 'icons/mute-icon.svg'
import altIconGroup from 'icons/tumor-board-default.jpg'

const GroupItem = ({
  imgSrc,
  name,
  notifcation,
  mute,
  collapse,
  index,
  selectedGroupItem,
  active,
  creator,
  created,
  footerNav,
}) => {
  const formatedDate = moment(created).format('MMM DD')
  const addDefaultSrc = ev => {
    ev.target.src = '/images/icons/settings/tumor-board-default.jpg'
  }
  const addDefaultSrcMobile = ev => {
    ev.target.src = altIconGroup
  }
  if (!footerNav) {
    return (
      <div
        className={`group-item-component ${active ? 'active' : ''}`}
        onClick={() => {
          selectedGroupItem(index)
        }}
      >
        <div className="group-img">
          <img
            className="icon-img"
            src={
              imgSrc ? imgSrc : '/images/icons/settings/tumor-board-default.jpg'
            }
            alt={name}
            onError={addDefaultSrc}
          />
        </div>
        {!collapse && (
          <div className="layout">
            <div className="title">
              <div className="leftside">
                <h4>{name}</h4>
                {mute && <img src={MuteIcon} alt="mute" />}
              </div>
            </div>
            <div className="description">
              <p>{`Created by: ${creator.firstname} ${creator.surname}`}</p>
              <p style={{textAlign: "right", width: "30%"}}>{formatedDate}</p>
              {notifcation && <img src={NotifcationIcon} alt="notification" />}
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div
        className={`group-item-component ${active ? 'active' : ''}`}
        onClick={() => {
          selectedGroupItem(index, null, true), footerNav('middle')
        }}
      >
        <img
          className="icon-img group-img"
          src={
            imgSrc ? imgSrc : '/images/icons/settings/tumor-board-default.jpg'
          }
          alt="icon"
          onError={addDefaultSrcMobile}
        />
        {!collapse && (
          <div className="layout">
            <div className="title">
              <div className="leftside">
                <h4>{name}</h4>
                {mute && <img src={MuteIcon} alt="mute" />}
              </div>
            </div>
            <div className="description">
              <p>{`Created by: ${creator.firstname} ${creator.surname}`}</p>
              <p style={{textAlign: "right", width: "30%"}}> {formatedDate}</p>
              {notifcation && <img src={NotifcationIcon} alt="notification" />}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default GroupItem