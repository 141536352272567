import React, { useState } from "react";
import Overlay from '../../others/overlay'
import PostItHeader from "./header";
import PostItQuorumMiddle from "./post-it-quorum-middle";
import PostItQuorumAction from "./post-it-quorum-action";
import { FadeIn } from 'animate-components'
import { withRouter } from "react-router-dom";


const PostItQuorum = ({back}) => {
  const [isHide, setisHide] = useState(false);


  return (
    <div style={isHide? {display: 'none'}: {display: 'block'}}>
        <Overlay />
    <div className="post-main" style={{ left: '50%' }}>
        <FadeIn duration="300ms">
          <PostItHeader hideQuorumPopup={back} headerName={"Quorum"} />
          <PostItQuorumMiddle/>
          <PostItQuorumAction hideQuorumPopup={back}/>
       </FadeIn>
    </div>
    </div>
  );
};

export default withRouter(PostItQuorum);
