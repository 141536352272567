import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import CalenderIcon from "./icons/calendar.svg";
import LocationIcon from "./icons/location.svg";
import { isPrivate, humanReadable } from "../../../utils/utils";
import { isAdmin } from "../../../utils/admin-utils";
import { cLoading } from "../../../utils/utils";
import IsLoading from "../../others/isLoading";
import "../Profile.scss";

const ProfilePageLayout = ({
  ud,
  session,
  match,
  isFollowing,
  updateState,
  setRef,
  tab,
  tabType,
  ExtraTopSection,
  ChangeAvatarSection,
  TopRightSection,
  TabsSection,
  MainContentSection,
  TooltipSection,
  SocialGraph,
  when
}) => {
  const [notPrivate, setNotPrivate] = useState(true);
  const [imgSrc, setImgSrc] = useState("/images/default-avatar.svg");
  const [username, setUsername] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const joined = ud.joined;
  const date = new Date(Number(joined));
  const dateParts = date.toString().split(" ");
  const joining_date = dateParts[1] + " " + dateParts[2] + ", " + dateParts[3];

  useEffect(() => {
    let _username = match.params.username;
    _username = typeof _username !== "undefined" ? _username : session.username;
    setUsername(_username);

    if (_username === ud.username) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [match, session, ud]);

  useEffect(() => {
    const { id, account_type } = ud;
    setNotPrivate(!isPrivate(id, isFollowing, account_type));
    document.body.style.overflowY = "scroll";
    return () => {
      document.body.style.overflow = "hidden";
    };
  }, [ud, isFollowing]);

  useEffect(() => {
    const { id, account_type } = ud;
    setNotPrivate(!isPrivate(id, isFollowing, account_type));
  }, [ud, isFollowing]);

  useEffect(() => {
    switchActiveTab(tab);
  }, [tab]);

  useEffect(() => {
    const { id } = ud;
    if (id) {
      setImgSrc(`/users/${id}/avatar.jpg`);
    }
  }, [ud]);

  const switchActiveTab = currActiveTab => {
    const el = document.querySelector(`.tabs > #${currActiveTab}.${tabType}`);
    if (el) {
      const activeTab = document.querySelectorAll(`.tabs > .${tabType}.active`);
      activeTab.forEach(i => i.classList.remove("active"));
      el.classList.add("active");
    }
    window.scrollTo(0,0)
  };

  const changeTab = e => {
    const target = e.target;
    if (target.classList.contains(tabType)) {
      updateState({ tab: target.id });
    }
  };

  return (
    <React.Fragment>
      <div className={`profile-main-container ${SocialGraph ? 'social-graph-container': ''}`}>
        <IsLoading loading={isLoading} when="page" />
        {notPrivate || isAdmin() ? (
          <div className={`profile-page ${cLoading(isLoading)}`} ref={setRef}>
            <div className="top">
              <div className="bottom-part">
                <div className="tabs" onClick={changeTab}>
                  {TabsSection}
                </div>
              </div>
              {when !== "mainProfile" && (
                <div className="top-part">
                  <img
                    src={imgSrc}
                    className="user-img"
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = "/images/default-avatar.svg";
                    }}
                  />
                  <div className="user-segment">
                    <div className="user-data">
                      <h2 className="username">
                        {ud.firstname} {ud.surname}
                      </h2>

                      <div className="joined_on sub-data">
                        <img src={CalenderIcon} alt="calender icon" />
                        <span>Joined on {joining_date}</span>
                      </div>
                    </div>
                    {ExtraTopSection}
                  </div>
                  {ChangeAvatarSection}
                  <div className="top-right">{TopRightSection}</div>
                </div>
              )}
            </div>
            {MainContentSection}
          </div>
        ) : (
          <div className="tab2-3">
            <Nothing
              mssg={`Account is private. Follow to connect with ${username}`}
              secondMssg={
                mutuals.length != 0
                  ? humanReadable(mutuals.length, "mutual follower")
                  : ""
              }
            />
          </div>
        )}
        {TooltipSection}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = store => ({
  ud: store.User.user_details,
  session: store.User.session,
  isFollowing: store.Follow.isFollowing,
  mutuals: store.User.mutualUsers
});

export default connect(mapStateToProps)(withRouter(ProfilePageLayout));
