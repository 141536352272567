import React, { Component } from "react";
import { connect } from "react-redux";
import PostTop from "./post-top/post-top";
import PropTypes from "prop-types";
import PostActions from "./post-actions/post-actions";
import PostBottom from "./post-bottom/post-bottom";
import AppLink from "../../others/link/link";
import ExpansionInfoPanel from "./expansion-info-panel/expansion-info-panel";
import PostLableAndPostDes from "./post-lableAndPostDes/post-lableAndPostDes";
import Viewer from "../../image-viewer/ImageViewer";
import nextIcon from "../icons/next.svg";
import PostRating from "./post-rating/post-rating";
import PostDesc from "./post-desc/post-desc";
import PostLabels from "./post-labels/post-labels";
import PostSignupBanner from "./post-bottom/post-signup-banner";
import axios from "../../../utils/axios-instance-utils";
import Notify from "handy-notification";
import { editPostFeed, editPost } from "../../../actions/post";
import PostPoll from "../polling/post-poll";
import ThumbnailListPanel from "./thumbnail-list-panel/thumbnail-list-panel";
import PostActionsNew from "./post-actions-new/post-actions-new";

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      index: 0,
      zoom: 0,
      openRate: false
    };
    this.goPrev = this.goPrev.bind(this);
    this.goNext = this.goNext.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
  }

  goPrev = () => {
    let { index } = this.state;
    this.setState({ index: index - 1 });
  };

  goNext = () => {
    let { index } = this.state;
    this.setState({ index: index + 1 });
  };

  goTo = i => {
    this.setState({ index: i });
  };

  zoomIn = () => {
    let { zoom } = this.state;
    this.setState({ zoom: zoom + 1 });
  };

  zoomOut = () => {
    let { zoom } = this.state;
    this.setState({ zoom: zoom - 1 });
  };

  componentDidMount = async () =>
    this.setState({ description: this.props.description });

  submitRating = async rate => {
    const { post_id, updatePost } = this.props;
    let res;
    try {
      res = await axios.post(
        `/api/posts/${post_id}/rates`,
        { rate },
        { withCredentials: true }
      );
    } catch (err) {
      Notify({ value: "Error occurred" });
      return;
    }
    // update new rate for post
    Notify({ value: "Rated successfully" });
    this.openRatePopup(false);
    updatePost(
      res.data.post_id,
      res.data.rate,
      res.data.rate_count,
      res.data.user_rate
    );
  };

  openRatePopup = async open => {
    this.setState({
      openRate: open
    });
  };

  render() {
    let {
      when,
      share_by_username,
      isMobile,
      post_id,
      postid_token,
      dzi_info,
      rate,
      rate_count,
      my_rate,
      user,
      sessionUserId,
      description,
      isLoggedUser,
      post_status,
      case_slove,
      goTo
    } = this.props;
    let { index, zoom, openRate } = this.state;
    return (
      <div
        id={`post_${post_id}`}
        className="posts dzi-post"
        style={
          case_slove ? { borderTop: "10px solid #6BDFAD" } : { border: "none" }
        }
      >
        {when == "shared" && (
          <div className="post_share_info">
            by{" "}
            <AppLink
              url={`/profile/${share_by_username}`}
              label={share_by_username}
            />
          </div>
        )}

        <PostTop
          postDetails={{
            ...this.props,
            description
          }}
          isQuorum={false}
          postStatus={post_status}
          updateDescription={value => this.setState({ description: value })}
          isMobile={isMobile}
          isLoggedUser={isLoggedUser}
          rate={rate}
          rateCount={rate_count}
          rated={my_rate}
          submit={this.submitRating}
          openRate={openRate}
          setOpenRate={this.openRatePopup}
          isOwner={user === sessionUserId}
        />
        <div className="post-container">
          {/* {isLoggedUser && (
            <PostRating
              rate={rate}
              rateCount={rate_count}
              rated={my_rate}
              submit={this.submitRating}
              openRate={openRate}
              setOpenRate={this.openRatePopup}
              isOwner={user === sessionUserId}
            />
          )} */}
          <PostDesc
            isLoggedUser={isLoggedUser}
            postDetails={{ ...this.props }}
            goTo={goTo}
          />
          {this.props.poll && (
            <PostPoll
              poll={this.props.poll}
              postId={this.props.post_id}
              isOwner={user === sessionUserId}
              isLoggedUser={isLoggedUser}
              when={when}
            />
          )}
          <div className="wf-post-body">
            <div className="image-viewer-container">
              <div className="viewer-container-case-stream">
                <Viewer
                  when={"central_feed"}
                  slideBoxId={
                    dzi_info.length !== 0 ? dzi_info[index].slide_box_id : null
                  }
                  zoom={zoom}
                  viewerId={`osd-viewer${post_id}`}
                  postId={postid_token}
                  keyFolder={
                    dzi_info.length !== 0 ? dzi_info[index].key_folder : null
                  }
                />
              </div>
            </div>
            <div className="wf-post-meta">
              <ThumbnailListPanel
                title="Case Series"
                tooltip="Click to view and hover to see stain"
                thumbnails={this.props.dzi_info}
                onThumbnailClick={this.goTo}
              />
              <ExpansionInfoPanel
                title="Labels"
                tooltip="Click to search"
                content={<PostLabels postDetails={{ ...this.props }} />}
              />
              <PostActionsNew
                postDetails={{ ...this.props }}
                isMobile={isMobile}
                commentBoxId={`comment_box_${post_id}`}
                type="case"
                openRate={this.openRatePopup}
                isOwner={user === sessionUserId}
              />
            </div>
          </div>
        </div>

        {}
        {}
        {}

        {isLoggedUser && (
          <PostBottom
            postDetails={{ ...this.props }}
            commentBoxId={`comment_box_${post_id}`}
            type="case"
          />
        )}
        {isLoggedUser === false && <PostSignupBanner />}
      </div>
    );
  }
}

Post.propTypes = {
  post_id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  user: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  imgSrc: PropTypes.array.isRequired,
  type: PropTypes.oneOf(["user", "group"]).isRequired,
  group_id: PropTypes.number,
  group_name: PropTypes.string,
  post_time: PropTypes.string.isRequired,
  share_by_username: PropTypes.string,
  share_time: PropTypes.string,
  when: PropTypes.oneOf([
    "feed",
    "viewPost",
    "userPosts",
    "bookmarks",
    "shared",
    "tagged",
    "groupPosts",
    "hashtag"
  ]).isRequired,
  likes_count: PropTypes.number.isRequired,
  shares_count: PropTypes.number.isRequired,
  comments_count: PropTypes.number.isRequired,
  tags_count: PropTypes.number.isRequired,
  comments: PropTypes.array,
  case_slove: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  const { session } = state.User;
  return {
    sessionUserId: session.id
  };
};
const mapDispatchToProps = dispatch => ({
  updatePost: (post_id, rate, rate_count, my_rate) => {
    // update both post on feed and on view post
    dispatch(
      editPostFeed({
        post_id,
        rate,
        rate_count,
        my_rate
      })
    );
    dispatch(
      editPost({
        post_id,
        rate,
        rate_count,
        my_rate
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Post);
