import React from "react";

const UserCard = ({ user, addSelf, id }) => {
  return (
    user.id !== id && (
      <div className="userCard" onClick={() => addSelf()}>
        <img
          // TODO: remove absolute link
          src={`/users/${user.id}/avatar.jpg`}
          style={{
            width: "40px",
            height: "40px",
            margin: "45px 5px 5px 10px",
            borderRadius: "25px"
          }}
          onError={e => {
            e.target.onerror = null;
            e.target.src = "/images/default-avatar.svg";
          }}
        />
        <div
          style={{
            height: "55px",
            display: "flex",
            flexDirection: "column",
            paddingTop: "7px"
          }}
        >
          <p className="searchName">
            {(typeof user.firstname !== "undefined"
              ? user.firstname
              : user.firstName) +
              " " +
              user.surname}
          </p>
          <p className="searchName">@{user.username}</p>
        </div>
      </div>
    )
  );
};

export default UserCard;
