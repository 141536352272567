import React from 'react'
import { connect } from 'react-redux'
import { CPP } from '../../../actions/post'
import ImageUploadSection from './image-upload'
import MultiSelect from './select-search'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
const options = []

const PostItMiddle = ({
  postIt,
  session,
  isMobile,
  back,
  dispatch,
  parent,
  images,
  hidePostModal,
}) => {
  let { username } = session
  let { fileChanged, desc, previewImg, filter, fileInput } = postIt

  let dp = (...args) => dispatch(CPP(...args))

  let fileChange = files => {
    if (files) {
      dp('fileChanged', true)
      dp('targetFiles', files)
      if (images) {
        dp('fileType', 'blob')
      } else {
        dp('fileType', 'file')
      }
    }
  }

  // let valueChange = e => dp("desc", e.target.value);

  let handleSelectChange = selectedOption => {
    const labelTags = selectedOption.map(label => label.value)
    dp('labeltags', labelTags)
  }

  const handleChange = value => dp('desc', value)

  return (
    <div
      className="i_p_main p_main p_details_upload_container"
      style={{ height: 'auto' }}
    >
      <React.Fragment>
        <div className="p_main_upload_section">
          <ImageUploadSection
            hidePostModal={hidePostModal}
            isMobile={isMobile}
            back={back}
            fileChange={fileChange}
            images={images}
            parent={parent}
          />
        </div>
        <div className="p_main_comment_section row">
          <div className="pull-left col-lg-6">
            <div className="label">ADD A CAPTION TO YOUR CASE</div>
            <ReactQuill value={desc} onChange={handleChange} />
          </div>
          <div className="pull-right col-lg-6">
            <div className="control-group">
              <div className="controls">
                <div className="label"> BEST DESCRIBES YOUR CASE</div>
                <MultiSelect
                  classname="select_case"
                  value={null}
                  handleChange={handleSelectChange}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

const mapStateToProps = state => ({
  session: state.User.session,
  postIt: state.Post.postIt,
})

export default connect(mapStateToProps)(PostItMiddle)
export { PostItMiddle as PurePostItMiddle }
