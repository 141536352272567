import PostItInitialState from './postit-initial-state'

export default {
  posts: [],
  bookmarks: [],
  quorums: [],
  casesAndQuorums: [],
  tagged: [],
  shared: [],
  photos: [],
  feed: [],
  viewPost: {},
  likes: [],
  tags: [],
  posted: false,
  isPostMine: false,
  usersToShare: [],
  sharers: [],
  postIt: PostItInitialState,
  isFeedLoad: false,
  isAllFeedLoad: false,
  currentFeedPage: 1,
  totalCaseAndQuorum: 0,
  totalBookmark: 0,
  currentCaseAndQuorumPage: 0,
  currentBookmarkPage: 0,
  isProfileLoaded: false,
  isBookmarkLoaded: false,
  isAllCasesLoaded: false,
  isAllBookmarkLoaded: false,
  currentProfile: null,
  isGridView: false,
  viewQuorum: {},
  userAnnotations: [],
  annotationColor: {},
  isPublicQuorum: false,
}
