import React, { useState } from "react";
import "./slidebox-component.scss";
import _ from "lodash";
import moment from "moment";
import axios from "../../../utils/axios-instance-utils";
import DirectoryBuilder from "./directory-builder";
import defaultFileIcon from "./icons/default-file.svg";
import verticalThreeDotsIcon from "./icons/vertical-three-dots.svg";
import { withRouter } from "react-router-dom";
import MoreMenu from "./MoreMenu";
import Notify from "handy-notification";
import PrimaryButton from "../../others/button/primary-btn";
import { connect } from "react-redux";
import {
  loadQuorum,
  setSlideBoxStorageLimit,
  setFolders,
  setSelectedUploadFolder,
  setSlideBoxStorageSize
} from "../../../actions/slideBox";
import { getUserDetails } from "../../../actions/user";
import CreateNewFolderPopUp from "./CreateNewFolder";
import DeleteConfirm from "./deleteConfirm";
import MoveFile from "./move-file";
import FolderDetails from "./folder-details";
import GroupsSelection from "./GroupsSelection";
import { SocketContext } from "../../../services/ServiceContext";
import InfoIcon from "./icons/InfoIcon.svg";
import SlideboxPagination from "./slidebox-pagination";
import Tooltip from "react-tooltip";
import { isMine } from "../../../utils/utils";
import SlideboxUsageBar from "./slidebox-usage-bar.js";
import NewIcon from "../../../assets/icons/add_new_folder.svg";
import UploadIcon from "../../../assets/icons/upload-slide.svg";
import MoveIcon from "./icons/move.svg";
import RenameIcon from "./icons/rename.svg";
import CollaborateIcon from "./icons/collaborate.svg";
import ViewDetailsIcon from "./icons/view-details.svg";
import DeleteIcon from "./icons/delete.svg";
import GetLinkIcon from "./icons/get-link.svg";
import OpenViewerIcon from "./icons/open-viewer.svg";
import AddToGroup from "./icons/add-to-group.svg";
import copyIcon from "./icons/copyIcon.svg";
import SearchIcon from "../../../assets/icons/search-icon.svg";

class SlideBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggledDirectories: {
        slidebox: false
      },
      openedDirectory: _.get(props, "location.state.folderPath") || "",
      listOfS3Directories: [],
      listOfSharedDirectories: [],
      listOfPublicDirectories: [],
      selectedRowIndex: {},
      selectedFiles: [],
      preventSelection: false,
      showRenameFilePopUp: false,
      isFile: false,
      rename: false,
      showDeletePopUp: false,
      selectedFile: null,
      selectedFileIndex: -1,
      selectedFolder: null,
      selectedFolderIndex: -1,
      folderPath: sessionStorage.getItem("selectedFolderPath"),
      showMoveFilePopup: false,
      showFolderDetailsPopup: false,
      activeParentFolder: null,
      showGroupsSelectionPopup: false,
      fileSort: {
        fileName: 0,
        fileSize: 0,
        uploadTime: 1
      },
      isShared: false,
      folderDetails: null,
      isSharedFolderSelect: false,
      isPublicFolderSelect: false,
      beforeSearchS3Objects: [],
      paginationPage: 1,
      paginationPerPage: 10,
      totalPageCount: null,
      searchValue: null,
      previousSearchValue: null,
      isSort: false,
      sortOrderField: null,
      sortOrderType: null,
      folderId: null,
      currentUsageBytes: 0,
      totalStorageBytes: 0,
      showPublic: true,
      folderDeleted: false,
      folderRootId: null
    };
    this.baseState = {
      searchValue: null,
      paginationPage: 1,
      isSort: false,
      sortOrderField: null,
      sortOrderType: null,
      fileSort: {
        fileName: 0,
        fileSize: 0,
        uploadTime: 1
      }
    };
    this.slideBoxSearchRef = React.createRef();
    this.currentRequestCanceller = undefined;
  }

  static contextType = SocketContext;
  componentDidMount = () => {
    const { username } = this.props.user_session;
    this.context.onConversionDone().subscribe(data => {
      this.loadListOfDirectoryFiles(
        sessionStorage.getItem("selectedFolderPath"),
        false
      );
    });

    this.props.loadUserData(username);
    if (this.props.location.state) {
      const { folderPath } = this.props.location.state;
      const path = folderPath;
      this.loadS3Tree(path);
    } else {
      this.loadS3Tree("");
    }
    this.slideBoxSearchRef.current.addEventListener(
      "keydown",
      this.handleSearchKeyDown
    );

    this.props.getSlideBoxStorageSize();
  };

  componentWillUnmount() {
    this.slideBoxSearchRef.current.removeEventListener(
      "keydown",
      this.handleSearchKeyDown
    );
  }

  loadS3Tree(folderCreate = false) {
    this.setState({ isLoadingS3Directories: true });
    axios
      .get("/api/getS3TreeData")
      .then(response => {
        response.data[0].folderPath = "slidebox";
        response.data[0].isRootFolder = true;
        response.data[1].folderPath = "shared";
        response.data[1].isRootFolder = true;
        response.data[2].folderPath = "public";
        response.data[2].isRootFolder = true;

        const selectedFolderId = this.props.selectedFolder
          ? this.props.selectedFolder.id
          : null;
        const isPublicFolder = this.props.selectedFolder
          ? this.props.selectedFolder.isPublicFolder
          : null;
        const isSharedFolder = this.props.selectedFolder
          ? this.props.selectedFolder.isPublicFolder
          : null;

        const previousFolder = selectedFolderId ? selectedFolderId : "slidebox";
        sessionStorage.setItem(
          "slideBoxRootFolderId",
          response.data[0].folder_id
        );
        const rootId = sessionStorage.getItem("slideBoxRootFolderId");
        const filteredByIsFolderFlag = filterByIsFolderFlag([response.data[0]]);
        const sharedDirs = filterByIsFolderFlag([response.data[1]]);
        const publicDirs = filterByIsFolderFlag([response.data[2]]);
        this.setState({
          listOfS3Directories: filteredByIsFolderFlag,
          listOfSharedDirectories: sharedDirs,
          listOfPublicDirectories: publicDirs,
          isLoadingS3Directories: false,
          folderId: response.data[0].folder_id,
          toggledDirectories: this.buildToggledDirectoryTree(
            response.data[0],
            previousFolder
          ),
          folderRootId: rootId
        });
        this.props.setStoreFolders(filteredByIsFolderFlag);
        folderCreate = folderCreate || selectedFolderId;
        this.loadAfterFileUpload(
          previousFolder,
          folderCreate,
          isSharedFolder,
          isPublicFolder
        );
      })
      .catch(error => {
        // console.log(error);
        this.setState({ isLoadingS3Directories: false });
      });
  }

  getActivePath = (activeList, item, previousFolder) => {
    let active = item.folder_id == previousFolder;
    if (active) {
      activeList.push(item.folder_id);
      return active;
    }

    if (!item.children || item.children.length == 0) {
      return false;
    }

    let finalActive = false;
    for (let i = 0; i < item.children.length; i++) {
      let active = this.getActivePath(
        activeList,
        item.children[i],
        previousFolder
      );
      finalActive = finalActive || active;
    }

    if (finalActive) {
      activeList.push(item.folder_id);
    }

    return finalActive;
  };

  buildToggledDirectoryTree = (start, previousFolder) => {
    let childreanList = start.children;
    let re = this.state.toggledDirectories;
    if (!childreanList || childreanList.length < 1) {
      return re;
    }
    let activeList = [];
    if (previousFolder) {
      let index = 0;
      while (activeList.length == 0 && index < childreanList.length) {
        this.getActivePath(activeList, childreanList[index], previousFolder);
        index++;
      }
    }
    let loopList = _.cloneDeep(childreanList);
    while (loopList.length !== 0) {
      let item = loopList.pop();
      let active = false;
      if (previousFolder) {
        active =
          activeList.indexOf(item.folder_id) !== -1 &&
          item.folder_id != previousFolder;
      }
      if (!item.children || item.children.length == 0) {
        // leaf dir
        re = _.merge(re, { [item.folder_id]: active });
      } else {
        re = _.merge(re, { [item.folder_id]: active });
        loopList = loopList.concat(item.children);
      }
    }
    return re;
  };

  loadAfterFileUpload = (
    folderPath,
    folderCreate,
    isSharedFolder,
    isPublicFolder
  ) => {
    this.onToggleDirectory(folderPath, folderCreate);
    this.onOpenDirectoryContent({
      directoryPath: folderPath,
      isSharedFolder,
      isPublicFolder
    });
  };

  loadAfterFolderCreate = () => {
    this.loadS3Tree(true);
  };

  onToggleDirectory = (
    folderPath,
    folderCreate = false,
    closeDirectory = false
  ) => {
    if (folderPath !== "slidebox" && !closeDirectory) {
      this.setState({
        activeParentFolder: folderPath
      });
    }
    const toOpenPaths = folderCreate ? this.getParentPaths(folderCreate) : [];
    const mergedDirs = {
      [folderPath]: folderCreate
        ? true
        : closeDirectory
        ? false
        : !this.state.toggledDirectories[folderPath]
    };
    toOpenPaths.forEach(path => {
      mergedDirs[path] = true;
    });
    const toggledDirectories = _.merge(
      {},
      this.state.toggledDirectories,
      mergedDirs
    );
    this.setState({ toggledDirectories });
  };

  /**
   * get parent paths of a path, from 'slidebox/:userId/'
   * {path: String}
   */
  getParentPaths = path => {
    let paths = [];
    for (let i = 0; i < path.length; i++) {
      if (path[i] === "/") {
        paths.push(path.slice(0, i + 1));
      }
    }
    paths = paths.filter(p => p); // only get unempty paths
    return paths.slice(2); // exclude slidebox/ and slidebox/:userId/
  };

  onOpenDirectoryContent = async ({
    directoryPath,
    onFolderClick = false,
    parentFolder,
    isSharedFolder = false,
    name = null,
    isPublicFolder = false
  }) => {
    this.setState({ selectedRowIndex: {} });
    if (this.slideBoxSearchRef.current) {
      this.slideBoxSearchRef.current.value = "";
    }
    await this.setState({
      isPublicFolderSelect: isPublicFolder,
      isSharedFolderSelect: directoryPath === "public" ? true : isSharedFolder,
      ...this.baseState
    });
    const activeParentFolder = this.state.activeParentFolder;
    if (
      sessionStorage.getItem("selectedFolderPath") !== "slidebox" &&
      onFolderClick &&
      activeParentFolder !== parentFolder
    ) {
      this.onToggleDirectory(activeParentFolder, false, true);
      this.setState({ activeParentFolder: null });
    }
    const folderPath = directoryPath ? directoryPath : "slidebox";
    const folderId =
      folderPath === "slidebox"
        ? this.state.folderId
        : isPublicFolder
        ? this.state.folderId
        : folderPath;

    sessionStorage.setItem("selectedFolderPath", folderPath);
    sessionStorage.setItem("selectedFolderId", folderId);
    this.props.setSelectedFolder({
      id: this.state.folderDeleted ? this.state.rootId : folderId,
      name: name,
      directoryPath: this.state.folderDeleted ? "slidebox" : directoryPath,
      isSharedFolder,
      isPublicFolder
    });
    this.setState({
      folderPath: folderPath
    });
    const fileLoadPath = directoryPath ? directoryPath : folderPath;
    const openedDirectoryPath =
      fileLoadPath === "slidebox"
        ? "slidebox/"
        : fileLoadPath === "shared"
        ? "shared/"
        : folderPath;
    if (directoryPath === this.state.directoryPath) return;
    this.setState({ openedDirectory: openedDirectoryPath });
    this.loadListOfDirectoryFiles(
      fileLoadPath,
      undefined,
      isSharedFolder,
      isPublicFolder
    );
  };

  loadListOfDirectoryFiles = (
    directoryPath,
    loading = true,
    isSharedFolder = false,
    isPublicFolder = false,
    sort = this.state.isSort,
    page = this.state.paginationPage,
    perPage = this.state.paginationPerPage
  ) => {
    if (this.state.isLoadingDirectoryContent) return;
    const payload = {
      folder: isSharedFolder
        ? "shared"
        : sessionStorage.getItem("selectedFolderPath"),
      folderId:
        directoryPath === "slidebox"
          ? this.state.folderId
          : isPublicFolder ||
            sessionStorage.getItem("selectedFolderPath") === "public"
          ? sessionStorage.getItem("selectedFolderPath") === "public"
            ? parseInt(sessionStorage.getItem("selectedFolderId"))
            : parseInt(sessionStorage.getItem("selectedFolderPath"))
          : directoryPath,
      page:
        (this.state.searchValue !== null &&
          this.state.previousSearchValue === null) ||
        (this.state.previousSearchValue !== this.state.searchValue &&
          this.state.searchValue !== null)
          ? 1
          : page,
      perPage,
      isSharedFolder,
      isPublicFolder,
      searchString: this.state.searchValue,
      sortOrderField: sort ? this.state.sortOrderField : null,
      sortOrderType: sort ? this.state.sortOrderType : null
    };
    if (isSharedFolder) {
      const { listOfSharedDirectories } = this.state;
      const foundSharedDirectory = listOfSharedDirectories[0]; // there is only one shared dir currently
      if (foundSharedDirectory)
        payload.collaboratorSlideId = foundSharedDirectory.collaboratorSlideId;
    }
    if (loading) {
      this.setState({ isLoadingDirectoryContent: true });
    }
    // stop current uploader
    this.currentRequestCanceller && this.currentRequestCanceller();
    const config = {
      cancelToken: new axios.CancelToken(cancel => {
        this.currentRequestCanceller = cancel;
      })
    };
    axios
      .post("/api/listS3ObjectsWithPagination", payload, config)
      .then(response => {
        if (response.status === 200) {
          let data = (response.data && response.data.pageData) || [];
          let pageCount = (response.data && response.data.pageCount) || 1;
          data = this.modifyS3ObjectList(
            data,
            isSharedFolder
              ? isSharedFolder
              : directoryPath === "shared"
              ? true
              : isSharedFolder
          );
          this.setState({
            isLoadingDirectoryContent: false,
            listOfDirectoryFiles: data,
            listOfOriginalDirectoryFiles: data,
            totalPageCount: pageCount,
            previousSearchValue: this.state.searchValue
          });
        }
      })
      .catch(error => {
        if (error.__CANCEL__) return;
        this.setState({ isLoadingDirectoryContent: false });
        //console.log(error);
      });
  };

  onPageChange = async page => {
    this.setState({
      paginationPage: page
    });
    const {
      folderPath,
      isSharedFolderSelect,
      isPublicFolderSelect
    } = this.state;
    this.loadListOfDirectoryFiles(
      folderPath,
      true,
      isSharedFolderSelect,
      isPublicFolderSelect,
      undefined,
      page,
      undefined
    );
  };

  onPerPageChange = perPage => {
    this.setState({
      paginationPerPage: perPage
    });
    const {
      folderPath,
      isSharedFolderSelect,
      isPublicFolderSelect
    } = this.state;
    this.loadListOfDirectoryFiles(
      folderPath,
      true,
      isSharedFolderSelect,
      isPublicFolderSelect,
      undefined,
      undefined,
      perPage
    );
  };

  handleRename = (file, fileIndex, isFile = true) => {
    this.setState({
      showRenameFilePopUp: true,
      renameFile: isFile ? file : undefined,
      selectedFolder: isFile ? undefined : file,
      folderPath: file.path,
      isFile: isFile,
      rename: true,
      [isFile ? "selectedFileIndex" : "selectedFolderIndex"]: fileIndex
    });
  };

  confirmDelete = (file, fileIndex) => {
    this.setState({
      showDeletePopUp: true,
      selectedFile: file,
      isFile: true,
      selectedFileIndex: fileIndex
    });
  };

  showFolderDetails = async (file, fileIndex, isFile = false, isShared) => {
    try {
      const folderCollaborators = axios.post("/api/collaboratorUserList", {
        folderPath: isFile ? file.file_path : file.path
      });

      const folderDetails = axios.post("/api/getFolderDetails", {
        folderPath: isFile ? file.file_path : file.path
      });

      const data = await Promise.all([folderCollaborators, folderDetails]);
      await this.setState({
        collaborators: data[0].data.collaborators,
        folderDetails: data[1].data.details,
        showFolderDetailsPopup: true,
        [isFile ? "selectedFile" : "selectedFolder"]: file,
        isFile: isFile,
        isShared: isShared,
        [isFile ? "selectedFileIndex" : "selectedFolderIndex"]: fileIndex
      });
    } catch (error) {
      //console.log(error);
    }
  };

  showFileDetails = async (file, fileIndex, isFile = true, isShared) => {
    try {
      const { data } = await axios.post("/api/collaboratorUserList", {
        folderPath: isFile ? file.file_path : file.path,
        pathId: isFile ? file.slide_box_id : file.path
      });
      await this.setState({
        collaborators: data.collaborators,
        showFolderDetailsPopup: true,
        [isFile ? "selectedFile" : "selectedFolder"]: file,
        isFile: isFile,
        isShared: isShared,
        [isFile ? "selectedFileIndex" : "selectedFolderIndex"]: fileIndex
      });
    } catch (error) {
      //console.log(error);
    }
  };

  showCollaboratorDetails = async (
    file,
    fileIndex,
    isFile = true,
    isShared
  ) => {
    try {
      const { data } = await axios.post("/api/collaboratorUserList", {
        folderPath: isFile ? file.file_path : file.path,
        pathId: isFile ? file.slide_box_id : file.folder_id
      });

      await this.setState({
        collaborators: data.collaborators,
        showFolderDetailsPopup: true,
        [isFile ? "selectedFile" : "selectedFolder"]: file,
        isFile: isFile,
        isShared: isShared,
        [isFile ? "selectedFileIndex" : "selectedFolderIndex"]: fileIndex
      });
    } catch (error) {
      //console.log(error);
    }
  };

  collaborateFiles = async (file, fileIndex) => {
    this.showCollaboratorDetails(file, fileIndex, true, true);
  };

  openFileDetails = async (
    file = this.state.selectedFile,
    fileIndex = this.state.selectedFileIndex
  ) => {
    this.showFileDetails(file, fileIndex, true, false);
  };

  closeFolderDetails = () => {
    this.setState({
      showFolderDetailsPopup: false
    });
  };

  openMoveFile = (file, fileIndex) => {
    this.setState({
      showMoveFilePopup: true,
      selectedFile: file,
      isFile: true,
      selectedFileIndex: fileIndex
    });
  };

  closeMoveFile = () => {
    this.setState({ showMoveFilePopup: false });
    this.loadAfterFolderCreate();
  };

  handleDelete = file => {
    const filePathSplitArray = file.file_path.split("/");
    const fileName = filePathSplitArray[filePathSplitArray.length - 1];
    this.setState({ showDeletePopUp: false });
    const { id } = this.props.user_session;
    const folderPath =
      sessionStorage.getItem("selectedFolderPath") === "slidebox"
        ? `slidebox/${id}/`
        : sessionStorage.getItem("selectedFolderPath");
    Notify({ value: "Deleting" });
    axios
      .post("/api/deleteObjects", {
        imgSrc: `${folderPath}${fileName}`,
        file_name_original:
          Object.keys(file).length > 0 ? file.file_name_original : "",
        keyFolder: Object.keys(file).length > 0 ? file.key_folder : "",
        slideboxId: Object.keys(file).length > 0 ? file.slide_box_id : "",
        slide_box_progress_id:
          Object.keys(file).length > 0 ? file.slide_box_progress_id : "",
        filePath: Object.keys(file).length > 0 ? file.file_path : "",
        fileSize: Object.keys(file).length > 0 ? file.file_size : ""
      })
      .then(result => {
        this.handleAfterDelete(result, file.file_size);
      })
      .catch(error => {
        Notify({ value: "Delete fail" });
        console.log(error);
      });
  };

  handleAddToQuorum = file => {
    this.props.history.push({ pathname: `/quorum` });
    this.props.loadQuorum({
      filename: file.file_name,
      fileid: file.slide_box_id,
      keyfolder: file.key_folder,
      slide: [
        {
          slide_box_id: file.slide_box_id,
          dzi_data: file.dzi_data,
          dzi_url: file.dzi_url,
          src: file.thumbnail,
          mimetype: "image/jpeg"
        }
      ],
      filePath: file.file_path
    });
  };

  handleAddToTumorBoards = file => {
    this.setState({ showGroupsSelectionPopup: true, selectedFile: file });
  };

  closeGroupModel = () => {
    this.setState({ showGroupsSelectionPopup: false });
  };

  handleShowInViewer = file => {
    this.props.history.push({
      pathname: `/viewer`,
      search: `?image=${file.key_folder}&thumb=${file.thumbDataToken}`
    });
  };

  handleCopySlideUrl = () => {
    Notify({ value: "Copied" });
  };

  handleAfterDelete = (result, fileSize) => {
    const folderPath = this.state.folderPath;
    if (result.data.success) {
      this.state.listOfDirectoryFiles.splice(this.state.selectedFileIndex, 1);
      const newUpdatedUsage = {
        ...this.props.slideBoxSize,
        currentUsageBytes: this.props.slideBoxSize.currentUsageBytes - fileSize
      };
      Notify({ value: "File deleted" });
      this.setState({
        selectedFile: false,
        isFile: false,
        listOfDirectoryFiles: [...this.state.listOfDirectoryFiles]
      });
      this.props.updatedSlideBoxSize(newUpdatedUsage);
      this.onOpenDirectoryContent({ directoryPath: folderPath });
    }
  };

  handleFolderCreation = () => {
    this.setState({ showRenameFilePopUp: true, isFile: false, rename: false });
  };

  handleCancelDeleteFile = () => {
    this.setState({
      showDeletePopUp: false,
      selectedFile: null,
      isFile: false
    });
  };

  handleCancelRenameFile = () => {
    this.setState({ showRenameFilePopUp: false });
  };

  handleAfterRenamed = data => {
    this.setState({ showRenameFilePopUp: false });
    Notify({ value: "File rename success" });
    if (data.rename) {
      if (data.isFile) {
        // rename file
        const file = this.state.listOfDirectoryFiles[
          this.state.selectedFileIndex
        ];
        if (!file) return;
        file.file_name = data.newName;
        this.setState({
          listOfDirectoryFiles: [...this.state.listOfDirectoryFiles]
        });
      } else {
        // rename folder
        this.onFolderChanged(true);
      }
    } else {
      // create new
    }
    // this.onOpenDirectoryContent();
  };

  handleAfterFolderCreate = () => {
    this.setState({ showRenameFilePopUp: false });
    Notify({ value: "Folder created successfully" });
    this.loadAfterFolderCreate();
  };

  preventSelection = () => {
    this.setState({ preventSelection: true });
  };

  handleSearchKeyDown = e => {
    const {
      target: { value }
    } = e;
    if (e.keyCode == 13) {
      if (!value) {
        this.setState({ searchValue: null });
        this.searchDirectoryFiles(null);
        // return this.setState({
        //   listOfDirectoryFiles: this.state.beforeSearchS3Objects,
        // })
      }
      this.setState({
        searchValue: value,
        beforeSearchS3Objects: this.state.listOfDirectoryFiles
      });
      this.searchDirectoryFiles(value);
      // this.searchDirectoryFilesInState(value)
    }
  };

  handleLabelClick = label => {
    this.slideBoxSearchRef.current.value = label;
    this.searchDirectoryFiles(label);
  };

  searchDirectoryFilesInState = fileName => {
    this.setState({
      isLoadingDirectoryContent: true
    });
    setTimeout(() => {
      if (!fileName) {
        return this.setState({
          listOfDirectoryFiles: this.state.listOfOriginalDirectoryFiles,
          isLoadingDirectoryContent: false
        });
      }
      const filtedFiles = this.state.listOfOriginalDirectoryFiles.filter(f => {
        return f.file_name.toLowerCase().search(fileName.toLowerCase()) !== -1;
      });
      this.setState({
        listOfDirectoryFiles: filtedFiles,
        isLoadingDirectoryContent: false
      });
    }, 1000);
  };

  modifyS3ObjectList = (data, isSharedFolder) => {
    return data.map(file => {
      const fileExtention = file.file_name.split(".")[1];
      file.fileType =
        fileExtention === "jpg" ||
        fileExtention === "jpeg" ||
        fileExtention === "png"
          ? "Static"
          : "WSI";
      file.isSharedFile = isSharedFolder;
      file.sizeStr = file.file_size
        ? bytesToSize(parseInt(file.file_size))
        : "N/A";
      file.uploadedAt =
        file.upload_time && moment(file.upload_time).format("MM-DD-YYYY hh:mm");
      return file;
    });
  };

  searchDirectoryFiles = async fileName => {
    if (this.state.isLoadingDirectoryContent) return;
    const fileLoadPath = this.state.folderPath;
    const payload = {
      folder: fileLoadPath,
      type: "withOutFolder",
      searchString: fileName,
      page:
        this.state.previousSearchValue === null ||
        this.state.previousSearchValue !== this.state.searchValue
          ? 1
          : this.state.paginationPage,
      perPage: this.state.paginationPerPage
    };
    this.setState({ isLoadingDirectoryContent: true });
    if (
      this.state.previousSearchValue === null ||
      this.state.previousSearchValue !== this.state.searchValue
    ) {
      this.setState({ paginationPage: 1 });
    }

    let response;
    try {
      response = await axios.post("/api/listS3ObjectsWithPagination", payload);
    } catch (err) {
      this.setState({ isLoadingDirectoryContent: false });
      //console.log(err);
    }
    if (response.status === 200) {
      let data = (response.data && response.data.pageData) || [];
      let pageCount = (response.data && response.data.pageCount) || 1;
      data = this.modifyS3ObjectList(
        data,
        fileLoadPath === "shared" ? true : false
      );
      this.setState({
        isSort: false,
        sortOrderField: null,
        sortOrderType: null,
        fileSort: {
          fileName: 0,
          fileSize: 0,
          uploadTime: 1
        },
        isLoadingDirectoryContent: false,
        listOfDirectoryFiles: data,
        listOfOriginalDirectoryFiles: data,
        totalPageCount: pageCount,
        previousSearchValue: this.state.searchValue
      });
    }
  };

  onFolderChanged = () => {
    this.loadS3Tree(true);
  };

  onFolderDelete = data => {
    this.setState({ folderDeleted: data });
    this.props.setSelectedFolder({
      id: this.state.folderRootId,
      directoryPath: "slidebox",
      isSharedFolder: false,
      isPublicFolder: false
    });
  };

  onToggleNestedDirectory = folderPath => {
    this.onToggleDirectory(folderPath);
    this.onOpenDirectoryContent({ directoryPath: folderPath });
  };
  /**
   * not return anything if value is undefined
   */
  getSortIcon = value => {
    if (value === 0) return <i class="fas fa-sort"></i>;
    if (value < 0) return <i class="fas fa-sort-up"></i>;
    if (value > 0) return <i class="fas fa-sort-down"></i>;
  };

  sortData = async (field, value) => {
    const {
      folderPath,
      isSharedFolderSelect,
      isPublicFolderSelect
    } = this.state;
    const pageReset = this.state.sortOrderField !== field ? 1 : undefined;
    await this.setState({
      isSort: value !== 0 ? true : false,
      sortOrderField: field,
      sortOrderType: value > 0 ? "DESC" : "ASC",
      paginationPage: pageReset === 1 ? 1 : this.state.paginationPage
    });
    this.loadListOfDirectoryFiles(
      folderPath,
      false,
      isSharedFolderSelect,
      isPublicFolderSelect
    );
  };

  sort = field => {
    const { fileSort } = this.state;
    if (fileSort[field] === undefined) return;
    let value;
    if (fileSort[field] === 0) {
      value = 1;
    } else if (fileSort[field] < 0) {
      value = 0;
    } else {
      value = -1;
    }
    // get sorted data
    this.sortData(field, value);
    // reset sort
    for (let key in fileSort) {
      fileSort[key] = 0;
    }
    // default data from server is sorted by uploadTime, so we reflect it on the ui
    if (value == 0) {
      // sort criteria reset, use default one
      this.setState({
        fileSort: { ...fileSort, uploadTime: 1 }
      });
    } else {
      // apply new sort
      this.setState({
        fileSort: { ...fileSort, [field]: value }
      });
    }
  };

  onEditFile = async file => {
    const payload = {
      slide_box_id: file.slide_box_id
    };
    if (file.description) {
      payload.description = file.description;
    }
    if (file.labels) {
      payload.labels = file.labels;
    }
    if (file.stain) {
      payload.stain = file.stain;
    }
    let res;
    try {
      res = await axios.put(`/api/slidebox/${file.slide_box_id}`, payload);
    } catch (err) {
      throw err;
    }
    // update state
    const { listOfDirectoryFiles, selectedFileIndex } = this.state;
    listOfDirectoryFiles[selectedFileIndex] = {
      ...listOfDirectoryFiles[selectedFileIndex],
      ...res.data
    };
    this.setState({ listOfDirectoryFiles });
    return res;
  };

  render() {
    let moreMenuItems = [
      { title: "Move", callBack: this.openMoveFile, img: MoveIcon },
      { title: "Rename", callBack: this.handleRename, img: RenameIcon },
      { title: "Share", callBack: this.collaborateFiles, img: GetLinkIcon },
      {
        title: "Add to Groups",
        callBack: this.handleAddToTumorBoards,
        img: AddToGroup
      },
      {
        title: "Open in viewer",
        callBack: this.handleShowInViewer,
        img: OpenViewerIcon
      },
      {
        title: "Details",
        callBack: this.openFileDetails,
        img: ViewDetailsIcon
      },
      {
        title: "Add to Quorum",
        callBack: this.handleAddToQuorum,
        img: CollaborateIcon
      },
      {
        title: "Copy slide url",
        callBack: this.handleCopySlideUrl,
        isCopy: true,
        img: copyIcon
      },
      { title: "Delete", callBack: this.confirmDelete, img: DeleteIcon }
    ];

    let moreMenuSharedItems = [
      {
        title: "Open in viewer",
        callBack: this.handleShowInViewer,
        img: OpenViewerIcon
      },
      {
        title: "Copy slide url",
        callBack: this.handleCopySlideUrl,
        isCopy: true,
        img: copyIcon
      },
      { title: "Details", callBack: this.openFileDetails, img: ViewDetailsIcon }
    ];

    let moreMenuSharedFileItems = [
      {
        title: "Open in viewer",
        callBack: this.handleShowInViewer,
        img: OpenViewerIcon
      },
      {
        title: "Add to Quorum",
        callBack: this.handleAddToQuorum,
        img: CollaborateIcon
      },
      {
        title: "Copy slide url",
        callBack: this.handleCopySlideUrl,
        isCopy: true,
        img: copyIcon
      },
      { title: "Details", callBack: this.openFileDetails, img: ViewDetailsIcon }
    ];

    let moreMenuPublicFileItems = [
      {
        title: "Open in viewer",
        callBack: this.handleShowInViewer,
        img: OpenViewerIcon
      },
      { title: "Details", callBack: this.openFileDetails, img: ViewDetailsIcon }
    ];

    let retryFileItems = [
      { title: "Delete", callBack: this.confirmDelete, img: DeleteIcon }
    ];

    const {
      showRenameFilePopUp,
      renameFile,
      isFile,
      rename,
      selectedFile,
      showDeletePopUp,
      selectedFolder,
      showMoveFilePopup,
      showFolderDetailsPopup,
      showGroupsSelectionPopup,
      fileSort,
      collaborators,
      folderDetails,
      isShared,
      isSharedFolderSelect,
      isPublicFolderSelect,
      totalPageCount,
      folderId,
      showPublic
    } = this.state;
    let {
      forQuorum,
      forSlidebox,
      forTumor,
      forMainPage,
      showShared,
      when
    } = this.props;

    let modalStyle = forQuorum || forSlidebox || forTumor;
    const { id: user_id, user } = this.props.user_session;
    const folderPath = this.state.folderPath;
    return (
      <div
        className="slidebar-container"
        style={modalStyle ? { height: "calc(100% - 66px)" } : null}
      >
        <div className="slidebox-page">
          <div className="container-content">
            <div className="slidebox-left-container">
              <div
                className="slidebox-wrapper"
                style={modalStyle ? { minHeight: "fit-content" } : null}
              >
                <div className="search-directory-wrapper">
                  <div className="action-buttons-newUpload">
                    {
                    when !== "groups" && (
                      <React.Fragment>
                        <PrimaryButton
                          img={NewIcon}
                          label="New"
                          onClick={
                            !isSharedFolderSelect && this.handleFolderCreation
                          }
                          disabled={
                            isSharedFolderSelect || isPublicFolderSelect
                          }
                          extraClass="new-button"
                        />
                        <PrimaryButton
                          img={UploadIcon}
                          label="Upload"
                          onClick={
                            !isSharedFolderSelect && this.openUploadFilePage
                          }
                          disabled={
                            isSharedFolderSelect || isPublicFolderSelect
                          }
                          extraClass="upload-button"
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className="file-manager-wrapper">
                  <span className="slidebox-heading">Slidebox</span>
                  <div>
                    {this.state.isLoadingS3Directories ? (
                      <div className="loader">Loading directory tree...</div>
                    ) : (
                      <React.Fragment>
                        {this.state.listOfS3Directories.map(
                          (directory, index) => (
                            <DirectoryBuilder
                              key={index}
                              index={index}
                              name={directory.name}
                              isRootFolder={directory.isRootFolder}
                              folderPath={directory.folderPath}
                              folderId={directory.folder_id}
                              openedDirectory={this.state.openedDirectory}
                              onToggleDirectory={this.onToggleDirectory}
                              onToggleNestedDirectory={
                                this.onToggleNestedDirectory
                              }
                              onOpenDirectoryContent={
                                this.onOpenDirectoryContent
                              }
                              toggledDirectories={this.state.toggledDirectories}
                              children={directory.children}
                              onFolderChanged={this.onFolderChanged}
                              onFolderRename={this.handleRename}
                              showDetails={this.showFolderDetails}
                              mainRoot={directory.mainRoot}
                              withMoreMenu={forMainPage}
                              selectedFolder={this.props.selectedFolder}
                              onFolderDelete={this.onFolderDelete}
                              rootId={this.state.folderRootId}
                            />
                          )
                        )}
                        {showShared &&
                          this.state.listOfSharedDirectories.map(
                            (directory, index) => (
                              <DirectoryBuilder
                                key={index}
                                index={index}
                                name={directory.name}
                                isRootFolder={directory.isRootFolder}
                                folderPath={directory.folderPath}
                                openedDirectory={this.state.openedDirectory}
                                onToggleDirectory={this.onToggleDirectory}
                                onToggleNestedDirectory={
                                  this.onToggleNestedDirectory
                                }
                                onOpenDirectoryContent={data =>
                                  this.onOpenDirectoryContent({
                                    ...data,
                                    isSharedFolder: true
                                  })
                                }
                                toggledDirectories={
                                  this.state.toggledDirectories
                                }
                                children={directory.children}
                                onFolderChanged={this.onFolderChanged}
                                onFolderRename={this.handleRename}
                                showDetails={this.showFolderDetails}
                                mainRoot={directory.mainRoot}
                                isSharedDirectory={true}
                                withMoreMenu={forMainPage}
                                selectedFolder={this.props.selectedFolder}
                              />
                            )
                          )}

                        {showPublic &&
                          this.state.listOfPublicDirectories.map(
                            (directory, index) => (
                              <DirectoryBuilder
                                key={index}
                                index={index}
                                name={directory.name}
                                isRootFolder={directory.isRootFolder}
                                folderPath={directory.folderPath}
                                isPublicFolder={directory.isPublicFolder}
                                folderId={directory.folder_id}
                                openedDirectory={this.state.openedDirectory}
                                onToggleDirectory={this.onToggleDirectory}
                                onToggleNestedDirectory={
                                  this.onToggleNestedDirectory
                                }
                                onOpenDirectoryContent={
                                  this.onOpenDirectoryContent
                                }
                                toggledDirectories={
                                  this.state.toggledDirectories
                                }
                                children={directory.children}
                                onFolderChanged={this.onFolderChanged}
                                onFolderRename={this.handleRename}
                                showDetails={this.showFolderDetails}
                                mainRoot={directory.mainRoot}
                                withMoreMenu={forMainPage}
                                selectedFolder={this.props.selectedFolder}
                              />
                            )
                          )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <SlideboxUsageBar
                  shouldShow={this.props.showUsageBar}
                  {...this.props.slideBoxSize}
                />
              </div>
            </div>
            <div
              className="slidebox-right-container"
              style={
                forQuorum || forSlidebox || forTumor
                  ? { paddingTop: "1.5%" }
                  : {}
              }
            >
              <div
                className="search_box group_search"
                style={{ padding: "5px 5px", display: "block", width: 300 }}
              >
                <input
                  className="search"
                  style={{ height: "35px" }}
                  type="text"
                  placeholder="Search"
                  // onChange={onChangeHandler}
                  // defaultValue={inputValue}
                  spellCheck="false"
                  autoComplete="false"
                  ref={this.slideBoxSearchRef}
                />
                <img src={SearchIcon} alt="search icon" />
              </div>
              <div
                className="slidebox-directory-content-list"
                id="slideboxOverflow"
              >
                <div
                  className="grid-container-slidebox"
                  style={
                    !this.props.forMainPage
                      ? this.props.showCheckbox
                        ? {
                            gridTemplateColumns: "6% 11% 17% 13% 17% 22% 13%"
                          }
                        : {
                            gridTemplateColumns: "12% 18% 14% 18% 23% 14%"
                          }
                      : {}
                  }
                >
                  {this.props.showCheckbox && <div></div>}
                  <div>Image</div>
                  <div onClick={() => this.sort("fileName")}>
                    Name
                    {this.getSortIcon(fileSort.fileName)}
                  </div>
                  <div onClick={() => this.sort("fileSize")}>
                    Size
                    {this.getSortIcon(fileSort.fileSize)}
                  </div>
                  <div onClick={() => this.sort("uploadTime")}>
                    Upload Time
                    {this.getSortIcon(fileSort.uploadTime)}
                  </div>
                  <div>Labels</div>
                  <div className="text-center">Status</div>
                </div>
                <div
                  className="grid-bottom-container"
                  id="slideboxOverflow"
                  style={modalStyle ? { maxHeight: "88%" } : {}}
                >
                  {this.state.isLoadingDirectoryContent ? (
                    <div className="text-center">Loading data...</div>
                  ) : (
                    <React.Fragment>
                      {this.state.listOfDirectoryFiles &&
                        this.state.listOfDirectoryFiles.map(
                          (fileItem, index) => (
                            <SlideBoxListItem
                              moreMenuItems={
                                fileItem.isSharedFile
                                  ? moreMenuSharedItems
                                  : isMine(fileItem.user_id)
                                  ? moreMenuItems
                                  : fileItem.module_name === "public"
                                  ? moreMenuPublicFileItems
                                  : moreMenuSharedFileItems
                              }
                              fileItem={fileItem}
                              key={index}
                              index={index}
                              onSelectedRow={
                                forQuorum || forTumor || forMainPage
                                  ? this.onSingleSelect
                                  : this.onSelectedRow
                              }
                              preventSelection={this.preventSelection}
                              selectedRowIndex={
                                this.state.selectedRowIndex || {}
                              }
                              forQuorum={forQuorum}
                              forTumor={forTumor}
                              forMainPage={forMainPage}
                              handleShowInViewer={this.handleShowInViewer}
                              handleLabelClick={this.handleLabelClick}
                              isSlideBoxPage={this.props.showShared}
                              showCheckbox={this.props.showCheckbox}
                              retryMenuItem={retryFileItems}
                            />
                          )
                        )}
                      {this.state.listOfDirectoryFiles &&
                        this.state.listOfDirectoryFiles.length === 0 && (
                          <div className="text-center">
                            <span>No data</span>
                          </div>
                        )}
                    </React.Fragment>
                  )}
                </div>
                <div className="slidebox-pagination-container">
                  {totalPageCount && (
                    <SlideboxPagination
                      page={this.state.paginationPage}
                      onPageChange={this.onPageChange}
                      perPage={this.state.paginationPerPage}
                      onPerPageChange={this.onPerPageChange}
                      lastPage={totalPageCount}
                    />
                  )}
                </div>
              </div>
              {forQuorum || forSlidebox || forTumor ? (
                <div className="slidebox-action">
                  {this.props.onNextPage && (
                    <button
                      className="pri_btn next_page_btn"
                      onClick={
                        forQuorum
                          ? this.nextToQuorum
                          : forTumor
                          ? this.nextToTumor
                          : this.onNextPage
                      }
                      disabled={this.state.selectedFiles.length ? false : true}
                    >
                      NEXT
                    </button>
                  )}
                </div>
              ) : null}
            </div>

            {showRenameFilePopUp && (
              <CreateNewFolderPopUp
                isFile={isFile ? true : false}
                rename={rename}
                handleAfterCreate={
                  rename
                    ? this.handleAfterRenamed
                    : this.handleAfterFolderCreate
                }
                selectedFolder={selectedFolder}
                folderPath={folderPath}
                selectedFile={renameFile}
                open={showRenameFilePopUp}
                userId={user_id}
                onClose={() => this.handleCancelRenameFile()}
              />
            )}

            {showDeletePopUp && (
              <DeleteConfirm
                isFile
                user={user}
                selectedFile={selectedFile}
                handleDelete={this.handleDelete}
                toggleOptions={() => this.handleCancelDeleteFile()}
              />
            )}
            {showMoveFilePopup && (
              <MoveFile
                selectedFile={selectedFile}
                onClose={this.closeMoveFile}
                listOfS3Directories={this.state.listOfS3Directories}
              />
            )}
            {showFolderDetailsPopup && (
              <FolderDetails
                onClose={this.closeFolderDetails}
                selectedItem={isFile ? selectedFile : selectedFolder}
                collaborators={collaborators}
                folderDetails={folderDetails}
                isShared={isShared}
                isFile={isFile}
                onSubmitEdit={this.onEditFile}
              />
            )}
            {showGroupsSelectionPopup && (
              <GroupsSelection
                dialogOpen={showGroupsSelectionPopup}
                onClose={this.closeGroupModel}
                selectedFile={selectedFile}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  openUploadFilePage = () => {
    this.props.history.push("/slidebox-new/upload");
  };

  nextToQuorum = (_e, quorumFile = null) => {
    this.props.history.push({ pathname: `/quorum` });
    let file = quorumFile ? quorumFile : this.state.selectedFiles[0];
    this.props.loadQuorum({
      filename: file.file_name,
      fileid: file.slide_box_id,
      keyfolder: file.key_folder,
      slide: [
        {
          slide_box_id: file.slide_box_id,
          dzi_data: file.dzi_data,
          dzi_url: file.dzi_url,
          src: file.thumbnail,
          mimetype: "image/jpeg"
        }
      ],
      filePath: file.file_path
    });
  };

  nextToTumor = (_e, tumorFile = null) => {
    this.props.closeModal("CLOSE MODAL");
    let file = tumorFile ? tumorFile : this.state.selectedFiles[0];
    const body = {
      tumorBoardId: this.props.tumorBoardId,
      slide: JSON.stringify({
        dzi_data: file.dzi_data,
        dzi_url: file.dzi_url
      }),
      slideId: file.slide_box_id
    };
    axios.put("/api/tumorBoards/slidePath", body);
    Notify({ value: "Slide added successfully" });
    this.props.loadQuorum({
      filename: file.file_name,
      fileid: file.slide_box_id,
      keyfolder: file.key_folder,
      slide: [
        {
          slide_box_id: file.slide_box_id,
          dzi_data: file.dzi_data,
          dzi_url: file.dzi_url,
          src: file.thumbnail,
          mimetype: "image/jpeg"
        }
      ],
      filePath: file.file_path
    });
  };

  onNextPage = () => {
    if (this.props.onNextPage) {
      // send data for selected rows
      const selectedFiles = _.cloneDeep(this.state.selectedFiles);
      this.props.onNextPage(selectedFiles);
    }
  };

  onSingleSelect = (
    fileData,
    index,
    proceedNexToTumor = false,
    proceedNexToQuorum = false
  ) => {
    if (proceedNexToTumor) {
      this.nextToTumor(null, fileData);
      return;
    } else if (proceedNexToQuorum) {
      this.nextToQuorum(null, fileData);
      return;
    }

    if (this.state.selectedRowIndex[index]) {
      const listOfSelctedFiles = [];
      const selectedRowIndex = _.clone(this.state.selectedRowIndex);
      selectedRowIndex[index] = false;
      return this.setState({
        selectedFiles: listOfSelctedFiles,
        selectedRowIndex
      });
    }
    let selectedRowIndex = {};
    selectedRowIndex[index] = true;
    const listOfSelctedFiles = [fileData];

    return this.setState({
      selectedFiles: listOfSelctedFiles,
      selectedRowIndex
    });
  };

  onSelectedRow = (fileData, index) => {
    if (this.state.selectedRowIndex[index]) {
      const listOfSelctedFiles = this.state.selectedFiles.filter(
        fileItem => fileItem.slide_box_id !== fileData.slide_box_id
      );
      const selectedRowIndex = _.clone(this.state.selectedRowIndex);
      selectedRowIndex[index] = false;
      return this.setState({
        selectedFiles: listOfSelctedFiles,
        selectedRowIndex
      });
    }
    const selectedRowIndex = _.clone(this.state.selectedRowIndex);
    selectedRowIndex[index] = true;
    const listOfSelctedFiles = [...this.state.selectedFiles, fileData];
    return this.setState({
      selectedFiles: listOfSelctedFiles,
      selectedRowIndex
    });
  };
}

const SlideBoxFileStatus = props => {
  return (
    <span
      onClick={() =>
        props.status.toLowerCase() === "retry" &&
        props.retryConversion({
          fileName: props.fileData ? props.fileData.file_name : null,
          filePath: props.fileData.file_path,
          slideBoxId: props.fileData.slide_box_id,
          slideBoxProgressId: props.fileData.slide_box_progress_id,
          isLargeFile: props.fileData.file_size > 20971520
        })
      }
    >
      {props.children}
    </span>
  );
};

const bytesToSize = bytes => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.abs(bytes / Math.pow(1024, i), 2).toFixed(2) + " " + sizes[i];
};

const SlideBoxListItem = props => {
  const [retryConvertion, setRetryConvertion] = useState(false);
  const onSelectedRow = (
    e,
    fileItem,
    proceedNexToTumor = false,
    proceedNexToQuorum = false
  ) => {
    if (
      props.onSelectedRow &&
      fileItem &&
      !props.showShared &&
      fileItem.isConverted
    ) {
      props.onSelectedRow(
        props.fileItem,
        props.index,
        proceedNexToTumor,
        proceedNexToQuorum
      );
    } else {
      e.preventDefault();
      if (
        (fileItem && !fileItem.isConverted && fileItem.isConverting) ||
        Object.keys(fileItem).length === 1
      ) {
        Notify({ value: "This file still converting, cannot select it" });
      } else {
        if (e.target.tagName === "svg") {
          props.onSelectedRow(
            props.fileItem,
            props.index,
            proceedNexToTumor,
            proceedNexToQuorum
          );
        } else {
          if (e.target.tagName !== "LI" && e.target.tagName !== "BUTTON") {
            Notify({ value: "This file conversion failed, cannot select it" });
          }
        }
      }
    }
  };

  let convertionStatus = "";
  if (props.fileItem && props.fileItem.isConverted) {
    convertionStatus = "ready";
  }
  if (
    (props.fileItem &&
      !props.fileItem.isConverted &&
      props.fileItem.isConverting) ||
    Object.keys(props.fileItem).length === 1
  ) {
    convertionStatus = "converting";
  }

  if (
    props.fileItem &&
    !props.fileItem.isConverted &&
    !props.fileItem.isConverting
  ) {
    convertionStatus = props.fileItem.isSharedFile ? "failed" : "retry";
  }

  // const fileSize = props.fileItem.Size
  //   ? bytesToSize(parseInt(props.fileItem.Size))
  //   : 'N/A'

  const retryConversion = fileData => {
    axios
      .post("/api/retryFileConversion", fileData)
      .then(response => {
        if (response.status === 200) {
          setRetryConvertion(true);
        }
      })
      .catch(error => {
        //console.log(error);
      });
  };

  const openDetailItem = props.moreMenuItems.find(i => i.title === "Details");
  const openFileDetailCallback = openDetailItem
    ? openDetailItem.callBack
    : () => {};

  return (
    <React.Fragment>
      <div
        onDoubleClick={e => {
          if (props.isSlideBoxPage) {
            props.handleShowInViewer(props.fileItem);
          } else if (props.forTumor) {
            onSelectedRow(e, props.fileItem, true);
          } else if (props.forQuorum) {
            onSelectedRow(e, props.fileItem, false, true);
          }
        }}
        onClick={e => onSelectedRow(e, props.fileItem)}
        className={
          props.selectedRowIndex[props.index]
            ? "grid-container-contents selected-row desktop-slidebox"
            : "grid-container-contents desktop-slidebox"
        }
        style={
          !props.forMainPage
            ? props.showCheckbox
              ? {
                  gridTemplateColumns: "6% 11% 17% 13% 17% 22% 13%"
                }
              : {
                  gridTemplateColumns: "14% 17% 15% 13% 26% 15%"
                }
            : {}
        }
      >
        {props.showCheckbox && (
          <div className="grid-item">
            <input
              type="checkbox"
              checked={props.selectedRowIndex[props.index]}
              onChange={e => onSelectedRow(e, props.fileItem)}
            />
          </div>
        )}
        <div className="grid-item image">
          <img
            src={
              props.fileItem && props.fileItem.thumbnail
                ? props.fileItem.thumbnail
                : defaultFileIcon
            }
            alt="img1"
          />
        </div>
        <div className="grid-item">
          <Tooltip />
          <div class="text-ellipsis" data-tip={props.fileItem.file_name}>
            {props.fileItem && props.fileItem.file_name
              ? props.fileItem.file_name
              : ""}
          </div>
        </div>
        <div className="grid-item">{props.fileItem.sizeStr}</div>{" "}
        {/* change the date to format // 02-jan-2020 */}
        <div className="grid-item">{props.fileItem.uploadedAt}</div>
        <div className="grid-item">
          {props.fileItem.labels ? (
            Array.isArray(props.fileItem.labels) ? (
              props.fileItem.labels.map(l => (
                <span
                  className="lable_tags"
                  onClick={() => props.handleLabelClick(l.label)}
                >
                  <a href="javascript:;">{l.label}</a>
                </span>
              ))
            ) : props.fileItem.labels ? (
              props.fileItem.labels.split(",").map(l => (
                <span
                  className="lable_tags"
                  onClick={() => props.handleLabelClick(l)}
                >
                  <a href="javascript:;">{l}</a>
                </span>
              ))
            ) : (
              <span className="lable_tags">
                <a href="javascript:;"></a>
              </span>
            )
          ) : (
            ""
          )}
        </div>
        <div className="text-center grid-item horizontal">
          <SlideBoxFileStatus
            fileData={props.fileItem}
            status={retryConvertion ? "converting" : convertionStatus}
            retryConversion={retryConversion}
          >
            {retryConvertion
              ? "Converting"
              : convertionStatus.charAt(0).toUpperCase() +
                convertionStatus.slice(1)}
          </SlideBoxFileStatus>
        </div>
        {props.forMainPage && (
          <React.Fragment>
            <div className="grid-item info">
              <img
                src={InfoIcon}
                onClick={() =>
                  convertionStatus === "ready" &&
                  openFileDetailCallback(props.fileItem, props.index)
                }
              />
            </div>
            <div className="grid-item">
              <MoreMenu
                className={"row-option"}
                icon={verticalThreeDotsIcon}
                fileData={props.fileItem}
                fileIndex={props.index}
                menuItems={props.moreMenuItems}
                id="file-three-dots-button"
                onButtonMouseDown={props.preventSelection}
                isConvertedFile={props.fileItem.isConverted}
                isRetry={
                  props.fileItem &&
                  !props.fileItem.isConverted &&
                  !props.fileItem.isConverting
                }
                retryMenuItem={props.retryMenuItem}
              />
            </div>
          </React.Fragment>
        )}
      </div>
      <div
        onClick={onSelectedRow}
        className={
          props.selectedRowIndex[props.index]
            ? "content-area selected-row mobile-slidebox"
            : "content-area mobile-slidebox"
        }
      >
        {!props.forQuorum && !props.forTumor ? (
          <div className="more-menu-item">
            <MoreMenu
              className={"row-option"}
              icon={verticalThreeDotsIcon}
              fileData={props.fileItem}
              menuItems={props.moreMenuItems}
              id="file-three-dots-button"
              onButtonMouseDown={props.preventSelection}
              isConvertedFile={props.fileItem.isConverted}
            />
          </div>
        ) : (
          <div />
        )}
        <div className="content-div">
          <div className="image-div">
            <img
              src={
                props.fileItem && props.fileItem.thumbnail
                  ? props.fileItem.thumbnail
                  : defaultFileIcon
              }
              alt="img1"
            />
          </div>
          <div className="information-div">
            <div className="info-item">
              <span className="description-info">Name</span>
              {props.fileItem && props.fileItem.file_name
                ? props.fileItem.file_name
                : ""}
            </div>
            <div className="info-item">
              <span className="description-info">File Size</span>
              {props.fileItem.sizeStr}
            </div>
            <div className="info-item">
              <span className="description-info">Upload Time</span>
              {props.fileItem.LastModified &&
                moment(props.fileItem.LastModified).format("MM-DD-YYYY hh:mm")}
            </div>
            <div className="info-item">
              <span className="description-info">Labels</span>
              {props.fileItem.fileType}
            </div>
          </div>
        </div>
        <div className="button-status">
          <SlideBoxFileStatus status={convertionStatus}>
            {convertionStatus.charAt(0).toUpperCase() +
              convertionStatus.slice(1)}
          </SlideBoxFileStatus>
        </div>
      </div>
    </React.Fragment>
  );
};

/**
 *
 * @param {array} listOfDirectories - list of directories.
 */
const filterByIsFolderFlag = listOfDirectories => {
  // console.log(listOfDirectories);
  if (!Array.isArray(listOfDirectories)) {
    if (!listOfDirectories.isFolder) return null;

    const filteredChildrenItemByIsFolderFlag = (
      listOfDirectories.children || []
    ).filter(objectItem => objectItem.isFolder);
    if (
      !filteredChildrenItemByIsFolderFlag ||
      filteredChildrenItemByIsFolderFlag.length === 0
    ) {
      delete listOfDirectories.children;
      return listOfDirectories;
    }

    listOfDirectories.children = filteredChildrenItemByIsFolderFlag.map(
      folderItem => filterByIsFolderFlag(folderItem)
    );
    // console.log(listOfDirectories);
    return listOfDirectories;
  }

  return (listOfDirectories || [])
    .filter(objectItem => objectItem.isFolder)
    .map(objectItem => filterByIsFolderFlag(objectItem));
};

const mapStateToProps = state => ({
  user_session: state.User.session,
  slideBoxSize: state.SlideBox.slideBoxSize,
  selectedFolder: state.SlideBox.selectedUploadFolder
});

const mapDispatchToProps = dispatch => ({
  loadQuorum: payload => dispatch(loadQuorum(payload)),
  loadUserData: payload => dispatch(getUserDetails(payload)),
  getSlideBoxStorageSize: payload => dispatch(setSlideBoxStorageLimit(payload)),
  setStoreFolders: payload => dispatch(setFolders(payload)),
  setSelectedFolder: payload => dispatch(setSelectedUploadFolder(payload)),
  updatedSlideBoxSize: payload => dispatch(setSlideBoxStorageSize(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SlideBox));
