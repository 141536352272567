import React, { useState } from "react";
import Overlay from '../../others/overlay'
import PostItHeader from "./fileheader";
import PostItQuorumMiddle from "./post-it-quorum-middle";
import PostItQuorumAction from "./post-it-quorum-action";
import { FadeIn } from 'animate-components'
import { withRouter } from "react-router-dom";
// import SlideBox from '../Slidebox/index';


const PostItSlideBox = ({back}) => {
  const [isHide, setisHide] = useState(false);


  return (    
    <div style={isHide? {display: 'none'}: {display: 'block'}}>
      
          <Overlay />
          <div className="post-main" style={{ left: '50%', height: '800px' }}>
         <FadeIn duration="300ms" height = "800px">
         <SlideBox /> 
         </FadeIn>
    </div>
    </div>
  );
};

export default withRouter(PostItSlideBox);
