import React, { Component } from 'react'
import { FadeIn } from 'animate-components'
import Overlay from '../../others/overlay'
import ToolTip from 'react-tooltip'
import Filters from './filters/filters'
import GetLocation from './getLocation'
import PostItHeader from './header'
import PostItDetails from './details'
import AddTags from './add-tags'
import Middle from './middle'
import ToggleAddTags from './toggleAddTags'
import PostItActions from './actions'
import { func, oneOf, number } from 'prop-types'
import AddEmojis from '../../others/emojis/add-emojis'
import { CPP } from '../../../actions/post'
import { connect } from 'react-redux'

@connect(store => ({
  postIt: store.Post.postIt,
}))
class PostIt extends Component {
  state = {
    isHide: false,
  }
  componentDidMount = () => {
    let { type, group, dispatch } = this.props
    dispatch(CPP('type', type))
    dispatch(CPP('group', group))
  }

  hidePostModal = state => {
    this.setState({ isHide: state })
  }

  render() {
    let {
      postIt: { fileChanged, showOverlay, desc },
      back,
      isMobile,
      parent,
      images,
      dispatch,
    } = this.props

    return (
      <div
        style={this.state.isHide ? { display: 'none' } : { display: 'block' }}
      >
        <Overlay />

        <div className="post" style={{ left: '50%' }}>
          <FadeIn duration="300ms">
            <PostItHeader headerName={'Case Streams'} />
            <PostItDetails isMobile={isMobile} />
            <Middle
              back={back}
              images={images}
              parent={parent}
              hidePostModal={this.hidePostModal}
            />
            {/* <AddTags /> */}
            <div className="t_p_bottom p_bottom">
              <div className="t_p_tag p_tag">
                <AddEmojis
                  position={{ top: -32, left: -217 }}
                  textArea=".e_p_textarea"
                  updateTextArea={value => dispatch(CPP('desc', desc + value))}
                  recenterEmojis
                />
              </div>

              <PostItActions back={back} />
            </div>
          </FadeIn>
        </div>

        {showOverlay && <Overlay type="white" />}

        <ToolTip />
      </div>
    )
  }
}

PostIt.propTypes = {
  back: func.isRequired,
  type: oneOf(['user', 'group']).isRequired,
  group: number,
}

export default PostIt
