import React, { Component } from "react";
import UploadIcon from "./icons/upload.svg";
import DocumentIcon from "../../../assets/icons/documents2.svg";
import AttachItem from "./attach-item/attach-item";
import PrimaryButton from "../../others/button/primary-btn";
import SecondaryButton from "../../others/button/secondary-btn";
import {
  showSnackBarComponent,
  setFileUploadProgress,
  setFileCanceller
} from "../../../actions/notification";
import axios from "../../../utils/axios-instance-utils";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Dropzone from "../../others/imageDragAndDrop/Dropzone";
import Notify from "handy-notification";
import MoveFile from "../slidebox-page/move-file";
import StorageAlert from "./storage-alert";
import Tooltip from "react-tooltip";

const arrowLeft = "/images/img/arrow_left.svg";

class UploadSlideboxFiles extends Component {
  state = {
    files: [],
    fileEdit: false,
    changedFileName: null,
    agreeTerms: false,
    fileSavingS3Path: null,
    uplodFileIndex: null,
    fileNameExits: false,
    errorCount: 0,
    defaultSelectedStain: "H&E",
    showMoveFilePopup: false,
    storageLimitExceed: false,
    storageIsExceedPopup: false
  };

  componentDidMount() {
    const { parentFiles } = this.props;
    if (parentFiles) {
      let temp = this.normalizeFile(parentFiles);
      this.setState({ files: temp });
    }
  }

  openMoveFile = () => {
    this.setState({
      showMoveFilePopup: true
    });
  };

  closeMoveFile = () => {
    this.setState({ showMoveFilePopup: false });
  };

  normalizeFile = files => {
    let tempFiles = [];
    for (let i = 0; i < files.length; i++) {
      files[i]["checked"] = true;
      tempFiles[i] = files[i];
    }
    return tempFiles;
  };

  onUploadFile = async event => {
    const files = this.state.files;
    const target = event.target;
    await this.setState({
      files: [...files, ...this.normalizeFile(target.files)],
      errorCount: 0
    });
    this.checkStorageLimitExceed(this.state.files);
  };

  onDropzonUploadFile = async dropzoneFiles => {
    const files = this.state.files;
    await this.setState({
      files: [...files, ...this.normalizeFile(dropzoneFiles)],
      errorCount: 0
    });
    this.checkStorageLimitExceed(this.state.files);
  };

  toggleUploadFile = listOfFiles => {
    const userId = this.props.id;
    const rootPath = "slidebox/" + userId + "/";
    const filesLength = listOfFiles.length;
    const fileSavingS3Path = rootPath;
    this.setState({ fileSavingS3Path: fileSavingS3Path });
    listOfFiles.forEach(file => (file.uploadedAt = Date.now()));
    if (listOfFiles.length > 0) {
      listOfFiles.forEach((fileData, index) => {
        const uploadingIndex = index + this.props.fileList.length; // fileIndex is used to update store
        axios
          .get("api/getPresignedPutUrl", {
            params: {
              fileName: fileSavingS3Path + fileData.originalFileName,
              fileType: fileData.type
            }
          })
          .then(response => {
            this.uploadToS3Direct(
              response.data,
              fileData,
              uploadingIndex,
              filesLength
            );
          })
          .catch(e => console.warn(e));
      });
      this.props.dispatch(showSnackBarComponent(true, listOfFiles));
    } else {
      Notify({ value: "Please select the image" });
    }
  };

  uploadToS3Direct = (data, file, fileIndex, filesLength) => {
    const fileData = new FormData();
    Object.keys(data.params).forEach(key => {
      if (key === "content-type") {
        fileData.append(key, "");
      } else {
        fileData.append(key, data.params[key]);
      }
    });
    fileData.append("file", file);
    let config = {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.props.dispatch(setFileUploadProgress(fileIndex, percentCompleted));
      },
      cancelToken: new axios.CancelToken(cancel => {
        this.props.dispatch(setFileCanceller(fileIndex, cancel));
      })
    };
    axios.post(data.endpoint_url, fileData, config).then(response => {
      this.handleOnUploadToDataBase(response, file, filesLength);
    });
  };

  handleOnUploadToDataBase = async (response, file, filesLength) => {
    const userId = this.props.id;
    let fileIndex = this.state.uplodFileIndex
      ? this.state.uplodFileIndex + 1
      : 1;
    await this.setState({ uplodFileIndex: fileIndex });
    try {
      let data = {
        originalFileName: file.originalFileName,
        fileName: file.name,
        uniquePath: "slidebox",
        isLargeFile: file.size > 20971520,
        fileIndex: fileIndex,
        stain:
          typeof file.stain === "undefined"
            ? this.state.defaultSelectedStain
            : file.stain,
        filesLength,
        selectedFolderId: this.props.selectedUploadFolder.id
      };

      axios
        .post("api/uploadFiles", data)
        .then(response => {
          if (response.data.msg === "success") {
            if (filesLength === fileIndex) {
              this.props.history.push("/slidebox-new", {
                folderSelected: true,
                folderPath: this.state.fileSavingS3Path
              });
            }
          }
        })
        .catch(e => console.warn(e));
    } catch (error) {
      Notify({ value: "Please select the image" });
    }
  };

  onChangeCheck = index => {
    const files = this.state.files;
    const tempFile = _.merge({}, files[index], {
      checked: !files[index].checked
    });
    files[index] = tempFile;
    this.setState({ files: files, errorCount: 0 });
  };

  onRemoveFile = async index => {
    const files = this.state.files;
    if (files[index]) {
      files.splice(index, 1);
    }
    await this.setState({ files: files, errorCount: 0 });
    this.checkStorageLimitExceed(this.state.files);
  };

  handleOnUpload = (response, folderPath) => {
    if (response) {
      this.props.history.push("/slidebox-new", {
        folderSelected: true,
        folderPath: folderPath
      });
    }
  };

  goBack = () => {
    this.props.history.push("/slidebox-new");
  };

  fileRenameInit = (file, index) => {
    this.setState({
      changedFileName: file.name,
      fileEdit: true,
      selectedFileToRename: index
    });
  };

  fileNameChangeDone = (file, editFileIndex, changedFileNameState) => {
    const modifyFile = new File([file], changedFileNameState, {
      type: file.type
    });
    modifyFile["checked"] = true;
    const filesList = this.state.files;
    const modifiedFilesList = filesList.map((data, index) => {
      if (index === editFileIndex && changedFileNameState) {
        return modifyFile;
      } else {
        return data;
      }
    });
    this.setState({ files: modifiedFilesList, fileEdit: false, errorCount: 0 });
  };

  handleChangeagreeTerms = () => {
    this.setState({ agreeTerms: !this.state.agreeTerms });
  };

  showWarning = event => {
    event.preventDefault();
    Notify({ value: "Please check the agreement" });
  };

  onClickUpload = () => {
    if (!this.state.agreeTerms || this.state.fileExits)
      return this.showWarning();
    if (!this.state.storageLimitExceed) {
      const files = this.state.files;
      const filesToBeUploaded = files.filter(file => file.checked);
      this.toggleUploadFile(filesToBeUploaded);
    } else {
      this.setState({ storageIsExceedPopup: true });
    }
  };

  setFileNameStateError = status => {
    const errorCount = status
      ? this.state.errorCount + 1
      : this.state.errorCount == 0
      ? 0
      : this.state.errorCount - 1;
    this.setState({
      fileNameExits: status,
      errorCount: errorCount
    });
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  checkStorageLimitExceed = files => {
    const { totalStorageBytes, currentUsageBytes } = this.props.slideBoxSize;
    const currentUploadSize = files.reduce((a, file) => a + file.size, 0);
    const totalCurrentFilesSize = currentUploadSize + currentUsageBytes;

    if (totalCurrentFilesSize > totalStorageBytes) {
      this.setState({ storageLimitExceed: true });
      return true;
    } else {
      this.setState({ storageLimitExceed: false });
      return false;
    }
  };

  closeStorageExceedPopup = () => {
    this.setState({ storageIsExceedPopup: false });
  };
  render() {
    const { files, changedFileName, agreeTerms, errorCount } = this.state;
    return (
      <div className="slidebox-upload-page">
        <div className="slidebox-upload-header">
          <div className="upload-header">
            <button onClick={this.goBack} className="back-arrow">
              <img src={arrowLeft} />
            </button>
            <span>Upload to folder: </span>
            <span>
              {this.props.selectedUploadFolder &&
              this.props.selectedUploadFolder.name
                ? this.props.selectedUploadFolder.name
                : "My SlideBox"}
            </span>
          </div>
          <p className="slidebox-change-directory" onClick={this.openMoveFile}>
            Change folder
          </p>
          <p className="slidebox-parahgraph">
            Please add any slides you would like to use on Crosscope
          </p>
        </div>
        <div className="slidebox-upload-container">
          <div className="upload-content" id="upload-content-area">
            <div className="container">
              <div className="upload-area">
                <div className="upload-area-border">
                  <div className="drag-and-drop-wrapper">
                    <img src={UploadIcon} alt="drag and drop" />
                    <Dropzone setDragAndDropFiles={this.onDropzonUploadFile} />
                  </div>
                  <div className="input-file-wrapper">
                    <button>CHOOSE FILES</button>
                    <input multiple onChange={this.onUploadFile} type="file" />
                  </div>
                </div>
              </div>
              <div className="select-item-header">
                {files.length > 0 && (
                  <React.Fragment>
                    <span>File name</span>
                    <span>Stain</span>
                  </React.Fragment>
                )}
              </div>
              <div className="list-items">
                {files.map((file, index) => (
                  <AttachItem
                    changedFileName={changedFileName}
                    key={index}
                    file={file}
                    index={index}
                    fileNameEditDone={this.fileNameChangeDone}
                    onChangeCheck={this.onChangeCheck}
                    onRemoveFile={this.onRemoveFile}
                    userId={this.props.id}
                    uploadFileNameError={this.setFileNameStateError}
                  />
                ))}
              </div>
              <div className="submit-wrapper">
                <div className="agree-terms">
                  <input
                    id="agree-to-term-of-service"
                    type="checkbox"
                    className="s_terms"
                    checked={this.state.agreeTerms}
                    defaultChecked={this.state.agreeTerms}
                    onChange={this.handleChangeagreeTerms}
                  />
                  <p>
                    <a
                      className="term-agreement-link"
                      target="_blank"
                      href="https://crosscope.com/terms-conditions/"
                    >
                      I agree to the Terms of Service
                    </a>
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center buttonWrapper">
                  <SecondaryButton
                    label="Cancel"
                    onClick={this.goBack}
                    extraClass="back-button"
                  />
                  <PrimaryButton
                    label="Upload"
                    onClick={this.onClickUpload}
                    disabled={
                      !agreeTerms || errorCount > 0 || files.length === 0
                    }
                    extraClass="upload-button"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="upload-instruction">
            <div className="upload-instruction-header">
              <img src={DocumentIcon} alt="DocumentIcon" />
              {/* <img src={InstructionIcon} alt="instruction" /> */}
              <span className="supportHeader">SUPPORTED FILE FORMATS</span>
            </div>
            <ul className="list">
              <li>Non-proprietary (JPG, PNG)</li>
              <li>Aperio (SVS, TIF)</li>
              <li>Leica (SCN)</li>
              <li>Hamamatus (NDPI,VMS)</li>
              <li>3D Histech (MRXS)</li>
              <li>Ventana (BIF)</li>
              <li>Philips (TIFF)</li>
              <li className>
                <a
                  data-for="upload-slidebox-tooltip"
                  data-tip="Upload as ZIP archive"
                  className="dash-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/help?tab=mrxs`}
                >
                  MRXS
                </a>
              </li>
            </ul>
          </div>
        </div>
        {this.state.showMoveFilePopup && (
          <MoveFile
            selectedFile={null}
            onClose={this.closeMoveFile}
            listOfS3Directories={this.props.folders}
            isChangeFolder={true}
          />
        )}

        {this.state.storageIsExceedPopup && (
          <StorageAlert
            acceptMessage={this.closeStorageExceedPopup}
            closeStorageAlert={this.closeStorageExceedPopup}
          />
        )}
        <Tooltip
          id="upload-slidebox-tooltip"
          place="top"
          type="dark"
          effect="solid"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  id: state.User.session.id,
  fileList: state.Notification.fileList,
  folders: state.SlideBox.folders,
  selectedUploadFolder: state.SlideBox.selectedUploadFolder,
  slideBoxSize: state.SlideBox.slideBoxSize
});

export default connect(mapStateToProps)(withRouter(UploadSlideboxFiles));
