import React, { Component } from "react";
import MultiSelect from "../post/post-it/select-search";
import { connect } from "react-redux";
import { CPP, resetPostIt } from "../../actions/post";
import { showPrompt } from "../../actions/prompt";
import { addPost, validatePost } from "../../utils/post-utils";
import Notify from "handy-notification";
import CreatePoll from "../post/polling/create-poll";
import MentioningQuill from "../../components/post/mentioning-quill";
import CloseIcon from "../../assets/icons/close-new.svg";
import PublishIcon from './icons/publish-icon-blue.svg'
import SecondaryButton from "../others/button/secondary-btn";
import PrimaryButton from "../others/button/primary-btn";
import InfoIcon from "icons/info-new.svg";
import ToolTip from "react-tooltip";

class CaseStreamUpload extends Component {
  constructor() {
    super();
    this.state = {
      mentionedList: [],
      withPoll: false,
      poll: {},
      quillFocused: false,
      stainData: []
    };
    this.dp = this.dp.bind(this);
    this.postIt = this.postIt.bind(this);
    this.BackAndReset = this.BackAndReset.bind(this);
  }

  dp = (...args) => this.props.dispatch(CPP(...args));

  componentDidMount() {
    let { dispatch, desc, labelTags } = this.props;
    dispatch(CPP("type", "user"));
    if (desc) dispatch(CPP("desc", desc));
    if (labelTags) dispatch(CPP("labeltags", labelTags));
  }

  handleSelectChange = selectedOption => {
    const labelTags = (selectedOption || []).map(label => label.value);
    this.dp("labeltags", labelTags);
  };

  BackAndReset = async e => {
    e ? e.preventDefault() : null;
    await this.props.dispatch(resetPostIt());
    //back()
  };

  goBack = () => {
    this.props.goBack();
  };
  convertSize = size => {
    return parseFloat(size / 1024).toFixed(2) + " KB";
  };
  bytesToSize = bytes => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, index), 2) + " " + sizes[index];
  };

  checkPost = async e => {
    e.preventDefault();
    // validate post content
    try {
      let isValid = await validatePost({ ...this.props.postIt });
      if (isValid) {
        let posted = await this.postIt();

        let shouldShowPrompt = (this.props.userData.verification !== "verified" && posted)
        this.redirectAfterPublish()

        if (shouldShowPrompt) {
          this.props.dispatch(showPrompt())
        }
      }
    } catch (err) {
      return Notify({ value: err.message });
    }
  }

  redirectAfterPublish = () => {
    this.BackAndReset();
    this.props.closeModal("CLOSE MODAL");
    // history.push("/");
  }

  postIt = async e => {
    let { dispatch, postIt, slidebox, userData } = this.props;
    const { mentionedList, withPoll, poll, stainData } = this.state;
    const mentionedUsers = mentionedList.map(u => u.userId);
    try {
      return await addPost({
        dispatch,
        ...postIt,
        slidebox,
        userData,
        mentionedUsers,
        poll: withPoll ? poll : undefined,
        stainData
      });
    } catch (err) {
      return Notify({ value: err.message });
    }
  };

  handleSelectStainChange = async (value, slideBox_id) => {
    const data = {
      slideBox_id,
      stain: value
    };
    this.setState({ stainData: [...this.state.stainData, data] });
  };

  modalBack = () => {
    this.props.toggleOptions();
    this.setState({ deletePost: false });
  };

  render() {
    let { slidebox } = this.props;
    let { desc } = this.props.postIt;
    return (
      <div className="case-stream-upload">
        <div className="header-modal">
          <div className="left">
            <img src={PublishIcon} alt="PublishIcon" />
            <h3 className="add-from">Publish to Streams</h3>
          </div>
          <img
            src={CloseIcon}
            onClick={e => this.props.closeModal("CLOSE MODAL")}
            id="close_modal_streams"
            style={{ cursor: "pointer" }}
            className="close-icon"
          />
        </div>

        <div className="bottom">
          <div className="bottom1">
            <div className="left-part">
              <div className="slide-details">
                <span>Slide Details</span>
                <img
                  src={InfoIcon}
                  data-for="publish-tooltip"
                  data-tip={`Tips on getting quicker opinions<br />
                  1. Do not include any patient identifiers <br />
                  2. Use labels to get attention from experts within the field <br />
                  3. Provide appropriate evidence with caption for the case`}
                />
              </div>
              <div className="slide-list">
                <React.Fragment>
                  {slidebox &&
                    slidebox.map(s => (
                      <div
                        className="case-popup-file"
                        style={{ padding: "5px 15px" }}
                      >
                        <div className="details">
                          <span style={{ fontSize: 14, marginBottom: 0 }}>
                            {s.file_name}
                          </span>
                          <div
                            className="stain-container"
                          >
                            <span style={{ color: "#858D9B" }}>
                              {this.bytesToSize(s.file_size)}
                            </span>
                            <MultiSelect
                              data-for="publish-tooltip"
                              data-tip="Stain"
                              classname="stain-select"
                              defaultValue={{
                                value: s.stain ? s.stain : "H&E",
                                label: s.stain ? s.stain : "H&E"
                              }}
                              handleChange={({ value }) =>
                                this.handleSelectStainChange(
                                  value,
                                  s.slide_box_id
                                )
                              }
                              type={"stains"}
                              placeholder="Stains"
                              customStyles={true}
                              style={{ width: "100%", marginTop: 10 }}
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                </React.Fragment>
              </div>

              <div className="slide-details">
                <span>Case Details</span>
                <img
                  src={InfoIcon}
                  data-for="publish-tooltip"
                  data-tip={`Add description that best describe your case`}
                />
              </div>

              <MentioningQuill
                value={desc}
                placeholder="Add details to your case..."
                style={{ border: "none" }}
                onFocus={() => this.setState({ quillFocused: true })}
                onBlur={() => this.setState({ quillFocused: false })}
                className={
                  (this.state.quillFocused ? "focused " : " ") +
                  (this.state.withPoll ? "with-poll" : "")
                }
                onChange={value => this.dp("desc", value)}
                onMentionedListChange={mentionedList =>
                  this.setState({ mentionedList })
                }
              />
            </div>
            <span className="line"></span>
            <div className="right-part">
              <div className="details-poll">
                <div className="slide-details">
                  <span>Labels</span>
                  <img
                    src={InfoIcon}
                    data-for="publish-tooltip"
                    data-tip={`Add labels that best describe your case`}
                  />
                </div>
                <MultiSelect
                  classname="select_case"
                  value={null}
                  handleChange={this.handleSelectChange} //TODO: REPLACE WITH THE HANDLESELECTCHANGE FUNCTION; Make an appearing disappearing grey box shown in psd
                  options={[]}
                  placeholder="Add labels that best describe your post"
                  customStyles={true}
                  style={{ width: "100%", marginTop: 10 }}
                />


                <div className="create-poll-switch">
                  <div className="poll-switch">
                    <span>Create Poll&nbsp;</span>
                    <label class="switch small">
                      <input
                        type="checkbox"
                        onChange={() =>
                          this.setState({ withPoll: !this.state.withPoll })
                        }
                        defaultChecked={this.state.withPoll}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                {this.state.withPoll && (
                  <CreatePoll setPoll={poll => this.setState({ poll })} />
                )}
              </div>
              <div className="bottom2 prompt-bottom">
                <SecondaryButton
                  onClick={this.goBack}
                  label="Back"
                  extraClass="add-width"
                />
                <PrimaryButton
                  style={{
                    float: "right"
                  }}
                  onClick={this.checkPost}
                  label="PUBLISH"
                  extraClass="add-width"
                />
              </div>
            </div>
          </div>
        </div>
        <ToolTip
          id="publish-tooltip"
          place="right"
          type="dark"
          effect="solid"
          multiline={true}
          className="tooltip-publish"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  slidebox: state.SlideBox.casestreamModalData,
  postIt: state.Post.postIt,
  userData: state.User.current_user_details
});

export default connect(mapStateToProps)(CaseStreamUpload);
