import React, { Component } from 'react';
import FbImageLibrary from 'react-fb-image-grid';
import Modal from '../../post/post/post-middle/image-modal';

class Image extends Component {
  state = {
    modal: false,
    showImage: false
  };

  _toggle = what => this.setState({ [what]: !this.state[what] });

  loadImages = index => {
    this.openModal(index);
  };

  async openModal(index) {
    this.setState({ index, modal: true });
  }

  onClose = () => {
    this.setState({ modal: false });
  };

  isJsonArray = data => {
    return Array.isArray(data);
  };

  mapImageValues = images => {
    return images.map(image => image.src);
  };

  mapToImageArray = image => {
    const array = [];
    array.push(image);
    return array;
  };

  render() {
    let { imgSrc } = this.props;
    let { modal, index } = this.state;
    const images = this.isJsonArray(imgSrc)
      ? this.mapImageValues(imgSrc)
      : this.mapToImageArray(imgSrc);
    return (
      <div>
        <div style={{ width: '50%' }} spellCheck="false">
          <FbImageLibrary
            onClickEach={({ src, index }) => this.loadImages(index)}
            images={images}
            hideOverlay={true}
          />
        </div>
        {modal && <Modal onClose={this.onClose} index={index} images={images} />}
      </div>
    );
  }
}

export default Image;
