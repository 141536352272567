import React from 'react';

const SubmitCard = ({ imgSrc, title, desc, onClick }) => {
  return (
    <div className='submit-card' onClick={onClick}>
      <img src={`/images${imgSrc}`} className='submit-img'></img>
      <p className='submit-title'>{title}</p>
      <p className='submit-description'>{desc}</p>
    </div>
  );
}

export default SubmitCard;