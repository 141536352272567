import React from 'react'
import PostTopDetails from './top-details'
import PostOptions from './options/options'
import PropTypes from 'prop-types'

const PostTop = ({
  postDetails,
  updateDescription,
  when,
  isQuorum,
  postStatus,
  isLoggedUser,
  rate,
  rateCount,
  rated,
  submit,
  openRate,
  setOpenRate,
  isOwner
}) => (
  <div>
    <div className="p_i">
      <PostTopDetails
        details={{ ...postDetails }}
        isQuorum={isQuorum}
        postStatus={postStatus}
        isLoggedUser={isLoggedUser}
        rate={rate}
        rateCount={rateCount}
        rated={rated}
        submit={submit}
        openRate={openRate}
        setOpenRate={setOpenRate}
        isOwner={isOwner}
      />
      {isLoggedUser && (
        <PostOptions
          postDetails={{ ...postDetails }}
          postStatus={postStatus}
          updateDescription={updateDescription}
          isQuorum={isQuorum}
        />
      )}
    </div>
  </div>
)

PostTop.propTypes = {
  postDetails: PropTypes.shape({
    post_id: PropTypes.number.isRequired,
    group_id: PropTypes.number,
    type: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    when: PropTypes.string.isRequired,
    post_time: PropTypes.string.isRequired,
    group_name: PropTypes.string,
    description: PropTypes.string.isRequired,
    user: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    case_slove: PropTypes.number.isRequired,
  }).isRequired,
  updateDescription: PropTypes.func.isRequired,
}

export default PostTop
