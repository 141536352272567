import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUnreadNotifications,
  showSnackBarComponent,
  clearUploadingList
} from "../actions/notification";
import {
  setWindowWidth,
  setIsMobile,
  toggleSidebarAction
} from "../actions/settings";
import { isLogoutUser, getUserDetails } from "../actions/user";
import Header from "./others/header/header";
import AppRoutes from "./App-routes";
import {
  fetchUserBoards,
  fetchInvitations,
  fetchRequests,
  addInvitation,
  addRequest,
  addMessage,
  deleteMessage,
  editMessage
} from "../actions/tumorBoards";
import { SocketContext } from "../services/ServiceContext";
import ProgressBarComponent from "./ProgressBar";
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseSidebar: false,
      isLoggedUser: null,
      counter: 1
    };
  }

  static contextType = SocketContext;

  componentDidMount = () => {
    let { dispatch } = this.props;
    const displayWidth = window.innerWidth;
    const isMobile = this.isMobileDevice();
    dispatch(isLogoutUser());
    dispatch(setWindowWidth(displayWidth));
    dispatch(setIsMobile(isMobile));

    window.addEventListener("resize", this.handleWindowSizeChange);
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeRoute !== prevProps.activeRoute) {
      window.scrollTo(0, 0);
    }

    if (this.props.isLogOutUserStatus === "no" && this.state.counter === 1) {
      this.setState({ counter: this.state.counter + 1 });
      let { dispatch, isLogOutUserStatus } = this.props;
      const isLogLoggedUserStatus = isLogOutUserStatus === "no" ? true : false;
      if (isLogLoggedUserStatus) {
        dispatch(getUnreadNotifications());
        dispatch(fetchInvitations());
        dispatch(fetchRequests());
        dispatch(fetchUserBoards());
        dispatch(getUserDetails());
        this.context.onUpdateBoard().subscribe(() => {
          dispatch(fetchUserBoards());
        });
        this.context.onUpdateMembers().subscribe(() => {
          dispatch(fetchUserBoards());
        });
        this.context.onUpdateNotifications().subscribe(() => {
          dispatch(fetchInvitations());
        });
        this.context.onMessage().subscribe(msg => {
          const data = msg.eventData;
          if (
            data.msg.user_id !== this.props.session.id ||
            data.msg.user_id !== 0
          ) {
            const _message = {
              email: data.sender.email,
              firstName: data.sender.firstname,
              surname: data.sender.surname,
              username: data.sender.username,
              id: msg.msgId,
              board: { id: parseInt(data.tumor_board_id) },
              type: data.msg.tumor_board_message_type,
              time: data.msg.tumor_board_message_time,
              body: data.msg.tumor_board_message_msg,
              userId: data.msg.user_id,
              status: data.msg.tumor_board_status
            };
            dispatch(addMessage(_message));
          }
        });
        this.context.onMessageDeleted().subscribe(({ msgId, tumorBoardId }) => {
          if (this.props.selectedBoard) {
            if (
              parseInt(this.props.selectedBoard.id) === parseInt(tumorBoardId)
            ) {
              dispatch(deleteMessage(parseInt(msgId)));
            }
          }
        });
        this.context
          .onMessageUpdated()
          .subscribe(({ msgId, tumorBoardId, msgData }) => {
            if (this.props.selectedBoard) {
              if (
                parseInt(this.props.selectedBoard.id) === parseInt(tumorBoardId)
              ) {
                dispatch(
                  editMessage(parseInt(msgId), msgData.tumor_board_message_msg)
                );
              }
            }
          });
        this.context.onConversionDone().subscribe(data => {
          if (data && data.isLargeFile) {
            dispatch(getUnreadNotifications());
          }
        });

        this.context.onNotifyToCollaborators().subscribe(data => {
          if (data.msg === "success") {
            dispatch(getUnreadNotifications());
          }
        });

        this.context.onRequest().subscribe(req => {
          if (req.type === "invitation" || req.type === "suggestion") {
            dispatch(addInvitation(req));
          } else {
            dispatch(addRequest(req));
          }
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    let { dispatch } = this.props;
    const displayWidth = window.innerWidth;
    dispatch(setWindowWidth(displayWidth));
  };

  isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  closeSnackbar = (conversionComplete = null, clearAll = false) => {
    this.props.dispatch(showSnackBarComponent(false, [], conversionComplete));
    if (clearAll) {
      this.props.dispatch(clearUploadingList());
    }
  };

  toggleSidebar = toggle => {
    this.props.dispatch(toggleSidebarAction(!toggle));
    this.setState({ collapseSidebar: toggle });
  };

  render() {
    let {
      unreadNotifications,
      unreadInvitations,
      unreadRequests,
      unreadMessages,
      session,
      activeRoute,
      windowSize,
      isMobile,
      userData,
      currentUserData,
      selectedBoard,
      showSnackBar,
      fileList,
      conversionComplete,
      percentCompleted,
      isLogOutUserStatus,
      isFeedLoad
    } = this.props;
    return (
      <Router>
        <div className="app">
          <Header
            un={unreadNotifications + unreadInvitations + unreadRequests}
            session={session}
            activeRoute={activeRoute}
            windowSize={windowSize}
            isMobile={isMobile}
            userData={userData}
            currentUserData={currentUserData}
            toggleSidebar={this.toggleSidebar}
            collapseSidebar={!this.props.showSidebar}
            isLoggedUser={isLogOutUserStatus === "no" ? true : false}
            isFeedLoad={isFeedLoad}
          />
          <AppRoutes
            isMobile={isMobile}
            collapseSidebar={!this.props.showSidebar}
            isLoggedUser={isLogOutUserStatus === "no" ? true : false}
          />
          {showSnackBar && (
            <ProgressBarComponent
              open={showSnackBar}
              fileList={fileList}
              conversionComplete={conversionComplete}
              percentCompleted={percentCompleted}
              closeCallBack={(conversionComplete, clearAll) =>
                this.closeSnackbar(conversionComplete, clearAll)
              }
            />
          )}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = store => ({
  unreadNotifications: store.Notification.unreadNotifications,
  unreadInvitations: store.TumorBoards.invitations.length,
  unreadRequests: store.TumorBoards.requests.length,
  unreadMessages: store.Message.unreadMessages,
  session: store.User.session,
  activeRoute: store.User.activeRoute,
  isLogOutUserStatus: store.User.isLogoutUser,
  windowSize: store.Setting.windowSize,
  isMobile: store.Setting.isMobile,
  userData: store.User.user_details,
  currentUserData: store.User.current_user_details,
  selectedBoard: store.TumorBoards.selectedBoard,
  showSnackBar: store.Notification.showSnackBar,
  fileList: store.Notification.fileList,
  conversionComplete: store.Notification.conversionComplete,
  percentCompleted: store.Notification.percentCompleted,
  isFeedLoad: store.Post.isFeedLoad,
  showSidebar: store.Setting.showSidebar
});

export default connect(mapStateToProps)(App);
export { App as PureApp };
