import React from "react";
import { Popover, IconButton, MenuItem, Divider } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { FeatureGate } from "react-feature-gate";
import Move from "./icons/move.svg";
import Rename from "./icons/rename.svg";
import Delete from "./icons/delete.svg";
import Share from "./icons/get-link.svg";
import Details from "./icons/view-details.svg";

class MoreMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      copytoClipboard: false
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.props.onButtonMouseDown();
    this.setState({ anchorEl: null });
  };

  handleMenuClick = callBackFn => {
    callBackFn(
      this.props.fileData || null,
      !isNaN(this.props.fileIndex) ? this.props.fileIndex : null
    );
    this.handleClose();
  };

  setCopytoClipboard = () => {
    this.setState({ copytoClipboard: true });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      menuItems,
      id,
      onButtonMouseDown,
      fileData,
      retryMenuItem
    } = this.props;
    const open = Boolean(anchorEl);
    const image = [
      { title: "Move", img: Move },
      { title: "Rename", img: Rename },
      { title: "Delete", img: Delete },
      { title: "Share", img: Share },
      { title: "Details", img: Details }
    ]
    return (
      <React.Fragment>
        <IconButton
          id={id}
          onMouseDown={onButtonMouseDown}
          aria-owns={open ? "three-dots-options-area" : undefined}
          aria-haspopup="true"
          onClick={
            (this.props.isConvertedFile ||
              this.props.isFolder ||
              this.props.isRetry) &&
            this.handleClick
          }
        >
          <MoreVert />
        </IconButton>
        <Popover
          id="more-action"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          {menuItems &&
            !this.props.isRetry &&
            menuItems.length > 0 &&
            menuItems.map((item, index) => (
              <div key={index} className="menu-container">
                {item.isCopy ? (
                  <CopyToClipboard
                    text={`${window.location.origin}${"/viewer?image="}${fileData.key_folder
                      }`}
                    onCopy={() => this.setCopytoClipboard}
                  >
                    <MenuItem
                      disabled={item.disabled ? item.disabled : false}
                      onClick={() => this.handleMenuClick(item.callBack)}
                      className="menu-option"
                    >
                      <img src={item.img} alt={item.img} className="menuIcon" />
                      {item.title}
                    </MenuItem>
                  </CopyToClipboard>
                ) : item.title === "Add to Quorum" ? (
                  <FeatureGate feature="quorum">
                    <MenuItem
                      disabled={item.disabled ? item.disabled : false}
                      onClick={() => this.handleMenuClick(item.callBack)}
                      className="menu-option"
                    >
                      <img src={item.img} alt={item.img} className="menuIcon" />
                      {item.title}
                    </MenuItem>
                  </FeatureGate>
                ) : (
                  <MenuItem
                    className="menu-option"
                    disabled={item.disabled ? item.disabled : false}
                    onClick={() => this.handleMenuClick(item.callBack)}
                  >
                    {
                      item.img ? <img src={item.img} alt={item.img} className="menuIcon" />
                        : image.map(img =>
                          item.title === img.title ? <img src={img.img} alt={img.title} className="menuIcon" />
                            : ''
                        )
                    }
                    {item.title}
                  </MenuItem>
                )}

                {/* <Divider /> */}
              </div>
            ))}

          {menuItems &&
            this.props.isRetry &&
            retryMenuItem.length > 0 &&
            retryMenuItem.map((item, index) => (
              <div key={index} className="menu-container">
                <MenuItem
                  className="menu-option"
                  disabled={item.disabled ? item.disabled : false}
                  onClick={() => this.handleMenuClick(item.callBack)}
                >
                  <img src={item.img} alt={item.img} className="menuIcon" />
                  {item.title}
                </MenuItem>
              </div>
            ))}
        </Popover>
      </React.Fragment>
    );
  }
}

export default MoreMenu;
