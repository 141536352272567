import React from "react";
import viewerContainer from "../containers/viewer";
import OpenSeadragonViewer from "./react-openseadragon-viewer";
import SlideThumbnail from "./slide-thumbnail";
import { withRouter } from "react-router-dom";
import ToolTip from "react-tooltip";
import "../utils/react-opeanseadragon-filter";
import { connect } from "react-redux";
import ViewerCollapsibleSidebar from "./collapsible-sidebar/viewer-collapsibe-sidebar";
import OpenSeadragonControls from "./react-openseadragon-controls";
import ViewerToolBar from "./viewer-toolbar";
import OpenSeadragon from "../utils/fabricjs-overlay";

// store.Setting.windowSize
class Viewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapsibleSidebar: true,
      viewer: null
    };
    this.setFullScreenRef = React.createRef();
  }

  setViewerObject = viewer => {
    this.setState({ viewer: viewer });
  };

  toggleSidebar = () => {
    this.setState({
      showCollapsibleSidebar: !this.state.showCollapsibleSidebar
    });
  };

  render() {
    const { pathname } = this.props.history.location;

    return (
      <React.Fragment>
        {this.props.showCollapsibleSidebar && (
          <ViewerCollapsibleSidebar
            imageMetadata={this.props.imageMetadata}
            showCollapsibleSidebar={this.state.showCollapsibleSidebar}
            toggleSidebar={this.toggleSidebar}
            viewer={this.props.viewer}
          />
        )}
        <div
          className={`osd ${pathname === "/viewer" ? "osd-viewer-page" : ""}
      ${
        pathname === "/quorum" ||
        pathname === "/groups" ||
        pathname.split("/")[1] === "quorum"
          ? "osd-sub-viewer-page"
          : ""
      }
      ${(pathname === "/" || pathname.split("/")[1] === "openpost") &&
        "osd-viewer-case-page"}
      `}
        >
          <div className="row osd-row">
            <div
              className={`easer ${this.props.osdDisplay} ${this.props.viewerColumns}`}
            >
              <div className="viewer-container">
                <div className="viewer">
                  {this.props.viewerDataArray.map((viewerData, index) => (
                    <OpenSeadragonViewer
                      {...this.props}
                      currentPageId={this.props.currentPageId}
                      setViewerObject={this.setViewerObject}
                      showCollapsibleSidebar={this.state.showCollapsibleSidebar}
                      toggleSidebar={this.toggleSidebar}
                      viewerDataArray={this.props.viewerDataArray}
                      viewerData={viewerData}
                      viewerIndex={index}
                      setViewerInstance={this.props.setViewerInstance}
                      viewerInstance={
                        index === 0
                          ? this.props.imageViewer
                          : this.props.splitViewer
                      }
                      setOverlayInstance={this.props.setOverlayInstance}
                      viewerData={viewerData}
                      selectedViewer={this.props.selectedViewer}
                      viewerOnClick={this.props.viewerOnClick}
                      removeSplitSlide={this.props.removeSplitSlide}
                      setButtonInstance={this.props.setButtonInstance}
                      viewer={this.props.viewer}
                      setFullScreenRef={this.setFullScreenRef}
                      imageViewer={this.props.imageViewer}
                      splitViewer={this.props.splitViewer}
                    />
                  ))}
                </div>

                <OpenSeadragonControls
                  when={this.props.when}
                  isMobile={this.props.isMobile}
                  viewerId={this.props.viewerId}
                  postId={this.props.postId}
                  keyFolder={this.props.keyFolder ? this.props.keyFolder : ""}
                />

                {this.props.when !== "central_feed" && (
                  <ViewerToolBar
                    fromCaseStream={
                      this.props.fromCaseStream
                        ? this.props.fromCaseStream
                        : null
                    }
                    slideOwnerOrCollaborator={
                      this.props.slideOwnerOrCollaborator
                    }
                    osdButtons={this.props.osdButtons}
                    viewer={
                      this.props.currentViewerInstance
                        ? this.props.currentViewerInstance
                        : this.props.imageViewer
                    }
                    OpenSeadragon={OpenSeadragon}
                    overlay={
                      this.props.currentOverlayInstance
                        ? this.props.currentOverlayInstance
                        : this.props.overlayInstance
                    }
                    thumbnailSliderShow={this.props.thumbnailSliderShow}
                    toggleThumbnailSlider={this.props.toggleThumbnailSlider}
                    slideThumbData={this.props.slideThumbData}
                    zoomValueBar={this.state.zoomValueBar}
                    slideId={this.props.osdConfig.slideId}
                    when={this.props.when}
                    featureId={
                      this.props.when === "quorum"
                        ? this.props.quorum_id
                        : this.props.when === "groups"
                        ? this.props.groupId
                        : null
                    }
                    postIsMine={this.props.postIsMine}
                    groupAdmin={this.props.groupAdmin}
                    setFullScreenRef={this.setFullScreenRef}
                    enableFullScreenState={this.state.enableFullScreenState}
                    selectedGroupData={this.props.selectedGroupData}
                    postIsValidate={
                      this.props.postIsValidate
                        ? this.props.postIsValidate
                        : false
                    }
                    showCollapsibleSidebar={this.state.showCollapsibleSidebar}
                    toggleSlideManager={this.toggleSidebar}
                    rotationValue={this.state.rotationValue}
                    viewer1={this.props.viewer1}
                    overlayInstance={this.props.overlayInstance}
                    imageViewer={this.props.imageViewer}
                    selectedViewer={this.props.selectedViewer}
                    rotateImageHandler={this.props.rotateImageHandler}
                    rotationValue={this.props.rotationValue}
                    showCustomRotate={this.props.showCustomRotate}
                    handleRotate={this.props.handleRotate}
                    handleHomeClick={this.props.handleHomeClick}
                  />
                )}
              </div>
            </div>

            {this.props.slideThumbData.length > 0 && (
              <React.Fragment>
                {this.props.when == "groups" && (
                  <div
                    data-for="viewer-tooltip"
                    onClick={this.props.toggleThumbnailSlider}
                    data-tip={
                      this.props.thumbnailSliderShow
                        ? "Hide group history"
                        : "Show group history"
                    }
                    className={`slide-thumbnail-label ${
                      this.props.thumbnailSliderShow
                        ? "thumbnail-show"
                        : "thumbnail-hide"
                    }`}
                  >
                    Group History
                  </div>
                )}

                {this.props.thumbnailSliderShow && (
                  <SlideThumbnail
                    slides={this.props.slideThumbData}
                    changeSlide={this.props.changeSlide}
                    caseStremPostId={this.props.caseStremPostId}
                    defultKeyFolder={this.props.defultKeyFolder}
                    showSidebar={this.props.showSidebar}
                    loadFrom={this.props.loadFrom}
                    showCollapsibleSidebar={this.state.showCollapsibleSidebar}
                    collapsibleSidebarExist={this.props.showCollapsibleSidebar}
                    when={this.props.when}
                    highlightSlide={
                      this.props.when == "groups" &&
                      this.props.tumorBoardSlideId
                        ? this.props.tumorBoardSlideId
                        : null
                    }
                    groupId={this.props.groupId ? this.props.groupId : null}
                    isAdmin={
                      this.props.groupAdmin ? this.props.groupAdmin : null
                    }
                    toggleThumbnailSlider={this.props.toggleThumbnailSlider}
                    splitSlide={this.props.splitSlide}
                  />
                )}
              </React.Fragment>
            )}
          </div>
          <ToolTip id="viewer-tooltip" place="top" type="dark" effect="solid" />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  showSidebar: state.Setting.showSidebar
});

export default connect(mapStateToProps)(withRouter(viewerContainer(Viewer)));
