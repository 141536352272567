import React, { Fragment, useState } from "react";
import { FadeIn } from "animate-components";
import _ from "lodash";
import { connect } from "react-redux";
import Overlay from "../../others/overlay";
import DirectoryBuilder from "./directory-builder";
import axios from "../../../utils/axios-instance-utils";
import Notify from "handy-notification";
import ClosePopupIcon from "../../../assets/icons/close-new.svg";
import { setSelectedUploadFolder } from '../../../actions/slideBox';
import SecondaryButton from "../../others/button/secondary-btn";
import PrimaryButton from "../../others/button/primary-btn";
import MoveIcon from "./icons/move.svg";

const MoveFile = ({ onClose, selectedFile, listOfS3Directories, isChangeFolder = false, setSelectedFolder, selectedDirectory }) => {
  const [openedDirectory, setOpenedDirectory] = useState(false);
  const [toggledDirectories, setToggledDirectories] = useState({
    slidebox: true
  });
  const [openedDirectoryId, setOpenedDirectoryId] = useState(null);
  const [folderPath, setFolderPath] = useState("slidebox/");
  const [selectedDir, setSelectedDir] = useState(selectedDirectory);
  const handleSubmit = () => {
    if (isChangeFolder) {
      if (!openedDirectory) {
        Notify({ value: "No folder selected" });
      } else {
        // only set new folder
        setSelectedFolder(selectedDir)
        Notify({ value: `Selected folder  ${selectedDir.name}` });
      }
      onClose()
    } else if (openedDirectory) {
      const payload = {
        selectedFile: {
          Key: selectedFile.file_path,
          slideboxid: selectedFile.slide_box_id,
          filename: selectedFile.file_name
        },
        openedFolderId: openedDirectoryId
      };
      Notify({ value: "File moving" });
      axios
        .post("/api/moveFileObjects", payload)
        .then(response => {
          if (response.status === 200) {
            Notify({ value: "File move success" });
            onClose();
          }
        })
        .catch(error => {
          this.setState({ isLoadingDirectoryContent: false });
        });
    } else {
      Notify({ value: "Please select the folder" });
    }
  };
  const onOpenDirectoryContent = ({
    directoryPath,
    onFolderClick = false,
    parentFolder,
    isSharedFolder = false,
    name = null
  }) => {
    const openedFolderPath = directoryPath ? directoryPath : "slidebox";
    setFolderPath(openedFolderPath);
    const fileLoadPath = directoryPath ? directoryPath : folderPath;
    const openedDirectoryPath =
      fileLoadPath === "slidebox" ? "slidebox/" : fileLoadPath;
    setOpenedDirectory(openedDirectoryPath);
    const path = fileLoadPath === "slidebox" ? sessionStorage.getItem("slideBoxRootFolderId") : fileLoadPath
    setOpenedDirectoryId(path);
    setSelectedDir({
      id: path,
      name: name
    })
  };

  const onToggleDirectory = (folderPath, folderCreate = false) => {
    const addedDir = {
      [folderPath]: folderCreate ? true : !toggledDirectories[folderPath]
    };
    const newToggledDirectories = _.merge({}, toggledDirectories, addedDir);
    setToggledDirectories(newToggledDirectories);
  };

  const onToggleNestedDirectory = folderPath => {
    onToggleDirectory(folderPath);
    onOpenDirectoryContent({ directoryPath: folderPath });
  };

  return (
    <Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <div className="prompt-top">
            <div className="top-icon-and-text">
              <img
                src={MoveIcon}
                alt="move"
              />
              <span className="prompt-title">{isChangeFolder ? 'Select Folder' : "Move File"}</span>
            </div>
            <img
              className="close-icon"
              src={ClosePopupIcon}
              onClick={() => onClose(false)}
            />
          </div>
          <div className="modal_middle">
            <div className="file-manager-wrapper" style={{ overflowY: 'scroll', maxHeight: '70vh'}}>
              {listOfS3Directories.map((directory, index) => (
                <DirectoryBuilder
                  key={index}
                  index={index}
                  name={directory.name}
                  isRootFolder={directory.isRootFolder}
                  folderPath={directory.folderPath}
                  folderId={directory.folderId}
                  openedDirectory={openedDirectory}
                  onToggleDirectory={onToggleDirectory}
                  onOpenDirectoryContent={onOpenDirectoryContent}
                  toggledDirectories={toggledDirectories}
                  children={directory.children}
                  withMoreMenu={false}
                  mainRoot={directory.mainRoot}
                  onToggleNestedDirectory={onToggleNestedDirectory}
                />
              ))}
            </div>
          </div>

          <div className="prompt-bottom">
            <SecondaryButton label="Cancel" onClick={() => onClose(false)} />
            <PrimaryButton
              label={isChangeFolder ? 'Select' : "Move"}
              onClick={handleSubmit}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  selectedDirectory: state.SlideBox.selectedUploadFolder
});

const mapDispatchToProps = dispatch => ({
  setSelectedFolder: payload => dispatch(setSelectedUploadFolder(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveFile);
