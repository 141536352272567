import React from "react";
import ContentLoader from "react-content-loader";

const CaseStreamSecondLoader = () => (
  <ContentLoader
    speed={2}
    width={1180}
    height={675}
    viewBox="0 0 1180 675"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="5" y="40" rx="0" ry="0" width="1180" height="551" />{" "}
  </ContentLoader>
);

export default CaseStreamSecondLoader;
