import React from 'react'
import { Link } from 'react-router-dom'

const Route = ({ activeUrl, name, tag, url }) => {
  return (
    <div
      className={`route ${
        activeUrl === url ||
        (url === '/quorumAction' && activeUrl === '/quorum')
          ? 'active'
          : ''
      }`}
    >
      {
        <Link to={url} className={name}>
          <div className={tag}></div>
          <span>{name}</span>
        </Link>
      }
    </div>
  )
}

export default Route
