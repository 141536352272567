import React, { useState, useEffect } from "react";
import { bytesToSize } from "../../../utils/utils";
import $ from "jquery";

const SlideboxUsageBar = ({
  shouldShow = false,
  totalStorageBytes,
  currentUsageBytes
}) => {
  // State
  const [usage, setUsage] = useState("");
  const [total, setTotal] = useState("");

  useEffect(() => {
    // Init the bar
    // Calculate the usage size
    let convertedTotalStorage = bytesToSize(totalStorageBytes);
    let convertedCurrentUsage = bytesToSize(currentUsageBytes);

    let usagePercentage = currentUsageBytes / totalStorageBytes;
    let barSize = $(".meteo-progress").width();
    setUsage(convertedCurrentUsage);
    setTotal(convertedTotalStorage);

    $(".meteo-progress > span").each(function() {
      $(this)
        .data("origWidth", barSize * usagePercentage)
        .width(0)
        .animate(
          {
            width: $(this).data("origWidth")
          },
          1200
        );
    });
  });

  return (
    shouldShow && (
      <div className="slidebox-size-progress-container">
        <div className="size-detail">
          {usage} of {total} used
        </div>
        <div className="meteo-progress">
          <span></span>
        </div>
        <div className="extend-storage">
          <a href="mailto:info@crosscope.com?subject=Request to extend Crosscope Storage&body=Hi, <br /> [Add your message here] <br /> <br />">Extend Storage</a>
        </div>
      </div>
    )
  );
};

export default SlideboxUsageBar;
