import React, { Component } from "react";
// import ToTags from "../../../hashtag/toTags/toTags";
// import ImageTheatre from "../../../others/imageTheatre/imageTheatre";
import PropTypes from "prop-types";
import PostTags from "./post-tags";
import FbImageLibrary from "react-fb-image-grid";
import Modal from "./image-modal";
import { withRouter } from "react-router-dom";

class PostImage extends Component {
  state = {
    modal: false,
    showImage: false
  };

  _toggle = what => this.setState({ [what]: !this.state[what] });

  loadImages = index => {
     if(this.props.isQuorum){
      this.props.history.push(`/quorum/${this.props.postDetails.postid_token}`);
     }else{
      this.openModal(index); 
     }

  };

  async openModal(index) {
    this.setState({ index, modal: true });
  }

  onClose = () => {
    this.setState({ modal: false });
  };

  isJsonArray = data => {
    return Array.isArray(data);
  };

  mapImageValues = images => {
    return images.map(image => image.src);
  };

  mapToImageArray = image => {
    const array = [];
    array.push(image);
    return array;
  };

  render() {
    let {
      postDetails: {
        post_id,
        post_time,
        description,
        imgSrc,
        slideThumbnailSrc,
        filter,
        username,
        tags_count
      }
    } = this.props;
    let { modal, index } = this.state;
    const images = this.isJsonArray(slideThumbnailSrc)
      ? this.mapImageValues(slideThumbnailSrc)
      : this.mapImageValues(imgSrc);
    return (
      <div>
        <div className="p_o">
          <div className="p_actual" spellCheck="false">
            <FbImageLibrary
              onClickEach={({ src, index }) => this.loadImages(index)}
              images={images}
              className="image_conatiner"
              hideOverlay={true}
            />
            <PostTags post_id={post_id} tags_count={tags_count} />
          </div>
        </div>

        {/* {showImage && (
          <ImageTheatre
            imgSrc={`/posts/${imgSrc}`}
            filter={filter}
            username={username}
            time={post_time}
            link={`/post/${post_id}`}
            back={() => this._toggle("showImage")}
          />
        )} */}
        {modal && (
          <Modal onClose={this.onClose} index={index} images={images} />
        )}
      </div>
    );
  }
}

PostImage.propTypes = {
  postDetails: PropTypes.shape({
    post_id: PropTypes.number.isRequired,
    post_time: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imgSrc: PropTypes.array.isRequired,
    filter: PropTypes.string.isRequired,
    tags_count: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired
  }).isRequired
};

export default  withRouter(PostImage)
