import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import AppLink from '../../../../others/link/link'
import OpenIcon from './icons/open-icon.svg'
const base = window.location.origin
const OpenPost = ({ when, post_id, token, isQuorum }) => (
  <Fragment>
    {when != 'viewPost' && (
      <li style={{ display: 'flex' }}>
        <a href={isQuorum ? `/quorum/${token}` : `/openpost/${token}`}>
          <img
            src={OpenIcon}
            alt="case solved"
            style={{ width: 20, marginLeft: -10, marginRight: 10 }}
          />
          Open
        </a>
      </li>
    )}
  </Fragment>
)

OpenPost.propTypes = {
  when: PropTypes.string.isRequired,
  post_id: PropTypes.number.isRequired,
}

export default OpenPost
