import React, { Component } from "react";
import PrimaryButton from "../../../others/button/primary-btn";
import { deactivatOrDeleteAccount } from "../../services/edit-profile-utils";
import DeleteConfirm from "./DeleteConfirm";
import { userLogOut } from "../../../../actions/user";

export default class Delete extends Component {
  constructor() {
    super();
    this.state = {
      showDeletePopUp: false
    };
  }

  deleteAccount = status => {
    let {
      ud: { username: susername, email: semail }
    } = this.props;
    deactivatOrDeleteAccount({
      susername,
      semail,
      status: status
    });
    window.location.href = "/logout";
    sessionStorage.clear();
    this.props.dispatch(userLogOut());
  };

  loadDeleteConfirm = e => {
    e.preventDefault();
    this.setState({ showDeletePopUp: true });
  };

  deleteConfirm = () => {
    this.deleteAccount(
      this.props.account_status === "delete" ? "active" : "delete"
    );
  };

  handleCancelDeleteProfile = () => {
    this.setState({ showDeletePopUp: false });
  };

  render() {
    const { account_status, username } = this.props.ud;
    return (
      <div className="edit-tab">
        <h3 className="label-styles">Do you want to delete this account?</h3>
        <PrimaryButton
          extraClass="d-button"
          onClick={e => this.loadDeleteConfirm(e)}
          label="DELETE"
        />
        {this.state.showDeletePopUp && (
          <DeleteConfirm
            user={username}
            handleDelete={this.deleteConfirm}
            toggleOptions={() => this.handleCancelDeleteProfile()}
          />
        )}
      </div>
    );
  }
}
