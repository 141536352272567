import React from "react";
import { CirclePicker } from "react-color";

const AnnotationColorPicker = ({ customClass, onColorChange }) => {
  return (
    <div className={customClass ? customClass : "color-picker-container"}>
      <CirclePicker circleSize={26} onChangeComplete={onColorChange} />
    </div>
  );
};

export default AnnotationColorPicker;
