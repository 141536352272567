import React from 'react'
import { func } from 'prop-types'
import { connect } from 'react-redux'
import { addPost } from '../../../utils/post-utils'
import { CPP, resetPostIt } from '../../../actions/post'
import SecondaryButton from '../../others/button/secondary-btn'
import PrimaryButton from '../../others/button/primary-btn'
import { withRouter } from 'react-router-dom'

const PostItActions = props => {
  let {
    back,
    group_name,
    postIt: { fileChanged, showOverlay, ...rest },
    dispatch,
    isMobile,
    history,
  } = props

  let toggleOverlay = () => dispatch(CPP('showOverlay', !showOverlay))

  let BackAndReset = async e => {
    e ? e.preventDefault() : null
    await dispatch(resetPostIt())
    back()
  }

  let postIt = async e => {
    e.preventDefault()
    toggleOverlay()

    await addPost({
      dispatch,
      ...rest,
      group_name,
    })

    toggleOverlay()
    BackAndReset()
    history.push('/')
  }

  return (
    <div className="t_p_act p_act">
      <SecondaryButton
        label="Cancel"
        className="case_submit_cancel"
        onClick={BackAndReset}
      />
      <PrimaryButton
        label="Submit Case"
        onClick={postIt}
        disabled={!fileChanged}
        extraClass={'case_submit'}
      />
    </div>
  )
}

PostItActions.propTypes = {
  back: func.isRequired,
}

const mapStateToProps = state => ({
  group_name: state.Group.group_details.name,
  postIt: state.Post.postIt,
  isMobile: state.Setting.isMobile,
})

export default connect(mapStateToProps)(withRouter(PostItActions))
export { PostItActions as PurePostItActions }
