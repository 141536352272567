import React from 'react'
import { connect } from 'react-redux'
import { Popover, IconButton, MenuItem, Menu } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

  class PostItHeader extends React.Component { 
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const {
      icon,
      buttonStyle,
      id,
      hideSlideboxPopup,
      postIt,
      headerName,
    } = this.props
    let { location, fetchingLocation, fileChanged } = postIt
    let fetchingCondition = fetchingLocation && fileChanged
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div className="i_p_top p_top">
        <div className="i_p_info p_info">
          <span className="p_header">{headerName}</span>
          {headerName === 'File Manager' && (
            <span
              style={{
                float: 'right',
                margin: '10px 0px 0px 0px',
              }}
            >
              <React.Fragment>
                <IconButton
                  aria-haspopup="true"
                  variant="contained"
                  onClick={this.handleClick}
                >
                  <MoreVertIcon style={{ color: '#11666C' }} />
                </IconButton>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                  style={{ zIndex: '1' }}
                >
                  <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                  <MenuItem onClick={this.handleClose}>My account</MenuItem>
                  <MenuItem onClick={this.handleClose}>Logout</MenuItem>
                </Menu>
              </React.Fragment>
              <span
                style={{
                  float: 'right',
                }}
                onClick={() => hideSlideboxPopup(true)}
              >
                <i
                  class="fas fa-times"
                  style={{
                    fontSize: '20px',
                  }}
                ></i>
              </span>
            </span>
          )}
        </div>
        {/* <span className="loc_text" title={location}>
        {fetchingCondition
          ? 'Fetching location...'
          : location
            ? `${location.substr(0, 20)}..`
            : ''}
      </span> */}
      </div>
    )
}
}

const mapStateToProps = state => ({
  session: state.User.session,
  postIt: state.Post.postIt,
})

export default connect(mapStateToProps)(PostItHeader)