import React from 'react';
import Overlay from '../others/overlay';
import { FadeIn } from 'animate-components';
import SubmitCard from './SubmitCard';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';

const SubmitMenu = ({ back, history, isMobile, togglePostIt, loadQuorumModal, loadSlideboxModal }) => {
  const goToTumorBoards = () => {
    if(!isMobile) back()
    history.push('/tumor-boards') 
  }

  return (
    <div>
      {!isMobile && <Overlay />}
      <div id='submit-container'>
        <FadeIn duration='300ms' id='submit-content'> 
          <SubmitCard imgSrc='/icons/settings/case-streams.png' title='CASE STREAMS' desc='Share, view, and search from thousands of real world cases.'
            onClick={() => { isMobile ? history.push('/submit-case') : togglePostIt() }}/>
          <SubmitCard imgSrc='/icons/settings/quorum.png' title='QUORUM' desc='Learn and study from hand-picked cases, or brush up on must know diagnoses.'
            onClick={() => loadQuorumModal()}/>
          <SubmitCard imgSrc='/icons/settings/tumor-boards.png' title='TUMOR BOARDS' desc='Create group with other members and discuss slides.'
            onClick={() => goToTumorBoards()}/>
          <SubmitCard imgSrc='/icons/settings/slide-box.png' title='SLIDE BOX' desc='Slide management and storage for you personal digital slidebox.'
            onClick={() =>  loadSlideboxModal()}/>
          <SubmitCard imgSrc='/icons/settings/artificial-intelligence.png' title='TOOLKIT' desc='Use deep learning based diagnostic support tools to create efficient workflows.' 
            onClick={() => console.log('Go to Toolkit')}/>
          <SubmitCard imgSrc='/icons/settings/challenge.png' title='CHALLENGE' desc='Create quizzes/polls using your own slides.'
            onClick={() => console.log('Go to Challenge')}/>
          <img src='/images/icons/neutral/icons8-delete_sign.png' id='submit-close' onClick={() => { isMobile ? history.goBack() : back() }}></img>
        </FadeIn>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { isMobile } = state.Setting
  return { isMobile }
}

export default connect(mapStateToProps)(withRouter(SubmitMenu));