import React, { useState, useEffect, useRef } from "react";
import GroupItem from "./group-item";
import LeftTabHeader from "./left-tab-header";
import DropdownIcon from "icons/dropdown-icon.svg";
import SearchIcon from "icons/loupe.svg";
import Fade from "../../animations/Fade";
import axios from "../../../utils/axios-instance-utils";
import { connect } from "react-redux";
import { setPublicGroups } from "../../../actions/tumorBoards";
import { loadQuorum } from "../../../actions/slideBox";
import { toggleSidebarAction } from "../../../actions/settings.js";
import SortIcon from "@material-ui/icons/Sort";

const GroupFlatList = ({
  groups,
  onSetGroupState,
  selectedGroupItemIndex,
  onSelectGroupItem,
  onSelectedPublicGroupItem,
  onChangeActiveTab,
  expanded,
  publicGroups,
  dispatch,
  footerNav
}) => {
  const [createGroupPopup, setCreateGroupPopup] = useState(true);
  const [collapseGroup, setCollapseGroup] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState(groups);
  const [inputValue, setInputValue] = useState("");
  const [sorting, setSorting] = useState(false);
  const [ascending, setAscending] = useState(false);
  const [publicSorting, setPublicSorting] = useState(false);
  const [publicAscending, setPublicAscending] = useState(false);
  const [timeout, setTimeoutState] = useState(undefined);
  const [mounted, setMounted] = useState(false);
  const [isSelectedPublicGroup, setIsSelectedPublicGroup] = useState(false);
  const [done, setDone] = useState(false);
  const [initialPublicGroupSet, setInitialPublicGroupSet] = useState(false);
  const inputRef = useRef(null);

  const sortedList = (list, ascending) => {
    if (ascending) {
      return list
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((group, index) => (
          <GroupItem
            key={index}
            {...group}
            collapse={collapseGroup}
            index={index}
            selectedGroupItem={() => selectGroup(index, true)}
            active={isSelectedPublicGroup && selectedGroupItemIndex === index}
            footerNav={footerNav}
          />
        ));
    } else {
      return list
        .sort((b, a) => a.name.localeCompare(b.name))
        .map((group, index) => (
          <GroupItem
            key={index}
            {...group}
            collapse={collapseGroup}
            index={index}
            selectedGroupItem={() => selectGroup(index, true)}
            active={isSelectedPublicGroup && selectedGroupItemIndex === index}
            footerNav={footerNav}
          />
        ));
    }
  };
  const unsortedList = list => {
    return list.map((group, index) => (
      <GroupItem
        key={index}
        {...group}
        collapse={collapseGroup}
        index={index}
        selectedGroupItem={() => selectGroup(index, true)}
        active={isSelectedPublicGroup && selectedGroupItemIndex === index}
        footerNav={footerNav}
      />
    ));
  };

  useEffect(() => {
    setMounted(true);
    search();
    return () => {
      setMounted(false);
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    setGroupState(groups);
  }, [groups]);

  useEffect(() => {
    if (!footerNav) {
      if (expanded) {
        setCollapseGroup(false);
      } else {
        setCollapseGroup(true);
      }
    }
  }, [expanded]);

  useEffect(() => {
    if (inputValue !== "") {
      setDone(false);
      clearTimeout(timeout);
      if (inputValue !== "") {
        setTimeoutState(setTimeout(search, 200));
      }
      const newGroups = groups.filter(board => {
        if (board.name.toLowerCase().includes(inputValue.toLowerCase()))
          return board;
      });
      setGroupState(newGroups);
    } else {
      setGroupState(groups);
      search();
    }
  }, [inputValue]);

  const setGroupState = groups => {
    setFilteredGroups(groups);
    onSetGroupState(groups);
  };

  const onChangeCollapseGroup = () => {
    if (collapseGroup) {
      onSelectGroupItem && onSelectGroupItem(-1);
    }
    setCollapseGroup(!collapseGroup);
  };

  const search = async () => {
    const currentInputValue = inputValue;
    await axios
      .get(`/api/tumorBoards/newTBs?filter=${encodeURI(currentInputValue)}`, {
        withCredentials: true
      })
      .then(response => {
        if (response.status === 200 && mounted) {
          const groups = [];
          response.data.TumorBoards.forEach(board => {
            groups.push({
              creator: {
                firstname: board.firstname,
                surname: board.surname,
                username: board.username,
                id: board.tumor_board_creator
              },
              bio: board.tumor_board_bio,
              created: board.tumor_board_created,
              id: board.tumor_board_id,
              imgSrc: board.tumor_board_img_src,
              name: board.tumor_board_name,
              slide: board.tumor_board_slide,
              type: board.tumor_board_type,
              isPublicGroup: true
            });
          });
          setDone(true);
          onSetPublicGroup(groups);
        }
        if (response.status === 200 && !initialPublicGroupSet) {
          const groups = [];
          response.data.TumorBoards.forEach(board => {
            groups.push({
              creator: {
                firstname: board.firstname,
                surname: board.surname,
                username: board.username,
                id: board.tumor_board_creator
              },
              bio: board.tumor_board_bio,
              created: board.tumor_board_created,
              id: board.tumor_board_id,
              imgSrc: board.tumor_board_img_src,
              name: board.tumor_board_name,
              slide: board.tumor_board_slide,
              type: board.tumor_board_type,
              isPublicGroup: true
            });
          });
          setInitialPublicGroupSet(true);
          onSetPublicGroup(groups);
        }
      });
  };

  const onSetPublicGroup = groups => {
    dispatch(setPublicGroups(groups));
  };

  const onChangeHandler = event => {
    setInputValue(inputRef.current.value.trim());
  };

  const onSelectedGroupItem = (index, isPublicGroup, isMobile) => {
    !isMobile ? setCollapseGroup(true) : null;
    if (!isPublicGroup) {
      onSelectGroupItem && onSelectGroupItem(index);
    } else {
      onSelectedPublicGroupItem && onSelectedPublicGroupItem(index);
    }
    changeActiveTab("image");
  };

  const changeActiveTab = activeTab => {
    onChangeActiveTab && onChangeActiveTab(activeTab);
  };

  const selectGroup = (index, isPublic, isMobile) => {
    dispatch(toggleSidebarAction(false));
    dispatch(loadQuorum({}));
    if (isPublic) {
      onSelectedGroupItem(index, true, isMobile);
      setIsSelectedPublicGroup(true);
    } else {
      onSelectedGroupItem(index, null, isMobile);
      setIsSelectedPublicGroup(false);
    }
  };

  return (
    <React.Fragment>
      <div
        id="group-flatlist"
        className={`group-flatlist-component group-flat-${
          collapseGroup ? "close" : "open"
        }`}
      >
        {collapseGroup && (
          <button className="dropdown-btn" onClick={onChangeCollapseGroup}>
            <img src={DropdownIcon} alt="arrow" />
          </button>
        )}
        <LeftTabHeader
          collapse={!collapseGroup}
          createGroupPopup={createGroupPopup && !collapseGroup}
          onChangeActiveTab={changeActiveTab}
          onChangeCollapseGroup={onChangeCollapseGroup}
          footerNav={footerNav}
        />

        {!collapseGroup && (
          <div
            className="search_box group_search"
            style={{ padding: "5px 5px", display: "block" }}
          >
            <input
              className="search"
              style={{ height: "35px" }}
              type="text"
              placeholder="Search..."
              onChange={onChangeHandler}
              defaultValue={inputValue}
              spellCheck="false"
              autoComplete="false"
              ref={inputRef}
            />
            <img src={SearchIcon} alt="search icon" />
          </div>
        )}
        <div class="group-list">
          {inputValue === "" && filteredGroups.length > 0 && (
            <div className={"sub-list"}>
              <p id="my-boards" style={{ marginTop: "5px" }}>
                My Groups
                {!collapseGroup && (
                  <SortIcon
                    onClick={() => {
                      setPublicSorting(true);
                      setPublicAscending(!publicAscending);
                    }}
                  />
                )}
              </p>
              {publicAscending && publicSorting ?
              filteredGroups.sort((a, b) => a.name.localeCompare(b.name)).map((group, index) => (
                <GroupItem
                  key={index}
                  {...group}
                  collapse={collapseGroup}
                  index={index}
                  selectedGroupItem={selectGroup}
                  active={
                    !isSelectedPublicGroup && selectedGroupItemIndex === index
                  }
                  footerNav={footerNav}
                />
              )): publicSorting === true && publicAscending === false ?
              filteredGroups.sort((b, a) => a.name.localeCompare(b.name)).map((group, index) => (
                <GroupItem
                  key={index}
                  {...group}
                  collapse={collapseGroup}
                  index={index}
                  selectedGroupItem={selectGroup}
                  active={
                    !isSelectedPublicGroup && selectedGroupItemIndex === index
                  }
                  footerNav={footerNav}
                />
              )) : 
              filteredGroups.map((group, index) => (
                <GroupItem
                  key={index}
                  {...group}
                  collapse={collapseGroup}
                  index={index}
                  selectedGroupItem={selectGroup}
                  active={
                    !isSelectedPublicGroup && selectedGroupItemIndex === index
                  }
                  footerNav={footerNav}
                />
              ))}
            </div>
          )}
          {inputValue === "" && publicGroups.length > 0 && (
            <div className={"sub-list"}>
              <p id="my-boards" className="public-heading">
                Public Groups
                {!collapseGroup && (
                  <SortIcon
                    onClick={() => {
                      setSorting(true);
                      setAscending(!ascending);
                    }}
                  />
                )}
              </p>

              {!sorting
                ? unsortedList(publicGroups)
                : sortedList(publicGroups, ascending)}
            </div>
          )}
          {inputValue !== "" && (
            <div className={"sub-list"}>
              <p
                id="my-boards"
                style={{ marginTop: "5px" }}
                className="public-heading"
              >
                Public Groups
              </p>
              <Fade
                show={inputValue !== "" && !done}
                duration="300ms"
                delay={300}
                chaining={300}
              >
                <p id="emptyTB">Searching...</p>
              </Fade>
              <Fade
                show={inputValue !== "" && done && publicGroups.length === 0}
                duration="300ms"
                delay={300}
              >
                <p id="emptyTB">Could not find any public groups.</p>
              </Fade>

              {inputValue !== "" &&
                publicGroups.map((group, index) => (
                  <GroupItem
                    key={index}
                    {...group}
                    collapse={collapseGroup}
                    index={index}
                    selectedGroupItem={() => selectGroup(index, true)}
                    active={
                      isSelectedPublicGroup && selectedGroupItemIndex === index
                    }
                    footerNav={footerNav}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { publicGroups } = state.TumorBoards;
  return {
    publicGroups
  };
};

export default connect(mapStateToProps)(GroupFlatList);
