import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import mime from 'mime-types'
import { Picker } from 'emoji-mart'
import ShareIcon from 'icons/share-new.svg'
import CloseIcon from 'icons/close-new.svg'
import SecondaryButton from '../../../others/button/secondary-btn'
import PrimaryButton from '../../../others/button/primary-btn'
import Overlay from '../../../others/overlay'

const AttachmentModal = ({
  file,
  commentBoxId,
  isMobile,
  handleCommentWithAttachment,
  afterCommentDone,
}) => {
  const imgRef = useRef(null)
  const inputRef = useRef(null)
  const [name, setName] = useState('')
  const [text, setText] = useState('')
  const [showPicker, setShowPicker] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (file && file.type.includes('image')) {
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        imgRef.current.src = fileReader.result
      })
      fileReader.readAsDataURL(file)
    }
    if (file) {
      const nameArray = file.name.split('.')
      nameArray.pop()
      setName(nameArray.join('.'))
    }
  }, [file])

  const close = () => {
    afterCommentDone()
  }

  const sendMsg = async () => {
    if (!loading) {
      setLoading(true)
      handleCommentWithAttachment(text)
    }
  }

  const onClickOutside = e => {
    var element = document.getElementById('create-board')

    if (e.target !== element && !element.contains(e.target)) {
      close()
    }
  }

  return (
    <div id="create-board-overlay">
      <Overlay />
      <div id="create-board">
        {loading && <div id="loading-file">Sending...</div>}
        <div id="file-div">
          <div
            id="create-board-header"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <img className="share-icon" src={ShareIcon} alt="share icon" />
              <p id="share-board-title">Comment Attachment</p>
            </div>
            <img
              src={CloseIcon}
              id="close-create-board"
              onClick={() => close()}
              style={{ height: 12, marginRight: 10, cursor: 'pointer' }}
            ></img>
          </div>
          <div
            id="edit-board-content"
            style={{
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {file.type.includes('image') && (
              <div style={{ display: 'flex', padding: '10px 20px' }}>
                <img
                  alt="preview"
                  id="preview"
                  style={{
                    objectFit: 'contain',
                    border: 'solid 1px #eee',
                    width: '290px',
                    height: '200px',
                    margin: '0 auto'
                  }}
                  ref={imgRef}
                />
              </div>
            )}

            {!file.type.includes('image') && (
              <div style={{ display: 'flex', padding: '10px 20px' }}>
                <div id="previewer">
                  <p>This file cannot be previewed</p>
                  {mime.extension(file.type) && (
                    <p>.{mime.extension(file.type).toUpperCase()}</p>
                  )}
                  <p>{name}</p>
                </div>
              </div>
            )}

            <div className="write-comment">
              <input
                placeholder="Type your comment here..."
                id="input-send"
                value={text}
                ref={inputRef}
                onChange={() => setText(inputRef.current.value)}
              />
              <img
                src="/images/icons/neutral/smiley.png"
                alt="emoji"
                style={{ width: '20px', height: '20px' }}
                className="attach"
                onClick={() => setShowPicker(!showPicker)}
              />
            </div>
          </div>
          <div
            className="share-modal-footer"
          >
            <SecondaryButton
              label="Cancel"
              onClick={() => close()}
            />
            <PrimaryButton
              label="Share"
              onClick={() => sendMsg()}
            />
          </div>
          {showPicker && !isMobile && (
            <Picker onSelect={emoji => setText(text + emoji.native)} />
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  previewStyles: {
    height: '200px',
    width: '200px',
    objectFit: 'contain',
    margin: '10px 0px',
    marginLeft: '175px',
    border: 'solid 1px #eee',
  },
}

const mapStateToProps = state => {
  const { isMobile, windowSize } = state.TumorBoards
  return {
    isMobile: isMobile || windowSize < 992,
  }
}

export default connect(mapStateToProps)(AttachmentModal)
