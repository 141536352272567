/* eslint indent:0 */
/* eslint no-unreachable:0 */

import initialState from "./initialState";
import * as methods from "./methods";

export default (state = initialState, action) => {
  let py = action.payload;

  switch (action.type) {
    case "GET_USER_POSTS":
      return { ...state, posts: py };
      break;

    case "GET_BOOKMARKED_POSTS":
      let { data, username } = py;
      let isAllBookmarkLoaded = data.posts.length == 0;

      let bookmarkData = !isAllBookmarkLoaded && username === state.currentProfile && data.page > state.currentBookmarkPage
      ? {
        isAllBookmarkLoaded,
        isBookmarkLoaded: true,
        bookmarks: [...state.bookmarks, ...data.posts],
        currentBookmarkPage: state.currentBookmarkPage + 1,
        totalBookmark: data.total_count
      } : (
        isAllBookmarkLoaded ? {
          isAllBookmarkLoaded,
          isBookmarkLoaded: true
        } : {}
      )

      return {
        ...state,
        ...bookmarkData
      };
      break;

    // case "GET_BOOKMARKED_POSTS":
    //   return { ...state, bookmarks: py };
    //   break;

    case "GET_QUORUM_POSTS":
      return { ...state, quorums: py };
      break;

    case "GET_TAGGED_POSTS":
      return { ...state, tagged: py };
      break;

    case 'GET_ALL_POSTS':
      let isAllCasesLoaded = py.data.posts.length == 0;

      // do not mutate if not current user profile
      let postData = !isAllCasesLoaded && py.username === state.currentProfile && py.data.page == state.currentCaseAndQuorumPage + 1
      ? {
        isAllCasesLoaded,
        isProfileLoaded: true,
        casesAndQuorums: [...state.casesAndQuorums, ...py.data.posts],
        currentCaseAndQuorumPage: state.currentCaseAndQuorumPage + 1,
        totalCaseAndQuorum: py.data.total_count
      } : (
        isAllCasesLoaded ? {
          isAllCasesLoaded,
          isProfileLoaded: true
        } : {}
      )

      return {
        ...state,
        ...postData
      }
      break;

    // case "GET_ALL_POSTS":
    //   return { ...state, casesAndQuorums: py };
    //   break;

    case "SWITCH_GRID_VIEW":
      return { ...state, isGridView: !state.isGridView };
      break;

    case "RESET_ALL_POST_AND_BOOKMARK":
      return {
        ...state,
        currentProfile: py,
        casesAndQuorums: [],
        bookmarks: [],
        isProfileLoaded: false,
        isBookmarkLoaded: false,
        isAllCasesLoaded: false,
        isAllBookmarkLoaded: false,
        totalCaseAndQuorum: 0,
        totalBookmark: 0,
        currentCaseAndQuorumPage: 0,
        currentBookmarkPage: 0,
      }
      break;

    case "GET_SHARED_POSTS":
      return { ...state, shared: py };
      break;

    case "GET_PHOTOS":
      return { ...state, photos: py };
      break;

    case "GET_FEED":
      return {
        ...state,
        feed: methods.addFeed(state.feed, py.data),
        isFeedLoad: true,
        currentFeedPage: py.page
      };
      break;

    case "GET_FEED_NEW":
      return {
        ...state,
        feed: py,
        isFeedLoad: true,
        isAllFeedLoad: false,
        currentFeedPage: 1
      };
      break;

    case "SET_FEED_LOADING":
      return {
        ...state,
        isFeedLoad: false
      };
      break;

    case "SET_PROFILE_LOADING":
      return {
        ...state,
        isProfileLoaded: false
      };
      break;

    case "SET_BOOKMARK_LOADING":
      return {
        ...state,
        isBookmarkLoaded: false
      };
      break;

    case "SET_ALL_FEED_LOADED":
      return {
        ...state,
        isAllFeedLoad: true,
        isFeedLoad: true
      };
      break;

    case "GET_GROUP_POSTS":
      return { ...state, posts: py };
      break;

    case "GET_GROUP_PHOTOS":
      return { ...state, photos: py };
      break;

    case "GET_POST":
      return { ...state, viewPost: py };
      break;

    case "GET_QUORUM":
      return { ...state, viewQuorum: py };
      break;

    case "RESET_QUORUM":
      return { ...state, viewQuorum: {} };
      break;

    case "SET_ANNOTATION":
      return {
        ...state,
        userAnnotations: methods.setAnnotation(state.userAnnotations, py)
      };
      break;

    case "UPDATE_QUORUM":
      return {
        ...state,
        viewQuorum: methods.updateQuorum(state.viewQuorum, py)
      };
      break;

    case "UPDATE_QUORUM_DESC":
      return {
        ...state,
        viewQuorum: methods.updateQuorumDesc(state.viewQuorum, py)
      };

    case "PUBLISH_QUORUM":
      return { ...state, viewQuorum: py };
      break;

    case "IS_PUBLIC_QUORUM":
      return { ...state, isPublicQuorum: py };
      break;

    case "ADD_USER_POST":
      return {
        ...state,
        feed: methods.addPost(state.feed, py)
      };
      break;

    case "ADD_GROUP_POST":
      return {
        ...state,
        posts: methods.addPost(state.posts, py)
      };
      break;

    case "EDIT_POST":
      return {
        ...state,
        viewPost: methods.editPost(state.viewPost, py)
      };
      break;

    case "EDIT_QUORUM":
      return {
        ...state,
        viewQuorum: methods.editQuorum(state.viewQuorum, py)
      };
      break;

    case "EDIT_QUORUM_TIME":
      return {
        ...state,
        viewQuorum: methods.editQuorumTime(state.viewQuorum, py)
      };
      break;

    case "EDIT_POST_FEED":
      return {
        ...state,
        feed: methods.editPostFeed(state.feed, py)
      };
      break;

    case "DELETE_POST":
      return {
        ...state,
        posts: methods.deletePost(state.posts, py),
        feed: methods.deletePost(state.feed, py)
      };
      break;

    case "GET_POST_LIKES":
      return {
        ...state,
        likes: py.likes,
        isPostMine: py.isPostMine
      };
      break;

    case "REMOVE_LIKE":
      return {
        ...state,
        likes: methods.removeLike(state.likes, py)
      };
      break;

    case "GET_POST_TAGS":
      return {
        ...state,
        tags: py.tags,
        isPostMine: py.isPostMine
      };
      break;

    case "UNTAG":
      return {
        ...state,
        tags: methods.untag(state.tags, py)
      };
      break;

    case "GET_USERS_TO_SHARE":
      return { ...state, usersToShare: py };
      break;

    case "GET_POST_SHARERS":
      return { ...state, sharers: py };
      break;

    case "BOOKMARK":
      return {
        ...state,
        viewPost: methods.bookmarkInPost(state.viewPost, py)
      };
      break;

    //   case 'UNBOOKMARK':
    //     return {
    //       ...state,
    //       bookmarks: methods.unbookmark(state.bookmarks, py),
    //     }
    // break

    case "UNBOOKMARK":
      return {
        ...state,
        viewPost: methods.unbookmarkInPost(state.viewPost, py)
      };
      break;

    case "BOOKMARK_FEED":
      return {
        ...state,
        feed: methods.bookmarkFeed(state.feed, py)
      };
      break;

    case "UNBOOKMARK_FEED":
      return {
        ...state,
        feed: methods.unbookmarkFeed(state.feed, py)
      };
      break;

    case "REMOVE_SHARE":
      return {
        ...state,
        sharers: methods.removeShare(state.sharers, py)
      };
      break;

    case "COMMENT":
      return {
        ...state,
        viewPost: methods.comment(state.viewPost, py)
      };
      break;

    case "POSTS_COMMENT":
      return {
        ...state,
        feed: methods.postsComment(state.feed, py)
      };
      break;

    case "COMMENT_REPLY":
      return {
        ...state,
        viewPost: methods.commentReply(state.viewPost, py)
      };
      break;

    case "POSTS_COMMENT_REPLY":
      return {
        ...state,
        feed: methods.postsCommentReply(state.feed, py)
      };
      break;

    case "DELETE_COMMENT":
      return {
        ...state,
        viewPost: methods.deleteComment(state.viewPost, py)
      };
      break;

    case "EDIT_COMMENT":
      return {
        ...state,
        viewPost: methods.editComment(state.viewPost, py)
      };
      break;

    case "DELETE_COMMENT_FEED":
      return {
        ...state,
        feed: methods.deleteCommentFeed(state.feed, py)
      };
      break;

    case "EDIT_COMMENT_FEED":
      return {
        ...state,
        feed: methods.editCommentFeed(state.feed, py)
      };
      break;

    case "DELETE_REPLY":
      return {
        ...state,
        viewPost: methods.deleteReply(state.viewPost, py)
      };
      break;

    case "EDIT_REPLY":
      return {
        ...state,
        viewPost: methods.editReply(state.viewPost, py)
      };
      break;

    case "DELETE_REPLY_FEED":
      return {
        ...state,
        feed: methods.deleteReplyFeed(state.feed, py)
      };
      break;

    case "EDIT_REPLY_FEED":
      return {
        ...state,
        feed: methods.editReplyFeed(state.feed, py)
      };
      break;

    // Post It
    case "CHANGE_POSTIT_PROPS":
      return {
        ...state,
        postIt: methods.changePostIt(state.postIt, py)
      };

    case "RESET_POSTIT":
      return {
        ...state,
        postIt: methods.resetPostItProperties()
      };

    case "VOTE_COMMENT_FEED":
      return {
        ...state,
        feed: methods.voteComment(state.feed, py)
      };

    case "VOTE_REPLY_FEED":
      return {
        ...state,
        feed: methods.voteReply(state.feed, py)
      };

    case "VOTE_COMMENT_POST":
      return {
        ...state,
        viewPost: methods.voteCommentPost(state.viewPost, py)
      };

    case "VOTE_REPLY_POST":
      return {
        ...state,
        viewPost: methods.voteReplyPost(state.viewPost, py)
      };

    case "CASE_SLOVE":
      return {
        ...state,
        feed: methods.slovePost(state.feed, py)
      };

    case "POST_REVIEWED":
      return {
        ...state,
        feed: methods.reviewedPost(state.feed, py)
      };

    case "ALLOWED_POST_OWNER":
      return {
        ...state,
        feed: methods.allowPostOwner(state.feed, py)
      };

    case "CASE_SLOVE_SINGLE":
      return {
        ...state,
        viewPost: methods.slovePostSingle(state.viewPost, py)
      };

    case "MARK_SPAM":
      return {
        ...state,
        feed: methods.spamPost(state.feed, py)
      };

    case "GET_ANNOTATION_COLOR":
      return { ...state, annotationColor: py };

    case "EDIT_POST_POLL_OPTIONS":
      return {
        ...state,
        feed: methods.editPostPollOptionsFeed(state.feed, py)
      };
    case "EDIT_VIEW_POST_POLL_OPTIONS":
      return {
        ...state,
        viewPost: methods.editViewPostPollOptions(state.viewPost, py)
      };
      break;
  }

  return state;
};
