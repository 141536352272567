import React from 'react'
import moment from 'moment'

const FileItem = ({
  userId,
  firstName,
  surname,
  body,
  filename,
  time,
  img,
}) => {
  const formattedTime = moment(time).format('h:mm A')
  return (
    <div className="conversation-item">
      <div>
        {/* TODO: remove absolute link */}
        <img
          src={`/users/${userId}/avatar.jpg`}
          alt="user"
          className="user-img"
          onError={e => {
            e.target.onerror = null
            e.target.src = '/images/default-avatar.svg'
          }}
        />
      </div>
      <div className="conversation-item-content">
        <div className="conversation-item-details">
          <h3 className="details-fullname">{`${firstName} ${surname}`}</h3>
          <p>{formattedTime}</p>
        </div>
        <div className="conversation-message">
          <p>{body}</p>
          <a href={img}>{filename}</a>
        </div>
      </div>
    </div>
  )
}

export default FileItem
