import React from "react";

const AdminAnnotationLabel = ({
  enable = false,
  isPostMine,
  quorum_color,
  user_id,
  firstname,
  surname,
  disableAnnotation,
  enableAnnotation,
  quorum_id,
  annotationIds,
  slide_id,
  publishAnnotation,
  is_public,
  enabledAnnotation,
  toggle,
  isValidate,
  initailAnnoatation = false
}) => {
  return (
    <React.Fragment>
      {(isValidate || initailAnnoatation) && isPostMine && (
        <div
          className="annotation-element"
          style={{ backgroundColor: quorum_color }}
        >
          <span
            className="annotation-user"
            onClick={() =>
              toggle === "on"
                ? disableAnnotation(annotationIds)
                : enableAnnotation(annotationIds)
            }
          >
            {firstname} {surname}
          </span>
          {toggle === "on" ? (
            <span onClick={() => disableAnnotation(annotationIds)}>
              <i class="fas fa-eye annotation-logo"></i>
            </span>
          ) : (
            <span onClick={() => enableAnnotation(annotationIds)}>
              <i class="fas fa-eye-slash annotation-logo"></i>
            </span>
          )}

          {isPostMine && (
            <React.Fragment>
              {is_public ? (
                <span
                  className="toggle-off-span"
                  onClick={() => publishAnnotation(slide_id, user_id, false)}
                  title="Hide from public"
                >
                  <i class="fas fa-toggle-off"></i>
                </span>
              ) : (
                <span
                  className="toggle-on-span"
                  onClick={() => publishAnnotation(slide_id, user_id, true)}
                  title="Show to public"
                >
                  <i class="fas fa-toggle-on"></i>
                </span>
              )}{" "}
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminAnnotationLabel;
