import React, { useEffect, useRef, useState } from "react";
import ZoomIn from "../icons/zoom-in-icon-new.svg";
import ZoomOut from "../icons/zoom-out-icon-new.svg";
import Home from "../icons/home-icon-new.svg";
import FullScreen from "../icons/full-page-new.svg";
import Rotate from "../icons/rotate-icon-new.svg";
import ToolTip from "react-tooltip";
import CircularSlider from "@fseehawer/react-circular-slider";

const ViewerToolbarBasic = ({
  viewer,
  OpenSeadragon,
  enableFullScreen,
  enableFullScreenState,
  setFullScreenRef,
  rotateImageHandler,
  rotationValue,
  showCustomRotate,
  handleRotate,
  handleHomeClick
}) => {
  const homeRef = useRef(null);
  const rotateRef = useRef(null);
  useEffect(() => {
    if (typeof viewer !== "undefined") {
      const homeButton = new OpenSeadragon.Button({
        element: homeRef.current,
        onClick: handleHomeClick,
        tooltip: "Home/Reset"
      });
    }
  }, [viewer]);

  // const handleHomeClick = () => {
  //   if (typeof viewer !== "undefined") {
  //     viewer.viewport.setRotation(0);
  //     viewer.viewport.goHome();
  //     setRotationValue(0);
  //     setShowCustomRotate(false);
  //   }
  // };

  // const rotateImageHandler = val => {
  //   setRotationValue(val);
  //   viewer.viewport.setRotation(val);
  // };

  // const handleRotate = () => {
  //   setShowCustomRotate(!showCustomRotate);
  // };

  const handleFullScreen = () => {
    enableFullScreen();
  };

  return (
    <React.Fragment>
      <ul>
        <li id="zoom-in" data-for="viewer-toolbar" data-tip={"Zoom in"}>
          <img src={ZoomIn} />
        </li>
        <li id="zoom-out" data-for="viewer-toolbar" data-tip={"Zoom out"}>
          <img src={ZoomOut} />
        </li>
        <li id="home" ref={homeRef} data-for="viewer-toolbar" data-tip={"Home"}>
          <img src={Home} />
        </li>
        <li
          id="full-page"
          ref={setFullScreenRef}
          data-for="viewer-toolbar"
          data-tip={"Full page"}
          className={enableFullScreenState ? "active-tool" : ""}
        >
          <img src={FullScreen} />
        </li>
        <li
          id="rotate"
          className={
            showCustomRotate ||
            (typeof viewer !== "undefined"
              ? viewer.viewport.getRotation() === 0
                ? false
                : true
              : false)
              ? "active-tool"
              : "nonactive-tool"
          }
          ref={rotateRef}
          data-for="viewer-toolbar"
          data-tip={"Toggle rotate wheel"}
        >
          <img src={Rotate} onClick={handleRotate} />
        </li>
      </ul>
      {showCustomRotate && (
        <li className="circular-slider">
          <CircularSlider
            value
            width={80}
            progressSize={12}
            trackSize={12}
            verticalOffset={0}
            valueFontSize={1}
            labelFontSize={0}
            appendToValue={"°"}
            labelColor={"#3f51b5"}
            progressColorFrom={"#8791cc"}
            progressColorTo={"#5a649c"}
            knobColor={"#3f51b5"}
            onChange={v => rotateImageHandler(v)}
            dataIndex={rotationValue}
          />
        </li>
      )}
      <ToolTip id="viewer-toolbar" place="bottom" type="info" effect="solid" />
    </React.Fragment>
  );
};

export default ViewerToolbarBasic;
