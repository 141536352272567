import React, { Component } from 'react'
import TimeAgo from 'handy-timeago'
import { dateDetails } from '../../../../utils/utils'
import { connect } from 'react-redux'
import CommentType from './comment-type'
import CommentOptions from './options/comment-options'
import AppLink from '../../../others/link/link'
import { Comment, Header } from 'semantic-ui-react'
import { voteComment } from '../../../../utils/comment-utils'
import Notify from 'handy-notification'

@connect()
export default class Reply extends Component {
  state = {
    text: '',
    replyComment: false,
    upvote: 0,
    downvote: 0,
  }

  componentDidMount = () => this.setState({ text: this.props.text })

  _toggle = what => this.setState({ [what]: !this.state[what] })

  handleVote = vote_type => {
    let { reply_id, reply_by, post_id, when, dispatch } = this.props

    if (vote_type === 'upvote' && this.state.upvote === 0) {
      this.setState({ upvote: this.state.upvote + 1 })
      Notify({ value: "Upvoted" });
    }

    if (vote_type === 'upvote' && this.state.upvote === 1) {
      this.setState({ upvote: this.state.upvote - 1 })
      Notify({ value: "Upvote removed" });
    }

    if (vote_type === 'downvote' && this.state.downvote === 0) {
      this.setState({ downvote: this.state.downvote + 1 })
      Notify({ value: "Downvoted" });
    }

    if (vote_type === 'downvote' && this.state.downvote === 1) {
      this.setState({ downvote: this.state.downvote - 1 })
      Notify({ value: "Downvote removed" });
    }

    voteComment({
      vote_type,
      vote_by: reply_by,
      conversation_id: reply_id,
      conversation_type: 'reply',
      post_id,
      postOwner: reply_by,
      dispatch,
      when,
      // done: () => incrementComments()
    })
  }

  render() {
    let {
      reply_id,
      comment_id,
      reply_by,
      reply_by_username,
      type,
      imgReplySrc,
      reply_time,
      text,
      vote_type,
      upvote_count,
      downvote_count,
      firstname,
      surname,
      post_id,
      affiliation,
      country,
      when,
    } = this.props

    return (
      <Comment>
        <div class="comment-leftside">
          <Comment.Avatar src={`/users/${reply_by}/avatar.jpg`}
          onError={e => {
            e.target.onerror = null
            e.target.src = '/images/default-avatar.svg'
          }} />
          <div class="comment-vote">
            <span
              class={`comment-vote-up ${vote_type === 'upvote' && 'voted'}`}
              onClick={() => this.handleVote('upvote')}
              data-tip="Upvote"
            >
              {vote_type !== 'upvote' ? (
                <img src="images/img/up-vote-arrow.svg" />
              ) : (
                <img src="images/img/up-vote-arrow-a.svg" />
              )}
            </span>
            {upvote_count}
            <span
              class={`comment-vote-down ${vote_type === 'downvote' && 'voted'}`}
              onClick={() => this.handleVote('downvote')}
              data-tip="Downvote"
            >
              {vote_type !== 'downvote' ? (
                <img src="images/img/down-vote-arrow.svg" />
              ) : (
                <img src="images/img/down-vote-arrow-a.svg" />
              )}
            </span>
          </div>
        </div>
        <Comment.Content>
          <div className="comment_higlight_section">
            <div className="comment_section_row">
              <Comment.Author as="a">
                <AppLink
                  to={`/profile/${reply_by_username}`}
                >{`${firstname} ${surname}`}</AppLink>
              </Comment.Author>
              <Comment.Metadata>
                <div class="metadata-horizontal">
                  <div
                    style={{ fontSize: 11, position: 'relative', left: 15 }}
                    title={dateDetails(reply_time)}
                  >
                    {Number(TimeAgo(reply_time)) === 0
                      ? 'Now'
                      : TimeAgo(reply_time)}
                  </div>
                </div>
              </Comment.Metadata>
              <Comment.Action className="comment-options">
                <CommentOptions
                  commentDetails={{
                    reply_id,
                    comment_id,
                    comment_by: reply_by,
                    reply_by_username,
                    text,
                    type,
                    imgReplySrc,
                    post_id,
                  }}
                  // decrementComments={decrementComments}
                  when={when}
                  isReply={true}
                  updateCommentText={value => this.setState({ text: value })}
                />
              </Comment.Action>
            </div>
            <Comment.Text>
              <Comment.Metadata className="affiliation">
                <div
                  style={{ fontSize: 11, position: 'relative', top: -2 }}
                >{`${affiliation}${', '}${country}`}</div>
              </Comment.Metadata>
              <CommentType
                type={type}
                text={text}
                imgCommentSrc={imgReplySrc}
              />
            </Comment.Text>
          </div>

          {/* <Comment.Actions>
            <div className="comment_action_section upvote_section">
              <Comment.Action
                onClick={() => this.handleVote('upvote')}
                className={
                  vote_type === 'upvote' ? 'active_upvote' : 'inactive_upvote'
                }
              >
                <i class="fa fa-arrow-up"></i>
                <span
                  className={
                    vote_type === 'upvote' ? 'active_upvote' : 'inactive_upvote'
                  }
                >
                  {upvote_count} {upvote_count > 1 ? 'Upvotes' : 'Upvote'}
                </span>
              </Comment.Action>
            </div>

            <div className="comment_action_section downvote_section">
              <Comment.Action
                onClick={() => this.handleVote('downvote')}
                className={
                  vote_type === 'downvote'
                    ? 'active_downvote'
                    : 'inactive_downvote'
                }
              >
                <i class="fa fa-arrow-down"></i>
                <span
                  className={
                    vote_type === 'downvote'
                      ? 'active_downvote'
                      : 'inactive_downvote'
                  }
                >
                  Downvote
                </span>
              </Comment.Action>
            </div>
          </Comment.Actions> */}
        </Comment.Content>
      </Comment>
    )
  }
}

// Comment.propTypes = {
//   comment_id: PropTypes.number.isRequired,
//   comment_by: PropTypes.number.isRequired,
//   comment_by_username: PropTypes.string.isRequired,
//   comment_time: PropTypes.string.isRequired,
//   post_id: PropTypes.number.isRequired,
//   imgCommentSrc: PropTypes.string,
//   text: PropTypes.string,
//   type: PropTypes.oneOf(["text", "sticker", "image"]).isRequired,
//   decrementComments: PropTypes.func.isRequired
// };
