import React, { Component, Fragment } from 'react'
import { Me } from '../../../utils/utils'
import Prompt from '../../others/prompt'
import deleteIcon from '../../../assets/icons/delete-icon.svg';

class DeleteConfirm extends Component {
  state = {
    deleteFileOrFolder: false,
  }

  delete = e => {
    e.preventDefault()
    let { selectedFile, handleDelete } = this.props
    handleDelete(selectedFile)
  }

  modalBack = () => {
    this.props.toggleOptions()
    this.setState({ deleteFileOrFolder: false })
  }

  render() {
    let { user, isFile } = this.props

    return (
      <Fragment>
        {Me(user) && (
          <li style={{ display: 'flex' }}>
            <img src={DownloadIcon} alt="delete" />
            <a href="#" onClick={this.showDeleteFileOrFolder}>
              Delete {isFile ? 'file' : 'folder'}
            </a>
          </li>
        )}
        <Prompt
          title={isFile ? 'Delete file' : 'Delete folder'}
          content="This will be deleted. There's no undo so you won't be able to find it."
          actionText="Delete"
          action={this.delete}
          back={this.modalBack}
          img={deleteIcon}
          alt="Delete"
        />
      </Fragment>
    )
  }
}

export default DeleteConfirm
