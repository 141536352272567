export const showPrompt = () => {
  return {
    type: 'SHOW_PROMPT'
  }
}


export const hidePrompt = () => {
  return {
    type: 'HIDE_PROMPT'
  }
}
