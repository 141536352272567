import React from "react";


class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };

    this.listElRef = React.createRef();
    this.btnLeftElRef = React.createRef();
    this.btnRightElRef = React.createRef();
  }

  componentDidMount() {
    this.btnLeftElRef.current.addEventListener(
      "click",
      this.slideImages
    );

    this.btnRightElRef.current.addEventListener(
      "click",
      this.slideImages
    );
  }

  componentWillUnmount() {
    this.btnLeftElRef.current.removeEventListener(
      "click",
      this.slideImages
    );

    this.btnRightElRef.current.removeEventListener(
      "click",
      this.slideImages
    );
  }

  slideImages = async event => {
       const { isMobile } = this.props;
       const button =  event.target.id;
       const listEl = document.querySelector('.home-grid.products-grid.products-grid--max-4');
       const btnLeftEl = document.querySelector('#left-btn');
       const btnRightEl = document.querySelector('#right-btn');
       const totalChildren = listEl.querySelectorAll(".case-image-wrapper").length;
       if(button === "left"){
         await this.setState({count: this.state.count + 1})
       }else{
        await this.setState({count: this.state.count - 1})
       }
       listEl.style.left = this.state.count * (isMobile? 90 : 105) + 'px';
       btnLeftEl.style.display = this.state.count < 0 ? "block" : "none";
       btnRightEl.style.display = this.state.count > 3-totalChildren ? "block" : "block";
  };

  render() {
    const { children, thumbsLength } = this.props;
    const buttonStyle = thumbsLength > 3 ? {cursor: 'pointer'} : {display: 'none'};
    return (
      <div className="home-product-new-hldr">
        <div className="slider-btn-hldr slider-btn-hldr-left">
          <button
            id="left-btn"
            className="slider-btn"
            style={{ display: "none" }}
            ref= {this.btnLeftElRef}
          >
            <svg viewBox="0 0 256 256" id="left">
              <polyline
                fill="none"
                stroke="black"
                strokeWidth="16"
                points="184,16 72,128 184,240"
              ></polyline>
            </svg>
          </button>
        </div>
        <div className="home-product-new">
          <div
            className="home-grid products-grid products-grid--max-4"
            style={{ left: "0px" }}
          >
            {children}
          </div>
        </div>
        <div className="slider-btn-hldr slider-btn-hldr-right" style={ buttonStyle }>
          <button
            id="right-btn"
            className="slider-btn"
            style={{ display: "block" }}
            ref= {this.btnRightElRef}
          >
            <svg viewBox="0 0 256 256" id="right">
              <polyline
                fill="none"
                stroke="black"
                strokeWidth="16"
                points="72,16 184,128 72,240"
              ></polyline>
            </svg>
          </button>
        </div>
      </div>
    );
  }
}

export default ImageSlider;
