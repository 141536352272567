import React from 'react'
import { shape, string } from 'prop-types'
import AppLink from '../link/link'

const ModalItemInfo = ({ info }) => {
  let { link, name } = info

  return (
    <div className="modal_it_info">
      <span className="modal_it_light">{`${name}`}</span>
    </div>
  )
}

ModalItemInfo.propTypes = {
  info: shape({
    username: string.isRequired,
    firstname: string.isRequired,
    surname: string.isRequired,
  }).isRequired,
}

export default ModalItemInfo
