import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import axios from '../../../utils/axios-instance-utils'
import { deleteTB, setSelectedBoard } from '../../../actions/tumorBoards'
import UserCard from './user-card'
import ClosePopupIcon from 'icons/close.png'
import PrimaryButton from '../../others/button/primary-btn'
import SecondaryButton from '../../others/button/secondary-btn'
import Notify from 'handy-notification'

const GroupLeavePopup = ({
  close,
  loggedUser,
  selectedMembers,
  selectedBoard,
  dispatch,
  changeActiveTab
}) => {
  const [isCreator, setIsCreator] = useState(false)
  const [qty, setQty] = useState(0)

  const [searching, setSearching] = useState('')
  const [results, setResults] = useState([])
  const [delegated, setDelegated] = useState(null)

  const memberRef = useRef(null)

  useEffect(() => {
    setIsCreator(selectedBoard.creator.id === loggedUser)
    setQty(selectedMembers.length)
  }, [])

  useEffect(() => {
    if (searching !== '') {
      search()
    }
  }, [searching])

  const leave = async () => {
    if (isCreator && qty === 1) {
      const { status } = await axios
        .delete(`/api/tumorBoards/${selectedBoard.id}`, {
          withCredentials: true,
        })
        .catch(console.log)
      if (status === 200) {
        Notify({value: 'Group left'})
        dispatch(deleteTB(selectedBoard.id))
        dispatch(setSelectedBoard(null))
        close()
        changeActiveTab('')
      }
    } else if (isCreator && qty > 1 && delegated) {
      const { status } = await axios
        .patch(
          '/api/tumorBoards/leaveTB',
          { tumorBoardId: selectedBoard.id, newCreator: delegated.id },
          { withCredentials: true }
        )
        .catch(console.log)
      if (status === 200) {
        Notify({value: 'Group left'})
        dispatch(deleteTB(selectedBoard.id))
        dispatch(setSelectedBoard(null))
        close()
        changeActiveTab('')
      }
    } else {
      Notify({ value: 'Add atleast one name or email address' })
      const { status } = await axios
        .patch(
          '/api/tumorBoards/leaveTB',
          { tumorBoardId: selectedBoard.id },
          { withCredentials: true }
        )
        .catch(console.log)
      if (status === 200) {
        dispatch(deleteTB(selectedBoard.id))
        dispatch(setSelectedBoard(null))
        close()
        changeActiveTab('')
      }
    }
  }

  const search = () => {
    const _results = selectedMembers.filter(member => {
      let name = member.firstName + ' ' + member.surname
      if (
        member.id !== loggedUser &&
        (member.firstName.toLowerCase().includes(searching) ||
          name.toLowerCase().includes(searching) ||
          member.surname.toLowerCase().includes(searching) ||
          member.username.toLowerCase().includes(searching))
      )
        return member
    })
    setResults(_results)
  }

  return (
    selectedBoard && (
      <div class="prompt">
        <div class="prompt-top">
          <span className="prompt-title">Leave group</span>
          <img
            src={ClosePopupIcon}
            id="close-create-board"
            onClick={() => close()}
          />
        </div>
        <div class="prompt-middle">
          {!isCreator && (
            <div>
              <p style={{ textAlign: 'center', color: 'rgb(107, 124, 140)' }}>
                Are you sure you want to leave <span style={{ wordWrap: 'anywhere' }}>{`"${selectedBoard.name}`}</span>"? This
                action <b>cannot</b> be undone.
              </p>
            </div>
          )}
          {isCreator && qty === 1 && (
            <div>
              <p style={{ textAlign: 'center', color: 'rgb(107, 124, 140)' }}>
                Are you sure you want to leave <span style={{ wordWrap: 'anywhere' }}>{`"${selectedBoard.name}`}</span>"? You
                are the only member left and the tumor board will be{' '}
                <b>deleted</b>. This action <b>cannot</b> be undone.
              </p>
            </div>
          )}
          {isCreator && qty > 1 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <p style={{ textAlign: 'center', color: 'rgb(107, 124, 140)' }}>
                Please select a member that will inherit the creator's position
                in the tumor board.
              </p>
              <div class="input-wrapper">
                <input
                  className="create-board-input"
                  placeholder="Add names or email addresses"
                  style={{ borderColor: '#d0cfd2' }}
                  onChange={() => {
                    setSearching(memberRef.current.value.trim().toLowerCase())
                  }}
                  ref={memberRef}
                  spellCheck={false}
                />
              </div>
              {searching !== '' && (
                <div
                  className="usersSearch"
                  id={results.length > 0 ? 'withResults' : 'noResults'}
                >
                  {results.length === 0 && (
                    <p className="infoText">Could not find any users.</p>
                  )}
                  {results.map(user => (
                    <UserCard
                      user={user}
                      key={user.username}
                      addSelf={() => {
                        setDelegated(user)
                        memberRef.current.value = ''
                        setSearching('')
                      }}
                    />
                  ))}
                </div>
              )}
              {delegated && (
                <div style={{ marginTop: '10px' }}>
                  <p>
                    Delegated User:&nbsp;
                    <b>{`${delegated.firstName} ${delegated.surname}`}</b>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div class="prompt-bottom">
          <SecondaryButton label="Cancel" onClick={() => close()} />
          <PrimaryButton label="Leave" onClick={() => leave()} />
        </div>
      </div>
    )
  )
}

const mapStateToProps = state => {
  const { session } = state.User
  const { selectedMembers, selectedBoard } = state.TumorBoards
  return {
    loggedUser: session.id,
    selectedMembers,
    selectedBoard,
  }
}

export default connect(mapStateToProps)(GroupLeavePopup)
