import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AppLink from '../link/link'
import { uData } from '../../../utils/utils'
import { connect } from 'react-redux'
import { userLogOut } from '../../../actions/user'
import AccountPreferences from '../../profile/components/AcccountPreferences';

const HeaderOptions = ({
  toggleOptions,
  user,
  isMobile,
  ref,
  userData: { firstname, surname, username },
  dispatch,
}) => {
  let clicked = () => toggleOptions()
  let id = uData('session')

  const logOut = e => {
    e.preventDefault()
    window.location.href = '/logout'
    sessionStorage.clear()
    dispatch(userLogOut())
  }
  const [openAccountPreferencesModal, setOpenAccountPreferencesModal] = useState(false);
  const handleAccountPreferencesModalOpen = () => {
    setOpenAccountPreferencesModal(!openAccountPreferencesModal);
  };
  const handleAccountPreferencesModalClose = () => {
    setOpenAccountPreferencesModal(false);
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <ul className="o_ul_mobile">
          <li className="o_li_mobile_profile">
            <img
              src={`/users/${id}/avatar.jpg`}
              alt="avatar"
              className="sp_img"
              onError={e => {
                e.target.onerror = null
                e.target.src = '/images/default-avatar.svg'
              }}
            />
            <div className="user_name">
              {firstname} {surname}
            </div>
            <h5>@{username}</h5>
          </li>
          <li className="o_li_mobile" onClick={clicked}>
            <AppLink
              url={`/`}
              className="o_a"
              alt="Home"
              label="Home"
              iconClass="home"
            />
          </li>
          <li className="o_li_mobile" onClick={clicked}>
            <AppLink
              url={`/submit-case`}
              className="o_a"
              alt="Submit Case"
              label="Submit Case"
              iconClass="submit_case"
            />
          </li>
          <li className="o_li_mobile" onClick={clicked}>
            <AppLink
              url={`/profile/${user}`}
              className="o_a"
              alt="Profile"
              label="Profile"
              iconClass="profileIcon"
            />
          </li>
          <li className="o_li_mobile" onClick={clicked}>
            <AppLink
              url="/edit-profile"
              className="o_a"
              alt="Edit Profile"
              label="Edit Profile"
              iconClass="editProfileIcon"
            />
          </li>
          <li className="o_li_mobile" onClick={clicked}>
            <AppLink
              url={`/social-graph/${user}`}
              className="o_a"
              alt="Social Graph"
              label="Social Graph"
              iconClass="socialGraphIcon"
            />
          </li>
          <li className="o_li_mobile" onClick={clicked}>
            <AppLink
              url="/help"
              className="o_a"
              alt="Help"
              label="Help"
              iconClass="helpIcon"
            />
          </li>

          <li className="o_li_mobile o_divider">
            <hr className="menu_divider" />
          </li>

          <li className="o_li_mobile">
            <a href="/logout" className="o_a" alt="Settings">
              <span className="logoutIcon"></span>
              Logout
            </a>
          </li>
        </ul>
      ) : (
        <ul className="o_ul" ref={ref}>
          <li className="o_li" onClick={clicked}>
            <AppLink
              url={`/profile/${user}`}
              className="o_a"
              alt="Profile"
              label="My Profile"
              iconClass="profileIcon"
            />
          </li>
          <li className="o_li" onClick={handleAccountPreferencesModalOpen}>
            <a className="o_a">
              <span className="accountPreferencesIcon"></span>
              Account Preferences
            </a>
          </li>
          <AccountPreferences
            openModal={openAccountPreferencesModal}
            onClose={handleAccountPreferencesModalClose}
            userDetails={user}
          />
          <li className="o_li" onClick={clicked}>
            <AppLink
              url={`/social-graph/${user}`}
              className="o_a"
              alt="Social Graph"
              label="Social Graph"
              iconClass="socialGraphIcon"
            />
          </li>
          <li className="o_li" onClick={clicked}>
            <AppLink
                url="/help"
                className="o_a"
                alt="Help"
                label="Help"
                iconClass="helpIcon"
              />
          </li>
          <li className="o_li o_divider">
            <hr className="menu_divider" />
          </li>
          <li className="o_li">
            <a className="o_a" alt="Settings" onClick={logOut}>
              <span className="logoutIcon"></span>
              Logout
            </a>
          </li>
        </ul>
      )}
    </React.Fragment>
  )
}

HeaderOptions.propTypes = {
  toggleOptions: PropTypes.func.isRequired,
}

export default connect()(HeaderOptions)
