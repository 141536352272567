export default {
  conversations: [],
  messages: [],
  conAbout: {},
  unreadMessages: 0,
  onlineUsers: [],
  conDetails: {
    con_with: null,
    con_with_username: '',
    con_with_firstname: '',
    con_with_surname: '',
    isOnline: 'no',
    lastOnline: '',
  },
}
