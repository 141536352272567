import React, { Component } from "react";
import ToTags from "../../../hashtag/toTags/toTags";
import { connect } from "react-redux";

class PostDesc extends Component {
  constructor(props) {
    super(props);
    this.truncateRef = React.createRef();
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.showSidebar != this.props.showSidebar &&
      this.truncateRef &&
      typeof this.truncateRef.onResize === 'function'
    ) {
      this.truncateRef.onResize();
    }
    return null;
  }

  render() {
    let {
      isLoggedUser,
      postDetails: { description },
      goTo
    } = this.props;

    return (
      <div style={!isLoggedUser ? { marginTop: "20px" } : {}}>
        <div className="p_a row">
          <div className="p_abt_des col-lg-12">
            {
              goTo ? (
                <p>
                  <span dangerouslySetInnerHTML={{__html: description}} />
                </p>
              ) : (
                <p>
                  <span dangerouslySetInnerHTML={{__html: description}} />
                </p>
              )
            } 
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showSidebar: state.Setting.showSidebar
  };
};

export default connect(mapStateToProps)(PostDesc);
