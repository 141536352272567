import React, { Component, Fragment } from 'react'
import { markAsReviewed } from '../../../../../utils/post-utils'
import { connect } from 'react-redux'
import ReviewedIcon from './icons/case-solved-icon.svg'

class MarkAsReviewed extends Component {

  markReviewed = e => {
    e.preventDefault()
    let {
      postDetails: { post_id, isQuorum },
      dispatch,
    } = this.props
    markAsReviewed({
      post_id,
      isQuorum,
      dispatch,
    })
  }

  render() {
    return (
      <Fragment>
        <li style={{ display: 'flex' }}>
          <a href="" onClick={this.markReviewed}>
            <img
              src={ReviewedIcon}
              alt="mark reviewed"
              style={{ width: 20, marginLeft: -10, marginRight: 10 }}
            />
            Mark as Reviewed
          </a>
        </li>
      </Fragment>
    )
  }
}

export default connect()(MarkAsReviewed);
