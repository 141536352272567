import axios from "axios";
// axios.defaults.headers.authorization = axios.defaults.headers.authorization
//  ? axios.defaults.headers.authorization : 'bearer ' + sessionStorage.getItem('authToken');
axios.defaults.headers.get["Pragma"] = "no-cache";
axios.defaults.headers.get["Cache-Control"] = "no-cache, no-store";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
// axios.defaults.baseURL = 'https://accounts.crosscope.dev'
// axios.defaults.withCredentials = true
export default axios;
