import {
  ComplexImageTypes,
  UploadFileTypes,
} from '../../../../config/constants'

export const getFoldersFromResponse = response => {
  const foldersListResponse = response.Contents || []
  let folderList = []
  let folderId = 100

  for (let i = 0; i < foldersListResponse.length; i++) {
    const folderData = foldersListResponse[i]

    const itemList = folderData.Key.replace(/\/\//g, '/').split('/')

    let parentFolder = ''
    let parentPath = ''
    let firstIndex = false

    itemList.forEach((item, index) => {
      if (item.trim() === '') {
        return
      }
      if (index === itemList.length - 1 && isFile(item)) {
        let parent = folderList.find(folder => folder.path === parentPath)
        if (!parent) {
          return
        }
        parent.files.push({
          name: item,
          parentName: parentFolder,
          parentId: parentPath,
          path: parentPath + '/' + item,
          modified_date: new Date(folderData.upload_time),
          id: parentFolder + item + new Date(folderData.upload_time).valueOf(),
          size: folderData.Size,
          storageClass: folderData.StorageClass,
          eTag: folderData.ETag,
          thumbNail: folderData.thumbNail,
        })
      } else if (
        !folderList.find(
          folder => folder.name === item && folder.parentId === parentPath
        )
      ) {
        folderList.push({
          name: item,
          parentName: parentFolder,
          modified_date: new Date(folderData.LastModified),
          files: [],
          path: itemList.filter((item, itemInd) => itemInd <= index).join('/'),
          id: item + '__' + folderId,
          parentId: parentPath,
        })
      }

      folderId = folderId + 1
      parentPath = itemList
        .filter((item, itemInd) => itemInd <= index)
        .join('/')
      parentFolder = item
    })
  }

  return folderList.map(folder => {
    let size = 0
    folder.files.forEach(file => {
      size += file.size
    })
    folder.size = size
    return folder
  })
}

let fileTypes = UploadFileTypes.toString()
  .split('|')
  .map(type => {
    return type.replace('/', '')
  })

const FILE_EXTENSIONS = [...ComplexImageTypes, ...fileTypes]

const isFile = name => {
  let extensions = name.split('.')
  if (extensions && extensions.length > 0) {
    return FILE_EXTENSIONS.includes(
      extensions[extensions.length - 1].toLowerCase()
    )
  } else {
    return false
  }
}

export const getTreeStructure = folders => {
  let treeStructure = []
  folders
    .filter(obj => obj.path !== 'slidebox')
    .forEach(folder => {
      let parent = findParent(folder, treeStructure)
      if (!parent) {
        treeStructure.push({
          ...folder,
          key: folder.path,
          title: folder.name,
        })
      } else {
        if (!parent.children) {
          parent.children = []
        }
        parent.children.push({
          ...folder,
          key: folder.path,
          title: folder.name,
        })
      }
    })
  if (folders.length > 0) {
    treeStructure[0].title = 'My Slides'
  } else {
    alert()
    treeStructure.push({
      title: 'test',
    })
  }

  return treeStructure
}

const findParent = (folder, treeStructure) => {
  if (treeStructure.find(obj => obj.path === folder.parentId)) {
    return treeStructure.find(obj => obj.path === folder.parentId)
  }
  let parent = null
  for (let i = 0; i < treeStructure.length; i++) {
    parent = findParent(folder, treeStructure[i].children)
    if (parent) {
      return parent
    }
  }
  return parent
}
