import React, { Fragment } from "react";
import Post from "../post/post";
import End from "../../others/end";
import Nothing from "../../others/nothing";
import { connect } from "react-redux";

const ShowPost = ({ post, isMobile, isLoggedUser }) => {
  let { post_id } = post;
  return (
    <Fragment>
      {post_id && typeof post_id !== "undefined" ? (
        <Fragment>
          <Post
            key={post_id}
            {...post}
            when="viewPost"
            isMobile={isMobile}
            isLoggedUser={isLoggedUser}
          />
          {isLoggedUser && <End />}
        </Fragment>
      ) : (
        <Nothing mssg="No such post found" />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  post: state.Post.viewPost,
  isMobile: state.Setting.isMobile
});

export default connect(mapStateToProps)(ShowPost);
