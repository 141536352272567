import React, { Component } from "react";
import TimeAgo from "handy-timeago";
import { Me, dateDetails } from "../../../../utils/utils";
import { connect } from "react-redux";
import CommentType from "./comment-type";
import CommentOptions from "./options/comment-options";
import AppLink from "../../../others/link/link";
import classNames from "classnames";
import { Comment } from "semantic-ui-react";
import { voteComment } from "../../../../utils/comment-utils";
import CommentBox from "../../post/post-bottom/comment-box";
import Reply from "./reply";
import Notify from 'handy-notification'

@connect()
export default class CommentSection extends Component {
  state = {
    text: "",
    replyComment: false,
    allReplies: false,
    upvote: 0,
    downvote: 0,
  };

  componentDidMount = () => this.setState({ text: this.props.text });

  _toggle = what => this.setState({ [what]: !this.state[what] });

  loadReplyModal = () => {
    this.setState({ replyComment: true });
    // setTimeout(() => {
    //   document.getElementById('replyBox').focus()
    // }, 100)
  };

  modalBack = () => {
    this.setState({ replyComment: false });
  };

  handleVote = vote_type => {
    let { comment_id, comment_by, post_id, when, dispatch } = this.props;

    if (vote_type === 'upvote' && this.state.upvote === 0) {
      this.setState({ upvote: this.state.upvote + 1 })
      Notify({ value: "Upvoted" });
    }

    if (vote_type === 'upvote' && this.state.upvote === 1) {
      this.setState({ upvote: this.state.upvote - 1 })
      Notify({ value: "Upvote removed" });
    }

    if (vote_type === 'downvote' && this.state.downvote === 0) {
      this.setState({ downvote: this.state.downvote + 1 })
      Notify({ value: "Downvoted" });
    }

    if (vote_type === 'downvote' && this.state.downvote === 1) {
      this.setState({ downvote: this.state.downvote - 1 })
      Notify({ value: "Downvote removed" });
    }

    voteComment({
      vote_type,
      vote_by: comment_by,
      conversation_id: comment_id,
      conversation_type: "comment",
      post_id,
      postOwner: comment_by,
      when,
      dispatch
      // done: () => incrementComments()
    });
  };

  loadAllReply = (e, postId) => {
    e.preventDefault();
    if (this.state.allReplies) {
      document.getElementById(`post_${postId}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      });
    }
    this.setState({
      allReplies: !this.state.allReplies
    });
  };

  render() {
    let {
      comment_id,
      comment_by,
      comment_by_username,
      type,
      imgCommentSrc,
      comment_time,
      decrementComments,
      vote_type,
      upvote_count,
      downvote_count,
      replies,
      isReply,
      firstname,
      surname,
      affiliation,
      country,
      when,
      post_id
    } = this.props;
    let { text, allReplies, replyComment } = this.state;
    return (
      <div className={classNames("comments", { my_comment: Me(comment_by) })}>
        <Comment.Group>
          <Comment>
            <div class="comment-leftside">
              <Comment.Avatar src={`/users/${comment_by}/avatar.jpg`}
              onError={e => {
                e.target.onerror = null
                e.target.src = '/images/default-avatar.svg'
              }} />
              <div class="comment-vote">
                <span
                  class={`comment-vote-up ${vote_type === "upvote" && "voted"}`}
                  onClick={() => this.handleVote("upvote")}
                  data-tip="Upvote"
                >
                  {vote_type !== "upvote" ? (
                    <img src="images/img/up-vote-arrow.svg" />
                  ) : (
                    <img src="images/img/up-vote-arrow-a.svg" />
                  )}
                </span>
                {upvote_count}
                <span
                  class={`comment-vote-down ${vote_type === "downvote" &&
                    "voted"}`}
                  onClick={() => this.handleVote("downvote")}
                  data-tip="Downvote"
                >
                  {vote_type !== "downvote" ? (
                    <img src="images/img/down-vote-arrow.svg" />
                  ) : (
                    <img src="images/img/down-vote-arrow-a.svg" />
                  )}
                </span>
              </div>
            </div>

            <Comment.Content>
              <div className="comment_higlight_section">
                <div className="comment_section_row">
                  <Comment.Author as="a">
                    <AppLink
                      to={`/profile/${comment_by_username}`}
                    >{`${firstname} ${surname}`}</AppLink>
                  </Comment.Author>
                  <div className="horizontal-separator">|</div>
                  <Comment.Metadata>
                    <div class="metadata-horizontal">
                      <span
                        className="comment_reply_span"
                        onClick={this.loadReplyModal}
                      >
                        {replies.length}{" "}
                        {replies.length > 1 ? "Replies" : "Reply"}
                      </span>
                      <div
                        style={{ fontSize: 11 }}
                        title={dateDetails(comment_time)}
                      >
                        {Number(TimeAgo(comment_time)) === 0
                          ? "Now"
                          : TimeAgo(comment_time)}
                      </div>
                    </div>
                  </Comment.Metadata>
                  <Comment.Action className="comment-options">
                    {" "}
                    <CommentOptions
                      commentDetails={{
                        comment_id,
                        comment_by,
                        text,
                        type,
                        imgCommentSrc,
                        post_id
                      }}
                      decrementComments={decrementComments}
                      when={when}
                      isReply={false}
                      updateCommentText={value =>
                        this.setState({ text: value })
                      }
                    />
                  </Comment.Action>
                </div>
                <Comment.Text>
                  <Comment.Metadata className="affiliation">
                    <div
                      style={{ fontSize: 11, position: "relative", top: -2 }}
                    >
                      {`${affiliation}${", "}${country}`}
                    </div>
                  </Comment.Metadata>
                  <CommentType
                    type={type}
                    text={text}
                    imgCommentSrc={imgCommentSrc}
                  />
                </Comment.Text>
              </div>
            </Comment.Content>

            <Comment.Group className="reply_section">
              {allReplies ? (
                <div className="reply_div">
                  {this.props.replies.length > 0 &&
                    this.props.replies.map((reply, i) => (
                      <Reply when={when} key={i} {...reply}></Reply>
                    ))}
                </div>
              ) : (
                <div className="reply_div">
                  <span className="reply_showmore">
                    {replies.length !== 0 && replies.length !== 1 && (
                      <p
                        className="more_text"
                        onClick={e => this.loadAllReply(e, post_id)}
                      >
                        View more..
                      </p>
                    )}

                    {this.props.replies.length > 0 &&
                      this.props.replies
                        .slice(
                          this.props.replies.length - 1,
                          this.props.replies.length
                        )
                        .map((reply, i) => (
                          <Reply key={i} when={when} {...reply}></Reply>
                        ))}
                  </span>
                </div>
              )}
            </Comment.Group>
            {replyComment && (
              <CommentBox
                comment_id={comment_id}
                session={this.props.session}
                childProps={this.props.childProps}
                commentBoxId="replyBox"
              />
            )}
          </Comment>
        </Comment.Group>
      </div>
    );
  }
}

// Comment.propTypes = {
//   comment_id: PropTypes.number.isRequired,
//   comment_by: PropTypes.number.isRequired,
//   comment_by_username: PropTypes.string.isRequired,
//   comment_time: PropTypes.string.isRequired,
//   post_id: PropTypes.number.isRequired,
//   imgCommentSrc: PropTypes.string,
//   text: PropTypes.string,
//   type: PropTypes.oneOf(["text", "sticker", "image"]).isRequired,
//   decrementComments: PropTypes.func.isRequired
// };
