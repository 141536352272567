import React, { useState, useEffect } from "react";
import TimeAgo from "handy-timeago";
import AppLink from "../others/link/link";
import axios from "../../utils/axios-instance-utils";
import Notify from "handy-notification";
import { connect } from "react-redux";
import {
  deleteInvitation,
  deleteRequest,
  fetchUserBoards
} from "../../actions/tumorBoards";

const RequestAndInvitation = ({ payload, dispatch }) => {
  const [avatarSrc, setAvatarSrc] = useState(null);

  useEffect(() => {
    setAvatarSrc(`/users/${payload.sender.id}/avatar.jpg`);
  }, [payload]);

  const description =
    payload.type === "invitation" ? (
      <span>
        {" "}
        invited you to join <b>{payload.board.name}</b>
      </span>
    ) : payload.type === "suggestion" ? (
      <span>
        {" "}
        suggested you to request to join <b>{payload.board.name}</b>
      </span>
    ) : payload.type === "request" ? (
      <span>
        {" "}
        requested to join <b>{payload.board.name}</b>
      </span>
    ) : null;

  const accept = async () => {
    if (payload.type === "invitation") {
      const { status } = await axios
        .post("/api/tumorBoards/acceptInvitation", {
          invitationId: payload.id,
          tumorBoardId: payload.board.id
        })
        .catch(() => {
          Notify({ value: "There was an error while accepting invitation" });
        });
      if (status === 200) {
        Notify({ value: "Joined tumor board" });
        dispatch(deleteInvitation(payload.id));
      }
    } else if (payload.type === "request") {
      const { status } = await axios
        .post(
          "/api/tumorBoards/acceptRequest",
          { requestId: payload.id, tumorBoardId: payload.board.id },
          { withCredentials: true }
        )
        .catch(() => {
          Notify({ value: "There was an error while accepting request" });
        });
      if (status === 201) {
        Notify({ value: "Accepted user" });
        dispatch(deleteRequest(payload.id));
      }
    }
    dispatch(fetchUserBoards());
  };

  const reject = async () => {
    if (payload.type === "invitation") {
      const { status } = await axios
        .delete(`/api/tumorBoards/rejectInvitation/${payload.id}`, {
          withCredentials: true
        })
        .catch(() => {
          Notify({ value: "There was an error while rejecting invitation" });
        });
      if (status === 200) {
        Notify({ value: "Rejected invitation" });
        dispatch(deleteInvitation(payload.id));
      }
    } else if (payload.type === "request") {
      const { status } = await axios
        .delete(
          `/api/tumorBoards/rejectRequest/${payload.board.id}/${payload.id}`,
          { withCredentials: true }
        )
        .catch(() => {
          Notify({ value: "There was an error while rejecting request" });
        });
      if (status === 200) {
        Notify({ value: "Rejected request to join" });
        dispatch(deleteRequest(payload.id));
      }
    }
  };

  const requestToJoin = async () => {
    const { status } = await axios
      .post(
        "/api/tumorBoards/request",
        { tumorBoardId: payload.board.id },
        { withCredentials: true }
      )
      .catch(() => {
        Notify({
          value: "You have already sent a request to join"
        });
      });
    if (status === 201) {
      Notify({ value: "Sent request to join" });
      dispatch(deleteRequest(payload.id));
    }
  };

  const useDefaultAvatar = () => {
    setAvatarSrc("/images/default-avatar.svg");
  };

  return (
    <div className="noti">
      <img src={avatarSrc} className="noti_avatar" onError={useDefaultAvatar} />
      <div className="noti_left">
        <AppLink
          url={`/profile/${payload.sender.username}`}
          className="noti_bold noti_username"
          label={payload.sender.firstName + " " + payload.sender.surname}
        />
        {description}
        <span className="noti_time">
          {typeof payload.time === "string"
            ? TimeAgo(Date.parse(payload.time))
            : TimeAgo(payload.time)}
        </span>
      </div>
      <div className="noti_right follow_noti_right">
        {(payload.type === "invitation" || payload.type === "request") && (
          <a className="pri_btn accept" onClick={() => accept()}>
            Accept
          </a>
        )}
        {(payload.type === "invitation" || payload.type === "request") && (
          <a className="pri_btn reject" onClick={() => reject()}>
            Reject
          </a>
        )}
        {payload.type === "suggestion" && (
          <a className="pri_btn suggest" onClick={() => requestToJoin()}>
            Request to Join
          </a>
        )}
      </div>
    </div>
  );
};

export default connect()(RequestAndInvitation);
