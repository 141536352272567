import React from "react";
import { connect } from "react-redux";

const PostItHeader = ({ postIt, headerName, hideQuorumPopup }) => {
  let { location, fetchingLocation, fileChanged } = postIt;
  let fetchingCondition = fetchingLocation && fileChanged;

  return (
    <div className="i_p_top p_top">
      <div className="i_p_info p_info">
        <span className="p_header">{headerName}</span>
        {headerName === "Quorum" && (
          <span
            style={{
              float: "right",
              margin: "10px 0px 0px 0px"
            }}
            onClick={() => hideQuorumPopup(true)}
          >
            <i
              class="fas fa-times"
              style={{
                fontSize: "20px"
              }}
            ></i>
          </span>
        )}
      </div>
      {/* <span className="loc_text" title={location}>
        {fetchingCondition
          ? 'Fetching location...'
          : location
            ? `${location.substr(0, 20)}..`
            : ''}
      </span> */}
    </div>
  );
};

const mapStateToProps = state => ({
  session: state.User.session,
  postIt: state.Post.postIt
});

export default connect(mapStateToProps)(PostItHeader);
