/* eslint indent:0 */
/* eslint no-unreachable:0 */

import initialState from './initialState'

export default (state = initialState, action) => {
  let py = action.payload

  switch (action.type) {
    case 'GET_NOTIFICATIONS_LOADED':
      return { ...state, notificationsLoaded: py }
      break
    case 'GET_NOTIFICATIONS':
      return { ...state, notifications: py, notificationsLoaded: true }
      break

    case 'CLEAR_NOTIFICATIONS':
      return { ...state, notifications: [] }
      break

    case 'GET_UNREAD_NOTIFICATIONS':
      return { ...state, unreadNotifications: py }
      break

    case 'READ_NOTIFICATIONS':
      return { ...state, unreadNotifications: 0 }
      break

    case 'GET_NOTIFICATION_SETTINGS':
      return { ...state, notificationSettings: py }
      break

    case 'SHOW_SNACK_BAR_COMPONENT':
      return {
        ...state,
        showSnackBar: py.show,
        fileList: [...state.fileList, ...py.fileList],
        conversionComplete: py.conversionComplete,
        percentCompleted: py.percentCompleted,
      }
      break

    case 'SET_FILE_UPLOAD_PROGRESS':
      return {
        ...state,
        fileList: updateFileData(state.fileList, py.fileIndex, {
          progress: py.percentCompleted,
        }),
      }
      break

    case 'SET_FILE_CANCELLER':
      return {
        ...state,
        fileList: updateFileData(state.fileList, py.fileIndex, {
          canceller: py.canceller,
        }),
      }
      break

    case 'REMOVE_UPLOADING_FILE':
      return {
        ...state,
        fileList: removeFile(state.fileList, py.fileIndex),
      }

    case 'CLEAR_UPLOADING_LIST':
      return {
        ...state,
        fileList: [],
      }
  }

  return state
}

const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return '0 Byte'
  var index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, index), 2) + ' ' + sizes[index]
}

const updateFileData = (files, fileIndex = -1, data) => {
  if (!files[fileIndex]) return files
  if (data['progress']) {
    // update speed
    const seconds = Math.floor(Date.now() - files[fileIndex].uploadedAt) / 1000
    const speed = bytesToSize(
      Math.floor((files[fileIndex].size * data['progress']) / 100) / seconds
    )
    data.speed = `${speed}/s`
  }
  for (let key in data) {
    files[fileIndex][key] = data[key]
  }
  return [...files]
}
/**
 * We dont remove file from list, only erase it's data
 * because we need to keep the index of other files persistent on that upload patch
 */
const removeFile = (files, fileIndex = -1) => {
  if (!files[fileIndex]) return files
  // files.splice(fileIndex, 1)
  files[fileIndex] = undefined
  return [...files]
}
