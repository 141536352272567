import React, { useState, useEffect } from 'react'
import { FadeIn } from 'animate-components'
import { Form, Col } from 'react-bootstrap'
import moment from 'moment'

import ModalHeader from '../../others/modal/modal-header'
import PrimaryButton from '../../others/button/primary-btn'
import ModalBack from '../../others/modal/modal-back'
import Overlay from '../../others/overlay'
import { createPoll } from '../../../utils/post-utils'

const CreatePoll = ({ setPoll }) => {
  const [question, setQuestion] = useState('')
  const [lengthInDay, setLengthInDay] = useState(1)
  const [lengthInHour, setLengthInHour] = useState(0)
  const [lengthInMinute, setLengthInMinute] = useState(0)
  const [options, setOptions] = useState(['', ''])

  const onQuestionChange = async question => {
    await setQuestion(question)
    // updatePoll()
  }
  useEffect(() => {
    updatePoll()
  })

  const setLength = async (value, unit) => {
    let integerValue = value;
    if (value < 0) {
      integerValue = parseInt(value, 10);
    }
    const absoluteValue = Math.abs(integerValue);
    if (unit === 'day') {
      await setLengthInDay(absoluteValue)
    } else if (unit === 'hour') {
      await setLengthInHour(absoluteValue)
    } else if (unit === 'minute') {
      await setLengthInMinute(absoluteValue)
    }
    updatePoll()
  }

  const setOption = async (i, value) => {
    options[i] = value
    await setOptions([...options])
    // updatePoll()
  }
  const addOption = async () => {
    await setOptions([...options, ''])
    // updatePoll()
  }
  const removeOption = async i => {
    options.splice(i, 1)
    await setOptions([...options])
    // updatePoll()
  }
  const updatePoll = () => {
    setTimeout(() => {
      const expiredAt =
        moment()
          .add(lengthInDay, 'days')
          .add(lengthInHour, 'hours')
          .add(lengthInMinute, 'minutes')
          .unix() * 1000
      setPoll({
        question,
        options,
        expiredAt,
      })
    })
  }
  return (
    <React.Fragment>
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Length</Form.Label>
            <Form.Control
              type="number"
              value={lengthInDay}
              placeholder="days"
              onChange={(e) => setLength(e.target.value, 'day')}
              min="0"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>&nbsp;</Form.Label>
            <Form.Control
              type="number"
              value={lengthInHour}
              placeholder="hours"
              onChange={e => setLength(e.target.value, 'hour')}
              min="0"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>&nbsp;</Form.Label>
            <Form.Control
              type="number"
              value={lengthInMinute}
              placeholder="minutes"
              name="minutes"
              onChange={e => setLength(e.target.value, 'minute')}
              min="0"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group class="create-poll-options" as={Col}>
            <Form.Label>Options</Form.Label>
            {options.map((option, i) => (
              <div className="option" key={i}>
                <Form.Control
                  type="text"
                  defaultValue={option.value}
                  placeholder={`Option ${i + 1}`}
                  onChange={e => setOption(i, e.target.value)}
                />
                {i >= 2 && (
                  <div onClick={() => removeOption(i)}>
                    <i class="fas fa-times"></i>
                  </div>
                )}
              </div>
            ))}
          </Form.Group>
        </Form.Row>
        {options.length < 4 && (
          <Form.Row>
            <Form.Group>
              <div className="add-options-btn" onClick={addOption}>
                {/* <img src={plusSrc} className="button-plus-img" /> */}
                <i class="fas fa-plus"></i>
                <span>Add option</span>
              </div>
            </Form.Group>
          </Form.Row>
        )}
      </Form>
    </React.Fragment>
  )
}

export default CreatePoll
