import React, { Component } from "react";
import Viewer from "./components/viewer";
import "./ImageViewer.scss";
import axios from "../../utils/axios-instance-utils";
import $ from "jquery";
import { connect } from "react-redux";
import { setViewerDataArray } from "../../actions/viewer";
import openseadragonAnnotations from "./utils/openseadragon-annotations";
import Notify from "handy-notification";

class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
      imageData: undefined,
      imageOtherSlideData: [],
      caseStremPostId: null,
      thumbnailSliderShow: props.when == "viewer" ? true : false,
      thumbToken: null,
      navigatorShow: true,
      loadFrom: null,
      fromCaseStream: false,
      postIsMine: null,
      slideOwnerOrCollaborator: null,
      loadSlideId: null,
      splitSlideData: null,
      selectedViewer: 0,
      OPENSEADRAGONVIEWER: undefined,
      OPENSEADRAGONBUTTON: undefined,
      OPENSEADRAGONVIEWER1: undefined,
      OPENSEADRAGONBUTTON1: undefined,
      overlayInstance: undefined,
      overlayInstanceSplitViewer: undefined,
      currentOverlayInstance: undefined,
      currentViewerInstance: undefined,
      showCustomRotate: false,
      rotationStart: false,
      orgAngleArray: null,
      orgAngleArraySplitView: null,
      currentButtonInstance: undefined,
      rotationValue: 0
    };
  }

  componentDidMount() {
    // this.props.toggleSidebarAction(true);
    this.updateImageViewer(this.props);
  }

  componentWillMount() {
    sessionStorage.removeItem("selectedSlide");
    this.updateImageViewer(this.props);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.location !== this.props.location ||
      nextProps.value !== this.props.value ||
      nextProps.dziData !== this.props.dziData ||
      nextProps.slideBoxId !== this.props.slideBoxId
    ) {
      if (this.props.location && this.props.location.pathname === "/viewer") {
        const urlParams = new URLSearchParams(window.location.search);
        const folderKey = urlParams.get("image");
        const thumb = urlParams.get("thumb");
        const postId = urlParams.get("post");
        if (thumb) {
          this.loadImageDataWithThumbInSlideBox(folderKey, thumb);
        }
        if (postId) {
          this.loadImageDataWithThumbInCaseStream(folderKey, postId);
          this.setState({ fromCaseStream: true });
        }
      } else {
        this.updateImageViewer(nextProps);
      }
    }
    return true;
  }

  updateImageViewer = props => {
    if (props.location) {
      const {
        location: { search }
      } = this.props;
      const searchData = search.split("=");
      this.value = searchData[1].split("&")[0];
      if (typeof searchData[1].split("&")[1] !== "undefined") {
        const searchType = searchData[1].split("&")[1];
        if (searchType === "thumb") {
          const thumbToken = searchData[2];
          this.setState({ thumbToken: thumbToken });
          this.loadImageDataWithThumbInSlideBox(this.value, thumbToken);
        } else {
          const postId = searchData[2];
          this.setState({ caseStremPostId: postId, fromCaseStream: true });
          this.loadImageDataWithThumbInCaseStream(this.value, postId);
        }
      } else {
        this.loadImageDataWithoutThumb(this.value);
      }
    } else {
      if (
        props.when === "central_feed" ||
        props.when === "groups" ||
        props.when === "quorum"
      ) {
        if (props.slideBoxId) {
          this.setState({ postIsMine: props.postIsMine });
          this.loadImageDataWithoutThumb(
            props.slideBoxId,
            true,
            props.keyFolder
          );
        }
      }
    }
  };

  loadImageDataWithThumbInCaseStream = (value, postId) => {
    this.setState({ loadFrom: "case-view" });
    axios
      .post("api/getFileData", {
        caseStreamId: postId,
        keyFolder: decodeURI(value),
        showSlideThumb: true
      })
      .then(response => {
        this.handleOnDziData(response.data[0]);
        this.setState({
          imageData: response.data[0],
          postIsMine: response.data[0].postIsMine
        });
        this.setState({
          imageOtherSlideData: response.data[0].slide_thumbdata
        });
      })
      .catch(e => console.warn(e));
  };

  loadImageDataWithThumbInSlideBox = (value, thumToken) => {
    this.setState({ loadFrom: "slide-view" });
    axios
      .post("api/getFileData", {
        thumbToken: thumToken,
        keyFolder: decodeURI(value),
        showSlideThumb: true,
        isPublicFolder:
          this.props.selectedUploadFolder &&
          this.props.selectedUploadFolder.isPublicFolder
            ? true
            : false
      })
      .then(response => {
        this.handleOnDziData(response.data[0]);
        this.setState({ imageData: response.data[0] });
        this.setState({
          imageOtherSlideData: response.data[0].slide_thumbdata,
          slideOwnerOrCollaborator: response.data[0].slideOwnerOrCollaborator
        });
      })
      .catch(e => console.warn(e));
  };

  loadImageDataWithoutThumb = (value, slideId = false, folderKey) => {
    let dataParams;
    if (slideId) {
      dataParams = {
        moduleName: "slidebox",
        showSlideThumb: false,
        fileId: value,
        isGroup: true,
        groupId: this.props.selectedGroup ? this.props.selectedGroup.id : null,
        keyFolder:
          typeof value !== "number" && value.toString().includes("PU")
            ? folderKey
            : null,
        isPublicFolder:
          typeof value !== "number" && value.toString().includes("PU")
            ? true
            : false
      };
    } else {
      dataParams = {
        moduleName: "slidebox",
        showSlideThumb: false,
        keyFolder: value
      };
    }
    axios
      .post("api/getFileData", dataParams)
      .then(response => {
        this.handleOnDziData(response.data[0]);
        this.setState({
          imageData: response.data[0],
          imageOtherSlideData: response.data[0].slide_thumbdata
        });
      })
      .catch(e => console.warn(e));
  };

  setViewerInstance = (instance, index) => {
    if (index <= 1) {
      if (index === 0) {
        this.setState({ OPENSEADRAGONVIEWER: instance });
      } else {
        this.setState({ OPENSEADRAGONVIEWER1: instance });
      }
    }
  };

  setButtonInstance = (instance, index) => {
    if (index <= 1) {
      if (index === 0) {
        this.setState({ OPENSEADRAGONBUTTON: instance });
      } else {
        this.setState({ OPENSEADRAGONBUTTON1: instance });
      }
    }
  };

  viewerOnClick = index => {
    const {
      OPENSEADRAGONBUTTON,
      OPENSEADRAGONVIEWER,
      overlayInstance,
      OPENSEADRAGONBUTTON1,
      OPENSEADRAGONVIEWER1,
      overlayInstanceSplitViewer
    } = this.state;
    if (index === 0) {
      this.setState({
        currentButtonInstance: OPENSEADRAGONBUTTON,
        currentViewerInstance: OPENSEADRAGONVIEWER,
        currentOverlayInstance: overlayInstance,
        selectedViewer: index,
        showCustomRotate: false,
        rotationStart: false,
        orgAngleArray: null
      });
    } else {
      this.setState({
        currentButtonInstance: OPENSEADRAGONBUTTON1,
        currentViewerInstance: OPENSEADRAGONVIEWER1,
        currentOverlayInstance: overlayInstanceSplitViewer,
        selectedViewer: index,
        showCustomRotate: false,
        rotationStart: false,
        orgAngleArray: null
      });
    }
  };

  removeSplitSlide = async (event, indexVal) => {
    event.stopPropagation();
    if (indexVal === 0) {
      const {
        OPENSEADRAGONBUTTON1,
        OPENSEADRAGONVIEWER1,
        overlayInstanceSplitViewer
      } = this.state;
      await this.setState({
        currentButtonInstance: OPENSEADRAGONBUTTON1,
        currentViewerInstance: OPENSEADRAGONVIEWER1,
        currentOverlayInstance: overlayInstanceSplitViewer,
        OPENSEADRAGONVIEWER: OPENSEADRAGONVIEWER1,
        OPENSEADRAGONBUTTON: OPENSEADRAGONBUTTON1,
        overlayInstance: overlayInstanceSplitViewer,
        rotationStart: false,
        orgAngleArray: null,
        orgAngleArraySplitView: null
      });
      this.props.history.push(
        `/viewer?image=${this.state.splitSlideData.key_folder}&thumb=${this.state.thumbToken}`
      );
    } else {
      this.props.history.push(
        `/viewer?image=${this.state.imageData.key_folder}&thumb=${this.state.thumbToken}`
      );
    }
    await this.setState({
      selectedViewer: indexVal === 1 ? 0 : 1,
      splitSlideData: null
    });
    const newSlideArray = this.props.viewerDataArray.filter(
      (data, index) => index !== indexVal
    );

    this.props.setViewerData(newSlideArray);
  };

  fetchImageMetadata = (url, initialLoad, imgData) => {
    fetch(url + "vips-properties.xml").then(response => {
      response.blob().then(blob => {
        const reader = new FileReader();
        const ABS = !!reader.readAsBinaryString;

        reader.onload = e => {
          this.metadata = this.parseMetadataXML(e.target.result);
          const { zoom } = this.props;
          const dziTileData = this.tileSourceFromData(
            this.dziData,
            this.dziFilesUrl
          );

          this.setState({ loadSlideId: imgData.slide_box_id });

          const config = {
            basename: "example/image",
            getImageURL: dziTileData,
            thumbnail: dziTileData,
            osdConfig: {
              setStrings: [{ name: "Tooltips.Home", value: "Reset" }],
              defaultZoomLevel: zoom ? zoom : 0,
              tileSources: dziTileData,
              navigatorPosition: "ABSOLUTE",
              navigatorTop: "50px",
              navigatorLeft: "0px",
              navigatorHeight: "120px",
              navigatorWidth: "145px",
              visibilityRatio: 1.0,
              constrainDuringPan: true,
              sequenceMode: false,
              showReferenceStrip: false,
              showNavigator: true,
              navigatorAutoFade: false,
              zoomInButton:
                typeof this.props.viewerId === "undefined"
                  ? "zoom-in"
                  : `zoom-in-${this.props.viewerId}`,
              zoomOutButton:
                typeof this.props.viewerId === "undefined"
                  ? "zoom-out"
                  : `zoom-out-${this.props.viewerId}`,
              homeButton: "home",
              fullPageButton: "full-page",
              rotate: "rotate",
              crossOriginPolicy: "Anonymous",
              canvasDrag: false,
              slideId:
                typeof imgData !== "undefined"
                  ? initialLoad
                    ? imgData.slidebox_slide_id
                    : imgData.slideId
                  : this.props.dziData
                  ? this.props.dziData.slide_box_id
                  : null
              // toolbar: "viewer-toolbar-container"
            },
            imageMetadata: {
              ...this.metadata,
              "aperio.Filename":
                typeof imgData !== "undefined"
                  ? typeof imgData.slide_name !== "undefined"
                    ? imgData.slide_name
                    : typeof imgData.slideName !== "undefined"
                    ? imgData.slideName
                    : ""
                  : ""
            },
            pages: [
              {
                id: 0,
                title: "MLK",
                sidebarThumbnail: dziTileData,
                transcript: "MLK",
                viewer: "OSD_VIEWER",
                cdmCollection: "mpls",
                cdmIdentifier: "3188",
                infoURL: dziTileData
              }
            ]
          };
          if (initialLoad) {
            this.props.setViewerData([
              {
                id: `osd-viewer${1}`,
                config: config
              }
            ]);
          } else {
            this.props.setViewerData([
              ...this.props.viewerDataArray,
              {
                id: `osd-viewer${this.props.viewerDataArray.length + 1}`,
                config: config
              }
            ]);
          }
          this.setState({ isDataLoaded: true });
        };

        if (ABS) reader.readAsBinaryString(blob);
        else reader.readAsArrayBuffer(blob);
      });
    });
  };

  parseMetadataXML = data => {
    let imageMetadata = {};
    const xml = $($.parseXML(data));
    const image = xml.find("image");
    const props = image.children().children();
    for (let p in props) {
      let property = props[p];
      if (property.firstElementChild) {
        let key = property.firstElementChild.innerHTML,
          value = property.lastElementChild.innerHTML;
        imageMetadata[key] = value;
      }
    }
    return imageMetadata;
  };

  handleOnDziData = (imgData, initialLoad = true) => {
    if (_.isEmpty(imgData)) {
      return;
    }
    this.loadDziData(imgData);

    // this.setState({ isDataLoaded: false });
    // this.dziFilesUrl =
    //   typeof imgData.dzi_url !== "undefined"
    //     ? imgData.module_name === "public"
    //       ? `${imgData.dzi_url}`
    //       : imgData.dzi_url
    //     : null;
    // fetch(
    //   imgData.module_name === "public"
    //     ? `${imgData.dzi_url.split(".dzi_files/")[0]}.dzi.dzi`
    //     : imgData.dzi_data
    // ).then(response => {
    //   response.blob().then(blob => {
    //     const reader = new FileReader();
    //     const ABS = !!reader.readAsBinaryString;
    //     reader.onload = e => {
    //       this.dziData = e.target.result; // This will give the xml string of that file
    //       this.fetchImageMetadata(this.dziFilesUrl, initialLoad, imgData);
    //     };

    //     if (ABS) reader.readAsBinaryString(blob);
    //     else reader.readAsArrayBuffer(blob);
    //   });
    // });
  };

  tileSourceFromData = (data, filesUrl) => {
    const xml = $($.parseXML(data));
    const image = xml.find("Image");
    const size = xml.find("Size");
    let dzi = {
      Image: {
        xmlns: image.attr("xmlns"),
        Url: filesUrl,
        Format: image.attr("Format"),
        Overlap: image.attr("Overlap"),
        TileSize: image.attr("TileSize"),
        Size: {
          Height: size.attr("Height"),
          Width: size.attr("Width")
        }
      }
    };

    return dzi;
  };

  changeSlideImage = (keyFolder, slideId = null) => {
    if (this.props.when == "groups") {
      this.props.changeThumbnailSlide(slideId);
      return;
    }

    const { caseStremPostId, thumbToken } = this.state;
    if (caseStremPostId) {
      this.props.history.push(
        `/viewer?image=${keyFolder}&post=${caseStremPostId}`
      );
    } else {
      this.props.history.push(`/viewer?image=${keyFolder}&thumb=${thumbToken}`);
    }
  };

  toggleThumbnailSlider = () => {
    this.setState({ thumbnailSliderShow: !this.state.thumbnailSliderShow });
  };

  toggleNavigator = () => {
    this.setState({ navigatorShow: !this.state.navigatorShow });
  };

  loadDziData = (imgData, initialLoad = true) => {
    if (initialLoad) {
      this.setState({ isDataLoaded: false });
    }

    console.log(imgData);

    this.dziFilesUrl =
      typeof imgData.dzi_url !== "undefined" ? imgData.dzi_url : null;
    fetch(`${imgData.dzi_url.split(".dzi_files/")[0]}.dzi.dzi`).then(
      response => {
        response.blob().then(blob => {
          const reader = new FileReader();
          const ABS = !!reader.readAsBinaryString;
          reader.onload = e => {
            this.dziData = e.target.result; // This will give the xml string of that file
            this.fetchImageMetadata(this.dziFilesUrl, initialLoad, imgData);
          };

          if (ABS) reader.readAsBinaryString(blob);
          else reader.readAsArrayBuffer(blob);
        });
      }
    );
  };

  setOverlayInstance = (instance, index) => {
    if (index === 0) {
      this.setState({ overlayInstance: instance });
    } else {
      this.setState({ overlayInstanceSplitViewer: instance });
    }
  };

  splitSlide = async slideData => {
    this.setState({ splitSlideData: slideData, selectedViewer: 1 });
    if (this.props.viewerDataArray.length <= 1) {
      this.loadDziData(slideData, false);
    } else {
      Notify({ value: "Cannot add more than 2 slides" });
    }
  };

  handleHomeClick = () => {
    let { OPENSEADRAGONVIEWER, OPENSEADRAGONVIEWER1 } = this.state;

    if (OPENSEADRAGONVIEWER1) {
      OPENSEADRAGONVIEWER1.viewport.setRotation(0);
      OPENSEADRAGONVIEWER1.viewport.goHome();
      openseadragonAnnotations.refresAnnotationData(
        this.props.allAnnotationData
      );
      this.setState({ rotationValue: 0 });
    }

    OPENSEADRAGONVIEWER.viewport.setRotation(0);
    OPENSEADRAGONVIEWER.viewport.goHome();
    openseadragonAnnotations.refresAnnotationData(this.props.allAnnotationData);
    this.setState({ showCustomRotate: false, rotationValue: 0 });
    // this.rotateObject(0);
  };

  rotateImageHandler = val => {
    this.setState({ rotationValue: val });
    if (this.state.OPENSEADRAGONVIEWER1) {
      this.state.OPENSEADRAGONVIEWER1.viewport.setRotation(val);
      this.rotateObjectViewerSplitView(val);
    }

    this.state.OPENSEADRAGONVIEWER.viewport.setRotation(val);
    this.rotateObject(val);
  };

  rotateObject = angle => {
    let canvas = this.state.overlayInstance.fabricCanvas();
    let canvasCenter = new fabric.Point(0, 0); // center of canvas
    if (!this.state.rotationStart && !this.state.orgAngleArray) {
      this.setState({ rotationStart: true });
      let objArray = canvas
        .getObjects()
        .map(data => ({ orgAngle: data.angle }));
      this.setState({ orgAngleArray: objArray });
    }
    canvas.getObjects().forEach((obj, index) => {
      let objectOrigin = new fabric.Point(obj.left, obj.top);
      let orgAngleVal = this.state.orgAngleArray
        ? this.state.orgAngleArray[index]["orgAngle"]
        : obj.angle;
      let radians = fabric.util.degreesToRadians(
        angle - (obj.angle - orgAngleVal)
      );
      let newAngle = orgAngleVal + angle;
      let angleValue = newAngle > 360 ? newAngle - 360 : newAngle;
      let new_loc = fabric.util.rotatePoint(
        objectOrigin,
        canvasCenter,
        radians
      );
      obj.top = new_loc.y;
      obj.left = new_loc.x;
      obj.angle = angleValue;
      obj.setCoords(); //rotate each object by the same angle
      canvas.renderAll();
    });
  };

  handleRotate = () => {
    this.setState({ showCustomRotate: !this.state.showCustomRotate });
  };

  rotateObjectViewerSplitView = angle => {
    let canvasSplitView = this.state.overlayInstanceSplitViewer.fabricCanvas();
    let canvasCenterSplitView = new fabric.Point(0, 0); // center of canvas
    if (!this.state.orgAngleArraySplitView) {
      let objArraySplitView = canvasSplitView
        .getObjects()
        .map(data => ({ orgAngle: data.angle }));
      this.setState({ orgAngleArraySplitView: objArraySplitView });
    }

    canvasSplitView.getObjects().forEach((obj, index) => {
      let objectOriginSplitView = new fabric.Point(obj.left, obj.top);
      let orgAngleValSplitView = this.state.orgAngleArraySplitView
        ? this.state.orgAngleArraySplitView[index]["orgAngle"]
        : obj.angle;
      let radiansSplitView = fabric.util.degreesToRadians(
        angle - (obj.angle - orgAngleValSplitView)
      );
      let newAngleSplitView = orgAngleValSplitView + angle;
      let angleValue2 =
        newAngleSplitView > 360 ? newAngleSplitView - 360 : newAngleSplitView;
      let new_loc2 = fabric.util.rotatePoint(
        objectOriginSplitView,
        canvasCenterSplitView,
        radiansSplitView
      );
      obj.top = new_loc2.y;
      obj.left = new_loc2.x;
      obj.angle = angleValue2;
      obj.setCoords(); //rotate each object by the same angle
      canvasSplitView.renderAll();
    });
  };

  render() {
    if (!this.state.isDataLoaded) {
      return null;
    }
    const { zoom } = this.props;
    const {
      imageData,
      imageOtherSlideData,
      navigatorShow,
      loadFrom,
      fromCaseStream,
      postIsMine,
      slideOwnerOrCollaborator
    } = this.state;
    const dziTileData = this.tileSourceFromData(this.dziData, this.dziFilesUrl);
    const config = {
      basename: "example/image",
      getImageURL: dziTileData,
      thumbnail: dziTileData,
      osdConfig: {
        setStrings: [{ name: "Tooltips.Home", value: "Reset" }],
        defaultZoomLevel: zoom ? zoom : 0,
        tileSources: dziTileData,
        navigatorPosition: "ABSOLUTE",
        navigatorTop: "50px",
        navigatorLeft: "0px",
        navigatorHeight: "120px",
        navigatorWidth: "145px",
        visibilityRatio: 1.0,
        constrainDuringPan: true,
        sequenceMode: false,
        showReferenceStrip: false,
        showNavigator: true,
        navigatorAutoFade: false,
        zoomInButton:
          typeof this.props.viewerId === "undefined"
            ? "zoom-in"
            : `zoom-in-${this.props.viewerId}`,
        zoomOutButton:
          typeof this.props.viewerId === "undefined"
            ? "zoom-out"
            : `zoom-out-${this.props.viewerId}`,
        homeButton: "home",
        fullPageButton: "full-page",
        rotate: "rotate",
        crossOriginPolicy: "Anonymous",
        canvasDrag: false,
        slideId:
          typeof imageData !== "undefined"
            ? imageData.slide_box_id
            : this.props.dziData
            ? this.props.dziData.slide_box_id
            : null
        // toolbar: "viewer-toolbar-container"
      },
      imageMetadata: {
        ...this.metadata,
        "aperio.Filename":
          typeof imageData !== "undefined" ? imageData.file_name : ""
      },
      pages: [
        {
          id: 0,
          title: "MLK",
          sidebarThumbnail: dziTileData,
          transcript: "MLK",
          viewer: "OSD_VIEWER",
          cdmCollection: "mpls",
          cdmIdentifier: "3188",
          infoURL: dziTileData
        }
      ]
    };

    return (
      <Viewer
        {...config}
        {...this.props}
        mainImage={this.value}
        slideThumbData={imageOtherSlideData}
        changeSlide={this.changeSlideImage}
        toggleThumbnailSlider={this.toggleThumbnailSlider}
        thumbnailSliderShow={this.state.thumbnailSliderShow}
        defultKeyFolder={imageData ? imageData.key_folder : null}
        navigatorShow={navigatorShow}
        toggleNavigator={this.toggleNavigator}
        loadFrom={loadFrom}
        showCollapsibleSidebar={this.props.showCollapsibleSidebar}
        fromCaseStream={fromCaseStream}
        postIsMine={postIsMine}
        slideOwnerOrCollaborator={slideOwnerOrCollaborator}
        splitSlide={this.splitSlide}
        viewerDataArray={this.props.viewerDataArray}
        setViewerInstance={this.setViewerInstance}
        viewer1={this.state.OPENSEADRAGONVIEWER}
        splitViewer={this.state.OPENSEADRAGONVIEWER1}
        setOverlayInstance={this.setOverlayInstance}
        selectedViewer={this.state.selectedViewer}
        viewerOnClick={this.viewerOnClick}
        removeSplitSlide={this.removeSplitSlide}
        overlayInstance={this.state.overlayInstance}
        osdButtons={
          this.state.currentButtonInstance
            ? this.state.currentButtonInstance
            : this.state.OPENSEADRAGONBUTTON
        }
        setButtonInstance={this.setButtonInstance}
        currentViewerInstance={this.state.currentViewerInstance}
        imageViewer={this.state.OPENSEADRAGONVIEWER}
        currentOverlayInstance={this.state.currentOverlayInstance}
        rotateImageHandler={this.rotateImageHandler}
        rotationValue={this.state.rotationValue}
        showCustomRotate={this.state.showCustomRotate}
        handleRotate={this.handleRotate}
        handleHomeClick={this.handleHomeClick}
      />
    );
  }
}

//selectedBoard

const mapStateToProps = state => ({
  selectedGroup: state.TumorBoards.selectedBoard,
  selectedUploadFolder: state.SlideBox.selectedUploadFolder,
  viewerDataArray: state.Viewer.viewerDataArray,
  allAnnotationData: state.Viewer.annotationData
});

const mapDispatchToProps = dispatch => ({
  setViewerData: payload => dispatch(setViewerDataArray(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer);
