const { SmallImageConversionUrl } = process.env;

const MedicalTags = [
  { value: "Crosscope Uploads ( V2 )", label: "Crosscope Uploads ( V2 )" },
  { value: "Bone & Soft Tissue", label: "Bone & Soft Tissue" },
  { value: "Rheumatological Disease", label: "Rheumatological Disease" },
  { value: "Infectious Disease", label: "Infectious Disease" },
  { value: "Neoplastic Disease", label: "Neoplastic Disease" },
  { value: "Breast", label: "Breast" },
  { value: "Neoplastic Disease", label: "Neoplastic Disease" },
  { value: "Benign Disease", label: "Benign Disease" },
  { value: "Cardiovascular", label: "Cardiovascular" },
  { value: "Arteritis", label: "Arteritis" },
  { value: "Aortic Disease", label: "Aortic Disease" },
  { value: "Cardiomyopathy", label: "Cardiomyopathy" },
  { value: "Coronary Artery Disease", label: "Coronary Artery Disease" },
  { value: "Fibrosis", label: "Fibrosis" },
  { value: "Metabolic Diseases", label: "Metabolic Diseases" },
  { value: "Myocardial Infarction", label: "Myocardial Infarction" },
  { value: "Myocardial Hypertrophy", label: "Myocardial Hypertrophy" },
  { value: "Myocarditis", label: "Myocarditis" },
  { value: "Pericardial Disease", label: "Pericardial Disease" },
  { value: "Neoplasia", label: "Neoplasia" },
  { value: "Valvular Disease", label: "Valvular Disease" },
  {
    value: "Hypertensive Vascular Disease",
    label: "Hypertensive Vascular Disease"
  },
  { value: "Developmental Anomalies", label: "Developmental Anomalies" },
  { value: "Dermatopathology", label: "Dermatopathology" },
  { value: "Dermatological Disease", label: "Dermatological Disease" },
  { value: "Endocrine", label: "Endocrine" },
  { value: "Adrenal Gland", label: "Adrenal Gland" },
  { value: "Thyroid Gland", label: "Thyroid Gland" },
  { value: "Gastrointestinal", label: "Gastrointestinal" },
  { value: "Esophagus", label: "Esophagus" },
  { value: "Stomach", label: "Stomach" },
  { value: "Colon", label: "Colon" },
  { value: "Inflammatory Bowel Disease", label: "Inflammatory Bowel Disease" },
  { value: "Infections", label: "Infections" },
  { value: "Appendix", label: "Appendix" },
  { value: "Anorectal", label: "Anorectal" },
  { value: "Pancreas", label: "Pancreas" },
  { value: "Hepatobiliary", label: "Hepatobiliary" },
  { value: "Infectious Diseases", label: "Infectious Diseases" },
  { value: "Vascular Disorders", label: "Vascular Disorders" },
  {
    value: "Toxic And Metabolic Disorders",
    label: "Toxic And Metabolic Disorders"
  },
  { value: "Non - Neoplastic Disorders", label: "Non - Neoplastic Disorders" },
  { value: "Neoplastic Disorders", label: "Neoplastic Disorders" },
  { value: "Head And Neck", label: "Head And Neck" },
  {
    value: "Nasal Cavity Nasopharynx And Sinuses",
    label: "Nasal Cavity Nasopharynx And Sinuses"
  },
  {
    value: "Oral Cavity Tonsils And Neck",
    label: "Oral Cavity Tonsils And Neck"
  },
  { value: "Larynx And Hypolarynx", label: "Larynx And Hypolarynx" },
  {
    value: "Major And Minor Salivary Glands",
    label: "Major And Minor Salivary Glands"
  },
  { value: "Diseases Of Ear", label: "Diseases Of Ear" },
  { value: "Hematopathology", label: "Hematopathology" },
  { value: "Red Blood Cells", label: "Red Blood Cells" },
  { value: "White Blood Cells", label: "White Blood Cells" },
  { value: "Normal Tissue", label: "Normal Tissue" },
  { value: "B - Cell Malignancy", label: "B - Cell Malignancy" },
  { value: "Histocytic Lesion", label: "Histocytic Lesion" },
  { value: "Neuropathology", label: "Neuropathology" },
  { value: "Infectious Diseases", label: "Infectious Diseases" },
  { value: "Hemorrhagic Disorders", label: "Hemorrhagic Disorders" },
  { value: "Neoplasm", label: "Neoplasm" },
  { value: "Demyelinating Diseases", label: "Demyelinating Diseases" },
  { value: "Vascular Diseases", label: "Vascular Diseases" },
  { value: "Pulmonary", label: "Pulmonary" },
  { value: "Asthma", label: "Asthma" },
  { value: "Obstructive Lung Disease", label: "Obstructive Lung Disease" },
  { value: "Bronchial Disease", label: "Bronchial Disease" },
  { value: "Infectious Diseases", label: "Infectious Diseases" },
  { value: "Interstitial Lung Disease", label: "Interstitial Lung Disease" },
  { value: "Neoplasia", label: "Neoplasia" },
  { value: "Pneumonia", label: "Pneumonia" },
  { value: "Vascular Disease", label: "Vascular Disease" },
  { value: "Congenital Anomalies", label: "Congenital Anomalies" },
  { value: "Pediatric Disease", label: "Pediatric Disease" },
  { value: "Aspiration", label: "Aspiration" },
  { value: "Miscellaneous", label: "Miscellaneous" },
  { value: "Kidney & Urinary Tract", label: "Kidney & Urinary Tract" },
  { value: "Cystic Diseases", label: "Cystic Diseases" },
  { value: "Glomerular Diseases", label: "Glomerular Diseases" },
  { value: "Infectious Diseases", label: "Infectious Diseases" },
  { value: "Neoplasia", label: "Neoplasia" },
  { value: "Tubular Diseases", label: "Tubular Diseases" },
  { value: "Vascular Disease", label: "Vascular Disease" },
  {
    value: "Fetal & Congenital Anomalies",
    label: "Fetal & Congenital Anomalies"
  },
  { value: "Urinary Bladder", label: "Urinary Bladder" },
  { value: "Female Reproductive", label: "Female Reproductive" },
  { value: "Cervix", label: "Cervix" },
  { value: "Fallopian Tube", label: "Fallopian Tube" },
  { value: "Lymph Node", label: "Lymph Node" },
  { value: "Ovary", label: "Ovary" },
  { value: "Uterine Fundus", label: "Uterine Fundus" },
  { value: "Vulva", label: "Vulva" },
  { value: "Vagina", label: "Vagina" },
  { value: "Peritoneum", label: "Peritoneum" },
  { value: "Male Reproductive", label: "Male Reproductive" },
  { value: "Lymph - Related Organs", label: "Lymph - Related Organs" },
  { value: "Spleen", label: "Spleen" },
  { value: "Thymus", label: "Thymus" },
  { value: "Lymph Nodes", label: "Lymph Nodes" },
  { value: "Syndromes", label: "Syndromes" },
  { value: "Cowden Sydrome", label: "Cowden Sydrome" },
  { value: "Gout ( Tophus )", label: "Gout ( Tophus )" },
  { value: "Acute Osteomyelitis", label: "Acute Osteomyelitis" },
  { value: "Healed Osteomyelitis", label: "Healed Osteomyelitis" },
  { value: "Aveolar Soft Parts Sarcoma", label: "Aveolar Soft Parts Sarcoma" },
  { value: "Chordoma", label: "Chordoma" },
  {
    value: "Giant Cell Tumor Of Tendon Sheath",
    label: "Giant Cell Tumor Of Tendon Sheath"
  },
  { value: "Myxoid Liposarcoma", label: "Myxoid Liposarcoma" },
  {
    value: "Osteogenesis Imperfecta Congenita Type Ii ( 1 2 3 )",
    label: "Osteogenesis Imperfecta Congenita Type Ii ( 1 2 3 )"
  },
  { value: "Paget Disease", label: "Paget Disease" },
  { value: "Angiosarcoma", label: "Angiosarcoma" },
  { value: "Adenoid Cystic Carcinoma", label: "Adenoid Cystic Carcinoma" },
  { value: "Tubular Carcinoma", label: "Tubular Carcinoma" },
  {
    value: "Lobular Carcinoma ( Invasive )",
    label: "Lobular Carcinoma ( Invasive )"
  },
  {
    value: "Lobular Carcinoma ( In Situ )",
    label: "Lobular Carcinoma ( In Situ )"
  },
  { value: "Lactating Adenoma", label: "Lactating Adenoma" },
  {
    value: "Paget Disease Of The Nipple",
    label: "Paget Disease Of The Nipple"
  },
  { value: "Lymph Node Metastasis", label: "Lymph Node Metastasis" },
  {
    value: "Ductal Carcinoma In Situ ( Dcis )",
    label: "Ductal Carcinoma In Situ ( Dcis )"
  },
  {
    value: "Ductal Carcinoma In Situ ( Dcis ) Comedocarcinoma",
    label: "Ductal Carcinoma In Situ ( Dcis ) Comedocarcinoma"
  },
  { value: "Fibroadenoma", label: "Fibroadenoma" },
  { value: "Phyllodes Tumor", label: "Phyllodes Tumor" },
  {
    value: "Invasive ( Infiltrating ) Ductal Carcinoma",
    label: "Invasive ( Infiltrating ) Ductal Carcinoma"
  },
  { value: "Sclerosing Adenosis", label: "Sclerosing Adenosis" },
  {
    value: "Medullary Carcinoma ( 1 2 )",
    label: "Medullary Carcinoma ( 1 2 )"
  },
  { value: "Intraductal Papilloma", label: "Intraductal Papilloma" },
  {
    value: "Metaplastic Carcinoma ( Carcinosarcoma ) ( 1 2 3 )",
    label: "Metaplastic Carcinoma ( Carcinosarcoma ) ( 1 2 3 )"
  },
  { value: "Papillary Carcinoma", label: "Papillary Carcinoma" },
  {
    value: "Xanthogranulomatous Mastitis",
    label: "Xanthogranulomatous Mastitis"
  },
  { value: "Kawasaki Disease", label: "Kawasaki Disease" },
  {
    value: "Polyarteritis Nodosa ( 1 2 )",
    label: "Polyarteritis Nodosa ( 1 2 )"
  },
  { value: "Takayasu ( 1 2 3 )", label: "Takayasu ( 1 2 3 )" },
  { value: "Abdominal Aneurysm", label: "Abdominal Aneurysm" },
  {
    value: "Aortic Dissection ( Gross )",
    label: "Aortic Dissection ( Gross )"
  },
  {
    value: "Aortic Dissection ( Microscopic )",
    label: "Aortic Dissection ( Microscopic )"
  },
  {
    value: "Aortic Dissection ( External Rupture )",
    label: "Aortic Dissection ( External Rupture )"
  },
  {
    value: "Aortic Dissection ( Intimal Tear )",
    label: "Aortic Dissection ( Intimal Tear )"
  },
  {
    value: "Aortic Dissection ( Iliac Artery )",
    label: "Aortic Dissection ( Iliac Artery )"
  },
  { value: "Cmd", label: "Cmd" },
  { value: "Atherosclerosis", label: "Atherosclerosis" },
  { value: "Histiocytoid", label: "Histiocytoid" },
  { value: "Hypertrophic", label: "Hypertrophic" },
  { value: "Arrhythmogenic ( 1 2 )", label: "Arrhythmogenic ( 1 2 )" },
  { value: "Amyloidosis ( 1 2 )", label: "Amyloidosis ( 1 2 )" },
  { value: "Sarcoidosis", label: "Sarcoidosis" },
  { value: "Dissection", label: "Dissection" },
  { value: "Fibromuscular Dysplasia", label: "Fibromuscular Dysplasia" },
  {
    value: "Non - Atherosclerotic Occlusion ( Low Mag )",
    label: "Non - Atherosclerotic Occlusion ( Low Mag )"
  },
  {
    value: "Non - Atherosclerotic Occlusion ( High Mag )",
    label: "Non - Atherosclerotic Occlusion ( High Mag )"
  },
  { value: "Atherosclerosis", label: "Atherosclerosis" },
  { value: "Anomalous Origin", label: "Anomalous Origin" },
  { value: "Coronary Artery Anomaly", label: "Coronary Artery Anomaly" },
  {
    value: "Endocardial Fibroelastosis ( Gross )",
    label: "Endocardial Fibroelastosis ( Gross )"
  },
  {
    value: "Endocardial Fibroelastosis ( Microscopic 1 2 )",
    label: "Endocardial Fibroelastosis ( Microscopic 1 2 )"
  },
  {
    value: "Interstitial - Friedreich'S Ataxia",
    label: "Interstitial - Friedreich'S Ataxia"
  },
  { value: "Hurler Syndrome ( 1 2 )", label: "Hurler Syndrome ( 1 2 )" },
  {
    value: "Glycogen Storage Disease ( 1 2 )",
    label: "Glycogen Storage Disease ( 1 2 )"
  },
  {
    value: "Morphological Timing ( Table )",
    label: "Morphological Timing ( Table )"
  },
  { value: "Mi : 4 8 Hrs", label: "Mi : 4 8 Hrs" },
  {
    value: "Mi : 7 - 1 0 Days ( Low Mag )",
    label: "Mi : 7 - 1 0 Days ( Low Mag )"
  },
  {
    value: "Mi : 7 - 1 0 Days ( High Mag )",
    label: "Mi : 7 - 1 0 Days ( High Mag )"
  },
  { value: "3 Days ( Gross )", label: "3 Days ( Gross )" },
  { value: "Contraction Band", label: "Contraction Band" },
  { value: "Myocytolysis", label: "Myocytolysis" },
  { value: "Wavy Myofibers", label: "Wavy Myofibers" },
  { value: "Microscopic 1", label: "Microscopic 1" },
  { value: "Microscopic 2", label: "Microscopic 2" },
  { value: "Aids", label: "Aids" },
  { value: "Chaga'S Disease", label: "Chaga'S Disease" },
  { value: "Cytomegalovirus", label: "Cytomegalovirus" },
  { value: "Lymphocytic ( Low Mag )", label: "Lymphocytic ( Low Mag )" },
  { value: "Lymphocytic ( High Mag )", label: "Lymphocytic ( High Mag )" },
  {
    value: "Rocky Mountain Spotted Fever",
    label: "Rocky Mountain Spotted Fever"
  },
  {
    value: "Fibrinous Pericarditis ( 1 2 )",
    label: "Fibrinous Pericarditis ( 1 2 )"
  },
  { value: "Lipid Tamponade", label: "Lipid Tamponade" },
  { value: "Pneumopericardium", label: "Pneumopericardium" },
  { value: "Fibroma", label: "Fibroma" },
  {
    value: "Lipomatous Hypertrophy ( Gross )",
    label: "Lipomatous Hypertrophy ( Gross )"
  },
  {
    value: "Lipomatous Hypertrophy ( Micro )",
    label: "Lipomatous Hypertrophy ( Micro )"
  },
  { value: "Metastatic Melanoma", label: "Metastatic Melanoma" },
  { value: "Myxoma ( Low Mag )", label: "Myxoma ( Low Mag )" },
  { value: "Myxoma ( High Mag )", label: "Myxoma ( High Mag )" },
  { value: "Rhabdomyoma", label: "Rhabdomyoma" },
  { value: "Papillary Fibroelastoma", label: "Papillary Fibroelastoma" },
  {
    value: "Acute Bacterial Endocarditis",
    label: "Acute Bacterial Endocarditis"
  },
  {
    value: "Blood Cyst ( Dissecting Microscope )",
    label: "Blood Cyst ( Dissecting Microscope )"
  },
  { value: "Blood Cyst ( Micro )", label: "Blood Cyst ( Micro )" },
  { value: "Fungal Endocarditis", label: "Fungal Endocarditis" },
  {
    value: "Mitral Valve : Annular Calcification",
    label: "Mitral Valve : Annular Calcification"
  },
  {
    value: "Mitral Valve Stenosis ( La View )",
    label: "Mitral Valve Stenosis ( La View )"
  },
  {
    value: "Mitral Valve Stenosis ( Gross )",
    label: "Mitral Valve Stenosis ( Gross )"
  },
  { value: "Mitral Valve Prosthesis", label: "Mitral Valve Prosthesis" },
  { value: "Mitral Valve Prolapse", label: "Mitral Valve Prolapse" },
  {
    value: "Mitral Prolapse ( La View )",
    label: "Mitral Prolapse ( La View )"
  },
  { value: "Valve Ossification", label: "Valve Ossification" },
  { value: "Decidual Vasculopathy", label: "Decidual Vasculopathy" },
  {
    value: "Tetralogy Of Fallot ( 1 2 3 )",
    label: "Tetralogy Of Fallot ( 1 2 3 )"
  },
  {
    value: "Molluscum Contagiosum ( 1 2 )",
    label: "Molluscum Contagiosum ( 1 2 )"
  },
  {
    value: "Glomus Tumor ( Glomangioma )",
    label: "Glomus Tumor ( Glomangioma )"
  },
  {
    value: "Merkel Cell Carcinoma ( Trabecular Carcinoma )",
    label: "Merkel Cell Carcinoma ( Trabecular Carcinoma )"
  },
  { value: "Kapsosi Sarcoma ( 1 2 )", label: "Kapsosi Sarcoma ( 1 2 )" },
  { value: "Hidradenoma Papilliferum", label: "Hidradenoma Papilliferum" },
  { value: "Sclerosing Lipogranuloma", label: "Sclerosing Lipogranuloma" },
  { value: "1 5 Week Fetus", label: "1 5 Week Fetus" },
  { value: "2 3 Week Fetus ( 1 2 )", label: "2 3 Week Fetus ( 1 2 )" },
  { value: "Normal Child ( 1 2 )", label: "Normal Child ( 1 2 )" },
  { value: "Adrenocortical Tumor", label: "Adrenocortical Tumor" },
  { value: "Cortical Adrenal Adenoma", label: "Cortical Adrenal Adenoma" },
  {
    value: "Fetal Cortex Lipid Retention ( 1 2 )",
    label: "Fetal Cortex Lipid Retention ( 1 2 )"
  },
  {
    value: "Adrenocortical Carcinoma ( 1 2 )",
    label: "Adrenocortical Carcinoma ( 1 2 )"
  },
  { value: "Pheochromocytoma", label: "Pheochromocytoma" },
  { value: "Graves Disease", label: "Graves Disease" },
  { value: "Hashimoto Disease ( 1 2 )", label: "Hashimoto Disease ( 1 2 )" },
  { value: "Amyloid Goiter", label: "Amyloid Goiter" },
  {
    value: "Non - Toxic Multinodular Goiter ( 1 2 )",
    label: "Non - Toxic Multinodular Goiter ( 1 2 )"
  },
  { value: "Eosinophilic Esophagitis", label: "Eosinophilic Esophagitis" },
  { value: "Candida Esophagitis", label: "Candida Esophagitis" },
  {
    value: "Chronic Gastritis ( H . Pylori )",
    label: "Chronic Gastritis ( H . Pylori )"
  },
  { value: "Helicobacter Heilmannii", label: "Helicobacter Heilmannii" },
  { value: "Intestinal Metaplasia", label: "Intestinal Metaplasia" },
  { value: "Gastric Adenocarcinoma", label: "Gastric Adenocarcinoma" },
  {
    value: "Gastrointestinal Stromal Tumor ( Gist ) ( 1 2 )",
    label: "Gastrointestinal Stromal Tumor ( Gist ) ( 1 2 )"
  },
  { value: "Collagenous Colitis", label: "Collagenous Colitis" },
  { value: "Mucinous Adenocarcinoma", label: "Mucinous Adenocarcinoma" },
  { value: "Chronic Ulcerative Colitis", label: "Chronic Ulcerative Colitis" },
  {
    value: "Inflammatory Bowel Disease ( Indeterminate Type )",
    label: "Inflammatory Bowel Disease ( Indeterminate Type )"
  },
  {
    value: "Cryptosporidiosis ( Gallbladder )",
    label: "Cryptosporidiosis ( Gallbladder )"
  },
  { value: "Pseudomembranous Colitis", label: "Pseudomembranous Colitis" },
  {
    value: "Cytomegalovirus ( Stomach )",
    label: "Cytomegalovirus ( Stomach )"
  },
  { value: "Schistosomiasis ( 1 2 )", label: "Schistosomiasis ( 1 2 )" },
  {
    value: "Enterobius Vermicularis ( Appendix )",
    label: "Enterobius Vermicularis ( Appendix )"
  },
  { value: "Giardiasis ( Duodenum )", label: "Giardiasis ( Duodenum )" },
  { value: "Strongyloidiasis ( 1 2 )", label: "Strongyloidiasis ( 1 2 )" },
  { value: "Spirochetosis ( 1 2 )", label: "Spirochetosis ( 1 2 )" },
  {
    value: "Mucinous Cystadenocarcinoma",
    label: "Mucinous Cystadenocarcinoma"
  },
  { value: "Acute Appendicitis", label: "Acute Appendicitis" },
  { value: "Appendiceal Carcinoid", label: "Appendiceal Carcinoid" },
  {
    value: "Anal Transition Zone Carcinoma",
    label: "Anal Transition Zone Carcinoma"
  },
  { value: "Melanoma", label: "Melanoma" },
  { value: "Adenocarcinoma", label: "Adenocarcinoma" },
  { value: "Dysplasia", label: "Dysplasia" },
  { value: "Miliary Tuberculosis", label: "Miliary Tuberculosis" },
  {
    value: "Miliary Tuberculosis ( Higher Mag )",
    label: "Miliary Tuberculosis ( Higher Mag )"
  },
  { value: "Hepatitis B", label: "Hepatitis B" },
  { value: "Chronic Hepatitis C", label: "Chronic Hepatitis C" },
  {
    value: "Passive Congestion With Centrolobular Necrosis ( 1 2 )",
    label: "Passive Congestion With Centrolobular Necrosis ( 1 2 )"
  },
  { value: "Cardiac Sclerosis", label: "Cardiac Sclerosis" },
  {
    value: "Non - Alcoholic Steatohepatitis ( Nash )",
    label: "Non - Alcoholic Steatohepatitis ( Nash )"
  },
  {
    value: "Acute Alcoholic Hepatitis With Mallory Bodies",
    label: "Acute Alcoholic Hepatitis With Mallory Bodies"
  },
  {
    value: "Alpha - 1 Anti - Trypsin Deficiency ( 1 2 )",
    label: "Alpha - 1 Anti - Trypsin Deficiency ( 1 2 )"
  },
  {
    value: "Glycogen Storage Disease Type I",
    label: "Glycogen Storage Disease Type I"
  },
  { value: "Gaucher Disease", label: "Gaucher Disease" },
  { value: "Acute Cholecystitis", label: "Acute Cholecystitis" },
  {
    value: "Chronic Cholecystitis ( Rokitansky - Aschoff Sinus )",
    label: "Chronic Cholecystitis ( Rokitansky - Aschoff Sinus )"
  },
  { value: "Feathery Degeneration", label: "Feathery Degeneration" },
  { value: "Bile Infarct", label: "Bile Infarct" },
  {
    value: "Secondary Biliary Cirrhosis",
    label: "Secondary Biliary Cirrhosis"
  },
  { value: "Cholangiocarcinoma", label: "Cholangiocarcinoma" },
  { value: "Hepatocellular Carcinoma", label: "Hepatocellular Carcinoma" },
  { value: "Non - Neoplastic Lesions", label: "Non - Neoplastic Lesions" },
  { value: "Benign Neoplasm", label: "Benign Neoplasm" },
  { value: "Malignant Neoplasm", label: "Malignant Neoplasm" },
  { value: "Non - Neoplastic Lesions", label: "Non - Neoplastic Lesions" },
  { value: "Benign Neoplasm", label: "Benign Neoplasm" },
  { value: "Malignant Neoplasm", label: "Malignant Neoplasm" },
  { value: "Non - Neoplastic Lesions", label: "Non - Neoplastic Lesions" },
  { value: "Benign Neoplasm", label: "Benign Neoplasm" },
  { value: "Malignant Neoplasm", label: "Malignant Neoplasm" },
  { value: "Non - Neoplastic Lesions", label: "Non - Neoplastic Lesions" },
  { value: "Benign Neoplasm", label: "Benign Neoplasm" },
  { value: "Malignant Neoplasm", label: "Malignant Neoplasm" },
  { value: "Non - Neoplastic Lesions", label: "Non - Neoplastic Lesions" },
  { value: "Howell - Jolly Bodies", label: "Howell - Jolly Bodies" },
  { value: "Schistocytes", label: "Schistocytes" },
  { value: "Helmet Cells", label: "Helmet Cells" },
  { value: "Blister Cells", label: "Blister Cells" },
  { value: "Spherocytes", label: "Spherocytes" },
  {
    value: "Dacrocytes ( Tear Drop Cells )",
    label: "Dacrocytes ( Tear Drop Cells )"
  },
  { value: "Codocytes ( Target Cells )", label: "Codocytes ( Target Cells )" },
  { value: "Nucleated Red Blood Cells", label: "Nucleated Red Blood Cells" },
  { value: "Polychromatophilia", label: "Polychromatophilia" },
  { value: "Stomatocyte", label: "Stomatocyte" },
  { value: "Elliptocytes", label: "Elliptocytes" },
  { value: "Sickle Cell Disease", label: "Sickle Cell Disease" },
  {
    value: "Alpha - Thalassemia ( Hemoglobin H Disease )",
    label: "Alpha - Thalassemia ( Hemoglobin H Disease )"
  },
  {
    value: "Alpha - Thalassemia ( Hemoglobin Bart ’ S )",
    label: "Alpha - Thalassemia ( Hemoglobin Bart ’ S )"
  },
  { value: "Pelger - Huet Anomaly", label: "Pelger - Huet Anomaly" },
  {
    value: "Transient Abnormal Myelopoesis : Down Syndrome",
    label: "Transient Abnormal Myelopoesis : Down Syndrome"
  },
  { value: "Embryonal Red Blood Cells", label: "Embryonal Red Blood Cells" },
  { value: "Hematopoiesis In Yolk Sac", label: "Hematopoiesis In Yolk Sac" },
  {
    value: "Hematopoiesis In Liver ( 1 2 3 )",
    label: "Hematopoiesis In Liver ( 1 2 3 )"
  },
  {
    value: "Hematopoiesis In Bone Marrow",
    label: "Hematopoiesis In Bone Marrow"
  },
  {
    value: "Hematopoiesis In Other Fetal Tissue",
    label: "Hematopoiesis In Other Fetal Tissue"
  },
  { value: "Hairy Cell Leukemia", label: "Hairy Cell Leukemia" },
  {
    value: "Langerhans Cell Histocytosis",
    label: "Langerhans Cell Histocytosis"
  },
  { value: "Cryptococcus ( 1 2 )", label: "Cryptococcus ( 1 2 )" },
  { value: "Toxoplasmosis ( 1 2 )", label: "Toxoplasmosis ( 1 2 )" },
  { value: "Bacterial Meningitis", label: "Bacterial Meningitis" },
  {
    value: "Bacterial Meningitis Pneumococcal ( 1 2 )",
    label: "Bacterial Meningitis Pneumococcal ( 1 2 )"
  },
  {
    value: "Cerebral Hemorrhage ( 1 2 )",
    label: "Cerebral Hemorrhage ( 1 2 )"
  },
  {
    value: "Cerebral Hemorrhage In Sickle Cell Disease",
    label: "Cerebral Hemorrhage In Sickle Cell Disease"
  },
  { value: "Acute Subdural Hemorrhage", label: "Acute Subdural Hemorrhage" },
  { value: "Germinoma ( 1 2 3 )", label: "Germinoma ( 1 2 3 )" },
  {
    value: "Glioblastoma Multiforme ( 1 2 )",
    label: "Glioblastoma Multiforme ( 1 2 )"
  },
  { value: "Meningioma", label: "Meningioma" },
  {
    value:
      "Atypical Teratoid / Rhabdoid Tumor ( Atrt ) Of Infancy And Childhood",
    label:
      "Atypical Teratoid / Rhabdoid Tumor ( Atrt ) Of Infancy And Childhood"
  },
  {
    value: "Acute Demyelinating Disease ( 1 2 3 )",
    label: "Acute Demyelinating Disease ( 1 2 3 )"
  },
  {
    value: "Amyotropic Lateral Sclerosis ( Lou Gehrig Disease )",
    label: "Amyotropic Lateral Sclerosis ( Lou Gehrig Disease )"
  },
  {
    value: "Cerebral Arteriopathy ( 1 2 )",
    label: "Cerebral Arteriopathy ( 1 2 )"
  },
  {
    value: "Hemorrhage Due To Cerebral Amyloid Angiopathy",
    label: "Hemorrhage Due To Cerebral Amyloid Angiopathy"
  },
  { value: "Asthma ( 1 2 3 )", label: "Asthma ( 1 2 3 )" },
  { value: "Charcot - Leyden Crystals", label: "Charcot - Leyden Crystals" },
  { value: "Emphysema ( 1 2 )", label: "Emphysema ( 1 2 )" },
  { value: "Bullous Emphysema", label: "Bullous Emphysema" },
  { value: "Bronchiectasis ( 1 2 )", label: "Bronchiectasis ( 1 2 )" },
  { value: "Cryptococcosis ( 1 2 3 )", label: "Cryptococcosis ( 1 2 3 )" },
  { value: "Aspergillosis ( 1 2 3 )", label: "Aspergillosis ( 1 2 3 )" },
  { value: "Aspergilloma", label: "Aspergilloma" },
  { value: "Aspergillus Species", label: "Aspergillus Species" },
  { value: "Schistosomiasis ( 1 2 )", label: "Schistosomiasis ( 1 2 )" },
  {
    value: "Rocky Mountain Spotted Fever",
    label: "Rocky Mountain Spotted Fever"
  },
  { value: "Blastomycosis ( 1 2 )", label: "Blastomycosis ( 1 2 )" },
  { value: "Actinomycosis", label: "Actinomycosis" },
  { value: "Nocardiosis", label: "Nocardiosis" },
  { value: "Candidiasis", label: "Candidiasis" },
  { value: "Histoplasmosis", label: "Histoplasmosis" },
  { value: "Sporotrichosis", label: "Sporotrichosis" },
  {
    value: "Hypersensitivity Pneumonitis",
    label: "Hypersensitivity Pneumonitis"
  },
  { value: "Toxoplasmosis", label: "Toxoplasmosis" },
  {
    value: "Measles ( Rubeola ) Pneumonia",
    label: "Measles ( Rubeola ) Pneumonia"
  },
  { value: "Honeycomb Lung", label: "Honeycomb Lung" },
  {
    value: "Nonspecific Interstitial Lung Disease ( Nsip )",
    label: "Nonspecific Interstitial Lung Disease ( Nsip )"
  },
  {
    value: "Cryptogenic Organizing Pneumonia",
    label: "Cryptogenic Organizing Pneumonia"
  },
  {
    value: "Desquamative Interstitial Pneumonia ( Dip )",
    label: "Desquamative Interstitial Pneumonia ( Dip )"
  },
  {
    value: "Usual Interstitial Pneumonia ( Uip ) ( 1 2 3 )",
    label: "Usual Interstitial Pneumonia ( Uip ) ( 1 2 3 )"
  },
  {
    value: "Rheumatoid Arthritis ( 1 2 )",
    label: "Rheumatoid Arthritis ( 1 2 )"
  },
  { value: "Sarcoidosis", label: "Sarcoidosis" },
  { value: "Goodpasture Syndrome", label: "Goodpasture Syndrome" },
  { value: "Adenocarcinoma", label: "Adenocarcinoma" },
  { value: "Bronchiolar Carcinoma", label: "Bronchiolar Carcinoma" },
  {
    value: "Bronchiolar Carcinoma ( Lymphatic Spread )",
    label: "Bronchiolar Carcinoma ( Lymphatic Spread )"
  },
  { value: "Carcinoid Tumor", label: "Carcinoid Tumor" },
  { value: "Mesothelioma ( 1 2 )", label: "Mesothelioma ( 1 2 )" },
  {
    value: "Squamous Cell Carcinoma ( Cytology )",
    label: "Squamous Cell Carcinoma ( Cytology )"
  },
  {
    value: "Bronchogenic Carcinoma With Lung Abscess",
    label: "Bronchogenic Carcinoma With Lung Abscess"
  },
  {
    value: "Endolymphatic Stromal Myosis ( From Uterus )",
    label: "Endolymphatic Stromal Myosis ( From Uterus )"
  },
  { value: "Basaloid Carcinoma", label: "Basaloid Carcinoma" },
  { value: "Incomplete Resolution", label: "Incomplete Resolution" },
  { value: "Klebsiella Pneumoniae", label: "Klebsiella Pneumoniae" },
  {
    value: "Pneumocystis Pneumonia ( 1 2 )",
    label: "Pneumocystis Pneumonia ( 1 2 )"
  },
  {
    value: "Adenoviral Pneumonia ( 1 2 )",
    label: "Adenoviral Pneumonia ( 1 2 )"
  },
  { value: "Cytomegalovirus", label: "Cytomegalovirus" },
  { value: "Bronchopneumonia ( Gross )", label: "Bronchopneumonia ( Gross )" },
  {
    value: "Bronchopneumonia ( Microscopic )",
    label: "Bronchopneumonia ( Microscopic )"
  },
  { value: "Plexiform Lesion ( 1 2 3 )", label: "Plexiform Lesion ( 1 2 3 )" },
  {
    value: "Hemorrhagic Infarct ( 1 2 )",
    label: "Hemorrhagic Infarct ( 1 2 )"
  },
  { value: "Thromboembolus", label: "Thromboembolus" },
  { value: "Amniotic Fluid Embolism", label: "Amniotic Fluid Embolism" },
  { value: "Tumor Embolism", label: "Tumor Embolism" },
  {
    value: "Idiopathic Pulmonary Hemosiderosis",
    label: "Idiopathic Pulmonary Hemosiderosis"
  },
  { value: "Arteriole In Septum", label: "Arteriole In Septum" },
  {
    value: "Wegener Granulomatosis ( 1 2 3 4 )",
    label: "Wegener Granulomatosis ( 1 2 3 4 )"
  },
  {
    value: "Congentital Cystic Adenomatoid Malformation ( 1 2 )",
    label: "Congentital Cystic Adenomatoid Malformation ( 1 2 )"
  },
  {
    value: "Pulmonary Hypoplasia ( 1 2 )",
    label: "Pulmonary Hypoplasia ( 1 2 )"
  },
  {
    value: "Congenital Pulmonary Lymphangiectasis",
    label: "Congenital Pulmonary Lymphangiectasis"
  },
  {
    value: "Bronchial Pattern In Situs Inversus",
    label: "Bronchial Pattern In Situs Inversus"
  },
  {
    value: "Bronchopulmonary Disease ( 1 2 )",
    label: "Bronchopulmonary Disease ( 1 2 )"
  },
  {
    value: "Hyaline Membrane Disease ( 1 2 )",
    label: "Hyaline Membrane Disease ( 1 2 )"
  },
  {
    value: "Pulmonary Interstitial Emphysema",
    label: "Pulmonary Interstitial Emphysema"
  },
  {
    value: "Pulmonary Intersitial Emphysema With Hemorrhage",
    label: "Pulmonary Intersitial Emphysema With Hemorrhage"
  },
  {
    value: "Chronic Granulomatous Disease ( Cgd )",
    label: "Chronic Granulomatous Disease ( Cgd )"
  },
  { value: "Aspiration Pneumonia", label: "Aspiration Pneumonia" },
  {
    value: "Aspiration Pneumonia With Lung Abscess",
    label: "Aspiration Pneumonia With Lung Abscess"
  },
  {
    value: "Peripartum Aspiration Of Maternal Blood",
    label: "Peripartum Aspiration Of Maternal Blood"
  },
  { value: "Meconium Aspiration", label: "Meconium Aspiration" },
  { value: "Lymphangioleiomyomatosis", label: "Lymphangioleiomyomatosis" },
  { value: "Foreign Body Giant Cell", label: "Foreign Body Giant Cell" },
  { value: "Hamartoma", label: "Hamartoma" },
  { value: "Foreign Body", label: "Foreign Body" },
  {
    value: "Eosinophilic Granuloma ( 1 2 3 4 )",
    label: "Eosinophilic Granuloma ( 1 2 3 4 )"
  },
  {
    value: "Pulmonary Alveolar Proteinosis ( Pap )",
    label: "Pulmonary Alveolar Proteinosis ( Pap )"
  },
  {
    value: "Cystic Dysplastic Kidney ( 1 2 )",
    label: "Cystic Dysplastic Kidney ( 1 2 )"
  },
  { value: "Renal Dysplasia ( 1 2 )", label: "Renal Dysplasia ( 1 2 )" },
  { value: "Glomerular Cysts", label: "Glomerular Cysts" },
  {
    value: "Autosomal Dominant Polycystic Kidney Disease ( 1 2 3 4 )",
    label: "Autosomal Dominant Polycystic Kidney Disease ( 1 2 3 4 )"
  },
  {
    value: "Autosomal Recessive Polycystic Kidney Disease ( 1 2 3 )",
    label: "Autosomal Recessive Polycystic Kidney Disease ( 1 2 3 )"
  },
  { value: "Multicystic Disease", label: "Multicystic Disease" },
  { value: "Prune Belly Syndrome", label: "Prune Belly Syndrome" },
  { value: "Meckel - Gruber Syndrome", label: "Meckel - Gruber Syndrome" },
  { value: "Nodular Glomerulosclerosis", label: "Nodular Glomerulosclerosis" },
  {
    value: "Acute Proliferative Glomerulonephritis ( 1 2 3 4 )",
    label: "Acute Proliferative Glomerulonephritis ( 1 2 3 4 )"
  },
  {
    value: "Crescentic Glomerulonephritis ( 1 2 3 4 )",
    label: "Crescentic Glomerulonephritis ( 1 2 3 4 )"
  },
  {
    value: "Iga Nephropathy - Mesangial Proliferative Glomerulonephritis",
    label: "Iga Nephropathy - Mesangial Proliferative Glomerulonephritis"
  },
  {
    value: "Iga Nephropathy - Focal Segmental Glomerulonephritis",
    label: "Iga Nephropathy - Focal Segmental Glomerulonephritis"
  },
  { value: "Iga Nephropathy ( 1 2 )", label: "Iga Nephropathy ( 1 2 )" },
  { value: "Alport Syndrome", label: "Alport Syndrome" },
  {
    value: "Chronic Sclerosing Glomeruonephritis ( 1 2 )",
    label: "Chronic Sclerosing Glomeruonephritis ( 1 2 )"
  },
  {
    value: "Benign Familial Hematuria ( 1 2 )",
    label: "Benign Familial Hematuria ( 1 2 )"
  },
  {
    value: "Chronic Pyelonephritis ( 1 2 )",
    label: "Chronic Pyelonephritis ( 1 2 )"
  },
  {
    value: "Xanthogranulomatous Pyelonephritis",
    label: "Xanthogranulomatous Pyelonephritis"
  },
  { value: "Polyoma Virus", label: "Polyoma Virus" },
  { value: "Cystic Nephroma", label: "Cystic Nephroma" },
  { value: "Wilms Tumor", label: "Wilms Tumor" },
  { value: "Nephrogenic Rest", label: "Nephrogenic Rest" },
  {
    value: "Denys - Drash Syndrome ( 1 2 )",
    label: "Denys - Drash Syndrome ( 1 2 )"
  },
  { value: "Renal Cell Carcinoma", label: "Renal Cell Carcinoma" },
  {
    value: "Mucinous Tubrlar And Spindle Cell Carcinoma",
    label: "Mucinous Tubrlar And Spindle Cell Carcinoma"
  },
  { value: "Acute Tubular Necrosis", label: "Acute Tubular Necrosis" },
  { value: "Renal Infarct", label: "Renal Infarct" },
  { value: "Hyaline Arteriolosclerosis", label: "Hyaline Arteriolosclerosis" },
  { value: "Renal Agenesis", label: "Renal Agenesis" },
  { value: "Potter Facies ( Frontal )", label: "Potter Facies ( Frontal )" },
  { value: "Potter Facies ( Lateral )", label: "Potter Facies ( Lateral )" },
  { value: "Renal Tubular Dysgenesis", label: "Renal Tubular Dysgenesis" },
  { value: "Posterior Urethral Valve", label: "Posterior Urethral Valve" },
  { value: "Fused ( Horshoe ) Kidney", label: "Fused ( Horshoe ) Kidney" },
  { value: "Condyloma Acuminatum", label: "Condyloma Acuminatum" },
  {
    value: "Squamous Cell Carcinoma ( Lymphatic Invasion )",
    label: "Squamous Cell Carcinoma ( Lymphatic Invasion )"
  },
  {
    value: "Squamous Cell Carcinoma ( Microinvasion 1 )",
    label: "Squamous Cell Carcinoma ( Microinvasion 1 )"
  },
  {
    value: "Invasive Squamous Cell Carcinoma",
    label: "Invasive Squamous Cell Carcinoma"
  },
  { value: "Adenocarcinoma", label: "Adenocarcinoma" },
  {
    value: "Pap Smear ( High Grade Squamous Intraepithelial Lesion )",
    label: "Pap Smear ( High Grade Squamous Intraepithelial Lesion )"
  },
  { value: "Paraganglioma", label: "Paraganglioma" },
  { value: "Endometriosis", label: "Endometriosis" },
  { value: "Tuberculosis Salpingitis", label: "Tuberculosis Salpingitis" },
  {
    value: "Metastatic Endometrioid Carcinoma",
    label: "Metastatic Endometrioid Carcinoma"
  },
  { value: "Adenomatoid Tumor", label: "Adenomatoid Tumor" },
  { value: "Endometriosis", label: "Endometriosis" },
  { value: "Ovarian Carcinoma ( 1 2 )", label: "Ovarian Carcinoma ( 1 2 )" },
  { value: "Granulosa Cell Tumor", label: "Granulosa Cell Tumor" },
  {
    value: "Juvenile Granulosa Cell Tumor",
    label: "Juvenile Granulosa Cell Tumor"
  },
  { value: "Krukenberg Tumor ( 1 2 )", label: "Krukenberg Tumor ( 1 2 )" },
  { value: "Serous Papillary Carcinoma", label: "Serous Papillary Carcinoma" },
  { value: "Clear Cell Carcinoma", label: "Clear Cell Carcinoma" },
  { value: "Neuroendocrine Carcinoma", label: "Neuroendocrine Carcinoma" },
  { value: "Dysgerminoma ( 1 2 )", label: "Dysgerminoma ( 1 2 )" },
  {
    value: "Micropapillary Serous Borderline Tumor ( 1 2 )",
    label: "Micropapillary Serous Borderline Tumor ( 1 2 )"
  },
  {
    value: "Serous Papillary Borderline Tumor",
    label: "Serous Papillary Borderline Tumor"
  },
  {
    value: "Sex Cord Tumor With Annular Tubules",
    label: "Sex Cord Tumor With Annular Tubules"
  },
  { value: "Thecoma", label: "Thecoma" },
  { value: "Burkitt Lymphoma", label: "Burkitt Lymphoma" },
  {
    value: "Endometrioid Carcinoma ( 1 2 3 4 )",
    label: "Endometrioid Carcinoma ( 1 2 3 4 )"
  },
  {
    value: "Serous Papillary Carcinoma ( 1 2 3 4 )",
    label: "Serous Papillary Carcinoma ( 1 2 3 4 )"
  },
  { value: "Endometrial Adenocarcinoma", label: "Endometrial Adenocarcinoma" },
  { value: "Endometrial Carcinoma", label: "Endometrial Carcinoma" },
  { value: "Atrophic Endometrium", label: "Atrophic Endometrium" },
  { value: "Leiomyoma ( 1 2 )", label: "Leiomyoma ( 1 2 )" },
  { value: "Leiomyosarcoma ( 1 2 3 )", label: "Leiomyosarcoma ( 1 2 3 )" },
  { value: "Carcinosarcoma", label: "Carcinosarcoma" },
  { value: "Adenomyosis", label: "Adenomyosis" },
  { value: "Stromal Sarcoma", label: "Stromal Sarcoma" },
  { value: "Lipoleiomyoma", label: "Lipoleiomyoma" },
  {
    value: "Squamous Cell Carcinoma ( Microinvasive 1 2 )",
    label: "Squamous Cell Carcinoma ( Microinvasive 1 2 )"
  },
  { value: "Squamous Cell Carcinoma", label: "Squamous Cell Carcinoma" },
  {
    value: "Vulvar Intraepithelial Neoplasia Warty Type",
    label: "Vulvar Intraepithelial Neoplasia Warty Type"
  },
  { value: "Bartholin Cyst", label: "Bartholin Cyst" },
  { value: "Condyloma Acuminatum", label: "Condyloma Acuminatum" },
  {
    value: "Vaginal Intraepithelial Neoplasia ( 1 2 )",
    label: "Vaginal Intraepithelial Neoplasia ( 1 2 )"
  },
  { value: "Condyloma ( 1 2 3 )", label: "Condyloma ( 1 2 3 )" },
  { value: "Malakoplakia", label: "Malakoplakia" },
  { value: "Rhabdomyoma", label: "Rhabdomyoma" },
  {
    value: "Primary Serous Carcinoma ( Psammocarcinoma )",
    label: "Primary Serous Carcinoma ( Psammocarcinoma )"
  },
  { value: "Splenic Abscess", label: "Splenic Abscess" },
  { value: "Gaucher Disease ( 1 2 )", label: "Gaucher Disease ( 1 2 )" },
  {
    value: "Sickle Cell Anemia ( Sequestration )",
    label: "Sickle Cell Anemia ( Sequestration )"
  },
  { value: "Sickle Cells", label: "Sickle Cells" },
  {
    value: "Sickle Cell Anemia ( Autosplenectomy )",
    label: "Sickle Cell Anemia ( Autosplenectomy )"
  },
  { value: "Gamna Gandy Body", label: "Gamna Gandy Body" },
  { value: "Sarcoidosis", label: "Sarcoidosis" },
  { value: "Thymoma ( 1 2 )", label: "Thymoma ( 1 2 )" },
  {
    value: "Small Cell Neuroendocrine Carcinoma ( 1 2 )",
    label: "Small Cell Neuroendocrine Carcinoma ( 1 2 )"
  },
  {
    value: "Acquired Immunodeficiency Syndrome ( Aids )",
    label: "Acquired Immunodeficiency Syndrome ( Aids )"
  },
  { value: "Sarcoidosis", label: "Sarcoidosis" },
  { value: "Castleman'S Disease", label: "Castleman'S Disease" },
  {
    value: "Sinonasal Inflammatory Polyps ( 1 2 3 )",
    label: "Sinonasal Inflammatory Polyps ( 1 2 3 )"
  },
  { value: "Choanal Polyp", label: "Choanal Polyp" },
  { value: "Nasal Glioma ( 1 2 )", label: "Nasal Glioma ( 1 2 )" },
  { value: "Rhinosporidiosis", label: "Rhinosporidiosis" },
  { value: "Rhinoscleroma ( 1 2 3 4 )", label: "Rhinoscleroma ( 1 2 3 4 )" },
  {
    value: "Juvenile Nasopharyngeal Angiofibroma ( 1 2 )",
    label: "Juvenile Nasopharyngeal Angiofibroma ( 1 2 )"
  },
  {
    value: "Schneiderian Papilloma ( Inverted )",
    label: "Schneiderian Papilloma ( Inverted )"
  },
  {
    value: "Schneiderian Papilloma ( Cylindrical 1 2 )",
    label: "Schneiderian Papilloma ( Cylindrical 1 2 )"
  },
  {
    value: "Schneiderian Papillomas ( Septal )",
    label: "Schneiderian Papillomas ( Septal )"
  },
  {
    value: "Squamous Cell Carcinoma ( 1 2 )",
    label: "Squamous Cell Carcinoma ( 1 2 )"
  },
  { value: "Maxillary Sinus Carcinoma", label: "Maxillary Sinus Carcinoma" },
  {
    value: "Sinonasal Adenocarcinoma ( Low - Grade High - Grade )",
    label: "Sinonasal Adenocarcinoma ( Low - Grade High - Grade )"
  },
  { value: "Lymphoepithelioma", label: "Lymphoepithelioma" },
  { value: "Mucocele ( 1 2 3 )", label: "Mucocele ( 1 2 3 )" },
  { value: "Irritation Fibroma", label: "Irritation Fibroma" },
  { value: "Amyloidosis ( 1 2 3 )", label: "Amyloidosis ( 1 2 3 )" },
  { value: "Atrophic Glossitis", label: "Atrophic Glossitis" },
  { value: "Bifid Uvula", label: "Bifid Uvula" },
  { value: "Oral Candidiasis", label: "Oral Candidiasis" },
  { value: "Condyloma Acuminatum", label: "Condyloma Acuminatum" },
  { value: "Crohn'S Disease", label: "Crohn'S Disease" },
  { value: "Epidermoid Cyst", label: "Epidermoid Cyst" },
  { value: "Erythroplakia", label: "Erythroplakia" },
  { value: "Leukoplakia", label: "Leukoplakia" },
  { value: "Hairy Tongue", label: "Hairy Tongue" },
  { value: "Herpes Lesion", label: "Herpes Lesion" },
  { value: "Apthous Ulcer", label: "Apthous Ulcer" },
  { value: "Major Apthous Stomatitis", label: "Major Apthous Stomatitis" },
  { value: "Lingual Thyroid", label: "Lingual Thyroid" },
  { value: "Oral Lipoma", label: "Oral Lipoma" },
  { value: "Ranula", label: "Ranula" },
  { value: "Nasolabial Cyst", label: "Nasolabial Cyst" },
  {
    value: "Scarlet Fever ( 'Strawberry Tongue' )",
    label: "Scarlet Fever ('Strawberry Tongue')"
  },
  { value: "Herpes Zoster", label: "Herpes Zoster" },
  {
    value: "Traumatic Ulcer And Hematoma",
    label: "Traumatic Ulcer And Hematoma"
  },
  { value: "Verruca Vulgaris", label: "Verruca Vulgaris" },
  { value: "Squamous Papillomas", label: "Squamous Papillomas" },
  { value: "Carotid Body Paraganglioma", label: "Carotid Body Paraganglioma" },
  {
    value: "Squamous Cell Carcinoma Of Lip ( 1 2 3 )",
    label: "Squamous Cell Carcinoma Of Lip ( 1 2 3 )"
  },
  {
    value: "Squamous Cell Carcinoma Of Tongue ( 1 2 )",
    label: "Squamous Cell Carcinoma Of Tongue ( 1 2 )"
  },
  {
    value: "Verrucous Carcinoma Of Tongue ( 1 2 )",
    label: "Verrucous Carcinoma Of Tongue ( 1 2 )"
  },
  {
    value: "Vocal Cord Nodules / Polyps",
    label: "Vocal Cord Nodules / Polyps"
  },
  {
    value: "Granular Cell Tumor ( 1 2 3 )",
    label: "Granular Cell Tumor ( 1 2 3 )"
  },
  { value: "Laryngeal Papillomatosis", label: "Laryngeal Papillomatosis" },
  {
    value: "Squamous Cell Carcinoma ( 1 2 3 4 )",
    label: "Squamous Cell Carcinoma ( 1 2 3 4 )"
  },
  { value: "Verrucous Carcinoma", label: "Verrucous Carcinoma" },
  {
    value: "Basaloid Squamous Cell Carcinoma ( 1 2 )",
    label: "Basaloid Squamous Cell Carcinoma ( 1 2 )"
  },
  {
    value: "Chronic Sclerosing Sialadenitis",
    label: "Chronic Sclerosing Sialadenitis"
  },
  { value: "Sialolithiasis ( 1 2 3 )", label: "Sialolithiasis ( 1 2 3 )" },
  {
    value: "Inflammatory Tumor Of Parotid Gland",
    label: "Inflammatory Tumor Of Parotid Gland"
  },
  {
    value: "Branchial Cleft Cyst ( 1 2 3 )",
    label: "Branchial Cleft Cyst ( 1 2 3 )"
  },
  {
    value: "Benign Lymphoepithelial Lesion",
    label: "Benign Lymphoepithelial Lesion"
  },
  { value: "Basal Cell Adenoma", label: "Basal Cell Adenoma" },
  { value: "Warthin'S Tumor ( 1 2 )", label: "Warthin'S Tumor ( 1 2 )" },
  { value: "Myoepithelioma", label: "Myoepithelioma" },
  {
    value: "Pleomorphic Adenoma ( 1 2 )",
    label: "Pleomorphic Adenoma ( 1 2 )"
  },
  { value: "Basal Cell Adenoarcinoma", label: "Basal Cell Adenoarcinoma" },
  {
    value: "Acinic Cell Carcinoma ( 1 )",
    label: "Acinic Cell Carcinoma ( 1 )"
  },
  {
    value: "Adenoid Cystic Carcinoma ( 1 2 3 )",
    label: "Adenoid Cystic Carcinoma ( 1 2 3 )"
  },
  {
    value: "Carcinoma Ex Pleomorphic Adenoma",
    label: "Carcinoma Ex Pleomorphic Adenoma"
  },
  {
    value: "Polymorphous Low - Grade Adenocarcinoma",
    label: "Polymorphous Low - Grade Adenocarcinoma"
  },
  {
    value: "Mucoepidermoid Carcinoma ( 1 2 )",
    label: "Mucoepidermoid Carcinoma ( 1 2 )"
  },
  {
    value: "Recurrent Mixed Tumor Of Parotid ( 1 2 3 )",
    label: "Recurrent Mixed Tumor Of Parotid ( 1 2 3 )"
  },
  { value: "Aural Polyp", label: "Aural Polyp" }
];

const StainTags = [
  { value: "H&E", label: "H&E" },
  { value: "CDX2", label: "CDX2" },
  { value: "CK7", label: "CK7" },
  { value: "CK20", label: "CK20" },
  { value: "SATB2", label: "SATB2" },
  { value: "HE", label: "HE" },
  { value: "HMB45", label: "HMB45" },
  { value: "HEPAR", label: "HEPAR" },
  { value: "Melan A", label: "Melan A" }
];

const UploadFileTypes = /jpeg|jpg|png|pbm|tif|bmp|raw|pdf/; //accept aws s3 upload file types

const UploadFileSize = 20000000; //accept aws s3 upload max file size

const UploadFileFileMax = 20; //accept aws s3 upload max file count

const BaseImageServerUrl = "https://viewer.crosscope.dev";

const ImageAnalyticaServerUrl = "https://apps.crosscope.dev/api/nucleisegment";

const SmallImageConversionEndpoint = `${SmallImageConversionUrl}`;

const LargeImageConversionEndpoint = "https://prodapi.scholarprod.crosscope.com/api/sqs";

const ComplexImageTypes = [
  "svs",
  "tif",
  "ndpi",
  "vms",
  "vmu",
  "scn",
  "mrxs",
  "tiff",
  "svslide",
  "bif",
  "jpg",
  "png",
  "jpeg"
];

const AnnotationColors = [
  { strokeColor: "#4babf9", fillColor: "#7ebff3" },
  { strokeColor: "#eaea7f", fillColor: "#eaea7f" },
  { strokeColor: "#f16fd7", fillColor: "#f388de" },
  { strokeColor: "#18ecf3", fillColor: "#88f0f3" },
  { strokeColor: "#b966ff", fillColor: "#cfa9ef" }
];

const AllowedOrigins = [
  "http://localhost:3000",
  "http://localhost:3001",
  "https://accounts.crosscope.dev",
  "https://accounts.crosscope.com",
  "http://mern.crosscope.dev",
  "http://mern.crosscope.com"
];

module.exports = {
  MedicalTags,
  StainTags,
  UploadFileTypes,
  UploadFileSize,
  UploadFileFileMax,
  BaseImageServerUrl,
  ComplexImageTypes,
  ImageAnalyticaServerUrl,
  AnnotationColors,
  AllowedOrigins,
  SmallImageConversionEndpoint,
  LargeImageConversionEndpoint
};
