import * as methods from "./methods";

const initialState = {
  imageValue: null,
  type: null,
  isQuorumImageLoad: false,
  annotationType: null,
  isAnnotationActive: false,
  setAnnotations: false,
  isQuorumImageInit: false,
  removedAnnotation: false,
  when: null,
  annotatedTime: null,
  annotationColor: null,
  userAnnotation: [],
  annotationData: [],
  toogleData: null,
  liveShareOn: false,
  userLiveShareOn: false,
  isSoketDataUpdate: false,
  scaleUnit: "cm", // default unit to cm
  scaleRatio: 0.01,
  viewerDataArray: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_ANNOTATION_DATA": {
      const data = action.payload;
      return {
        ...state,
        userAnnotation: data
      };
    }

    case "SET_CURRENT_ANNOTATION_DATA": {
      const data = action.payload;
      return {
        ...state,
        annotationData: data
      };
    }

    case "SET_DEFAULT_SCALE_UNIT": {
      return {
        ...state,
        scaleUnit: initialState.scaleUnit
      };
    }

    case "SET_SCALE_UNIT": {
      const data = action.payload;
      return {
        ...state,
        scaleUnit: data
      };
    }

    case "SET_DEFAULT_SCALE_RATIO": {
      return {
        ...state,
        scaleRatio: initialState.scaleRatio
      };
    }

    case "SET_SCALE_RATIO": {
      const data = action.payload;
      return {
        ...state,
        scaleRatio: data
      };
    }

    case "DELETE_ANNOTATION_DATA": {
      const deletedId = action.payload;
      return {
        ...state,
        annotationData: state.annotationData.filter(
          data => data.pk !== deletedId
        )
      };
    }

    case "UPDATE_CURRENT_ANNOTATION_DATA": {
      const data = action.payload;
      return {
        ...state,
        annotationData: [...state.annotationData, data]
      };
    }

    case "UPDATE_CURRENT_ANNOTATION_DATA_IN_VALIDATE": {
      const data = action.payload;
      return {
        ...state,
        annotationData: [...state.annotationData, ...data],
        isSoketDataUpdate: true
      };
    }

    case "SET_SOCKET_DATE_ACTION_FALSE": {
      const data = action.payload;
      return {
        ...state,
        isSoketDataUpdate: false
      };
    }

    case "EDIT_CURRENT_ANNOTATION_DATA": {
      const data = action.payload;
      const filteredAnnoData = state.annotationData.filter(
        data => data.pk !== action.pk
      );
      return {
        ...state,
        annotationData: [...filteredAnnoData, data]
      };
    }

    case "PUBLIC_ANNOTATION_DATA": {
      const data = action.payload;
      const updatedAnnoData = state.annotationData.map(dataSet => {
        if (data.user_id === dataSet.user_id) {
          return {
            ...dataSet,
            is_public: data.status
          };
        } else {
          return dataSet;
        }
      });
      return {
        ...state,
        annotationData: updatedAnnoData
      };
    }

    case "TOGGLE_ANNOTATION_DATA": {
      const payloadData = action.payload;
      return {
        ...state,
        annotationData: state.annotationData.map(data => {
          if (payloadData.annotationIds.includes(data.pk)) {
            return {
              ...data,
              toggle: payloadData.onOrOff
            };
          } else {
            return data;
          }
        }),
        toogleData: payloadData
      };
    }

    case "VALIDATE_ANNOTATION_DATA": {
      const payloadData = action.payload;
      return {
        ...state,
        annotationData: state.annotationData.map(data => {
          if (payloadData.includes(data.pk)) {
            return {
              ...data,
              isValidate: true
            };
          } else {
            return data;
          }
        })
      };
    }

    case "SET_ANNOTATION_TYPE": {
      const type = action.payload;
      return {
        ...state,
        annotationType: type
      };
    }

    case "SET_ANNOTATION_COLOR": {
      const color = action.payload;
      return {
        ...state,
        annotationColor: color
      };
    }

    case "SET_ANNOTATION_ACTIVE": {
      const status = action.payload;
      return {
        ...state,
        isAnnotationActive: status
      };
    }

    case "SET_LIVE_SHARE_ACTIVE": {
      const status = action.payload;
      return {
        ...state,
        liveShareOn: status
      };
    }

    case "SET_LIVE_SHARE_USER_ACTIVE": {
      const status = action.payload;
      return {
        ...state,
        userLiveShareOn: status
      };
    }

    case "RESET_VIEWER_ANNOTATION": {
      return {
        ...initialState
      };
    }
    case "SET_VIEWER_DZI_DATA_ARRAY": {
      return {
        ...state,
        viewerDataArray: action.payload
      };
    }

    default:
      return state;
  }
};
