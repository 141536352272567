import React, { Component } from "react";
import { FadeIn } from "animate-components";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import queryString from 'query-string';

export default class Settings extends Component {
  componentDidMount = () => {
    document.body.style.overflow = 'scroll';
  }
  render() {
    // TODO: For navigate help page with URL params
    let params = queryString.parse(this.props.location.search)
    let activeKey = "help"
    const keys = ["help", "mrxs", "guideline", "term", "policy"]
    if (params && params.tab &&  keys.includes(params.tab)) {
      activeKey = params.tab
    }

    return (
      <React.Fragment>
        <FadeIn duration="300ms">
          <div className="senapati">
            <div className="srajkumar help_srajkumar row-fluid">
              {/* temporarily added flex-nowrap for tabbable */}
              <div
                id="myTabHolder"
                className="tabbable tabs-left row flex-nowrap"
              >
                <Tab.Container defaultActiveKey="help" >
                  <div className="col-lg-2 col-md-3 tab-navigation">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="help">Help & Support</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="guideline">
                          Community Guidelines
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Tab.Content className="col-lg-10 col-md-9" style={{ fontFamily: 'Roboto'}}>
                    <Tab.Pane eventKey="help">
                      <div className="tab-pane" id="lA">
                        <div className="tab-pane-div">
                          <h3 className="text-center">Help &amp; Support</h3>
                          <div
                            className="content"
                            style={{
                              boxSizing: "inherit",
                              margin: "2rem",
                              outline: "none",
                              textAlign: "justify"
                            }}
                          >
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              Crosscope is an all in one AI-powered digital
                              pathology engagement platform to share, archive
                              and solve medical cases in real time.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              You can use Crosscope to:
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              1. Upload interesting medical cases.
                            </p>
                            <br />
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              2. Search or browse through hundreds of active
                              cases looking for those that you can uniquely help
                              solve.
                            </p>
                            <br />
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              3. Follow your peers and enhance your medical
                              knowledge from the exiciting medical content they
                              post.
                            </p>
                            <br />
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              4. Collaborate with experts from aroiund the globe
                              and discuss symptoms, medical history and other
                              clues that could help crack the case.
                            </p>
                            <br />
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              5. Build up your Crosscope reputation by making
                              smart diagnostic and solution suggestions, and
                              supporting those of your peers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="lA" style={{"marginTop": "50px"}}>
                        <div className="tab-pane-div">
                          <h3 className="text-center">How to upload MRXS or SCN</h3>
                          <div
                            className="content"
                            style={{
                              boxSizing: "inherit",
                              margin: "2rem",
                              outline: "none",
                              textAlign: "justify"
                            }}
                          >
                            <p>
                              <br />
                              A MRXS image is composed of multiple
                              files.
                              <br />
                              To upload them into Crosscope, you need to group
                              them into a zip file with the following format :
                            </p>

                            <ul>
                              <li>
                                The main file (example : the .mrxs file) must be
                                at the root of the zip file.
                              </li>
                              <li>
                                The other files (example : the .dat and .ini
                                files) must be grouped into a folder next to
                                main file with the same name.
                              </li>
                            </ul>

                            <p>
                              <br />
                              As an example, the{" "}
                              <a
                                className="wiki external"
                                target="_blank"
                                href="http://openslide.cs.cmu.edu/download/openslide-testdata/Mirax/CMU-1.zip"
                                rel="external"
                              >
                                CMU-1
                              </a>
                              <span class="icon icon-link-external fa fa-external-link fa-fw "></span>
                              file from the openslide datatest has the following
                              structure
                            </p>

                            <pre
                              className="codelisting"
                              data-theme="off"
                              data-wrap="1"
                              dir="ltr"
                              style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                                wordWrap: "break-word"
                              }}
                              id="codebox1"
                            >
                              {`
                           zip
                            |
                             --> CMU-1.mrxs
                            |
                             --> CMU-1/
                                   |
                                    ---> Data0000.dat
                                   |
                                    ---> Data0001.dat
                                   |
                                    ---> ...
                                   |
                                    ---> Data0022.dat
                                   |
                                    ---> Index.dat
                                   |
                                    ---> Slidedat.ini`}
                            </pre>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="guideline">
                      <div className="tab-pane" id="lB">
                        <div className="tab-pane-div">
                          <h3 className="text-center">Community Guidelines</h3>
                          <div
                            className="content"
                            style={{
                              boxSizing: "inherit",
                              margin: "2rem",
                              outline: "none",
                              textAlign: "justify"
                            }}
                          >
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px"
                              }}
                            >
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px"
                                }}
                              >
                                Crosscope is an all in one AI-powered digital
                                pathology engagement platform to share, archive
                                and solve medical cases in real time.
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px"
                                }}
                              >
                                The following Community Guidelines are here to
                                help you understand what it means to be a member
                                of the Crosscope community:
                              </p>
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                Respect Patient Privacy
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                The cases presented on Crosscope are real
                                patients. Whether you are sharing a case or
                                uploading a comment you must never share patient
                                identifiable information. Any case or comment
                                that violates this policy will be removed. It is
                                strictly your responsibility to maintain patient
                                privacy at all times.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                Maintain Professional Behavior
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Crosscope is a respectful network for social
                                learning and saving lives. Please keep in mind
                                that this is a very professional environment
                                while contributing to Crosscope via your medical
                                cases and comments. Crosscope is a global
                                network of healthcare professionals from
                                different countries and backgrounds. Please
                                maintain a respectful learning environment.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                Support Your Contributions with Captions
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                For helping the Crosscope community of experts
                                to understand and contribute towards the
                                diagnoses, please consider providing appropriate
                                evidence and data along with your case uploads.
                                Any failure to provide necessary information on
                                case uploads may result in your case or feedback
                                being removed.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                Avoid Promotional Messages
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Blatant link-dumping or self-promotion messages
                                will be removed. Our community is busy cracking
                                cases and saving lives, spamming them with
                                commercial or promotional content is not
                                permitted.
                              </p>
                            </div>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              <br style={{ boxSizing: "inherit" }} />
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "0px",
                                padding: "0px"
                              }}
                            >
                              If you have any questions or comments, we'd love
                              to hear from you.{" "}
                              <br style={{ boxSizing: "inherit" }} />
                              Please feel free to email us at{" "}
                              <a
                                href="mailto:info@crosscope.com"
                                style={{
                                  boxSizing: "inherit",
                                  color: "#0f0da1",
                                  cursor: "pointer",
                                  outline: "none"
                                }}
                              >
                                info@crosscope.com
                              </a>
                              <br style={{ boxSizing: "inherit" }} />
                              <br style={{ boxSizing: "inherit" }} />
                              <br style={{ boxSizing: "inherit" }} />
                              The Crosscope Team
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="term">
                      <div className="tab-pane" id="lC">
                        <div className="tab-pane-div">
                          <h3 className="text-center">Terms of Service</h3>
                          <div
                            className="content"
                            style={{
                              boxSizing: "inherit",
                              margin: "2rem",
                              outline: "none",
                              textAlign: "justify"
                            }}
                          >
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              Thank you for registering to use Crosscope Inc.’s
                              (“Company” or “we” or “our” or “us” or the like)
                              web-based virtual microscopy platform (the
                              “Service”). These Terms of Service (this
                              “Agreement”) govern your use of the Crosscope
                              Digital Pathology service.{" "}
                              <b>
                                Please read this Agreement carefully, as it
                                (among other things) requires in Section 11 that
                                you and the Company arbitrate certain claims
                                instead of going to court and restricts class
                                action claims.
                              </b>
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              Please press “Accept” if you agree to be legally
                              bound by all terms and conditions herein. Your
                              acceptance of this Agreement creates a legally
                              binding contract between you and the Company. If
                              you do not agree with any aspect of this
                              Agreement, then do not press “Accept,” in which
                              case you may not use the Service.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              <b>Note for Children.</b> The Service is intended
                              for use by physicians and researchers. Use of the
                              Service by anyone under the age of 18 is
                              prohibited.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              1. The Service
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              The Service is a digital pathology platform that
                              allows physicians and researchers to
                              collaboratively review and evaluate cases, share
                              their expertise and learn from one another. The
                              Service is not intended to replace professional
                              medical advice, diagnosis or treatment. You
                              acknowledge that the Service is available for use
                              by the general public, and the Company does not
                              verify the stated identity, credentials or
                              experience of any user of the Service, nor does
                              the Company make any representation or warranty as
                              to the accuracy or usefulness of any information
                              made available on the Service from any user
                              thereof. Use of the Service is not a substitute
                              for formal scientific education and training.{" "}
                              <b>
                                You are solely responsible for ensuring that
                                your use of the Service does not constitute or
                                result in a violation of any applicable laws,
                                rules or regulations, including without
                                limitation laws relating to the practice of
                                medicine.
                              </b>
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              2. Privacy
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              The Company’s Privacy Policy, at [
                              <a
                                href="www.crosscope.com/privacy.html"
                                target="_blank"
                              >
                                www.crosscope.com/privacy.html
                              </a>
                              ] (the “Privacy Policy”), describes the
                              collection, use and disclosure of data by the
                              Company in connection with the Service. The
                              Privacy Policy, as may be updated by the Company
                              from time to time in accordance with its terms, is
                              hereby incorporated into this Agreement, and you
                              hereby agree to the collection, use and disclose
                              practices set forth therein. User shall only
                              transfer data and materials (including User
                              Content) to the Service that qualify as{" "}
                              <b>De-Identified Data</b>. For purposes of this
                              Agreement, “De- Identified Data” means
                              de-identified information (i) created in
                              accordance with at least one of the
                              de-identification specifications set forth in 45
                              C.F.R. § 164.514(b) and (ii) from which all
                              personal identifiers have been removed such that
                              there is no reasonable basis to believe nor is
                              there any actual knowledge that such information
                              can be used to identify an individual.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              <b>
                                The Service is not intended to be used to store,
                                share or transmit information any Protected
                                Health Information “PHI”(as defined under the
                                Health Insurance Portability and Accountability
                                Act of 1996 (“HIPPA”)), and you represent and
                                warrant that you
                              </b>{" "}
                              shall not submit, collect or use any “personal
                              health information” as defined in 45 CFR §160.103
                              (“ PHI”), with or to the Service.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              In the event that User transfers data or materials
                              (including User Content) to the Service that do
                              not qualify as De-Identified Data or that
                              otherwise contain PHI (collectively,{" "}
                              <b>“Unauthorized Data”</b>), (i) Crosscope shall
                              have the right, but not the obligation, to delete
                              any Unauthorized Data, (ii) Crosscope shall not be
                              liable for any dissemination of Unauthorized Data
                              to or by User or other users of the Service, and
                              (iii) User shall indemnify and hold Crosscope
                              harmless for any losses incurred by Crosscope as a
                              result of User’s transfer of Unauthorized Data to
                              the Service. User acknowledges and agrees that
                              Crosscope is not responsible for monitoring the
                              Service for Unauthorized Data and that Crosscope
                              shall have no liability under this Agreement for
                              Unauthorized Data received from User, in each case
                              notwithstanding anything to the contrary herein.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              3. Third Party Platforms.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              The Service may include advertisements or other
                              links that allow you to access web sites or other
                              online services that are owned and operated by
                              third parties. You acknowledge and agree that the
                              Company is not responsible and shall have no
                              liability for the content of such third-party
                              sites and services, products or services made
                              available through them, or your use of or
                              interaction with them.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              4. User-generated content.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              4.1. The Service allows you and other users to
                              upload, store and share image content and comments
                              (“User-generated content”) through the Service.
                              You understand that all User-generated content is
                              available only on an “as-available” basis and the
                              Company does not guarantee that the availability
                              of User-generated content will be uninterrupted or
                              bug free. The Service is not intended to be used
                              as a primary database of medical images, and you
                              should maintain your own back-up copies of all
                              User- generated content that you submit to and
                              store on the Service. You agree you are
                              responsible for all of your User-generated content
                              and all activities that occur under your user
                              account. Some or all of your User-generated
                              content may be removed at any time, for any reason
                              or for no reason and without notice. Without
                              limiting the foregoing, your User-generated
                              content may be removed if the Company determines
                              (in its sole discretion) that its content is
                              inappropriate, if it may include Protected Health
                              Information or if it is the subject of flagging by
                              other Service users.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              4.2. In connection with your User-generated
                              content, you further agree that you will not use,
                              create, provide or post: (i) material that is
                              subject to third party intellectual property or
                              proprietary rights, including privacy and
                              publicity rights, unless you are the owner of such
                              rights or have permission from their rightful
                              owner to post the material and to grant the
                              Company all of the license rights granted herein;
                              (ii) material that is unlawful, defamatory,
                              libelous, threatening, pornographic, harassing,
                              hateful, racially or ethnically offensive or
                              encourages conduct that would be considered a
                              criminal offense, violate any law or is otherwise
                              inappropriate; or (iii) advertisements or
                              marketing content or solicitations of business, or
                              any content of a commercial nature. The Company
                              may investigate an allegation that any
                              User-generated content does not conform this to
                              Agreement and may determine in good faith and in
                              its sole discretion whether to remove such
                              User-generated content, which it reserves the
                              right to do at any time.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              4.3. You hereby acknowledge that you may be
                              exposed to User-generated content from other users
                              that is inaccurate, offensive, obscene, indecent,
                              or objectionable when using the Service, and
                              further acknowledge that the Company does not
                              control the User-generated content posted by
                              Service users and does not have any obligation to
                              monitor such content for any purpose. Under no
                              circumstances will Crosscope become liable for any
                              payment to you for any User-generated content or
                              any other information that User provides, whether
                              concerning the Service or otherwise.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              5. Ownership; Proprietary Rights.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              As between you and the Company, you own all
                              worldwide right, title and interest, including all
                              intellectual property and other proprietary
                              rights, in and to Media that you submit to the
                              Service, subject to the licenses granted to the
                              Company herein. As between you and the Company,
                              the Company owns all worldwide right, title and
                              interest, including all intellectual property and
                              other proprietary rights, in and to the Service,
                              the software and technology used by the Company to
                              provide Service features and functionality and all
                              usage and other data generated or collected in
                              connection with the use thereof (the “Company
                              Materials”). You agree not to reverse engineer,
                              decompile, disassemble, license, distribute, copy,
                              modify, publicly perform or display, transmit,
                              publish, edit, adapt, create derivative works
                              from, or otherwise make any unauthorized use of
                              the Company Materials.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              6. Prohibited Uses.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              As a condition of your use of the Service, you
                              will not use the Service for any purpose that is
                              unlawful or prohibited by this Agreement, or would
                              cause a breach of any applicable agreements with
                              third parties to which you are bound. You may not
                              use the Service in any manner that in our sole
                              discretion could damage, disable, overburden,
                              impair or interfere with any other party’s use of
                              the Service. You may not obtain or attempt to
                              obtain any materials or information through any
                              means not intentionally made available through the
                              Service. In addition, you agree not to use false
                              or misleading information in connection with your
                              user account, and acknowledge that we reserve the
                              right to disable any user account with a profile
                              which we reasonably believe is false or misleading
                              (including a profile that impersonates a third
                              party). By using the Service, you represent and
                              warrant that: (i) you are not located in any
                              country that is subject to a U.S. Government
                              embargo, or that has been designated by the U.S.
                              Government as a “terrorist-supporting” country;
                              and (ii) you are not listed on any U.S. Government
                              list of prohibited or restricted parties.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              7. Termination.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              You may terminate this Agreement at any time, for
                              any reason or for no reason, by deleting your
                              Service account through the Service user
                              interface. If you are for any reason unable to
                              delete your account through the Service user
                              interface, you may also send us a request for
                              deletion of your account to{" "}
                              <u>info@crosscope.com</u>. Such requests will be
                              honored within a reasonable time period after they
                              have been received provided that you give us all
                              reasonably necessary information and cooperation.
                              Certain data (including your Media) may continue
                              to be stored in our backup systems until regularly
                              scheduled deletions occur. You agree that the
                              Company, in its sole discretion and for any or no
                              reason, may terminate this Agreement, your account
                              or your use of the Service. The Company may also
                              in its sole discretion and at any time discontinue
                              providing the Service, or any part thereof, with
                              or without notice. You agree that any termination
                              of your use of the Service or any account you may
                              have or portion thereof may be effected without
                              prior notice, and you agree that the Company shall
                              not be liable to you or any third-party for any
                              such termination. Sections 1, 3-5, 7-12 and 14
                              will survive any termination of this Agreement.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              8. Disclaimers; No Warranties.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              THE SERVICE AND ANY MEDIA, INFORMATION OR OTHER
                              MATERIALS MADE AVAILABLE IN CONJUNCTION WITH OR
                              THROUGH THE SERVICE ARE PROVIDED “AS IS” AND
                              WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR
                              IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE
                              PURSUANT TO APPLICABLE LAW, THE COMPANY AND ITS
                              LICENSORS, SERVICE PROVIDERS AND PARTNERS DISCLAIM
                              ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
                              NOT LIMITED TO, IMPLIED WARRANTIES OF
                              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                              AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. THE
                              COMPANY AND ITS LICENSORS, SERVICE PROVIDERS AND
                              PARTNERS DO NOT WARRANT THAT THE FEATURES AND
                              FUNCTIONALITY OF THE SERVICE WILL BE UNINTERRUPTED
                              OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR
                              THAT THE SERVICE OR THE SERVERS THAT MAKE
                              AVAILABLE THE FEATURES AND FUNCTIONALITY THEREOF
                              ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                              CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON
                              IMPLIED WARRANTIES. IF THESE LAWS APPLY TO YOU,
                              SOME OR ALL OF THE FOREGOING DISCLAIMERS,
                              EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU,
                              AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              9. Indemnification; Hold Harmless.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              You agree to indemnify and hold the Company and
                              its affiliated companies, and each of their
                              officers, directors and employees, harmless from
                              any claims, losses, damages, liabilities, costs
                              and expenses, including reasonable attorney’s
                              fees, (any of the foregoing, a “Claim”) arising
                              out of or relating to your use or misuse of the
                              Service, violation of any applicable law, rule or
                              regulation, breach of this Agreement or
                              infringement, misappropriation or violation of the
                              intellectual property or other rights of any other
                              person or entity, provided that the foregoing does
                              not obligate you to the extent the Claim arises
                              out of the Company’s willful misconduct or gross
                              negligence. The Company reserves the right, at
                              your expense, to assume the exclusive defense and
                              control of any matter for which you are required
                              to indemnify us and you agree to cooperate with
                              our defense of these claims.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              10. Limitation of Liability and Damages.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED
                              TO, NEGLIGENCE, SHALL THE COMPANY OR ITS
                              CONTRACTORS, EMPLOYEES, OFFICERS, DIRECTORS AND
                              AGENTS, OR THIRD PARTY LICENSORS, SERVICE
                              PROVIDERS OR PARTNERS, BE LIABLE TO YOU FOR ANY
                              SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR
                              EXEMPLARY DAMAGES THAT RESULT FROM THE SERVICE,
                              INCLUDING YOUR USE THEREOF, OR ANY OTHER
                              INTERACTIONS WITH THE COMPANY, EVEN IF THE COMPANY
                              OR A COMPANY REPRESENTATIVE HAS BEEN ADVISED OF
                              THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL
                              THE TOTAL LIABILITY OF COMPANY OR ITS CONTRACTORS,
                              EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, LICENSORS,
                              SERVICE PROVIDERS OR PARTNERS TO YOU FOR ALL
                              DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT
                              OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF
                              THE SERVICE EXCEED FIFTY U.S. DOLLARS. APPLICABLE
                              LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
                              LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                              SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY
                              TO YOU, IN WHICH CASE, THE COMPANY’S LIABILITY
                              WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY
                              LAW.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              11. Arbitration.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              11.1. <u>Agreement to Arbitrate</u>. This Section
                              11 is referred to herein as the “Arbitration
                              Agreement.” The parties that any and all
                              controversies, claims, or disputes between you and
                              Company arising out of, relating to, or resulting
                              from this Agreement or the Service, shall be
                              subject to binding arbitration pursuant to the
                              terms and conditions of this Arbitration
                              Agreement, and not any court action (other than a
                              small claims court action to the extent the claim
                              qualifies). The Federal Arbitration Act governs
                              the interpretation and enforcement of this
                              Arbitration Agreement.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              11.2. <u>Class Action Waiver</u>. THE PARTIES
                              AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST THE
                              OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A
                              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                              OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS
                              BOTH PARTIES AGREE OTHERWISE, THE ARBITRATOR MAY
                              NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON&#39;S
                              OR PARTY&#39;S CLAIMS AND MAY NOT OTHERWISE
                              PRESIDE OVER ANY FORM OF A CONSOLIDATED,
                              REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE
                              ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY,
                              INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR
                              OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO
                              THE EXTENT NECESSARY TO PROVIDE RELIEF
                              NECESSITATED BY THAT PARTY&#39;S INDIVIDUAL
                              CLAIM(S).
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              11.3. <u>Procedures</u>. Arbitration will be
                              conducted by a neutral arbitrator in accordance
                              with the American Arbitration Association&#39;s
                              (&quot;AAA&quot;) rules and procedures (the
                              &quot;AAA Rules&quot;), as modified by this
                              Arbitration Agreement. If there is any
                              inconsistency between the AAA Rules and this
                              Arbitration Agreement, the terms of this
                              Arbitration Agreement will control unless the
                              arbitrator determines that the application of the
                              inconsistent Arbitration Agreement terms would not
                              result in a fundamentally fair arbitration. The
                              arbitrator must also follow the provisions of this
                              Agreement as a court would, including without
                              limitation, the limitation of liability provisions
                              in Section 10. You may visit http://www.adr.org
                              for information on the AAA and
                              http://www.adr.org/fileacase for information on
                              how to file a claim against the Company.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              11.4. <u>Venue</u>. The arbitration shall be held
                              in the county in which you reside or at another
                              mutually agreed location. If the value of the
                              relief sought is $10,000 or less, you or Company
                              may elect to have the arbitration conducted by
                              telephone or based solely on written submissions,
                              which election shall be binding on each party, but
                              subject to the arbitrator&#39;s discretion to
                              require an in-person hearing if the circumstances
                              warrant. Attendance at any in-person hearing may
                              be made by telephone by either or both parties
                              unless the arbitrator requires otherwise.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              11.5. <u>Governing Law</u>. The arbitrator will
                              decide the substance of all claims in accordance
                              with the laws of the state of California, without
                              regard to its conflicts of laws rules, and will
                              honor all claims of privilege recognized by law.
                              The arbitrator shall not be bound by rulings in
                              prior arbitrations involving different Service
                              users, but is bound by rulings in prior
                              arbitrations involving you to the extent required
                              by applicable law.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              11.6. <u>Costs of Arbitration</u>. Payment of all
                              filing, administration, and arbitrator fees
                              (collectively, the &quot;Arbitration Fees&quot;)
                              will be governed by the AAA&#39;s Rules. Each
                              party will be responsible for all other fees it
                              incurs in connection with the arbitration,
                              including without limitation, all attorney fees.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              11.7. <u>Confidentiality</u>. All aspects of the
                              arbitration proceeding, and any ruling, decision
                              or award by the arbitrator, will be strictly
                              confidential for the benefit of all parties.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              11.8. <u>Severability</u>. If a court decides that
                              any term or provision of this Arbitration
                              Agreement other than Section 11.2 is invalid or
                              unenforceable, the parties agree to replace such
                              term or provision with a term or provision that is
                              valid and enforceable and that comes closest to
                              expressing the intention of the invalid or
                              unenforceable term or provision, and this
                              Arbitration Agreement shall be enforceable as so
                              modified. If a court decides that any of the
                              provisions of Section 11.2 is invalid or
                              unenforceable, then the entirety of this
                              Arbitration Agreement shall be null and void. The
                              remainder of this Agreement will continue to
                              apply.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              12. Claims.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              YOU AND THE COMPANY AGREE THAT ANY CAUSE OF ACTION
                              ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE
                              SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER
                              THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE
                              OF ACTION IS PERMANENTLY BARRED.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              13. Changes to Agreement.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              The Company may make modifications, deletions
                              and/or additions to this Agreement (“Changes”) at
                              any time. Changes will be effective: (i) thirty
                              (30) days after we provide notice of the Changes,
                              whether such notice is provided through the
                              Service user interface, is sent to the e-mail
                              address associated with your account or otherwise;
                              or (ii) when you opt-in or otherwise expressly
                              agree to the Changes or a version of this
                              Agreement incorporating the Changes, whichever
                              comes first.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              14. Miscellaneous.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              Under this Agreement, you consent to receive
                              communications from the Company electronically.
                              This Agreement shall be governed by and construed
                              in accordance with the laws of the State of
                              California, without giving effect to any
                              principles of conflicts of law. You agree that any
                              action at law or in equity arising out of or
                              relating to this Agreement or the Service that is
                              not subject to arbitration under Section 11 shall
                              be filed only in the state or federal courts in
                              California and you hereby consent and submit to
                              the personal jurisdiction of such courts for the
                              purposes of litigating any such action. The
                              failure of any party at any time to require
                              performance of any provision of this Agreement
                              shall in no manner affect such party’s right at a
                              later time to enforce the same. A waiver of any
                              breach of any provision of this Agreement shall
                              not be construed as a continuing waiver of other
                              breaches of the same or other provisions of this
                              Agreement. If any provision of this Agreement
                              shall be unlawful, void, or for any reason
                              unenforceable, then that provision shall be deemed
                              severable from this Agreement and shall not affect
                              the validity and enforceability of any remaining
                              provisions. This Agreement, and any rights and
                              licenses granted hereunder, may not be transferred
                              or assigned by you, but may be assigned by the
                              Company without restriction. This is the entire
                              agreement between us relating to the subject
                              matter herein and shall not be modified except in
                              a writing, signed by both parties, or by a change
                              to this Agreement made by the Company as set forth
                              in Section 13 above.
                            </p>
                            <p
                              className="text-bold color-primary"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px",
                                color: "#0f0da1",
                                fontWeight: "500"
                              }}
                            >
                              15. Contact Information.
                            </p>
                            <p
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1em",
                                padding: "0px"
                              }}
                            >
                              The Service is provided by Crosscope, Inc. If you
                              have any questions, complaints or claims with
                              respect to the Service, you may contact us at{" "}
                              <u>info@crosscope.com</u>.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="policy">
                      <div className="tab-pane" id="lD">
                        <div className="tab-pane-div">
                          <h3 className="text-center">Privacy Policy</h3>
                          <div
                            className="content"
                            style={{
                              boxSizing: "inherit",
                              margin: "2rem",
                              outline: "none",
                              textAlign: "justify"
                            }}
                          >
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1.5rem",
                                outline: "none"
                              }}
                            >
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px"
                                }}
                              >
                                Welcome to our Privacy Policy
                              </p>
                              -- Your privacy is critically important to us.
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px"
                                }}
                              ></p>
                              It is Crosscope's policy to respect your privacy
                              regarding any information we may collect while
                              operating our website. This Privacy Policy applies
                              to "Crosscope" https://www.crosscope.com
                              (hereinafter, "us", "we", or
                              "https://www.crosscope.com"). We respect your
                              privacy and are committed to protecting personally
                              identifiable information you may provide us
                              through the Website. We have adopted this privacy
                              policy ("Privacy Policy") to explain what
                              information may be collected on our Website, how
                              we use this information, and under what
                              circumstances we may disclose the information to
                              third parties. This Privacy Policy applies only to
                              information we collect through the Website and
                              does not apply to our collection of information
                              from other sources. This Privacy Policy, together
                              with the Terms and conditions posted on our
                              Website, set forth the general rules and policies
                              governing your use of our Website. Depending on
                              your activities when visiting our Website, you may
                              be required to agree to additional terms and
                              conditions.
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px"
                                }}
                              ></p>
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                1. Website Visitors
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Like most website operators, Crosscope collects
                                non-personally-identifying information of the
                                sort that web browsers and servers typically
                                make available, such as the browser type,
                                language preference, referring site, and the
                                date and time of each visitor request.
                                Crosscope's purpose in collecting non-personally
                                identifying information is to better understand
                                how Crosscope's visitors use its website. From
                                time to time, Crosscope may release
                                non-personally-identifying information in the
                                aggregate, e.g., by publishing a report on
                                trends in the usage of its website. Crosscope
                                also collects potentially personally-identifying
                                information like Internet Protocol (IP)
                                addresses for logged in users and for users
                                leaving comments on https://www.crosscope.com
                                services. Crosscope only discloses logged in
                                user and commenter IP addresses under the same
                                circumstances that it uses and discloses
                                personally-identifying information as described
                                below.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1.5rem",
                                outline: "none"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                2. Gathering of Personally-Identifying
                                Information
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Certain visitors to Crosscope's websites choose
                                to interact with Crosscope in ways that require
                                Crosscope to gather personally-identifying
                                information. The amount and type of information
                                that Crosscope gathers depends on the nature of
                                the interaction. For example, we ask visitors
                                who sign up for our app at
                                https://www.crosscope.com to provide a username
                                and email address.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1.5rem",
                                outline: "none"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                3. Security
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                All information is saved and stored on servers
                                which are secured with passwords and pins to
                                ensure no unauthorized person has access to it.
                                The security of your Personal Information is
                                important to us but remember that no method of
                                transmission over the Internet, or method of
                                electronic storage is 100% secure. While we
                                strive to use commercially acceptable means to
                                protect your Personal Information, we cannot
                                guarantee its absolute security.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1.5rem",
                                outline: "none"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                4. Advertisements
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Ads appearing on our website may be delivered to
                                users by advertising partners, who may set
                                cookies. These cookies allow the ad server to
                                recognize your computer each time they send you
                                an online advertisement to compile information
                                about you or others who use your computer. This
                                information allows ad networks to, among other
                                things, deliver targeted advertisements that
                                they believe will be of most interest to you.
                                This Privacy Policy covers the use of cookies by
                                Crosscope and does not cover the use of cookies
                                by any advertisers.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1.5rem",
                                outline: "none"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                5. Links To External Sites
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Our Service may contain links to external sites
                                that are not operated by us. If you click on a
                                third-party link, you will be directed to that
                                third party's site. We strongly advise you to
                                review the Privacy Policy and terms and
                                conditions of every site you visit. We have no
                                control over, and assume no responsibility for
                                the content, privacy policies or practices of
                                any third-party sites, products or services.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1.5rem",
                                outline: "none"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                6. Protection of Certain Personally-Identifying
                                Information
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Crosscope discloses potentially
                                personally-identifying and
                                personally-identifying information only to those
                                of its employees, contractors and affiliated
                                organizations that (i) need to know that
                                information in order to process it on
                                Crosscope's behalf or to provide services
                                available at Crosscope's website, and (ii) that
                                have agreed not to disclose it to others. Some
                                of those employees, contractors and affiliated
                                organizations may be located outside of your
                                home country; by using Crosscope's website, you
                                consent to the transfer of such information to
                                them. Crosscope will not rent or sell
                                potentially personally-identifying and
                                personally-identifying information to anyone.
                                Other than to its employees, contractors and
                                affiliated organizations, as described above,
                                Crosscope discloses potentially
                                personally-identifying and
                                personally-identifying information only in
                                response to a subpoena, court order or other
                                governmental request, or when Crosscope believes
                                in good faith that disclosure is reasonably
                                necessary to protect the property or rights of
                                Crosscope, third parties or the public at large.
                                If you are a registered user of
                                https://www.crosscope.com and have supplied your
                                email address, Crosscope may occasionally send
                                you an email to tell you about new features,
                                solicit your feedback, or just keep you up to
                                date with what's going on with Crosscope and our
                                products. We primarily use our services to
                                communicate this type of information, so we
                                expect to keep this type of email to a minimum.
                                If you send us a request (for example via a
                                support email or via one of our feedback
                                mechanisms), we reserve the right to publish it
                                in order to help us clarify or respond to your
                                request or to help us support other users.
                                Crosscope takes all measures reasonably
                                necessary to protect against the unauthorized
                                access, use, alteration or destruction of
                                potentially personally-identifying and
                                personally-identifying information.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1.5rem",
                                outline: "none"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                7. Aggregated Statistics
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Crosscope may collect statistics about the
                                behavior of visitors to its website. Crosscope
                                may display this information publicly or provide
                                it to others. However, Crosscope does not
                                disclose your personally-identifying
                                information.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                marginBottom: "1.5rem",
                                outline: "none"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                8. Cookies
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                To enrich and perfect your online experience,
                                Crosscope uses "Cookies", similar technologies
                                and services provided by others to display
                                personalized content, appropriate advertising
                                and store your preferences on your computer. A
                                cookie is a string of information that a website
                                stores on a visitor's computer, and that the
                                visitor's browser provides to the website each
                                time the visitor returns. Crosscope uses cookies
                                to help Crosscope identify and track visitors,
                                their usage of https://www.crosscope.com, and
                                their website access preferences. Crosscope
                                visitors who do not wish to have cookies placed
                                on their computers should set their browsers to
                                refuse cookies before using Crosscope's
                                websites, with the drawback that certain
                                features of Crosscope's websites may not
                                function properly without the aid of cookies. By
                                continuing to navigate our website without
                                changing your cookie settings, you hereby
                                acknowledge and agree to Crosscope's use of
                                cookies.
                              </p>
                            </div>
                            <div
                              className="content"
                              style={{
                                boxSizing: "inherit",
                                outline: "none"
                              }}
                            >
                              <p
                                className="text-bold color-primary"
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "1em",
                                  padding: "0px",
                                  color: "#0f0da1",
                                  fontWeight: "500"
                                }}
                              >
                                9. Privacy Policy Changes
                              </p>
                              <p
                                style={{
                                  boxSizing: "inherit",
                                  marginBottom: "0px",
                                  padding: "0px"
                                }}
                              >
                                Although most changes are likely to be minor,
                                Crosscope may change its Privacy Policy from
                                time to time, and in Crosscope's sole
                                discretion. Crosscope encourages visitors to
                                frequently check this page for any changes to
                                its Privacy Policy. Your continued use of this
                                site after any change in this Privacy Policy
                                will constitute your acceptance of such change.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </FadeIn>
      </React.Fragment>
    );
  }
}
