import React, { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import ToolTip from "react-tooltip";
import _ from "lodash";

import Overview from "./components/Overview.js";
import Nothing from "../others/nothing";
import BookmarkCardView from "../PostCardView/BookmarkCardView";
import { Me } from "../../utils/utils";
import MapPosts from "../post/map-posts/map-posts";
import Unfollow from "../others/follow/unfollow";
import Follow from "../others/follow/follow";
import { toggleFollow } from "../../actions/follow";
import { getUserDetails, resetUser } from "../../actions/user";
import { getUserStats, isFollowing } from "../../actions/follow";
import {
  getBookmarkedPosts,
  getAllPosts,
  setProfileLoading,
  setBookmarkLoading,
  resetAllPostAndBookmark
} from "../../actions/post";
import { withRouter } from "react-router-dom";
import EditIcon from "../../assets/icons/pencil.svg";
import CaseStreamCardLoader from "../others/fadedLoaders/case-stream-card-loader";
import ProfilePageLayout from "./components/ProfilePageLayout.js";
import End from "../others/end";
import PrimaryButton from "../others/button/primary-btn";
import SecondaryButton from "../others/button/secondary-btn";
import EditProfilePopup from "./EditProfilePopup";
// import './Profile.scss';

class MainProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "Overview",
      ud: props.ud,
      user: null,
      showEditProfile: false
    };
    this.toggle = this.toggle.bind(this);
    this.pageContainerRef = createRef();
  }

  componentDidMount() {
    let {
      match: {
        params: { username }
      },
      dispatch
    } = this.props;
    dispatch(setProfileLoading());
    dispatch(setBookmarkLoading());
    this.refreshProfilePage(dispatch, username).then(() => {
      this.getFreshPageData();
    });
  }

  componentWillMount() {
    let {
      match: {
        params: { username }
      },
      dispatch
    } = this.props;
    dispatch(setProfileLoading());
    dispatch(setBookmarkLoading());
    this.refreshProfilePage(dispatch, username).then(() => {
      this.getFreshPageData();
    });
  }

  refreshProfilePage = (dispatch, username) => {
    return new Promise((resolve, _reject) => {
      dispatch(resetAllPostAndBookmark(username));
      resolve();
    });
  };

  getFreshPageData = () => {
    let {
      match: {
        params: { username }
      },
      dispatch,
      session,
      ud
    } = this.props;
    const user =
      typeof username !== "undefined" ? username : this.props.session.username;
    this.setState({ user });
    dispatch(resetUser());
    dispatch(getUserStats(user));
    dispatch(getAllPosts(user, this.props.currentCaseAndQuorumPage + 1));
    if (session.id === ud.id) {
      dispatch(getBookmarkedPosts(user, this.props.currentBookmarkPage + 1));
    } else {
      dispatch(isFollowing(username));
    }
    this.pageContainerRef.current.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    dispatch(getUserDetails(user));
    window.addEventListener("scroll", this.loadOnPageScroll);
  };

  toggle = what => this.props.dispatch(toggleFollow(what));

  updateState = obj => {
    this.setState(obj);
  };

  gotoSocialGraph = type => {
    const { history } = this.props;
    const urlSplitedData = history.location.pathname.split("/");
    const username = urlSplitedData[urlSplitedData.length - 1];
    this.props.history.push(`/social-graph/${username}`, { tab: type });
  };

  toggleEditProfile = () => {
    // this.props.history.push(`/edit-profile`);
    this.setState(prev => ({
      showEditProfile: !prev.showEditProfile
    }));
  };

  loadOnPageScroll = async e => {
    let element = event.target;
    let {
      dispatch,
      isProfileLoaded,
      isBookmarkLoaded,
      isAllCasesLoaded,
      isAllBookmarkLoaded,
      currentBookmarkPage,
      currentCaseAndQuorumPage,
      isMobile
    } = this.props;
    let { user, tab } = this.state;
    if (isMobile) {
      if (
        element.documentElement.scrollTop +
        element.documentElement.clientHeight +
        1000 >=
        element.documentElement.scrollHeight
      ) {
        if (tab == "Cases" && isProfileLoaded && !isAllCasesLoaded) {
          dispatch(setProfileLoading());
          dispatch(getAllPosts(user, currentCaseAndQuorumPage + 1));
        } else if (
          tab == "Bookmarks" &&
          isBookmarkLoaded &&
          !isAllBookmarkLoaded
        ) {
          dispatch(setBookmarkLoading());
          dispatch(getBookmarkedPosts(user, currentBookmarkPage + 1));
        }
      }
    } else {
      if (
        element.documentElement.scrollTop +
        element.documentElement.clientHeight +
        15 >=
        element.documentElement.scrollHeight
      ) {
        if (tab == "Cases" && isProfileLoaded && !isAllCasesLoaded) {
          dispatch(setProfileLoading());
          dispatch(getAllPosts(user, currentCaseAndQuorumPage + 1));
        } else if (
          tab == "Bookmarks" &&
          isBookmarkLoaded &&
          !isAllBookmarkLoaded
        ) {
          dispatch(setBookmarkLoading());
          dispatch(getBookmarkedPosts(user, currentBookmarkPage + 1));
        }
      }
    }
  };

  renderSwitch = username => {
    let {
      ud: { id, role, speciality, affiliation, bio, joined },
      casesAndQuorums,
      bookmarks
    } = this.props;
    let {
      isFollowing,
      followers,
      followings,
      totalCaseAndQuorum,
      totalBookmark
    } = this.props;
    let map_posts = casesAndQuorums.map(p => (
      <BookmarkCardView
        key={p.post_id}
        {...p}
        when={p.type === "quorum" ? "quorums" : "userPosts"}
      />
    ));

    let bookmark_posts = bookmarks.map(p => (
      <BookmarkCardView key={p.post_id} {...p} when="bookmarks" />
    ));
    const { tab } = this.state;
    const ExtraTopSection = this.getExtraTopSection(followings, followers);
    const TopRightSection = this.getTopRightSection(id, username, isFollowing);
    const ChangeAvatarSection = null;

    switch (tab) {
      case "Overview":
        return (
          <Overview
            about={bio}
            role={role}
            affiliation={affiliation}
            speciality={speciality}
            joined={joined}
            ud={this.props.ud}
            ExtraTopSection={ExtraTopSection}
            ChangeAvatarSection={ChangeAvatarSection}
            TopRightSection={TopRightSection}
          />
        );
      case "Cases":
        return (
          <React.Fragment>
            <div className="tab2-3">
              <MapPosts
                posts={map_posts}
                profile
                pageIndicator="Cases"
                waitForLoad={
                  this.props.casesAndQuorums.length == 0 &&
                  !this.props.isProfileLoaded
                }
                nothingMssg={
                  Me(id)
                    ? "You have no quorum cases"
                    : `${username} has no quorum cases`
                }
              />
            </div>
            {!this.props.isProfileLoaded && (
              <div className="profile-case-loader">
                <CaseStreamCardLoader />
              </div>
            )}
            {this.props.isAllCasesLoaded && map_posts.length > 0 && <End />}
          </React.Fragment>
        );
      case "Bookmarks":
        return Me(id) ? (
          <React.Fragment>
            <div className="tab2-3">
              <MapPosts
                posts={bookmark_posts}
                profile
                pageIndicator="Bookmarks"
                waitForLoad={
                  this.props.bookmarks.length == 0 &&
                  !this.props.isBookmarkLoaded
                }
                nothingMssg={
                  Me(id)
                    ? "You have no bookmarked cases"
                    : `${username} has no bookmarked cases`
                }
              />
            </div>
            {!this.props.isBookmarkLoaded && (
              <div className="profile-case-loader">
                <CaseStreamCardLoader />
              </div>
            )}
            {this.props.isAllBookmarkLoaded && bookmark_posts.length > 0 && (
              <End />
            )}
          </React.Fragment>
        ) : (
          <div className="tab2-3">
            <Nothing
              pageIndicator="Bookmarks"
              mssg={`${username}'s bookmarked cases are private`}
            />
          </div>
        );
      default:
        return null;
    }
  };

  getTopRightSection = (id, username, isFollowing) => {
    const comp = Me(id) ? (
      <Fragment>
        <PrimaryButton
          label="Edit Profile"
          extraClass="blue"
          onClick={this.toggleEditProfile}
        />
      </Fragment>
    ) : !Me(id) && isFollowing ? (
      <Unfollow
        user={id}
        unfollowed={() => this.toggle(false)}
        updateFollowers
      />
    ) : (
      <Follow
        userDetails={{ user: id, username }}
        followed={() => this.toggle(true)}
        updateFollowers
      />
    );
    return comp;
  };

  getExtraTopSection = (followings, followers) => {
    return (
      <div className="follow-area">
        <div className="following">
          <PrimaryButton
            label={`${followers} ${followers > 1 ? "Followers" : "Follower"}`}
            extraClass="blue"
            onClick={() => this.gotoSocialGraph("Followers")}
          />
        </div>
        <div className="following">
          <SecondaryButton
            label={`${followings} Following`}
            extraClass="blue"
            onClick={() => this.gotoSocialGraph("Following")}
          />
        </div>
      </div>
    );
  };

  getTabsSection = (id, totalCaseAndQuorum, totalBookmark) => {
    return (
      <Fragment>
        <span className="tab active" id="Overview">
          Overview
        </span>
        <span className="tab" id="Cases">
          Cases
          <span className="number">{totalCaseAndQuorum}</span>
        </span>
        {Me(id) && (
          <span className="tab" id="Bookmarks">
            Bookmarks
            <span className="number">{totalBookmark}</span>
          </span>
        )}
        <span className="tab" id="Final"></span>
      </Fragment>
    );
  };

  render() {
    let {
      match: {
        params: { username }
      },
      isFollowing,
      followers,
      followings,
      ud: { id },
      totalCaseAndQuorum,
      totalBookmark
    } = this.props;
    username =
      typeof username !== "undefined" ? username : this.props.session.username;

    // ExtraTopSection
    const ExtraTopSection = this.getExtraTopSection(followings, followers);

    // TopRightSection
    const TopRightSection = this.getTopRightSection(id, username, isFollowing);

    // ChangeAvatarSection
    const ChangeAvatarSection = null;

    // TabsSection
    const TabsSection = this.getTabsSection(
      id,
      totalCaseAndQuorum,
      totalBookmark
    );

    // MainContentSection
    const MainContentSection = this.renderSwitch(username);

    // TooltipSection
    const TooltipSection = <ToolTip place="top" type="dark" effect="solid" />;

    return (
      <React.Fragment>
        <ProfilePageLayout
          setRef={this.pageContainerRef}
          tab={this.state.tab}
          tabType="tab"
          updateState={this.updateState}
          ExtraTopSection={ExtraTopSection}
          ChangeAvatarSection={ChangeAvatarSection}
          TopRightSection={TopRightSection}
          TabsSection={TabsSection}
          MainContentSection={MainContentSection}
          TooltipSection={TooltipSection}
          when="mainProfile"
        />
        {this.state.showEditProfile ? (
          <EditProfilePopup toggleEditProfile={this.toggleEditProfile} />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => ({
  session: store.User.session,
  ud: store.User.user_details,
  mutuals: store.User.mutualUsers,
  isFollowing: store.Follow.isFollowing,
  isMobile: store.Setting.isMobile,
  followers: store.Follow.followers.length,
  followings: store.Follow.followings.length,
  casesAndQuorums: store.Post.casesAndQuorums,
  bookmarks: store.Post.bookmarks,
  currentCaseAndQuorumPage: store.Post.currentCaseAndQuorumPage,
  currentBookmarkPage: store.Post.currentBookmarkPage,
  isProfileLoaded: store.Post.isProfileLoaded,
  isBookmarkLoaded: store.Post.isBookmarkLoaded,
  isAllCasesLoaded: store.Post.isAllCasesLoaded,
  isAllBookmarkLoaded: store.Post.isAllBookmarkLoaded,
  totalCaseAndQuorum: store.Post.totalCaseAndQuorum,
  totalBookmark: store.Post.totalBookmark
});

export default connect(mapStateToProps)(withRouter(MainProfile));
