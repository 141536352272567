import React from "react";
import { withStyles } from "@material-ui/core";
import SlideBox from "./slidebox-page/slidebox";
import { loadCaseStreamModal } from "../../actions/slideBox";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const styles = {
  dialogPaper: {
    width: 300
  },
  checkboxRoot: {
    color: "#0b569f"
  }
};

class SlideBoxPage extends React.Component {
  // Set callback when using within modal
  onNextPage = selectedFiles => {
    //returns list of selected files
    this.props.loadCaseStreamModal(selectedFiles);
    this.props.goNext();
  };

  render() {
    return (
      // set onNextPage callback function when used on modal
      <SlideBox
        showUsageBar={this.props.showUsageBar}
        onNextPage={this.onNextPage}
        closeModal={this.props.closeModal}
        forQuorum={this.props.forQuorum}
        forSlidebox={this.props.forSlidebox}
        forMainPage={
          !this.props.forSlidebox &&
          !this.props.forQuorum &&
          !this.props.forQuorum
        }
        showCheckbox={this.props.showCheckbox}
        forTumor={this.props.forTumor}
        tumorBoardId={this.props.tumorBoardId}
        showShared={typeof this.props.showShared !== "undefined" ? false : true}
        when={this.props.when}
        showStreamsModal={this.props.showStreamsModal}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  loadCaseStreamModal: payload => dispatch(loadCaseStreamModal(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(SlideBoxPage)));
