import React, { Component } from "react";
import { FadeIn } from "animate-components";
import Title from "../../others/title";
import { connect } from "react-redux";
import { getUserDetails } from "../../../actions/user";
import { getPost } from "../../../actions/post";
import { getUnreadNotifications } from "../../../actions/notification";
import { getUnreadMessages } from "../../../actions/message";
import IsLoading from "../../others/isLoading";
import ShowPost from "./show-post";
import { cLoading } from "../../../utils/utils";
import { setActivePage } from "../../../actions/user";

class ViewPost extends Component {
  state = {
    loading: true
  };

  componentDidMount = () => {
    let {
      match: {
        params: { post_id }
      },
      dispatch
    } = this.props;
    dispatch(setActivePage("view-post"));
    post_id ? dispatch(getPost(post_id)) : null;
    window.scrollTo(0, 0);
    document.body.style.overflowY = "scroll"
    document.body.style.overflowX = "hidden"
  };

  componentWillUnmount = () => {
    document.body.style.overflow = "hidden"
  };

  componentWillReceiveProps = nextProps => {
    let {
      dispatch,
      userData: { username }
    } = this.props;
    if (
      nextProps.isLogoutUser !== this.props.isLogoutUser &&
      nextProps.isLogoutUser === "no"
    ) {
      dispatch(getUnreadNotifications());
      dispatch(getUnreadMessages());
      dispatch(getUserDetails(username));
    }
    this.setState({ loading: false });
  };

  render() {
    const post_id = this.props.post ? this.props.post.post_id : null;
    return (
      <div style={{ width: "100%" }}>
        <Title value="View post" />

        <FadeIn duration="300ms">
          <div className="main_view_container view_main_view_container home_main_view_container">
              <IsLoading
                when={"page"}
                loading={post_id ? false : this.props.post ? true : false}
              />
            <div className="stream_page_container">
              <div
                className={
                  "posts_div" +
                  cLoading(post_id ? false : this.props.post ? true : false)
                }
              >
                {post_id && (
                  <ShowPost
                    isLoggedUser={
                      this.props.isLogoutUser === "no" ? true : false
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </FadeIn>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  userData: store.User.session,
  post: store.Post.viewPost,
  isLogoutUser: store.User.isLogoutUser
});

export default connect(mapStateToProps)(ViewPost);
export { ViewPost as PureViewPost };
