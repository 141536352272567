import axios from "./axios-instance-utils";
import Notify from "handy-notification";
import {
  setAnnotionDataAction,
  updateAnnotionDataAction,
  deleteAnnotionDataAction,
  editAnnotionDataAction,
  publicAnnotationDataAction,
  toggleAnnotationDataAction,
  validateAnnotionDataAction,
  setSocketDataUpdateAction
} from "../actions/viewer";

export const setAnnotations = async options => {
  let {
    annotationData,
    slideId,
    featureId,
    dispatch,
    when,
    selectedFolderType,
    callback
  } = options;
  let {
    data: { success, mssg, insertId, insertData }
  } = await axios.post("/api/addAnnotation", {
    annotationData,
    slideId,
    featureId,
    selectedFolderType,
    when
  });

  if (success) {
    dispatch(
      updateAnnotionDataAction({
        ...annotationData,
        pk: insertId,
        slide_id: slideId,
        user_id: insertData.user_id,
        firstname: insertData.firstname,
        surname: insertData.surname,
        is_public: insertData.is_public,
        toggle: "on"
      })
    );
    callback();
  }
  when !== "quorum" && Notify({ value: mssg });
};

export const updateAnnotations = async options => {
  let { annotationData, slideId, dispatch, when, updateRedux = true } = options;
  let {
    data: { success, mssg, updateData }
  } = await axios.post("/api/updateAnnotation", {
    annotationData,
    slideId,
    when
  });
  if (success) {
    dispatch(
      editAnnotionDataAction({
        annotationData: {
          ...annotationData,
          slide_id: slideId,
          user_id: updateData.user_id,
          firstname: updateData.firstname,
          surname: updateData.surname,
          is_public: updateData.is_public,
          isValidate: updateData.isValidate
        },
        pk: annotationData["pk"]
      })
    );
  }

  //Notify({ value: mssg });
};

export const getAnnotations = async options => {
  let { slideId, dispatch, when, featureId, selectedFolderType } = options;
  let {
    data: { success, mssg, annotationData }
  } = await axios.post("/api/getAnnotations", {
    slideId,
    when,
    featureId,
    selectedFolderType
  });
  success ? dispatch(setAnnotionDataAction(annotationData)) : null;
};

export const deleteAnnotations = async options => {
  let { annotationId, dispatch, callback } = options;
  let {
    data: { success, mssg }
  } = await axios.post("/api/deleteAnnotation", { annotationId });
  success ? dispatch(deleteAnnotionDataAction(annotationId)) : null;
  Notify({ value: mssg });
  callback();
};

export const publishAnnotations = async options => {
  let { slide_id, status, user_id, dispatch } = options;
  let {
    data: { success, mssg }
  } = await axios.post("/api/publishAnnotation", {
    slideId: slide_id,
    status,
    when: "quorum"
  });
  success ? dispatch(publicAnnotationDataAction(options)) : null;
  Notify({ value: mssg });
};

export const toggleAnnotations = options => {
  let { dispatch } = options;
  dispatch(toggleAnnotationDataAction(options));
};

export const validateAnnotations = async options => {
  let { annotationIds, userId, dispatch } = options;
  let {
    data: { success, mssg }
  } = await axios.post("/api/validateAnnoation", { annotationIds });
  success ? dispatch(validateAnnotionDataAction(annotationIds)) : null;
  Notify({ value: mssg });
};

export const setSocketDataUpdateFlase = options => {
  let { dispatch } = options;
  dispatch(setSocketDataUpdateAction(options));
};
