import React from 'react'

const AnnotationUserPanel = ({ title, annotationUsers }) => {
  return (
    <div className="annotation-user-list-panel">
      <div className="annotation-user-list-panel-header">
        <div>
          {title}{' '}
          <img src="/images/img/information.svg" alt={'Annotation users'} />
        </div>
        {/* <span>SEE ALL</span> */}
      </div>
      <hr />
      <div className="annotation-user-list-panel-content">
        {annotationUsers.map((user, i) => (
          <img
            key={i}
            className="annotation-user-img"
            src={`/users/${user.user_id}/avatar.jpg`}
            alt={user.username}
            onError={e => {
              e.target.onerror = null
              e.target.src = '/images/default-avatar.svg'
            }}
            data-tip={user.username}
          />
        ))}
      </div>
    </div>
  )
}

export default AnnotationUserPanel
