import React from "react";
import Comment from "./comment";
import PropTypes from "prop-types";

const Comments = ({
  comments,
  decrementComments,
  allCommets,
  loadAllComments,
  when,
  childProps,
  session,
  isComment,
  isReply,
  post
}) => {
  let map_comments =
    comments.length > 0
      ? comments.map(c => (
          <Comment
            when={when}
            key={c.comment_id}
            {...c}
            decrementComments={decrementComments}
            childProps={childProps}
            session={session}
            isReply={isReply}
          />
        ))
      : null;

  let map_comments_latest =
    comments.length > 0
      ? comments
          .slice(comments.length - 1, comments.length)
          .map(c => (
            <Comment
              when={when}
              key={c.comment_id}
              {...c}
              decrementComments={decrementComments}
              childProps={childProps}
              session={session}
              isReply={isReply}
            />
          ))
      : null;

  return (
    <div>
      {allCommets ? (
        when == "viewPost" ? (
          <div className="comments_div">{map_comments}</div>
        ) : (
          <div className="comments_div">
            {map_comments}
            <span className="comments_showmore">
              {comments.length !== 0 && comments.length !== 1 && (
                <p
                  className="more_text"
                  onClick={e => loadAllComments(e, post)}
                >
                  HIDE ALL COMMENTS &nbsp;
                  <img
                    src="/images/img/angle-arrow-down-small.svg"
                    style={{ transform: "rotate(180deg)" }}
                  />
                </p>
              )}
            </span>
          </div>
        )
      ) : (
        <div className="comments_div">
          {map_comments_latest}
          <span className="comments_showmore">
            {comments.length !== 0 &&
              comments.length !== 1 &&
              (isComment ? (
                <p
                  className="more_text"
                  onClick={e => loadAllComments(e, post)}
                >
                  LOAD {comments.length} MORE {comments.length > 1 ? "COMMENTS": "COMMENT"} &nbsp;
                  <img src="/images/img/angle-arrow-down-small.svg" />
                </p>
              ) : (
                <p
                  className="more_text"
                  onClick={e => loadAllComments(e, post)}
                >
                LOAD {comments.length - 1} MORE {comments.length - 1 > 1 ? "COMMENTS": "COMMENT"} &nbsp;
                  <img src="/images/img/angle-arrow-down-small.svg" />
                </p>
              ))}
          </span>
        </div>
      )}
    </div>
  );
};

Comments.propTypes = {
  when: PropTypes.string.isRequired,
  comments: PropTypes.array,
  decrementComments: PropTypes.func
};

export default Comments;
