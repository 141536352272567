import React from 'react'
import Notify from 'handy-notification'
import axios from '../../../utils/axios-instance-utils'
import { connect } from 'react-redux'
import { deleteMessage } from '../../../actions/tumorBoards'
import Prompt from '../../others/prompt'
import DeleteIcon from '../icons/delete.svg';

const GroupDeleteMessage = ({ close, selectedBoard, toDelete, dispatch }) => {
  const deleteMsg = async () => {
    const { status, data } = await axios
      .put(
        '/api/tumorBoards/msgs/delete',
        { tumorBoardId: selectedBoard.id, msgId: toDelete },
        { withCredentials: true }
      )
      .catch(() => Notify({ value: 'Error while deleting message' }))
    if (status === 200) {
      dispatch(deleteMessage(toDelete))
      close()
      Notify({ value: 'Message deleted successfully' })
    }
  }

  return (
    <Prompt
      title="Delete message"
      content="Are you sure you want to delete that message? This action cannot be undone."
      actionText="Delete"
      action={deleteMsg}
      promptType="Cancel"
      img={DeleteIcon}
      alt="Delete"
      onClose={close}
    />
  ) 
}

const mapStateToProps = state => {
  const { selectedBoard } = state.TumorBoards
  return {
    selectedBoard,
  }
}

export default connect(mapStateToProps)(GroupDeleteMessage)
