import React, { useEffect, useState, useRef } from "react";
import axios from "../../../../utils/axios-instance-utils";
import MultiSelect from "../../../post/post-it/select-search";
import { connect } from "react-redux";
import CloseIcon from '../../../../assets/icons/close-new.svg'
import Notify from 'handy-notification'
const AttachItem = ({
  file,
  onRemoveFile,
  index,
  fileNameEditDone,
  uploadFileNameError,
  selectedUploadFolder,
  userId
}) => {
  const [fileNameState, setFileNameState] = useState(true);
  const [errorState, setErrorState] = useState(false);
  const [fileEditState, setFileEditState] = useState(false);
  const [changedFileNameState, setChangedFileNameState] = useState(null);
  const [selectedFileIndexState, setSelectedFileIndexState] = useState(null);
  const inputEl = useRef();
  let fileName = false;

  useEffect(() => {
    setFileEditState(false);
    const folderId = selectedUploadFolder
      ? selectedUploadFolder.id
      : sessionStorage.getItem("selectedFolderId");
    const data = {
      fileName: file.name,
      fileSavingFolder: folderId
    };
    axios
      .post("api/check-file-exists", data)
      .then(response => {
        if (response.data.msg === "success") {
          if (response.data.fileExits) {
            setFileNameState(
              response.data.fileExits && response.data.inSameFolder
            );
            setErrorState(
              response.data.fileExits && response.data.inSameFolder
            );
            if (response.data.inSameFolder) {
              onFileNameEdit(file, index);
              uploadFileNameError(true);
            } else {
              file["originalFileName"] = response.data.orgFileName;
              uploadFileNameError(false);
            }
          } else {
            file["originalFileName"] = file.name;
            setFileNameState(response.data.fileExits);
            setErrorState(response.data.fileExits);
            uploadFileNameError(false);
          }
        }
      })
      .catch(e => console.warn(e));
  }, [file, selectedUploadFolder]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [file, changedFileNameState]);

  const convertSize = size => {
    return parseFloat(size / 1024).toFixed(2) + " KB";
  };

  if (window.innerWidth < 767 && file.name.length >= 20) {
    fileName = file.name.replace(
      /([.\s\S]{10})([.\s\S]*)([.\s\S]{7})/g,
      "$1...$3"
    );
  }

  const onFileNameEdit = (file, index) => {
    setFileEditState(true);
    setChangedFileNameState(file.name);
    setSelectedFileIndexState(index);
  };

  const toggleInputName = changedName => {
    setChangedFileNameState(changedName);
  };

  const handleClickOutside = event => {
    if (
      (inputEl.current && inputEl.current.contains(event.target)) ||
      event.target.id === "file-edit-init-span" ||
      event.target.id === "file-edit-done-span" ||
      event.target.className === "attach-item-details" ||
      event.target.className === "back-button" ||
      event.target.className === "upload-button" ||
      event.target.className === "checkbox-container" ||
      (event.target.tagName === "INPUT" &&
        event.target.id !== "agree-to-term-of-service")
    ) {
      return false;
    } else {
      if (inputEl.current && parseInt(inputEl.current.id) === index) {
        fileNameEditDone(
          file,
          parseInt(inputEl.current.id),
          changedFileNameState
        );
      }
    }
  };

  const handleClickEditDone = (event, file, index) => {
    event.preventDefault();
    if(changedFileNameState!==""){
      fileNameEditDone(file, index, changedFileNameState);
    }
    else{
      Notify({value: 'Please enter the file name'})
    }
  };

  const handleSelectStainChange = ({ value }) => {
    file["stain"] = value;
  };

  return (
    <div className="attach-item px-4 py-3">
      <div className="attach-item-details ">
        {fileEditState && selectedFileIndexState === index ? (
          <input
            id={index}
            ref={inputEl}
            autoFocus={true}
            type="text"
            value={changedFileNameState}
            style={{ width: "175px" }}
            onChange={e => toggleInputName(e.target.value)}
          />
        ) : (
          <React.Fragment>
            <div className="attach-item-details-sec">
              <span className="attach-item-name">
                {fileName || file.name.length > 20
                  ? file.name.substring(0, 20) + "..."
                  : file.name}
              </span>
              {!fileNameState && !fileEditState && (
                <p className="attach-item-size">{convertSize(file.size)}</p>
              )}
            </div>
            {!fileNameState && !fileEditState && (
              <MultiSelect
                classname="stain-select"
                defaultValue={{ value: "H&E", label: "H&E" }}
                handleChange={handleSelectStainChange}
                type={"stains"}
                placeholder="Stains"
                customStyles={true}
                style={{ width: "100%", marginTop: 10 }}
              />
            )}
          </React.Fragment>
        )}
      </div>
      {fileEditState && errorState && (
        <div style={{ position: "absolute", marginLeft: "190px" }}>
          <span style={{ color: "red", fontSize: "11px" }}>
            File already exists in current folder. Rename it.
          </span>
        </div>
      )}
      {fileEditState && index === selectedFileIndexState ? (
        <span
          id="file-edit-done-span"
          className="file-name-edit"
          onClick={e => handleClickEditDone(e, file, index)}
        >
          <i class={"fa fa-check"}></i>
        </span>
      ) : (
        <span
          id="file-edit-init-span"
          className="file-name-edit"
          onClick={() => onFileNameEdit(file, index)}
        >
          <i class={"fa fa-pencil-alt"}></i>
        </span>
      )}

      <label
        className="checkbox-container"
        onClick={() => {
          onRemoveFile(index);
        }}
      >
        <img src={CloseIcon} />
      </label>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedUploadFolder: state.SlideBox.selectedUploadFolder
});

export default connect(mapStateToProps)(React.memo(AttachItem));
