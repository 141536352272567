import React, { useState } from "react";

const ThumbnailListPanel = ({
  title,
  tooltip,
  thumbnails,
  onThumbnailClick
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const onSelect = i => {
    onThumbnailClick(i);
    setSelectedIndex(i);
  };
  return (
    <div className="thumbnail-list-panel">
      <div className="thumbnail-list-panel-header">
        <div>
          {title}{" "}
          <img
            src="/images/img/information.svg"
            data-tip={tooltip ? tooltip : ""}
          />
        </div>
        {/* <span>SEE ALL</span> */}
      </div>
      <hr />
      <div className="thumbnail-list-panel-content">
        {thumbnails.map((thumbnail, i) => (
          <img
            src={thumbnail.thumbnail}
            onClick={() => onSelect(i)}
            style={selectedIndex === i ? { border: "solid 1px #4b4b4b" } : {}}
            data-tip={thumbnail.stain ? thumbnail.stain : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default ThumbnailListPanel;
