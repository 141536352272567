import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addTumorBoardEnable } from "../../../actions/slideBox";
import { setSelectedBoard, setSlideUrl } from "../../../actions/tumorBoards.js";
import axios from "../../../utils/axios-instance-utils";
import { Checkbox, withStyles } from "@material-ui/core";
import Overlay from "../../others/overlay";
import { FadeIn } from "animate-components";
import ModalHeader from "../../others/modal/modal-header";
import PrimaryButton from "../../others/button/primary-btn";
import Notify from "handy-notification";
import AddToGroup from "./icons/add-to-group.svg";

const styles = {
  dialogPaper: {
    width: 300
  },
  checkboxRoot: {
    color: "#0b569f !important"
  }
};

const GroupsSelection = ({
  myBoards,
  classes,
  history,
  dispatch,
  dialogOpen,
  onClose,
  selectedFile
}) => {
  const [selectedTumorBoard, setSelectedTumorBoard] = useState(null);
  const [tumorBoardIndex, setTumorBoardIndex] = useState(null);

  useEffect(() => {
  }, dialogOpen);

  const handleOnSelectBoard = (e, board, index) => {
    setTumorBoardIndex(index);
    setSelectedTumorBoard(e.target.checked ? board.id : null);
  };
  const handleOnAdd = () => {
    const selectedBoard = myBoards.find(
      board => board.id === selectedTumorBoard
    );
    selectedBoard.slide = [
      {
        dzi_data: selectedFile.dzi_data,
        dzi_url: selectedFile.dzi_url
      }
    ];
    selectedBoard.slideId = selectedFile.slide_box_id;
    setTumorBoardSlide(selectedBoard);
  };

  const setTumorBoardSlide = async folderPath => {
    let body = {
      tumorBoardId: folderPath.id,
      slide: JSON.stringify(folderPath.slide[0]),
      slideId: folderPath.slideId
    };
    const result = await axios
    .put("/api/tumorBoards/slidePath", body)
    .catch(err => {
      Notify({ value: err.response.data.message });
        return false;
      });
    if (typeof result.status !== "undefined" && result.status === 200) {
      Notify({ value: "File adding" });
      dispatch(
        setSlideUrl(
          { slide: folderPath.slide[0], slideId: folderPath.slideId },
          true
        )
      );
      folderPath.current = folderPath.slide.length - 1;
      dispatch(setSelectedBoard(folderPath));
      let getFileDataResult = await axios.post("/api/getFileData", {
        keyFolder: selectedFile.key_folder
      });
      if (getFileDataResult.status === 200) {
        let post_id = folderPath.id;
        let key_folder = getFileDataResult.data[0].key_folder;
        await axios.post("/api/updateSlidebox", {
          moduleName: "tumor_board",
          moduleId: post_id,
          filename: selectedFile.file_name,
          filePath: selectedFile.file_path,
          keyFolder: key_folder
        });
      }
      Notify({ value: "Slide added successfully" });
      history.push({
        pathname: `/tumor-boards`,
        state: {
          selectedGroupIndex: tumorBoardIndex
        }
      });
      dispatch(addTumorBoardEnable(false));
    }
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="customModal group-selection-modal prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            title='Add to group'
            img={AddToGroup}
            onClick={onClose}
            extraClass="addGroup"
          />
          <div className="modal_middle">
            {myBoards.map((board, index) => (
              <div className="group-item">
                <Checkbox
                  classes={{ checked: classes.checkboxRoot }}
                  checked={selectedTumorBoard === board.id}
                  onClick={e => handleOnSelectBoard(e, board, index)}
                />
                <div className="group-item-component">
                  <div className="group-img">
                    <img
                      className="icon-img"
                      src={
                        board.imgSrc
                          ? board.imgSrc
                          : "/images/icons/settings/tumor-board-default.jpg"
                      }
                      alt={board.name}
                      onError={ev =>
                      (ev.target.src =
                        "/images/icons/settings/tumor-board-default.jpg")
                      }
                    />
                  </div>
                  <div className="layout">
                    <div className="title">
                      <div className="leftside">
                        <h4>{board.name}</h4>
                      </div>
                    </div>
                    <div className="description">
                      <p>{`Admin: ${board.creator.firstname} ${board.creator.surname}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="modal_bottom">
            <PrimaryButton
              label="Add"
              onClick={handleOnAdd}
              disabled={!selectedTumorBoard}
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { isMobile, windowSize } = state.Setting;
  const { file } = state.TumorBoards;
  const { session, user_details } = state.User;
  const { myBoards } = state.TumorBoards;
  return {
    isMobile: isMobile || windowSize < 767,
    file,
    session,
    myBoards,
    usedS3FolderSize: user_details.s3_folder_size
  };
};

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(GroupsSelection))
);
