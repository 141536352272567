import React, { Component, Fragment } from 'react'
import { Me } from '../../../../../utils/utils'
import { isAdmin } from '../../../../../utils/admin-utils'
import { actionsInPost } from '../../../../../utils/post-utils'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MarkSpamIcon from './icons/mark-spam-icon.svg'

class SpamPostOption extends Component {
  state = {
    redirect: false,
  }

  markSpamClick = e => {
    e.preventDefault()
    let {
      postDetails: { post_id, post_status },
      dispatch,
    } = this.props
    actionsInPost({
      post_id,
      status: post_status === 'spam' ? false : true,
      typeValue: 'spam',
      dispatch,
    })
  }

  modalBack = () => {
    this.props.toggleOptions()
    this.setState({ deletePost: false })
  }

  render() {
    let {
      postDetails: { user, post_status },
    } = this.props
    return (
      <Fragment>
        {!Me(user) && (
          <li style={{ display: 'flex' }}>
            <a href="" onClick={this.markSpamClick}>
              <img
                src={MarkSpamIcon}
                alt="mark spam"
                style={{ width: 20, marginLeft: -10, marginRight: 10 }}
              />
              {post_status !== 'spam' ? 'Mark Spam' : 'Unspam Case'}{' '}
              {isAdmin() ? 'as admin' : null}
            </a>
          </li>
        )}
      </Fragment>
    )
  }
}

SpamPostOption.propTypes = {
  postDetails: PropTypes.shape({
    user: PropTypes.number.isRequired,
    post_id: PropTypes.number.isRequired,
    when: PropTypes.string.isRequired,
    case_slove: PropTypes.bool.isRequired,
  }).isRequired,
  toggleOptions: PropTypes.func.isRequired,
}

// export default connect()(CaseslovePostOption)
export default connect()(SpamPostOption)
export { SpamPostOption as PureSpamPostOption }
