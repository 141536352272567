import React from "react";

const PostItDetails = ({isMobile}) => {
  return (
    <div className="i_p_main p_details row" style={{ height: 200 }}>
      { !isMobile ? (
      <div className="i_p_ta col-sm-8 col-md-8 col-lg-8">
        <h5>Tips on getting quicker opinion</h5>
        <span>
          <i class="fa fa-check-square"></i>
          <div className="p_details_body">Remove all patient identifiers</div>
        </span>
        <span>
          <i class="fa fa-check-square"></i>
          <div className="p_details_body">
            Use lables to get attention from experts withing the field
          </div>
        </span>
        <span>
          <i class="fa fa-check-square"></i>
          <div className="p_details_body">
            Provide appropiate evidence with caption for case
          </div>
        </span>
      </div>)
      :     (<div className="i_p_ta col-sm-12 col-md-12 col-lg-12">
      <h5>Tips on getting quicker opinion</h5>
      <span>
        <i class="fa fa-check-square"></i>
        <div className="p_details_body">Remove all patient identifiers</div>
      </span>
      <span>
        <i class="fa fa-check-square"></i>
        <div className="p_details_body">
          Use lables to get attention from experts within the field
        </div>
      </span>
      <span>
        <i class="fa fa-check-square"></i>
        <div className="p_details_body">
          Provide appropriate evidence with caption for case
        </div>
      </span>
      </div>)}<div className="i_p_ta col-sm-4 col-md-4 col-lg-4 p_details_img_container">
        <div className="p_detials_img_container">
          <img
            src="../images/icons/color/icons8-image_filled.png"
            className="p_details_img left"
            alt="Filled"
          />
          <img
            src="../images/icons/color/icons8-pictures_folder.png"
            className="p_details_img right"
            alt="Folder"
          />
          <img
            src="../images/icons/color/icons8-remove_image_filled.png"
            className="p_details_img left"
            alt="Remove"
          />
          <img
            src="../images/icons/color/icons8-rescan_document_filled.png"
            className="p_details_img right"
            alt="Rescan"
          />
        </div>
      </div>
    </div>
  );
};

export default PostItDetails;
export { PostItDetails as PurePostItDetails };
