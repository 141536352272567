import React, { Component } from "react";
import { connect } from "react-redux";
import PrimaryButton from "../../../others/button/primary-btn";
import SecondaryButton from "../../../others/button/secondary-btn";
import Notify from 'handy-notification'

class Account extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      cpassword: "",
      email: ""
    };
  }
  change = (what, e) => this.setState({ [what]: e.target.value });
  update = e => {
    e.preventDefault();
    if(this.state.password === "" && this.state.cpassword === ""){
      Notify({value: "Password Fields Empty"})
      return
    }
    else if(this.state.password === "" || this.state.cpassword === ""){
      Notify({value: "Password Field Empty"})
      return
    }
    this.props.onDoneEditing(this.state);
    e.reset();
  };

  componentDidMount = () => {
    for (let key in this.props.ud) {
      this.state[key] != undefined &&
        this.setState({
          loading: false,
          ...this.props.ud
        });
    }
  };
  componentWillReceiveProps = ({ ud }) => {
    for (let key in ud) {
      this.state[key] != undefined &&
        this.setState({
          loading: false,
          ...ud
        });
    }
  };
  render() {
    let { username, email, password, cpassword } = this.state;

    return (
      <div className="edit-tab">
        <h3 className="label-styles">Edit Account</h3>
        <div className="info-box first-box">
          <label className="label">Username</label>
          <input
            type="text"
            name="username"
            maxLength="25"
            minLength="3"
            value={username}
            disabled
            onChange={e => this.change("username", e)}
          />
        </div>
        <div className="info-box">
          <label className="label">Email</label>
          <input
            type="text"
            name="email"
            maxLength="25"
            minLength="3"
            value={email}
            disabled
          />
        </div>
        <div className="info-box">
          <label className="label">Password</label>
          <input
            type="text"
            type="password"
            name="password"
            maxLength="25"
            minLength="3"
            placeholder="Password"
            value={password}
            onChange={e => this.change("password", e)}
          />
        </div>
        <div className="info-box last-box">
          <label className="label">Confirm</label>
          <input
            type="text"
            type="password"
            id="cpassword"
            name="cpassword"
            maxLength="25"
            minLength="3"
            placeholder="Confirm Password"
            value={cpassword}
            onChange={e => this.change("cpassword", e)}
          />
        </div>
        <div className="update-buttons">
          {
            this.props.label && <SecondaryButton
              label={this.props.label}
              onClick={this.props.onClose}
            />
          }
          <PrimaryButton
            onClick={this.update}
            extraClass="update-btn"
            label="Update"
          />
        </div>
        {/* <button onClick={this.update} className="update-btn" style={{ marginTop: '10px' }}>
          Update
        </button> */}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  ud: store.User.current_user_details
});

export default connect(mapStateToProps)(Account);

