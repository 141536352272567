import React from "react";
import Prompt from "../../others/prompt";

const StorageAlert = ({ acceptMessage, closeStorageAlert }) => {
  return (
    <React.Fragment>
      <Prompt
        title={"Storage limit exceeded"}
        content= {<p> You have used your free storage limit on Crosscope. Contact <a href="mailto:info@crosscope.com?subject=Request to extend Crosscope Storage&body=Hi, <br /> [Add your message here] <br /> <br />"> info@crosscope.com </a>  to extend your storage.</p>}
        action={acceptMessage}
        promptType={"alert"}
        actionText={"Ok"}
        back={closeStorageAlert}
      />
    </React.Fragment>
  );
};

export default StorageAlert;
