import GroupIcon from './icons/groups-icon.svg'
import SlideboxIcon from './icons/slidebox-icon.svg'
import SpacesIcon from './icons/spaces-icon.svg'
import StreamsIcon from './icons/streams-icon.svg'

const routes = [
  {
    url: '/',
    name: 'Streams',
    // img: StreamsIcon,
    tag: "streams img",
  },
  {
    url: '/slidebox',
    name: 'Slidebox',
    // img: SlideboxIcon,
    tag: "slidebox img"
  },
  {
    url: '/groups',
    name: 'Groups',
    // img: GroupIcon,
    tag: "groups img",
  },
  // {
  //   url: '/quorumAction',
  //   name: 'Quorum',
  //   // img: SpacesIcon,
  //   tag: "quorum img"
  // },
]

export default routes
