import React, { useState, useRef, useEffect, useMemo } from 'react'
import ReactQuill from 'react-quill'
import 'quill-mention'
import ReactDOMServer from 'react-dom/server'
import axios from '../../../utils/axios-instance-utils'
import UserCard from '../../tumor-boards/new-components/user-card'
import _ from 'lodash'

const MentioningQuill = ({
  placeholder = '',
  value = '',
  style = {},
  className = '',
  inputRef = useRef(null),
  onEnterPressed,
  onChange,
  onFocus,
  onBlur,
  onMentionedListChange,
}) => {
  const [mentionedList, setMentionList] = useState([])
  useEffect(() => {
    onMentionedListChange && onMentionedListChange(mentionedList)
  }, mentionedList)
  const mentionModule = useMemo(
    () => ({
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ['@'],
      renderItem: item => {
        const user = item.user
        const rendered = ReactDOMServer.renderToString(
          <UserCard user={user} addSelf={() => {}} />
        )
        return rendered
      },
      source: async (searchTerm, renderList) => {
        const list = await searchUsers(searchTerm)
        renderList(list)
      },
      onSelect: (item, insertItem) => {
        insertItem(item)
      },
      showDenotationChar: false,
    }),
    []
  )
  let searchRequestCanceller
  const searchUsers = async term => {
    searchRequestCanceller && searchRequestCanceller()
    const config = {
      cancelToken: new axios.CancelToken(cancel => {
        searchRequestCanceller = cancel
      }),
    }
    try {
      const { status, data } = await axios.get(
        `/api/tumorBoards/users?filter=${encodeURI(term)}`,
        {
          ...config,
          withCredentials: true,
        }
      )
      if (!(status === 200 && typeof data.userList !== 'undefined')) {
        return []
      }
      const res = data.userList.map(user => ({
        id: user.id,
        value: `<a href='/profile/${user.username}' style='text-decoration: none;'>${user.firstname} ${user.surname}</a>`,
        name: `${user.firstname} ${user.surname}`,
        user: user,
      }))
      return res
    } catch (err) {
      return []
    }
  }
  const handleChange = (value, delta) => {
    if (onChange) onChange(value)
    const charCode =
      delta.ops[delta.ops.length - 1].insert &&
      delta.ops[delta.ops.length - 1].insert.charCodeAt &&
      delta.ops[delta.ops.length - 1].insert.charCodeAt(0)
    if (charCode === 10 && onEnterPressed) {
      // submit
      // remove enter character
      inputRef.current.editor.deleteText(
        inputRef.current.editor.getSelection().index,
        1
      )
      onEnterPressed(inputRef.current.getEditorContents(), mentionedList)
      return
    }
    // check mentioning
    let toCheck, pos
    if (_.get(delta, 'ops[1]')) {
      toCheck = _.get(delta, 'ops[1]')
      pos = _.get(delta, 'ops[0].retain')
    } else {
      toCheck = _.get(delta, 'ops[0]')
      pos = 0
    }
    if (_.get(toCheck, 'insert.mention')) {
      const lastMention = mentionedList[mentionedList.length - 1]
      if (lastMention && lastMention.pos === pos) {
        // do replace, this will happen on slow connection
        lastMention.userId = _.get(toCheck, 'insert.mention.id')
      } else {
        const newMention = {
          userId: _.get(toCheck, 'insert.mention.id'),
          pos,
        }
        mentionedList.push(newMention)
      }
      setMentionList(mentionedList)
    }
    if (toCheck.delete) {
      const lastMention = mentionedList[mentionedList.length - 1]
      if (!lastMention) return
      if (pos === lastMention.pos) {
        // remove mention
        mentionedList.splice(mentionedList.length - 1)
        setMentionList(mentionedList)
      }
    }
  }
  return (
    <ReactQuill
      value={value}
      onChange={handleChange}
      theme="snow"
      style={style}
      className={className}
      placeholder={placeholder}
      modules={{ mention: mentionModule }}
      ref={inputRef}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

export default React.memo(MentioningQuill)
