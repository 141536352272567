import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import Followers from "./components/Followers/Followers";
import Followings from "./components/Followings/Followings";
import { getUserDetails, resetUser } from "../../actions/user";
import { getUserStats } from "../../actions/follow";
import ProfilePageLayout from './components/ProfilePageLayout.js';
import PrimaryButton from '../others/button/primary-btn'

class SocialGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "Followers",
      ud: props.ud,
      user: null

    };
  }

  componentWillMount() {
    let {
      match: {
        params: { username }
      },
      dispatch,
    } = this.props;
    const user =
      typeof username !== "undefined" ? username : this.props.session.username;
    const currentTab = this.props.history.location.state
      ? this.props.history.location.state.tab
      : "Followers";
    this.setState({ tab: currentTab });
    dispatch(resetUser());
    dispatch(getUserStats(user));
    dispatch(getUserDetails(user));
  }

  updateState = (obj) => {
    this.setState(obj);
  }

  renderSwitch = () => {
    const { tab } = this.state;
    let {
      match: {
        params: { username }
      }
    } = this.props;
    switch (tab) {
      case "Following":
        return <Followings param={username} />;
      case "Followers":
        return <Followers param={username} />;
    }
  };

  loadProfile = () => {
    const { history } = this.props;
    const urlSplitedData = history.location.pathname.split("/");
    const userName = urlSplitedData[urlSplitedData.length - 1];
    this.props.history.push(`/profile/${userName}`);
  };

  getTopRightSection = () => {
    return (
      <PrimaryButton
        label="Profile"
        extraClass="blue"
        onClick={() => this.loadProfile()}
      />
    )
  }

  getTabsSection = (followers, followings) => {
    return (
      <Fragment>
        <span className="tab active" id="Followers">
          Followers <span className="number">{followers}</span>
        </span>
        <span className="tab" id="Following">
          Following
          <span className="number">
            {followings}
          </span>
        </span>
        <span className="tab" id="Final">
        </span>
      </Fragment>
    )
  }

  render() {
    let {
      followers,
      followings
    } = this.props;
    // ExtraTopSections
    const ExtraTopSection = null;

    // TopRightSection
    const TopRightSection = this.getTopRightSection();

    // ChangeAvatarSection
    const ChangeAvatarSection = null;

    // TabsSection
    const TabsSection = this.getTabsSection(followers, followings);

    // MainContentSection
    const MainContentSection = this.renderSwitch();

    // TooltipSection
    const TooltipSection = null;

    return (
      <ProfilePageLayout
        setRef={null}
        tab={this.state.tab}
        tabType='tab'
        updateState={this.updateState}
        ExtraTopSection={ExtraTopSection}
        ChangeAvatarSection={ChangeAvatarSection}
        TopRightSection={TopRightSection}
        TabsSection={TabsSection}
        MainContentSection={MainContentSection}
        TooltipSection={TooltipSection}
        SocialGraph
      />
    );
  }
}

const mapStateToProps = store => ({
  session: store.User.session,
  ud: store.User.user_details,
  followers: store.Follow.followers.length,
  followings: store.Follow.followings.length
});

export default connect(mapStateToProps)(withRouter(SocialGraph));
