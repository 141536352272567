import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import AddUserIcon from '../icons/invite.svg'
import axios from '../../../utils/axios-instance-utils'
import UserCard from './user-card'

const MemberInvitation = ({
  onMemberSelect,
  session,
  isCollaborator,
  filePath,
}) => {
  const [searching, setSearching] = useState('')
  const membersRef = useRef(null)
  const [timeout, setTimeoutState] = useState(undefined)
  const [results, setResults] = useState([])
  const [done, setDone] = useState(true)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => {
      setMounted(false)
      if (timeout) clearTimeout(timeout)
    }
  }, [])
  useEffect(() => {
    setResults([])
    setDone(false)
    clearTimeout(timeout)
    if (searching !== '') {
      setTimeoutState(setTimeout(search, 1000))
    }
  }, [searching])

  const addSelf = user => {
    onMemberSelect(user)
    setSearching('')
    membersRef.current.value = ''
  }
  const search = async () => {
    const apiUrl = !isCollaborator
      ? `/api/tumorBoards/users?filter=${encodeURI(searching)}`
      : `/api/listOfUserForCollaborator?filter=${encodeURI(
        searching
      )}&filePath=${encodeURI(filePath)}`
    const { status, data } = await axios.get(apiUrl, { withCredentials: true })
    if (status === 200 && typeof data.userList !== 'undefined' && mounted) {
      data.userList = data.userList.filter(u => u.id !== session)
      setResults(data.userList)
      setDone(true)
    }
  }

  return (
    <div className="members-input-wrapper">
      <img src={AddUserIcon} alt="user add" />
      <input
        type="text"
        placeholder="Enter User Name"
        onChange={() => {
          setSearching(membersRef.current.value.trim())
        }}
        ref={membersRef}
        spellCheck={false}
      />
      {searching !== '' && (
        <div
          className="usersSearch"
          id={results.length > 0 ? 'withResults' : 'noResults'}
        >
          {!done && <p className="infoText">Searching...</p>}
          {done && results.length === 0 && (
            <p className="infoText">Could not find any users.</p>
          )}
          {results.map((user, i) => (
            <UserCard user={user} key={i} addSelf={() => addSelf(user)} />
          ))}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  session: state.User.session.id,
})

export default connect(mapStateToProps)(MemberInvitation)
