/**
 * @author Crosscope
 *
 */

import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

// reducers
import User from "./reducers/User/User";
import Follow from "./reducers/Follow/Follow";
import Notification from "./reducers/Notification/Notification";
import Post from "./reducers/Post/Post";
import Explore from "./reducers/Explore/Explore";
import Group from "./reducers/Group/Group";
import Message from "./reducers/Message/Message";
import Setting from "./reducers/Setting/Setting";
import Hashtag from "./reducers/Hashtag/hashtag";
import TumorBoards from "./reducers/TumorBoards/TumorBoards";
import Viewer from "./reducers/Viewer/Viewer";
import SlideBox from "./reducers/SlideBox/SlideBox";
import Prompt from "./reducers/Prompt/Prompt";

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    state.User.user_details = undefined;
  }
  return appReducers(state, action);
};

const appReducers = combineReducers({
  User,
  Follow,
  Notification,
  Post,
  Explore,
  Group,
  Message,
  Setting,
  Hashtag,
  TumorBoards,
  Viewer,
  SlideBox,
  Prompt
});

const middlwares =
  process.env.NODE_ENV !== "production"
    ? applyMiddleware(thunk, logger)
    : applyMiddleware(thunk);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    location.pathname === "/login" && "User",
    "Follow",
    "Notification",
    "Post",
    "Explore",
    "Group",
    "Message",
    "Setting",
    "Hashtag",
    "TumorBoards",
    "Viewer",
    "Prompt"
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeEnhancers(middlwares)
);

export const persistor = persistStore(store);
