import React from 'react';
import { connect } from 'react-redux'

const JoinedItem = ({ type, firstName, surname, group }) => {
  let text
  switch (type) {
    case 'joined':
      text = 'has joined the group.'
      break;
    case 'removed':
      text = 'has been removed from group.'
      break;
    case 'left':
      text = 'has left the group.'
      break;
    case 'setAdmin':
      text = 'is now an Admin.'
      break;
    case 'removeAdmin':
      text = 'is no longer an Admin.'
      break;
  }
  return (
    <div className="joined-item">
      <p className="joined-name"><span>{`${firstName} ${surname}`}</span> {text}</p>
      {/* <p className="joined-group">{group.name}</p> */}
    </div>
  )
}

const mapStateToProps = state => {
  const { selectedBoard: group } = state.TumorBoards;
  return {
    group,
  };
};
export default connect(mapStateToProps)(JoinedItem)
