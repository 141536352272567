import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./home/home";
import EmailVerification from "./email-verification/email-verification";
import Notifications from "./notifications/notifications";
import ViewPost from "./post/view-post/view-post";
import Settings from "./settings/settings";
import AdminLogin from "./admin/admin-login";
import IsAdmin from "./admin/is-admin";
import Help from "./help/help";
import SearchPage from "./search/search-page";
import PostItMobile from "./post/post-it/post-it-mobile";
import ImageViewer from "./split-image-viewer/ImageViewer";
import QuorumPage from "./quorum/quorum-page";
import Error from "./error/error";
import SubmitMenu from "./submit-menu/SubmitMenu";
import TumorBoards from "./tumor-boards/TumorBoards";
import SlideBoxPage from "./slidebox-component/slidebox-page";
import UploadSlideboxFilesPage from "./slidebox-component/upload-files/upload-slidebox-files";
import LeftSidebar from "./others/left-sidebar/left-sidebar";
import QuorumAction from "./quorum/quorum-action";
import QuorumPageInit from "./quorum/quorum-page-init";
import MainProfile from "./profile/MainProfile";
import EditProfile from "./profile/EditProfile";
import SocialGraph from "./profile/SocialGraph";
import VersionPage from "./version";

const AppRoutes = ({ isMobile, collapseSidebar, isLoggedUser }) => {
  return (
    <div className="main-page-container">
      {isLoggedUser && (
        <React.Fragment>
          <LeftSidebar collapseSidebar={collapseSidebar} />
          <Switch>
            <Route
              exact
              path="/slidebox-new"
              render={props => <SlideBoxPage {...props} showUsageBar={true} />}
            />
            <Route
              exact
              path="/slidebox-new/upload"
              component={UploadSlideboxFilesPage}
            />
            <Route path="/" exact component={Home} />
            <Route
              key="profile"
              path="/profile/:username"
              render={props => (
                <MainProfile key={props.match.params.username} {...props} />
              )}
            />
            <Route path="/error/:what" component={Error} />
            <Route
              path="/email-verification/:is"
              component={EmailVerification}
            />
            <Route path="/notifications" component={Notifications} />
            <Route
              key="edit-profile"
              path="/edit-profile"
              component={EditProfile}
            />
            <Route path="/openpost/:post_id" component={ViewPost} />
            <Route path="/settings" component={Settings} />
            <Route path="/admin-login" component={AdminLogin} />
            <Route path="/is-admin" component={IsAdmin} />
            <Route path="/help" component={Help} />
            <Route path="/search" component={SearchPage} />
            <Route
              key="social-profile"
              path="/social-graph/:username"
              component={SocialGraph}
            />
            <Route
              path="/viewer"
              render={props => (
                <ImageViewer
                  {...props}
                  when="viewer"
                  showCollapsibleSidebar={true}
                />
              )}
            />
            {isMobile && <Route path="/submit-case" component={PostItMobile} />}
            {isMobile && <Route path="/submit-menu" component={SubmitMenu} />}
            <Route path="/tumor-boards" component={TumorBoards} />
            <Route path="/groups" component={TumorBoards} />
            <Route path="/quorum" exact component={QuorumPageInit} />
            <Route path="/quorumAction" exact component={QuorumAction} />
            <Route path="/quorum/:quorum_id" exact component={QuorumPage} />
            {isMobile && <Route path="/submit-case" component={PostItMobile} />}
            <Route
              path="/slidebox"
              render={props => <SlideBoxPage {...props} showUsageBar={true} />}
            />
            <Route path="/version" component={VersionPage} />
            <Route component={Error} />
          </Switch>
        </React.Fragment>
      )}
      {!isLoggedUser && (
        <React.Fragment>
          <Switch>
            <Route path="/openpost/:post_id" component={ViewPost} />
          </Switch>
        </React.Fragment>
      )}
    </div>
  );
};

export default AppRoutes;
