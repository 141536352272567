import React, { Fragment } from 'react'
import ToTags from '../../../hashtag/toTags/toTags'
import CommentTypeImage from './type-image'

const CommentType = ({ type, text, imgCommentSrc }) => {
  let noComment = {
    fontStyle: 'italic',
    display: 'block',
  }

  return (
    <Fragment>
      {// if no comment
      type == 'text' && !text ? (
        <span style={noComment}>Empty comment</span>
      ) : // if text comment
      type == 'text' ? (
        <p className="ce">
          <ToTags str={text} />
        </p>
      ) : // if image comment
      type == 'image' ? (
        <div>
          <CommentTypeImage imgCommentSrc={imgCommentSrc} />
          {text ? (
            <p className="ce" style={{ marginTop: 10 }}>
              <ToTags str={text} />
            </p>
          ) : null}
        </div>
      ) : // if sticker comment
      type == 'sticker' ? (
        <img className="comments_sticker" src={`/comments/${imgCommentSrc}`} />
      ) : // else nothing
      null}
    </Fragment>
  )
}

export default CommentType
