import React, { Component } from "react";
import AppLink from "../../../others/link/link";

export default class PostLableAndPostDes extends Component {
  render() {
    let {
      postDetails: { label_tags }
    } = this.props;

    return (
      <div>
        <div className="p_a row">
          <div className="p_abt col-lg-12">
            {label_tags.map((label, i) => (
              <span key={i} className="lable_tags">
                <AppLink to={`/search?tags=${label.labeltag}`}>
                  {label.labeltag}
                </AppLink>
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
