import React, { Component, Fragment } from "react";
import { isAdmin } from "../../../../../utils/admin-utils";
import ToolTip from "react-tooltip";
import { connect } from "react-redux";
import { Me } from "../../../../../utils/utils";
import PropTypes from "prop-types";
import EditCommentOption from "./edit";
import DeleteCommentOption from "./delete";

class CommentTools extends Component {
  state = {
    showOptions: false
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showOptions: false });
    }
  };

  toggleOptions = () => this.setState({ showOptions: !this.state.showOptions });

  render() {
    let {
      commentDetails: { comment_by },
      commentDetails,
      updateCommentText,
      decrementComments,
      when,
      isReply
    } = this.props;
    let { showOptions } = this.state;
    return (
      <Fragment>
        {(Me(comment_by) || isAdmin()) && (
          <span
            className={showOptions ? "toggle_options opened" : "toggle_options"}
            data-tip="Options"
            onClick={this.toggleOptions}
          >
            <img src="/images/img/more.svg" />
          </span>
        )}

        {(Me(comment_by) || isAdmin()) && showOptions && (
          <div className="options comments_options" ref={this.setWrapperRef}>
            <ul>
              <EditCommentOption
                commentDetails={commentDetails}
                updateCommentText={updateCommentText}
                toggleOptions={this.toggleOptions}
                when={when}
                isReply={isReply}
              />
              <DeleteCommentOption
                commentDetails={commentDetails}
                decrementComments={decrementComments}
                toggleOptions={this.toggleOptions}
                when={when}
                isReply={isReply}
              />
            </ul>
          </div>
        )}

        <ToolTip />
      </Fragment>
    );
  }
}

CommentTools.propTypes = {
  commentDetails: PropTypes.shape({
    comment_id: PropTypes.number.isRequired,
    comment_by: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    imgCommentSrc: PropTypes.string
  }).isRequired,
  decrementComments: PropTypes.func.isRequired,
  updateCommentText: PropTypes.func.isRequired
};

export default connect()(CommentTools);
export { CommentTools as PureCommentTools };
