import React, { useState } from "react";
import { Dialog, DialogContent, TextField } from "@material-ui/core";
import axios from "../../../utils/axios-instance-utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as slideBoxActions from "../../../actions/slideBox";
import Notify from "handy-notification";
import Overlay from "../../others/overlay";
import { FadeIn } from "animate-components";
import ModalHeader from "../../others/modal/modal-header";
import SecondaryButton from "../../others/button/secondary-btn";
import PrimaryButton from "../../others/button/primary-btn";
import RenameIcon from "./icons/rename.svg";

const CreateNewFolderPopUp = ({
  onClose,
  setSlideBoxLoading,
  handleAfterCreate,
  open,
  selectedFolder,
  rename,
  isFile,
  selectedFile,
  folderPath,
  userId
}) => {
  const [openDialog] = useState(open);
  const [fileOrFolderName, toggleFoldername] = rename
    ? isFile
      ? useState(selectedFile.file_name)
      : useState(selectedFolder.name)
    : useState("");
  const [nameError, setNameError] = useState();

  const checkNameError = name => {
    toggleFoldername(name);
    const regex = /\./g;
    if (!regex.test(name)) {
      setNameError();
    } else {
      setNameError(!isFile ? "Folder name should not contain [.]" : undefined);
    }
  };
  const handleSaveClick = () => {
    if (fileOrFolderName.trim() === "") {
      setNameError('Name should not be empty');
      return;
    }
    if (rename) {
      let newKey;
      if (isFile) {
        newKey = folderPath + "/";
      } else {
        newKey = fileOrFolderName;
      }
      const data = {
        oldKey: isFile ? folderPath : selectedFolder.folderId,
        newKey: newKey,
        isFile: false
      };

      if (isFile) {
        data.newFilename = fileOrFolderName;
        data.oldFilename = selectedFile.file_name;
        data.slideboxId = selectedFile.slide_box_id;
        data.isFile = true;
        data.moduleName = "slidebox";
        Notify({ value: "Renaming" });
        axios
          .post("/api/renameObjects", data)
          .then(() => {
            handleAfterCreate({
              rename,
              isFile,
              newName: data.newFilename
            }); // send new name for slidebox component to update
          })
          .catch(error => {
            //console.log('renameObjects', error);
            setSlideBoxLoading(false);
          });
        onClose();
      } else {
        data.folderToMove = `${data.oldKey}`;
        data.destinationFolder = `${data.newKey}/`;
        const folderData = {
          oldFolderId: data.oldKey,
          newFolderName: data.newKey
        };
        axios
          .post("/api/renameFolder", folderData)
          .then(() => {
            handleAfterCreate({
              rename: true,
              isFile: false,
              newName: fileOrFolderName
            });
          })
          .catch(error => {
            //console.log(error);
            setSlideBoxLoading(false);
          });
        onClose();
      }
    } else {
      let key = "";
      if (folderPath && folderPath !== "") {
        key =
          folderPath === "slidebox"
            ? folderPath + "/" + userId + "/"
            : folderPath;
      }
      Notify({ value: "Folder creating" });
      axios
        .post("/api/createFolder", {
          folderName: fileOrFolderName,
          parentFolder: sessionStorage.getItem("selectedFolderId")
        })
        .then(result => {
          handleAfterCreate(result);
        })
        .catch(error => {
          //console.log(error);
          setSlideBoxLoading(false);
        });
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            title={
              rename
                ? isFile
                  ? "Rename File"
                  : "Rename Folder"
                : "Create new folder"
            }
            onClick={() => onClose(false)}
            img={RenameIcon}
            alt="rename"
          />
          <div className="modal_middle" style={{ padding: "20px" }}>
            <div>
              <TextField
                disableUnderline={true}
                id="directoryNameTextField"
                InputProps={{
                  style: { fontSize: "14px" },
                  disableUnderline: true,
                }}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontFamily: "'Roboto', sans-serif",
                  color: '#4b4b4b'
                }}
                value={fileOrFolderName}
                onChange={e => checkNameError(e.target.value)}
                autoFocus
                placeholder={isFile ? "Enter file name" : "Enter folder name"}
              />
              {nameError && <p class="form-invalid-message">{nameError}</p>}
            </div>
          </div>
          <div className="prompt-bottom">
            <SecondaryButton
              label="Cancel"
              secondary
              onClick={() => onClose(false)}
            />
            <PrimaryButton
              label="Save"
              onClick={handleSaveClick}
              disabled={nameError}
            />
          </div>
        </FadeIn>

      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...slideBoxActions }, dispatch);

export default connect(null, mapDispatchToProps)(CreateNewFolderPopUp);
