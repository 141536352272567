import React, { Component } from 'react'
import TextComment from '../../comment/text-comment/text-comment'

class CommentBox extends Component {
  render() {
    let { childProps, session, comment_id, commentBoxId } = this.props
    return (
      <div className="p_cit">
        <div className="p_cit_img">
          <img src={`/users/${session}/avatar.jpg`}
          onError={e => {
            e.target.onerror = null
            e.target.src = '/images/default-avatar.svg'
          }}
          />
        </div>

        <div className="p_cit_main">
          <TextComment
            commentBoxId={commentBoxId}
            comment_id={comment_id}
            {...childProps}
          />
        </div>
      </div>
    )
  }
}

export default CommentBox
