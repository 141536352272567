import React from "react";
import emptyNotifiction from "../../assets/icons/empty-notification.svg";
import PropTypes from "prop-types";

const Nothing = props => {
  console.log({ props });
  return (
    <div className="home_last_mssg">
      {props.pageIndicator === "Notifications" && (
        <img src={emptyNotifiction} alt="emptyNotifiction" />
      )}
      {props.pageIndicator === "Search" ? (
        props.mssg
      ) : (
        <p>
          No new <span>{props.pageIndicator}</span>
          <br /> Any new {props.pageIndicator} will appear here
        </p>
      )}
    </div>
  );
};

Nothing.defaultProps = {
  mssg: "Hello, a message for you",
  showMssg: true,
  secondMssg: "",
  conPage: false,
  pageIndicator: "Notification"
};
Nothing.propTypes = {
  mssg: PropTypes.string,
  showMssg: PropTypes.bool,
  secondMssg: PropTypes.string,
  conPage: PropTypes.bool,
  pageIndicator: PropTypes.string
};

export default Nothing;
