import React, { Component, Fragment } from 'react'
import * as PostUtils from '../../../utils/post-utils'
import axios from '../../../utils/axios-instance-utils'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BookmarkIcon from '../icons/bookmark.svg'
import BookmarkedIcon from '../icons/bookmark-filled.svg'

class PostBookmark extends Component {
  state = {
    bookmarked: false,
  }

  componentDidMount = async () => {
    let {
        postDetails: { post_id },
      } = this.props,
      { data: bookmarked } = await axios.post('/api/bookmarked-or-not', {
        post: post_id,
      })
    this.setState({ bookmarked })
  }

  bookmark = async () => {
    let {
      postDetails: { post_id, when },
      dispatch,
      ud: { id },
    } = this.props
    PostUtils.bookmark({
      post_id,
      when,
      user: id,
      dispatch,
      done: () => this.setState({ bookmarked: true }),
    })
  }

  unbookmark = async () => {
    let {
      postDetails: { post_id, when },
      dispatch,
      ud: { id },
    } = this.props
    PostUtils.unbookmark({
      post_id,
      when,
      user: id,
      dispatch,
      done: () => this.setState({ bookmarked: false }),
    })
  }

  render() {
    let { bookmarked } = this.state
    let {
      postDetails: { bookmark_count },
      isMobile,
    } = this.props

    return (
      <Fragment>
        <div className="p_bmrk_wra">
          {bookmarked ? (
            <span
              className="p_bookmark undo_bookmark"
              data-tip={isMobile ? '' : 'Undo bookmark'}
              onClick={this.unbookmark}
            >
              <img src={BookmarkedIcon} style={{height: '17px'}}/>
              <span className="bookmark_count_span">
                {' '}
                {bookmark_count !== 0 && bookmark_count}{' '}
              </span>
            </span>
          ) : (
            <span
              className="p_bookmark do_bookmark"
              data-tip={isMobile ? '' : 'Bookmark'}
              onClick={this.bookmark}
            >
              <img src={BookmarkIcon} style={{height: '17px'}}/>
              <span className="bookmark_count_span">
                {' '}
                {bookmark_count !== 0 && bookmark_count}{' '}
              </span>
            </span>
          )}
        </div>
      </Fragment>
    )
  }
}

PostBookmark.propTypes = {
  postDetails: PropTypes.shape({
    post_id: PropTypes.number.isRequired,
    when: PropTypes.string.isRequired,
  }).isRequired,
}

const mapStateToProps = store => ({
  ud: store.User.user_details,
})

export default connect(mapStateToProps)(PostBookmark)
export { PostBookmark as PurePostBookmark }
