import axios from "./axios-instance-utils";
import Notify from "handy-notification";
import { addGroupPost, addUserPost } from "../actions/post";
import { insta_notify, Me, uData, wait } from "./utils";
import * as PostActions from "../actions/post";
import d from "./API/DOM";
import Action from "./API/Action";

export const validatePost = async options => {
  console.log(options)
  let { desc, labeltags } = options;
  // check mandatory fields
  if (!desc) throw new Error("Please add details");
  if (!labeltags || labeltags.length === 0)
    throw new Error("Please select one or more labels");
  return true;
};

/**
 * Add post
 * @param {Object} options Options for creating a new post
 * @param {Function} options.dispatch
 * @param {String} options.desc
 * @param {String} options.targetFile
 * @param {String} options.filter
 * @param {String} options.location
 * @param {String} options.type
 * @param {Number} options.group
 * @param {String} options.group_name
 * @param {Object[]} options.tags
 * @param {Number} options.tags.user
 * @param {String} options.tags.username
 */
export const addPost = async options => {
  let {
    dispatch,
    desc,
    targetFiles,
    filter,
    location,
    type,
    group,
    group_name,
    labeltags,
    fileType,
    slidebox,
    userData,
    mentionedUsers,
    poll,
    stainData
  } = options,
    user = Number(uData("session")),
    username = uData("username"),
    form = new FormData(),
    action = new Action(".p_post"),
    slide_id = slidebox.map(s =>
      s.module_name === "public" ? `PU` + s.slide_box_id : s.slide_box_id
    ),
    imgSrc = slidebox.map(s => {
      return { src: s.dzi_url };
    });
  // check mandatory fields
  if (!desc) throw new Error("Please add details");
  if (!labeltags || labeltags.length === 0)
    throw new Error("Please select one or more labels");

  action.start();
  wait();

  form.append("desc", desc);
  form.append("filter", filter);
  form.append("location", location);
  form.append("type", type);
  form.append("group", group);
  form.append("labeltags", JSON.stringify(labeltags));
  form.append("slide_id", JSON.stringify(slide_id));
  form.append("imgSrc", JSON.stringify(imgSrc));
  form.append("stainData", JSON.stringify(stainData));
  form.append("mentionedUsers", JSON.stringify(mentionedUsers));
  if (poll) form.append("poll", JSON.stringify(poll));
  if (fileType === "files") {
    form.append("isBlob", false);
  } else {
    form.append("isBlob", true);
  }
  if (targetFiles) {
    for (let i = 0; i < targetFiles.length; i++) {
      if (fileType === "file") {
        form.append("caseImages", targetFiles[i], targetFiles[i].name);
      } else {
        form.append(
          "caseImages",
          targetFiles[i].blob,
          targetFiles[i].image.split("/")[3]
        );
      }
    }
  }

  let {
    data: {
      success,
      mssg,
      post_id,
      firstname,
      surname,
      images,
      label_tags,
      affiliation,
      country,
      dzi_info,
      poll: createdPoll,
      postid_token
    }
  } = await axios.post("/api/post-it-new", form);
  // await post('/api/post-labeltags', { labeltags, post_id, user })

  if (success && post_id) {
    let newPost = {
      key: post_id,
      comments_count: 0,
      likes_count: 0,
      shares_count: 0,
      tags_count: 0,
      user: userData.id,
      username: userData.username,
      firstname: userData.firstname,
      surname: userData.surname,
      description: desc,
      filter,
      imgSrc: images,
      location,
      post_time: `${new Date().getTime()}`,
      post_id,
      group_id: 0,
      group_name: "",
      type: "user",
      label_tags,
      affiliation: userData.affiliation,
      country: userData.country,
      comments: [],
      is_followed: true,
      postid_token: postid_token,
      dzi_info,
      poll: createdPoll
    };

    type == "user"
      ? dispatch(
        addUserPost({
          ...newPost,
          when: "feed"
        })
      )
      : dispatch(
        addGroupPost({
          ...newPost,
          group_id: group,
          group_name,
          type: "group",
          when: "groupPosts"
        })
      );
  }

  action.end();
  Notify({ value: mssg });

  return success;
};

/**
 * Edit post
 *
 * @param {Object} options
 * @param {Number} options.post_id
 * @param {String} options.description
 * @param {Function} options.dispatch
 * @param {Function} options.done
 * @param {Function} options.failed
 */
export const editPost = async options => {
  let {
    post_id,
    description,
    dispatch,
    done,
    failed,
    when,
    valid_until,
    labelTags,
    mentionedUsers
  } = options;
  let {
    data: { success, mssg }
  } = await axios.post("/api/edit-post", {
    description,
    post_id,
    labelTags,
    mentionedUsers,
    when
  });
  let {
    data: { success_v, mssg_v }
  } = await axios.post("/api/edit-quorum-expire", { post_id, valid_until });

  if (success) {
    if (when === "feed") {
      dispatch(
        PostActions.editPostFeed({
          post_id,
          description,
          label_tags: labelTags
            ? labelTags.map(l => ({ labeltag: l }))
            : undefined
        })
      );
      done();
    } else if (when === "quorum") {
      dispatch(PostActions.editQuorum({ post_id, description }));
      dispatch(PostActions.editQuorumTime({ post_id, valid_until }));
    } else {
      dispatch(PostActions.editPost({ post_id, description }));
      done();
    }
  }

  Notify({ value: when === "quorum" ? "Quorum updated" : mssg });
};

export const editQuorumTime = async options => {
  let { post_id, valid_until, dispatch, done } = options;
  let {
    data: { success, mssg }
  } = await axios.post("/api/edit-quorum-expire", { valid_until, post_id });

  if (success) {
    dispatch(PostActions.editQuorumTime({ post_id, valid_until }));
    Notify({ value: "Quorum updated" });
    done();
  } else {
    Notify({ value: "error" });
  }
};

/**
 * Deletes a post
 * @param {Object} options
 * @param {Number} options.post_id
 * @param {String} options.when
 * @param {Function} options.dispatch
 * @param {Function} options.redirect
 */
export const deletePost = async options => {
  let { post_id, when, dispatch, redirect, isQuorum } = options;

  wait();

  let {
    data: { success, mssg }
  } = await axios.post("/api/delete-post", { post: post_id, isQuorum });

  if (success) {
    dispatch(PostActions.deletePost(post_id));
    when == "viewPost" ? redirect() : redirect();
  }

  Notify({ value: mssg });
};

/**
 * Like post
 * @param {Object} options
 * @param {Number} options.post_id
 * @param {Number} options.user
 * @param {Function} options.done
 */
export const like = async options => {
  let { post_id, user, done } = options;
  let {
    data: { success, mssg }
  } = await axios.post("/api/like-post", { post: post_id });

  if (success) {
    !Me(user)
      ? insta_notify({
        to: user,
        type: "like",
        post_id
      })
      : null;

    done();
  } else {
    Notify({ value: mssg });
  }
};

/**
 * Unlikes a post
 * @param {Object} options
 * @param {Number} options.post_id
 * @param {Function} options.done
 */
export const unlike = async options => {
  let { post_id, done } = options;
  let {
    data: { success, mssg }
  } = await axios.post("/api/unlike-post", { post_id });
  success ? done() : Notify({ value: mssg });
};

/**
 * Bookmarks a post
 * @param {Object} options
 * @param {Number} options.post_id
 * @param {Function} options.done
 */
export const bookmark = async options => {
  let { post_id, when, user, dispatch, done } = options;
  let {
    data: { success, mssg }
  } = await axios.post("/api/bookmark-post", { post_id });

  // success ? done() : null
  // Notify({ value: mssg })
  if (success) {
    if (when == "feed") {
      dispatch(PostActions.bookmarkFeed(post_id));
      Notify({ value: "Post bookmarked" });
    }
    if (when == "viewPost") {
      dispatch(PostActions.bookmark(post_id));
      Notify({ value: "Post bookmarked" });
    }
    done();
  } else {
    Notify({ value: mssg });
  }
};

/**
 * Unbookmark post
 * @param {Object} options
 * @param {Number} options.post_id
 * @param {String} options.when
 * @param {Number} options.user
 * @param {Function} options.dispatch
 * @param {Function} options.done
 */
export const unbookmark = async options => {
  let { post_id, when, user, dispatch, done } = options;
  let session = uData("session");

  let {
    data: { success, mssg }
  } = await axios.post("/api/unbookmark-post", {
    post: post_id,
    user: session
  });

  if (success) {
    if (when == "feed") {
      dispatch(PostActions.unbookmarkFeed(post_id));
      Notify({ value: "Post unbookmarked" });
    }
    if (when == "viewPost") {
      dispatch(PostActions.unbookmark(post_id));
      Notify({ value: "Post unbookmarked" });
    }
    done();
  } else {
    Notify({ value: mssg });
  }
};

/**
 *  Share post
 * @param {Object} options
 * @param {Number} options.user
 * @param {Number} options.post_id
 * @param {Number} options.postOwner
 * @param {Function} options.done
 */
export const share = async options => {
  let { user, post_id, postOwner, done } = options;
  new d(".share_btn").blur();

  let {
    data: { mssg, success }
  } = await axios.post("/api/share-post", { share_to: user, post_id });

  if (success) {
    insta_notify({
      to: user,
      type: "share",
      post_id
    });
    !Me(postOwner)
      ? insta_notify({
        to: postOwner,
        type: "shared_your_post",
        post_id
      })
      : null;

    done();
  }

  Notify({ value: mssg });
};

/**
 * Unshare post
 * @param {Object} options
 * @param {Number} options.user
 * @param {Number} options.post_id
 * @param {Function} options.done
 */
export const unshare = async options => {
  let { user, post_id, done } = options;
  new d(".share_btn").blur();

  let {
    data: { success, mssg }
  } = await axios.post("/api/unshare-post", { unshare_to: user, post_id });

  success ? done() : null;
  Notify({ value: mssg });
};

export const casesolovePost = async options => {
  let { post_id, isSlove, when, dispatch, callback } = options;

  wait();
  let {
    data: { success, mssg }
  } = await axios.post("/api/case-slove", { post: post_id, isSlove });

  if (success) {
    if (when === "feed") {
      dispatch(PostActions.caseSlove({ post_id, isSlove }));
      callback();
    } else {
      dispatch(PostActions.caseSloveSingle({ post_id, isSlove }));
      callback();
    }
  }

  Notify({ value: mssg });
};

export const markAsReviewed = async options => {
  let { post_id, isQuorum, dispatch } = options;
  wait();
  let {
    data: { success, mssg }
  } = await axios.post("/api/mark-post-review", {
    postId: post_id,
    isQuorum: isQuorum
  });

  if (success) {
    const post_status = isQuorum ? "published" : "active";
    dispatch(
      PostActions.reviewedPost({
        post_id: post_id,
        post_status
      })
    );
  }

  Notify({ value: mssg });
};

export const markUserAsAllowed = async options => {
  let { user, dispatch } = options;
  wait();
  let {
    data: { success, mssg }
  } = await axios.post("/api/mark-user-allow", {
    userId: user
  });

  if (success) {
    dispatch(PostActions.allowPostOwner(user));
  }

  Notify({ value: mssg });
  return success;
};

export const actionsInPost = async options => {
  let { post_id, status, typeValue, dispatch } = options;
  wait();
  let {
    data: { success, mssg }
  } = await axios.post("/api/mark-as-spam", {
    post: post_id,
    typeValue,
    status
  });

  if (success) {
    if (typeValue === "spam") {
      const post_status = status ? "spam" : "action";
      dispatch(PostActions.caseAction({ post_id, post_status }));
    }
  }

  Notify({ value: mssg });
};

export const createPoll = async options => {
  let { post_id, question, expiredAt, options: pollOptions } = options;
  // console.log("post-utils createPoll", {
  //   post_id,
  //   question,
  //   expiredAt,
  //   pollOptions
  // });
};
