import React, { useState } from "react";
import { withRouter } from "react-router-dom";

const PostItQuorumAction = props => {

  const [quorumKey, setquorumKey] = useState('');

  const loadQuorumPage = (e) =>{
    e.preventDefault();
    props.hideQuorumPopup(true, true)
    props.history.push("/quorum");

  }

  const valueChange = e => setquorumKey(e.target.value);

  const loadQuorumPageByKey = async () => {
    let response = await fetch('/api/get-quorumid-bykey', {
      method: "POST",
      body: JSON.stringify({
        quorumKey
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    }).then(x => x.json());

    if(response.success){
        props.hideQuorumPopup(true, true)
        props.history.push(`/quorum/${response.quorum_id}`)
    }
  };
  
  return (
    <div className="post-main-action" style={{ height: "auto" }}>
      <div className="create-quorum-section">
        <div className="create-quorum-section-wrapper"  onClick={loadQuorumPage}>
          <span> Create quorum</span>
          <img
            src="../images/icons/neutral/icons8-add_folder_2.png"
            alt="Filled"
          />
        </div>
      </div>
      <div className="join-quorum-section">
        <div className="join-quorum-section-wrapper">
          <span>Join quorum</span>
          <input className="search-quorum" placeholder="Enter CODE" onChange={valueChange}/>
          <span className="search-quorum-btn" onClick={()=>loadQuorumPageByKey()}>
             <i className="fas fa-caret-right"></i>
          </span>
          
        </div>
      </div>
    </div>
  );
};

export default withRouter(PostItQuorumAction);
