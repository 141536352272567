import React from 'react'
import ContentLoader from 'react-content-loader'

const CaseStreamLoader = () => (
  <ContentLoader
    speed={2}
    width={1180}
    height={675}
    viewBox="0 0 1180 675"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="7" y="40" rx="2" ry="2" width="371" height="50" />
    <rect x="1005" y="40" rx="0" ry="0" width="207" height="49" />
    <rect x="5" y="148" rx="0" ry="0" width="1180" height="551" />{' '}
  </ContentLoader>
)

export default CaseStreamLoader
