import React, { useState, useEffect } from "react";
import EyePngIcon from "../icons/open-eye.png";
import { connect } from "react-redux";
import { loadQuorum } from "../../../actions/slideBox";
import axios from "../../../utils/axios-instance-utils";
import Notify from "handy-notification";
import CloseIcon from "../icons/close-new.png";
import SplitImageAddIcon from "../icons/plus-icon.png";

const SlideThumbnail = ({
  slides,
  changeSlide,
  defultKeyFolder,
  loadFrom,
  when,
  highlightSlide,
  groupId,
  isAdmin,
  toggleThumbnailSlider
}) => {
  let [productListSteps, setproductListSteps] = useState(0);
  let [productAmount, setproductAmount] = useState(0);
  let productListWidth = 0;
  let listItemsRef = [];

  useEffect(() => {
    let productList = document.querySelector(".js-product-list");
    let tmpProductAmount = productAmount;
    listItemsRef.forEach((item, index) => {
      const selectedImage = sessionStorage.getItem("selectedSlide")
        ? sessionStorage.getItem("selectedSlide")
        : 0;
      if (selectedImage) {
        if (index === parseInt(selectedImage)) {
          item.style.opacity = "0.5";
        } else {
          item.style.opacity = "1";
        }
      } else {
        if (item.id.trim() === defultKeyFolder.trim()) {
          item.style.opacity = "0.5";
        }
      }

      tmpProductAmount++;
      productListWidth += 240;
      productList.style.width = productListWidth + "px";
    });
    setproductAmount(tmpProductAmount + 1);
  }, [slides]);

  const carouselNext = () => {
    let productAmountVisible = Math.floor(
      document.getElementById("viewer-carousel-content").clientWidth / 125
    );
    if (productListSteps < productAmount - productAmountVisible) {
      setproductListSteps(productListSteps + 1);
      moveProductList(productListSteps + 1);
    }
  };

  const carouselPrev = () => {
    if (productListSteps > 0) {
      setproductListSteps(productListSteps - 1);
      moveProductList(productListSteps - 1);
    }
  };

  const moveProductList = step => {
    let productList = document.querySelector(".js-product-list");
    productList.style.transform = "translateX(-" + 125 * step + "px)";
  };

  const loadClickedSlide = (e, keyFolder, itemIndex, slideId = null) => {
    listItemsRef.forEach((item, index) => {
      if (index === itemIndex) {
        item.style.opacity = "0.5";
        sessionStorage.setItem("selectedSlide", itemIndex);
      } else {
        item.style.opacity = "1";
      }
    });

    if (when === "groups" && groupId && isAdmin && isAdmin !== null) {
      const dataParams = {
        moduleName: "slidebox",
        showSlideThumb: false,
        fileId: slideId,
        isGroup: true,
        groupId: groupId
      };
      axios
        .post("api/getFileData", dataParams)
        .then(response => {
          const file = response.data[0];
          const body = {
            tumorBoardId: groupId,
            slide: JSON.stringify({
              dzi_data: file.dzi_data,
              dzi_url: file.dzi_url
            }),
            slideId: file.slide_box_id
          };
          axios.put("/api/tumorBoards/slidePath", body);
          Notify({ value: "Changing groups default slide" });
          this.props.loadQuorum({
            filename: file.file_name,
            fileid: file.slide_box_id,
            keyfolder: file.key_folder,
            slide: [
              {
                slide_box_id: file.slide_box_id,
                dzi_data: file.dzi_data,
                dzi_url: file.dzi_url,
                src: file.thumbnail,
                mimetype: "image/jpeg"
              }
            ],
            filePath: file.file_path
          });
        })
        .catch(e => console.warn(e));
    } else {
      changeSlide(keyFolder, slideId);
    }
  };
  return (
    <div
      id="viewer-carousel-content"
      className="carousel custom-viewer-carousel"
    >
      {when !== "groups" && (
        <div className="close-icon-container">
          <img
            src={CloseIcon}
            className="disable-slide-tray-icon"
            onClick={toggleThumbnailSlider}
          />
        </div>
      )}
      <div className="carousel__inner-content">
        <span
          className="carousel__control js-carousel-prev"
          onClick={carouselPrev}
        >
          <i className="glyphicon glyphicon-chevron-left icon" />
        </span>
        <span
          href="#"
          className="carousel__control js-carousel-next"
          onClick={carouselNext}
        >
          <i className="glyphicon glyphicon-chevron-right icon" />
        </span>
        <ul className="product-list js-product-list">
          {slides.map((slide, index) => (
            <li
              className="product-list__item"
              id={slide.key_folder}
              onClick={e =>
                loadClickedSlide(e, slide.key_folder, index, slide.slide_box_id)
              }
              ref={listItem => (listItemsRef[index] = listItem)}
            >
              {/* {defultKeyFolder !== slide.key_folder && (
                <div
                  className={"add-to-split-viewer"}
                  onClick={e => loadSplittedView(e, slide)}
                >
                  <img src={SplitImageAddIcon} />
                </div>
              )} */}
              <div data-slide={index + 1} className="product">
                <a className="slide-thumbnail-link">
                  <img
                    className={`slide-thumbnail-img ${
                      highlightSlide && highlightSlide == slide.slide_box_id
                        ? "highlight-slide"
                        : ""
                    }`}
                    src={slide.thumbnail}
                  />
                  <div className="slide-thumbnail-overlay">
                    <img className="overlay-icon" src={EyePngIcon} />
                  </div>
                </a>
                <span className="slide-stain">
                  {slide.stain ? slide.stain : " "}
                </span>
                <span
                  data-tip={slide.file_name ? slide.file_name : ""}
                  className="slide-name"
                >
                  {`${
                    slide.file_name && when && when != "groups"
                      ? slide.file_name.substring(0, 10) + "..."
                      : ""
                  }`}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  loadQuorum: payload => dispatch(loadQuorum(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideThumbnail);
