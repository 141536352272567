import React from "react";
import Route from "./components/route";
import routeConfig from "./routes-config";
import { withRouter } from "react-router-dom";

const LeftSidebar = ({ collapseSidebar, history }) => {
  const path = history.location.pathname.replace(/(?!^)(\W.+)/, "");
  return (
    <div
      id="main-left-sidebar"
      className={`left-sidebar-component sidebar-${
        collapseSidebar ? "collapse" : "open"
      }`}
    >
      <div
        className="fixed-container"
        style={{ width: `${fixedWidth(collapseSidebar)}` }}
      >
        {routeConfig.map((route, index) => {
          return (
            <Route
              key={index}
              {...route}
              activeUrl={path}
              collapse={collapseSidebar}
            />
          );
        })}
      </div>
    </div>
  );
};

const fixedWidth = collapseSidebar => {
  if (collapseSidebar) {
    return "71px";
  } else {
    return "250px";
  }
};

export default withRouter(LeftSidebar);
