import React from 'react'
import PropTypes from 'prop-types'
import MobileNavLink from './mobileNavLink'
import { uData } from '../../../utils/utils'

const MobileNavBar = ({ toggleOptions, user, currentUserData }) => {
  let clicked = () => toggleOptions()
  let id = uData('session')

  const logOut = e => {
    e.preventDefault()
    sessionStorage.removeItem('authToken')
    sessionStorage.removeItem('isLogin')
    window.location.href = '/logout'
  }

  return (
    <div className="mobile_leftside-bar">
      <div className="mobile_profile-block">
        <MobileNavLink
          url={`/profile/${user}`}
          className="nav_item"
          alt="Profile"
          label="Profile"
          iconClass="profileIcon"
        />
        <div className="navAvatar">
          <img
            src={`/users/${currentUserData.id}/avatar.jpg`}
            alt="avatar"
            className="navAvatar_header"
            onError={e => {
              e.target.onerror = null
              e.target.src = '/images/default-avatar.svg'
            }}
          />
          <span>
            {currentUserData.firstname + ' ' + currentUserData.surname}
          </span>
        </div>
      </div>
      <div className="profile-stats">
        <div className="nav_list" onClick={clicked}>
          <MobileNavLink
            url="/edit-profile"
            className="nav_item"
            alt="Edit Profile"
            label="Edit Profile"
            iconClass="editProfileIcon"
          />
        </div>
        <div className="nav_list" onClick={clicked}>
          <MobileNavLink
            url={`/social-graph/${user}`}
            className="nav_item"
            alt="Social Graph"
            label="Social Graph"
            iconClass="socialGraphIcon"
          />
        </div>
        <div className="nav_list" onClick={clicked}>
          <MobileNavLink
            url="/help"
            className="nav_item underline"
            alt="Help"
            label="Help"
            iconClass="helpIcon"
          />
        </div>
        <div className="nav_list" onClick={clicked}>
          <MobileNavLink
            url={`/`}
            className="nav_item"
            alt="Streams"
            label="Streams"
            iconClass="streams img"
          />
        </div>
        <div className="nav_list" onClick={clicked}>
          <MobileNavLink
            url={`/groups`}
            className="nav_item"
            alt="Groups"
            label="Groups"
            iconClass="groups img"
          />
        </div>
        <div className="nav_list" onClick={clicked}>
          <MobileNavLink
            url={`/quorumAction`}
            className="nav_item"
            alt="Quorum"
            label="Quorum"
            iconClass="quorum img"
          />
        </div>
        <div className="nav_list" onClick={clicked}>
          <MobileNavLink
            url={`/slidebox`}
            className="nav_item"
            alt="Slidebox"
            label="Slidebox"
            iconClass="slidebox img"
          />
        </div>
        <div className="nav_list logout_mobile_lefsidebar" onClick={clicked}>
          <a className="nav_item" alt="Settings" onClick={logOut}>
            <div className="logoutIcon"></div>
            <span>Logout</span>
          </a>
        </div>
      </div>
    </div>
  )
}

MobileNavBar.propTypes = {
  toggleOptions: PropTypes.func.isRequired,
}

export default MobileNavBar
