import React, { Component } from "react";
import { FadeIn } from "animate-components";
import { connect } from "react-redux";
import {
  editComment,
  editCommentFeed,
  editReply,
  editReplyFeed
} from "../../../../actions/post";
import axios from "../../../../utils/axios-instance-utils";
import Notify from "handy-notification";
import d from "../../../../utils/API/DOM";
import AddEmojis from "../../../others/emojis/add-emojis";
import PrimaryButton from "../../../others/button/primary-btn";
import SecondaryButton from "../../../others/button/secondary-btn";
import Overlay from "../../../others/overlay";
import { string, func, number } from "prop-types";
import ReactQuill from "react-quill";
import "quill-mention";
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import UserCard from "../../../tumor-boards/new-components/user-card";
import ClosePopupIcon from "../../../../assets/icons/close-new.svg";
import EditIcon from "../../../../assets/icons/edit.svg";

class EditComment extends Component {
  state = {
    ogComment: "",
    mentionedList: []
  };
  constructor(props) {
    super(props);
    this.mentionModule = {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ["@"],
      renderItem: (item, searchTerm) => {
        const user = item.user;
        const rendered = ReactDOMServer.renderToString(
          <UserCard user={user} addSelf={() => { }} />
        );
        return rendered;
      },
      source: async (searchTerm, renderList) => {
        const list = await this.searchUsers(searchTerm);
        renderList(list);
      },
      onSelect: (item, insertItem) => {
        insertItem(item);
      },
      showDenotationChar: false
    };
    this.inputRef = React.createRef();
  }

  componentDidMount = () => {
    let { comment } = this.props;
    this.setState({
      ogComment: comment
    });
    this.inputRef.current.editor.pasteHTML(0, comment);
  };

  descChange = (value, delta) => {
    let toCheck, pos;
    if (_.get(delta, "ops[1]")) {
      toCheck = _.get(delta, "ops[1]");
      pos = _.get(delta, "ops[0].retain");
    } else {
      toCheck = _.get(delta, "ops[0]");
      pos = 0;
    }
    if (_.get(toCheck, "insert.mention")) {
      const { mentionedList } = this.state;
      const lastMention = mentionedList[mentionedList.length - 1];
      if (lastMention && lastMention.pos === pos) {
        lastMention.userId = _.get(toCheck, "insert.mention.id");
      } else {
        const newMention = {
          userId: _.get(toCheck, "insert.mention.id"),
          pos
        };
        mentionedList.push(newMention);
      }
      this.setState({ mentionedList });
    }
    if (toCheck.delete) {
      const { mentionedList } = this.state;
      const lastMention = mentionedList[mentionedList.length - 1];
      if (!lastMention) return;
      if (pos === lastMention.pos) {
        mentionedList.splice(mentionedList.length - 1);
        this.setState({ mentionedList });
      }
    }
  };

  back = () => {
    let { back, updateComment } = this.props;
    updateComment(this.state.ogComment);
    back();
  };

  updateComment = async e => {
    e.preventDefault();
    new d(".e_p_update").addClass("a_disabled");
    let { post_id, comment_id, dispatch, back, when } = this.props;
    const comment = this.inputRef.current.getEditorContents();
    if (comment == "<p><br></p>") {
      Notify({ value: "Empty text" });
      return false;
    }
    const { mentionedList } = this.state;
    const mentionedUsers = mentionedList.map(u => u.userId);
    await axios.post("/api/edit-comment", {
      comment_id,
      comment,
      mentionedUsers
    });
    if (when === "feed") {
      dispatch(editCommentFeed({ post_id, comment_id, comment }));
    } else {
      dispatch(editComment({ comment_id, comment }));
    }
    this.props.updateComment(comment);
    Notify({ value: "Comment updated" });
    back();
  };

  updateReply = async e => {
    e.preventDefault();
    new d(".e_p_update").addClass("a_disabled");
    let { post_id, reply_id, comment_id, dispatch, back, when } = this.props;
    const comment = this.inputRef.current.getEditorContents();
    if (comment == "<p><br></p>") {
      Notify({ value: "Empty text" });
      return false;
    }
    await axios.post("/api/edit-reply", { reply_id, comment });
    if (when === "feed") {
      dispatch(editReplyFeed({ post_id, reply_id, comment_id, comment }));
    } else {
      dispatch(editReply({ reply_id, comment_id, comment }));
    }

    Notify({ value: "Reply updated" });
    back();
  };

  searchUsers = async term => {
    try {
      const { status, data } = await axios.get(
        `/api/tumorBoards/users?filter=${encodeURI(term)}`,
        {
          withCredentials: true
        }
      );
      if (!(status === 200 && typeof data.userList !== "undefined")) {
        return [];
      }
      const res = data.userList.map(user => ({
        id: user.id,
        value: `<a href='/profile/${user.username}' style='text-decoration: none;'>${user.firstname} ${user.surname}</a>`,
        name: `${user.firstname} ${user.surname}`,
        user: user
      }));
      return res;
    } catch (err) {
      return [];
    }
  };

  updateTextArea = emoji => {
    this.inputRef.current.editor.insertText(
      this.inputRef.current.getEditorSelection().index,
      emoji
    );
  };

  render() {
    let { ogComment } = this.state;

    return (
      <div>
        <Overlay />
        <div className="prompt edit_comment">
          <FadeIn duration="300ms">
            <div className="prompt-top">
              <div className="top-icon-and-text">
                <img
                  src={EditIcon}
                  alt="EditIcon"
                />
                <span className="prompt-title">
                  {this.props.isReply ? "Edit reply" : "Edit comment"}
                </span>
              </div>
              <img
                className="close-icon"
                src={ClosePopupIcon}
                onClick={this.back}
              />
            </div>

            <div className="e_p_middle modal_middle" style={{ padding: 20 }}>
              <ReactQuill
                onChange={this.descChange}
                style={{ border: "none" }}
                modules={{ mention: this.mentionModule }}
                ref={this.inputRef}
              />
            </div>

            <div className="prompt-bottom">
              <AddEmojis
                position={{
                  top: -30,
                  left: -217
                }}
                textArea=".e_c_textarea"
                updateTextArea={this.updateTextArea}
                recenterEmojis
              />
              <div className="actions-btn">
                <SecondaryButton label="Cancel" onClick={this.back} />
                <PrimaryButton
                  label={"Update"}
                  onClick={
                    this.props.isReply ? this.updateReply : this.updateComment
                  }
                  extraClass="prompt-done"
                />
              </div>
            </div>
          </FadeIn>
        </div>
      </div>
    );
  }
}

EditComment.propTypes = {
  comment: string.isRequired,
  comment_id: number.isRequired,
  back: func.isRequired,
  updateComment: func.isRequired
};

export default connect()(EditComment);
export { EditComment as PureEditComment };
