import Notify from 'handy-notification';
import { message } from '../Message/methods';

export const addTumorBoard = (boards, board) => {
  const betterBoard = {
    name: board.tumor_board_name,
    bio: board.tumor_board_bio,
    created: board.tumor_board_created,
    creator: { id: board.tumor_board_creator, firstname: board.firstname, surname: board.surname, username: board.username },
    id: board.tumor_board_id,
    imgSrc: board.tumor_board_img_src,
    slide: board.tumor_board_slide,
    type: board.tumor_board_type,
    admin: true
  }
  boards = [betterBoard, ...boards];
  boards.sort((a, b) => {
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
  });
  return boards;
}

export const setTumorBoards = (boards) => {
  const betterBoards = [];
  boards.forEach((board, i) => {
    betterBoards[i] = {
      name: board.tumor_board_name,
      bio: board.tumor_board_bio,
      created: board.tumor_board_created,
      creator: { id: board.tumor_board_creator, firstname: board.firstname, surname: board.surname, username: board.username },
      id: board.tumor_board_id,
      imgSrc: board.tumor_board_img_src,
      slide: board.tumor_board_slide,
      type: board.tumor_board_type,
      admin: board.tumor_board_member_admin === 1
    }
  })
  betterBoards.sort((a, b) => {
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
  });
  return betterBoards;
}

export const setAdmin = (userId, admin, members) => {
  return members.map((member) => {
    if(member.id !== userId) {
      return member;
    }
    return {
      ...member,
      admin
    }
  })
}

export const removeMember = (id, members) => {
  let _members = [...members];
  const toDelete = members.findIndex((member) => {
    if(member.id === id) return true;
  })
  if(toDelete !== -1) {
    _members = _members.slice(0, toDelete).concat(_members.slice(toDelete + 1, _members.length))
  }
  return _members;
}

export const deleteTB = (id, boards) => {
  let _boards = [...boards];
  const index = boards.findIndex((board) => {
    if(board.id === id) return true;
  })
  if(index !== -1) {
    _boards = _boards.slice(0, index).concat(_boards.slice(index + 1, _boards.length))
  }
  return _boards;
}

export const setInvitations = (invitations) => {
  return invitations.map((inv) => 
  ({
    id: inv.tumor_board_request_id,
    time: inv.tumor_board_request_time,
    sender: {
      email: inv.sender_email,
      firstName: inv.sender_firstname,
      surname: inv.sender_surname,
      username: inv.sender_username,
      id: inv.sender_id
    },
    board: {
      name: inv.tumor_board_name,
      id: inv.tumor_board_id
    },
    type: inv.tumor_board_request_type
  }));
}

export const setRequests = (requests) => {
  return requests.map((request) => 
  ({
    id: request.tumor_board_request_id,
    time: request.tumor_board_request_time,
    sender: { 
      email: request.not_member_email,
      surname: request.not_member_surname,
      firstName: request.not_member_firstname,
      username: request.not_member_username,
      id: request.not_member_id
    },
    board: {
      name: request.tumor_board_name,
      id: request.tumor_board_id
    },
    type: request.tumor_board_request_type
  }));
}

export const deleteInvitation = (id, invs) => {
  let _invs = [...invs];
  const index = invs.findIndex((inv) => {
    if(inv.id === id) return true
  })
  if(index !== -1) {
    _invs = _invs.slice(0, index).concat(_invs.slice(index + 1, _invs.length))
  }
  return _invs;
}

export const deleteRequest = (id, requests) => {
  let _requests = [...requests];
  const index = requests.findIndex((inv) => {
    if(inv.id === id) return true
  })
  if(index !== -1) {
    _requests = _requests.slice(0, index).concat(_requests.slice(index + 1, _requests.length))
  }
  return _requests;
}

export const addReq = (req, requests) => {
  return [...requests, {
    id: req.requestId,
    time: Date.now(),
    sender: {
      ...req.sender,
      firstName: req.sender.firstname
    },
    board: {
      name: req.tumorBoard.tumor_board_name,
      id: req.tumorBoard.tumor_board_id
    },
    type: req.type
  }]
}

export const addInv = (inv, invitations) => {
  return [...invitations, {
    id: inv.requestId,
    time: Date.now(),
    sender: {
      ...inv.sender,
      firstName: inv.sender.firstname
    },
    board: {
      name: inv.tumorBoard.tumor_board_name,
      id: inv.tumorBoard.tumor_board_id
    },
    type: inv.type
  }]
}

export const compareSelected = (selected, boards) => {
  const index = boards.findIndex((board) => {
    return (board.id === selected.id)
  })
  if(index === -1) {
    return null
  }
  return selected
}

export const setSlideImage = (payload, selectedBoard) => {
  const { slide , isPath } = payload;
  return {
    ...selectedBoard,
    slide
  };
}

export const deleteMessage = (id, messages) => {
  return messages.map((msg) => {
    if(msg.id !== id) return msg;
    else return {
      ...msg,
      status: 'deleted'
    }
  })
}

export const editMessage = (id, body, messages) => {
  return messages.map((msg) => {
    if(msg.id !== id) return msg;
    else return {
      ...msg,
      body,
      status: 'updated'
    }
  })
}