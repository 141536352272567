import React from 'react'

function PostSignupBanner(props) {
  return (
    <React.Fragment>
      <div className="login_message_shower">
        <div className="activity_container" style={{ marginTop: '10px' }}>
          <div className="display_text_signup">
            <span>Join this interesting case discussion by signing up </span>
            <span>for your FREE access account today</span>
          </div>
          <div className="register_link" style={{ textAlign: 'center' }}>
            <a href="/signup" className="signup_link_overlay">
              Sign Up
            </a>
          </div>
          <div className="register_link" style={{ textAlign: 'center' }}>
            <span style={{ fontSize: '10px' }}>
              Already have an account on Crosscope?
              <a href="/login" className="link_span">
                Sign In.
              </a>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostSignupBanner
