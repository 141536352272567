import React from "react";
import viewerContainer from "../containers/viewer";
import OpenSeadragonViewer from "./react-openseadragon-viewer";
import SlideThumbnail from "./slide-thumbnail";
import { withRouter } from "react-router-dom";
import ToolTip from "react-tooltip";
import "../utils/react-opeanseadragon-filter";
import { connect } from "react-redux";
import ViewerCollapsibleSidebar from "./collapsible-sidebar/viewer-collapsibe-sidebar";

// store.Setting.windowSize

class Viewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapsibleSidebar: true,
      viewer: null
    };
  }

  setViewerObject = viewer => {
    this.setState({ viewer: viewer });
  };

  toggleSidebar = () => {
    this.setState({
      showCollapsibleSidebar: !this.state.showCollapsibleSidebar
    });
  };

  render() {
    const { pathname } = this.props.history.location;
    return (
      <div
        className={`osd ${pathname === "/viewer" ? "osd-viewer-page" : ""}
      ${pathname === "/quorum" ||
            pathname === "/groups" ||
            pathname.split("/")[1] === "quorum"
            ? "osd-sub-viewer-page"
            : ""
          }
      ${(pathname === "/" || pathname.split("/")[1] === "openpost") &&
          "osd-viewer-case-page"}
      `}
      >
        {this.props.showCollapsibleSidebar && this.state.viewer && (
          <ViewerCollapsibleSidebar
            imageMetadata={this.props.imageMetadata}
            showCollapsibleSidebar={this.state.showCollapsibleSidebar}
            toggleSidebar={this.toggleSidebar}
            viewer={this.state.viewer}
          />
        )}
        <div className="row osd-row">
          <div
            className={`easer ${this.props.osdDisplay} ${this.props.viewerColumns}`}
          >
            <OpenSeadragonViewer
              {...this.props}
              currentPageId={this.props.currentPageId}
              setViewerObject={this.setViewerObject}
              showCollapsibleSidebar={this.state.showCollapsibleSidebar}
              toggleSidebar={this.toggleSidebar}
            />
          </div>

          {this.props.slideThumbData.length > 0 && (
            <React.Fragment>
              {this.props.when == "groups" && (
                <div
                  data-for="viewer-tooltip"
                  onClick={this.props.toggleThumbnailSlider}
                  data-tip={
                    this.props.thumbnailSliderShow
                      ? "Hide group history"
                      : "Show group history"
                  }
                  className={`slide-thumbnail-label ${this.props.thumbnailSliderShow
                    ? "thumbnail-show"
                    : "thumbnail-hide"
                    }`}
                >
                  Group History
                </div>
              )}
              {this.props.thumbnailSliderShow && (
                <SlideThumbnail
                  slides={this.props.slideThumbData}
                  changeSlide={this.props.changeSlide}
                  caseStremPostId={this.props.caseStremPostId}
                  defultKeyFolder={this.props.defultKeyFolder}
                  showSidebar={this.props.showSidebar}
                  loadFrom={this.props.loadFrom}
                  showCollapsibleSidebar={this.state.showCollapsibleSidebar}
                  collapsibleSidebarExist={this.props.showCollapsibleSidebar}
                  when={this.props.when}
                  highlightSlide={
                    this.props.when == "groups" && this.props.tumorBoardSlideId
                      ? this.props.tumorBoardSlideId
                      : null
                  }
                  groupId={this.props.groupId ? this.props.groupId : null}
                  isAdmin={this.props.groupAdmin ? this.props.groupAdmin : null}
                  toggleThumbnailSlider={this.props.toggleThumbnailSlider}
                />
              )}
            </React.Fragment>
          )}
        </div>
        <ToolTip id="viewer-tooltip" place="top" type="dark" effect="solid" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showSidebar: state.Setting.showSidebar
});

export default connect(mapStateToProps)(withRouter(viewerContainer(Viewer)));
