import React from 'react';
import NewChatIcon from '../icons/empty-panel-icon.svg';

const EmptyPanel = () => (
    <div className="empty-panel">
        <div className="content">
            <img src={NewChatIcon} alt="new chat" />
            <h3>
                Groups are great for getting opinions and staying in touch with
                just the people you want. Share static as well as whole slide images
                make plans, have conversations and more.
        </h3>
        </div>
    </div>
)

export default EmptyPanel;