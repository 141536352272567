import React, { useState, useEffect } from 'react'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
const SlideboxPagination = ({
  page = 1,
  onPageChange,
  perPage = 10,
  onPerPageChange,
  lastPage,
}) => {
  const [currentPage, setCurrentPage] = useState(page)
  const [currentPerPage, setCurrentPerPage] = useState(perPage)
  const [pageNo, setPageNo] = useState(page)
  useEffect(() => {
    setCurrentPage(page)
  }, [page])
  const pageChanged = e => {
    const value = e.target.text
    let page
    if (value === '<') {
      page = currentPage - 1
    } else if (value === '>') {
      page = currentPage + 1
    } else if (value === '<<') {
      page = 1
    } else if (value === '>>') {
      page = lastPage
    } else {
      page = value
    }
    setCurrentPage(page)
    setPageNo(page)
    onPageChange && onPageChange(page)
  }
  const handleSelect = e => {
    e = Number(e)
    setCurrentPerPage(e)
    onPerPageChange && onPerPageChange(e)
  }
  const onActionPress = (e) => {
    if (e.key === 'Enter' && pageNo > 0) {
      page = pageNo
      onPageChange && onPageChange(page)
    }
  }
  const handleInputChange = (e) => {
    if (e.target.value === "") {
      setPageNo("")
    }
    else {
      setPageNo(Number(e.target.value))
    }
  }
  return (
    <React.Fragment>
      <div className="pagination-perpage">
        <span>Per page</span>
        <Dropdown onSelect={handleSelect}>
          <Dropdown.Toggle variant="light">{currentPerPage}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={10}>10</Dropdown.Item>
            <Dropdown.Item eventKey={20}>20</Dropdown.Item>
            <Dropdown.Item eventKey={30}>30</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Pagination>
        {page != 1 && (
          <React.Fragment>
            <Pagination.Item onClick={pageChanged}>{'<<'}</Pagination.Item>
            <Pagination.Item onClick={pageChanged} key={currentPage - 1}>{'<'}</Pagination.Item>
          </React.Fragment>
        )}
        <Pagination.Item className="pageIndicator" key={currentPage} active={true}>
          <span><input type="text" value={pageNo} onChange={(e) => { handleInputChange(e) }} onKeyDown={(e) => onActionPress(e)} /> of {lastPage}</span>
        </Pagination.Item>
        {lastPage && page < lastPage && (
          <React.Fragment>
            <Pagination.Item onClick={pageChanged} key={currentPage + 1}>{'>'}</Pagination.Item>
            <Pagination.Item onClick={pageChanged}>{'>>'}</Pagination.Item>
          </React.Fragment>
        )}
      </Pagination>
    </React.Fragment>
  )
}
export default SlideboxPagination