import React, { useState, useEffect } from "react";
import GroupDetailsUser from "./group-details-user";
import { connect } from "react-redux";
import PrimaryButton from "../../../components/others/button/primary-btn";

const GroupDetails = ({
  group,
  onEdit,
  loggedUser,
  selectedMembers,
  changeActiveTab,
  onClickEmitter,
  footerNav
}) => {
  useEffect(() => {
    onClickEmitter.addListener("onGroupClick", target => {
      if (!target.getAttribute("ispopup")) {
        setUserPopupIndex(-1);
      }
    });
    return () => {
      onClickEmitter.removeAllListeners(["onGroupClick"]);
    };
  }, []);

  useEffect(() => {
    if (footerNav) {
      footerNav("middle");
    }
  }, []);

  const isCreator = group.creator === loggedUser;
  const isAdmin = group.admin;
  const addDefaultSrc = ev => {
    ev.target.src = "/images/icons/settings/tumor-board-default.jpg";
  };
  const [userPopupIndex, setUserPopupIndex] = useState(-1);
  const onUserOptionClick = index => {
    setUserPopupIndex(index);
  };
  const onTitleClick = () => {
    changeActiveTab("image");
  };
  return (
    <div className="group-selected">
      <div className="group-selected-content">
        <div className="header">
          <img
            src={
              group.imgSrc
                ? group.imgSrc
                : "/images/icons/settings/tumor-board-default.jpg"
            }
            alt="group icon"
            onError={addDefaultSrc}
          />
          <div className="header-description">
            <h3 className="title" onClick={onTitleClick}>
              {group.name}
            </h3>
            <p className="members">
              {" "}
              {group.members ? group.members.length : 0} {group.members && group.members.length > 1 ? "members" : "member"}
            </p>
          </div>
          {(isAdmin || isCreator) && (
            <div className="submit-button-wrapper" onClick={onEdit}>
              <PrimaryButton label="Edit" />
            </div>
          )}
        </div>
        <div className="about-content">
          <h3 className="title">About</h3>
          <div className="description">{group.bio}</div>
        </div>
        <div className="members">
          <div className="title">
            <h3>{group.members && group.members.length > 1 ? "Members" : "Member"}</h3>
            <p>{group.members ? group.members.length : 0}</p>
          </div>
          <div className="users">
            {selectedMembers
              ? selectedMembers.map((m, i) => (
                  <GroupDetailsUser
                    key={i}
                    member={m}
                    onOptionClick={() => onUserOptionClick(i)}
                    popupActive={userPopupIndex === i}
                    group={group}
                    changeActiveTab={changeActiveTab}
                  />
                ))
              : undefined}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const { session } = state.User;
  const { selectedMembers, onClickEmitter } = state.TumorBoards;
  return {
    loggedUser: session.id,
    selectedMembers,
    onClickEmitter
  };
};
export default connect(mapStateToProps)(GroupDetails);
