import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { uData } from '../../../utils/utils';
import Bell from '../../../assets/icons/bell.svg';
import DefaultAvatar from '../../../../dist/images/default-avatar.svg';
import pdfReaderImg from '../../../assets/icons/pdf-reader.svg';
import PDFViewer from '../../profile/components/PDFViewer';

const HeaderTopLinks = (props) => {
  let id = uData('session')
  let username = uData('username')

  const [openUserGuideModal, setOpenUserGuideModal] = React.useState(false);

  const handleUserGuideModalOpen = () => {
    setOpenUserGuideModal(!openUserGuideModal);
  };
  const handleUserGuideModalClose = () => {
    setOpenUserGuideModal(false);
  };


  return (
    <Fragment>
      <PDFViewer
        openModal={openUserGuideModal}
        onClose={handleUserGuideModalClose}
      />
      <IconButton
        color="info"
        aria-label="info"
        component="span"
        onClick={handleUserGuideModalOpen}
      >
        <img src={pdfReaderImg} alt="" />
      </IconButton>
      <IconButton
        color="info"
        aria-label="info"
        component="span"
      >
        <NavLink
          to="/notifications"
          activeClassName="ha_active"
          className="notification"
        >
          {
            props.un ? (
              <span className="notification_span nav_icon" data-badge={props.un}>
                <img className="bell-image" src={Bell} />
              </span>
            ) : (
              <span className="notification_empty_span nav_icon">
                <img className="bell-image" src={Bell} />
              </span>
            )
          }
        </NavLink>
      </IconButton >
      <a
        activeClassName="ha_active"
        className="sp"
        onClick={(e) => props.toggleOptions(e)}
      >
        <img
          src={`/users/${id}/avatar.jpg`}
          alt="avatar"
          className="sp_img"
          onError={e => {
            e.target.onerror = null
            e.target.src = DefaultAvatar
          }}
        />
      </a>
    </Fragment>
  )
}

export default HeaderTopLinks
