import React, { useState, useEffect, useContext, useRef } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { SocketContext } from "../../services/ServiceContext";
import { getQuorum } from "../../actions/post";
import { connect } from "react-redux";
import { CPP, updateQuorumValue } from "../../actions/post";
import { editPost } from "../../utils/post-utils";
import ReactQuill from "react-quill";
import ReactHtmlParser from "react-html-parser";
import DateTime from "react-datetime";
import MultiSelect from "../post/post-it/select-search";
import "react-quill/dist/quill.snow.css"; // ES6
import Viewer from "../image-viewer/ImageViewer";
import Notify from "handy-notification";
import { Slider } from "material-ui-slider";
import AddUserIcon from "./icons/add-user-icon.svg";
import CopyIcon from "./icons/clipboard.svg";
import ActiveDoc from "./icons/active-doc.svg";
import UnactiveImg from "./icons/photo.svg";
import EditIcon from "../../assets/icons/pencil.svg";
import Shareicon from "./icons/icons8-share.svg";
import axios from "../../utils/axios-instance-utils";
import UserAnnotationLabel from "./components/user-annotation-label";
import AdminAnnotationLabel from "./components/admin-annotation-label";
import AppLink from "../others/link/link";
import User from "./components/user";
import moment from "moment";
import Share from "../post/share/share/share";
import {
  publishAnnotations,
  toggleAnnotations,
  validateAnnotations
} from "../../utils/viewer-utils";
import { updateAnnotionDataInValidateAction } from "../../actions/viewer";
import { hidePrompt } from "../../actions/prompt";
import Prompt from "../../components/others/prompt";

const options = [];

const QuorumPannel = ({
  type,
  isQuorumImageLoad,
  quorumKey,
  isPostMine,
  setScreenShot,
  userId,
  quorum_id,
  isValidateDone,
  dispatch,
  quorum,
  isPublishInCase,
  setRemainingTime,
  dziImageUrl,
  post_time,
  user_details,
  annotationData,
  setSubmitQuorumStatus,
  allAnnotationData,
  publishAdminAnnotation,
  annotatedQuorum,
  isShowPrompt,
  setExpireTimeData,
  validUntil
}) => {
  const [expireTime, setExpireTime] = useState(
    type == "view" ? moment(parseInt(quorum.valid_until)) : null
  );
  const [shareBox, showShareBox] = useState(false);
  const [expirySet, setExpiryBool] = useState(type == "view" ? true : false);
  const [expiryParts, setExpiryParts] = useState([]);
  const [quorumEdit, setquorumEdit] = useState(false);
  const [searching, setSearching] = useState("");
  const [timeout, setTimeoutState] = useState(undefined);
  const [mounted, setMounted] = useState(false);
  const [members, setMembers] = useState([]);
  const [label_value, setLabelValue] = useState([]);
  const membersRef = useRef(null);
  const socket = useContext(SocketContext);
  const { description, user, label_tags, labeltags } = quorum;
  const { firstname, surname, country, id: user_id } = user_details;
  const desc = description;
  const [enabledAnnotation, setEnabledAnnotation] = useState(null);
  const [copytoClipboard, setCopytoClipboard] = useState(false);
  const [modifyAnnotation, setModifyAnnotation] = useState([]);
  const [modifyUserState, setModifyUserState] = useState([]);
  const [currentUserAnnoataionState, setCurrentUserAnnoataionState] = useState(
    []
  );

  const [publishQuorumProgress, setPublishQuorumProgress] = useState(false);

  useEffect(() => {
    const dataSet = allAnnotationData.reduce((r, a) => {
      r[a.user_id] = [...(r[a.user_id] || []), a];
      return r;
    }, {});

    const modifyAnnotationData = Object.keys(dataSet).map(key => {
      return {
        user_id: parseInt(key),
        firstname: dataSet[key][0]["firstname"],
        surname: dataSet[key][0]["surname"],
        slide_id: dataSet[key][0]["slide_id"],
        annotationIds: dataSet[key].map(annoData => annoData.pk),
        quorum_color: dataSet[key][0]["stroke"],
        is_public: dataSet[key][0]["is_public"],
        toggle: dataSet[key][0]["toggle"],
        isValidate: dataSet[key][0]["isValidate"]
      };
    });

    const modifyUserStateData = modifyAnnotationData.filter(
      userAnno => userAnno.user_id === userId
    );

    const currentUserAnnoataions = modifyAnnotationData
      .filter(userAnnotation => {
        if (userAnnotation.user_id === userId) {
          return true;
        }
      })
      .map(filterData => filterData.annotationIds)[0];

    setModifyAnnotation(modifyAnnotationData);
    setModifyUserState(modifyUserStateData);
    setCurrentUserAnnoataionState(currentUserAnnoataions);
  }, [allAnnotationData]);

  useEffect(() => {
    clearTimeout(timeout);
    if (searching !== "") {
      setTimeoutState(setTimeout(search, 1000));
    }
  }, [searching]);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    socket.onAnnotation().subscribe(req => {
      if (quorum_id === req.quorum_id && userId !== req.user_id) {
        dispatch(updateAnnotionDataInValidateAction(req.annotationData));
      }
    });
  }, []);

  const dp = (...args) => dispatch(updateQuorumValue(...args));

  const search = async () => {
    const {
      status,
      data
    } = await axios.get(
      `/api/tumorBoards/users?filter=${encodeURI(searching)}`,
      { withCredentials: true }
    );
    if (status === 200 && typeof data.userList !== "undefined" && mounted) {
      setResults(data.userList);
      setDone(true);
    }
  };

  const handleChange = value => {
    dp("description", value);
  };

  const handleClickOutside = event => {
    const element = document.getElementById("share-modal-box");
    if (element && !element.contains(event.target)) {
      showShareBox(false);
    }
  };

  let handleSelectChange = selectedOption => {
    let labelTags = selectedOption
      ? selectedOption.map(label => label.value)
      : [];
    //labelTags = labeltags.concat(labelTags)
    dp("labeltags", labelTags);
  };

  const calculateRemainingTime = (time, expireIn) => {
    const expireTime = expireIn ? parseInt(expireIn) : new Date().getTime();
    const msec = expireTime - new Date().getTime();
    const mins = Math.ceil(parseInt(msec) / 60000);
    const hrs = Math.ceil(parseInt(mins) / 60);

    let remaingMins = null;
    if (mins < 60) {
      remaingMins = 60 - mins;
    } else {
      remaingMins = 60 - (mins % 60);
    }

    let remaingHours = remaingMins !== 0 ? hrs : hrs;
    if (remaingHours > 0 || (remaingHours === 0 && remaingMins !== 0)) {
      remaingHours = remaingHours < 10 ? "0" + remaingHours : remaingHours;
      remaingMins = remaingMins < 10 ? "0" + remaingMins : remaingMins;
      return `${remaingHours} hours ${
        remaingMins === 60 ? "00" : remaingMins
      } minutes `;
    } else {
      return "0 hours ";
    }
  };

  // const setWrapperRef = node => {
  //   setWrapperref(node);
  // };

  const checkExpireData = expireIn => {
    const currentDate = parseInt(new Date().getTime());
    const expireDate = parseInt(new Date(expireIn).getTime());
    return currentDate < expireDate;
  };

  // const setRemainingTimeValue = value => {
  //   setExpireTime(value);
  //   setExpiryTime();
  // };

  const setExpiryTime = async newTime => {
    // newTime here is a Moment Object
    let time = newTime.valueOf();
    setExpireTime(newTime);
    setRemainingTime(newTime);
    if (type == "view" || checkExpireData(time)) {
      const dateFormat = newTime.format("LLLL");
      const parts = getCurrentTime(dateFormat);
      let part2;
      const part1 =
        parts[0].substring(0, 3) +
        " " +
        parts[0].split(" ")[1].split(",")[0] +
        ", " +
        parts[0].split(" ")[2];
      if (dateFormat.indexOf("AM") >= 0) {
        part2 = parts[1].split(" ")[0] + " AM";
      } else {
        part2 = parts[1].split(" ")[0] + " PM";
      }
      if (type == "view") {
        setExpiryParts([part1, part2]);
        setExpiryBool(true);
      } else {
        setTimeout(() => {
          setExpiryParts([part1, part2]);
          setExpiryBool(true);
        }, 1000);
      }
    } else {
      Notify({ value: "Expire date is already past" });
    }
  };

  const getPostTime = d => {
    const date = new Date(parseInt(d));
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    minutes = minutes ? minutes : "00"; //to deal with invalid date obj
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const getCurrentTime = d => {
    if (!d) d = new Date();
    var n = d.toString();
    var part2;
    var parts = n.substring(n.indexOf(" ")).split(" ");
    var part1 = parts[1] + " " + parts[2] + ", " + parts[3];
    var parts2 = parts[4].split(":");
    if (parts2[0] <= 12) {
      part2 = parts2[0] + ":" + parts2[1] + " AM";
    } else {
      part2 = parts2[0] - 12 + ":" + parts2[1] + " PM";
    }
    return [part1, part2];
  };

  const setCopytoClipboardValue = () => {
    Notify({ value: "Copied to Clipboard" });
  };

  const setBase64Data = async b64Data => {
    const contentType = "image/png";
    const blob = b64toBlob(b64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    return {
      blobUrl: blobUrl,
      blob: blob
    };
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(
      b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const getScreenShot = async screenshot => {
    const blobData = await setBase64Data(screenshot);
    setScreenShot(blobData);
  };

  const publishImage = async () => {
    setPublishQuorumProgress(true);
    await setSubmitQuorumStatus();
    const osdElement = document.getElementsByClassName("openseadragon-canvas");
    const canvas = osdElement[0].children[0];
    getScreenShot(canvas.toDataURL());
  };

  const handleShare = () => {
    showShareBox(true);
  };

  const initialTimeEdit = () => {};

  const UserCard = ({ user, addSelf, id }) => {
    return (
      user.id !== id && (
        <div className="userCard" onClick={() => addSelf()}>
          <img
            // TODO: remove absolute link
            src={`/users/${user.id}/avatar.jpg`}
            style={{
              width: "50px",
              height: "50px",
              margin: "5px",
              borderRadius: "25px"
            }}
            onError={e => {
              e.target.onerror = null;
              e.target.src = "/images/default-avatar.svg";
            }}
          />
          <div
            style={{
              height: "60px",
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px"
            }}
          >
            <p className="searchName">
              {(typeof user.firstname !== undefined
                ? user.firstname
                : user.firstName) +
                " " +
                user.surname}
            </p>
            <p className="searchName">@{user.username}</p>
          </div>
        </div>
      )
    );
  };

  const addSelf = user => {
    membersRef.current.value = "";
    if (checkUser(user)) {
      setMembers([user, ...members]);
      dp("quorumMembers", [user, ...members]);
    }
    setSearching("");
  };

  const handleEdit = () => {
    if (!quorumEdit) {
      setExpiryTime(moment(expireTime));
      let labelTags;
      if (!labeltags) {
        labelTags = label_tags.map(l => l.labeltag);
        dp("labeltags", labelTags);
      } else {
        labelTags = labeltags;
      }

      const label_value = labelTags.map(l => {
        return { label: l, value: l };
      });
      setLabelValue(label_value);
    }
    if (quorumEdit) {
      editPost({
        post_id: quorum.quorum_id,
        description,
        valid_until: expireTime.valueOf(),
        labelTags: labeltags,
        dispatch,
        when: "quorum",
        done: null,
        failed: null //this.returnOgDescription(),
      });
    }
    setquorumEdit(!quorumEdit);
  };

  const checkUser = toCheck => {
    const checked = members.filter(user => {
      if (user.id === toCheck.id) return user.id;
    });
    return checked.length === 0;
  };

  const activeSide = value => {
    const leftSide = document.getElementsByClassName("left-container")[0];
    const rightSide = document.getElementsByClassName("right-container")[0];
    const doc = document.getElementById("quorum-footer-doc");
    const img = document.getElementById("quorum-footer-img");
    switch (value) {
      case "left":
        leftSide.style.display = "block";
        rightSide.style.display = "none";
        doc.style.opacity = "1";
        img.style.opacity = "0.5";
        break;
      case "right":
        leftSide.style.display = "none";
        rightSide.style.display = "block";
        doc.style.opacity = "0.5";
        img.style.opacity = "1";
        break;
    }
  };

  const disableAnnotation = annotationIds => {
    const data = {
      annotationIds,
      onOrOff: "off",
      dispatch: dispatch
    };
    setEnabledAnnotation(null);
    toggleAnnotations(data);
  };

  const enableAnnotation = annotationIds => {
    const data = {
      annotationIds,
      onOrOff: "on",
      dispatch: dispatch
    };
    setEnabledAnnotation(user_id);
    toggleAnnotations(data);
  };

  const publishAnnotation = (slide_id, user_id, status) => {
    const data = {
      slide_id,
      user_id,
      status,
      dispatch: dispatch
    };
    publishAnnotations(data);
  };

  const validateUserAnnotations = () => {
    const data = {
      userId,
      annotationIds: currentUserAnnoataionState,
      dispatch: dispatch
    };

    validateAnnotations(data);
  };

  const closePrompt = () => {
    dispatch(hidePrompt());
  };

  const isValidDate = (currentDate, selectedDate) => {
    const today = DateTime.moment();
    return currentDate.isAfter(today);
  };

  let imgSrc =
    type === "create"
      ? userId
        ? `/users/${userId}/avatar.jpg`
        : "/images/default-avatar.svg"
      : quorum.user
      ? `/users/${quorum.user}/avatar.jpg`
      : "/images/default-avatar.svg";

  return (
    <React.Fragment>
      <div className="quorum-new-main-container">
        <div id="quorum-left-sidebar" className="left-container">
          {type === "create" && (
            <React.Fragment>
              <div className="title-box">
                <h2 className="heading">Quorum</h2>
                <CopyToClipboard
                  text={quorumKey}
                  onCopy={() => setCopytoClipboard(true)}
                >
                  <div
                    className="code-box"
                    onClick={setCopytoClipboardValue}
                    title="Copy to clipboard"
                  >
                    {quorumKey}
                    <img src={CopyIcon} />
                  </div>
                </CopyToClipboard>
              </div>
              <div className="quill-box">
                <ReactQuill
                  defaultValue={desc ? desc : ""}
                  onChange={handleChange}
                  theme="snow"
                  style={{ border: "none" }}
                />
              </div>
            </React.Fragment>
          )}
          {type === "create" && true && (
            <React.Fragment>
              <div className="tags-box">
                <h3 className="tags-h3">Topic tags</h3>
                <MultiSelect
                  classname="select_case"
                  value={null}
                  handleChange={handleSelectChange}
                  options={options}
                  placeholder="Add tags that best describe your post"
                  customStyles={true}
                />
              </div>

              <div className="privacy-box">
                <h3 className="tags-h3">Privacy Level</h3>

                <div className="share-case-stream">
                  <span>Share publicly to Streams</span>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={e => isPublishInCase && isPublishInCase(e)} //TODO: REPLACE WITH ACTUAL FUNCTION
                      defaultChecked={true}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="timeline-box">
                <h3 className="tags-h3">Timeline</h3>
                <span>Set time for your Quorum</span>
                <div className="time-select">
                  <div className="current-time">
                    <span className="date-segment">{getCurrentTime()[0]}</span>
                    <span className="date-segment">{getCurrentTime()[1]}</span>
                  </div>
                  <span className="time-text">to</span>
                  {expirySet ? (
                    <div
                      className="current-time"
                      style={{ cursor: "pointer" }}
                      onClick={() => setExpiryBool(false)}
                    >
                      <span className="date-segment">{expiryParts[0]}</span>
                      <span className="date-segment">{expiryParts[1]}</span>
                    </div>
                  ) : (
                    <div className="time-remaining">
                      <section>
                        <DateTime
                          closeOnSelect={true}
                          isValidDate={isValidDate}
                          onChange={value => setExpiryTime(value)}
                        />
                        {/* <span
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            padding: "10px 0"
                          }}
                          onClick={() => setExpiryTime()}
                        >
                          <i class="fa fa-check"></i>
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            padding: "10px 0"
                          }}
                          onClick={() => initialTimeEdit(false)}
                        >
                          <i class="fa fa-times"></i>
                        </span> */}
                      </section>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="guests-box">
                <h3 className="tags-h3">Guests</h3>
                <div className="guests-select">
                  <div className="members-input-wrapper">
                    <img
                      className="memeber-invite-img"
                      src={AddUserIcon}
                      alt="user add"
                    />
                    <input
                      type="text"
                      placeholder="Invite guest"
                      onChange={() => {
                        setSearching(membersRef.current.value.trim())
                      }}
                      ref={membersRef}
                      spellCheck={false}
                    />
                    {searching !== '' && (
                      <div
                        className="usersSearch"
                        id={results.length > 0 ? 'withResults' : 'noResults'}
                      >
                        {!done && <p className="infoText">Searching...</p>}
                        {done && results.length === 0 && (
                          <p className="infoText">Could not find any users.</p>
                        )}
                        {results.map((user, i) => (
                          <UserCard
                            user={user}
                            key={i}
                            addSelf={() => addSelf(user)}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div> */}

              {members.length > 0 && (
                <div className="users">
                  {members.map((member, i) => (
                    <User
                      key={i}
                      img={`/users/${member.id}/avatar.jpg`}
                      fullName={`${member.firstName || member.firstname} ${
                        member.surname
                      }`}
                    />
                  ))}
                </div>
              )}

              <div className="button-section">
                {dziImageUrl.slide && (
                  <button
                    className="publish-button"
                    onClick={!publishQuorumProgress && publishImage}
                    disabled={!publishQuorumProgress ? false : true}
                  >
                    {publishQuorumProgress ? "Publishing..." : "Publish"}
                  </button>
                )}
              </div>
            </React.Fragment>
          )}

          {type === "view" && (
            <React.Fragment>
              <div className="title-box">
                <h2 className="heading">Quorum</h2>

                <CopyToClipboard
                  text={quorumKey}
                  onCopy={() => setCopytoClipboard(true)}
                >
                  <div
                    className="code-box"
                    onClick={setCopytoClipboardValue}
                    title="Copy to clipboard"
                  >
                    {quorumKey}
                    <img src={CopyIcon} />
                  </div>
                </CopyToClipboard>
              </div>
              {isPostMine && quorumEdit ? (
                <div className="quill-box">
                  <ReactQuill
                    value={desc}
                    onChange={handleChange}
                    theme="snow"
                    style={{ border: "none" }}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <div className="description-box">
                    <img
                      src={imgSrc}
                      className="user-img"
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = "/images/default-avatar.svg";
                      }}
                    />
                    <p className="description">
                      <span className="username">
                        {quorum.firstname} {quorum.surname}
                      </span>
                      <span className="location">{country}</span>
                      <span className="time">{getPostTime(post_time)}</span>
                      <br />
                      {ReactHtmlParser(quorum.description)}
                    </p>
                  </div>
                  <div className="list-of-changed">
                    {labeltags
                      ? labeltags.map((label, i) => (
                          <span key={i} className="lable_tags">
                            <AppLink to={`/search?tags=${label}`}>
                              {label}
                            </AppLink>
                          </span>
                        ))
                      : label_tags.map((label, i) => (
                          <span key={i} className="lable_tags">
                            <AppLink to={`/search?tags=${label.labeltag}`}>
                              {label.labeltag}
                            </AppLink>
                          </span>
                        ))}
                  </div>

                  {modifyAnnotation
                    .filter(data => data.user_id === user)
                    .map((data, i) => (
                      <AdminAnnotationLabel
                        key={i}
                        isPostMine={isPostMine}
                        publishAnnotation={publishAnnotation}
                        enableAnnotation={enableAnnotation}
                        disableAnnotation={disableAnnotation}
                        enabledAnnotation={enabledAnnotation}
                        {...data}
                      />
                    ))}
                </React.Fragment>
              )}

              {quorumEdit ? (
                <MultiSelect
                  classname="select_case"
                  defaultValue={label_value}
                  handleChange={handleSelectChange}
                  options={options}
                  placeholder="Add tags that best describe your post"
                  customStyles={true}
                />
              ) : null}

              {quorumEdit ? (
                <div className="time-select" style={{}}>
                  <div className="current-time">
                    <span className="date-segment">
                      {getCurrentTime(post_time)[0]}
                    </span>
                    <span className="date-segment">
                      {getCurrentTime(post_time)[1]}
                    </span>
                  </div>
                  <span className="time-text">to</span>
                  {expirySet ? (
                    <div
                      className="current-time"
                      style={{ cursor: "pointer" }}
                      onClick={() => setExpiryBool(false)}
                    >
                      <span className="date-segment">{expiryParts[0]}</span>
                      <span className="date-segment">{expiryParts[1]}</span>
                    </div>
                  ) : (
                    <div className="time-remaining">
                      <section>
                        <DateTime
                          closeOnSelect={true}
                          isValidDate={isValidDate}
                          onChange={value => setExpiryTime(value)}
                        />
                        {/* <span
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            padding: "10px 0"
                          }}
                          onClick={() => setExpiryTime()}
                        >
                          <i class="fa fa-check"></i>
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            padding: "10px 0"
                          }}
                          onClick={() => initialTimeEdit(false)}
                        >
                          <i class="fa fa-times"></i>
                        </span> */}
                      </section>
                    </div>
                    // <DateTime onChange={setExpiryTime} />
                  )}
                </div>
              ) : (
                <p className="time-left-box">
                  {calculateRemainingTime(post_time, quorum.valid_until)}left
                </p>
              )}

              <div
                className="activity"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10
                }}
              >
                <div
                  className="activity-buttons"
                  style={{ display: "flex", position: "relative" }}
                >
                  {shareBox && (
                    <div>
                      <Share
                        postid_token={quorum.quorumid_token}
                        post={quorum.quorum_id}
                        slideThumbnailSrc={dziImageUrl.slide}
                        dziInfo={null}
                        title={description}
                        back={() => showShareBox(false)}
                        postOwner={user}
                        isQuorum={true}
                        feed={false}
                      />
                    </div>
                  )}
                  <button
                    className="edit-button share"
                    onClick={() => handleShare()}
                    style={{ marginRight: 15, background: "#222C50" }}
                  >
                    <img src={Shareicon} style={{ display: "none" }} />
                    <span>Share</span>
                  </button>

                  {isPostMine && (
                    <button
                      className="edit-button edit"
                      onClick={() => handleEdit()}
                    >
                      {/* <img src={EditIcon} style={{ display: "none" }} /> */}
                      <span>{quorumEdit ? "Save" : "Edit"}</span>
                    </button>
                  )}
                </div>
              </div>
              {/*
              <div className="annotations-view">
                <div className="top">
                  <i class="fas fa-tags tags-icon"></i>
                  <span>Annotations</span>
                  <i class="fas fa-chevron-up up-icon"></i>
                </div>
                <div className="bottom" style={{ display: "flex" }}>
                  <i class="fas fa-eye annotation-logo"></i>
                  <Slider
                    defaultValue={70}
                    style={{ width: "30%", marginLeft: "2%" }}
                    colorSecondary
                  />
                </div>
              </div> */}
              <div
                className="annotations-view second"
                style={{ marginTop: 0, flexGrow: 2 }}
              >
                <div className="top">
                  <i class="fas fa-user tags-icon"></i>
                  <span>User Annotations</span>
                  <i class="fas fa-chevron-up up-icon"></i>
                </div>
                <div className="bottom bottom-labels">
                  {modifyAnnotation
                    .filter(data => data.user_id !== user)
                    .map((data, i) => (
                      <UserAnnotationLabel
                        key={i}
                        enableAnnotation={enableAnnotation}
                        disableAnnotation={disableAnnotation}
                        enabledAnnotation={enabledAnnotation}
                        {...data}
                      />
                    ))}
                  <div
                    className={
                      calculateRemainingTime(post_time, quorum.valid_until) !==
                      "00:00"
                        ? modifyUserState.length === 0
                          ? "publish-quorum-active"
                          : !modifyUserState[0].isValidate
                          ? "publish-quorum-active"
                          : "publish-quorum-deactive"
                        : "publish-quorum-deactive"
                    }
                  >
                    {calculateRemainingTime(post_time, quorum.valid_until) !==
                    "00:00" ? (
                      <React.Fragment>
                        {modifyUserState.length === 0 ? (
                          <button
                            className="validate-btn"
                            title="Annotation already validated"
                            // onClick={() => annotatedQuorum()}
                          >
                            Validate Annotation
                          </button>
                        ) : !modifyUserState[0].isValidate ? (
                          <button
                            className="validate-btn"
                            onClick={() => validateUserAnnotations()}
                          >
                            Validate Annotation
                          </button>
                        ) : (
                          <button
                            className="validate-btn"
                            title="Annotation already validated"
                            // onClick={() => annotatedQuorum()}
                          >
                            Validate Annotation
                          </button>
                        )}
                      </React.Fragment>
                    ) : (
                      <span title="Quorum is expired">Expired</span>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <div className="right-container">
          {type === "view" || isQuorumImageLoad ? (
            <Viewer
              when={"quorum"}
              slideBoxId={
                dziImageUrl.slide ? dziImageUrl.slide[0].slide_box_id : null
              }
              type={type}
              quorum_id={quorum.quorum_id}
              setScreenShot={setScreenShot}
              postIsMine={isPostMine}
              postIsValidate={
                modifyUserState.length > 0 && modifyUserState[0].isValidate
                  ? true
                  : false
              }
            />
          ) : (
            dziImageUrl.slide && (
              <Viewer
                type={type}
                slideBoxId={
                  dziImageUrl.slide ? dziImageUrl.slide[0].slide_box_id : null
                }
                quorum_id={null}
                postIsMine={null}
                when={"quorum"}
                postIsValidate={
                  modifyUserState.length > 0 && modifyUserState[0].isValidate
                    ? true
                    : false
                }
              />
            )
          )}
        </div>
        <div className="quorum-footer">
          <div className="create-page" onClick={() => activeSide("left")}>
            <img src={ActiveDoc} alt="create" id="quorum-footer-doc" />
          </div>
          <div className="img-page" onClick={() => activeSide("right")}>
            <img
              src={UnactiveImg}
              alt="image"
              id="quorum-footer-img"
              style={{ opacity: "0.5" }}
            />
          </div>
        </div>
      </div>
      {isShowPrompt && (
        <Prompt
          title={`Thanks for sharing case on Crosscope`}
          content={`Your case is being reviewed by our moderators and will be visible to the community upon approval.`}
          actionText="Ok"
          action={closePrompt}
          promptType={"HideCancelButton"}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  session: state.User.session,
  postIt: state.Post.postIt,
  quorum: state.Post.viewQuorum,
  user_details: state.User.user_details,
  allAnnotationData: state.Viewer.annotationData,
  isShowPrompt: state.Prompt.isShowPrompt
});

export default connect(mapStateToProps)(QuorumPannel);
