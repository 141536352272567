import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Notify from "handy-notification";
import { editPostPollOptions } from "../../../actions/post";
import axios from "../../../utils/axios-instance-utils";

const PostPoll = ({ postId, poll, isOwner, isLoggedUser, when, dispatch }) => {
  const [largestPercentOptionIndex, setLargestPercentOptionIndex] = useState(0);
  const [voted, setVoted] = useState(false);
  const voteCount = poll.options
    ? poll.options.reduce((acc, cur) => {
        return acc + cur.count;
      }, 0)
    : 0;

  const recalculate = () => {
    options = poll.options || [];
    totalCount = options.reduce((acc, cur) => {
      return acc + cur.count;
    }, 0);
    options = options.map((option, i) => {
      const percent = Math.round((option.count / totalCount || 0) * 100);
      if (option.count > options[largestPercentOptionIndex].count) {
        setLargestPercentOptionIndex(i);
      }
      if (option.active && !voted) setVoted(true);
      return {
        ...option,
        percent
      };
    });
  };
  let options = poll.options || [];
  let totalCount = 0;
  recalculate();
  useEffect(() => {
    recalculate();
  }, [poll]);
  const onOptionClick = async i => {
    setVoted(true);
    let res;
    try {
      res = await axios.post(`/api/post_poll_options/${options[i].id}/vote`);
      dispatch(editPostPollOptions(postId, res.data, when));
    } catch (err) {
      Notify({ value: "Cannot vote without logging" });
    }
  };

  const daysLeft = () => {
    const today = new Date();
    const expireDate = new Date(poll.expiredAt);
    const oneDay = 1000 * 60 * 60 * 24;
    const daysLeft = Math.ceil(
      (expireDate.getTime() - today.getTime()) / oneDay
    );
    return daysLeft < 0 ? 0 : daysLeft;
  };

  const getTimeLeft = () => {
    let time = daysLeft() > 1 ? daysLeft() + " days left" : daysLeft();
    time = time === 1 ? getMinutes() : time === 0 ? "" : time;
    return time;
  };

  const getMinutes = () => {
    const today = new Date();
    const expireDate = new Date(poll.expiredAt);
    const oneMinute = 1000 * 60;
    const oneHour = 1000 * 60 * 60;
    const minutesLeft = Math.floor(
      (expireDate.getTime() - today.getTime()) / oneMinute
    );
    const hoursLeft = Math.floor(
      (expireDate.getTime() - today.getTime()) / oneHour
    );
    if (minutesLeft < 60) {
      return `${minutesLeft}${" "} minutes left`;
    } else {
      if (hoursLeft < 24) {
        return `${hoursLeft}${" "} hours left`;
      } else {
        return "1 day left";
      }
    }
  };

  return (
    <div
      className={`poll-container ${!isOwner &&
        !voted &&
        daysLeft() >= 1 &&
        "inprogress"}`}
    >
      {options.map((option, i) =>
        isOwner || voted || daysLeft() < 1 ? (
          <div class="poll-option">
            <div class="poll-option-content">
              {option.option}&nbsp;&nbsp;
              {option.active && <i class="far fa-check-circle"></i>}
              <div
                class="poll-option-progress"
                style={{
                  width: option.percent > 2 ? `${option.percent}%` : `2%`,
                  backgroundColor:
                    largestPercentOptionIndex === i ? "#0B569F1A" : "#6565651A"
                }}
              />
            </div>
            <span class="poll-option-percent">{option.percent}%&nbsp;</span>
          </div>
        ) : (
          <div class="poll-option" onClick={() => !voted && onOptionClick(i)}>
            <div class="poll-option-content">{option.option}</div>
          </div>
        )
      )}
      <div className="poll-bottom">
        <span className="vote-count">
          {voteCount}
          {voteCount === 1 ? `${" "}vote` : `${" "}votes`}
        </span>
        <span className="vote-expire-at">
          {getTimeLeft() != 0 ? `${" "}·${" "}${getTimeLeft()}` : ""}
        </span>
      </div>
    </div>
  );
};

export default connect()(PostPoll);
