import React, { Component } from "react";
import ToTags from "../../../hashtag/toTags/toTags";
import AppLink from '../../../others/link/link'

export default class PostLableAndPostDes extends Component {
  render() {
    let {
      postDetails: { description, label_tags },
    } = this.props;

    return (
      <div>
        <div className="p_a row">
          <div className="p_abt col-lg-12">
            {label_tags.map((label, i) => (
              <span key={i} className="lable_tags">
                <AppLink to={`/search?tags=${label.labeltag}`}>{label.labeltag}</AppLink>
              </span>
            ))}
          </div>
          <div
            className="p_abt_des col-lg-12"
          >
            <p>
              <span dangerouslySetInnerHTML={{__html: description}} />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
