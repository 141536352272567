import React, { Component } from "react";
import { FadeIn } from "animate-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import FollowingsList from "./FollowingsList";
import Title from "../../../others/title";
import { bottomScroll, cLoading } from "../../../../utils/utils";
import { getFollowings } from "../../../../actions/follow";
import MonHeader from "../../../others/m-on/mon-header";
import IsLoading from "../../../others/isLoading";
import FollowSectionEnd from "../../../others/follow/follow-section-end";

class Followings extends Component {
  state = {
    loading: true
  };

  componentDidMount = () => {
    let {
      dispatch,
      ud: { id }
    } = this.props;
    dispatch(getFollowings(id));
    bottomScroll();
  };

  componentWillReceiveProps = () => this.setState({ loading: false });

  componentWillMount = () => {
    let {
      dispatch,
      ud: { id }
    } = this.props;
    dispatch(getFollowings(id));
  };

  render() {
    let { followings, param: username } = this.props,
      { loading } = this.state,
      len = followings.length,
      map_followings = followings.map(f => (
        <FollowingsList key={f.follow_id} {...f} />
      ));

    return (
      <div className="following-box">
        <FadeIn duration="300ms">
          <IsLoading loading={loading} />

          <div
            className={classNames(
              "main_view_container",
              "pro_main_view_container",
              cLoading(loading)
            )}
          >
            <div
              className={classNames({
                m_div: len != 0,
                m_no_div: len == 0
              })}
            >
              {/* <MonHeader len={len} forWhat={'following'} /> */}

              <div className="m_wrapper">{len != 0 && map_followings}</div>
            </div>
          </div>

          <FollowSectionEnd len={len} loading={loading} when="followings" />
        </FadeIn>
      </div>
    );
  }
}

Followings.propTypes = {
  param: PropTypes.string.isRequired
};

const mapStateToProps = store => ({
  ud: store.User.user_details,
  followings: store.Follow.followings
});

export default connect(mapStateToProps)(Followings);
export { Followings as PureFollowings };
