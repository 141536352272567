import React, { Component } from "react";
import Toggle from "react-toggle";
import { connect } from "react-redux";

import { getNotificationSettings } from "../../../../actions/notification";
import { editProfileNotifications } from "../../services/edit-profile-utils";

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      post: true,
      comment: true,
      follow: true
    };
  }

  componentDidMount = () => {
    let { dispatch } = this.props;
    dispatch(getNotificationSettings());
  };

  componentWillReceiveProps = ({ notificationSettings }) => {
    if (notificationSettings && Object.keys(notificationSettings).length > 0) {
      this.setNotificationSetting(notificationSettings);
    }
  };

  setNotificationSetting(props) {
    this.setState(props);
  }

  changeNotifications = async (e, type) => {
    let { dispatch } = this.props;
    e.preventDefault();
    const data = {};
    data[type] = !this.state[type];
    await this.setState(data);
    editProfileNotifications({
      noticationSettings: this.state,
      callbackFn: getNotificationSettings,
      dispatch
    });
  };

  render() {
    return (
      <div className="edit-tab">
        <h3 className="label-styles">Set your notification preferences</h3>
        <div className="info-box">
          <label className="label notification-label">
            Post Notifications :
          </label>
          <div style={styles.toggle}>
            <Toggle
              defaultChecked={
                this.props.notificationSettings &&
                this.props.notificationSettings.post
              }
              checked={
                this.props.notificationSettings &&
                this.props.notificationSettings.post
              }
              onClick={data => this.changeNotifications(data, "post")}
            />
          </div>
        </div>
        <div className="info-box">
          <label className="label notification-label">
            Comment Notifications :
          </label>
          <div style={styles.toggle}>
            <Toggle
              defaultChecked={
                this.props.notificationSettings &&
                this.props.notificationSettings.comment
              }
              checked={
                this.props.notificationSettings &&
                this.props.notificationSettings.comment
              }
              onClick={data => this.changeNotifications(data, "comment")}
            />
          </div>
        </div>
        <div className="info-box">
          <label className="label notification-label">
            Follow Notifications :
          </label>
          <div style={styles.toggle}>
            <Toggle
              defaultChecked={
                this.props.notificationSettings &&
                this.props.notificationSettings.follow
              }
              checked={
                this.props.notificationSettings &&
                this.props.notificationSettings.follow
              }
              onClick={data => this.changeNotifications(data, "follow")}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  toggle: { display: "inline-block", verticalAlign: "baseline", marginLeft: 10 }
};

const mapStateToProps = store => ({
  notificationSettings: store.Notification.notificationSettings
});

export default connect(mapStateToProps)(Notifications);
