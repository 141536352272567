import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PostBookmark from "../../bookmark/post-bookmark";
import PostShare from "../../share/post-share";

import openIcon from "./icons/launch-icon.svg";
import starIcon from "./icons/star.svg";

class PostActionsNew extends Component {
  state = {
    likes_count: 0,
    shares_count: 0
  };

  incrementWhat = what => this.setState({ [what]: this.state[what] + 1 });
  decrementWhat = what => this.setState({ [what]: this.state[what] - 1 });

  componentDidMount = () => {
    let {
      postDetails: { likes_count, shares_count },
      isMobile
    } = this.props;
    this.setState({ likes_count, shares_count });
  };

  render() {
    let {
      postDetails: {
        post_id,
        user,
        when,
        is_followed,
        description,
        bookmark_count,
        postid_token,
        slideThumbnailSrc,
        type,
        dzi_info
      },
      commentBoxId,
      isMobile,
      isQuorum,
      openRate,
      isOwner,
      history
    } = this.props;

    let { likes_count, shares_count } = this.state;
    let childProps = {
      postDetails: {
        post_id,
        user,
        description,
        postid_token,
        slideThumbnailSrc,
        type,
        dzi_info
      },
      incrementWhat: this.incrementWhat,
      decrementWhat: this.decrementWhat,
      isMobile
    };

    return (
      <div className="p_a_container">
        <div className="p_a">
          <div className="p_do">
            <PostShare
              {...childProps}
              isQuorum={isQuorum}
              toggleOptions={this.toggleOptions}
            />
            {!isOwner && (
              <div data-tip="Rate" onClick={() => openRate(true)}>
                <img src={starIcon} style={{ height: "17px" }} />
              </div>
            )}
            <PostBookmark
              postDetails={{ post_id, when, bookmark_count }}
              isMobile={isMobile}
              isQuorum={isQuorum}
            />
          </div>

          {when !== "viewPost" && (
            <div className="p_did">
              <div
                className="add-quorum-button"
                onClick={() =>
                  history.push(
                    isQuorum
                      ? `/quorum/${postid_token}`
                      : `/openpost/${postid_token}`
                  )
                }
              >
                <img src={openIcon} className="button-plus-img" />
                <h4 class="button-text">Launch</h4>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(PostActionsNew);
