import Notify from "handy-notification";

import axios from "../../../utils/axios-instance-utils";
import { addTag } from "../../../actions/user";
import { ObjectMssg, wait } from "../../../utils/utils";
import Action from "../../../utils/API/Action";

/**
 * Add user tags
 * @param {Object} options
 * @param {String} options.value
 * @param {Number} options.user
 * @param {Function} options.dispatch
 */
export const addUserTags = options => {
  let { value, user, dispatch } = options;
  if (value) {
    dispatch(
      addTag({
        user,
        tag: value
      })
    );
  } else {
    Notify({ value: "Please enter a tag" });
  }
};

/**
 * Edit profile
 * @param {Object} options
 * @param {String} options.susername
 * @param {String} options.semail
 * @param {Object} options.values
 * @param {String} options.values.username
 * @param {String} options.values.email
 */
export const editProfile = async (options, onDone) => {
  let {
    susername,
    semail,
    values,
    values: { username, email, password, cpassword, role, speciality, affiliation, firstname, surname }
  } = options,
    { data: uCount } = await axios.post("/api/what-exists", {
      what: "username",
      value: username
    }),
    { data: eCount } = await axios.post("/api/what-exists", {
      what: "email",
      value: email
    }),
    action = new Action(".edit_done");

  action.start("Processing..");

  if (!username) {
    Notify({ value: "Username must not be empty" });
  } else if (!email) {
    Notify({ value: "Email must not be empty" });
  } else if (uCount == 1 && username != susername) {
    Notify({ value: "Username already exists" });
  } else if (eCount == 1 && email != semail) {
    Notify({ value: "Email already exists" });
  } else if (password !== cpassword) {
    Notify({ value: "Password and confirm password do not match" });
  } else {
    let errorMsg = "";
    if (password && (!password.match(/[A-Z]/g) || !password.match(/[0-9]/g))) {
      if (!password.match(/[A-Z]/g) && password.match(/[0-9]/g)) {
        errorMsg = "Password must contain at least one uppercase";
      }

      if (!password.match(/[0-9]/g) && password.match(/[A-Z]/g)) {
        errorMsg = "Password must contain at least one number";
      }

      if (!password.match(/[A-Z]/g) && !password.match(/[0-9]/g)) {
        errorMsg =
          "Password must contain at least one uppercase and one number";
      }
    } else if (password) {
      if (!password.match(/[a-z]/g)) {
        errorMsg = "Password must contain lowercase";
      }
      if (password.length < 6) {
        errorMsg = "Password must be at least 6 characters long";
      }
    }
    if (errorMsg && password) {
      Notify({ value: errorMsg });
      return false;
    } else {
      if (!firstname) {
        Notify({ value: "FirstName must not be empty" });
      }
      else if (!surname) {
        Notify({ value: "LastName must not be empty" });
      }
      else if (!affiliation) {
        Notify({ value: "Affiliation must not be empty" });
      }
      else if (!role) {
        Notify({ value: "Role must not be empty" });
      }
      else if (!speciality) {
        Notify({ value: "Speciality must not be empty" });
      }
      else {
        let {
          data: { mssg, success }
        } = await axios.post("/api/edit-profile", values);
        Notify({
          value: mssg
        });
        if (success) {
          onDone();
        }
      }
    }
  }
  action.end("Done Editing");
};

export const editProfileNotifications = async options => {
  let { noticationSettings, callbackFn, dispatch } = options;
  let {
    data: { mssg }
  } = await axios.post("/api/edit-profile/notifications", {
    settings: noticationSettings
  });

  dispatch(callbackFn());
  Notify({
    value: ObjectMssg(mssg)
  });
};

export const deactivatOrDeleteAccount = async options => {
  const { status } = options;
  let {
    data: { mssg, success }
  } = await axios.post("/api/edit-profile/profile-status", { status: status });

  Notify({
    value: ObjectMssg(mssg)
  });
};
