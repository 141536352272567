

const initialState = {
  isShowPrompt: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_PROMPT': {
      return {
        ...state,
        isShowPrompt: true
      }
    }

    case 'HIDE_PROMPT': {
      return {
        ...state,
        isShowPrompt: false
      }
    }

    default:
      return { ...state };
  }
}
