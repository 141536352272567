import React, { Component } from 'react'
import { FadeIn } from 'animate-components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import d from '../../../utils/API/DOM'
import { editPost } from '../../../utils/post-utils'
import PrimaryButton from '../../others/button/primary-btn'
import SecondaryButton from '../../others/button/secondary-btn'
import Overlay from '../../others/overlay'
import MultiSelect from '../../post/post-it/select-search'
import ClosePopupIcon from '../../../assets/icons/close-new.svg'
import MentioningQuill from '../mentioning-quill'
import EditIcon from '../../../assets/icons/edit.svg'
import Notify from 'handy-notification'

class EditPost extends Component {
  constructor(props) {
    super(props)
    this.state = {
      description: '',
      ogDescription: '',
      labelsChanged: false,
      mentionedList: [],
    }
  }

  componentDidMount = () => {
    let { description, labels } = this.props
    this.setState({
      description,
      ogDescription: description,
      labels,
      loaded: true,
    })
  }

  back = () => {
    let { back } = this.props
    this.returnOgDescription()
    back()
  }

  descChange = value => {
    this.setState({ description: value })
    this.props.changeDesc(value)
    console.log(this.state.description)
  }

  returnOgDescription = () => {
    let { ogDescription } = this.state
    this.props.changeDesc(ogDescription)
  }

  updatePost = async e => {
    e.preventDefault()
    new d('.e_p_update').addClass('a_disabled')
    let { description, labels, mentionedList } = this.state
    let { dispatch, post, back, when } = this.props
    const mentionedUsers = mentionedList.map(u => u.userId)
    if(!labels){
      Notify({value: 'Please enter at least one label'})
    }
    editPost({
      post_id: post,
      description,
      dispatch,
      when,
      done: () => back(),
      failed: this.returnOgDescription(),
      labelTags: this.state.labelsChanged
        ? labels.map(l => l.label)
        : undefined,
      mentionedUsers,
    })
  }

  handleSelectChange = selectedOption => {
    this.setState({
      labels: selectedOption,
      labelsChanged: true,
    })
  }

  render() {
    let { description, labels } = this.state
    let { when } = this.props

    return (
      <React.Fragment>
        <Overlay />
        <div className="prompt edit_post">
          <FadeIn duration="300ms">
            <div className="prompt-top">
              <div className="top-icon-and-text">
                <img
                  src={EditIcon}
                  alt="EditIcon"
                />
                <span className="prompt-title">Edit post</span>
              </div>
              <img
                className="close-icon"
                src={ClosePopupIcon}
                onClick={this.back}
              />
            </div>

            <div className="e_p_middle modal_middle">
              <div class="edit-row">
                <h4 class="title">Details</h4>
                <MentioningQuill
                  value={description}
                  style={{ border: 'none' }}
                  onChange={this.descChange}
                  onMentionedListChange={mentionedList =>
                    this.setState({ mentionedList })
                  }
                />
              </div>

              {this.state.loaded && (
                <div class="edit-row" style={{ marginTop: 65 }}>
                  <h4 class="title">Labels</h4>
                  <MultiSelect
                    classname="select_case"
                    defaultValue={(labels || []).map((l, i) => ({
                      value: i,
                      label: l.labeltag,
                    }))}
                    handleChange={this.handleSelectChange} //TODO: REPLACE WITH THE HANDLESELECTCHANGE FUNCTION; Make an appearing disappearing grey box shown in psd
                    options={[]}
                    placeholder="Add tags that best describe your post"
                    customStyles={true}
                    style={{ width: '100%', marginTop: 10 }}
                  />
                </div>
              )}
            </div>

            <div className="prompt-bottom">
              <SecondaryButton label="Cancel" onClick={this.back} />
              <PrimaryButton
                label={when === 'quorum' ? 'Update' : 'Update'}
                onClick={this.updatePost}
                extraClass="prompt-done"
              />
            </div>
          </FadeIn>
        </div>
      </React.Fragment>
    )
  }
}

EditPost.propTypes = {
  post: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
  changeDesc: PropTypes.func.isRequired,
}

export default connect()(EditPost)
export { EditPost as PureEditPost }
