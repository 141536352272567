import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import axios from '../../../utils/axios-instance-utils'
import { setFile, addMessage } from '../../../actions/tumorBoards'
import mime from 'mime-types'
import Notify from 'handy-notification'
import PrimaryButton from '../../others/button/primary-btn'
import SecondaryButton from '../../others/button/secondary-btn'
import ShareIcon from '../icons/share.svg'
import CloseIcon from '../icons/close_small.png'
import { Picker } from 'emoji-mart'
import PdfIcon from 'icons/pdf-icon-new.svg';
import DocIcon from 'icons/DocIcon-new.svg';
import CsvIcon from 'icons/csvIcon-new.svg';
import xlsIcon from 'icons/xls.svg'

const ChatFile = ({ file, dispatch, selectedBoard, isMobile, fileRef }) => {
  const imgRef = useRef(null)
  const inputRef = useRef(null)
  const [name, setName] = useState('')
  const [text, setText] = useState('')
  const [showPicker, setShowPicker] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (file && file.type.includes('image')) {
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        imgRef.current.src = fileReader.result
      })
      fileReader.readAsDataURL(file)
    }
    if (file) {
      const nameArray = file.name.split('.')
      nameArray.pop()
      setName(nameArray.join('.'))
    }
  }, [file])

  const close = () => {
    dispatch(setFile(null));
    fileRef.current.value = null;
  }

  const sendMsg = async () => {
    if (!loading) {
      setLoading(true)
      const formData = new FormData()
      formData.append('tumorBoardId', selectedBoard.id)
      formData.append('msg', text)
      formData.append('img', file)
      const { status, data } = await axios
        .post('/api/tumorBoards/msgs', formData, { withCredentials: true })
        .catch(() => {
          Notify({ value: 'Could not send message' })
        })
      if (status === 201) {
        dispatch(
          addMessage({
            email: data.sender.email,
            firstName: data.sender.firstname,
            surname: data.sender.surname,
            username: data.sender.username,
            id: data.msgId,
            board: { id: selectedBoard.id },
            type: data.msg.tumor_board_message_type,
            time: data.msg.tumor_board_message_time,
            body: data.msg.tumor_board_message_msg,
            userId: data.sender.id,
            img: data.msg.tumor_board_message_img,
            status: data.msg.tumor_board_message_status,
            filename: data.msg.tumor_board_message_filename,
          })
        )
      }
      setText('')
      dispatch(setFile(null))
      setLoading(false)
    }
  }

  return (
    <div id="create-board-overlay">
      <div id="create-board">
        {loading && <div id="loading-file">Sending...</div>}
        <div id="file-div">
          <div
            id="create-board-header"
            style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '15px' }}
          >
            <div style={{ display: 'flex' }}>
              <img className="share-icon" src={ShareIcon} alt="share icon" />
              <p id="share-board-title">Share File</p>
            </div>
            <img
              src={CloseIcon}
              id="close-create-board"
              onClick={() => close()}
              style={{ height: 12, marginRight: 10, cursor: 'pointer' }}
            ></img>
          </div>
          <div
            id="edit-board-content"
            style={{
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {file.type.includes('image') && (
              <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginBottom: '15px' }}>
                <img
                  alt="preview"
                  id="preview"
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '160px',
                    marginBottom: '20px',
                    border: '1px solid #eee'
                  }}
                  ref={imgRef}
                />
                {mime.extension(file.type) && (
                  <p className="file-name">{name}.{mime.extension(file.type)}</p>
                )}
              </div>
            )}
            {!file.type.includes('image') && (
              <div style={{ display: 'flex' }}>
                {
                  mime.extension(file.type) === 'docx' ?
                    (
                      <img src={DocIcon} alt="doc" />
                    ) : mime.extension(file.type) === 'csv' ? (
                      <img src={CsvIcon} alt="csv" />
                    ) : mime.extension(file.type) === 'pdf' ? (
                      <img src={PdfIcon} alt="pdf" />
                    ) : (
                      <img src={xlsIcon} alt="xlsIcon" />
                    )
                }
                <div style={{ margin: 'auto 0' }}>
                  <p style={{ fontWeight: 'bold' }}>This file cannot be previewed</p>
                  {mime.extension(file.type) && (
                    <p>{name}.{mime.extension(file.type)}</p>
                  )}
                </div>
              </div>
            )}

            <div
              id="sender-container"
              style={{ position: 'relative', marginTop: '25px', width: '100%' }}
            >
              <input
                placeholder="Write a message..."
                id="input-send"
                value={text}
                ref={inputRef}
                onChange={() => setText(inputRef.current.value)}
              ></input>
              <div id="buttons-send">
                <img
                  src="/images/icons/neutral/smiley.png"
                  alt="emoji"
                  style={{ width: '20px', height: '20px' }}
                  className="attach"
                  onClick={() => setShowPicker(!showPicker)}
                />
              </div>
            </div>
          </div>
          <div
            className="share-modal-footer"
          >
            <SecondaryButton
              label="Cancel"
              onClick={() => close()}
            />
            <PrimaryButton
              label="Share"
              onClick={() => sendMsg()}
            />
          </div>
          {showPicker && !isMobile && (
            <Picker onSelect={emoji => setText(text + emoji.native)} />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { file, selectedBoard } = state.TumorBoards
  const { isMobile, windowSize } = state.TumorBoards
  return {
    file,
    selectedBoard,
    isMobile: isMobile || windowSize < 992,
  }
}

export default connect(mapStateToProps)(ChatFile)
