import axios from "../utils/axios-instance-utils";

export const fetchSlideBoxFolders = (
  folder = "slidebox",
  type = "withFolder"
) => {
  return dispatch => {
    dispatch(setSlideBoxLoading(true));
    axios
      .post("/api/listS3Objects", { folder: folder, type })
      .then(response => {
        if (response.status === 200) dispatch(setFolderLists(response.data));
      })
      .catch(error => {
        //console.log(error);
      });
  };
};

const setFolderLists = data => {
  return {
    type: "SET_FOLDER_LISTS",
    data
  };
};

export const setFolders = folders => {
  return {
    type: "SET_FOLDERS",
    data: {
      folders
    }
  }
}

export const setSelectedUploadFolder = folder => {
  return {
    type: "SET_SELECTED_UPLOAD_FOLDER",
    data: {
      folder
    }
  }
}

export const setSlideBoxStorageSize = data => {
  return {
    type: "SET_SLIDE_BOX_SIZE",
    data
  };
};

export const setSlideBoxLoading = loading => {
  return {
    type: "SET_SLIDE_BOX_LOADING",
    loading
  };
};

export const loadQuorum = data => {
  return {
    type: "SET_LOAD_QUOROM",
    data
  };
};

export const loadCaseStreamModal = data => {
  return {
    type: "SET_CASESTREAM_MODAL",
    data
  };
};

export const addQuorumEnable = data => {
  return {
    type: "SET_ADD_QUOROM_BUTTON",
    data
  };
};

export const addTumorBoardEnable = enable => {
  return {
    type: "SET_ADD_TUMOR_BOARDS_ENABLE",
    enable
  };
};

export const setSlideBoxStorageLimit = () => {
  return async dispatch => {
    const response = await axios.post("/api/getSlideBoxStorageDetails");
    dispatch(setSlideBoxStorageSize(response.data));
  };
};
