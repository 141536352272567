import React, { Component, Fragment } from 'react'
import { textComment, replyComment } from '../../../../utils/comment-utils'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AddEmojis from '../../../others/emojis/add-emojis'
import AttachmentModal from './attachment-modal'
import _ from 'lodash'
import MentioningQuill from '../../mentioning-quill'

class TextComment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: false,
      showPicker: false,
      file: null,
      attchmentModelShow: false,
      mentionedList: [],
    }
    this.setFileRef = React.createRef()
    this.inputRef = React.createRef()
  }

  updateTextArea = emoji => {
    this.inputRef.current.editor.insertText(
      this.inputRef.current.getEditorSelection().index,
      emoji
    )
  }

  handleCommentWithAttachment = textData => {
    let {
      postDetails: { post_id, user, firstname, surname, when },
      dispatch,
      comment_id,
    } = this.props
    const text = textData
    const { file, mentionedList } = this.state
    const mentionedUsers = mentionedList.map(u => u.userId)
    if (comment_id) {
      replyComment({
        comment_id,
        post_id,
        text,
        when,
        dispatch,
        postOwner: user,
        firstname,
        surname,
        file,
        mentionedUsers,
        done: this.afterCommentDone,
      })
    } else {
      textComment({
        post_id,
        text,
        when,
        dispatch,
        postOwner: user,
        firstname,
        surname,
        file,
        mentionedUsers,
        done: this.afterCommentDone,
      })
    }
  }

  afterCommentDone = () => {
    const { incrementComments } = this.props
    this.inputRef.current.editor.setText('')
    this.setFileRef.current.files = null
    this.setState({ attchmentModelShow: false, file: null })
    incrementComments()
  }

  postComment = (content = '', mentionedList = []) => {
    let {
      postDetails: { post_id, user, firstname, surname, when },
      dispatch,
      comment_id,
    } = this.props
    const text = content
    const { file } = this.state
    if (text.trim() == '<p><br></p>') {
      return false
    } else {
      const mentionedUsers = mentionedList.map(u => u.userId)
      if (comment_id) {
        replyComment({
          comment_id,
          post_id,
          text: content,
          when,
          dispatch,
          postOwner: user,
          firstname,
          surname,
          file,
          mentionedUsers,
          done: this.afterCommentDone,
        })
      } else {
        textComment({
          post_id,
          text: content,
          when,
          dispatch,
          postOwner: user,
          firstname,
          surname,
          file,
          mentionedUsers,
          done: this.afterCommentDone,
        })
      }
    }
  }

  setfile = () => {
    this.setState({
      file: this.setFileRef.current.files[0],
      attchmentModelShow: true,
    })
  }

  checkFile = () => {
    if (!this.state.file) this.setFileRef.current.click()
    else this.setState({ file: null })
  }

  onEnterPressed = async (content, mentionedList) => {
    await this.postComment(content, mentionedList)
  }
  render() {
    const { commentBoxId, isMobile, windowSize, comment_id } = this.props
    const { attchmentModelShow, file, content } = this.state
    return (
      <Fragment>
        <div
          className="p_cit_teaser"
          onClick={() => this.setState({ comment: true })}
        >
          <div
            class={
              !comment_id
                ? 'form-group comment-form-container'
                : 'form-grop reply-form-container'
            }
            style={{ width: '100%', height: '100%', marginBottom: '0px' }}
          >
            <input
              type="file"
              accept="image/*"
              hidden
              ref={this.setFileRef}
              onChange={() => this.setfile()}
            ></input>
            <img
              src={
                !this.state.file
                  ? '/images/img/attach.svg'
                  : '/images/icons/color/attach.png'
              }
              alt="file"
              style={
                !isMobile
                  ? styles.attachment
                  : { ...styles.attachment, right: 0 }
              }
              className="attach"
              onClick={() => this.checkFile()}
            />
            <MentioningQuill
              placeholder={
                !comment_id
                  ? 'Join the discussion...'
                  : 'Reply to the comment...'
              }
              style={
                !isMobile
                  ? styles.commentBox
                  : { ...styles.commentBox, width: '100%' }
              }
              onEnterPressed={this.onEnterPressed}
              inputRef={this.inputRef}
            />

            {!isMobile ? (
              <button className="sendButton" onClick={this.postComment}>
                send
              </button>
            ) : null}
          </div>
          {!isMobile ? (
            <div className="p_cit_tool">
              <AddEmojis
                textArea={'#comment_input'}
                updateTextArea={this.updateTextArea}
              />
            </div>
          ) : null}
          {attchmentModelShow && (
            <AttachmentModal
              handleCommentWithAttachment={this.handleCommentWithAttachment}
              afterCommentDone={this.afterCommentDone}
              commentBoxId={commentBoxId}
              file={this.setFileRef.current.files[0]}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

const styles = {
  commentBox: {
    width: '100%',
    background: '#1082f114',
    borderRadius: 3,
    opacity: 1,
  },
  attachment: {
    width: '20px',
    height: '20px',
    marginRight: '11px',
    position: 'absolute',
    top: '12px',
    right: '35px',
    zIndex: 1,
  },
}

TextComment.propTypes = {
  postDetails: PropTypes.shape({
    post_id: PropTypes.number.isRequired,
    user: PropTypes.number.isRequired,
    when: PropTypes.string.isRequired,
  }).isRequired,
  incrementComments: PropTypes.func.isRequired,
}

const mapStateToProps = store => ({
  windowSize: store.Setting.windowSize,
  isMobile: store.Setting.isMobile,
})

export default connect(mapStateToProps)(TextComment)
