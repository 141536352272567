import React from "react";

const UserAnnotationLabel = ({
  enable = false,
  isPostMine,
  quorum_color,
  user_id,
  firstname,
  surname,
  enableAnnotation,
  disableAnnotation,
  annotationIds,
  enabledAnnotation,
  quorum_id,
  slide_id,
  toggle,
  isValidate
}) => {
  return (
    <React.Fragment>
      {isValidate && (
        <div
          className="annotation-element"
          style={{ backgroundColor: quorum_color }}
        >
          <span
            className="annotation-user"
            onClick={() =>
              toggle === "on"
                ? disableAnnotation(annotationIds)
                : enableAnnotation(annotationIds)
            }
          >
            {firstname} {surname}
          </span>
          {toggle === "on" ? (
            <span onClick={() => disableAnnotation(annotationIds)}>
              <i class="fas fa-eye annotation-logo"></i>
            </span>
          ) : (
            <span onClick={() => enableAnnotation(annotationIds)}>
              <i class="fas fa-eye-slash annotation-logo"></i>
            </span>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default UserAnnotationLabel;
