import React, { Component } from 'react'
import TimeAgo from 'handy-timeago'
import PostOptionLists from './options-list'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

export default class PostOptions extends Component {
  state = {
    showOptions: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node
  }

  /**
   * Hidd if clicked on outside of element
   */
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showOptions: false })
    }
  }

  toggleOptions = () => this.setState({ showOptions: !this.state.showOptions })

  render() {
    let {
      postDetails: {
        user,
        post_id,
        when,
        description,
        case_slove,
        post_status,
        postid_token,
        label_tags,
        verification
      },
      postStatus,
      updateDescription,
      isQuorum,
    } = this.props
    let { showOptions } = this.state
    return (
      <div>
        <div className="p_i_2">
          <div className="p_h_opt">
            <span
              className={showOptions ? 'exp_p_menu opened' : 'exp_p_menu'}
              onClick={this.toggleOptions}
            >
              <img src="/images/img/more.svg" className="dots-icon" />
            </span>
          </div>
        </div>

        {showOptions && (
          <div
            className="options p_options"
            style={{
              top: when == 'shared' ? 80 : 48,
            }}
            ref={this.setWrapperRef}
          >
            <PostOptionLists
              postDetails={{
                user,
                post_id,
                when,
                case_slove,
                post_status,
                description,
                postid_token,
                labels: label_tags,
                verification
              }}
              postStatus={postStatus}
              toggleOptions={this.toggleOptions}
              updateDescription={updateDescription}
              isQuorum={isQuorum}
            />
          </div>
        )}

        <ReactTooltip />
      </div>
    )
  }
}

PostOptions.propTypes = {
  postDetails: PropTypes.shape({
    user: PropTypes.number.isRequired,
    post_id: PropTypes.number.isRequired,
    when: PropTypes.string.isRequired,
    post_time: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    case_slove: PropTypes.number.isRequired,
  }).isRequired,
  updateDescription: PropTypes.func.isRequired,
}
