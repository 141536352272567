import React from "react";
import PropTypes from "prop-types";
import { uniq } from "../../../utils/utils";
import ReactHtmlParser from "react-html-parser";

const ToTags = ({ str }) => {
  let finStr = "";
  for (var i = 0; i < str.length; i++) {
    let s = str.charAt(i);
    if ((s == "@" || s == "#") && str[i - 1] != " ") {
      finStr = finStr + " " + s;
    } else {
      finStr = finStr + s;
    }
  }
  str = finStr;
  let checkLink = str.match(
    /https|http?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
  );

  if (!checkLink) {
    let hashes = str.split(" ");
    let hh = [];
    if (!hashes) {
      hh = [];
    } else {
      hh = hashes.map(elem => {
        let hash = elem.slice(1);
        let filtered = hash.replace(/[^a-z0-9A-Z_]/, "");
        filtered = filtered.replace(/\/p>/g, "");
        let isLink = elem.match(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
        );

        if (elem.charAt(0) == "#") {
          if (hash.charAt(0) !== "#") {
            return `<a key=${uniq()} href="${
              window.location.origin
            }/search?hashtags=${filtered}" className="hashtag">${elem}</a>`;
          }
        } else if (elem.charAt(0) == "@") {
          if (hash.charAt(0) !== "@") {
            return `<a
                  key=${uniq()}
                  href="${window.location.origin}/profile/${filtered}"
                  className="hashtag"
                >${elem}</a>`;
          }
        } else if (isLink) {
          return `<a
                key=${uniq()}
                target="_blank"
                href="${elem}"
                className="hashtag"
              >${elem}</a>`;
        }

        return `${elem} `;
      });
    }
    return <span>{ReactHtmlParser(hh.join(" "))}</span>;
  } else {
    return <span>{ReactHtmlParser(str)}</span>;
  }
};

ToTags.defaultProps = {
  str: ""
};

ToTags.propTypes = {
  str: PropTypes.string.isRequired
};

export default ToTags;
