import React, { useCallback } from 'react'
import {useDropzone} from 'react-dropzone'
import { ComplexImageTypes } from '../../../../config/constants'

function Dropzone({ setDragAndDropFiles }) {
  const onDrop = (acceptedFiles) => {
    const isComplexImage = checkImageIsComplex(
      acceptedFiles[0].name.split(".")[1]
    );
    if(isComplexImage) {
      setDragAndDropFiles(acceptedFiles);
    }
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  
  const checkImageIsComplex = imgtype => {
    return ComplexImageTypes.some(type => type === imgtype.toLowerCase());
  };

  return (
    <div {...getRootProps({ className: "dropzone-fileupload row"})}>
      <input {...getInputProps()} multiple={true}/>
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag & drop files here</p>
      }
    </div>
  )
}

export default Dropzone;