import React, { Fragment } from 'react'
import { string } from 'prop-types'
import Icon from '@material-ui/core/Icon'

const MaterialIcon = ({ icon, ...props }) => {
  return (
    <Fragment>
      <Icon className="material-icons">{icon}</Icon>
    </Fragment>
  )
}

MaterialIcon.propTypes = {
  icon: string.isRequired,
}

export default MaterialIcon
