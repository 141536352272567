import PostItIntialState from "./postit-initial-state";

export const addPost = (posts, post) => {
  posts = [post, ...posts];
  return posts;
};

export const addFeed = (feed, newfeed) => {
  feed = [...feed, ...newfeed];
  return feed;
};

export const editPost = (
  viewPost,
  { description, rate, rate_count, my_rate }
) => {
  return {
    ...viewPost,
    description: description || viewPost.description,
    rate: rate || viewPost.rate,
    rate_count: rate_count || viewPost.rate_count,
    my_rate: my_rate || viewPost.my_rate
  };
};

export const editQuorum = (viewQuorum, { post_id, description }) => {
  return {
    ...viewQuorum,
    description: description
  };
};

export const editQuorumTime = (viewQuorum, { post_id, valid_until }) => {
  return {
    ...viewQuorum,
    valid_until: valid_until
  };
};

export const editPostFeed = (
  feed,
  { post_id, description, rate, rate_count, my_rate, label_tags }
) => {
  return feed.map(post => {
    if (post.post_id === post_id) {
      return {
        ...post,
        description: description || post.description,
        rate: rate || post.rate,
        rate_count: rate_count || post.rate_count,
        my_rate: my_rate || post.my_rate,
        label_tags: label_tags || post.label_tags
      };
    }
    return post;
  });
};

export const deletePost = (posts, post) =>
  posts.filter(p => p.post_id != parseInt(post));

export const untag = (tags, user) => {
  let tt = tags.filter(t => t.user != user);
  return tt;
};

export const unbookmark = (bookmarks, post) =>
  bookmarks.filter(b => b.post_id != post);

export const bookmarkInPost = viewPost => {
  const count = viewPost.bookmark_count + 1;
  return {
    ...viewPost,
    bookmark_count: count
  };
};

export const unbookmarkInPost = viewPost => {
  const count = viewPost.bookmark_count - 1;
  return {
    ...viewPost,
    bookmark_count: count
  };
};

//  export const unbookmarkInPost = (viewPost) =>{
//   return  {
//     ...viewPost,
//     bookmark_count: viewPost.bookmark_count - 1
//  }
// }

export const bookmarkFeed = (feed, post_id) => {
  return feed.map(p => {
    if (p.post_id === post_id) {
      const count = p.bookmark_count + 1;
      return {
        ...p,
        bookmark_count: count
      };
    }
    return p;
  });
};

export const unbookmarkFeed = (feed, post_id) => {
  return feed.map(p => {
    if (p.post_id === post_id) {
      const count = p.bookmark_count - 1;
      return {
        ...p,
        bookmark_count: count
      };
    }
    return p;
  });
};

export const removeShare = (sharers, share_id) =>
  sharers.filter(s => s.share_id != share_id);

export const comment = (post, comment) => {
  post = {
    ...post,
    comments: [...post.comments, comment]
  };
  return post;
};

export const postsComment = (feed, comment) => {
  const mapCommentToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === comment.post_id) {
        return {
          ...feedData,
          comments: [...feedData.comments, comment],
          comments_count: feedData.comments_count++
        };
      } else {
        return feedData;
      }
    });
  };
  return mapCommentToFeed();
};

export const commentReply = (post, reply) => {
  const { comments } = post;
  const { comment_id } = reply;
  const mapReplyToComment = comment => {
    if (comment.comment_id === comment_id) {
      return {
        ...comment,
        replies: [...comment, reply]
      };
    } else {
      return comment;
    }
  };

  const mapReplyToPost = () => {
    return comments.map(comment => {
      return mapReplyToComment(comment);
    });
  };

  post = {
    ...post,
    comments: mapReplyToPost()
  };
  return post;
};

export const postsCommentReply = (feed, reply) => {
  const { post_id, comment_id } = reply;

  const mapReplyToComment = comment => {
    if (comment.comment_id === comment_id) {
      return {
        ...comment,
        replies: [...comment.replies, reply],
        isReply: true
      };
    } else {
      return comment;
    }
  };

  const mapFeedToComment = comments => {
    return comments.map(comment => {
      return mapReplyToComment(comment);
    });
  };

  const mapReplyToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post_id) {
        return {
          ...feedData,
          comments: mapFeedToComment(feedData.comments)
        };
      } else {
        return feedData;
      }
    });
  };

  return mapReplyToFeed();
};

export const slovePost = (feed, post) => {
  const mapCaseSloveToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post.post_id) {
        return {
          ...feedData,
          case_slove: post.isSlove
        };
      } else {
        return feedData;
      }
    });
  };
  return mapCaseSloveToFeed();
};

export const reviewedPost = (feed, post) => {
  const mapReviewedToPost = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post.post_id) {
        return {
          ...feedData,
          post_status: post.post_status
        };
      } else {
        return feedData;
      }
    });
  };
  return mapReviewedToPost();
}

export const allowPostOwner = (feed, user) => {
  const mapAllowedOwnerToPost = () => {
    return feed.map(feedData => {
      if (feedData.user === user) {
        return {
          ...feedData,
          verification: 'verified'
        };
      } else {
        return feedData;
      }
    });
  };
  return mapAllowedOwnerToPost();
}



export const slovePostSingle = (post, postData) => {
  post = {
    ...post,
    case_slove: postData.isSlove
  };

  return post;
};

export const spamPost = (feed, post) => {
  const mapSpamToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post.post_id) {
        return {
          ...feedData,
          post_status: post.post_status
        };
      } else {
        return feedData;
      }
    });
  };
  return mapSpamToFeed();
};

export const voteComment = (feed, upvoteData) => {
  const {
    post_id,
    conversation_id,
    conversation_type,
    vote_type_in
  } = upvoteData;

  const mapVoteToComment = comment => {
    if (comment.comment_id === conversation_id) {
      if (comment.vote_type === vote_type_in) {
        const data = {
          ...comment,
          downvote_count:
            vote_type_in === "downvote"
              ? comment.downvote_count - 1
              : comment.downvote_count,
          upvote_count:
            vote_type_in === "upvote"
              ? comment.upvote_count - 1
              : comment.upvote_count,
          vote_type: null
        };
        return data;
      } else {
        if (comment.vote_type === null) {
          const data = {
            ...comment,
            downvote_count:
              vote_type_in === "downvote"
                ? comment.downvote_count + 1
                : comment.downvote_count,
            upvote_count:
              vote_type_in === "upvote"
                ? comment.upvote_count + 1
                : comment.upvote_count,
            vote_type: vote_type_in
          };
          return data;
        } else {
          if (vote_type_in === "downvote") {
            const data = {
              ...comment,
              downvote_count: comment.downvote_count + 1,
              upvote_count: comment.upvote_count - 1,
              vote_type: vote_type_in
            };
            return data;
          } else {
            const data = {
              ...comment,
              downvote_count: comment.downvote_count - 1,
              upvote_count: comment.upvote_count + 1,
              vote_type: vote_type_in
            };
            return data;
          }
        }
      }
    } else {
      return comment;
    }
  };
  const mapFeedToComment = comments => {
    return comments.map(comment => {
      return mapVoteToComment(comment);
    });
  };
  const mapVoteToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post_id) {
        return {
          ...feedData,
          comments: mapFeedToComment(feedData.comments)
        };
      } else {
        return feedData;
      }
    });
  };
  return mapVoteToFeed();
};

export const voteReply = (feed, upvoteData) => {
  const {
    post_id,
    conversation_id,
    conversation_type,
    vote_type_in
  } = upvoteData;

  const mapVoteToReply = reply => {
    if (reply.reply_id === conversation_id) {
      if (reply.vote_type === vote_type_in) {
        const data = {
          ...reply,
          downvote_count:
            vote_type_in === "downvote"
              ? reply.downvote_count - 1
              : reply.downvote_count,
          upvote_count:
            vote_type_in === "upvote"
              ? reply.upvote_count - 1
              : reply.upvote_count,
          vote_type: null
        };
        return data;
      } else {
        if (reply.vote_type === null) {
          const data = {
            ...reply,
            downvote_count:
              vote_type_in === "downvote"
                ? reply.downvote_count + 1
                : reply.downvote_count,
            upvote_count:
              vote_type_in === "upvote"
                ? reply.upvote_count + 1
                : reply.upvote_count,
            vote_type: vote_type_in
          };
          return data;
        } else {
          if (vote_type_in === "downvote") {
            const data = {
              ...reply,
              downvote_count: reply.downvote_count + 1,
              upvote_count: reply.upvote_count - 1,
              vote_type: vote_type_in
            };
            return data;
          } else {
            const data = {
              ...reply,
              downvote_count: reply.downvote_count - 1,
              upvote_count: reply.upvote_count + 1,
              vote_type: vote_type_in
            };
            return data;
          }
        }
      }
    } else {
      return reply;
    }
  };

  const mapVoteToComment = comment => {
    return comment.replies.map(replies => {
      return mapVoteToReply(replies);
    });
  };

  const mapFeedToComment = comments => {
    return comments.map(comment => {
      return {
        ...comment,
        replies: mapVoteToComment(comment)
      };
    });
  };

  const mapVoteToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post_id) {
        return {
          ...feedData,
          comments: mapFeedToComment(feedData.comments)
        };
      } else {
        return feedData;
      }
    });
  };

  return mapVoteToFeed();
};

export const voteCommentPost = (viewPost, upvoteData) => {
  const { conversation_id, conversation_type, vote_type_in } = upvoteData;
  const setVoteToComment = comment => {
    if (comment.comment_id === conversation_id) {
      if (comment.vote_type === vote_type_in) {
        const data = {
          ...comment,
          downvote_count:
            vote_type_in === "downvote"
              ? comment.downvote_count - 1
              : comment.downvote_count,
          upvote_count:
            vote_type_in === "upvote"
              ? comment.upvote_count - 1
              : comment.upvote_count,
          vote_type: null
        };
        return data;
      } else {
        if (comment.vote_type === null) {
          const data = {
            ...comment,
            downvote_count:
              vote_type_in === "downvote"
                ? comment.downvote_count + 1
                : comment.downvote_count,
            upvote_count:
              vote_type_in === "upvote"
                ? comment.upvote_count + 1
                : comment.upvote_count,
            vote_type: vote_type_in
          };
          return data;
        } else {
          if (vote_type_in === "downvote") {
            const data = {
              ...comment,
              downvote_count: comment.downvote_count + 1,
              upvote_count: comment.upvote_count - 1,
              vote_type: vote_type_in
            };
            return data;
          } else {
            const data = {
              ...comment,
              downvote_count: comment.downvote_count - 1,
              upvote_count: comment.upvote_count + 1,
              vote_type: vote_type_in
            };
            return data;
          }
        }
      }
    } else {
      return comment;
    }
  };

  const mapVoteToComment = comments => {
    return comments.map(comment => {
      return setVoteToComment(comment);
    });
  };

  const mapVoteToPost = () => {
    return {
      ...viewPost,
      comments: mapVoteToComment(viewPost.comments)
    };
  };

  return mapVoteToPost();
};

export const voteReplyPost = (viewPost, upvoteData) => {
  const { conversation_id, conversation_type, vote_type_in } = upvoteData;

  const setVoteToReply = reply => {
    if (reply.reply_id === conversation_id) {
      if (reply.vote_type === vote_type_in) {
        const data = {
          ...reply,
          downvote_count:
            vote_type_in === "downvote"
              ? reply.downvote_count - 1
              : reply.downvote_count,
          upvote_count:
            vote_type_in === "upvote"
              ? reply.upvote_count - 1
              : reply.upvote_count,
          vote_type: null
        };
        return data;
      } else {
        if (reply.vote_type === null) {
          const data = {
            ...reply,
            downvote_count:
              vote_type_in === "downvote"
                ? reply.downvote_count + 1
                : reply.downvote_count,
            upvote_count:
              vote_type_in === "upvote"
                ? reply.upvote_count + 1
                : reply.upvote_count,
            vote_type: vote_type_in
          };
          return data;
        } else {
          if (vote_type_in === "downvote") {
            const data = {
              ...reply,
              downvote_count: reply.downvote_count + 1,
              upvote_count: reply.upvote_count - 1,
              vote_type: vote_type_in
            };
            return data;
          } else {
            const data = {
              ...reply,
              downvote_count: reply.downvote_count - 1,
              upvote_count: reply.upvote_count + 1,
              vote_type: vote_type_in
            };
            return data;
          }
        }
      }
    } else {
      return reply;
    }
  };

  const mapVoteToReply = comment => {
    return comment.replies.map(replies => {
      return setVoteToReply(replies);
    });
  };

  const mapVoteToComment = comments => {
    return comments.map(comment => {
      return {
        ...comment,
        replies: mapVoteToReply(comment)
      };
    });
  };

  const mapReplyVoteToPost = () => {
    return {
      ...viewPost,
      comments: mapVoteToComment(viewPost.comments)
    };
  };

  return mapReplyVoteToPost();
};

export const deleteComment = (post, comment_id) => {
  let comments = post.comments.filter(c => c.comment_id != comment_id);
  return {
    ...post,
    comments
  };
};

export const editComment = (post, { comment_id, comment }) => {
  let comments = post.comments.map(c => {
    if (c.comment_id == comment_id) {
      c.text = comment;
    }
    return c;
  });

  return {
    ...post,
    comments
  };
};

export const deleteCommentFeed = (feed, { post_id, comment_id }) => {
  const mapFeedToComment = comments => {
    return comments.filter(c => c.comment_id != comment_id);
  };

  const mapCommentToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post_id) {
        return {
          ...feedData,
          comments: mapFeedToComment(feedData.comments)
        };
      } else {
        return feedData;
      }
    });
  };

  return mapCommentToFeed();
};

export const editCommentFeed = (feed, { post_id, comment_id, comment }) => {
  let mapToComment = c => {
    if (c.comment_id == comment_id) {
      c.text = comment;
    }
    return c;
  };

  const mapFeedToComment = comments => {
    return comments.map(comment => {
      return {
        ...comment,
        replies: mapToComment(comment)
      };
    });
  };

  const mapCommentToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post_id) {
        return {
          ...feedData,
          comments: mapFeedToComment(feedData.comments)
        };
      } else {
        return feedData;
      }
    });
  };

  return mapCommentToFeed();
};

export const editReplyFeed = (
  feed,
  { post_id, comment_id, reply_id, comment }
) => {
  let mapToReply = r => {
    if (r.reply_id == reply_id) {
      r.text = comment;
    }
    return r;
  };
  const mapReplyToComment = c => {
    if (c.comment_id === comment_id) {
      const repiles = c.replies.map(reply => {
        return mapToReply(reply);
      });
      return {
        ...c,
        repiles
      };
    } else {
      return c;
    }
  };

  const mapFeedToComment = comments => {
    return comments.map(comment => {
      return mapReplyToComment(comment);
    });
  };

  const mapReplyToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post_id) {
        return {
          ...feedData,
          comments: mapFeedToComment(feedData.comments)
        };
      } else {
        return feedData;
      }
    });
  };

  return mapReplyToFeed();
};

export const deleteReply = (post, { comment_id, reply_id }) => {
  const { comments } = post;

  const mapReplyToComment = c => {
    if (c.comment_id === comment_id) {
      const replies = c.replies.filter(r => r.reply_id != reply_id);
      return {
        ...c,
        replies
      };
    } else {
      return c;
    }
  };

  const mapFeedToComment = () => {
    return comments.map(comment => {
      return mapReplyToComment(comment);
    });
  };

  return {
    ...post,
    comments: mapFeedToComment()
  };
};

export const deleteReplyFeed = (feed, { post_id, comment_id, reply_id }) => {
  const mapReplyToComment = c => {
    if (c.comment_id === comment_id) {
      const replies = c.replies.filter(r => r.reply_id != reply_id);
      return {
        ...c,
        replies
      };
    } else {
      return c;
    }
  };

  const mapFeedToComment = comments => {
    return comments.map(comment => {
      return mapReplyToComment(comment);
    });
  };

  const mapReplyToFeed = () => {
    return feed.map(feedData => {
      if (feedData.post_id === post_id) {
        return {
          ...feedData,
          comments: mapFeedToComment(feedData.comments)
        };
      } else {
        return feedData;
      }
    });
  };

  return mapReplyToFeed();
};

export const removeLike = (likes, like_id) =>
  likes.filter(l => l.like_id != like_id);

export const changePostIt = (postIt, pyOptions) => {
  let { what, value } = pyOptions;
  let updated = {
    ...postIt,
    [what]: value
  };
  return updated;
};

export const updateQuorum = (quorum, pyOptions) => {
  let updated = {
    ...quorum,
    isValidateDone: true,
    desc: ""
  };
  return updated;
};

export const updateQuorumDesc = (quorum, pyOptions) => {
  let { what, value } = pyOptions;
  let updated = {
    ...quorum,
    [what]: value
  };
  return updated;
};

export const setAnnotation = (annotations, payload) => {
  const { annotation, isAdmin } = payload;

  const dataSet = [...annotations, annotation];
  let dataSetNew = [];
  if (!isAdmin) {
    dataSetNew = dataSet.filter(data => {
      if (data.user_id === user_details.userId) {
        return data;
      }
    });
  } else {
    dataSetNew = dataSet;
  }
  return dataSetNew;
};

export const resetPostItProperties = () => {
  let reset = PostItIntialState;
  return reset;
};

export const editPostPollOptionsFeed = (feed, { post_id, options }) => {
  return feed.map(post => {
    if (post.post_id === post_id) {
      return {
        ...post,
        poll: {
          ...post.poll,
          options
        }
      };
    }
    return post;
  });
};

export const editViewPostPollOptions = (viewPost, { options }) => {
  return {
    ...viewPost,
    poll: {
      ...viewPost.poll,
      options
    }
  };
};
