import React, { Component } from "react";
import HeaderOptions from "./header-options";
import MobileNavBar from "../mobile-nav/mobile-nav";
import HeaderTopLinks from "./top-links";
import HeaderLogo from "./logo";
import PostIt from "../../post/post-it/post-it";
import SubmitMenu from "../../submit-menu/SubmitMenu";
import PostItQuorum from "../../post/post-it/post-it-quorum";
import PostItSlidebox from "../../post/post-it/post-it-slidebox";
import AppLink from "../link/link";
import { connect } from "react-redux";
import { getUnreadNotifications } from "../../../actions/notification";
import { getFeedNew } from "../../../actions/post";
import { getUnreadMessages } from "../../../actions/message";
import { withRouter } from "react-router-dom";
import Fade from "../../animations/Fade";
import MenuIcon from "./new-icons/wf-menu.svg";

class Header extends Component {
  state = {
    showOptions: false,
    postIt: false,
    location: "",
    hover: false,
    navOpen: false,
    showSubmitMenu: false,
    showQuorumModal: false,
    showSlideboxModal: false,
    collapseSidebar: false
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  setSlidebarWrapperRef = node => {
    this.slidebarWrapperRef = node;
  };

  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.id !== "profile-nav-image"
    ) {
      this.setState({ navOpen: !this.state.navOpen, showOptions: false });
    }
    if (
      this.slidebarWrapperRef &&
      !this.slidebarWrapperRef.contains(event.target)
    ) {
      this.setState({ navOpen: false });
    }
  };

  toggleOptions = e => {
    this.setState({ showOptions: !this.state.showOptions });
  };

  showSubmitMenu = () => {
    const { isMobile, history } = this.props;
    if (isMobile) {
      this.state.showSubmitMenu
        ? history.goBack()
        : history.push("/submit-menu");
    } else {
      this.setState({ showSubmitMenu: !this.state.showSubmitMenu });
    }
  };

  togglePostIt = () => {
    this.setState({
      postIt: !this.state.postIt,
      showSubmitMenu: !this.state.showSubmitMenu
    });
  };

  closePostIt = () => {
    const { isMobile, history } = this.props;
    if (isMobile) {
      history.goBack();
    } else {
      this.setState({ postIt: false });
    }
  };

  toggleNav = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ navOpen: !this.state.navOpen });
  };

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  toggleSumbitMenu = () => {
    this.setState({ showSubmitMenu: !this.state.showSubmitMenu });
  };

  toggleQuorumPost = (status, closeBoth = false) => {
    if (closeBoth) {
      this.setState({ showQuorumModal: false, showSubmitMenu: false });
    } else {
      this.setState({
        showQuorumModal: !this.state.showQuorumModal,
        showSubmitMenu: !this.state.showSubmitMenu
      });
    }
  };

  toggleSlideboxPost = (status, closeBoth = false) => {
    this.setState({ showSlideboxModal: false, showSubmitMenu: false });
    this.props.history.push("/slidebox");
  };

  toggleSidebar = () => {
    this.props.toggleSidebar(!this.props.collapseSidebar);
    this.setState({ collapseSidebar: !this.props.collapseSidebar });
  };

  topToPage = e => {
    e.preventDefault();
    if (this.props.isLoggedUser) {
      if (this.props.activeRoute === "home") {
        const { dispatch } = this.props;
        dispatch(getFeedNew(1));
        dispatch(getUnreadNotifications());
        dispatch(getUnreadMessages());
        window.scrollTo(0, 0);
      } else {
        const { dispatch } = this.props;
        dispatch(getFeedNew(1));
      }
      this.props.history.push("/");
    } else {
      window.location.href = "/login";
    }
  };

  render() {
    let {
      showOptions,
      showSubmitMenu,
      navOpen,
      postIt,
      showQuorumModal,
      showSlideboxModal
    } = this.state;
    const {
      session: { username },
      activeRoute,
      windowSize,
      isMobile,
      userData,
      currentUserData,
      location,
      isLoggedUser,
      isFeedLoad
    } = this.props;

    const fullname = currentUserData.firstname + " " + currentUserData.surname;
    return (
      <React.Fragment>
        {windowSize < 992 || isMobile ? (
          <div className="index_header_mobile header_mobile">
            {navOpen && <div className="menuOverlay"></div>}
            {isLoggedUser && (
              <div
                id="mySidenav"
                className={
                  navOpen
                    ? "sidenav header_sidenav_open"
                    : "sidenav header_sidenav_close"
                }
                ref={this.setSlidebarWrapperRef}
              >
                <a href="" className="closebtn" onClick={this.toggleNav}>
                  ×
                </a>
                <div className="navbar-nav mobile_nav">
                  <MobileNavBar
                    toggleOptions={this.toggleNav}
                    user={username}
                    isMobile={isMobile}
                    userData={userData}
                    currentUserData={currentUserData}
                  />
                </div>
              </div>
            )}
            <div className="container-mobile-header">
              <div className="left-side-of-Mheader">
                {isLoggedUser && (
                  <div className="ham-menu">
                    <span onClick={this.toggleNav} className="nav-icon">
                      <img
                        onClick={this.toggleSidebar}
                        src={MenuIcon}
                        alt="menu"
                      />
                    </span>
                  </div>
                )}
                <div className="main-logo text-center">
                  <HeaderLogo topToPage={this.topToPage} />
                </div>
              </div>
              <div className="right-side-of-Mheader">
                <HeaderTopLinks un={this.props.un} />
                <AppLink url="/search?mobile=" className="to_home_nav">
                  {activeRoute === "search" ? (
                    <span class="search_icon active">
                      <i class="fas fa-search"></i>
                    </span>
                  ) : (
                    <span class="search_icon">
                      <i class="fas fa-search"></i>
                    </span>
                  )}
                </AppLink>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              isLoggedUser
                ? "header_container"
                : "header_container visitor-view"
            }
          >
            <div className={isLoggedUser ? "header" : "header logged_out"}>
              {isLoggedUser && (
                <div className="sidebar-button">
                  <img onClick={this.toggleSidebar} alt="" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjAuNzUiIHkxPSIxLjc0MDY5IiB4Mj0iMTkuMjUiIHkyPSIxLjc0MDY5IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxsaW5lIHgxPSIwLjc1IiB5MT0iOS4yNDA2NiIgeDI9IjE5LjI1IiB5Mj0iOS4yNDA2NiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8bGluZSB4MT0iMC43NSIgeTE9IjE2Ljc0MDciIHgyPSIxOS4yNSIgeTI9IjE2Ljc0MDciIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==" alt="menu" />
                </div>
              )}
              <HeaderLogo topToPage={this.topToPage} />
              {!isLoggedUser && (
                <div class="right">
                  <a href="/signup" className="signup_link">
                    Sign Up
                  </a>
                </div>
              )}
              {isLoggedUser && (
                <div className="header_right">
                  <HeaderTopLinks
                    un={this.props.un}
                    showOptions={showOptions}
                    toggleOptions={this.toggleOptions}
                  />
                </div>
              )}

              {showOptions ? (
                <div className="sp_options options" ref={this.setWrapperRef}>
                  <div className="userDropDown">
                    <div className="userImgCon">
                      <img
                        src={`/users/${currentUserData.id}/avatar.jpg`}
                        alt="avatar"
                        className="sp_img"
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = "/images/default-avatar.svg";
                        }}
                      />
                    </div>
                    <div className="usernames">
                      <h4>
                        {fullname.length > 19
                          ? fullname.substring(0, 19) + "..."
                          : fullname}
                      </h4>
                      <h5>@{currentUserData.username}</h5>
                    </div>
                  </div>
                  <HeaderOptions
                    toggleOptions={this.toggleOptions}
                    user={username}
                    userData={currentUserData}
                  />
                </div>
              ) : null}

              <Fade duration="300ms" show={showSubmitMenu}>
                <SubmitMenu
                  back={this.toggleSumbitMenu}
                  togglePostIt={this.togglePostIt}
                  loadQuorumModal={this.toggleQuorumPost}
                  loadSlideboxModal={this.toggleSlideboxPost}
                />
              </Fade>

              <Fade duration="300ms" show={postIt}>
                <PostIt
                  back={this.closePostIt}
                  isMobile={isMobile}
                  type="user"
                  group={null}
                />
              </Fade>

              <Fade duration="300ms" show={showQuorumModal}>
                <PostItQuorum
                  back={this.toggleQuorumPost}
                  isMobile={isMobile}
                  type="user"
                  group={null}
                />
              </Fade>

              <Fade duration="300ms" show={showSlideboxModal}>
                <PostItSlidebox
                  back={this.toggleSlideboxPost}
                  isMobile={isMobile}
                  type="user"
                  group={null}
                />
              </Fade>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect()(withRouter(Header));
export { Header as PureHeader };
