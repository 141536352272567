import React, { Component, Fragment } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Croppie from "croppie";
import _ from "lodash";

import ClosePopupIcon from "../../assets/icons/close-new.svg";
import AccountIcon from "../../assets/icons/account.svg";
import PrimaryButton from "../others/button/primary-btn";
import SecondaryButton from "../others/button/secondary-btn";
import Basic from "./components/EditingTabs/Basic.js";
import Account from "./components/EditingTabs/Account.js";
import Notifications from "./components/EditingTabs/Notifications.js";
import Deactivate from "./components/EditingTabs/Deactivate";
import Delete from "./components/EditingTabs/Delete";
import { upload_avatar } from "../../utils/avatar-utils";
import { getUserDetails, resetUser } from "../../actions/user";
import { getUserStats } from "../../actions/follow";
import ChangeAvatarPreview from "./components/ChangeAvatarPreview.js";
import ProfilePageLayout from "./components/ProfilePageLayout.js";
import { editProfile } from "./services/edit-profile-utils";
import Overlay from "../others/overlay";
// import CloseIcon from '../../assets/icons/close.svg'

class EditProfilePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "Basic",
      viewAvatar: false,
      changeAvatar: false,
      croppieInstance: null,
      inputImageLoad: false,
      socialGraph: false,
      ud: props.ud,
      user: null,
      data: null,
      imgSrc: "/images/default-avatar.svg"
    };
    this.uploadPhotoRef = React.createRef(); // upload photo input
  }

  componentWillMount() {
    let {
      dispatch,
      session: { username }
    } = this.props;
    const { id } = this.state.ud;
    if (id) {
      this.setState({
        imgSrc: `/users/${id}/avatar.jpg`
      });
    }
    dispatch(resetUser());
    dispatch(getUserStats(username));
    dispatch(getUserDetails(username));
  }

  updateState = obj => {
    this.setState(obj);
  };

  fileChange = async ({ target }) => {
    const screenWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    let viewportSize, boundarySize;
    if (screenWidth <= 768) {
      viewportSize = 100;
      boundarySize = 138;
    } else if (screenWidth < 993) {
      viewportSize = 137;
      boundarySize = 175;
    } else {
      viewportSize = 150;
      boundarySize = 200;
    }
    await this.setState({ changeAvatar: true });
    let profileImage = new Croppie(
      document.getElementById("create-profile-image"),
      {
        viewport: { width: viewportSize, height: viewportSize, type: "circle" },
        boundary: { width: boundarySize, height: boundarySize },
        showZoomer: true,
        enableOrientation: true
      }
    );

    let input = target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        profileImage
          .bind({
            url: e.target.result
          })
          .then(function() {
            //console.log('jQuery bind complete');
          });
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      // swal("Sorry - you're browser doesn't support the FileReader API");
    }
    this.setState({ croppieInstance: profileImage, inputImageLoad: true });
  };

  cancleUpload = () => {
    this.setState({ changeAvatar: false, inputImageLoad: false });
    this.uploadPhotoRef.value = "";
  };

  uploadProfileHandler = () => {
    this.state.croppieInstance
      .result({ type: "blob", size: "original" })
      .then(blobData => {
        const options = {
          file: blobData,
          user_id: this.state.ud.id
        };
        this.uploadAvatar(options);
      });
  };

  uploadAvatar = options => {
    upload_avatar({
      file: options.file,
      of: "user",
      group: null
    });
  };

  renderSwitch = () => {
    const { tab } = this.state;
    switch (tab) {
      case "Basic":
        return <Basic onDoneEditing={this.onDoneEditing} />;
      case "Account":
        return <Account onDoneEditing={this.onDoneEditing} />;
      case "Notifications":
        return <Notifications />;
      case "Deactivate":
        return <Deactivate ud={this.state.ud} />;
      case "Delete":
        return <Delete ud={this.state.ud} />;
      default:
        return null;
    }
  };

  gotoProfile = () => {
    this.props.history.push(`/profile/${this.props.ud.username}`);
  };

  onChangingInput = updatedUser => {
    this.setState({
      data: updatedUser
    });
  };

  onDoneEditing = updatedUser => {
    let {
      ud: { username: susername, email: semail },
      dispatch
    } = this.props;
    // sync data from api
    if (this.state.data === null) {
      this.props.toggleEditProfile();
    }
    editProfile(
      {
        susername,
        semail,
        values: updatedUser
      },
      () => {
        // update when success
        dispatch(getUserDetails(updatedUser.username));
        this.props.toggleEditProfile();
      }
    );
  };

  getExtraTopSection = () => {
    return (
      <div className="change_dp">
        <div className="edit-pencil">
          <CreateIcon color="inherit" />
          <input
            type="file"
            id="file_input"
            accept="image/*"
            onChange={this.fileChange}
            ref={ref => (this.uploadPhotoRef = ref)}
          />
        </div>
      </div>
    );
  };

  getTopRightSection = () => {
    return (
      <button className="edit-btn" onClick={this.gotoProfile}>
        Profile
      </button>
    );
  };

  getChangeAvatarSection = () => {
    if (this.state.changeAvatar) {
      return (
        <ChangeAvatarPreview
          uploadProfileHandler={this.uploadProfileHandler}
          cancleUpload={this.cancleUpload}
        />
      );
    }
    return null;
  };

  getTabsSection = () => {
    return (
      <Fragment>
        <span className="editing-tab active" id="Basic">
          Basic Info
        </span>
        <span className="editing-tab" id="Account">
          Account
        </span>
        <span className="editing-tab" id="Notifications">
          Notifications
        </span>
        <span className="editing-tab" id="Deactivate">
          Deactivate account
        </span>
        <span className="editing-tab" id="Delete">
          Delete account
        </span>
      </Fragment>
    );
  };

  render() {
    // ExtraTopSection
    const ExtraTopSection = this.getExtraTopSection();

    // TopRightSection
    const TopRightSection = this.getTopRightSection();

    // ChangeAvatarSection
    const ChangeAvatarSection = this.getChangeAvatarSection();

    // TabsSection
    const TabsSection = this.getTabsSection();

    // MainContentSection
    const MainContentSection = this.renderSwitch();

    // TooltipSection
    const TooltipSection = null;

    return (
      <React.Fragment>
        <Overlay />
        <div className="modal_big">
          <div className="prompt edit-profile">
            <div className="modal_header">
              <div className="prompt-title">
                <img className="header-icon" src={AccountIcon} alt="account" />
                <span className="prompt-title-text">Edit Profile</span>
              </div>
              <img
                className="close-icon"
                src={ClosePopupIcon}
                onClick={this.props.toggleEditProfile}
              />
            </div>
            <div className="modal_middle">
              <div className="modal_left">
                <Basic
                  onDoneEditing={this.onDoneEditing}
                  onChangingInput={this.onChangingInput}
                />
              </div>
              <div className="modal_right">
                {this.state.changeAvatar ? (
                  <ChangeAvatarPreview
                    uploadProfileHandler={this.uploadProfileHandler}
                    cancleUpload={this.cancleUpload}
                  />
                ) : (
                  <React.Fragment>
                    <img
                      src={this.state.imgSrc}
                      className="user-img"
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = "/images/default-avatar.svg";
                      }}
                    />

                    <div className="change_dp">
                      <div className="edit-pencil">
                        <PrimaryButton
                          label="Upload an Image"
                          extraClass="blue upload-img"
                        />
                        <input
                          type="file"
                          id="file_input"
                          accept="image/*"
                          onChange={this.fileChange}
                          ref={ref => (this.uploadPhotoRef = ref)}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="modal_bottom">
              <SecondaryButton
                label="Cancel"
                onClick={this.props.toggleEditProfile}
              />
              <PrimaryButton
                label="Save"
                onClick={() => this.onDoneEditing(this.state.data)}
              />
            </div>
            {/* <div className="prompt-bottom" style={{ marginTop: 0 }}>
                <SecondaryButton
                  label="Cancel"
                  onClick={() => {
                    onClose(false);
                    setUserName(userDetails.userName);
                    setFirstName(userDetails.firstName);
                    setLastName(userDetails.lastName);
                    setProfileImageUrl(userDetails.userImageSrc);
                    setChangeAvatar(false);
                  }}
                />
                <PrimaryButton
                  label="Save"
                  onClick={!profileUploadPending ? handleSaveClick : null}
                  disabled={
                    profileUploadPending ||
                      firstName.trim() === '' ||
                      lastName.trim() === '' ||
                      userName.trim() === ''
                      ? true
                      : false
                  }
                  extraClass="prompt-done"
                />
              </div> */}
          </div>
        </div>
        {/* <ProfilePageLayout
            setRef={null}
            tab={this.state.tab}
            tabType="editing-tab"
            updateState={this.updateState}
            ExtraTopSection={ExtraTopSection}
            ChangeAvatarSection={ChangeAvatarSection}
            TopRightSection={TopRightSection}
            TabsSection={TabsSection}
            MainContentSection={MainContentSection}
            TooltipSection={TooltipSection}
          /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => ({
  ud: store.User.user_details,
  session: store.User.session,
  current_user_details: store.User.current_user_details
});

export default connect(mapStateToProps)(withRouter(EditProfilePopup));
