import React from 'react'
import PropTypes from 'prop-types'
import ClosePopupIcon from '../../../assets/icons/close-new.svg'

const ModalHeader = ({ title, onClick, type, img, alt, extraClass }) => (
  <div className="prompt-top">
    <div className="top-icon-and-text">
      <img src={img} alt={alt} className={extraClass ? extraClass : ""} />
      <span className="prompt-title">{title}</span>
    </div>
    {type !== "share" && (
      <img className="close-icon" src={ClosePopupIcon} onClick={onClick} />
    )}
  </div>
)

ModalHeader.defaultProps = {
  title: '',
}

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default ModalHeader
