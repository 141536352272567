import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes, { object } from "prop-types";
import CollapsibleIcon from "../../../../assets/icons/dropdown-icon-new.svg";
import "./viewer-collapsible-sidebar.scss";
import { toggleSidebarAction } from "../../../../actions/settings";
import OpenSeadragon from "../../utils/fabricjs-overlay";
import { getAnnotationArea } from '../../../../utils/utils';
import { SocketContext } from '../../../../services/ServiceContext';


const ViewerCollapsibleSidebar = ({
  imageMetadata,
  showCollapsibleSidebar,
  toggleSidebar,
  viewer
}) => {
  const socket = useContext(SocketContext);
  const annotationList = useSelector(state => state.Viewer.annotationData);
  const scaleUnit = useSelector(state => state.Viewer.scaleUnit);
  const scaleRatio = useSelector(state => state.Viewer.scaleRatio);
  const dispatch = useDispatch();
  useEffect(() => {
    // close sidebar
    dispatch(toggleSidebarAction(false));
    // open collapsible sidebar contents
    const expansibleItems = document.getElementsByClassName('default_open');
    for (let i = 0; i < expansibleItems.length; i++) {
      toggleExpansible(expansibleItems[i])
    }

    socket.getAnnotationMessageSubject().subscribe((item) => {
      if (item && item.fromSrc === 'toolbar-annotation') {
        // get current active annotation
        let activeAnno = document.querySelectorAll('.viewer-annotation-detail.active');
        if (activeAnno && activeAnno.length > 0 && activeAnno[0].getAttribute('data-pk') == item.pk) {
          return;
        }
        collapseAllAnnotations();
        let anno = document.querySelectorAll(`.viewer-annotation-detail[data-pk="${item.pk}"]`);
        if (anno && anno.length > 0) {
          toggleExpansible(anno[0]);
        }
      }
    })
  }, []);

  const toggleExpansible = (e) => {
    let el = e.target ? e.target : e;
    el.classList.toggle("active");
    let content = el.nextElementSibling;
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  };

  const collapseExpansible = e => {
    let el = e.target ? e.target : e;
    el.classList.remove("active");
    let content = el.nextElementSibling;
    content.style.display = "none";
  }

  const rotate = (cx, cy, x, y, angle) => {
    var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = (cos * (x - cx)) - (sin * (y - cy)) + cx,
      ny = (cos * (y - cy)) + (sin * (x - cx)) + cy;
    return [nx, ny];
  }
  const fitViewportToObject = item => {

    var radians = (Math.PI / 180) * item.angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      centerX = item.left + (cos * item.width) / 2,
      centerY = item.top + (sin * item.height) / 2;

    const itemXY = rotate(centerX, centerY, item.left, item.top, (-1 * item.angle))
    const nVX = (itemXY[0]) / imageMetadata.width;
    const nVY = (itemXY[1]) / imageMetadata.width;
    const nVW = (item.width * item.scaleX) / imageMetadata.width;
    const nVH = (item.height * item.scaleY) / imageMetadata.width;
    const newBounds = new OpenSeadragon.Rect(nVX - 0.1, nVY - 0.1, nVW + 0.2, nVH + 0.2);

    viewer.viewport.fitBounds(newBounds)
    return newBounds;
  };

  const collapseAllAnnotations = () => {
    const expansibleItems = document.querySelectorAll('.viewer-annotation-detail');
    for (let i = 0; i < expansibleItems.length; i++) {
      collapseExpansible(expansibleItems[i])
    }
  }

  const onAnnotationSelect = (e, item) => {
    // close all
    if (!e.target.classList.contains('active')) {
      collapseAllAnnotations();
    }

    toggleExpansible(e)
    if (viewer) {
      fitViewportToObject(item)
    }
  }

  const onEditAnnotation = item => {
    socket.getAnnotationMessageSubject().next({ ...item, fromSrc: 'collapsible-sidebar' });
  }

  const getAnnotationType = item => {
    if (!item || !item.type) {
      return '';
    }

    switch (item.type) {
      case 'ellipse':
        return 'Ellipse';
      case 'arrow':
        return 'Arrow';
      case 'path':
        return 'Path';
      case 'rect':
        return 'Rectangle';
    }

    return '';
  }

  return (
    <div
      id="viewer-collapsible-sidebar"
      className={`viewer-collapsible-sidebar-container viewer-sidebar-${showCollapsibleSidebar ? "open" : "collapsed"
        }`}
    >
      <div className="viewer-collapsible-sidebar-header">
        {showCollapsibleSidebar && <span> SLIDE MANAGER </span>}
        <img
          onClick={toggleSidebar}
          src={CollapsibleIcon}
          alt="menu"
          className={showCollapsibleSidebar ? '': 'rotate-icon'}
        />
      </div>

      {showCollapsibleSidebar && (
        <div className="viewer-collapsible-sidebar-content">
          <div className="expansible-item">
            <div className="expansible-header" onClick={toggleExpansible}>
              <span className="anno-details">Slide Details</span>
            </div>
            <div class="expansible-content">
              <div className="item">
                <div>Filename:</div>
                <div className="file-name">
                  {imageMetadata["aperio.Filename"]
                    ? imageMetadata["aperio.Filename"]
                    : "-"}
                </div>
              </div>
              <div className="item">
                <div>Height:</div>
                <div className="file-name">{imageMetadata.height ? imageMetadata.height : "-"}</div>
              </div>
              <div className="item">
                <div>Width:</div>
                <div className="file-name">{imageMetadata.width ? imageMetadata.width : "-"}</div>
              </div>
              <div className="item">
                <div>Magnification:</div>
                <div className="file-name">
                  {imageMetadata["openslide.objective-power"]
                    ? imageMetadata["openslide.objective-power"]
                    : "-"}
                </div>
              </div>
              <div className="item">
                <div>Resolution:</div>
                <div className="file-name">
                  {imageMetadata["aperio.MPP"]
                    ? imageMetadata["aperio.MPP"]
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="expansible-item">
            <div className="expansible-header" onClick={toggleExpansible}>
              <span className="anno-details">Annotation Details</span>
            </div>
            <div class="expansible-annotation-details">
              {annotationList.map(item => (
                <div className="no-border">
                  <div
                    data-pk={item.pk}
                    style={{ color: item.stroke ? item.stroke : "black" }}
                    className="expansible-annotation-header header-close viewer-annotation-detail"
                    onClick={(e) => onAnnotationSelect(e, item)}>
                    {item.text ? item.text : "No Description"}
                  </div>
                  <div class="expansible-content">
                    <div className="item">
                      <div>Annotator:</div>
                      <div className="file-name">
                        {item.firstname ? (item.firstname + ' ' + item.surname) : ''}
                      </div>
                    </div>
                    <div className="item">
                      <div>Type:</div>
                      <div className="file-name">{getAnnotationType(item)}</div>
                    </div>
                    {getAnnotationArea(item.type, item.height, item.width, scaleUnit, scaleRatio) && (
                      <div className="item">
                        <div>Area:</div>
                        <div className="file-name">
                          {getAnnotationArea(item.type, item.height, item.width, scaleUnit, scaleRatio)}
                          <sup>2</sup>
                        </div>
                      </div>
                    )
                    }
                    <div className="item annotation-link" onClick={() => onEditAnnotation(item)}>
                      <div>Edit Annotation</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ViewerCollapsibleSidebar.propTypes = {
  imageMetadata: PropTypes.object.isRequired
};

export default ViewerCollapsibleSidebar;
