import React from "react";

const User = ({ img, fullName }) => (
  <div className="user">
    <div
      style={{
        height: "50px",
        display: "flex",
        alignItems: "center",
        margin: "0 0 0 15px"
      }}
    >
      <img
        src={img}
        alt="user"
        height="35"
        width="35"
        style={{ borderRadius: "100%" }}
      />
    </div>
    <h3>{fullName}</h3>
  </div>
);

export default User;
