import React, { Fragment, useState, useRef } from "react";
import { FadeIn } from "animate-components";
import _ from "lodash";
import Overlay from "../../others/overlay";
import Button from "../../others/button/primary-btn";
import { Tabs, Tab } from "react-bootstrap";
import MemberInvitation from "../../tumor-boards/new-components/member-invitation";
import User from "../../tumor-boards/new-components/user";
import axios from "../../../utils/axios-instance-utils";
import Notify from "handy-notification";
import MultiSelect from "../../post/post-it/select-search";
import { isMine } from "../../../utils/utils";
import ModalHeader from "../../others/modal/modal-header";
import ViewDetailsIcon from "./icons/view-details.svg";
import PrimaryButton from "../../others/button/primary-btn";
import SecondaryButton from "../../others/button/secondary-btn";

const closeSrc = "/images/img/closeModal.svg";

const FolderDetails = ({
  onClose,
  selectedItem: item,
  collaborators: receivedCollaborators,
  folderDetails,
  isShared,
  isFile,
  onSubmitEdit
}) => {
  const [fileDetails, setFileDetails] = useState(item);
  const [invitedCollaborators, setInvitedCollaborators] = useState([]);
  const [collaborators, setCollaborators] = useState(receivedCollaborators);
  const [inviteByEmail, setInviteByEmail] = useState(false);
  const emailInputRef = useRef(null);
  const [emailInputValue, setEmailInputValue] = useState("");
  const [editing, setEditing] = useState(false);

  const bytesToSize = bytes => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.abs(bytes / Math.pow(1024, i), 2).toFixed(2) + " " + sizes[i];
  };

  const generalTabContent = () => (
    <Fragment>
      <div className="general-information">
        {isFile ? (
          <Fragment>
            <div className="information-row">
              <div className="information-row-label">File name:</div>
              <div className="information-row-content">
                <b>{fileDetails.file_name}</b>
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Description:</div>
              <div className="information-row-content">
                {editing ? (
                  <textarea
                    rows="3"
                    value={fileDetails.description}
                    onChange={onDescriptionChange}
                  ></textarea>
                ) : (
                  <b>{fileDetails.description}</b>
                )}
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Size:</div>
              <div className="information-row-content">
                <b>{item.sizeStr}</b>
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Type:</div>
              <div className="information-row-content">
                <b>{item.fileType}</b>
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Labels:</div>
              <div className="information-row-content">
                {editing ? (
                  <MultiSelect
                    classname="select_case"
                    defaultValue={
                      fileDetails.labels
                        ? Array.isArray(fileDetails.labels)
                          ? fileDetails.labels.map((l, i) => ({
                            value: i,
                            label: l.label
                          }))
                          : fileDetails.labels.split(",").map((l, i) => ({
                            value: i,
                            label: l
                          }))
                        : ""
                    }
                    handleChange={handleSelectChange} //TODO: REPLACE WITH THE HANDLESELECTCHANGE FUNCTION; Make an appearing disappearing grey box shown in psd
                    options={[]}
                    placeholder="Slide labels"
                    customStyles={true}
                    style={{ width: "100%", marginTop: 10 }}
                  />
                ) : fileDetails.labels ? (
                  Array.isArray(fileDetails.labels) ? (
                    fileDetails.labels.map((l, i) => (
                      <Label key={i} label={l.label} onClick={() => { }} />
                    ))
                  ) : (
                    fileDetails.labels
                      .split(",")
                      .map((label, i) => (
                        <Label key={i} label={label} onClick={() => { }} />
                      ))
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Stain:</div>
              <div className="information-row-content">
                {!editing && <b>{fileDetails.stain}</b>}
                {editing && (
                  <MultiSelect
                    classname="stain-select"
                    defaultValue={{
                      value: fileDetails.stain,
                      label: fileDetails.stain
                    }}
                    handleChange={handleSelectStainChange}
                    type={"stains"}
                    placeholder="Stains"
                    customStyles={true}
                    style={{ width: "100%", marginTop: 10 }}
                  />
                )}
              </div>
            </div>

            <div className="information-row">
              <div className="information-row-label">Uploaded On:</div>
              <div className="information-row-content">
                <b>{item.uploadedAt}</b>
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Owner:</div>
              <div className="information-row-content">
                <b>
                  {fileDetails.firstname} {fileDetails.surname}
                </b>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="information-row">
              <div className="information-row-label">Folder name:</div>
              <div className="information-row-content">
                <b>{folderDetails.folderName}</b>
              </div>
            </div>
            {/* <div className="information-row">
            <div className="information-row-label">Description:</div>
            <div className="information-row-content">
              {editing ? (
                <textarea
                  rows="3"
                  value={fileDetails.description}
                  onChange={onDescriptionChange}
                ></textarea>
              ) : (
                <b>{fileDetails.description}</b>
              )}
            </div>
          </div> */}

            <div className="information-row">
              <div className="information-row-label">Size:</div>
              <div className="information-row-content">
                <b>{bytesToSize(folderDetails.allFileSizeSum)}</b>
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Created On:</div>
              <div className="information-row-content">
                <b>{folderDetails.folderMetadata.LastModified}</b>
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Owner:</div>
              <div className="information-row-content">
                <b>{folderDetails.folderOwner}</b>
              </div>
            </div>
            <div className="information-row">
              <div className="information-row-label">Number of Files:</div>
              <div className="information-row-content">
                <b>{folderDetails.childCount}</b>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <div className="modal_bottom">
        <React.Fragment>
          {!item.isSharedDirectory &&
            isMine(item.user_id) &&
            isFile &&
            !item.isSharedFile &&
            (editing ? (
              <PrimaryButton onClick={edit} label="Save" />
            ) : (
              <PrimaryButton onClick={() => setEditing(true)} label="Edit" />
            ))}
        </React.Fragment>
      </div>
    </Fragment>
  );

  const collaboratorsTabContent = () => (
    <Fragment>
      <div className="collaborator-list">
        {collaborators &&
          collaborators.map((c, i) => (
            <Collaborator
              key={i}
              img={c.img}
              name={c.name}
              onRemove={() => removeSlideboxCollaborators(i)}
            />
          ))}
      </div>
      {!item.isSharedDirectory && (
        <Fragment>
          <div className="invite-header">
            <span>Invite</span>
          </div>
          {!inviteByEmail ? (
            <Fragment>
              <MemberInvitation
                onMemberSelect={addMember}
                isCollaborator={true}
                filePath={item.file_path}
              />
              <div className="users">
                {invitedCollaborators.map((member, i) => (
                  <User
                    key={i}
                    img={`/users/${member.id}/avatar.jpg`}
                    fullName={`${member.firstname} ${member.surname}`}
                  />
                ))}
              </div>
            </Fragment>
          ) : (
            <div className="members-input-wrapper">
              <div>
                <input
                  type="text"
                  placeholder="Email"
                  onChange={onEmailInputChange}
                  ref={emailInputRef}
                />
              </div>
            </div>
          )}
          <div className="invite-option">
            <span>Invite through email&nbsp;</span>
            <label class="switch small">
              <input
                type="checkbox"
                onChange={() => setInviteByEmail(!inviteByEmail)}
                defaultChecked={inviteByEmail}
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div className="modal_bottom">
            {(!inviteByEmail
              ? invitedCollaborators && invitedCollaborators.length > 0
              : emailInputValue) && (
                <React.Fragment>
                  <SecondaryButton label="Cancel" onClick={onClose} />
                  <PrimaryButton
                    label="Invite"
                    onClick={addSlideboxCollaborators}
                  />
                </React.Fragment>
              )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );

  const getContent = () => {
    return (
      <Tabs
        defaultActiveKey={!isShared ? "general" : "collaborators"}
        transition={false}
      >
        <Tab
          eventKey="general"
          title="General"
          tabClassName="general-tab tab-heading"
        >
          {generalTabContent()}
        </Tab>
        {!item.isSharedDirectory &&
          !item.isSharedFile &&
          item.module_name !== "public" && (
            <Tab
              eventKey="collaborators"
              title="Collaborators"
              tabClassName="collaborators-tab tab-heading"
            >
              {collaboratorsTabContent()}
            </Tab>
          )}
      </Tabs>
    );
  };

  const addMember = user => {
    setInvitedCollaborators([user, ...invitedCollaborators]);
  };

  const onEmailInputChange = () => {
    setEmailInputValue(emailInputRef.current.value);
  };

  const addSlideboxCollaborators = async () => {
    let collaboratorsArray;
    if (!inviteByEmail) {
      collaboratorsArray = invitedCollaborators.map(data => {
        return {
          collaboratorId: data.id,
          isRegisterdUser: true,
          newUserEmail: null
        };
      });
    } else {
      const email = emailInputRef.current.value;
      const re = /\S+@\S+\.\S+/;
      if (!re.test(email)) {
        return Notify({ value: "Invalid email" });
      }
      collaboratorsArray = [
        {
          collaboratorId: null,
          isRegisterdUser: null,
          newUserEmail: email
        }
      ];
    }
    const payload = {
      collaboratorDataArray: collaboratorsArray,
      path: isFile ? fileDetails.file_path : item.name,
      pathId: isFile ? fileDetails.slide_box_id : item.path,
      isFolder: !isFile
    };
    let response;
    try {
      response = await axios.post("/api/addSlideboxCollaborator", payload);
    } catch (err) {
      if (_.get(err, "response.data.code") === "ER_DUP_ENTRY") {
        Notify({ value: "Collaborator already exists" });
      } else {
        Notify({ value: "Error occurred" });
      }
    }
    if (response.status === 200) {
      if (response.data.msg === "success") {
        if (!inviteByEmail) {
          Notify({ value: `${!isFile ? "Folder" : "File"} shared` });
          onClose();
        } else {
          Notify({ value: "Invitation sent" });
          emailInputRef.current.value = "";
          setEmailInputValue("");
        }
      } else {
        if (response.data.code === "ER_DUP_ENTRY") {
          if (!inviteByEmail) {
            Notify({ value: `${!isFile ? "Folder" : "File"} alredy shared` });
          } else {
            Notify({
              value: `Email alredy added to this ${!isFile ? "Folder" : "File"}`
            });
          }
        }
      }
    }
  };

  const removeSlideboxCollaborators = index => {
    const collaborator = collaborators[index];
    if (!collaborator) return;
    const payload = {
      collaboratorIdArray: [{ collaboratorId: collaborator.id }],
      pathId: isFile ? fileDetails.file_path : item.path
      // pathId: isFile ? fileDetails.slide_box_id : fileDetails.folder_id
    };
    axios
      .post("/api/deleteSlideboxCollaborator", payload)
      .then(response => {
        if (response.status === 200) {
          collaborators.splice(index, 1);
          setCollaborators([...collaborators]);
          Notify({ value: "Removed collaborator" });
        }
      })
      .catch(error => {
        //console.log(error);
      });
  };

  const onDescriptionChange = event => {
    fileDetails.description = event.target.value;
    setFileDetails({ ...fileDetails });
  };

  const edit = async () => {
    try {
      await onSubmitEdit({
        slide_box_id: fileDetails.slide_box_id,
        description: fileDetails.description,
        labels: fileDetails.labels,
        stain: fileDetails.stain
      });
    } catch (err) {
      Notify({ value: "Error occurred" });
      return;
    }
    setEditing(false);
    Notify({ value: `Edited successfully` });
  };

  const handleSelectChange = selectedOption => {
    setFileDetails({ ...fileDetails, labels: selectedOption });
  };

  const handleSelectStainChange = ({ value }) => {
    setFileDetails({ ...fileDetails, stain: value });
  };

  return (
    <Fragment>
      <Overlay />
      <div className="prompt customModal folder_details">
        <FadeIn duration="300ms">
          <ModalHeader
            title={isFile ? "File Information" : "Folder Information"}
            onClick={onClose}
            img={ViewDetailsIcon}
            alt="details"
            extraClass="detailsIcon"
          />
          <div className="modal_middle">{getContent()}</div>
        </FadeIn>
      </div>
    </Fragment>
  );
};

const Collaborator = ({ name, img, onRemove }) => {
  return (
    <div className="collaborator">
      <User img={img} fullName={name} />
      <button
        type="button"
        onClick={onRemove}
        className="btn btn-light remove-collaborator"
      >
        Remove
      </button>
    </div>
  );
};

const Label = ({ label, onClick }) => {
  return (
    <span className="lable_tags" onClick={onClick}>
      <a href="javascript:;">{label}</a>
    </span>
  );
};

export default FolderDetails;
