import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ImageSlider from "./image-carousel";
import Store from "./utils/Store";
import { withRouter } from "react-router-dom";
import {
  BaseImageServerUrl,
  ComplexImageTypes
} from "../../../../config/constants";
import Modal from "../post/post-middle/image-modal";
import 'react-dropzone-uploader/dist/styles.css'

const start_url = `${BaseImageServerUrl}/load/Upload/start`;
const continue_url = `${BaseImageServerUrl}/load/Upload/continue/`;
const finish_url = `${BaseImageServerUrl}/load/Upload/finish/`;
const upload_url = `${BaseImageServerUrl}/load/Slide/upload`;
const check_url = `${BaseImageServerUrl}/load/Slide/info/`;
const thumb_url = `${BaseImageServerUrl}/load/Slide/thumb/`;
const chunkSize = 5 * 1024 * 1024;

const store = new Store(`${BaseImageServerUrl}/data/`);

const thumbsContainer = {
  marginTop: 16
};

const thumbsContainerNew = {
  marginTop: 16,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap"
};



const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box"
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden"
};

const showImage = {
    marginLeft: 50,
    position: "absolute",
    color: "rgb(0, 0, 0)",
    top: -5,
    zIndex: 999
}

const thumbInnerSpan = {
  display: "flex",
  minWidth: "100%",
  overflow: "hidden",
  position: "absolute",
  margin: "35px 22px",
  fontWeight: "bold",
  opacity: "2"
};

const img = {
  display: "block",
  width: "auto",
  height: "100%"
};

const removeThumb = {
  marginLeft: "84px",
  position: "absolute",
  color: "rgb(0, 0, 0)",
  top: "-10px",
  zIndex: 999,
  fontSize:"17px"
}
const resize ={
  marginLeft: "63px",
  position: "absolute",
  color: "rgb(0, 0, 0)",
  top: "-10px",
  zIndex: 999,
}


function ImageUploadSection(props) {
    
    const [files, setFiles] = useState([]);
    const [canvas,setcanvasImage] = useState([]);
    const [mouseOver, setMouseOver] = useState(false);
    const [mouseOverImage, setMouseOverImage] = useState(false);
    const [comleplexFiles, setcomleplexFiles] = useState(false);
    const [fileUploadAmount, setfileUploadAmount] = useState(0);
    const [showImgModal, setShowImgModal] = useState({
            state: false,
            imageSrc: []
    });
    const [propsImages,setpropsimages] = useState([]);

    const handleSubmit = async files => {
           // console.log(files)
            const isComplexImage = checkImageIsComplex(
                files[0].meta.name.split(".")[1]
            );

            if (isComplexImage) {
                //console.log(isComplexImage)
                uploadFileToProcees(files.meta);
            } else {
                // setFiles(
                //     files.map(file =>
                //         Object.assign(file, {
                //             preview: URL.createObjectURL(file)
                //         })
                //     )
                // );
                props.fileChange(files);
            }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
        const isComplexImage = checkImageIsComplex(
            acceptedFiles[0].name.split(".")[1]
        );
        if (isComplexImage) {
            uploadFileToProcees(acceptedFiles);
        } else {
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
                if(files.length > 0){
                    for (let i = 0; i < files.length; i++) {
                        acceptedFiles.push(files[i]); 
                    }
                }
                setFiles( 
                    acceptedFiles
                );
            props.fileChange(acceptedFiles);
         }
        }
    });

    const checkImageIsComplex = imgtype => {
        return ComplexImageTypes.some(type => type === imgtype.toLowerCase());
    };

    const uploadFileToProcees = async files => {
        setcomleplexFiles(true);
        for (let i = 0; i < files.length; i++) {
            const selectedFile = files[i];
            const customFileName = `${Date.now()}-${selectedFile.name.toLowerCase()}`;
            let token = await start_upload(customFileName);
            readFileChunks(selectedFile, token, customFileName);
        }
    };

    const start_upload = async filename => {
        let body = { filename: filename };
        let token = await fetch(start_url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        }
        }).then(x => x.json());
        try {
        let a = token;
        return a["upload_token"];
        } catch (e) {
        //  console.log("test", e);
        }
    };

    const readFileChunks = async (file, token, name) => {
        let part = 0;
        let complete = false;
        let fileName = name;
        let fileSize = file.size;
        while (!complete) {
        try {
            let data = await promiseChunkFileReader(file, part);
            let body = {
            chunkSize: chunkSize,
            offset: part * chunkSize,
            data: data
            };
            await continue_upload(token)(body);
            part++;
            let uploadedAmount = 0;
            if (chunkSize > fileSize) {
            uploadedAmount = 100;
            } else {
            uploadedAmount = ((chunkSize * part) / fileSize) * 100;
            uploadedAmount = uploadedAmount > 100 ? 100 : uploadedAmount;
            }
            setfileUploadAmount(Math.floor(uploadedAmount));
        } catch (e) {
            //console.log(e);
            complete = true;
            finish_upload(fileName, token);
        }
        }
    };

    const continue_upload = token => {
        return async function(body) {
        return await fetch(continue_url + token, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
            "Content-Type": "application/json; charset=utf-8"
            }
        });
        };
    };

    const finish_upload = async (filename, token) => {
        let body = { filename: filename };
        let reg_req = await fetch(finish_url + token, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        }
        });

        if (!reg_req["ok"]) {
        // reset = true;
        } else {
        props.back();
        props.history.push({
            pathname: `/viewer`,
            search: `?image=${filename}`
        });
        // handlePost(filename, filename, false);
        }
    };

    const promiseChunkFileReader = (file, part) => {
        return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = evt => {
            if (evt.target.error == null) {
            let d = evt.target.result.split(",")[1];
              if (d) {
                  resolve(d);
              } else {
                  reject("Done Reading");
              }
            } else {
            reject(evt.target.error);
            }
        };
        const blob = file.slice(part * chunkSize, (part + 1) * chunkSize);
        fr.readAsDataURL(blob);
        });
    };

    const handlePost = (filename, slidename, reset) => {
        fetch(check_url + filename)
        .then(
            response => response.json() // if the response is a JSON object
        )
        .then(data => {
            data["upload_date"] = new Date(Date.now()).toLocaleString();
            data.name = slidename;
            data.location = "/images/" + filename;
            data.study = "";
            data.specimen = "";
            data.mpp = parseFloat(data["mpp-x"]) || parseFloat(data["mpp-y"]) || 0;
            data.mpp_x = parseFloat(data["mpp-x"]);
            data.mpp_y = parseFloat(data["mpp-y"]);
            store
            .post("Slide", {}, data)
            .then(success => {
                fetch(`${BaseImageServerUrl}/data/Slide/find`).then(datas => {
                datas.json().then(datalagi => {
                    const lulu = datalagi.filter(
                    a => a.upload_date === data["upload_date"]
                    );
                    props.back();
                    props.history.push({
                    pathname: `/viewer`,
                    search: `?image=${filename}`
                    });
                });
                });
            })
            .catch(
                error => console.log(error) // Handle the error response object
            );
        })
        .catch(
            error => console.log(error) // Handle the error response object
        );
    };

    const viewPrview = (e, imgSrc) => {
        e.preventDefault();
        setShowImgModal({
        state: true,
        imageSrc: [imgSrc]
        });
        if (!props.isMobile) {
        props.hidePostModal(true);
        }
    };

    const onClose = () => {
        if (!props.isMobile) {
        props.hidePostModal(false);
        }
        setShowImgModal({
        state: false,
        imageSrc: ""
        });
    };

    const removed = (key, removeArray) => {
        let array = [];
        if(removeArray[0].image){
            for (let i=0; i < removeArray.length; i++) { 
                if(removeArray[i].image !== key){
                    array.push(removeArray[i]) 
                }
            }
            // console.log(props.fileChange(array))
            props.fileChange(array);
            setpropsimages(array)
        }

        if(removeArray[0].preview){
            removeArray.splice(key, 1);
            props.fileChange(removeArray);
            setFiles(removeArray) ;
        }
    }

    const removeImage = (e, imgSrc) => {
        // e.preventDefault();
        
        if(propsImages){
        var array = [...propsImages]
        }else{
        var array = [...files];
        }
        
        let data = removed(imgSrc, array)
        
        // var index = array.indexOf(e.target.value)
        // if (index !== -1) {
        //   array.splice(index, 1);
        //   console.log(array)
        // }
    };

  const zoomImage = (e, image) => {
    setMouseOver(true)
    setMouseOverImage(image)
  }

  // console.log('files', mouseOver);
 
 
  const thumbs = !propsImages
    ? files.map((file, index) => {
      
    //   const fileTypes = ['tiff', 'tif'];
    //   const extension = file.name.split(".").pop().toLowerCase();
    //   const success = fileTypes.indexOf(extension) > -1;
    //   if(success){
    //     setcanvasImage(success)
    //     // var tiff = new Tiff({
    //     //   buffer: e.target.result
    //     // });
    //     // var tiffCanvas = tiff.toCanvas();
    //     // console.log(tiffCanvas)
    //   }
     
      return ( <React.Fragment>
          <div style={thumb} key={file.name} className="case-image-wrapper">
            <div style={thumbInner} > 
              <a onClick={e => removeImage(e, index)} style={removeThumb}><i class="fas fa-times-circle"></i></a>
              <a 
                // onClick={e => viewPrview(e, file.preview)} 
                onMouseEnter={e => zoomImage(e, file.preview)} 
                // onMouseLeave={e => setMouseOver(false)} 
                style={resize}><img src={require('../../../resize.png')} />
              </a>
              <img src={file.preview} style={img}  />
              {/* {canvas? <canvas></canvas> : <img src={file.preview} style={img}  />} */}
            </div>
              
          </div>
        </React.Fragment>
      )})
    : propsImages.map(file => (
        <div style={thumb} key={file.id} className="case-image-wrapper">
          <div style={thumbInner}>
            <a onClick={e => removeImage(e, file.image)} style={removeThumb}><i class="fas fa-times-circle" ></i></a>
            <a onClick={e => viewPrview(e, file.image)} 
              onMouseEnter={e => zoomImage(e, file.image)} 
                onMouseLeave={e => setMouseOver(false)} 
                style={resize}><img src={require('../../../resize.png')} /></a>
            <img src={file.image} style={img} 
              // onClick={e => viewPrview(e, file.image)} 
            />
          </div>
            {/* <span style={showImage} onClick={e => removeImage(e, file.image)} style={removeThumb}><i class="fas fa-camera-retro" ></i></span> */}
        </div>
      ));

  useEffect(() => {
    //   console.log('props.images', props.images);
        props.fileChange(props.images)
        setpropsimages(props.images)
    }, [props.images]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => {
        URL.revokeObjectURL(file.preview);
      });
    },
    [files]
  );

  return (
    <React.Fragment>
      {/* <section className="container">
        <div {...getRootProps({ className: "dropzone row",onDrop: event => event.stopPropagation() })}>
          <input {...getInputProps()} multiple={true}/>
          <div className="p_main_image_section col-lg-3">
            <img
              src="../images/icons/neutral/icons8-upload_to_cloud.png"
              alt="Uplaod"
            />
          </div>

          <div className="p_main_details_section col-lg-9">
            <span className="bold_drag">Drag & Drop</span>
            <span className="non_bold_drag">or click here to upload image</span>
          </div>
        </div>
       
        {!comleplexFiles ? (
          <React.Fragment>
            {thumbs.length > 0 && (
              <ImageSlider
                isMobile={props.isMobile}
                thumbsLength={thumbs.length}
              >
                <aside style={thumbsContainer}>{thumbs}</aside>
              </ImageSlider>
            )}
          </React.Fragment>
        ) : (
          <aside style={thumbsContainerNew}>
            <span className="complex_image_process">
              Uploaded ... ({`${fileUploadAmount}%`})
            </span>
          </aside>
        )}
        
        <img src={mouseOverImage} 
            style={{
              display: mouseOver?"block":"none",
              width: "640px",
              height:"360px",
              position: "absolute",
              top: "2%",
              right: "18%",
              border: "3px solid #8356d294",
            }} />
        
      </section> */}
      {/* {showImgModal.state && (
        <Modal onClose={onClose} index={0} images={showImgModal.imageSrc} />
      )} */}
    </React.Fragment>
  );
}
export default withRouter(ImageUploadSection);

