import axios from "./axios-instance-utils";
import Notify from "handy-notification";
import d from "./API/DOM";
import { ObjectMssg } from "./utils";
import Action from "./API/Action";

/**
 * For username checker
 * @param {String} el
 */
export const username_checker = el => {
  let element = new d(el);
  let uc = new d(".username_checker");
  element.on("keyup", async e => {
    let value = e.target.value;
    uc.show();

    if (value) {
      let { data: count } = await axios.post("/user/username-checker", {
        value
      });
      let el = document.getElementById("username_checker");
      el && el.parentNode.removeChild(el);
      let html;

      if (count == 0) {
        html =
          '<span id="username_checker" class="checker_text" data-valid="valid">username is available</span><span class="checker_icon"><i class="far fa-smile"></i></span>';

        uc.mutipleCSS({
          width: "160px",
          right: "-188px"
        });
      } else {
        html =
          '<span id="username_checker" class="checker_text" data-valid="invalid">username already taken</span><span class="checker_icon"><i class="far fa-frown"></i></span>';

        uc.mutipleCSS({
          width: "167px",
          right: "-194px"
        });
      }

      uc.html(html);
    } else {
      uc.hide();
    }
  });

  element.on("blur", () => uc.hide());
};

// export const username_checkapi = username => {
//   if (username) {
//     let { data: count } = await axios.post('/user/username-checker', { username });
//     let el = document.getElementById('username_checker');
//      el  &&  el.parentNode.removeChild(el) ;
//     let html;
//     if (count == 0) {
//       html =
//         '<span id="username_checker" class="checker_text" data-valid="valid">username is available</span><span class="checker_icon"><i class="far fa-smile"></i></span>'

//       uc.mutipleCSS({
//         width: '160px',
//         right: '-188px',
//       })
//     } else {
//       html =
//         '<span id="username_checker" class="checker_text" data-valid="invalid">username already taken</span><span class="checker_icon"><i class="far fa-frown"></i></span>'

//       uc.mutipleCSS({
//         width: '167px',
//         right: '-194px',
//       })
//     }
// }

/**
 * Common function for login & signup
 *
 * @param {Object} options Options
 * @param {Object} options.data
 * @param {String} options.btn
 * @param {String} options.url
 * @param {String} options.redirect
 * @param {String} options.defBtnValue
 */
export const commonLogin = options => {
  let { data, btn, url, redirect, defBtnValue, when } = options,
    overlay2 = new d(".overlay-2"),
    button = new d(btn),
    action = new Action(btn);

  action.start("Please wait..");
  axios
    .post(url, data)
    .then(s => {
      let {
        data: { mssg, success, insertId, authToken, sessData }
      } = s;
      if (success) {
        if (when === "signup") {
          Notify({
            value: mssg,
            done: () => (location.href = redirect)
          });
          sessionStorage.setItem("signupId", insertId);
        }
        if (when === "create_profile" || when === "login") {
          if (when === "create_profile") {
            Notify({
              value: mssg,
              done: () => (location.href = redirect)
            });
          } else {
            const redirectPath =
              redirect === "/"
                ? "/"
                : location.protocol + "//" + location.host + redirect;
            sessionStorage.setItem("isLogin", true);
            Notify({
              value: mssg,
              done: () => (window.location.href = redirectPath)
            });
          }
        }

        if (when === "forgot_password") {
          new d(".reset-success-msg").html("");
          Notify({
            value: "Reset password link is sent to your mail"
          });
          new d(".reset-success-msg").html(mssg);
          action.end(defBtnValue);
          new d(".fp_submit").removeAttr("disabled");
        }

        if (when === "reset_password") {
          new d(".s_submit").removeAttr("disabled");
          const redirectPath = redirect === "/";
          Notify({
            value: mssg,
            done: () => (window.location.href = redirectPath)
          });
        }

        if (btn !== "fp_submit") {
          new d(".s_submit").removeAttr("disabled");
          button.setValue("Redirecting..");
          overlay2.show();
        }
      } else {
        Notify({
          value: ObjectMssg(mssg)
        });
        new d(".s_submit").removeAttr("disabled");
        action.end(defBtnValue);
      }
      button.blur();
    })
    .catch(e => {
      new d("#user_signup_btn").removeAttr("disabled");
      //console.log(e)
    });
};
