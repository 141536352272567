import React, { useState } from "react";
import { FadeIn } from "animate-components";
import ToolTip from "react-tooltip";
import { connect } from "react-redux";
import AnnotationColorPicker from "./annotation-color-picker";

import DeleteIcon from "../icons/delete-new.svg";
import ColorChangeIcon from "../icons/color-plate.svg";
import RenameIcon from "../icons/edit-anno-icon.svg";

import {
  getAnnotationArea,
  getAnnotationPopupPosition
} from "../../../utils/utils";

const AnnotationDetailsPopup = ({
  deleteBtnOnClick,
  editBtnOnClick,
  currentAnnotationName,
  onColorChange,
  when,
  currentAnnotationData = {},
  scaleUnit,
  scaleRatio
}) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const colorBtnOnClick = () => {
    setOpenColorPicker(!openColorPicker);
  };

  const getPositionStyle = () => {
    if (
      !currentAnnotationData ||
      Object.keys(currentAnnotationData).length === 0 ||
      !currentAnnotationData.oCoords
    ) {
      return {
        zIndex: "50"
      };
    }

    let position = getAnnotationPopupPosition(
      currentAnnotationData.oCoords,
      285,
      85
    );

    return {
      ...position,
      zIndex: "50"
    };
  };

  return (
    <React.Fragment>
      <div
        className={
          when === "quorum"
            ? "prompt details-popup quorum-popup"
            : "prompt details-popup"
        }
        style={getPositionStyle()}
      >
        <FadeIn duration="300ms" style={{ height: "100%", width: "100%" }}>
          <div
            className="modal_middle"
            style={{ height: "100%", width: "100%" }}
          >
            <div className="modal_content">
              <div className="details-section">
                <span className="small-text">
                  {currentAnnotationData.firstname
                    ? currentAnnotationData.firstname +
                      " " +
                      currentAnnotationData.surname
                    : ""}
                </span>
                {getAnnotationArea(
                  currentAnnotationData.type,
                  currentAnnotationData.height,
                  currentAnnotationData.width,
                  scaleUnit,
                  scaleRatio
                ) && (
                  <span className="small-text">
                    Area:
                    {" " +
                      getAnnotationArea(
                        currentAnnotationData.type,
                        currentAnnotationData.height,
                        currentAnnotationData.width,
                        scaleUnit,
                        scaleRatio
                      )}
                    <sup>2</sup>
                  </span>
                )}
                <span>{currentAnnotationName}</span>
              </div>
              <div className="control-section">
                {when !== "quorum" && (
                  <div
                    data-for="annotation-detail-popup-tooltip"
                    data-tip="Edit color"
                    className={`control-icon ${
                      openColorPicker ? "color-section active" : "color-section"
                    }`}
                  >
                    <img src={ColorChangeIcon} onClick={colorBtnOnClick} />
                  </div>
                )}
                <div
                  data-for="annotation-detail-popup-tooltip"
                  data-tip="Delete"
                  className="control-icon delete-section"
                >
                  <img src={DeleteIcon} onClick={deleteBtnOnClick} />
                </div>
                {when !== "quorum" && (
                  <div
                    data-for="annotation-detail-popup-tooltip"
                    data-tip="Edit name"
                    className="control-icon delete-section"
                  >
                    <img src={RenameIcon} onClick={editBtnOnClick} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </FadeIn>
        {openColorPicker && (
          <AnnotationColorPicker onColorChange={onColorChange} />
        )}
      </div>
      <ToolTip
        id="annotation-detail-popup-tooltip"
        place="top"
        type="dark"
        effect="solid"
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  scaleUnit: state.Viewer.scaleUnit,
  scaleRatio: state.Viewer.scaleRatio
});

export default connect(mapStateToProps)(AnnotationDetailsPopup);
