import React from "react";
import { TextField } from "@material-ui/core";
import SecondaryButton from "../../others/button/secondary-btn";
import PrimaryButton from "../../others/button/primary-btn";
import Overlay from "../../others/overlay";
import { FadeIn } from "animate-components";
import ModalHeader from "../../others/modal/modal-header";
import { getAnnotationPopupPosition } from "../../../utils/utils";

const AnnotationEditPopup = ({
  onClose,
  setAnnoatationName,
  handleSaveClick,
  currentAnnotationName,
  currentAnnotationData
}) => {
  const getPositionStyle = () => {
    if (
      !currentAnnotationData ||
      Object.keys(currentAnnotationData).length === 0 ||
      !currentAnnotationData.oCoords
    ) {
      return {
        zIndex: "50"
      };
    }

    let position = getAnnotationPopupPosition(
      currentAnnotationData.oCoords,
      250,
      107
    );

    return {
      ...position,
      zIndex: "50"
    };
  };

  return (
    <React.Fragment>
      <div className="prompt annotation-edit-popup" style={getPositionStyle()}>
        <FadeIn duration="300ms">
          <div className="modal_middle" style={{ height: "50px" }}>
            <div className="modal_content" style={{ marginTop: "15px" }}>
              <TextField
                InputProps={{
                  style: { fontSize: "20px" }
                }}
                value={currentAnnotationName ? currentAnnotationName : ""}
                style={{ width: "100%", padding: "0 20px" }}
                onChange={e => setAnnoatationName(e.target.value)}
              />
            </div>
          </div>
          <div
            className="prompt-bottom"
            style={{ marginTop: 0, justifyContent: "center" }}
          >
            <SecondaryButton
              label="Cancel"
              onClick={() => onClose(false)}
            />
            <PrimaryButton
              label="Save"
              onClick={handleSaveClick}
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

export default AnnotationEditPopup;
