import React, { Component, Fragment } from 'react'
import { shape, number, string, func, bool } from 'prop-types'
import ToolTip from 'react-tooltip'
import classNames from 'classnames'
import { Picker } from 'emoji-mart'

export default class AddEmojis extends Component {
  state = {
    showEmojis: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node
  }

  /**
   * Hidd if clicked on outside of element
   */
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showEmojis: false })
    }
  }

  toggleEmojis = () => this.setState({ showEmojis: !this.state.showEmojis })

  render() {
    let { showEmojis } = this.state
    let {
      position,
      textArea,
      updateTextArea,
      recenterEmojis,
      disabled,
      addClassOnClicked,
      className,
    } = this.props

    let addClass = addClassOnClicked && showEmojis ? className : ''
    let disabledClass = disabled ? 'emoji_disabled' : ''

    return (
      <Fragment>
        <span
          className={classNames('emoji_span', disabledClass, addClass)}
          data-tip="Add emojis"
          onClick={this.toggleEmojis}
        >
          <img
            src="/images/img/smile.svg"
            alt="emoji"
            class="attach"
            style={{ width: '20px', height: '20px' }}
          />
        </span>

        {showEmojis ? (
          <div className="emoji-container" ref={this.setWrapperRef}>
            <Picker onSelect={emoji => updateTextArea(emoji.native)} />
          </div>
        ) : null}

        <ToolTip />
      </Fragment>
    )
  }
}

AddEmojis.defaultProps = {
  recenterEmojis: false,
  disabled: false,
  addClassOnClicked: false,
}

AddEmojis.propTypes = {
  position: shape({
    top: number.isRequired,
    left: number.isRequired,
  }).isRequired,
  textArea: string.isRequired,
  updateTextArea: func.isRequired,
  recenterEmojis: bool,
  disabled: bool,
  addClassOnClicked: bool,
  className: string,
}
