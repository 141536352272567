import React, { Component } from 'react'
import { connect } from 'react-redux'
import PostTop from './post-top/post-top'
import PostImage from './post-middle/post-image'
import AppLink from '../../others/link/link'
import PostRating from './post-rating/post-rating'
import axios from '../../../utils/axios-instance-utils'
import Notify from 'handy-notification'
import { editPostFeed } from '../../../actions/post'
import PostDesc from './post-desc/post-desc'
import ExpansionInfoPanel from './expansion-info-panel/expansion-info-panel'
import PostActionsNew from './post-actions-new/post-actions-new'
import PostLabels from './post-labels/post-labels'
import AnnotationUserPanel from './annotation-user-panel/annotation-user-panel'

class Quorum extends Component {
  state = {
    description: '',
    openRate: false,
  }

  componentDidMount = async () =>
    this.setState({ description: this.props.description })

  calculateRemainingTime = time => {
    const currentTime = new Date().getTime()
    const msec = currentTime - time

    const mins = Math.ceil(parseInt(msec) / 60000)
    const hrs = Math.ceil(parseInt(mins) / 60)

    let remaingMins = null
    if (mins < 60) {
      remaingMins = 60 - mins
    } else {
      remaingMins = 60 - (mins % 60)
    }

    let remaingHours = remaingMins !== 0 ? 24 - hrs : hrs
    if (remaingHours > 0 || (remaingHours === 0 && remaingMins !== 0)) {
      remaingHours = remaingHours < 10 ? '0' + remaingHours : remaingHours
      remaingMins = remaingMins < 10 ? '0' + remaingMins : remaingMins
      return `${remaingHours}${':'}${remaingMins === 60 ? '00' : remaingMins}`
    } else {
      return 0
    }
  }

  submitRating = async rate => {
    const { post_id, updatePost } = this.props
    //console.log('onSubmitRating', { rate, post_id })
    let res
    try {
      res = await axios.post(
        `/api/posts/${post_id}/rates`,
        { rate },
        { withCredentials: true }
      )
    } catch (err) {
      Notify({ value: 'Error occurred' })
    }
    // update new rate for post
    Notify({ value: 'Rate successfully' })
    this.openRatePopup(false)
    updatePost(
      res.data.post_id,
      res.data.rate,
      res.data.rate_count,
      res.data.user_rate
    )
  }
  openRatePopup = async open => {
    this.setState({
      openRate: open,
    })
  }

  render() {
    let {
      when,
      share_by_username,
      isMobile,
      quorum_id,
      post_time,
      rate,
      rate_count,
      my_rate,
      user,
      sessionUserId,
      isLoggedUser,
      post_status,
      annotation_users,
      goTo
    } = this.props
    let { description, openRate } = this.state

    return (
      <div className="posts dzi-post case-quorum-post">
        {when == 'shared' && (
          <div className="post_share_info">
            by{' '}
            <AppLink
              url={`/profile/${share_by_username}`}
              label={share_by_username}
            />
          </div>
        )}

        <PostTop
          postDetails={{
            ...this.props,
            description,
          }}
          isQuorum={true}
          postStatus={post_status}
          updateDescription={value => this.setState({ description: value })}
          isMobile={isMobile}
          isLoggedUser={isLoggedUser}
          isQuorum={true}
        />
        <div className="post-container">
          {isLoggedUser && (
            <PostRating
              rate={rate}
              rateCount={rate_count}
              rated={my_rate}
              submit={this.submitRating}
              openRate={openRate}
              setOpenRate={this.openRatePopup}
              isOwner={user === sessionUserId}
            />
          )}
          <PostDesc postDetails={{ ...this.props }} isQuorum={true} goTo={goTo}/>
          <div className="wf-post-body">
            <div className="image-viewer-container">
              <div className="viewer-container-case-stream">
                <PostImage
                  postDetails={{
                    ...this.props,
                    description,
                  }}
                  isMobile={isMobile}
                  isQuorum={true}
                />
              </div>
            </div>
            <div className="wf-post-meta">
              <AnnotationUserPanel
                title="Users"
                annotationUsers={annotation_users}
              />
              <ExpansionInfoPanel
                title="Label"
                content={
                  <PostLabels postDetails={{ ...this.props }} isQuorum={true} />
                }
              />
              <PostActionsNew
                postDetails={{ ...this.props }}
                isMobile={isMobile}
                type="quorum"
                commentBoxId={`comment_box_${quorum_id}`}
                isQuorum={true}
                openRate={this.openRatePopup}
                isOwner={user === sessionUserId}
              />
            </div>
          </div>
        </div>
      </div>
    )
    // return (
    //   <React.Fragment>
    //     <div className="quorum-special-header">
    //       <span className="quorum-header-span" style={{ color: 'white' }}>
    //         Quorum
    //       </span>

    //       <span
    //         className={
    //           this.calculateRemainingTime(post_time) !== 0
    //             ? 'quorum-status-span-active'
    //             : 'quorum-status-span-nonactive'
    //         }
    //       >
    //         {this.calculateRemainingTime(post_time) !== 0 ? (
    //           <i className="custom-icon-deactive"></i>
    //         ) : (
    //           <input type="radio" value="Offline" checked={true} />
    //         )}
    //       </span>
    //     </div>
    //     <div className={'posts qourum_posts_section'}>
    //       {when == 'shared' && (
    //         <div className="post_share_info">
    //           by{' '}
    //           <AppLink
    //             url={`/profile/${share_by_username}`}
    //             label={share_by_username}
    //           />
    //         </div>
    //       )}
    //       <PostTop
    //         postDetails={{
    //           ...this.props,
    //           description,
    //         }}
    //         updateDescription={value => this.setState({ description: value })}
    //         isMobile={isMobile}
    //         isQuorum={true}
    //       />

    //       <PostImage
    //         postDetails={{
    //           ...this.props,
    //           description,
    //         }}
    //         isMobile={isMobile}
    //         isQuorum={true}
    //       />

    //       <hr className="img_d_hr" />
    //       <PostLableAndPostDes
    //         postDetails={{ ...this.props }}
    //         isQuorum={true}
    //       />
    //       <PostRating
    //         rate={rate}
    //         rateCount={rate_count}
    //         rated={my_rate}
    //         submit={this.submitRating}
    //         openRate={openRate}
    //         setOpenRate={this.openRatePopup}
    //         isOwner={user === sessionUserId}
    //       />
    //       <hr className="post_rating_hr" />
    //       <PostActions
    //         postDetails={{ ...this.props }}
    //         isMobile={isMobile}
    //         commentBoxId={`comment_box_${quorum_id}`}
    //         isQuorum={true}
    //       />
    //     </div>
    //   </React.Fragment>
    // )
  }
}

const mapStateToProps = state => {
  const { session } = state.User
  return {
    sessionUserId: session.id,
  }
}
const mapDispatchToProps = dispatch => ({
  updatePost: (post_id, rate, rate_count, my_rate) =>
    dispatch(
      editPostFeed({
        post_id,
        rate,
        rate_count,
        my_rate,
      })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Quorum)
