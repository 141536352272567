import React, { Component } from "react";
import Title from "../others/title";
import { connect } from "react-redux";
import IsLoading from "../others/isLoading";
import ShowQuorum from "./show-quorum";
import { getQuorum, updateQuorum, resetQuorum } from "../../actions/post";
import { editQuorumTime } from "../../utils/post-utils";
import { setActivePage } from "../../actions/user";
import { getUserDetails } from "../../actions/user";
import { resetViewerAnnotationAction } from "../../actions/viewer"
import { uDetails } from "../../utils/utils";
import Notify from "handy-notification";

class QuorumPage extends Component {
  state = {
    loading: true,
    currentUserAnnotation: [],
    annotatedTime: false,
    isTimeEdit: false,
    validUntil: null
  };

  componentDidMount = () => {
    const { username } = uDetails("session");
    let {
      match: {
        params: { quorum_id }
      },
      dispatch
    } = this.props;

    dispatch(setActivePage("view-quorum"));
    dispatch(getUserDetails(username));
    quorum_id ? dispatch(getQuorum(quorum_id)) : null;
  };

  componentWillReceiveProps = () => this.setState({ loading: false });

  componentWillUnmount = () => {
    let { dispatch } = this.props;
    dispatch(resetViewerAnnotationAction());
    dispatch(resetQuorum())
  };

  annotatedQuorum = async () => {
    if (this.props.annotationData.length > 0) {
      const { dispatch } = this.props;
      const { quorum_id } = this.props.quorum;
      const data = {
        quorum_id
      };
      Notify({ value: "pending" });
      let response = await fetch("/api/post-annotation", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      }).then(x => x.json());

      if (response.success) {
        Notify({ value: "Annotation Validated" });
        dispatch(updateQuorum(quorum_id));
        this.setState({ annotatedTime: true });
      } else {
        Notify({ value: response.mssg });
      }
    } else {
      Notify({ value: "Select annotation first" });
      return false;
    }
  };

  loadUserAnnotionSection = annotation => {
    this.setState({ currentUserAnnotation: annotation });
  };

  publishAdminAnnotation = async (data, status) => {
    // let {
    //   match: {
    //     params: { quorum_id }
    //   },
    //   dispatch
    // } = this.props;
    // const reqData = {
    //   annotation_id: data.id,
    //   quorum_id: data.quorum_id,
    //   status: status
    // };
    // Notify({ value: status ? "Showing to public" : "Hiding from public" });
    // let response = await fetch("/api/public-admin-annotation", {
    //   method: "POST",
    //   body: JSON.stringify(reqData),
    //   headers: {
    //     "Content-Type": "application/json; charset=utf-8"
    //   }
    // }).then(x => x.json());
    // if (response.success) {
    //   Notify({ value: "Success" });
    //   dispatch(getQuorum(quorum_id));
    //   this.setState({ annotatedTime: true });
    // } else {
    //   Notify({ value: response.mssg });
    // }
  };

  initialTimeEdit = state => {
    this.setState({ isTimeEdit: state });
  };

  setRemainingTime = value => {
    const timeStampValue = new Date(value.toDate()).getTime();
    this.setState({ validUntil: timeStampValue });
  };

  setExpireTimeData = value => {
    const timeStampValue = new Date(value).getTime();
    this.setState({ validUntil: timeStampValue });
  };

  updateExpireTime = () => {
    let { dispatch, quorum } = this.props;
    const data = {
      valid_until: this.state.validUntil,
      post_id: quorum.quorum_id,
      done: this.initialTimeEdit(false),
      dispatch
    };
    dispatch(editQuorumTime(data));
  };

  render() {
    const {
      quorum_id,
      dziImageUrl,
      post_time,
      isValidateDone,
      quorum_key
    } = this.props.quorum ? this.props.quorum : true;
    const {
      currentUserAnnotation,
      annotatedTime,
      isTimeEdit,
      validUntil
    } = this.state;
    return (
      <React.Fragment>
        <Title value="View post" />
        <IsLoading when={"page"} loading={quorum_id ? false : true} />

        {quorum_id && (
          <ShowQuorum
            type="view"
            dziImageUrl={dziImageUrl}
            annotatedQuorum={this.annotatedQuorum}
            currentUserAnnotation={currentUserAnnotation}
            setScreenShot={this.setScreenShot}
            post_time={post_time}
            isValidateDone={isValidateDone}
            annotatedTime={annotatedTime}
            publishAdminAnnotation={
              this.publishAdminAnnotation && this.publishAdminAnnotation
            }
            isTimeEdit={isTimeEdit}
            initialTimeEdit={this.initialTimeEdit}
            setRemainingTime={this.setRemainingTime}
            updateExpireTime={this.updateExpireTime}
            setExpireTimeData={this.setExpireTimeData}
            quorumKey={quorum_key}
            validUntil={validUntil}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  quorum: state.Post.viewQuorum,
  user_details: state.User.user_details,
  annotationType: state.Viewer.annotationType,
  isAnnotationActive: state.Viewer.isAnnotationActive,
  annotationData: state.Viewer.annotationData
});
export default connect(mapStateToProps)(QuorumPage);
export { QuorumPage as PureQuorumPage };
