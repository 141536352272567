import React, { Component } from "react";
import PostLike from "../../like/post-like";
import PostBookmark from "../../bookmark/post-bookmark";
import PostShare from "../../share/post-share";
import { shape, number, string } from "prop-types";
import ShowLikes from "./show-likes";

export default class PostActions extends Component {
  state = {
    likes_count: 0,
    shares_count: 0
  };



  incrementWhat = what => this.setState({ [what]: this.state[what] + 1 });
  decrementWhat = what => this.setState({ [what]: this.state[what] - 1 });

  componentDidMount = () => {
    let {
      postDetails: { likes_count, shares_count },
      isMobile
    } = this.props;
    this.setState({ likes_count, shares_count });
  };

  render() {
    let {
      postDetails: { post_id, user, when, is_followed, description, bookmark_count, postid_token, slideThumbnailSrc, type, dzi_info },
      commentBoxId,
      isMobile,
      isQuorum
    } = this.props;

    let { likes_count, shares_count } = this.state;
    let childProps = {
      postDetails: { post_id, user, description, postid_token, slideThumbnailSrc, type, dzi_info },
      incrementWhat: this.incrementWhat,
      decrementWhat: this.decrementWhat,
      isMobile
    };

    return (
      <div className="p_a_container">
        <div className="p_a">
          <div className="p_do">
          <PostLike {...childProps}  commentBoxId={commentBoxId} likes_count={likes_count} isMobile={isMobile} isQuorum={isQuorum}/>
          <PostBookmark postDetails={{ post_id, when, bookmark_count }} isMobile={isMobile} isQuorum={isQuorum}/>
          </div>

          <div className="p_did">
            <PostShare isQuorum={isQuorum} {...childProps} />
          </div>
        </div>
      </div>
    );
  }
}


PostActions.propTypes = {
  postDetails: shape({
    post_id: number.isRequired,
    user: number.isRequired,
    when: string.isRequired,
    likes_count: number.isRequired,
    shares_count: number.isRequired
  }).isRequired
};
