import React, { Fragment, useState, useEffect } from "react";
import Nothing from "../others/nothing";
import QuorumPanel from "./quorum-panel";

import { connect } from "react-redux";

const ShowQuorum = ({
  quorum,
  isMobile,
  type,
  dziImageUrl,
  user_details,
  setRemainingTime,
  setScreenShot,
  setSubmitQuorumStatus,
  resetInitQuorumState,
  isValidateDone,
  dispatch,
  publishAdminAnnotation,
  annotatedQuorum,
  quorumKey,
  isPublishInCase,
  setExpireTimeData,
  validUntil
}) => {
  let { quorum_id } = quorum;
  let id = typeof user_details.id !== "undefined" ? user_details.id : null;

  return (
    <Fragment>
      {quorum_id && id ? (
        <Fragment>
          <QuorumPanel
            type={type}
            isPostMine={quorum.isPostMine}
            userId={
              typeof user_details.id !== "undefined" ? user_details.id : null
            }
            quorum_id={quorum_id}
            dziImageUrl={dziImageUrl}
            publishAdminAnnotation={publishAdminAnnotation}
            annotatedQuorum={annotatedQuorum}
            quorumKey={quorumKey}
            setRemainingTime={setRemainingTime}
            setExpireTimeData={setExpireTimeData}
            validUntil={validUntil}
          />
        </Fragment>
      ) : type === "create" ? (
        <Fragment>
          <QuorumPanel
            type={type}
            dziImageUrl={dziImageUrl}
            setRemainingTime={setRemainingTime}
            setScreenShot={setScreenShot}
            setSubmitQuorumStatus={setSubmitQuorumStatus}
            publishAdminAnnotation={publishAdminAnnotation}
            quorumKey={quorumKey}
            isPublishInCase={isPublishInCase}
          />
        </Fragment>
      ) : (
        <Nothing mssg="No such post found" />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  quorum: state.Post.viewQuorum,
  isMobile: state.Setting.isMobile,
  user_details: state.User.user_details
});

export default connect(mapStateToProps)(ShowQuorum);
