import React from 'react'

const User = ({ img, fullName }) => (
  <div className="user">
    <div style={{ height: '44px', display: 'flex', alignItems: 'center' }}>
      <img
        src={img}
        alt="user"
        height="35"
        width="35"
        style={{ borderRadius: '100%' }}
        onError={e => {
          e.target.onerror = null
          e.target.src = '/images/default-avatar.svg'
        }}
      />
    </div>
    <h3>{fullName}</h3>
  </div>
)

export default User
