import React, { useState, useEffect } from 'react'
import TimeAgo from 'handy-timeago'
import NotificationType from './type'
import NotificationActionType from './action-type/action-type'
import PropTypes from 'prop-types'
import AppLink from '../../others/link/link'

const Notification = props => {
  let {
    notify_by,
    notify_by_username,
    notify_by_firstname,
    notify_by_surname,
    notify_time,
    type,
    user_username,
    postid_token,
    file_folder,
    file_name,
    thumbnail,
  } = props

  const [avatarSrc, setAvatarSrc] = useState(thumbnail);

  useEffect(() => {
    if (type === 'slidebox' && thumbnail) {
      setAvatarSrc(thumbnail)
    } else if (notify_by) {
      setAvatarSrc(`/users/${notify_by}/avatar.jpg`)
    }
  }, [notify_by, type, thumbnail])

  const useDefaultAvatar = () => {
    if (type !== 'slidebox') {
      setAvatarSrc('/images/default-avatar.svg');
    } else {
      setAvatarSrc(thumbnail);
    }
  }

  return (
    <div className="noti follow_noti">
      {
        <img
          src={avatarSrc}
          onError={useDefaultAvatar}
          className="noti_avatar"
        />
      }

      <div className="noti_left">
        <AppLink
          url={
            type === 'slidebox' ? `/slidebox` : `/profile/${notify_by_username}`
          }
          className="noti_bold noti_username"
          label={type === 'slidebox' ? file_name : (notify_by_firstname + " " + notify_by_surname)}
        />

        <NotificationType
          type={type}
          user_username={user_username}
          file_name={file_name}
        />
        <span className="noti_time">{TimeAgo(notify_time)}</span>
      </div>

      <NotificationActionType details={{ ...props }} />
    </div>
  )
}

Notification.propTypes = {
  notify_id: PropTypes.number.isRequired,
  notify_by: PropTypes.number.isRequired,
  notify_by_username: PropTypes.string.isRequired,
  notify_time: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.number.isRequired,
  user_username: PropTypes.string.isRequired,
  post_id: PropTypes.number.isRequired,
  group_id: PropTypes.number.isRequired,
  isFollowing: PropTypes.bool.isRequired,
}

export default Notification
