import { dispatchHelper } from '../utils/utils'

export const getUserPosts = username =>
  dispatchHelper('GET_USER_POSTS', 'get-user-posts', { username })

export const getBookmarkedPosts = (user, page) =>
  dispatchHelper('GET_BOOKMARKED_POSTS', 'get-bookmarked-posts', { user, page })

export const getQuorumPosts = user =>
  dispatchHelper('GET_QUORUM_POSTS', 'get-quorum-posts', { user });

export const getAllPosts = (user, page) =>
  dispatchHelper('GET_ALL_POSTS', 'get-all-posts', { user, page });

export const getTaggedPosts = user =>
  dispatchHelper('GET_TAGGED_POSTS', 'get-tagged-posts', { user })

export const getSharedPosts = user =>
  dispatchHelper('GET_SHARED_POSTS', 'get-shared-posts', { user })

export const getPhotos = user =>
  dispatchHelper('GET_PHOTOS', 'get-photos', { user })

export const getFeed = (page, adminFilter = false)  =>
  dispatchHelper('GET_FEED', 'get-feed', { page, adminFilter })

export const getFeedNew = (page, adminFilter = false) =>
  dispatchHelper('GET_FEED_NEW', 'get-feed', { page, adminFilter })

export const setFeedLoading = () => {
  return { type: 'SET_FEED_LOADING' }
}

export const switchGridView = () => {
  return { type: 'SWITCH_GRID_VIEW' }
}

export const resetAllPostAndBookmark =  (username) => {
  return { type: 'RESET_ALL_POST_AND_BOOKMARK', payload: username }
}

export const setProfileLoading = () => {
  return { type: 'SET_PROFILE_LOADING' }
}

export const setBookmarkLoading = () => {
  return { type: 'SET_BOOKMARK_LOADING' }
}

export const getGroupPosts = group =>
  dispatchHelper('GET_GROUP_POSTS', 'get-group-posts', { group })

export const addUserPost = post => {
  return {
    type: 'ADD_USER_POST',
    payload: post,
  }
}

export const addGroupPost = post => {
  return {
    type: 'ADD_GROUP_POST',
    payload: post,
  }
}

export const getGroupPhotos = group =>
  dispatchHelper('GET_GROUP_PHOTOS', 'get-group-photos', { group })

export const getPost = post_id =>
  dispatchHelper('GET_POST', 'get-post', { post_id })

export const getQuorum = quorum_id =>
  dispatchHelper('GET_QUORUM', 'get-quorum', { quorum_id })

export const setAnnotationAction = (annotation, isAdmin) => {
  return {
    type: 'SET_ANNOTATION',
    payload: {
      annotation,
      isAdmin,
    },
  }
}

export const isPublicQuorum = status => {
  return {
    type: 'IS_PUBLIC_QUORUM',
    payload: status,
  }
}

export const publishQuorum = quorumData =>
  dispatchHelper('PUBLISH_QUORUM', 'post-quorum', { quorumData })

export const updateQuorum = quorum_id => {
  return {
    type: 'UPDATE_QUORUM',
    payload: quorum_id,
  }
}

export const updateQuorumValue = (what, value) => {
  return {
    type: 'UPDATE_QUORUM_DESC',
    payload: { what, value },
  }
}

export const editPost = post_details => {
  return {
    type: 'EDIT_POST',
    payload: post_details,
  }
}

export const editQuorum = post_details => {
  return {
    type: 'EDIT_QUORUM',
    payload: post_details,
  }
}

export const editQuorumTime = post_details => {
  return {
    type: 'EDIT_QUORUM_TIME',
    payload: post_details,
  }
}

export const editPostFeed = post_details => {
  return {
    type: 'EDIT_POST_FEED',
    payload: post_details,
  }
}

export const resetQuorum = () => {
  return {
    type: 'RESET_QUORUM',
    payload: {},
  }
}

export const deletePost = post => {
  return {
    type: 'DELETE_POST',
    payload: post,
  }
}

export const getPostLikes = post =>
  dispatchHelper('GET_POST_LIKES', 'get-post-likes', { post })

export const removeLike = like_id =>
  dispatchHelper('REMOVE_LIKE', 'remove-like', { like_id })

export const getPostTags = post =>
  dispatchHelper('GET_POST_TAGS', 'get-post-tags', { post })

export const untag = user => {
  return {
    type: 'UNTAG',
    payload: user,
  }
}

export const getUsersToShare = post =>
  dispatchHelper('GET_USERS_TO_SHARE', 'get-users-to-share', { post })

export const getPostSharers = post =>
  dispatchHelper('GET_POST_SHARERS', 'get-post-sharers', { post })

export const bookmark = post => {
  return {
    type: 'BOOKMARK',
    payload: post,
  }
}
export const bookmarkFeed = post => {
  return {
    type: 'BOOKMARK_FEED',
    payload: post,
  }
}

export const unbookmark = post => {
  return {
    type: 'UNBOOKMARK',
    payload: post,
  }
}
export const unbookmarkFeed = post => {
  return {
    type: 'UNBOOKMARK_FEED',
    payload: post,
  }
}

export const removeShare = share_id => {
  return {
    type: 'REMOVE_SHARE',
    payload: share_id,
  }
}

export const comment = comment => {
  return {
    type: 'COMMENT',
    payload: comment,
  }
}

export const feedComment = comment => {
  return {
    type: 'POSTS_COMMENT',
    payload: comment,
  }
}

export const commentReply = reply => {
  return {
    type: 'COMMENT_REPLY',
    payload: reply,
  }
}

export const feedCommentReply = reply => {
  return {
    type: 'POSTS_COMMENT_REPLY',
    payload: reply,
  }
}

export const deleteComment = comment_id => {
  return {
    type: 'DELETE_COMMENT',
    payload: comment_id,
  }
}

export const editComment = comment => {
  return {
    type: 'EDIT_COMMENT',
    payload: comment,
  }
}

export const deleteCommentFeed = comment => {
  return {
    type: 'DELETE_COMMENT_FEED',
    payload: comment,
  }
}

export const editCommentFeed = comment => {
  return {
    type: 'EDIT_COMMENT_FEED',
    payload: comment,
  }
}

export const deleteReply = reply_id => {
  return {
    type: 'DELETE_REPLY',
    payload: reply_id,
  }
}

export const editReply = reply => {
  return {
    type: 'EDIT_REPLY',
    payload: reply,
  }
}

export const deleteReplyFeed = reply => {
  return {
    type: 'DELETE_REPLY_FEED',
    payload: reply,
  }
}

export const editReplyFeed = reply => {
  return {
    type: 'EDIT_REPLY_FEED',
    payload: reply,
  }
}

// CHANGE POST IT PROPERTIES
export const CPP = (what, value) => {
  return {
    type: 'CHANGE_POSTIT_PROPS',
    payload: { what, value },
  }
}

export const resetPostIt = () => {
  return {
    type: 'RESET_POSTIT',
  }
}

export const voteCommentAction = vote => {
  return {
    type: 'VOTE_COMMENT_FEED',
    payload: vote,
  }
}

export const voteReplyAction = vote => {
  return {
    type: 'VOTE_REPLY_FEED',
    payload: vote,
  }
}

export const voteCommentPostAction = vote => {
  return {
    type: 'VOTE_COMMENT_POST',
    payload: vote,
  }
}

export const voteReplyPostAction = vote => {
  return {
    type: 'VOTE_REPLY_POST',
    payload: vote,
  }
}

export const caseSlove = post => {
  return {
    type: 'CASE_SLOVE',
    payload: post,
  }
}

export const caseSloveSingle = post => {
  return {
    type: 'CASE_SLOVE_SINGLE',
    payload: post,
  }
}

export const reviewedPost = post => {
  return {
    type: 'POST_REVIEWED',
    payload: post,
  }
}

export const allowPostOwner = user => {
  return {
    type: 'ALLOWED_POST_OWNER',
    payload: user,
  }
}

export const caseAction = post => {
  return {
    type: 'MARK_SPAM',
    payload: post,
  }
}

export const editPostPollOptions = (post_id, options, when) => {
  if(when === "viewPost"){
    return {
      type: 'EDIT_VIEW_POST_POLL_OPTIONS',
      payload: {
        post_id,
        options,
      },
    }
  }else{
    return {
      type: 'EDIT_POST_POLL_OPTIONS',
      payload: {
        post_id,
        options,
      },
    }
  }

}

export const getAnnotationColor = quorumData =>
  dispatchHelper('GET_ANNOTATION_COLOR', 'annotation-color', quorumData)
