import React from 'react'
import PropTypes from 'prop-types'
import { humanReadable } from '../../../utils/utils'
import AppLink from '../link/link'

const MonTopInfo = ({ info, basedOnMutuals }) => {
  let {
    user,
    username,
    firstname,
    surname,
    mutuals,
    affiliation,
    country,
  } = info

  return (
    <div className="m_top">
      <img
        src={`/users/${user}/avatar.jpg`}
        onError={e => {
          e.target.onerror = null
          e.target.src = '/images/default-avatar.svg'
        }}
      />
      <div className="m_top_right">
        <AppLink
          url={`/profile/${username}`}
          label={`${firstname} ${surname}`}
        />
        {basedOnMutuals ? (
          <React.Fragment>
            <span>
              {mutuals == 0
                ? `@${username}`
                : humanReadable(mutuals, 'mutual follower')}
            </span>
            <span style={{ display: 'block' }}>
              {affiliation}
              {' | '}
              {country}
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>@{username}</span>
            <span style={{ display: 'block', fontSize: '12px' }}>
              {affiliation}
              {' | '}
              {country}
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

MonTopInfo.defaultProps = {
  basedOnMutuals: false,
  mutuals: 0,
}

MonTopInfo.propTypes = {
  info: PropTypes.shape({
    user: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    mutuals: PropTypes.number,
  }).isRequired,
  basedOnMutuals: PropTypes.bool,
}

export default MonTopInfo
