import React from 'react'
import LogoAnimation from 'icons/loading-logo-animated.svg'

const Loading = () => {
  return (
    <div className="page_loading">
      <div className="top_loading_wrapper">
        <div className="top_loading"></div>
      </div>
      <img className="loading_image" src={LogoAnimation} alt=""/>
    </div>
  )
}

export default Loading
