import React, { useState, useEffect } from 'react';

const Fade = ({ show, duration, children, delay = 0, chaining = 0, zIndex = 9999999, style = {} }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if(show && delay === 0) setTimeout(() => setRender(true), chaining)
    else if(show) setTimeout(() => setRender(true), delay)
  }, [show])

  const onAnimationEnd = () => {
    if(!show) setRender(false)
  }

  return (
    shouldRender && (
      <div style={{ animation: `${show ? 'fadeIn' : 'fadeOut'} ${duration}`, zIndex: zIndex, ...style }} onAnimationEnd={onAnimationEnd}>
        {children}
      </div>
    )
  );
}

export default Fade;