import React, { Component, Fragment } from "react";
import { FadeIn } from "animate-components";
import { Scrollbars } from "react-custom-scrollbars";
import Title from "../../../others/title";
import ShareList from "./share-list";
import { connect } from "react-redux";
import ModalHeader from "../../../others/modal/modal-header";
import ModalMiddle from "../../../others/modal/modal-middle";
import IsLoading from "../../../others/isLoading";

class Share extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    const { origin } = window.location;
    let { isQuorum, slideThumbnailSrc, dziInfo, img } = this.props;
    const postUrl = `${origin}${isQuorum ? "/quorum/" : "/openpost/"}${this.props.postid_token
      }`;

    const postImage = img
      ? img
      : isQuorum && slideThumbnailSrc
        ? slideThumbnailSrc[0].src
        : dziInfo[0] && dziInfo[0].thumbnail;

    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", postImage);

    document
      .querySelector('meta[property="og:url"]')
      .setAttribute("content", postUrl);

    document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute("content", postImage);

    // document
    //   .querySelector('meta[name="twitter:card"]')
    //   .setAttribute('content', postUrl)
  }

  render() {
    const { origin } = window.location;
    let { isQuorum } = this.props;
    const postUrl = `${origin}${isQuorum ? "/quorum/" : "/openpost/"}${this.props.postid_token
      }`;
    const fullUrl = encodeURIComponent(postUrl);
    let { loading } = this.state;

    let ebody = "Hi, \r\n\r\n Check out this interesting case on Crosscope";
    ebody = encodeURIComponent(ebody);
    const mailUrl =
      "mailto:?subject=Crosscope: an interesting case&body=" +
      ebody +
      " " +
      postUrl;

    const socialMedia = [
      {
        name: "Facebook",
        icon: "facebook.svg",
        link: `https://www.facebook.com/sharer.php?u=${fullUrl}&title=[${"Check out this interesting case on Crosscope"}]`,
        isCopy: false
      },
      {
        name: "LinkedIn",
        icon: "linkedIn.svg",
        link: `https://www.linkedin.com/shareArticle?mini=true&url=${fullUrl}&title=${"Check out this interesting case on Crosscope"}&source=[${origin}]`,
        isCopy: false
      },
      {
        name: "WhatsApp",
        icon: "whatsapp.svg",
        link: `https://wa.me?text=${"Check out this interesting case on Crosscope"}${" "}${fullUrl}`,
        isCopy: false
      },
      {
        name: "Twitter",
        icon: "twitter.svg",
        link: `http://twitter.com/intent/tweet?text=${"Check out this interesting case on Crosscope"}+${" "}&url=${fullUrl}`,
        isCopy: false
      },
      {
        name: "Email",
        icon: "email.svg",
        link: mailUrl,
        isCopy: false
      },
      {
        name: "Link",
        icon: "link.svg",
        link: postUrl,
        isCopy: true
      }
    ];

    let map_social_media = socialMedia.map((u, i) => (
      <ShareList
        key={i}
        name={u.name}
        icon={u.icon}
        link={u.link}
        isCopy={u.isCopy}
      />
    ));
    let containerClass = this.props.isQuorum ? "shareModal" : "shareModal";
    if (this.props.top) containerClass += " top";
    return (
      <Fragment>
        <div
          className={containerClass}
          style={
            this.props.feed
              ? { left: "auto", right: 20, zIndex: 10 }
              : { left: "auto", zIndex: 10, right: 170 }
          }
          id="share-modal-box"
        >
          <Title value="Share post" />
          <FadeIn duration="300ms">
            {/* <Scrollbars style={{ height: 260 }} className="modal_middle"> */}
            <IsLoading loading={loading} />
            <ModalMiddle loading={loading} list={map_social_media} />
            {/* </Scrollbars> */}
          </FadeIn>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = store => ({
  users: store.Post.usersToShare
});

export default connect(mapStateToProps)(Share);
export { Share as PureShare };
