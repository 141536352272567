import React, { useState, useEffect, useContext } from "react";
import RectangularAnno from "../icons/anno-ractangle-new.svg";
import CircleAnno from "../icons/anno-circle-new.svg";
import PolygonAnno from "../icons/brush-icon-new.svg";
import ArrowAnno from "../icons/arrow-anno-icon-new.svg";
import HideAnno from "../icons/hide-anno-icon-new.svg";
import ShowAnno from "../icons/show-anno-icon-new.svg";
import EditAnno from "../icons/edit-anno-new.svg";
import ColorChangeIcon from "../icons/paint-plate-new.svg";
import { Slider } from "material-ui-slider";
import ToolTip from "react-tooltip";
import Prompt from "../../others/prompt";
import { SocketContext } from "../../../services/ServiceContext";

import openseadragonAnnotations from "../utils/openseadragon-annotations";

import AnnotationEditPopup from "./annotation-edit-popup";
import AnnotationDetailsPopup from "./annotation-details-popup";
import AnnotationColorPicker from "./annotation-color-picker";

import {
  setAnnotations,
  getAnnotations,
  updateAnnotations,
  deleteAnnotations,
  toggleAnnotations,
  setSocketDataUpdateFlase
} from "../../../utils/viewer-utils";
import { connect } from "react-redux";
import { getRandomRolor } from "../../../utils/utils";
import DeleteIcon from "./delete.svg";

const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const ViewerToolbarAnnotation = ({
  viewer,
  overlay,
  zoomValueBar,
  slideId,
  dispatch,
  allAnnotationData,
  toogleAnnotation,
  when,
  featureId,
  userDetails,
  postIsMine,
  fromCaseStream,
  slideOwnerOrCollaborator,
  postIsValidate,
  isSoketDataUpdate,
  selectedFolder
}) => {
  const socket = useContext(SocketContext);
  const [forceReloadAnnotationData, setForceReloadAnnotationData] = useState(
    false
  );
  const [pageMounted, setPageMounted] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [addAnnotations, setAddAnnotations] = useState(false);
  const [editAnnotations, setEditAnnotations] = useState(false);
  const [currentAnnotationType, setCurrentAnnotationType] = useState(null);
  const [currentAnnotations, setCurrentAnnotations] = useState(null);
  const [currentOrgAnnotations, setCurrentOrgAnnotations] = useState(null);
  const [currentAnnotationName, setCurrentAnnotationName] = useState(null);
  const [BrushWidth, setBrushWidth] = useState(60);
  const [showAnnotationDataPopup, setShowAnnotationDataPopup] = useState(false);
  const [showAnnotationEditPopup, setShowAnnotationEditPopup] = useState(false);
  const [globleColorChangeActive, setGlobleColorChangeActive] = useState(false);
  const [showBrushSizeTool, setshowBrushSizeTool] = useState(false);
  const [globleAnnotationColor, setGlobleAnnotationColor] = useState("#FF0000");
  const [otherUserAnnotations, setOtherUserAnnotations] = useState(null);
  const [currentUserAnnotations, setCurrentUserAnnotations] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [editAnnotationData, setEditAnnotationData] = useState(null);

  useEffect(() => {
    socket.onUpdateBoardAnnotation().subscribe(data => {
      if (
        when === "groups" &&
        featureId === parseInt(data.tumorBoardId) &&
        userDetails.id !== data.ownerId
      ) {
        setForceReloadAnnotationData(true);
        getAnnotations({
          dispatch,
          slideId,
          when,
          featureId,
          selectedFolderType:
            selectedFolder && selectedFolder.isPublicFolder
              ? "public"
              : "slidebox"
        });
      }
    });
  }, []);

  useEffect(() => {
    socket.getAnnotationMessageSubject().subscribe(item => {
      if (
        item &&
        currentUserAnnotations &&
        overlay &&
        item.fromSrc === "collapsible-sidebar"
      ) {
        // get active object
        let activeObj = overlay
          .fabricCanvas()
          .getObjects()
          .find(i => i.pk === item.pk);
        handleEditAnnotation(null, activeObj);
      }
    });
  }, [overlay, currentUserAnnotations]);

  useEffect(() => {
    if (typeof viewer !== "undefined" && overlay) {
      setPageMounted(true);
      getAnnotations({
        dispatch,
        slideId,
        when,
        featureId,
        selectedFolderType:
          selectedFolder && selectedFolder.isPublicFolder
            ? "public"
            : "slidebox"
      });
      openseadragonAnnotations.initialize(overlay, viewer);
      if (when === "quorum" && !postIsMine) {
        const globleColorInQuorum = getRandomRolor();
        setGlobleAnnotationColor(globleColorInQuorum);
      }
    }
  }, [overlay]);

  useEffect(() => {
    if (
      typeof viewer !== "undefined" &&
      overlay &&
      (addAnnotations || pageMounted || isSoketDataUpdate)
    ) {
      setUserAnnoatationData(allAnnotationData);
      openseadragonAnnotations.appendAllAnnotations(
        pageMounted
          ? allAnnotationData
          : [allAnnotationData[allAnnotationData.length - 1]],
        when,
        addAnnotations
      );
      setPageMounted(false);
      setAddAnnotations(false);
      setSocketDataUpdateFlase({ dispatch });
    } else if (
      typeof viewer !== "undefined" &&
      overlay &&
      forceReloadAnnotationData
    ) {
      setUserAnnoatationData(allAnnotationData);
      openseadragonAnnotations.refresAnnotationData(allAnnotationData);
      setForceReloadAnnotationData(false);
    }
  }, [allAnnotationData]);

  useEffect(() => {
    if (toogleAnnotation && !editAnnotations) {
      const { annotationIds, onOrOff } = toogleAnnotation;
      openseadragonAnnotations.turnAnnotationsOnOffByUser(
        onOrOff,
        annotationIds
      );
    }
  }, [toogleAnnotation]);

  const sentChangeAnnotationEvent = item => {
    socket
      .getAnnotationMessageSubject()
      .next({ ...item, fromSrc: "toolbar-annotation" });
  };

  const switchCursor = (isDrawing = false) => {
    if (isDrawing) {
      overlay.fabricCanvas().moveCursor = "crosshair";
      overlay.fabricCanvas().hoverCursor = "crosshair";
    } else {
      overlay.fabricCanvas().moveCursor = "move";
      overlay.fabricCanvas().hoverCursor = "move";
    }
  };

  const handleAnnotation = (e, annotationType) => {
    e.preventDefault();
    if (currentAnnotationType !== annotationType) {
      setGlobleColorChangeActive(false);
      if (when === "quorum" && !postIsMine) {
        openseadragonAnnotations.changeAllAnnotationStrokeColor(
          globleAnnotationColor
        );
      }
      openseadragonAnnotations.setMouseMode("addAnnotation");
      switchCursor(true);
      openseadragonAnnotations.annotationType = annotationType;
      var strokeWidth = 80 / zoomValueBar;
      openseadragonAnnotations.strokeWidthForDrawing = strokeWidth;
      if (annotationType == "brush") {
        resetBrushSize();
        toggleshowBrushSizeTool();
        if (!overlay.fabricCanvas().isDrawingMode) {
          overlay.fabricCanvas().isDrawingMode = true;
          overlay.fabricCanvas().freeDrawingBrush.width = BrushWidth;
          overlay.fabricCanvas().freeDrawingBrush.color = convertHexToRGBA(
            globleAnnotationColor,
            50
          );
        }
      }
      setCurrentAnnotationType(annotationType);
    } else {
      setCurrentAnnotationType(null);
      switchCursor(false);
      openseadragonAnnotations.setMouseMode("OSD");
      if (annotationType == "brush") {
        overlay.fabricCanvas().isDrawingMode = false;
      }
      if (annotationType == "brush") {
        toggleshowBrushSizeTool();
      }
    }
  };

  const handleEditAnnotation = (e, activeAnnotation) => {
    if (e) {
      e.preventDefault();
    }
    setShowAnnotationEditPopup(false);
    setCurrentAnnotationType(null);
    if (!editAnnotations) {
      hideOtherUserAnnotations();
      overlay
        .fabricCanvas()
        .getObjects()
        .some(obj => {
          obj.set({
            transparentCorners: false,
            cornerColor: "blue",
            cornerStrokeColor: "red",
            borderColor: "red",
            cornerSize: 12,
            padding: 10,
            cornerStyle: "circle",
            borderDashArray: [3, 3]
          });
        });
      openseadragonAnnotations.setMouseMode("editAnnotation");
      // set active annotation selected from sidebar
      if (activeAnnotation) {
        overlay.fabricCanvas().setActiveObject(activeAnnotation);
        onSelectAnnotationToEdit(activeAnnotation);
      }
    } else {
      if (when !== "quorum" && showAnnotations) {
        openseadragonAnnotations.turnAnnotationsOnOff("on");
      }
      openseadragonAnnotations.setMouseMode("OSD");
    }
    setEditAnnotations(!editAnnotations);
  };

  const toggleAnnotation = e => {
    e.preventDefault();
    if (showAnnotations) {
      openseadragonAnnotations.setMouseMode("OSD");
      openseadragonAnnotations.turnAnnotationsOnOff("off");
    } else {
      openseadragonAnnotations.turnAnnotationsOnOff("on");
    }
    setShowAnnotations(!showAnnotations);
  };

  const onClose = () => {
    if (currentAnnotationType) {
      openseadragonAnnotations.deleteCurrentAnnotation();
    } else {
      handleSaveClick();
    }
    setShowAnnotationDataPopup(false);
    setShowAnnotationEditPopup(false);
    setCurrentAnnotations(null);
    setCurrentAnnotationType(null);
  };

  const setAnnoatationName = name => {
    setCurrentAnnotationName(name);
  };

  const handleSaveClick = () => {
    if (currentAnnotationType) {
      const data = {
        ...currentAnnotations,
        text: currentAnnotationName
      };
      setAddAnnotations(true);
      setAnnotations({
        annotationData: data,
        slideId,
        featureId,
        dispatch,
        when,
        selectedFolderType:
          selectedFolder && selectedFolder.isPublicFolder
            ? "public"
            : "slidebox",
        callback: deleteCurrentDraw
      });
      setCurrentAnnotationType(null);
    } else {
      const canvasObject = overlay.fabricCanvas().getActiveObject();
      canvasObject.set({ text: currentAnnotationName });
      const data = {
        ...currentAnnotations,
        text: currentAnnotationName
      };
      updateAnnotations({ annotationData: data, slideId, when, dispatch });
    }
    setCurrentAnnotationName(null);
    setShowAnnotationDataPopup(false);
  };

  const deleteCurrentDraw = () => {
    openseadragonAnnotations.deleteCurrentAnnotation();
  };

  const editBtnOnClick = () => {
    setEditAnnotationData(currentOrgAnnotations);
    setShowAnnotationEditPopup(false);
    setShowAnnotationDataPopup(true);
  };

  const deleteBtnOnClick = () => {
    setShowConfirmDelete(true);
  };

  const onDeleteAnnotationComfirmed = () => {
    const annotationId = currentAnnotations.pk;
    deleteAnnotations({
      annotationId,
      dispatch,
      callback: hideDetailsPopup
    });
    setShowConfirmDelete(false);
  };

  const resetBrushSize = () => {
    setBrushSizeInAnnotation(60);
  };

  const onColorChange = color => {
    const canvasObject = overlay.fabricCanvas().getActiveObject();
    if (canvasObject.type == "path") {
      var updatedColor = convertHexToRGBA(color["hex"], 50);
    } else {
      var updatedColor = color["hex"];
    }
    canvasObject.set({ stroke: updatedColor });
    const data = {
      ...currentAnnotations,
      stroke: updatedColor
    };
    updateAnnotations({ annotationData: data, slideId, dispatch });
    overlay.fabricCanvas().renderAll();
  };

  const hideDetailsPopup = () => {
    overlay.fabricCanvas().remove(currentOrgAnnotations);
    overlay.fabricCanvas().renderAll();
    setShowAnnotationEditPopup(false);
  };

  const changeGlobleAnnotationColor = () => {
    setGlobleColorChangeActive(!globleColorChangeActive);
  };

  const onGlobleAnnoColorChange = color => {
    setGlobleAnnotationColor(color["hex"]);
    openseadragonAnnotations.changeAllAnnotationStrokeColor(color["hex"]);
  };

  const toggleshowBrushSizeTool = () => {
    setshowBrushSizeTool(!showBrushSizeTool);
  };

  const convertHexToRGBA = (hex, opacity) => {
    const tempHex = hex.replace("#", "");
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  };

  const onSelectAnnotationToEdit = activeObjs => {
    const convertedObj = openseadragonAnnotations.duplicateObject(activeObjs);
    setCurrentOrgAnnotations(activeObjs);
    setCurrentAnnotationName(convertedObj["text"]);
    setCurrentAnnotations(convertedObj);
    setShowAnnotationEditPopup(true);
    sentChangeAnnotationEvent(convertedObj);
  };

  if (overlay) {
    overlay.fabricCanvas().observe("mouse:down", o => {
      if (
        openseadragonAnnotations.getMouseMode() === "addAnnotation" &&
        openseadragonAnnotations.annotationType === "brush"
      ) {
        toggleshowBrushSizeTool();
      }
    });

    overlay.fabricCanvas().observe("mouse:up", o => {
      if (openseadragonAnnotations.getMouseMode() === "addAnnotation") {
        switchCursor(false);
        setCurrentAnnotationName(null);
        if (openseadragonAnnotations.annotationType === "brush") {
          overlay.fabricCanvas().isDrawingMode = false;
        }
        const canvasObjects = overlay.fabricCanvas().getObjects();
        if (canvasObjects.length !== 0) {
          const last = canvasObjects[canvasObjects.length - 1];
          const convertedObj = openseadragonAnnotations.duplicateObject(last);
          setCurrentAnnotations(convertedObj);
          if (when !== "quorum") {
            setEditAnnotationData(last);
            setShowAnnotationDataPopup(true);
          } else {
            const data = {
              ...convertedObj,
              text: featureId ? "quorum" : "initial-annotation"
            };
            setAddAnnotations(true);
            setAnnotations({
              annotationData: data,
              slideId,
              featureId,
              dispatch,
              when,
              selectedFolderType:
                selectedFolder && selectedFolder.isPublicFolder
                  ? "public"
                  : "slidebox",
              callback: deleteCurrentDraw
            });
            setCurrentAnnotationType(null);
          }
        }
      }
      if (openseadragonAnnotations.getMouseMode() === "editAnnotation") {
        const canvasObjects = overlay.fabricCanvas().getActiveObject();
        if (canvasObjects) {
          if (!showAnnotationEditPopup) {
            onSelectAnnotationToEdit(canvasObjects);
          }
        }
      }
    });

    overlay.fabricCanvas().observe("before:selection:cleared", o => {
      if (openseadragonAnnotations.getMouseMode() !== "OSD") {
        openseadragonAnnotations.setMouseMode("OSD");
        const convertedObj = openseadragonAnnotations.duplicateObject(o.target);
        setEditAnnotations(false);
        setShowAnnotationEditPopup(false);
        setShowAnnotationDataPopup(false);
        updateAnnotations({
          annotationData: convertedObj,
          slideId,
          dispatch
        });

        if (when !== "quorum" && showAnnotations) {
          openseadragonAnnotations.turnAnnotationsOnOff("on");
        }
      }
    });
  }

  const setUserAnnoatationData = allAnnotationData => {
    const dataSet = allAnnotationData.reduce((r, a) => {
      r[a.user_id] = [...(r[a.user_id] || []), a];
      return r;
    }, {});

    const modifyAnnotationData = Object.keys(dataSet).map(key => {
      return {
        user_id: parseInt(key),
        firstname: dataSet[key][0]["firstname"],
        surname: dataSet[key][0]["surname"],
        slide_id: dataSet[key][0]["slide_id"],
        annotationIds: dataSet[key].map(annoData => annoData.pk),
        quorum_color: dataSet[key][0]["stroke"],
        is_public: dataSet[key][0]["is_public"],
        toggle: dataSet[key][0]["toggle"],
        isValidate: dataSet[key][0]["isValidate"]
      };
    });

    const otherUserAnnoataions = modifyAnnotationData
      .filter(userAnnotation => {
        if (userAnnotation.user_id !== userDetails.id) {
          return true;
        }
      })
      .map(filterData => filterData.annotationIds);

    const currentUserAnnoataions = modifyAnnotationData
      .filter(userAnnotation => {
        if (userAnnotation.user_id === userDetails.id) {
          return true;
        }
      })
      .map(filterData => filterData.annotationIds)[0];

    setOtherUserAnnotations(otherUserAnnoataions.flat(1));
    setCurrentUserAnnotations(currentUserAnnoataions);
  };

  const hideOtherUserAnnotations = () => {
    openseadragonAnnotations.turnAnnotationsOnOffByUser(
      "on",
      currentUserAnnotations
    );
    openseadragonAnnotations.turnAnnotationsOnOffByUser(
      "off",
      otherUserAnnotations
    );
    const otherUserData = {
      annotationIds: otherUserAnnotations,
      onOrOff: "off",
      dispatch: dispatch
    };
    toggleAnnotations(otherUserData);

    const currentUserData = {
      annotationIds: currentUserAnnotations,
      onOrOff: "on",
      dispatch: dispatch
    };
    toggleAnnotations(currentUserData);
  };

  const setBrushSizeInAnnotation = width => {
    setBrushWidth(width);
    overlay.fabricCanvas().isDrawingMode = true;
    overlay.fabricCanvas().freeDrawingBrush.width = width;
    overlay.fabricCanvas().freeDrawingBrush.color = convertHexToRGBA(
      globleAnnotationColor,
      50
    );
  };

  return (
    <React.Fragment>
      <ul>
        <li
          id="toggle-annotation"
          className={!showAnnotations ? "active-tool" : "nonactive-tool"}
          data-for="viewer-toolbar"
          data-tip={"Toggle annotations"}
          onClick={toggleAnnotation}
        >
          <img src={showAnnotations ? ShowAnno : HideAnno} />
        </li>
        {((fromCaseStream && postIsMine) ||
          slideOwnerOrCollaborator ||
          when === "quorum" ||
          when === "groups") && (
          <React.Fragment>
            <li
              id="rect-annotation"
              className={
                !showAnnotations || postIsValidate
                  ? "anno-tool-disable"
                  : currentAnnotationType === "rectangle"
                  ? "active-tool"
                  : ""
              }
              data-for="viewer-toolbar"
              data-tip={"Annotate rectangle"}
              onClick={e =>
                showAnnotations &&
                !postIsValidate &&
                handleAnnotation(e, "rectangle")
              }
            >
              <img src={RectangularAnno} />
            </li>
            <li
              id="circle-annotation"
              className={
                !showAnnotations || postIsValidate
                  ? "anno-tool-disable"
                  : currentAnnotationType === "ellipse"
                  ? "active-tool"
                  : ""
              }
              data-for="viewer-toolbar"
              data-tip={"Annotate ellipse"}
              onClick={e =>
                showAnnotations &&
                !postIsValidate &&
                handleAnnotation(e, "ellipse")
              }
            >
              <img src={CircleAnno} />
            </li>
            <li
              id="polygon-annotation"
              className={
                !showAnnotations || postIsValidate
                  ? "anno-tool-disable"
                  : currentAnnotationType === "brush"
                  ? "active-tool"
                  : ""
              }
              data-for="viewer-toolbar"
              data-tip={"Annotate brush"}
              onClick={e =>
                showAnnotations &&
                !postIsValidate &&
                handleAnnotation(e, "brush")
              }
            >
              <img src={PolygonAnno} />
            </li>
            <li
              id="arrow-annotation"
              className={
                !showAnnotations || postIsValidate
                  ? "anno-tool-disable"
                  : currentAnnotationType === "arrow"
                  ? "active-tool"
                  : ""
              }
              data-for="viewer-toolbar"
              data-tip={"Annotate arrow"}
              onClick={e =>
                showAnnotations &&
                !postIsValidate &&
                handleAnnotation(e, "arrow")
              }
            >
              <img src={ArrowAnno} />
            </li>

            <li
              id="edit-annotation"
              className={
                !showAnnotations
                  ? "anno-tool-disable"
                  : editAnnotations
                  ? "active-tool"
                  : "nonactive-tool"
              }
              data-for="viewer-toolbar"
              data-tip={"Edit annotation"}
              onClick={handleEditAnnotation}
            >
              <img src={EditAnno} />
            </li>

            <li
              id="annotation-color-change"
              className={
                !showAnnotations || postIsValidate
                  ? "anno-tool-disable"
                  : globleColorChangeActive
                  ? "active-tool"
                  : when === "quorum"
                  ? postIsMine
                    ? "nonactive-tool"
                    : "anno-tool-disable"
                  : "nonactive-tool"
              }
              data-for="viewer-toolbar"
              data-tip={"Set annotation color"}
              onClick={
                when === "quorum"
                  ? postIsMine
                    ? changeGlobleAnnotationColor
                    : null
                  : changeGlobleAnnotationColor
              }
            >
              <img src={ColorChangeIcon} />
              <span
                style={{ backgroundColor: globleAnnotationColor }}
                class="notification-counter"
              ></span>
            </li>
          </React.Fragment>
        )}
      </ul>
      {showBrushSizeTool && (
        <div className="right-image-menu-control-parent color-adjustment-menu">
          <p className="right-image-menu-color-control-title">Brush Size</p>
          <div className="right-image-menu-item">
            <Slider
              min={10}
              max={400}
              defaultValue={60}
              onChange={setBrushSizeInAnnotation}
            />
          </div>
        </div>
      )}
      {showAnnotationDataPopup && (
        <AnnotationEditPopup
          onClose={onClose}
          setAnnoatationName={setAnnoatationName}
          handleSaveClick={handleSaveClick}
          currentAnnotationName={currentAnnotationName}
          currentAnnotationData={editAnnotationData}
        />
      )}
      {showAnnotationEditPopup && (
        <AnnotationDetailsPopup
          currentAnnotationName={currentAnnotationName}
          editBtnOnClick={editBtnOnClick}
          deleteBtnOnClick={deleteBtnOnClick}
          onColorChange={onColorChange}
          when={when}
          currentAnnotationData={currentOrgAnnotations}
        />
      )}
      {globleColorChangeActive && (
        <AnnotationColorPicker
          customClass={"toolbar-color-picker-continer"}
          onColorChange={onGlobleAnnoColorChange}
        />
      )}
      <ToolTip id="viewer-toolbar" place="bottom" type="info" effect="solid" />
      {showConfirmDelete && (
        <Prompt
          title={`Confirm Action`}
          content={`Are you sure you want to delete this annotation ?`}
          actionText="Yes"
          action={onDeleteAnnotationComfirmed}
          back={() => {
            setShowConfirmDelete(false);
          }}
          img={DeleteIcon}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  allAnnotationData: state.Viewer.annotationData,
  toogleAnnotation: state.Viewer.toogleData,
  isSoketDataUpdate: state.Viewer.isSoketDataUpdate,
  userDetails: state.User.session,
  selectedFolder: state.SlideBox.selectedUploadFolder
});

export default connect(mapStateToProps)(ViewerToolbarAnnotation);
