import React from 'react'
import PropTypes from 'prop-types'
import AppLink from '../../../others/link/link'

const NotificationTypePost = ({ post_id, postid_token, type }) => (
  <AppLink
    url={
      type === 'slidebox'
        ? `/viewer?image=${postid_token}`
        : `/openpost/${postid_token}`
    }
    className="pri_btn"
    label={type === 'slidebox' ? 'Open image' : 'Open post'}
  />
)

NotificationTypePost.propTypes = {
  post_id: PropTypes.number.isRequired,
}

export default NotificationTypePost
