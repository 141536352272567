import React, { Component } from "react";
import { connect } from "react-redux";
import { humanReadable } from "../../../../utils/utils";
import Comments from "../../comment/comments/comments";
import ImageComment from "../../comment/image-comment/imageComment";
import StickerComment from "../../comment/sticker-comment/stickerComment";
import TextComment from "../../comment/text-comment/text-comment";
import { shape, number, string, array } from "prop-types";
import AppLink from "../../../others/link/link";
import CommentBox from "./comment-box";

class PostBottom extends Component {
  state = {
    comments_count: 0,
    show_all_comments: false
  };

  componentDidMount = () =>
    this.setState({
      comments_count: this.props.postDetails.comments_count
    });

  incrementComments = () =>
    this.setState({
      comments_count: ++this.state.comments_count
    });

  loadAllComments = (e, postId) => {
    e.preventDefault();
    if (this.state.show_all_comments) {
      document.getElementById(`post_${postId}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      });
    }
    this.setState({
      show_all_comments: !this.state.show_all_comments
    });
  };

  render() {
    let { comments_count, show_all_comments } = this.state;
    let {
      postDetails,
      postDetails: { post_id, when, comments, is_followed },
      session,
      isComment,
      isReply,
      commentBoxId
    } = this.props;
    let childProps = {
      postDetails,
      incrementComments: this.incrementComments
    };

    return (
      <div className="p_c_conatiner">
        <CommentBox
          commentBoxId={commentBoxId}
          comment_id={null}
          session={session}
          childProps={childProps}
        />
        <hr class="comment_box_hr" style={{ margin: "10px 0 10px 0" }} />
        <Comments
          allCommets={when === "feed" ? show_all_comments : !show_all_comments}
          loadAllComments={this.loadAllComments}
          when={when}
          post={post_id}
          comments={comments}
          isComment={isComment}
          isReply={isReply}
          decrementComments={() =>
            this.setState({ comments_count: --comments_count })
          }
          childProps={childProps}
          session={session}
        />
      </div>
    );
  }
}

PostBottom.propTypes = {
  postDetails: shape({
    comments_count: number.isRequired,
    post_id: number.isRequired,
    when: string.isRequired,
    user: number.isRequired,
    comments: array
  }).isRequired
};

const mapStateToProps = store => ({
  session: store.User.session.id
});

export default connect(mapStateToProps)(PostBottom);
export { PostBottom as PurePostBottom };
