import React, { Component, Fragment } from 'react'
import Share from './share/share'
import PropTypes from 'prop-types'
import ShareIcon from '../../../assets/icons/share-icon.svg';
export default class PostShare extends Component {
  state = {
    showShare: false,
    shareModalTop: false,
  }
  constructor(props) {
    super(props)
    this.shareButtonRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node
  }

  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.id !== 'share-image-icon'
    ) {
      this.setState({ showShare: false })
    }
  }

  _toggle = what => {
    let {
      postDetails: { post_id, postid_token },
      isMobile,
    } = this.props
    this.setState({ [what]: !this.state[what] })
    const viewportY = this.shareButtonRef.current.getBoundingClientRect().y
    if (viewportY > 380) {
      this.setState({
        shareModalTop: true,
      })
    } else {
      this.setState({
        shareModalTop: false,
      })
    }
  }

  render() {
    let {
      postDetails: {
        post_id,
        user,
        description,
        postid_token,
        slideThumbnailSrc,
        type,
        dzi_info,
      },
      incrementWhat,
      decrementWhat,
      isMobile,
      isQuorum,
      img
    } = this.props
    let { showShare, shareModalTop } = this.state
    return (
      <Fragment>
        <span
          ref={this.shareButtonRef}
          className={
            showShare
              ? isQuorum
                ? 'p_send p_comm opened quorum-share'
                : 'p_send p_comm opened'
              : isQuorum
                ? 'p_send p_comm quorum-share'
                : 'p_send p_comm'
          }
          data-tip={isMobile ? '' : 'Share'}
          onClick={() => this._toggle('showShare')}
        >
          <img
            src={ShareIcon}
            id="share-image-icon"
            style={{ height: '17px', cursor: 'pointer' }}
          />
        </span>
        <div
          ref={this.setWrapperRef}
          style={
            !showShare
              ? { display: 'none' }
              : { display: 'unset', marginLeft: 0 }
          }
        >
          <Share
            top={shareModalTop}
            type={type}
            slideThumbnailSrc={slideThumbnailSrc}
            dziInfo={dzi_info}
            postid_token={postid_token}
            post={post_id}
            title={description}
            back={() => this._toggle('showShare')}
            postOwner={user}
            isQuorum={isQuorum}
            incrementShares={() => incrementWhat('shares_count')}
            decrementShares={() => decrementWhat('shares_count')}
            feed={true}
            img={img}
          />
        </div>
      </Fragment>
    )
  }
}

PostShare.propTypes = {
  postDetails: PropTypes.shape({
    post_id: PropTypes.number.isRequired,
    user: PropTypes.number.isRequired,
  }).isRequired,
  incrementWhat: PropTypes.func.isRequired,
  decrementWhat: PropTypes.func.isRequired,
}
