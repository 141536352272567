import * as methods from "./methods";

const initialState = {
  folders: [],
  isQuorumLoaded: false,
  quorumImageFromSlideBox: "",
  addQuorumEnableButton: false,
  addTumorBoardEnableButton: false,
  selectedUploadFolder: null,
  slideBoxSize: {
    currentUsageBytes: 0,
    totalStorageBytes: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_SLIDE_BOX_LOADING": {
      return {
        ...state,
        loading: action.loading
      };
    }
    case "SET_FOLDER_LISTS": {
      const folders = methods.getFoldersFromResponse(action.data);
      return {
        ...state,
        folders: folders,
        treeStructure: methods.getTreeStructure(folders),
        loading: false
      };
    }

    case "SET_FOLDERS": {
      const { folders } = action.data;
      return {
        ...state,
        folders
      };
    }

    case "SET_SELECTED_UPLOAD_FOLDER": {
      const { folder } = action.data;
      return {
        ...state,
        selectedUploadFolder: folder
      };
    }

    case "SET_LOAD_QUOROM": {
      return {
        ...state,
        isQuorumLoaded: true,
        quorumImageFromSlideBox: action.data
      };
    }

    case "SET_CASESTREAM_MODAL": {
      return {
        ...state,
        casestreamModalData: action.data
      };
    }

    case "SET_ADD_QUOROM_BUTTON": {
      return {
        ...state,
        addQuorumEnableButton: action.data
      };
    }

    case "SET_ADD_TUMOR_BOARDS_ENABLE": {
      return {
        ...state,
        addTumorBoardEnableButton: action.enable
      };
    }

    case "SET_SLIDE_BOX_SIZE": {
      return {
        ...state,
        slideBoxSize: action.data
      };
    }

    default:
      return state;
  }
};
