import React, { useState, useEffect } from "react";
import NavigatorToggle from "../icons/navigator-toggle-icon.svg";
import SlideThumbToggle from "../icons/toggle-slide-icon.svg";
import VerticleLine from "../icons/verticle-line.svg";
import LiveShare from "../icons/live-share.png";
import ViewerToolBarBasic from "./viewer-toolbar-basic";
import ViewerToolBarFilter from "./viewer-toolbar-filters";
import ViewerToolbarAnnotation from "./viewer-toolbar-annotation";
import SlideManagerIcon from "../icons/toggle-slide-manage-new.svg";
import ToolTip from "react-tooltip";
import { connect } from "react-redux";
import {
  liveShareOnAction,
  liveShareUserOnAction
} from "../../../actions/viewer";
import Notify from "handy-notification";

import "./viewer-toolbar.scss";

const ViewerToolBar = ({
  viewer,
  OpenSeadragon,
  toggleThumbnailSlider,
  osdButtons,
  overlay,
  slideThumbData,
  zoomValueBar,
  slideId,
  when,
  featureId,
  fromCaseStream,
  postIsMine,
  slideOwnerOrCollaborator,
  liveShareOn,
  liveShareUserOn,
  groupAdmin,
  enableFullScreenState,
  setFullScreenRef,
  thumbnailSliderShow,
  selectedGroupData,
  userDetails,
  userLiveShareOn,
  postIsValidate,
  toggleSlideManager,
  showCollapsibleSidebar,
  rotationValue,
  overlayInstance,
  viewer1,
  imageViewer,
  selectedViewer,
  rotateImageHandler,
  showCustomRotate,
  handleRotate,
  handleHomeClick
}) => {
  const [showMiniMap, setShowMiniMap] = useState(true);
  const [liveShareState, setLiveShareState] = useState(false);
  const [userLiveShareState, setUserLiveShareState] = useState(false);

  useEffect(() => {
    if (selectedGroupData) {
      setLiveShareState(selectedGroupData.slideLiveShareStatus);
    }
    if (
      selectedGroupData &&
      userLiveShareOn &&
      !groupAdmin &&
      (userDetails.id !== selectedGroupData.slideLiveSharePerson ||
        selectedGroupData.slideLiveSharePerson)
    ) {
      Notify({
        value:
          "Successfully joined LiveSync. Slide Viewer is in SYNC with the group admin"
      });
    }
    setUserLiveShareState(userLiveShareOn);
  }, [selectedGroupData, userLiveShareOn]);

  const onToggleMiniMap = e => {
    e.preventDefault();
    if (showMiniMap) {
      viewer.navigator.element.style.display = "none";
    } else {
      viewer.navigator.element.style.display = "inline-block";
    }
    setShowMiniMap(!showMiniMap);
  };

  const onToggleLiveSharing = () => {
    setLiveShareState(!liveShareState);
    liveShareOn(!liveShareState);
    if (liveShareState) {
      Notify({ value: "Successfully stopped LiveSync" });
    } else {
      Notify({
        value:
          "Successfully started LiveSync. All group members can see your viewer"
      });
    }
  };

  const onLiveSharingUserStatus = () => {
    setUserLiveShareState(!userLiveShareState);
    liveShareUserOn(!userLiveShareState);
    if (userLiveShareState) {
      Notify({ value: "Successfully exited LiveSync." });
    } else {
      Notify({
        value:
          "Successfully joined LiveSync. Slide Viewer is in SYNC with the group admin."
      });
    }
  };

  return (
    <div className="viewer-toolbar-container" style={{ paddingLeft: "10px" }}>
      <ViewerToolBarBasic
        viewer={viewer}
        OpenSeadragon={OpenSeadragon}
        osdButtons={osdButtons}
        enableFullScreenState={enableFullScreenState}
        setFullScreenRef={setFullScreenRef}
        rotationValue={rotationValue}
        rotateImageHandler={rotateImageHandler}
        showCustomRotate={showCustomRotate}
        handleRotate={handleRotate}
        handleHomeClick={handleHomeClick}
      />
      <ViewerToolBarFilter viewer={viewer} OpenSeadragon={OpenSeadragon} />
      <ul>
        <li
          id="show-navigator"
          className={showMiniMap ? "active-tool" : "nonactive-tool"}
          data-for="viewer-toolbar"
          data-tip={
            showMiniMap ? "Hide slide navigator" : "Show slide navigator"
          }
          onClick={onToggleMiniMap}
        >
          <img src={NavigatorToggle} />
        </li>
        {slideThumbData.length > 0 && !enableFullScreenState && (
          <li
            id="show-slidethumb"
            className={thumbnailSliderShow ? "active-tool" : "nonactive-tool"}
            data-for="viewer-toolbar"
            data-tip={
              thumbnailSliderShow
                ? when == "groups"
                  ? "Hide group history"
                  : "Hide slide tray"
                : when == "groups"
                ? "Show group history"
                : "Show slide tray"
            }
            onClick={toggleThumbnailSlider}
          >
            <img src={SlideThumbToggle} />
          </li>
        )}
        {when !== "groups" && (
          <li
            id="show-slidemanager"
            className={
              showCollapsibleSidebar ? "active-tool" : "nonactive-tool"
            }
            data-for="viewer-toolbar"
            data-tip={"Toggle slide manager"}
            onClick={toggleSlideManager}
          >
            <img src={SlideManagerIcon} />
          </li>
        )}
        <li>
          <img src={VerticleLine} />
        </li>
      </ul>
      {!enableFullScreenState && (
        <ViewerToolbarAnnotation
          overlay={overlay}
          viewer={viewer}
          zoomValueBar={zoomValueBar}
          slideId={slideId}
          when={when}
          featureId={featureId}
          fromCaseStream={fromCaseStream}
          postIsMine={postIsMine}
          slideOwnerOrCollaborator={slideOwnerOrCollaborator}
          postIsValidate={postIsValidate}
          overlayInstance={overlayInstance}
          viewer1={viewer1}
          imageViewer={imageViewer}
          selectedViewer={selectedViewer}
        />
      )}
      <ToolTip id="viewer-toolbar" place="bottom" type="info" effect="solid" />
      {when == "groups" && (
        <React.Fragment>
          {groupAdmin &&
          selectedGroupData &&
          (userDetails.id === selectedGroupData.slideLiveSharePerson ||
            !selectedGroupData.slideLiveSharePerson) ? (
            <ul className="live-sync-indicator">
              <li
                id="show-navigator"
                className={liveShareState ? "active-tool" : "nonactive-tool"}
                data-for="viewer-toolbar"
                data-tip={liveShareState ? "Stop LiveSync" : "Start LiveSync"}
                onClick={onToggleLiveSharing}
              >
                <img src={LiveShare} />
                <span
                  class={
                    liveShareState
                      ? "live-share-on-indicator"
                      : "live-share-off-indicator"
                  }
                ></span>
              </li>
            </ul>
          ) : (
            <ul className="live-sync-indicator">
              <li
                id="show-navigator"
                className={
                  selectedGroupData.slideLiveShareStatus
                    ? userLiveShareState
                      ? "active-tool"
                      : "nonactive-tool"
                    : "anno-tool-disable"
                }
                data-for="viewer-toolbar"
                data-tip={
                  userLiveShareState ? "Exit LiveSync" : "Join LiveSync"
                }
                onClick={
                  selectedGroupData.slideLiveShareStatus &&
                  onLiveSharingUserStatus
                }
              >
                <img src={LiveShare} />
                <span
                  class={
                    userLiveShareState
                      ? "live-share-on-indicator"
                      : "live-share-off-indicator"
                  }
                ></span>
              </li>
            </ul>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

//liveShareOnAction

const mapStateToProps = state => ({
  userDetails: state.User.user_details,
  userLiveShareOn: state.Viewer.userLiveShareOn,
  liveShareIsOn: state.Viewer.liveShareOn
});

const mapDispatchToProps = dispatch => ({
  liveShareOn: payload => dispatch(liveShareOnAction(payload)),
  liveShareUserOn: payload => dispatch(liveShareUserOnAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewerToolBar);
