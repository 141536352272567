import React from "react";
import ContentLoader from "react-content-loader";

const CaseStreamCardLoader = () => (
  <ContentLoader
    speed={2}
    width={1240}
    height={400}
    viewBox="0 0 1240 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="40" rx="0" ry="0" width="400" height="400" />
    <rect x="420" y="40" rx="0" ry="0" width="400" height="400" />
    <rect x="840" y="40" rx="0" ry="0" width="400" height="400" />
  </ContentLoader>
);

export default CaseStreamCardLoader;
