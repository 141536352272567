import React, { Component, Fragment } from 'react'
import { Me } from '../../../../../utils/utils'
import { isAdmin } from '../../../../../utils/admin-utils'
import { casesolovePost } from '../../../../../utils/post-utils'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CaseSolvedIcon from './icons/case-solved-icon.svg'

class CaseslovePostOption extends Component {
  state = {
    redirect: false,
  }

  caseSolveClick = e => {
    e.preventDefault()
    let {
      postDetails: { post_id, case_slove, when },
      dispatch,
    } = this.props
    casesolovePost({
      post_id,
      isSlove: case_slove ? false : true,
      when,
      dispatch,
      callback: () => {
        this.props.toggleOptions()
      },
    })
  }

  modalBack = () => {
    this.props.toggleOptions()
    this.setState({ deletePost: false })
  }

  render() {
    let {
      postDetails: { user, case_slove },
    } = this.props
    return (
      <Fragment>
        {(Me(user) || isAdmin()) && (
          <li style={{ display: 'flex' }}>
            <a href="" onClick={this.caseSolveClick}>
              <img
                src={CaseSolvedIcon}
                alt="case solved"
                style={{ width: 20, marginLeft: -10, marginRight: 10 }}
              />
              {case_slove ? 'Unsolved Case' : 'Case Solved'}{' '}
              {isAdmin() ? 'as admin' : null}
            </a>
          </li>
        )}
      </Fragment>
    )
  }
}

CaseslovePostOption.propTypes = {
  postDetails: PropTypes.shape({
    user: PropTypes.number.isRequired,
    post_id: PropTypes.number.isRequired,
    when: PropTypes.string.isRequired,
    case_slove: PropTypes.bool.isRequired,
  }).isRequired,
  toggleOptions: PropTypes.func.isRequired,
}

// export default connect()(CaseslovePostOption)
export default connect()(CaseslovePostOption)
export { CaseslovePostOption as PureCaseslovePostOption }
