import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Notify from "handy-notification";
import SlideboxPage from "../slidebox-component/slidebox-page";
import QuorumIcon from "./icons/quorum-icon.svg";

const plusSrc = "/images/img/add.svg";
const arrowLeft = "/images/img/arrow_left.svg";

class QuorumAction extends Component {
  constructor() {
    super();
    this.state = {
      mode: "new", //can be new or join
      quorumKey: "",
      modal: false,
      isOpenMenu: true
    };

    this.modeChange = this.modeChange.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }
  modeChange = e => {
    const mode = e.target.title;
    const activeButtons = e.target.parentNode.getElementsByClassName("active"); //fetch active button, returns array
    if (activeButtons) {
      Array.from(activeButtons).forEach(a => {
        a.classList.remove("active");
      });
    }
    e.target.classList.add("active");
    if (mode == "Create new") {
      this.setState({ mode: "new" });
    } else {
      this.setState({ mode: "join" });
    }
    this.isOpenQuorumMenu();
  };
  toggleModal = e => {
    const overlay = document.getElementById("quorum-modal-overlay");
    if (
      e.target == overlay ||
      e == "CLOSE MODAL" ||
      e.target.id == "close_modal_streams"
    ) {
      this.setState({ modal: false });
    } else {
      this.setState({ modal: true });
    }
  };
  valueChange = e => this.setState({ quorumKey: e.target.value });
  loadQuorumPageByKey = async () => {
    let { quorumKey } = this.state;
    let response = await fetch("/api/get-quorumid-bykey", {
      method: "POST",
      body: JSON.stringify({
        quorumKey
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    }).then(x => x.json());

    if (response.success) {
      this.props.history.push(`/quorum/${response.quorum_id}`);
    } else {
      Notify({
        value: "No Quorum found"
      });
    }
  };

  isOpenQuorumMenu = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    let { modal } = this.state;
    const closeSrc = "/images/img/closeModal.svg";
    return (
      <div className="quorum-action-main-container">
        <div
          className={
            this.state.isOpenMenu
              ? "left-container"
              : "left-container hide-container"
          }
        >
          <span className="heading">Quorum</span>
          <div
            className="button-div active create_new"
            onClick={this.modeChange}
            title="Create new"
          >
            <img
              src={"/images/icons/settings/icons8-groups.png"}
              alt="Logo"
              className="button-logo"
            />
            <span class="button-text">Create new</span>
          </div>
          <div
            className="button-div"
            onClick={this.modeChange}
            title="Join existing"
          >
            <img
              src={"/images/icons/settings/icons8-external.png"}
              alt="Logo"
              className="button-logo"
            />
            <span class="button-text">Join existing</span>
          </div>
        </div>
        <div
          className={
            this.state.isOpenMenu
              ? "right-container hide-container"
              : "right-container"
          }
        >
          <div className="go-to-previous" onClick={this.isOpenQuorumMenu}>
            <img src={arrowLeft} />
            <span>Join existing</span>
          </div>
          <img src={QuorumIcon} alt="Logo" className="logo" />
          {this.state.mode == "new" ? (
            <div
              className="add-quorum-button"
              style={{
                width: "35%",
                alignSelf: "center",
                textAlign: "left",
                marginBottom: 20
              }}
              onClick={this.toggleModal}
            >
              <img src={plusSrc} className="button-plus-img" />
              <h4 class="button-text">Add to Quorum</h4>
            </div>
          ) : (
            <div className="add-quorum-bar" style={{ width: 420 }}>
              <textarea
                placeholder="Enter code to join"
                className="join-code"
                onChange={this.valueChange}
                styleee={{ borderRadius: 5 }}
              />
              <button
                className="join-button"
                onClick={() => this.loadQuorumPageByKey()}
              >
                Join
              </button>
            </div>
          )}
          <p className="quorum-text">
            Learn and study using an interactive medical viewer based session
          </p>
        </div>
        {modal ? (
          <div
            className="overlay-quorum"
            id="quorum-modal-overlay"
          >
            <div className="modal-slidebox">
              <React.Fragment>
                <div className="header-modal">
                  <span>Add from Slidebox</span>
                  <img
                    src={closeSrc}
                    onClick={e => this.toggleModal("CLOSE MODAL")}
                    id="close_modal_streams"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <SlideboxPage forQuorum={true} showShared={false} />
              </React.Fragment>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(QuorumAction);
