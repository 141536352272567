/* eslint indent:0 */
/* eslint no-unreachable:0 */

import InitialState from './intialState'
import * as methods from './methods'

export default (state = InitialState, action) => {
  let py = action.payload

  switch (action.type) {
    case 'GET_USER_DETAILS':
      if (state.session.id === py.details.id) {
        return {
          ...state,
          current_user_details: py.details,
          user_details: py.details,
          tags: py.tags,
          session: {
            id: py.details.id,
            username: py.details.username,
          },
        }
      } else if (state.session.id === 0) {
        return {
          ...state,
          session: {
            id: py.details.id,
            username: py.details.username,
          },
          user_details: py.details,
          current_user_details: py.details,
          tags: py.tags,
        }
      } else {
        return {
          ...state,
          user_details: py.details,
          tags: py.tags,
        }
      }

      break

    case 'ADD_TAG':
      return { ...state, tags: methods.addTag(state.tags, py) }
      break

    case 'DELETE_TAG':
      return { ...state, tags: methods.deleteTag(state.tags, py) }
      break

    case 'GET_MUTUAL_USERS':
      return { ...state, mutualUsers: py }
      break

    case 'SET_ACTIVE_PAGE':
      return { ...state, activeRoute: py }
      break

    case 'RESET_USER':
      return {
        ...state,
        user_details: {
          id: null,
          username: '',
          firstname: '',
          surname: '',
          email: '',
          bio: '',
          account_type: '',
          email_verified: '',
          joined: '',
          twitter: '',
          facebook: '',
          github: '',
          instagram: '',
          phone: '',
          website: '',
          isOnline: false,
          lastOnline: '',
        },
      }
      break

    case 'GET_IS_LOGOUT_USER':
      return { ...state, isLogoutUser: py }
      break
  }

  return state
}
