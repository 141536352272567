import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ClosePopupIcon from "../../../assets/icons/close-new.svg";
import AccountIcon from "../../../assets/icons/account-preference.svg";
import Overlay from "../../others/overlay";
import { FadeIn } from "animate-components";
import { AppBar, Drawer, Toolbar } from "@material-ui/core";
import Notifications from "./EditingTabs/Notifications";
import Account from "./EditingTabs/Account";
import { editProfile } from "../services/edit-profile-utils";
import { getUserDetails } from "../../../actions/user";
import { connect } from "react-redux";
import "./AccountPreferences.scss";
import Deactivate from "./EditingTabs/Deactivate";
import Delete from "./EditingTabs/Delete";

const drawerWidth = 160;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    maxHeight: "465px"
  },
  title: {
    color: "#0F0DA1",
    fontSize: "16px",
    marginLeft: "10px",
    fontWeight: "700"
  },
  img: {
    marginLeft: "-3px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    zIndex: 3
  },
  drawer: {
    width: drawerWidth,
    backgroundColor: "#ffffff",
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 2
  },
  drawerContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    alignContent: "center"
  }
}));

const AccountPreferences = ({ openModal, onClose, ud, session }) => {
  const classes = useStyles();

  const [renderChangePassword, setRenderChangePassword] = React.useState(false);
  const [renderNotification, setRenderNotification] = React.useState(false);
  const [modalMiddle, setModalMiddle] = React.useState(true);
  const [renderDeactivate, setRenderDeactivate] = React.useState(false);
  const [renderDelete, setRenderDelete] = React.useState(false);

  const handleRenderChangePassword = () => {
    setRenderChangePassword(true);
    setRenderNotification(false);
    setRenderDeactivate(false);
    setRenderDelete(false);
    setModalMiddle(!modalMiddle);
  };

  const handleRenderNotification = () => {
    setRenderNotification(true);
    setRenderChangePassword(false);
    setRenderDeactivate(false);
    setRenderDelete(false);
    setModalMiddle(false);
  };

  const handleRenderDeactivate = () => {
    setRenderDeactivate(true);
    setRenderNotification(false);
    setRenderChangePassword(false);
    setRenderDelete(false);
    setModalMiddle(false);
  };

  const handleRenderDelete = () => {
    setRenderDelete(true);
    setRenderNotification(false);
    setRenderChangePassword(false);
    setRenderDeactivate(false);
    setModalMiddle(false);
  };

  const onDoneEditing = updatedUser => {
    const { username, email } = ud;
    editProfile(
      {
        susername: username,
        semail: email,
        values: updatedUser
      },
      () => {
        // update when success
        dispatch(getUserDetails(updatedUser.username));
      }
    );
  };

  return (
    openModal && (
      <React.Fragment>
        <Overlay />
        <div className="modal_big">
          <div className="prompt-preferences">
            <FadeIn duration="300ms">
              <div className={classes.root}>
                <CssBaseline />

                <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar className="prompt-top">
                    <div className="prompt-title">
                      <img
                        className={classes.img}
                        src={AccountIcon}
                        alt={"account"}
                      />
                      <span className={classes.title}>Account Preferences</span>
                    </div>
                    <img
                      className="close-icon"
                      src={ClosePopupIcon}
                      onClick={() => {
                        onClose(false);
                        setRenderChangePassword(false);
                      }}
                    />
                  </Toolbar>
                </AppBar>

                <Drawer
                  className={classes.drawer}
                  variant="permanent"
                  classes={{
                    paper: classes.drawerPaper
                  }}
                >
                  <Toolbar />
                  <div className={classes.drawerContainer}>
                    <List>
                      {["Change Password"].map((text, index) => (
                        <ListItem button key={text}>
                          <ListItemText
                            primary={text}
                            onClick={handleRenderChangePassword}
                          />
                          <Divider />
                        </ListItem>
                      ))}

                      <ListItem button>
                        <ListItemText
                          primary="Notifications"
                          onClick={handleRenderNotification}
                        />
                        <Divider />
                      </ListItem>

                      <ListItem button>
                        <ListItemText
                          primary="Deactivate Account"
                          onClick={handleRenderDeactivate}
                        />
                        <Divider />
                      </ListItem>

                      <ListItem button>
                        <ListItemText
                          primary="Delete Account"
                          onClick={handleRenderDelete}
                        />
                        <Divider />
                      </ListItem>
                    </List>
                  </div>
                </Drawer>
                <main className={classes.content}>
                  <Toolbar />
                  <div className="modal_middle">
                    {(renderChangePassword || modalMiddle) && (
                      <div className="d-flex justify-content-between box-sizing">
                        <div className="change-password">
                          <Account
                            onClose={onClose}
                            label="Cancel"
                            onDoneEditing={onDoneEditing}
                          />
                        </div>
                        <div className="password-hints">
                          <p className="must-Contain">Password must contain:</p>
                          <div className="hints">
                            <p>At least 1 upper case letter (A-Z)</p>
                            <p>At least 1 number (0-9)</p>
                            <p>At least 8 characters</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {renderNotification && (
                      <Notifications onClose={handleRenderNotification} />
                    )}

                    {renderDeactivate && (
                      <Deactivate ud={ud} onClose={handleRenderDeactivate} />
                    )}

                    {renderDelete && (
                      <Delete ud={ud} onClose={handleRenderDelete} />
                    )}
                  </div>
                </main>
              </div>
            </FadeIn>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

const mapStateToProps = store => ({
  ud: store.User.user_details,
  session: store.User.session,
  current_user_details: store.User.current_user_details
});

export default connect(mapStateToProps)(AccountPreferences);
