import React, { Component } from 'react'
import Title from '../others/title'
import { FadeIn } from 'animate-components'
import Notification from './notification/notification'
import { connect } from 'react-redux'
import { getNotifications, readNotifications } from '../../actions/notification'
import Nothing from '../others/nothing'
import End from '../others/end'
import { getUnreadMessages } from '../../actions/message'
import NotificationsHeader from './n-header'
import IsLoading from '../others/isLoading'
import { cLoading } from '../../utils/utils'
import { setActivePage } from '../../actions/user'
import RequestAndInvitation from './RequestAndInvitation'
import { BulletList } from 'react-content-loader'

class Notifications extends Component {
  state = { loading: true }

  componentDidMount = () => {
    let { dispatch } = this.props
    dispatch(readNotifications())
    dispatch(getNotifications())
    dispatch(getUnreadMessages())
    dispatch(setActivePage('notification'))
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.notifications) {
      this.setState({ loading: false })
    }
  }

  render() {
    let { notifications = [], requests, invitations } = this.props,
      { loading } = this.state,
      len = notifications.length + requests.length + invitations.length

    const allNots = [...notifications, ...requests, ...invitations]
    allNots.sort((a, b) => {
      let timeA =
        typeof a.notify_id === 'undefined'
          ? typeof a.time === 'string'
            ? Date.parse(a.time)
            : a.time
          : parseInt(a.notify_time)
      let timeB =
        typeof b.notify_id === 'undefined'
          ? typeof b.time === 'string'
            ? Date.parse(b.time)
            : b.time
          : parseInt(b.notify_time)
      if (timeA > timeB) return -1
      if (timeB > timeA) return 1
      if (timeB === timeA) return 0
    })

    const map_n = allNots.map(n => {
      if (typeof n.notify_id !== 'undefined')
        return <Notification key={n.notify_id} {...n} />
      else return <RequestAndInvitation key={n.id} payload={n} />
    })

    return (
      <div className="notification-container">
        {/* <IsLoading loading={loading} when="page" /> */}
        <Title value="Notifications" />

        {loading && (
          <div style={{ margin: '0 0 0 25%' }}>
            <BulletList />
          </div>
        )}
        <FadeIn duration="300ms" className={cLoading(loading)}>
          <div className="notifications_div">
            <NotificationsHeader />
            {len == 0 ? <Nothing /> : map_n}
            {len != 0 && <End />}
          </div>
        </FadeIn>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  notifications: store.Notification.notifications,
  invitations: store.TumorBoards.invitations,
  requests: store.TumorBoards.requests, 
})

export default connect(mapStateToProps)(Notifications)
export { Notifications as PureNotifications }
