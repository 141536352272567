import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import End from "../others/end";
import Post from "../post/post/post";
import Quorum from "../post/post/quorum";
import MapPosts from "../post/map-posts/map-posts";
import CaseStreamSecondLoader from "../others/fadedLoaders/case-stream-second-loader";
import CaseStreamCardLoader from "../others/fadedLoaders/case-stream-card-loader";
import Search from "../others/search/search";
import { Switch, FormControlLabel } from '@material-ui/core'
import { isAdmin } from '../../utils/admin-utils'
import { hidePrompt } from '../../actions/prompt';
import { switchGridView } from "../../actions/post";
import Prompt from '../../components/others/prompt';
import PostCardView from '../PostCardView/PostCardView'
import ListViewIcon from './icons/list-view-icon.svg'
import GridViewIcon from './icons/grid-view-icon.svg'
import ListViewIconActive from './icons/list-view-icon-active.svg'
import GridViewIconActive from './icons/grid-view-icon-active.svg'
import PublishIcon from './icons/publish-icon.svg'
import ThumbsIcon from './icons/thumbs-icon.png'

const Feed = ({
  feed,
  isMobile,
  isFeedLoad,
  isAllFeedLoad,
  triggerModal,
  windowSize,
  isLogoutUser,
  activeRoute,
  showReview,
  toggleReview,
  isShowPrompt,
  closePrompt,
  isGridView,
  switchGridView
}) => {

  const computeMapFeed = () => {
    if (!feed) return [];

    return feed
      .filter(f => f.account_status !== "delete")
      .map((f, i) => {
        if (f.type === "quorum") {
          return (
            <Quorum
              key={i}
              {...f}
              goTo={`/quorum/${f.postid_token}`}
              isMobile={isMobile}
              when="feed"
              isLoggedUser={isLogoutUser === "no" ? true : false}
            />
          );
        } else {
          return (
            <Post
              key={i}
              {...f}
              goTo={`/openpost/${f.postid_token}`}
              isMobile={isMobile}
              when="feed"
              isLoggedUser={isLogoutUser === "no" ? true : false}
            />
          );
        }
      });
  }

  let nothingMssg = "Looks like you're new";

  const onClick = e => {
    triggerModal(e);
  };

  const initialLoading = feed.length == 0 && !isFeedLoad

  return (
    <Fragment>
      {!isMobile && !initialLoading && (
        <div className="wf-feed-header">
          <Search activeRoute={activeRoute} />
          <div className="home-right-group">
            <div className="feed-header-right-section">
              {
                isAdmin() && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showReview}
                        onChange={toggleReview}
                        name="switchReview"
                        color="primary"
                      />
                    }
                    label="Show only under review"
                  />
                )
              }
            </div>
            <div className="switch-view-container">
              <div onClick={() => switchGridView(true)} style={{ "display": `${isGridView ? 'block' : 'none'}` }}>
                <img className='switch-icon'
                  src={GridViewIconActive}></img>
              </div>
              <div onClick={() => switchGridView(false)} style={{ "display": `${isGridView ? 'block' : 'none'}` }}>
                <img className='switch-icon disable'
                  src={ListViewIcon}>
                </img>
              </div>
              <div onClick={() => switchGridView(true)} style={{ "display": `${isGridView ? 'none' : 'block'}` }}>
                <img className='switch-icon disable'
                  src={GridViewIcon}></img>
              </div>
              <div onClick={() => switchGridView(false)} style={{ "display": `${isGridView ? 'none' : 'block'}` }}>
                <img className='switch-icon'
                  src={ListViewIconActive}></img>
              </div>
            </div>
            <div className="add-quorum-button publish" onClick={onClick}>
              <img src={PublishIcon} className="button-plus-img" />
              <h4 class="button-text">Publish</h4>
            </div>
          </div>
        </div>
      )}
      <div
        className={`posts_div ${(windowSize < 767 || isMobile) ? 'mobile_posts' : ''} ${isGridView ? 'grid-view' : 'list-view'}`}
        style={{ marginTop: feed.length == 0 ? 10 : 0 }}
      >
        {feed.length > 0 &&
          (
            isGridView ? (
              feed.map((f, i) => (
                <PostCardView
                  key={i} {...f}
                  thumbnail={f.dzi_info ? f.dzi_info[0] : {}}
                  imgSrc={f.imgSrc ? f.imgSrc : {}}>
                </PostCardView>
              ))
            ) : <MapPosts posts={computeMapFeed()} nothingMssg={nothingMssg} />
          )
        }
      </div>
      {!isFeedLoad && !isGridView && (
        <div style={{ marginTop: 20 }}>
          <CaseStreamSecondLoader />
        </div>
      )}

      {!isFeedLoad && isGridView > 0 && (
        <div style={{ marginTop: 20 }}>
          <CaseStreamCardLoader />
        </div>
      )}
      {
        (isAllFeedLoad || (feed.length == 0 && isAdmin() && showReview))
        && <End />
      }
      {isShowPrompt && (<Prompt
        title={`Thanks for sharing case on Crosscope`}
        content={`Your case is being reviewed by our moderators and will be visible to the community upon approval.`}
        actionText="Ok"
        action={closePrompt}
        promptType={'HideCancelButton'}
        img={ThumbsIcon}
      />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  feed: state.Post.feed,
  isAllFeedLoad: state.Post.isAllFeedLoad,
  isFeedLoad: state.Post.isFeedLoad,
  isMobile: state.Setting.isMobile,
  windowSize: state.Setting.windowSize,
  isLogoutUser: state.User.isLogoutUser,
  isShowPrompt: state.Prompt.isShowPrompt,
  isGridView: state.Post.isGridView
});

const mapDispatchToProps = dispatch => ({
  closePrompt: () => {
    dispatch(
      hidePrompt()
    )
  },
  switchGridView: () => {
    dispatch(switchGridView())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
