import { dispatchHelper } from '../utils/utils'

export const getUserDetails = username =>
  dispatchHelper('GET_USER_DETAILS', 'get-user-details', { username })

export const deleteTag = tag => {
  return {
    type: 'DELETE_TAG',
    payload: tag,
  }
}

export const addTag = tag => {
  return {
    type: 'ADD_TAG',
    payload: tag,
  }
}

export const setActivePage = page => {
  return {
    type: 'SET_ACTIVE_PAGE',
    payload: page,
  }
}

export const userLogOut = () => {
  return {
    type: 'USER_LOGOUT',
  }
}

export const resetUser = () => {
  return {
    type: 'RESET_USER',
  }
}

export const getMutualUsers = username =>
  dispatchHelper('GET_MUTUAL_USERS', 'get-mutual-users', { username })

export const isLogoutUser = () =>
  dispatchHelper('GET_IS_LOGOUT_USER', 'check-logout-user')
