import React from "react"

const VersionPage = () => {
  return (
    <div>
      Crosscope 1.0.3
    </div>
  )
}

export default VersionPage
