import React from 'react'
import { follow } from '../../../utils/user-interact-utils'
import { shape, number, string, func, bool } from 'prop-types'
import { connect } from 'react-redux'
import PrimaryButton from '../button/primary-btn'

const Follow = ({
  userDetails,
  followed,
  updateFollowings,
  updateFollowers,
  dispatch,
}) => {
  let { user, username, firstname, surname } = userDetails

  let followUser = e => {
    e.preventDefault()
    let obj = {
      user,
      username,
      firstname,
      surname,
      dispatch,
      update_followings: updateFollowings,
      update_followers: updateFollowers,
      done: () => followed(),
    }
    follow(obj)
  }

  return (
    <PrimaryButton
      label="Follow"
      onClick={followUser}
    />
  )
}

Follow.defaultProps = {
  updateFollowings: false,
  updateFollowers: false,
}

Follow.propTypes = {
  userDetails: shape({
    user: number.isRequired,
    username: string.isRequired,
    firstname: string,
    surname: string,
  }).isRequired,
  followed: func.isRequired,
  updateFollowings: bool,
  updateFollowers: bool,
}


export default connect()(Follow)
