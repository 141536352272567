import React, { Component } from "react";
import { FadeIn } from "animate-components";
import axios from "../../utils/axios-instance-utils";
import ResultUser from "./result-user";
import PostCardView from "../PostCardView/PostCardView";
import IsLoading from "../others/isLoading";
import Nothing from "../others/nothing";
import { withRouter } from "react-router-dom";
import { setActivePage } from "../../actions/user";
import { connect } from "react-redux";
import ToolTip from "react-tooltip";
import SearchIcon from "../../assets/icons/search-icon.svg";
import CloseIcon from "../../assets/icons/close-new.svg";

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      search: {
        users: [],
        cases: [],
        hashtags: []
      },
      type: "",
      isLoading: false
    };

    this.setSearchMainRef = React.createRef();
  }

  componentDidMount() {
    this.setSearchMainRef.current.addEventListener(
      "keydown",
      this.handleSearchMainKeyDown
    );
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    dispatch(setActivePage("search"));
    this.loadSearchData();
    document.body.style.overflowY = "scroll";
    return () => {
      document.body.style.overflow = "hidden";
    };
  }

  componentWillUnmount() {
    this.setSearchMainRef.current.removeEventListener(
      "keydown",
      this.handleSearchMainKeyDown
    );
    const { dispatch } = this.props;
    dispatch(setActivePage(""));
  }

  loadSearchData = async () => {
    const {
      location: { search }
    } = this.props;
    const searchData = search.split("=");
    const valueType = searchData[0].slice(1, searchData[0].length);
    const value = searchData[1];
    const newValue = value.replace(/%20/g, " ");
    this.setSearchMainRef.current.value = newValue;
    if (valueType === "mobile") {
      await this.setState({
        data: [],
        type: valueType,
        isLoading: false
      });
    } else {
      let { data } = await axios.post("/api/search-crosscope", {
        valueType,
        value: newValue
      });
      await this.setState({
        search: data,
        type: valueType,
        value: newValue,
        isLoading: false
      });
    }
  };

  loadSearchDataBtnClick = async searchValue => {
    const valueType = "text";
    const newValue = searchValue;
    this.setSearchMainRef.current.value = newValue;
    if (valueType === "mobile") {
      await this.setState({
        data: [],
        type: valueType,
        isLoading: false
      });
    } else {
      let { data } = await axios.post("/api/search-crosscope", {
        valueType,
        value: newValue
      });
      await this.setState({
        search: data,
        type: valueType,
        value: newValue,
        isLoading: false
      });
    }
  };

  handleSearchMainKeyDown = e => {
    const {
      target: { value }
    } = e;
    if (e.keyCode == 13) {
      this.search(value, false);
    }
  };

  handleSearcMobile = e => {
    e.preventDefault();
    const searchValue = this.setSearchMainRef.current.value;
    const trimValue = searchValue.trim();
    if (trimValue) {
      this.search(searchValue, true);
    }
  };

  search = (value, btnClick) => {
    if (value.trim() != "") {
      this.props.history.push({
        pathname: "/search",
        search: `?text=${value}`
      });

      if (!btnClick) {
        this.loadSearchData();
      } else {
        this.loadSearchDataBtnClick(value);
      }

      // this.setSearchRef.current.value = "";
    } else {
      return false;
    }
  };

  clear = e => {
    e.preventDefault();
    const search = {
      users: [],
      cases: [],
      hashtags: []
    };
    this.setState({ search: search });
    this.setSearchMainRef.current.value = "";
  };

  render() {
    const { search, value, type, isLoading } = this.state;
    const { users, cases } = search;
    return (
      <React.Fragment>
        <FadeIn duration="300ms" style={{ width: "100%" }}>
          <div className="main_view_container">
            <div className="srajkumar search_srajkumar row-fluid">
              <div class="for">
                <div
                  className="search_box group_search"
                  style={{ padding: "5px 5px", display: "block", width: 300 }}
                >
                  <input
                    className="search"
                    style={{ height: "35px" }}
                    type="text"
                    placeholder="Search..."
                    spellCheck="false"
                    autoComplete="false"
                    ref={this.setSearchMainRef}
                  />
                  <img
                    src={CloseIcon}
                    style={{ marginRight: 5, cursor: "pointer" }}
                    onClick={this.clear}
                    width="11px"
                    height="11px"
                    className="cancel-search"
                  />
                </div>
                {/* <a
                  class="fa fa-search"
                  onClick={this.handleSearcMobile}
                  id="searchBtnMobile"
                ></a> */}
                {/* <a
                  class="fas fa-times close-btn"
                  id="clearhistory"
                  onClick={this.clear}
                ></a> */}
              </div>
              <div className="searchResult">
                {users.length > 0 || cases.length > 0 ? (
                  <React.Fragment>
                    {type !== "tags" && type !== "hashtags" ? (
                      <React.Fragment>
                        {users.length > 0 && (
                          <div className="user_result_section">
                            <div className="userRst">
                              <h4>USERS</h4>
                              {users.length > 0 &&
                                users.map((user, i) => (
                                  <ResultUser key={i} {...user} />
                                ))}
                            </div>
                          </div>
                        )}
                        {cases.length > 0 && (
                          <div className="speciality_result_section">
                            <div className="specialistRst">
                              <h4>SPECIALITIES</h4>
                              {cases.length > 0 &&
                                cases.map((casedata, i) => (
                                  <PostCardView
                                    key={i}
                                    {...casedata}
                                    when="search"
                                  />
                                ))}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      cases.length > 0 && (
                        <div className="tag_result_section">
                          <h4>{type === "hashtags" ? `#${value}` : value}</h4>
                          <div className="specialistRst">
                            {cases.length > 0 &&
                              cases.map((casedata, i) => (
                                <PostCardView
                                  key={i}
                                  {...casedata}
                                  when="search"
                                />
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </React.Fragment>
                ) : isLoading ? (
                  <IsLoading loading={isLoading} when="page" />
                ) : (
                  <Nothing
                    mssg={
                      type === "mobile"
                        ? "Search cases and people"
                        : "No search result found"
                    }
                    pageIndicator="Search"
                  />
                )}
              </div>
            </div>
          </div>
        </FadeIn>
        <ToolTip place="top" type="dark" effect="solid" />
      </React.Fragment>
    );
  }
}

export default connect()(withRouter(SearchPage));
export { SearchPage as PureSearchPage };
