import React, { Component } from "react";
import { FadeIn } from "animate-components";
import Overlay from "../../others/overlay";
import ToolTip from "react-tooltip";
import Filters from "./filters/filters";
import GetLocation from "./getLocation";
import PostItHeader from "./header";
import PostItDetails from "./details";
import AddTags from "./add-tags";
import Middle from "./middle";
import ToggleAddTags from "./toggleAddTags";
import PostItActions from "./actions";
import { func, oneOf, number } from "prop-types";
import AddEmojis from "../../others/emojis/add-emojis";
import { CPP } from "../../../actions/post";
import { connect } from "react-redux";

class PostItMobile extends Component {
  state = {
    isHide : false
  }
  componentDidMount = () => {
    let { dispatch } = this.props;
    dispatch(CPP("type", "user"));
    dispatch(CPP("group", null));
  };

  togglePostIt = () => {
    this.setState({ postIt: !this.state.postIt });
  };

  hidePostModal = (state) => {
    this.setState({isHide: state})
 }

  render() {
    let {
      postIt: { fileChanged, showOverlay },
      isMobile,
      windowSize
    } = this.props;

    return (
      <div  style={this.state.isHide? {display: 'none'}: {display: 'block'}}>
        <FadeIn duration="300ms">
          <div
            className={
              windowSize < 992 || isMobile
                ? "main_view_container home_main_view_container_mobile"
                : "main_view_container home_main_view_container"
            }
          >
            <div
              className={
                windowSize < 992 || isMobile ? "stream_page_container_mobile" : "stream_page_container"
              }
            >
              <div className="post" style={{ left: "50%" }}>
                <PostItHeader />
                <PostItDetails isMobile={isMobile} />
                <Middle isMobile={isMobile} hidePostModal={this.hidePostModal}/>
                {/* <AddTags /> */}
                <div className="t_p_bottom p_bottom">
                  <PostItActions back={this.togglePostIt} />
                </div>
              </div>
              <ToolTip />
            </div>
          </div>
        </FadeIn>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  store,
  isMobile: store.Setting.isMobile,
  postIt: store.Post.postIt,
  windowSize: store.Setting.windowSize
});

export default connect(mapStateToProps)(PostItMobile);
export { PostItMobile as PurePostItMobile };
