import React, { Component } from "react";
import { FadeIn } from "animate-components";
import Title from "../others/title";
import { connect } from "react-redux";
import IsLoading from "../others/isLoading";
import ShowQuorum from "./show-quorum";
import { setActivePage } from "../../actions/user";
import { getUserDetails } from "../../actions/user";
import { uDetails } from "../../utils/utils";
import { withRouter } from "react-router-dom";
import { showPrompt } from "../../actions/prompt";
import axios from "../../utils/axios-instance-utils";
import Notify from "handy-notification";
import { resetQuorum } from "../../actions/post";

class QuorumPageInit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      quorumKey: "",
      isQuorumImageLoad: false,
      dziImageUrl: {
        filename: "",
        slide: null
      },
      isQuorumImageInit: false,
      canvasImage: {},
      isPublishInCaseSt: true,
      sumitQuorumSt: false,
      submitAnnotationSt: false,
      validUntil: null,
      defaultExpireDate: null,
      desc: ""
    };
    this.isAddedQuorum = false;
    this.setDescValue = this.setDescValue.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.isQuorumLoaded !== newProps.isQuorumLoaded ||
      (this.props.isQuorumLoaded && !this.isAddedQuorum)
    ) {
      this.loadSlideImage(newProps.quorumImageFromSlideBox);
    }
  }

  componentDidMount = () => {
    const { username } = uDetails("session");
    const { dispatch } = this.props;
    this.setState({ loading: false });
    dispatch(setActivePage("create-quorum"));
    dispatch(getUserDetails(username));
    this.loadQuorumKey();
  };

  componentWillUnmount = () => {
    let { dispatch } = this.props;
    dispatch(resetQuorum());
  };

  loadQuorumKey = async () => {
    let response = await fetch("/api/quorum-key", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    });

    let data = await response.json();
    if (data.success) {
      await this.setState({ quorumKey: data.quorum_key });
    }
  };

  setQuorumExpireTime = () => {
    const myDate = new Date();
    myDate.setHours(myDate.getHours() + 24);
    this.setState({ defaultExpireDate: myDate });
  };

  loadSlideImage = data => {
    this.setState({
      dziImageUrl: data,
      isQuorumImageLoad: true,
      isQuorumImageInit: true
    });
  };

  setDescValue = v => {
    this.setState({ desc: v });
  };

  doPublishQuorum = async () => {
    const { description, quorumMembers, labeltags } = this.props.quorum;
    const {
      quorumKey,
      dziImageUrl,
      canvasImage,
      isPublishInCaseSt,
      validUntil
    } = this.state;
    const { quorumImageFromSlideBox } = this.props;
    const { annotationData } = this.props;
    const form = new FormData();
    form.append("quorumKey", quorumKey);
    form.append("slideBoxId", dziImageUrl.slide[0].slide_box_id);
    form.append("description", description);
    form.append("type", "quorum");
    form.append("annotation", JSON.stringify(annotationData));
    form.append("valid_until", validUntil);
    form.append("status", isPublishInCaseSt ? "published" : "unpublished");
    form.append("labeltags", JSON.stringify(labeltags));
    form.append("isBlob", true);
    form.append(
      "caseImages",
      canvasImage.blob,
      canvasImage.blobUrl.split("/")[3]
    );
    form.append("mapImageArray", JSON.stringify(quorumImageFromSlideBox.slide));
    form.append("members", JSON.stringify(quorumMembers));
    Notify({ value: "Wait" });
    let response = await axios.post("/api/post-quorum", form);
    if (response.data.success) {
      this.redirectAfterPublish(response, isPublishInCaseSt);
    }
  };

  redirectAfterPublish = async (response, isPublishInCaseSt) => {
    let { quorumImageFromSlideBox } = this.props;
    Notify({ value: "Redirecting" });
    this.setState({ sumitQuorumSt: false });
    let getFileDataResult = await axios.post("/api/getFileData", {
      filename: quorumImageFromSlideBox.filename,
      fileId: quorumImageFromSlideBox.fileid,
      filePath: quorumImageFromSlideBox.filePath,
      keyFolder: quorumImageFromSlideBox.keyFolder
    });
    if (getFileDataResult.status === 200) {
      let post_id = response.data.post_id;
      let key_folder = getFileDataResult.data[0].key_folder;
      await axios.post("/api/updateSlidebox", {
        moduleName: "quorum",
        moduleId: post_id,
        filename: quorumImageFromSlideBox.filename,
        fileId: quorumImageFromSlideBox.fileid,
        filePath: quorumImageFromSlideBox.filePath,
        keyFolder: key_folder
      });
    }
    this.props.history.push(`/quorum/${response.data.quorum_id_token}`);
    let shouldShowPrompt =
      this.props.userData.verification !== "verified" && isPublishInCaseSt;
    if (shouldShowPrompt) {
      this.props.dispatch(showPrompt());
    }
  };

  validateQuorum = async () => {
    const { isQuorumImageLoad, validUntil } = this.state;
    const { description, labeltags } = this.props.quorum;
    let isValidQuorum = true;


    
    if (!isQuorumImageLoad) {
      Notify({ value: "Upload quorum image first" });
      isValidQuorum = false;
    } else if (!description) {
      Notify({ value: "Add quorum description" });
      isValidQuorum = false;
    } else if (!validUntil) {
      Notify({ value: "Set expiry date" });
      isValidQuorum = false;
    } else if (!labeltags || labeltags.length < 1) {
      Notify({ value: "Add at least one lable" });
      isValidQuorum = false;
    }

    if (!isValidQuorum) {
      this.setState({ sumitQuorumSt: false });
      return false;
    }

    await this.doPublishQuorum();
  };

  resetInitQuorumState = async () => {
    await this.setState({ isQuorumImageInit: false });
  };

  setScreenShot = async canvasImage => {
    await this.setState({ canvasImage }, () => this.validateQuorum());
  };

  isPublishInCase = async event => {
    await this.setState({ isPublishInCaseSt: !this.state.isPublishInCaseSt });
  };

  setSubmitQuorumStatus = async () => {
    this.setState({ sumitQuorumSt: true });
  };

  setRemainingTime = async value => {
    const timeStampValue = new Date(value.toDate()).getTime();
    this.setState({ validUntil: timeStampValue });
  };

  render() {
    const { loading, dziImageUrl, quorumKey } = this.state;
    return (
      <React.Fragment>
        <Title value="View post" />
        <FadeIn duration="300ms" style={{ width: "100%" }}>
          <IsLoading loading={loading} />
          <ShowQuorum
            type="create"
            dziImageUrl={dziImageUrl}
            setRemainingTime={this.setRemainingTime}
            setScreenShot={this.setScreenShot}
            setSubmitQuorumStatus={this.setSubmitQuorumStatus}
            quorumKey={quorumKey}
            isPublishInCase={this.isPublishInCase}
          />
        </FadeIn>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => ({
  post: store.Post.viewPost,
  isQuorumLoaded: store.SlideBox.isQuorumLoaded,
  quorumImageFromSlideBox: store.SlideBox.quorumImageFromSlideBox || "",
  annotationType: store.Viewer.annotationType,
  isAnnotationActive: store.Viewer.isAnnotationActive,
  annotationData: store.Viewer.annotationData,
  userAnnotation: store.Viewer.userAnnotation,
  quorum: store.Post.viewQuorum,
  userData: store.User.current_user_details
});

export default connect(mapStateToProps)(withRouter(QuorumPageInit));
export { QuorumPageInit as PureQuorumPageInit };
