import React, { useState, useEffect, useRef } from "react";
import UploadImageIcon from "icons/camera.svg";
import PrivateGroupIcon from "icons/private-group-icon.svg";
import PublicGroupIcon from "icons/public-group-icon.svg";
import DropdownIcon from "icons/dropdown-icon.svg";
import ThreeDots from "icons/three-dots.svg";
import ArrowLeft from "icons/arrow_left.svg";
import PrimaryButton from "../../others/button/primary-btn";
import SecondaryButton from "../../others/button/secondary-btn";
import User from "./user";
import _ from "lodash";
import MemberInvation from "./member-invitation";
import Notify from "handy-notification";

const GroupNew = ({ onSubmit, creatingError, footerNav, changeActiveTab }) => {
  const [file, setFile] = useState({});
  const [bloblFileURL, setBloblFileURL] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [about, setAbout] = useState("");
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [error, setError] = useState(creatingError);

  const fileRef = useRef(null);

  useEffect(() => {
    setError(creatingError);
  }, [creatingError]);

  useEffect(() => {
    if (footerNav) {
      footerNav("middle");
    }
  }, []);

  const submit = () => {
    let response = {
      file,
      isPublic,
      bio: about,
      name,
      members
    };
    if (name === "") {
      Notify({ value: "Group name is required" });
      setLoading(false);
    }

    onSubmit && onSubmit(response);
  };

  const checkUser = toCheck => {
    const checked = members.filter(user => {
      if (user.id === toCheck.id) return user.id;
    });
    return checked.length === 0;
  };

  const onClickDropdown = () => {
    setOpen(!open);
  };

  const onUploadFile = e => {
    const { files } = e.target;
    setBloblFileURL(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const onChange = e => {
    const { value, name } = e.target;
    setName(value);
    document.getElementsByClassName("aaaaaaa").focus();
  };

  const onAboutChange = e => {
    const { value, name } = e.target;
    setAbout(value);
  };

  const onSelectChange = value => {
    setIsPublic(value);
    setOpen(!open);
  };

  const addSelf = user => {
    if (checkUser(user)) {
      setMembers([user, ...members]);
    }
  };

  return (
    <div className="group-new">
      <div className="group-new-content">
        <div className="go-to-previous-page" onClick={() => footerNav("left")}>
          <img src={ArrowLeft} alt="goback" />
        </div>
        <h3 className="group-header">New Group</h3>
        <div className="group-info">
          <div className="upload-input-wrapper">
            <div className="upload-img-wrapper">
              <img
                src={_.isEmpty(bloblFileURL) ? UploadImageIcon : bloblFileURL}
                style={
                  !_.isEmpty(bloblFileURL)
                    ? { borderRadius: "100%", width: "100%", height: "100%" }
                    : { width: "38px", height: "38px" }
                }
                alt="upload image"
              />
            </div>
            <input
              type="file"
              accept="image/*"
              ref={fileRef}
              onChange={onUploadFile}
            />
          </div>
          <div className="input-wrapper">
            <div className="name-and-type">
              <input type="text" onChange={onChange} placeholder="Group name" />
              <Select
                isPublic={isPublic}
                open={open}
                onChange={onSelectChange}
                onClickDropdown={onClickDropdown}
              />
            </div>
            <div className="textarea-wrapper">
              <textarea
                onChange={onAboutChange}
                placeholder="About"
                className="aaaaaaa"
              />
            </div>
            <div className="group-new-members">
              {" "}
              <MemberInvation onMemberSelect={addSelf} />
              <div className="users">
                {members.map((member, i) => (
                  <User
                    key={i}
                    img={`/users/${member.id}/avatar.jpg`}
                    fullName={`${member.firstname} ${member.surname}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="submit-button-wrapper">
          <SecondaryButton label="Cancel" onClick={() => changeActiveTab("")} />
          <PrimaryButton label="Create" onClick={submit} />
        </div>
        <p style={{ color: "red" }}>{error}</p>
      </div>
    </div>
  );
};

export default GroupNew;

const Select = ({ isPublic, onChange, open, onClickDropdown }) => {
  return (
    <div onClick={onClickDropdown} className="group-new-select">
      <div className="select-condition">
        <div className="select-condition-text">
          <p className="group-type">Group type</p>
          <p className="actual-choice">{isPublic ? "Public" : "Private"}</p>
        </div>
        <button onClick={onClickDropdown} className="select-condition-btn">
        <img src={DropdownIcon} alt="dropdown" className={`triangle ${open ? 'rotate-triangle': ''}`} />
          <img
            src={ThreeDots}
            alt="dropdown"
            style={{ display: "none" }}
            className="three-dots"
          />
        </button>
      </div>
      {open && (
        <div className="choices-list">
          <div
            className="choice-item"
            onClick={() => {
              onChange(true);
            }}
          >
            <div className="content">
              <h3 className="title">Public Group</h3>
              <p className="description">
                Community members can find this group and contribute to the
                discussions.
              </p>
            </div>
            <img src={PublicGroupIcon} />
          </div>
          <div
            className="choice-item"
            onClick={() => {
              onChange(false);
            }}
          >
            <div className="content">
              <h3 className="title">Private Group</h3>
              <p className="description">
                Just group members can contribute and join only by invitation.
              </p>
            </div>
            <img src={PrivateGroupIcon} />
          </div>
        </div>
      )}
    </div>
  );
};
