import React from "react";

const HeaderLogo = ({ topToPage }) => (
  <div className="logo" onClick={e => topToPage(e)}>
    <img
      src="/images/crosscope-logo-new.svg"
      alt="Crosscope"
      onClick={e => topToPage(e)}
    />
  </div>
);

export default HeaderLogo;
