export const SET_ANNOTATION_DATA = "SET_ANNOTATION_DATA";
export const SET_CURRENT_ANNOTATION_DATA = "SET_CURRENT_ANNOTATION_DATA";
export const SET_ANNOTATION_TYPE = "SET_ANNOTATION_TYPE";
export const SET_ANNOTATION_COLOR = "SET_ANNOTATION_COLOR";
export const SET_ANNOTATION_ACTIVE = "SET_ANNOTATION_ACTIVE";

export const VIEWER_IMAGE_INIT_PENDIG = "VIEWER_IMAGE_INIT_PENDIG";
export const VIEWER_IMAGE_INIT_SUCCESS = "VIEWER_IMAGE_INIT_SUCCESS";
export const VIEWER_IMAGE_INIT_ERROR = "VIEWER_IMAGE_INIT_ERROR";

export const GET_VIEWER_IMAGE_PENDING = "GET_VIEWER_IMAGE_PENDING";
export const GET_VIEWER_IMAGE_SUCCESS = "GET_VIEWER_IMAGE_SUCCESS";
export const GET_VIEWER_IMAGE_ERROR = "GET_VIEWER_IMAGE_ERROR";
export const RESET_VIEWER_ANNOTATION = "RESET_VIEWER_ANNOTATION";
export const UPDATE_CURRENT_ANNOTATION_DATA = "UPDATE_CURRENT_ANNOTATION_DATA";
export const DELETE_ANNOTATION_DATA = "DELETE_ANNOTATION_DATA";
export const EDIT_CURRENT_ANNOTATION_DATA = "EDIT_CURRENT_ANNOTATION_DATA";
export const PUBLIC_ANNOTATION_DATA = "PUBLIC_ANNOTATION_DATA";
export const TOGGLE_ANNOTATION_DATA = "TOGGLE_ANNOTATION_DATA";
export const VALIDATE_ANNOTATION_DATA = "VALIDATE_ANNOTATION_DATA";
export const SET_LIVE_SHARE_ACTIVE = "SET_LIVE_SHARE_ACTIVE";
export const SET_LIVE_SHARE_USER_ACTIVE = "SET_LIVE_SHARE_USER_ACTIVE";
export const UPDATE_CURRENT_ANNOTATION_DATA_IN_VALIDATE =
  "UPDATE_CURRENT_ANNOTATION_DATA_IN_VALIDATE";

export const SET_SOCKET_DATE_ACTION_FALSE = "SET_SOCKET_DATE_ACTION_FALSE";

export const RESET_VIEWER_DATA = "RESET_VIEWER_DATA";

const resetDispatchAction = activePending => ({
  type: RESET_PASRECORDS,
  payload: activePending
});

export const setAnnotionDataAction = annotationData => ({
  type: SET_CURRENT_ANNOTATION_DATA,
  payload: annotationData
});

export const setViewerScaleUnit = unit => ({
  type: "SET_SCALE_UNIT",
  payload: unit
});

export const setViewerDefaultScaleUnit = () => ({
  type: "SET_DEFAULT_SCALE_UNIT"
});

export const setViewerScaleRatio = ratio => ({
  type: "SET_SCALE_RATIO",
  payload: ratio
});

export const setViewerDefaultScaleRatio = () => ({
  type: "SET_DEFAULT_SCALE_RATIO"
});

export const deleteAnnotionDataAction = annotationId => ({
  type: DELETE_ANNOTATION_DATA,
  payload: annotationId
});

export const updateAnnotionDataAction = annotation => ({
  type: UPDATE_CURRENT_ANNOTATION_DATA,
  payload: annotation
});

export const updateAnnotionDataInValidateAction = annotationArray => ({
  type: UPDATE_CURRENT_ANNOTATION_DATA_IN_VALIDATE,
  payload: annotationArray
});

export const editAnnotionDataAction = annotation => ({
  type: EDIT_CURRENT_ANNOTATION_DATA,
  payload: annotation.annotationData,
  pk: annotation.pk
});

export const publicAnnotationDataAction = data => ({
  type: PUBLIC_ANNOTATION_DATA,
  payload: data
});

export const toggleAnnotationDataAction = data => ({
  type: TOGGLE_ANNOTATION_DATA,
  payload: data
});

export const validateAnnotionDataAction = data => ({
  type: VALIDATE_ANNOTATION_DATA,
  payload: data
});

export const setAnnotionTypeAction = annotationType => ({
  type: SET_ANNOTATION_TYPE,
  payload: annotationType
});

export const setAnnotionActiveAction = annotationActiveStatus => ({
  type: SET_ANNOTATION_ACTIVE,
  payload: annotationActiveStatus
});

export const setAnnotionColorAction = annotationColor => ({
  type: SET_ANNOTATION_COLOR,
  payload: annotationColor
});

export const resetViewerAnnotationAction = () => ({
  type: RESET_VIEWER_ANNOTATION,
  payload: {}
});

export const initAnnotationAction = data => {
  return async dispatch => {
    const { type, status } = data;
    dispatch(setAnnotionTypeAction(type));
    dispatch(setAnnotionActiveAction(status));
  };
};

export const liveShareOnAction = status => ({
  type: SET_LIVE_SHARE_ACTIVE,
  payload: status
});

export const liveShareUserOnAction = status => ({
  type: SET_LIVE_SHARE_USER_ACTIVE,
  payload: status
});

export const setSocketDataUpdateAction = status => ({
  type: SET_SOCKET_DATE_ACTION_FALSE,
  payload: status
});

export const setViewerDataArray = payload => {
  return {
    type: "SET_VIEWER_DZI_DATA_ARRAY",
    payload
  };
};
