import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router-dom";
import Truncate from "react-truncate";
import ToolTip from "react-tooltip";
import Notify from "handy-notification";

import AppLink from "../others/link/link";
import PostRating from "../post/post/post-rating/post-rating";
import ShowMoreIcon from "../../assets/icons/show-more.svg";
import PostShare from "../post/share/post-share";
import axios from "../../utils/axios-instance-utils";

class BookmarkCardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRate: false,
      rate: props.rate || 0,
      rate_count: props.rate_count || 0,
      user_rate: props.my_rate || 0
    };

    this.truncateRef = React.createRef();
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (
      prevProps.showSidebar != this.props.showSidebar &&
      this.truncateRef &&
      typeof this.truncateRef.onResize === "function"
    ) {
      this.truncateRef.onResize();
    }
    return null;
  }

  calculateRemainingTime = time => {
    const currentTime = new Date().getTime();
    const msec = currentTime - time;

    const mins = Math.ceil(parseInt(msec) / 60000);
    const hrs = Math.ceil(parseInt(mins) / 60);

    let remaingMins = null;
    if (mins < 60) {
      remaingMins = 60 - mins;
    } else {
      remaingMins = 60 - (mins % 60);
    }

    let remaingHours = remaingMins !== 0 ? 24 - hrs : hrs;
    if (remaingHours > 0 || (remaingHours === 0 && remaingMins !== 0)) {
      remaingHours = remaingHours < 10 ? "0" + remaingHours : remaingHours;
      remaingMins = remaingMins < 10 ? "0" + remaingMins : remaingMins;
      return `${remaingHours}${":"}${remaingMins === 60 ? "00" : remaingMins}`;
    } else {
      return 0;
    }
  };

  submitRating = async rate => {
    const { post_id } = this.props;
    let res;
    try {
      res = await axios.post(
        `/api/posts/${post_id}/rates`,
        { rate },
        { withCredentials: true }
      );
    } catch (err) {
      console.log(err);
      Notify({ value: "Error occurred" });
      return;
    }
    // update new rate for post
    Notify({ value: "Rated successfully" });
    this.openRatePopup(false);
    this.setState({
      rate: res.data.rate,
      rate_count: res.data.rate_count,
      user_rate: res.data.user_rate
    });
  };

  openRatePopup = async open => {
    this.setState({
      openRate: open
    });
  };

  render() {
    let {
      username,
      post_id,
      firstname,
      surname,
      user,
      case_slove,
      country,
      affiliation,
      imgSrc,
      comments_count,
      likes_count,
      bookmark_count,
      postid_token,
      post_time,
      type,
      thumbnail,
      label_tags,
      post_status,
      sessionUserId,
      description,
      slideThumbnailSrc,
      dzi_info,
      isMobile,
      key
    } = this.props;

    bookmark_count = bookmark_count ? bookmark_count : 0;
    let imgSource = imgSrc.length ? imgSrc[0].src : "";
    let isQuorum = type === "quorum";
    let goTo = isQuorum
      ? `/quorum/${postid_token}`
      : `/openpost/${postid_token}`;
    let backgroundImgSrc = isQuorum
      ? `url("${imgSource}")`
      : `url("${thumbnail.thumbnail}")`;
    let cardStatus = null;
    let borderStyle = "";
    let dataTip = "";
    if (isQuorum) {
      cardStatus =
        this.calculateRemainingTime(post_time) !== 0 ? "online" : "offline";
      borderStyle = "quorum";
    } else if (case_slove) {
      cardStatus = "solved";
      borderStyle = "solved";
    }
    if (post_status == "under-review") {
      borderStyle = "under-review";
      if (isQuorum) {
        dataTip = "Quorum is under review";
      } else {
        dataTip = "Post is under review";
      }
    }
    let childProps = {
      postDetails: {
        post_id,
        user,
        description,
        postid_token,
        slideThumbnailSrc,
        type,
        dzi_info
      },
      isMobile
    };

    return (
      <div
        data-for={`card-view-tooltip-${post_id}`}
        data-tip={dataTip}
        className={`bookmark-card-view ${borderStyle}`}
      >
        <div className="post-card-header">
          <div className="user-info">
            <div className="entity-portrait-square">
              <AppLink to={`/profile/${username}`}>
                <img
                  alt={`${firstname}${" "}${surname}`}
                  src={`/users/${user}/avatar.jpg`}
                  size="square"
                  className="actor-avatar square "
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = "/images/default-avatar.svg";
                  }}
                />
              </AppLink>
            </div>
            <div className="entity-container">
              <AppLink to={`/profile/${username}`}>
                <span className="pro_preview_name">
                  {firstname} {surname}
                </span>
              </AppLink>
              <div
                data-for={`card-view-tooltip-${post_id}`}
                data-tip={`${affiliation}${", "}${country}`}
                className="an-meta inline"
              >
                <span>{`${affiliation}${", "}${country}`}</span>
              </div>
            </div>
          </div>
          <div className="sharing">
            <PostShare
              {...childProps}
              isQuorum={isQuorum}
              toggleOptions={this.toggleOptions}
              img={backgroundImgSrc}
            />
          </div>
        </div>

        <div className="post-card-description">
          <Truncate
            ref={ref => (this.truncateRef = ref)}
            lines={2}
            ellipsis={
              <span>
                ... <a href={goTo}>(see more)</a>
              </span>
            }
          >
            {ReactHtmlParser(description.replace(/&lt;/g, "<"))}
          </Truncate>
        </div>

        <div className="post-card-thumbnail">
          <AppLink to={goTo}>
            <div className="thumbnail-header-info">
              <div className="post-card-social-summary">
                {!isQuorum && (
                  <div className="card-info">
                    <i class="far fa-comment"></i>{" "}
                    {comments_count ? comments_count : 0}
                  </div>
                )}
                <div className="card-info">
                  <i class="fas fa-bookmark"></i>{" "}
                  {bookmark_count ? bookmark_count : 0}
                </div>
              </div>
            </div>
            <div class="card-thumbnail">
              <div
                className="img-container"
                style={{ backgroundImage: backgroundImgSrc }}
              ></div>
            </div>
          </AppLink>
        </div>

        <div className="post-card-footer">
          <div className="label-container">
            <div className="label-section">
              {label_tags.slice(0, 1).map((label, i) => (
                <span key={i} className="lable_tags">
                  <AppLink to={`/search?tags=${label.labeltag}`}>
                    {label.labeltag}
                  </AppLink>
                </span>
              ))}
            </div>
            {label_tags && label_tags.length > 1 && (
              <AppLink to={goTo}>
                <img src={ShowMoreIcon}></img>
              </AppLink>
            )}
          </div>
          <div className="rating-section">
            <PostRating
              rate={this.state.rate}
              rateCount={this.state.rate_count}
              rated={this.state.user_rate}
              openRate={this.state.openRate}
              submit={this.submitRating}
              setOpenRate={this.openRatePopup}
              isOwner={user === sessionUserId}
              isCardView={true}
            />
          </div>
        </div>
        <ToolTip
          id={`card-view-tooltip-${post_id}`}
          place="top"
          type="dark"
          effect="solid"
        />
      </div>
    );
  }
}

BookmarkCardView.propTypes = {
  post_id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  user: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  imgSrc: PropTypes.array.isRequired,
  type: PropTypes.oneOf(["user", "group", "quorum"]).isRequired,
  group_id: PropTypes.number,
  group_name: PropTypes.string,
  post_time: PropTypes.string.isRequired,
  share_by_username: PropTypes.string,
  share_time: PropTypes.string,
  when: PropTypes.oneOf([
    "feed",
    "viewPost",
    "userPosts",
    "bookmarks",
    "shared",
    "tagged",
    "groupPosts",
    "hashtag"
  ]).isRequired,
  likes_count: PropTypes.number.isRequired,
  shares_count: PropTypes.number.isRequired,
  comments_count: PropTypes.number.isRequired,
  tags_count: PropTypes.number.isRequired,
  comments: PropTypes.array,
  case_slove: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { session } = state.User;
  return {
    sessionUserId: session.id,
    isMobile: state.Setting.isMobile,
    showSidebar: state.Setting.showSidebar
  };
};
const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookmarkCardView));
