import React, { useState } from "react";
import PropTypes from "prop-types";
import verticalThreeDotsIcon from "./icons/vertical-three-dots.svg";
import MoreMenu from "./MoreMenu";
import axios from "../../../utils/axios-instance-utils";
import Notify from "handy-notification";
import DeleteConfirm from "./deleteConfirm";
import FolderIcon from "../../../assets/icons/folder-close-new.svg";
import FolderOpenIcon from "../../../assets/icons/folder-open-new.svg";

const Directory = props => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [currentDeleteFile, setCurrentDeleteFile] = useState(null);
  let opened = props.openedDirectory == props.folderId;
  const handleRename = (file, fileIndex) => {
    props.onFolderRenameChosen &&
      props.onFolderRenameChosen(
        { name: props.name, path: props.folderPath, folderId: props.folderId },
        fileIndex,
        false
      );
  };

  const confirmDelete = async file => {
    setCurrentDeleteFile(file ? file : props.folderId);
    setShowDeleteConfirm(true);
  };

  const showDetails = (file, fileIndex) => {
    props.showDetails &&
      props.showDetails(
        {
          name: props.name,
          path: props.folderPath ? props.folderPath : props.folderId,
          isSharedDirectory: props.isSharedDirectory
        },
        fileIndex,
        false
      );
  };

  const shareFolders = (file, fileIndex) => {
    props.showDetails &&
      props.showDetails(
        {
          name: file.name,
          path: file.path,
          isSharedDirectory: file.isSharedDirectory
        },
        fileIndex,
        true
      );
  };
  const deleteFolder = async () => {
    try {
      await axios.post("/api/deleteFolder", {
        folderToDelete: currentDeleteFile
      });
    } catch (err) {
      Notify({ value: "Error occurred" });
    }
    Notify({ value: "Folder deleted" });
    sessionStorage.setItem("selectedFolderId", props.folderRootId);
    sessionStorage.setItem("selectedFolderPath", "slidebox");
    props.onFolderDelete(true);
    setShowDeleteConfirm(false);
    props.onFolderChanged && props.onFolderChanged();
  };

  const handleCancelDeleteFile = () => {
    setCurrentDeleteFile(null);
    setShowDeleteConfirm(false);
  };

  const moreMenuItems =
    !props.isSharedDirectory && !props.isPublicFolder
      ? [
          { title: "Rename", callBack: handleRename },
          { title: "Delete", callBack: confirmDelete },
          { title: "Details", callBack: showDetails },
          { title: "Share", callBack: shareFolders }
        ]
      : [{ title: "Details", callBack: showDetails }];

  return (
    <div className={`directory-item ${opened ? "open" : ""}`}>
      <div className="folder-content">
        <div
          onClick={() =>
            props.onOpenDirectoryContent({
              directoryPath: props.folderPath
                ? props.folderPath
                : props.folderId,
              onFolderClick: true,
              parentFolder: props.parentFolder,
              isSharedFolder: props.isSharedDirectory,
              isPublicFolder: props.isPublicFolder,
              name: props.name
            })
          }
        >
          {props.openedDirectory === props.folderPath ||
          props.openedDirectory === props.folderId ? (
            <div>
              <img
                className="rotate-icon arrow"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNCA0Ljc1TDEgOC41IiBzdHJva2U9IiM0QjRCNEIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
              />
              <img className="open-directory-icon" src={FolderOpenIcon} />
            </div>
          ) : (
            <div>
              <img
                className="arrow"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNCA0Ljc1TDEgOC41IiBzdHJva2U9IiM0QjRCNEIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
              />
              <img className="closed-directory-icon" src={FolderIcon} />
            </div>
          )}
          <p className="directory-label">
            {props.name.length > 20
              ? props.name.substring(0, 20) + "..."
              : props.name}
          </p>
        </div>
        {props.withMoreMenu && (
          <MoreMenu
            className={"row-option"}
            icon={verticalThreeDotsIcon}
            fileData={{
              name: props.name,
              path: props.folderPath ? props.folderPath : props.folderId,
              isSharedDirectory: props.isSharedDirectory
            }}
            fileIndex={props.index}
            menuItems={moreMenuItems}
            onButtonMouseDown={() => {}}
            isFolder={true}
          />
        )}

        {showDeleteConfirm && (
          <DeleteConfirm
            handleDelete={deleteFolder}
            isFile={false}
            toggleOptions={() => handleCancelDeleteFile()}
          />
        )}
      </div>
    </div>
  );
};

Directory.propTypes = {
  folderPath: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  openedDirectory: PropTypes.string,
  onOpenDirectoryContent: PropTypes.func.isRequired
};

export default Directory;
