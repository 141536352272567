import { dispatchHelper } from "../utils/utils";

export const getBlockedUsers = () =>
  dispatchHelper("GET_BLOCKED_USERS", "get-blocked-users");

export const unblockUser = block_id => {
  return {
    type: "UNBLOCK_USER",
    payload: block_id
  };
};

export const setWindowWidth = width => {
  return {
    type: "ON_WINDOW_RESIZE",
    payload: width
  };
};

export const setIsMobile = isMobile => {
  return {
    type: "IS_MOBILE",
    payload: isMobile
  };
};

export const toggleSidebarAction = toggle => {
  return {
    type: "TOGGLE_SIDEBAR",
    payload: toggle
  };
};
