import React, { useState, useRef } from 'react'
import moment from 'moment'
import Image from '../../tumor-boards/components/Image'
import Fade from '../../animations/Fade'
import GroupDeleteMessage from '../../tumor-boards/new-components/group-delete-message'
import { connect } from 'react-redux'
import axios from '../../../utils/axios-instance-utils'
import Notify from 'handy-notification'
import { editMessage } from '../../../actions/tumorBoards'
import SecondaryButton from '../../others/button/secondary-btn'
import PrimaryButton from '../../others/button/primary-btn'

const ImageItem = ({
  id: msgId,
  userId,
  firstName,
  surname,
  time,
  img,
  body,
  type,
  status,
  dispatch,
  loggedId,
  group,
  edit,
  setEdit,
}) => {
  const [editing, setEditing] = useState('')
  const [toDelete, setToDelete] = useState(-1)
  const inputRef = useRef(null)
  const formattedTime = moment(time).format('h:mm A')
  const sendEdit = async id => {
    const formData = new FormData()
    formData.append('tumorBoardId', group.id)
    formData.append('msg', editing)
    formData.append('msgId', id)
    const { status, data } = await axios
      .put('api/tumorBoards/msgs', formData, { withCredentials: true })
      .catch(() => {
        Notify({ value: 'Error editing message' })
      })
    if (status === 200) {
      dispatch(editMessage(id, editing))
    }
    setEditing('')
    setEdit(-1)
  }
  return (
    <div className="conversation-item" style={{ width: '100%'}}>
      <div>
        {/* TODO: remove absolute link */}
        <img
          src={`/users/${userId}/avatar.jpg`}
          alt="user"
          className="user-img"
          onError={e => {
            e.target.onerror = null
            e.target.src = '/images/default-avatar.svg'
          }}
        />
      </div>
      <div className="conversation-item-content" style={{width: '100%'}}>
        <div className="conversation-item-details">
          <h3 className="details-fullname">{`${firstName} ${surname}`}</h3>
          <p>{formattedTime}</p>
          {status === 'updated' && <span>&nbsp;(edited)</span>}
          {loggedId === userId &&
            type !== 'joined' &&
            type !== 'removed' &&
            status !== 'deleted' && (
              <img
                src="/images/icons/neutral/edit.png"
                className="edit-and-delete"
                alt="edit"
                onClick={() => setEdit(msgId)}
              />
            )}
          {loggedId === userId &&
            type !== 'joined' &&
            type !== 'removed' &&
            status !== 'deleted' && (
              <img
                src="/images/icons/neutral/delete.png"
                className="edit-and-delete"
                alt="delete"
                onClick={() => setToDelete(msgId)}
              />
            )}
        </div>
        <div className="conversation-message">
          {edit === msgId ? (
            <div>
              <input
                autoFocus
                onFocus={() => setEditing(body)}
                value={editing}
                onChange={() => setEditing(inputRef.current.value)}
                ref={inputRef}
                style={{ width: '100%'}}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px'}}>
                <SecondaryButton label="Cancel" onClick={() => setEdit(-1)}/>
                <PrimaryButton label="Submit" onClick={() => sendEdit(msgId)}/>
              </div>
            </div>
          ) : (
            <p>{status !== 'deleted' ? body : 'This message was deleted.'}</p>
          )}
          {edit !== msgId && status !== 'deleted' && <Image imgSrc={img} />}
        </div>
      </div>
      <Fade show={toDelete !== -1} duration="300ms">
        <GroupDeleteMessage close={() => setToDelete(-1)} toDelete={msgId} />
      </Fade>
    </div>
  )
}
const mapStateToProps = state => {
  const { session } = state.User
  const { selectedBoard: group } = state.TumorBoards
  return {
    loggedId: session.id,
    group,
  }
}

export default connect(mapStateToProps)(ImageItem)
