import React, { Component, Fragment } from 'react'
import { isAdmin } from '../../../../../utils/admin-utils'
import { Me } from '../../../../../utils/utils'
import EditPost from '../../../edit-post/edit-post'
import EditIcon from './icons/edit-icon-new.svg'
import PropTypes from 'prop-types'

export default class EditPostOption extends Component {
  state = {
    editPost: false,
  }

  showEditPost = e => {
    e ? e.preventDefault() : null
    this.setState({ editPost: !this.state.editPost });
  }

  modalBack = () => {
    this.setState({ editPost: false })
    this.props.toggleOptions()
  }

  triggerModal = e => {
    const overlay = document.getElementById('quorum-modal-overlay')
    if (e == 'CLOSE MODAL' || e.target == overlay) {
      this.setState({ editPost: false })
    } else {
      this.setState({ editPost: true })
    }
  }

  render() {
    let {
      postDetails: { user, post_id, description, labels, when },
      updateDescription,
    } = this.props
    let { editPost } = this.state
    return (
      <Fragment>
        {(Me(user) || isAdmin()) && (
          <li style={{ display: 'flex' }}>
            <a href="#" onClick={this.showEditPost}>
              <img
                src={EditIcon}
                alt="edit"
                style={{ width: 20, marginLeft: -10, marginRight: 10 }}
              />
              Edit post {isAdmin() ? 'as admin' : ''}
            </a>
          </li>
        )}

        {editPost && (
          <EditPost
            post={post_id}
            description={description}
            labels={labels}
            back={this.modalBack}
            when={when}
            changeDesc={value => updateDescription(value)}
          />
        )}
      </Fragment>
    )
  }
}

EditPostOption.propTypes = {
  postDetails: PropTypes.shape({
    user: PropTypes.number.isRequired,
    post_id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  updateDescription: PropTypes.func.isRequired,
  toggleOptions: PropTypes.func.isRequired,
}
